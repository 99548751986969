import * as React from "react";
import { RouteComponentProps } from "@reach/router";

import GroupChatContainer from "../chat/GroupChatContainer";

export class GroupChatScreen extends React.PureComponent<RouteComponentProps> {
  render() {
    return <GroupChatContainer />;
  }
}
