/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type NeedStatus = "has" | "needs" | "obtained" | "unknown" | "%future added value";
export type RecidivismReason = "civil" | "new_charge" | "unknown" | "%future added value";
export type YesNoUnknown = "no" | "unknown" | "yes" | "%future added value";
export type ParticipantCasesGraphqlQueryVariables = {
    readonly id: string;
};
export type ParticipantCasesGraphqlQueryResponse = {
    readonly me: ({
        readonly case_manager: ({
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
        }) | null;
    }) | null;
    readonly participants: ({
        readonly participant: ReadonlyArray<({
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
            readonly cases: ReadonlyArray<({
                readonly id: string;
                readonly case_number: string;
                readonly supervision_start_date: string | null;
                readonly supervision_end_date: string | null;
                readonly created_at: string;
                readonly updated_at: string | null;
                readonly housing: ({
                    readonly status: NeedStatus | null;
                }) | null;
                readonly employment: ({
                    readonly status: NeedStatus | null;
                }) | null;
                readonly education: ({
                    readonly status: NeedStatus | null;
                }) | null;
                readonly treatment_types: ReadonlyArray<({
                    readonly id: string;
                }) | null> | null;
                readonly group_meetings: ReadonlyArray<({
                    readonly id: string;
                }) | null> | null;
                readonly courts: ReadonlyArray<({
                    readonly id: string;
                }) | null> | null;
                readonly transportation: ({
                    readonly status: NeedStatus | null;
                }) | null;
                readonly primary_treatment_facilities: ReadonlyArray<({
                    readonly id: string;
                }) | null> | null;
                readonly did_recidivate: YesNoUnknown | null;
                readonly recidivism_reason: RecidivismReason | null;
                readonly case_type: string | null;
                readonly medicated: YesNoUnknown | null;
                readonly program_result: string | null;
                readonly risk_level: string | null;
                readonly risk_score: string | null;
                readonly assessment_score: string | null;
                readonly hand_off_to_probation: YesNoUnknown | null;
                readonly absconded: YesNoUnknown | null;
                readonly is_emh: YesNoUnknown | null;
            }) | null> | null;
        }) | null> | null;
    }) | null;
};
export type ParticipantCasesGraphqlQuery = {
    readonly response: ParticipantCasesGraphqlQueryResponse;
    readonly variables: ParticipantCasesGraphqlQueryVariables;
};



/*
query ParticipantCasesGraphqlQuery(
  $id: String!
) {
  me {
    case_manager {
      id
      name {
        first
        last
      }
    }
  }
  participants(id: $id) {
    participant {
      id
      name {
        first
        last
      }
      cases {
        id
        case_number
        supervision_start_date
        supervision_end_date
        created_at
        updated_at
        housing {
          status
        }
        employment {
          status
        }
        education {
          status
        }
        treatment_types {
          id
        }
        group_meetings {
          id
        }
        courts {
          id
        }
        transportation {
          status
        }
        primary_treatment_facilities {
          id
        }
        did_recidivate
        recidivism_reason
        case_type
        medicated
        program_result
        risk_level
        risk_score
        assessment_score
        hand_off_to_probation
        absconded
        is_emh
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "String!",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "name",
        "storageKey": null,
        "args": null,
        "concreteType": "PersonName",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "first",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "last",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v3 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
        } as any)
    ], v4 = [
        (v1 /*: any*/)
    ], v5 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "me",
            "storageKey": null,
            "args": null,
            "concreteType": "Me",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "case_manager",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CaseManager",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/)
                    ]
                }
            ]
        } as any),
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "participants",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                }
            ],
            "concreteType": "Pagination",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participant",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Participant",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "cases",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Case",
                            "plural": true,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "case_number",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "supervision_start_date",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "supervision_end_date",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "created_at",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "updated_at",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "housing",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "CaseNeed",
                                    "plural": false,
                                    "selections": (v3 /*: any*/)
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "employment",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "CaseNeed",
                                    "plural": false,
                                    "selections": (v3 /*: any*/)
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "education",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "CaseNeed",
                                    "plural": false,
                                    "selections": (v3 /*: any*/)
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "treatment_types",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "IdField",
                                    "plural": true,
                                    "selections": (v4 /*: any*/)
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "group_meetings",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "IdField",
                                    "plural": true,
                                    "selections": (v4 /*: any*/)
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "courts",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "IdField",
                                    "plural": true,
                                    "selections": (v4 /*: any*/)
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "transportation",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "CaseNeed",
                                    "plural": false,
                                    "selections": (v3 /*: any*/)
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "primary_treatment_facilities",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "IdField",
                                    "plural": true,
                                    "selections": (v4 /*: any*/)
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "did_recidivate",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "recidivism_reason",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "case_type",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "medicated",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "program_result",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "risk_level",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "risk_score",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "assessment_score",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "hand_off_to_probation",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "absconded",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "is_emh",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "ParticipantCasesGraphqlQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v5 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "ParticipantCasesGraphqlQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v5 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "ParticipantCasesGraphqlQuery",
            "id": null,
            "text": "query ParticipantCasesGraphqlQuery(\n  $id: String!\n) {\n  me {\n    case_manager {\n      id\n      name {\n        first\n        last\n      }\n    }\n  }\n  participants(id: $id) {\n    participant {\n      id\n      name {\n        first\n        last\n      }\n      cases {\n        id\n        case_number\n        supervision_start_date\n        supervision_end_date\n        created_at\n        updated_at\n        housing {\n          status\n        }\n        employment {\n          status\n        }\n        education {\n          status\n        }\n        treatment_types {\n          id\n        }\n        group_meetings {\n          id\n        }\n        courts {\n          id\n        }\n        transportation {\n          status\n        }\n        primary_treatment_facilities {\n          id\n        }\n        did_recidivate\n        recidivism_reason\n        case_type\n        medicated\n        program_result\n        risk_level\n        risk_score\n        assessment_score\n        hand_off_to_probation\n        absconded\n        is_emh\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'dd1b78b38fbd583c9bdbfa0ba7cdb22a';
export default node;
