/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type ParticipantAssessmentResultStatus = "draft" | "finalized" | "new" | "%future added value";
export type ParticipantAssessmentGraphqlQueryVariables = {
    readonly id: string;
};
export type ParticipantAssessmentGraphqlQueryResponse = {
    readonly me: ({
        readonly case_manager: ({
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
        }) | null;
    }) | null;
    readonly participants: ({
        readonly participant: ReadonlyArray<({
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
            readonly assessment_results: ReadonlyArray<({
                readonly id: string;
                readonly status: ParticipantAssessmentResultStatus;
                readonly case_number: string;
                readonly assessment_name: string;
                readonly participant_id: string;
                readonly assessment_id: string;
                readonly rating: string | null;
                readonly total_score: number | null;
                readonly scored_by: string;
                readonly created_at: string;
                readonly updated_at: string | null;
                readonly scores: ReadonlyArray<({
                    readonly id: string;
                    readonly assessment_template_question_id: string;
                    readonly score: number | null;
                    readonly value: string | null;
                    readonly verified: boolean | null;
                    readonly question_text: string;
                    readonly question_number: number;
                    readonly question_options: ReadonlyArray<({
                        readonly value: number;
                        readonly text: string;
                    }) | null>;
                }) | null>;
                readonly ranges: ReadonlyArray<({
                    readonly rating: string;
                    readonly min_score: number;
                    readonly max_score: number | null;
                    readonly failure_rate: number | null;
                    readonly failure_to_appear_rate: number | null;
                    readonly new_arrest_rate: number | null;
                }) | null>;
            }) | null> | null;
        }) | null> | null;
    }) | null;
};
export type ParticipantAssessmentGraphqlQuery = {
    readonly response: ParticipantAssessmentGraphqlQueryResponse;
    readonly variables: ParticipantAssessmentGraphqlQueryVariables;
};



/*
query ParticipantAssessmentGraphqlQuery(
  $id: String!
) {
  me {
    case_manager {
      id
      name {
        first
        last
      }
    }
  }
  participants(id: $id) {
    participant {
      id
      name {
        first
        last
      }
      assessment_results {
        id
        status
        case_number
        assessment_name
        participant_id
        assessment_id
        rating
        total_score
        scored_by
        created_at
        updated_at
        scores {
          id
          assessment_template_question_id
          score
          value
          verified
          question_text
          question_number
          question_options {
            value
            text
          }
        }
        ranges {
          rating
          min_score
          max_score
          failure_rate
          failure_to_appear_rate
          new_arrest_rate
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "String!",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "name",
        "storageKey": null,
        "args": null,
        "concreteType": "PersonName",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "first",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "last",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "rating",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "value",
        "args": null,
        "storageKey": null
    } as any), v5 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "me",
            "storageKey": null,
            "args": null,
            "concreteType": "Me",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "case_manager",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CaseManager",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/)
                    ]
                }
            ]
        } as any),
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "participants",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                }
            ],
            "concreteType": "Pagination",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participant",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Participant",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "assessment_results",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ParticipantAssessmentResult",
                            "plural": true,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "status",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "case_number",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "assessment_name",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "participant_id",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "assessment_id",
                                    "args": null,
                                    "storageKey": null
                                },
                                (v3 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "total_score",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "scored_by",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "created_at",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "updated_at",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "scores",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ParticipantAssessmentResultScore",
                                    "plural": true,
                                    "selections": [
                                        (v1 /*: any*/),
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "assessment_template_question_id",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "score",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        (v4 /*: any*/),
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "verified",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "question_text",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "question_number",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "question_options",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "AssessmentTemplateQuestionOption",
                                            "plural": true,
                                            "selections": [
                                                (v4 /*: any*/),
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "text",
                                                    "args": null,
                                                    "storageKey": null
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "ranges",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "AssessmentTemplateRange",
                                    "plural": true,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "min_score",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "max_score",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "failure_rate",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "failure_to_appear_rate",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "new_arrest_rate",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "ParticipantAssessmentGraphqlQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v5 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "ParticipantAssessmentGraphqlQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v5 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "ParticipantAssessmentGraphqlQuery",
            "id": null,
            "text": "query ParticipantAssessmentGraphqlQuery(\n  $id: String!\n) {\n  me {\n    case_manager {\n      id\n      name {\n        first\n        last\n      }\n    }\n  }\n  participants(id: $id) {\n    participant {\n      id\n      name {\n        first\n        last\n      }\n      assessment_results {\n        id\n        status\n        case_number\n        assessment_name\n        participant_id\n        assessment_id\n        rating\n        total_score\n        scored_by\n        created_at\n        updated_at\n        scores {\n          id\n          assessment_template_question_id\n          score\n          value\n          verified\n          question_text\n          question_number\n          question_options {\n            value\n            text\n          }\n        }\n        ranges {\n          rating\n          min_score\n          max_score\n          failure_rate\n          failure_to_appear_rate\n          new_arrest_rate\n        }\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'a42f0fe4637a9bcc2cda6c87d61899fc';
export default node;
