/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type MutationResult = "failure" | "skipped" | "success" | "%future added value";
export type groupClassesDeleteGroupClassMutationVariables = {
    readonly group_class_id: string;
};
export type groupClassesDeleteGroupClassMutationResponse = {
    readonly deleteGroupClass: ({
        readonly result: MutationResult;
        readonly description: string | null;
        readonly group_class: ({
            readonly id: string;
        }) | null;
    }) | null;
};
export type groupClassesDeleteGroupClassMutation = {
    readonly response: groupClassesDeleteGroupClassMutationResponse;
    readonly variables: groupClassesDeleteGroupClassMutationVariables;
};



/*
mutation groupClassesDeleteGroupClassMutation(
  $group_class_id: String!
) {
  deleteGroupClass(group_class_id: $group_class_id) {
    result
    description
    group_class {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "group_class_id",
            "type": "String!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "deleteGroupClass",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "group_class_id",
                    "variableName": "group_class_id"
                }
            ],
            "concreteType": "NewGroupClassResult",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "result",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "group_class",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "GroupClass",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "groupClassesDeleteGroupClassMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "groupClassesDeleteGroupClassMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "groupClassesDeleteGroupClassMutation",
            "id": null,
            "text": "mutation groupClassesDeleteGroupClassMutation(\n  $group_class_id: String!\n) {\n  deleteGroupClass(group_class_id: $group_class_id) {\n    result\n    description\n    group_class {\n      id\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '8375954d4dab9a83811a1eaf824039f9';
export default node;
