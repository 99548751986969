import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "../shared/lib/graphql/commitMutation";
import {
  Message,
  MutationResponse,
  MutationResponseBase,
  MessageReadReceipt,
} from "../shared/lib/graphql";

interface SendMessageGqlArguments {
  text: string;
  participant_id: string;
  group_id: string;
}

export interface SendMessageInnerResult extends MutationResponseBase {
  newMessage: Message;
}

export interface SendMessageResult {
  addMessageFromCaseManager: SendMessageInnerResult;
}

export const sendMessage = async (
  variables: SendMessageGqlArguments
): Promise<SendMessageResult> => {
  return commitMutation(
    {
      mutation: graphql`
        mutation messagesSendMessageMutation(
          $text: String
          $participant_id: String
          $group_id: String
        ) {
          addMessageFromCaseManager(
            text: $text
            participant_id: $participant_id
            group_id: $group_id
          ) {
            result
            description
            errors
            newMessage {
              id
              text
              created_at
              sender_type
              recipient_type
              read_at
              sent_as
              delivery_status
              sender {
                uid
                name {
                  first
                  last
                }
              }
            }
          }
        }
      `,
      variables,
    },
    "Error while sending a message"
  ) as any;
};

export const markAsRead = async (
  messageReceipts: MessageReadReceipt[]
): Promise<MutationResponse[]> => {
  return commitMutation(
    {
      mutation: graphql`
        mutation messagesMarkMessagesAsReadMutation(
          $read_message_receipts: [MessageReadReceipt]!
        ) {
          markMessagesAsRead(read_message_receipts: $read_message_receipts) {
            result
            description
            errors
          }
        }
      `,
      variables: { read_message_receipts: messageReceipts },
    },
    "Error while marking a message as read"
  ) as any;
};
