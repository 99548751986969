import * as React from "react";

export class Logo extends React.PureComponent {
  render() {
    return (
      <svg viewBox="0 0 228 49" xmlns="http://www.w3.org/2000/svg">
        <mask id="a" fill="#fff">
          <path
            d="m.21896569.63423077h10.05410061v10.28500003h-10.05410061z"
            fill="#fff"
            fillRule="evenodd"
          />
        </mask>
        <mask id="b" fill="#fff">
          <path
            d="m0 48.3192308h227.299332v-48.22050003h-227.299332z"
            fill="#fff"
            fillRule="evenodd"
          />
        </mask>
        <g fill="none" fillRule="evenodd" transform="translate(0 -.019231)">
          <path
            d="m142.677165 30.4038462h8.503937v-16.673077h-8.503937z"
            fill="#008cb2"
          />
          <path
            d="m142.677165 47.0769231h8.503937v-16.6730769h-8.503937z"
            fill="#eda645"
          />
          <path
            d="m.21896569 5.83793077c0-2.8441 2.18740847-5.2037 5.02638542-5.2037 2.84063912 0 5.02804759 2.3596 5.02804759 5.2037 0 2.7829-2.18740847 5.08130003-5.02804759 5.08130003-2.83897695 0-5.02638542-2.29840003-5.02638542-5.08130003"
            fill="#cf3b29"
            mask="url(#a)"
            transform="translate(217.026266 37.4)"
          />
          <path
            d="m147.125991.09873077c2.927072 0 5.156035 2.2134 5.156035 5.1408 0 2.9291-2.228963 5.14080003-5.156035 5.14080003-2.863909 0-5.089547-2.21170003-5.089547-5.14080003 0-2.9274 2.225638-5.1408 5.089547-5.1408"
            fill="#cf3b29"
          />
          <path
            d="m32.8759515 47.4726308h8.8144583v-17.0476h-8.8144583z"
            fill="#008cb2"
            mask="url(#b)"
          />
          <path
            d="m50.6286994 21.7040308v-9.1103c-4.3897765 0-7.699146 2.4072-9.1618507 5.7919v-4.947h-8.5900661v16.9847h8.8144583c0-4.8161 3.8163297-8.7193 8.5252417-8.7193z"
            fill="#eda645"
            mask="url(#b)"
          />
          <path
            d="m0 21.6938308h8.95740445v-19.77270003h-8.95740445z"
            fill="#eda645"
            mask="url(#b)"
          />
          <path
            d="m0 47.4726308h8.95740445v-17.0476h-8.95740445z"
            fill="#cf3b29"
            mask="url(#b)"
          />
          <path
            d="m171.970696 38.0350808c-.034905 1.9108-1.612299 2.9954-4.065654 2.9954-3.753167 0-5.980468-1.7561-7.634321-3.7094l-4.923332 5.049c2.039476 2.6809 5.892374 5.9483 12.175355 5.9483 6.999375 0 12.474545-3.9525 12.762099-10.2833z"
            fill="#cf3b29"
            mask="url(#b)"
          />
          <path
            d="m177.738075 30.6987308c-4.027425-4.0885-11.500516-4.1667-12.948262-7.0771h-.001662.001662c-.14627-.2941-.23104-.6171-.244338-.9741h-8.280903c0 .0119-.001662.0221-.001662.0323 0 2.5517.703095 4.4693 1.80511 5.9653 3.254518 4.4217 10.024514 5.1187 12.742153 7.2318.726366.5644 1.168502 1.2291 1.168502 2.0944 0 .0238-.008311.0425-.008311.0629h8.312485c.009973-.1717.029919-.34.029919-.5168 0-3.1144-1.037191-5.2581-2.574693-6.8187"
            fill="#008cb2"
            mask="url(#b)"
          />
          <path
            d="m179.443954 17.6840408c-2.114273-2.6401-5.308953-5.0915-11.093286-5.0915-6.79659 0-12.068976 3.7621-12.085598 10.0555h8.279242c-.001662-.034-.011635-.0646-.011635-.0986 0-1.6915 1.399542-2.7319 3.439018-2.7319 2.757531 0 4.712236 1.4977 6.229792 3.23l.285893.3264 5.215872-5.3363z"
            fill="#eda645"
            mask="url(#b)"
          />
          <path
            d="m14.6998503 1.92079077h-5.74277828v8.72100003h5.30895338c2.9270717 0 5.3106156 2.4786 5.3106156 5.5267 0 3.0464-2.3835439 5.525-5.3106156 5.525h-14.26635783v8.7295h14.70018273c7.689173 0 13.9222898-6.256 13.9222898-14.2545 0-8.00020003-6.2331168-14.24770003-13.9222898-14.24770003"
            fill="#008cb2"
            mask="url(#b)"
          />
          <path
            d="m109.405329 47.4726308h8.749634v-17.017h-8.749634z"
            fill="#008cb2"
            mask="url(#b)"
          />
          <path
            d="m123.506634 12.5930508c-7.785579 0-14.093493 6.4532-14.093493 14.4143h8.749634c0-3.0141 2.398503-5.4655 5.343859-5.4655 2.945355 0 5.343859 2.4514 5.343859 5.4655v20.4663h8.749634v-20.4663c0-7.9611-6.309577-14.4143-14.093493-14.4143"
            fill="#cf3b29"
            mask="url(#b)"
          />
          <path
            d="m104.063132 12.5930508c-7.783916 0-14.0934924 6.4532-14.0934924 14.4143v20.4663h8.7496339v-20.4663c0-3.0141 2.3968415-5.4655 5.3438585-5.4655 2.945356 0 5.343859 2.4514 5.343859 5.4655v3.4493h8.749634v-3.4493c0-7.9611-6.309576-14.4143-14.093493-14.4143"
            fill="#eda645"
            mask="url(#b)"
          />
          <path
            d="m86.7644878 30.4564808h-8.922499c0 4.8178-3.8329513 8.7363-8.5435254 8.7363v9.1256c9.6472027 0 17.4660244-7.9968 17.4660244-17.8619"
            fill="#008cb2"
            mask="url(#b)"
          />
          <path
            d="m77.8409916 30.4564808h8.9224989c0-9.8651-7.8188217-17.8636-17.4643623-17.8636v9.1273c4.7105742 0 8.5418634 3.9185 8.5418634 8.7363"
            fill="#eda645"
            mask="url(#b)"
          />
          <path
            d="m69.2987958 39.1921008c-4.7105742 0-8.5418633-3.9185-8.5418633-8.7363s3.8312891-8.7363 8.5418633-8.7363v-9.1256c-9.6455406 0-17.4660245 7.9968-17.4660245 17.8619s7.8204839 17.8619 17.4660245 17.8619z"
            fill="#cf3b29"
            mask="url(#b)"
          />
          <path
            d="m199.995455 40.5053508c-4.750466 0-8.134633-3.383-8.54685-7.1162h-8.405566c1.318096 8.4694 8.241012 14.9311 16.586739 14.9311 6.854767 0 10.895489-2.6265 13.97548-6.7184l-5.968833-4.8093c-1.984625 2.5806-4.333263 3.7128-7.64097 3.7128"
            fill="#008cb2"
            mask="url(#b)"
          />
          <path
            d="m216.068419 30.7038308c0-9.8311-6.223144-18.1101-16.438807-18.1101-9.288175 0-16.81778 7.9968-16.81778 17.8619 0 .9996.081446 1.9788.231041 2.9342h8.405566 24.624967zm-24.262615-3.859c.711406-3.4714 3.852897-6.4396 7.823808-6.4396 3.972573 0 7.120713 3.094 7.823809 6.4396z"
            fill="#eda645"
            mask="url(#b)"
          />
        </g>
      </svg>
    );
  }
}
