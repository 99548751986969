import React from "react";
import graphql from "babel-plugin-relay/macro";
import { QueryRenderer } from "../../lib/graphql";
import { Check, Clear } from "@material-ui/icons";
import { Tooltip } from "antd";

const NotificationDeliveryIndicatorQuery = graphql`
  query NotificationDeliveryIndicatorQuery($id: String) {
    notifications(id: $id) {
      notification {
        final_delivery_error_message
        final_delivery_status
        time_deployed
      }
    }
  }
`;

export function NotificationDeliveryIndicator({ id }: { id: string }) {
  return (
    <QueryRenderer
      query={NotificationDeliveryIndicatorQuery}
      variables={{
        id,
      }}
      SuccessComponent={(queryResult: {
        notifications: {
          notification: Array<{
            final_delivery_error_message: string;
            final_delivery_status: string;
            time_deployed: string;
          }>;
        };
      }) => {
        const [notification] = queryResult.notifications.notification;
        const {
          final_delivery_error_message,
          final_delivery_status,
          time_deployed,
        } = notification;

        if (!time_deployed) {
          return null;
        }

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ margin: "0px" }}>Delivery Status:</p>
            {final_delivery_status === "delivered" && (
              <Check nativeColor="#6fbc22" />
            )}
            {final_delivery_error_message && (
              <Tooltip title={final_delivery_error_message}>
                <Clear nativeColor="#d02c2f" />
              </Tooltip>
            )}
          </div>
        );
      }}
    />
  );
}
