/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type CadenceOptions = "monthly" | "weekly" | "%future added value";
export type DataInputField = "aa_meeting" | "address" | "employment" | "law_enforcement_contact" | "%future added value";
export type DataInputType = "confirmation" | "verification" | "%future added value";
export type MutationResult = "failure" | "skipped" | "success" | "%future added value";
export type VerificationOptions = "update_or_request" | "with_confirmation" | "%future added value";
export type obligationsAddObligationMutationVariables = {
    readonly participant_id: string;
    readonly activity_id: string;
    readonly title_en?: string | null;
    readonly description_en?: string | null;
    readonly service_provider_id: string;
    readonly sub_address_for_event?: string | null;
    readonly start?: string | null;
    readonly end?: string | null;
    readonly source?: string | null;
};
export type obligationsAddObligationMutationResponse = {
    readonly addObligation: ({
        readonly obligation: ({
            readonly id: string | null;
            readonly title: ({
                readonly en: string | null;
            }) | null;
            readonly description: ({
                readonly en: string | null;
            }) | null;
            readonly activity: ({
                readonly type: string;
                readonly data_input_type: DataInputType | null;
                readonly data_input_field: DataInputField | null;
            }) | null;
            readonly service_provider: ({
                readonly title: string | null;
            }) | null;
            readonly sub_address_for_event: string | null;
            readonly status: string | null;
            readonly compliance: string | null;
            readonly start: string | null;
            readonly end: string | null;
            readonly is_writable: boolean | null;
            readonly cadence_option: CadenceOptions | null;
            readonly day_of_week: number | null;
            readonly day_of_month: number | null;
            readonly verification_option: VerificationOptions | null;
            readonly event_templates: ReadonlyArray<({
                readonly rrule: string | null;
                readonly exdate: string | null;
                readonly events: ReadonlyArray<({
                    readonly id: string | null;
                    readonly date: string | null;
                    readonly activity: {
                        readonly id: string;
                        readonly title: {
                            readonly en: string | null;
                        };
                        readonly type: string;
                    };
                    readonly event_template: ({
                        readonly id: string | null;
                    }) | null;
                    readonly required: boolean | null;
                    readonly service_provider: {
                        readonly address: string | null;
                        readonly phone: string | null;
                        readonly sub_address_for_event: string | null;
                        readonly title: string | null;
                    };
                    readonly attended: string | null;
                    readonly disposition: string | null;
                }) | null> | null;
            }) | null> | null;
        }) | null;
        readonly result: MutationResult;
        readonly description: string | null;
        readonly errors: ReadonlyArray<string | null> | null;
    }) | null;
};
export type obligationsAddObligationMutation = {
    readonly response: obligationsAddObligationMutationResponse;
    readonly variables: obligationsAddObligationMutationVariables;
};



/*
mutation obligationsAddObligationMutation(
  $participant_id: String!
  $activity_id: String!
  $title_en: String
  $description_en: String
  $service_provider_id: String!
  $sub_address_for_event: String
  $start: String
  $end: String
  $source: String
) {
  addObligation(participant_id: $participant_id, activity_id: $activity_id, title_en: $title_en, description_en: $description_en, service_provider_id: $service_provider_id, sub_address_for_event: $sub_address_for_event, start: $start, end: $end, source: $source) {
    obligation {
      id
      title {
        en
      }
      description {
        en
      }
      activity {
        type
        data_input_type
        data_input_field
        id
      }
      service_provider {
        title
        id
      }
      sub_address_for_event
      status
      compliance
      start
      end
      is_writable
      cadence_option
      day_of_week
      day_of_month
      verification_option
      event_templates {
        rrule
        exdate
        events {
          id
          date
          activity {
            id
            title {
              en
            }
            type
          }
          event_template {
            id
          }
          required
          service_provider {
            address
            phone
            sub_address_for_event
            title
            id
          }
          attended
          disposition
        }
        id
      }
    }
    result
    description
    errors
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "participant_id",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "activity_id",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "title_en",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "description_en",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "service_provider_id",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "sub_address_for_event",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "start",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "end",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "source",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "activity_id",
            "variableName": "activity_id"
        } as any),
        ({
            "kind": "Variable",
            "name": "description_en",
            "variableName": "description_en"
        } as any),
        ({
            "kind": "Variable",
            "name": "end",
            "variableName": "end"
        } as any),
        ({
            "kind": "Variable",
            "name": "participant_id",
            "variableName": "participant_id"
        } as any),
        ({
            "kind": "Variable",
            "name": "service_provider_id",
            "variableName": "service_provider_id"
        } as any),
        ({
            "kind": "Variable",
            "name": "source",
            "variableName": "source"
        } as any),
        ({
            "kind": "Variable",
            "name": "start",
            "variableName": "start"
        } as any),
        ({
            "kind": "Variable",
            "name": "sub_address_for_event",
            "variableName": "sub_address_for_event"
        } as any),
        ({
            "kind": "Variable",
            "name": "title_en",
            "variableName": "title_en"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "en",
            "args": null,
            "storageKey": null
        } as any)
    ], v4 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "title",
        "storageKey": null,
        "args": null,
        "concreteType": "i18nText",
        "plural": false,
        "selections": (v3 /*: any*/)
    } as any), v5 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "description",
        "storageKey": null,
        "args": null,
        "concreteType": "i18nText",
        "plural": false,
        "selections": (v3 /*: any*/)
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "type",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "data_input_type",
        "args": null,
        "storageKey": null
    } as any), v8 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "data_input_field",
        "args": null,
        "storageKey": null
    } as any), v9 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "title",
        "args": null,
        "storageKey": null
    } as any), v10 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "sub_address_for_event",
        "args": null,
        "storageKey": null
    } as any), v11 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
    } as any), v12 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "compliance",
        "args": null,
        "storageKey": null
    } as any), v13 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "start",
        "args": null,
        "storageKey": null
    } as any), v14 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "end",
        "args": null,
        "storageKey": null
    } as any), v15 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "is_writable",
        "args": null,
        "storageKey": null
    } as any), v16 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "cadence_option",
        "args": null,
        "storageKey": null
    } as any), v17 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "day_of_week",
        "args": null,
        "storageKey": null
    } as any), v18 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "day_of_month",
        "args": null,
        "storageKey": null
    } as any), v19 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "verification_option",
        "args": null,
        "storageKey": null
    } as any), v20 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "rrule",
        "args": null,
        "storageKey": null
    } as any), v21 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "exdate",
        "args": null,
        "storageKey": null
    } as any), v22 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "date",
        "args": null,
        "storageKey": null
    } as any), v23 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "activity",
        "storageKey": null,
        "args": null,
        "concreteType": "Activity",
        "plural": false,
        "selections": [
            (v2 /*: any*/),
            (v4 /*: any*/),
            (v6 /*: any*/)
        ]
    } as any), v24 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "event_template",
        "storageKey": null,
        "args": null,
        "concreteType": "EventTemplate",
        "plural": false,
        "selections": [
            (v2 /*: any*/)
        ]
    } as any), v25 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "required",
        "args": null,
        "storageKey": null
    } as any), v26 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "address",
        "args": null,
        "storageKey": null
    } as any), v27 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "phone",
        "args": null,
        "storageKey": null
    } as any), v28 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "attended",
        "args": null,
        "storageKey": null
    } as any), v29 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "disposition",
        "args": null,
        "storageKey": null
    } as any), v30 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "result",
        "args": null,
        "storageKey": null
    } as any), v31 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "description",
        "args": null,
        "storageKey": null
    } as any), v32 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "errors",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "obligationsAddObligationMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "addObligation",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "NewObligation",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "obligation",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Obligation",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "activity",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Activity",
                                    "plural": false,
                                    "selections": [
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        (v8 /*: any*/)
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "service_provider",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ServiceProvider",
                                    "plural": false,
                                    "selections": [
                                        (v9 /*: any*/)
                                    ]
                                },
                                (v10 /*: any*/),
                                (v11 /*: any*/),
                                (v12 /*: any*/),
                                (v13 /*: any*/),
                                (v14 /*: any*/),
                                (v15 /*: any*/),
                                (v16 /*: any*/),
                                (v17 /*: any*/),
                                (v18 /*: any*/),
                                (v19 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "event_templates",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "EventTemplate",
                                    "plural": true,
                                    "selections": [
                                        (v20 /*: any*/),
                                        (v21 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "events",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "Event",
                                            "plural": true,
                                            "selections": [
                                                (v2 /*: any*/),
                                                (v22 /*: any*/),
                                                (v23 /*: any*/),
                                                (v24 /*: any*/),
                                                (v25 /*: any*/),
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "service_provider",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "ServiceProvider",
                                                    "plural": false,
                                                    "selections": [
                                                        (v26 /*: any*/),
                                                        (v27 /*: any*/),
                                                        (v10 /*: any*/),
                                                        (v9 /*: any*/)
                                                    ]
                                                },
                                                (v28 /*: any*/),
                                                (v29 /*: any*/)
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        (v30 /*: any*/),
                        (v31 /*: any*/),
                        (v32 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "obligationsAddObligationMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "addObligation",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "NewObligation",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "obligation",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Obligation",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "activity",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Activity",
                                    "plural": false,
                                    "selections": [
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        (v8 /*: any*/),
                                        (v2 /*: any*/)
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "service_provider",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ServiceProvider",
                                    "plural": false,
                                    "selections": [
                                        (v9 /*: any*/),
                                        (v2 /*: any*/)
                                    ]
                                },
                                (v10 /*: any*/),
                                (v11 /*: any*/),
                                (v12 /*: any*/),
                                (v13 /*: any*/),
                                (v14 /*: any*/),
                                (v15 /*: any*/),
                                (v16 /*: any*/),
                                (v17 /*: any*/),
                                (v18 /*: any*/),
                                (v19 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "event_templates",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "EventTemplate",
                                    "plural": true,
                                    "selections": [
                                        (v20 /*: any*/),
                                        (v21 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "events",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "Event",
                                            "plural": true,
                                            "selections": [
                                                (v2 /*: any*/),
                                                (v22 /*: any*/),
                                                (v23 /*: any*/),
                                                (v24 /*: any*/),
                                                (v25 /*: any*/),
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "service_provider",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "ServiceProvider",
                                                    "plural": false,
                                                    "selections": [
                                                        (v26 /*: any*/),
                                                        (v27 /*: any*/),
                                                        (v10 /*: any*/),
                                                        (v9 /*: any*/),
                                                        (v2 /*: any*/)
                                                    ]
                                                },
                                                (v28 /*: any*/),
                                                (v29 /*: any*/)
                                            ]
                                        },
                                        (v2 /*: any*/)
                                    ]
                                }
                            ]
                        },
                        (v30 /*: any*/),
                        (v31 /*: any*/),
                        (v32 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "mutation",
            "name": "obligationsAddObligationMutation",
            "id": null,
            "text": "mutation obligationsAddObligationMutation(\n  $participant_id: String!\n  $activity_id: String!\n  $title_en: String\n  $description_en: String\n  $service_provider_id: String!\n  $sub_address_for_event: String\n  $start: String\n  $end: String\n  $source: String\n) {\n  addObligation(participant_id: $participant_id, activity_id: $activity_id, title_en: $title_en, description_en: $description_en, service_provider_id: $service_provider_id, sub_address_for_event: $sub_address_for_event, start: $start, end: $end, source: $source) {\n    obligation {\n      id\n      title {\n        en\n      }\n      description {\n        en\n      }\n      activity {\n        type\n        data_input_type\n        data_input_field\n        id\n      }\n      service_provider {\n        title\n        id\n      }\n      sub_address_for_event\n      status\n      compliance\n      start\n      end\n      is_writable\n      cadence_option\n      day_of_week\n      day_of_month\n      verification_option\n      event_templates {\n        rrule\n        exdate\n        events {\n          id\n          date\n          activity {\n            id\n            title {\n              en\n            }\n            type\n          }\n          event_template {\n            id\n          }\n          required\n          service_provider {\n            address\n            phone\n            sub_address_for_event\n            title\n            id\n          }\n          attended\n          disposition\n        }\n        id\n      }\n    }\n    result\n    description\n    errors\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '80911f160c71cf533b3862e88bb51c8d';
export default node;
