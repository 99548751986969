import {
  Environment,
  FetchFunction,
  Network,
  QueryResponseCache,
  RecordSource,
  RelayNetwork,
  Store,
} from "relay-runtime";
import { installRelayDevTools } from "relay-devtools";
import { buildFetchQuery } from "./defaultRelayCache";

export class ConfiguredEnvironment {
  recordSource: RecordSource;
  store: Store;
  network: RelayNetwork;
  environment: Environment;

  constructor(
    queryFetcher?: FetchFunction,
    responseCache?: QueryResponseCache
  ) {
    this.recordSource = new RecordSource();
    this.store = new Store(this.recordSource);
    this.network = Network.create(
      queryFetcher || buildFetchQuery(responseCache)
    );

    // enable relay-devtools if we're in a dev build
    if (
      process &&
      process.env &&
      process.env.NODE_ENV &&
      process.env.NODE_ENV !== "production"
    ) {
      installRelayDevTools();
    }

    this.environment = new Environment({
      network: this.network,
      store: this.store,
    });
  }

  get relayEnvironment() {
    return this.environment;
  }
}

// setting this immediately confuses Relay dev tools, for dx, only build on request
let UncachedConfiguredEnvironment: ConfiguredEnvironment = undefined as any;

export const getUncachedConfiguredEnvironment = (): ConfiguredEnvironment => {
  if (!UncachedConfiguredEnvironment) {
    UncachedConfiguredEnvironment = new ConfiguredEnvironment(
      buildFetchQuery(null as any)
    );
  }
  return UncachedConfiguredEnvironment;
};

export default new ConfiguredEnvironment();
