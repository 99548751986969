import React from "react";
import { Form } from "react-bulma-components";
import { ValidationItemStates } from "./types";
import { FormLabelWithToolTip } from "../../shared/components/elements/FormLabelWithToolTip";

interface Props {
  label: String;
  validations?: any;
  value: String;
  name: string;
  placeholder?: string;
  inputType: string;
  attemptedSubmission: Boolean;
  handleModalFormInputChange: Function;
  onBlur?: Function;
  disabled?: Boolean;
  textArea?: Boolean;
  toolTipText?: string;
}

const ModalInput = ({
  label,
  validations,
  value,
  name,
  placeholder,
  inputType,
  attemptedSubmission,
  handleModalFormInputChange,
  onBlur,
  toolTipText,
  disabled = false,
  textArea = false,
}: Props) => {
  return (
    <Form.Field>
      <FormLabelWithToolTip labelText={label as string} tipText={toolTipText} />
      <Form.Control>
        {textArea ? (
          <Form.Textarea
            color={
              validations && validations[name].errors.length > 0
                ? "danger"
                : null
            }
            type="text"
            placeholder={placeholder}
            name={name}
            value={value}
            disabled={disabled}
            data-input-type={inputType}
            onChange={(event: any) => {
              handleModalFormInputChange(event);
            }}
            onBlur={() => {
              if (typeof onBlur === "function") onBlur(name, value);
            }}
          />
        ) : (
          <Form.Input
            color={
              validations && validations[name].errors.length > 0
                ? "danger"
                : null
            }
            type="text"
            placeholder={placeholder}
            name={name}
            value={value}
            disabled={disabled}
            data-input-type={inputType}
            onChange={(event: any) => {
              handleModalFormInputChange(event);
            }}
            onBlur={() => {
              if (typeof onBlur === "function") onBlur(name, value);
            }}
          />
        )}
      </Form.Control>
      {validations && validations[name].errors.length > 0 ? (
        <Form.Help color="danger">{validations[name].errors[0]}</Form.Help>
      ) : null}
      {validations &&
      validations[name].state === ValidationItemStates.pristine &&
      attemptedSubmission ? (
        <Form.Help color="danger">{"Field is required"}</Form.Help>
      ) : null}
    </Form.Field>
  );
};

export default ModalInput;
