/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type BroadcastMessageRecipientType = "activity" | "all" | "caseload" | "program" | "risk_level" | "risk_level_by_sex" | "%future added value";
export type MutationResult = "failure" | "skipped" | "success" | "%future added value";
export type BroadcastMessageRecipientInput = {
    readonly queryType: BroadcastMessageRecipientType;
    readonly arg?: string | null;
};
export type broadcastMessagesAddMutationVariables = {
    readonly text: string;
    readonly to: BroadcastMessageRecipientInput;
    readonly time_to_deploy?: string | null;
};
export type broadcastMessagesAddMutationResponse = {
    readonly addBroadcastMessageFromCaseManager: ({
        readonly result: MutationResult;
        readonly description: string | null;
        readonly errors: ReadonlyArray<string | null> | null;
        readonly broadcast_message: ({
            readonly id: string;
            readonly created_at: string;
            readonly deployed_at: string | null;
            readonly deployed: boolean;
            readonly scheduled: boolean;
            readonly text: string;
            readonly to: {
                readonly queryType: BroadcastMessageRecipientType;
                readonly arg: string | null;
            };
            readonly case_manager: ({
                readonly name: {
                    readonly first: string;
                    readonly last: string | null;
                };
            }) | null;
            readonly time_to_deploy: string | null;
        }) | null;
    }) | null;
};
export type broadcastMessagesAddMutation = {
    readonly response: broadcastMessagesAddMutationResponse;
    readonly variables: broadcastMessagesAddMutationVariables;
};



/*
mutation broadcastMessagesAddMutation(
  $text: String!
  $to: BroadcastMessageRecipientInput!
  $time_to_deploy: String
) {
  addBroadcastMessageFromCaseManager(text: $text, to: $to, time_to_deploy: $time_to_deploy) {
    result
    description
    errors
    broadcast_message {
      id
      created_at
      deployed_at
      deployed
      scheduled
      text
      to {
        queryType
        arg
      }
      case_manager {
        name {
          first
          last
        }
        id
      }
      time_to_deploy
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "text",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "to",
            "type": "BroadcastMessageRecipientInput!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "time_to_deploy",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "text",
            "variableName": "text"
        } as any),
        ({
            "kind": "Variable",
            "name": "time_to_deploy",
            "variableName": "time_to_deploy"
        } as any),
        ({
            "kind": "Variable",
            "name": "to",
            "variableName": "to"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "result",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "description",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "errors",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "created_at",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "deployed_at",
        "args": null,
        "storageKey": null
    } as any), v8 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "deployed",
        "args": null,
        "storageKey": null
    } as any), v9 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "scheduled",
        "args": null,
        "storageKey": null
    } as any), v10 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "text",
        "args": null,
        "storageKey": null
    } as any), v11 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "to",
        "storageKey": null,
        "args": null,
        "concreteType": "BroadcastMessageRecipient",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "queryType",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "arg",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v12 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "name",
        "storageKey": null,
        "args": null,
        "concreteType": "PersonName",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "first",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "last",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v13 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "time_to_deploy",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "broadcastMessagesAddMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "addBroadcastMessageFromCaseManager",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "NewBroadcastMessage",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "broadcast_message",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BroadcastMessage",
                            "plural": false,
                            "selections": [
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                (v9 /*: any*/),
                                (v10 /*: any*/),
                                (v11 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "case_manager",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "CaseManager",
                                    "plural": false,
                                    "selections": [
                                        (v12 /*: any*/)
                                    ]
                                },
                                (v13 /*: any*/)
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "broadcastMessagesAddMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "addBroadcastMessageFromCaseManager",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "NewBroadcastMessage",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "broadcast_message",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BroadcastMessage",
                            "plural": false,
                            "selections": [
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                (v9 /*: any*/),
                                (v10 /*: any*/),
                                (v11 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "case_manager",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "CaseManager",
                                    "plural": false,
                                    "selections": [
                                        (v12 /*: any*/),
                                        (v5 /*: any*/)
                                    ]
                                },
                                (v13 /*: any*/)
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "mutation",
            "name": "broadcastMessagesAddMutation",
            "id": null,
            "text": "mutation broadcastMessagesAddMutation(\n  $text: String!\n  $to: BroadcastMessageRecipientInput!\n  $time_to_deploy: String\n) {\n  addBroadcastMessageFromCaseManager(text: $text, to: $to, time_to_deploy: $time_to_deploy) {\n    result\n    description\n    errors\n    broadcast_message {\n      id\n      created_at\n      deployed_at\n      deployed\n      scheduled\n      text\n      to {\n        queryType\n        arg\n      }\n      case_manager {\n        name {\n          first\n          last\n        }\n        id\n      }\n      time_to_deploy\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '2a81613b139315512cdc03cd867c53e0';
export default node;
