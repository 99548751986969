import * as React from "react";

import { wrapComponent } from "../types";

export type UserType = {
  displayName: string;
  email: string;
  photoUrl: string;
  uid: string;
};

export type UserContextType = {
  user?: UserType;
  logout: (evt: any) => Promise<void>;
};

export type WithUserProps = {
  userContext: UserContextType;
};

const UserContext = React.createContext<UserContextType>({} as any);

export const UserProvider = UserContext.Provider;
export const UserConsumer = UserContext.Consumer;

export type withUserFunc = <TCall, TWrapped extends WithUserProps>(
  WrappedComponent: React.ComponentType<TWrapped>
) => React.ComponentClass<TCall>;

export const withUser: withUserFunc = function <
  TCall,
  TWrapped extends WithUserProps
>(WrappedComponent: React.ComponentType<TWrapped>) {
  const C = class WithUser extends React.PureComponent<TCall> {
    render() {
      return (
        <UserContext.Consumer>
          {(userContext) => (
            <WrappedComponent userContext={userContext} {...this.props} />
          )}
        </UserContext.Consumer>
      );
    }
  };

  return wrapComponent(C, WrappedComponent, "withUser");
};
