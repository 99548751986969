import React, { PureComponent } from "react";
import idx from "idx.macro";
import graphql from "babel-plugin-relay/macro";
import { Tag } from "react-bulma-components";

import { QueryRenderer } from "../../shared/lib/graphql/index";

import { getFeatureFlags } from "../../featureFlags";

const { clientDisplayTerm } = getFeatureFlags();

interface Props {
  cmId?: string;
  showActive?: boolean;
}

interface ParticipantId {
  id: string;
}

interface Participant {
  participant: ParticipantId[];
}

interface ParticipantIds {
  participants: Participant;
}

export class ParticipantCount extends PureComponent<Props> {
  render() {
    const { cmId, showActive } = this.props;
    return (
      <>
        <QueryRenderer
          query={graphql`
            query ParticipantCountSecondaryQuery(
              $cmId: String
              $showActive: Boolean
            ) {
              participants(
                secondary_case_manager_id: $cmId
                active_clients_only: $showActive
              ) {
                participant {
                  id
                }
              }
            }
          `}
          variables={{
            cmId,
            showActive: showActive,
          }}
          SuccessComponent={(secondaryCmResult: ParticipantIds) => {
            const secondaryParticipants =
              idx(secondaryCmResult, (_) => _.participants.participant) || [];
            return (
              <QueryRenderer
                query={graphql`
                  query ParticipantCountQuery(
                    $cmId: String
                    $showActive: Boolean
                  ) {
                    participants(
                      case_manager_id: $cmId
                      active_clients_only: $showActive
                    ) {
                      participant {
                        id
                      }
                    }
                  }
                `}
                variables={{
                  cmId,
                  showActive: showActive,
                }}
                SuccessComponent={(primaryResult: ParticipantIds) => {
                  const primaryParticipants =
                    idx(primaryResult, (_) => _.participants.participant) || [];

                  const participants = [
                    ...primaryParticipants,
                    ...secondaryParticipants,
                  ];
                  const clientDisplayPluralTerm = `${clientDisplayTerm}${
                    participants.length !== 1 ? "s" : ""
                  }`;

                  if (cmId) {
                    return (
                      <Tag style={{ marginTop: 6, marginLeft: 10 }}>
                        {`${participants.length} ${clientDisplayPluralTerm}`}
                      </Tag>
                    );
                  } else {
                    return (
                      <Tag style={{ marginTop: 6, marginLeft: 10 }}>
                        {`${primaryParticipants.length} ${clientDisplayPluralTerm} (Total)`}
                      </Tag>
                    );
                  }
                }}
              />
            );
          }}
        />
      </>
    );
  }
}
