import * as React from "react";
import { initializeFirebase } from "./shared/lib/auth/firebase";
import { UserProvider, UserType } from "./shared/lib/contexts/UserContext";
import { QueryRenderer } from "./shared/lib/graphql";
import LoginController, { logout } from "./LoginController";
import { AppContents } from "./AppContents";
import { AppGql, AppGqlResponse } from "./AppGql";
import { setCattFeatureFlags } from "./featureFlags";
import { CattFeatureFlagDefs } from "./shared/lib/types";
import "antd/dist/antd.css";

type Props = {
  project?: string;
  sessionExpiryInMinutes?: number;
};

type State = {
  firebaseProject?: string;
  user?: UserType;
};

const verifyLoginUrl = `/graphql/verifyLogin`;

class App extends React.Component<Props, State> {
  state = {
    firebaseProject: (undefined as any) as string,
    user: (undefined as any) as UserType,
  };

  componentDidMount() {
    const firebaseProject = initializeFirebase(this.props.project);
    this.setState({ firebaseProject });
  }

  render() {
    const { user } = this.state;
    return (
      <div className="App">
        {this.state.firebaseProject && (
          <LoginController
            setUser={this._setUser}
            firebaseProject={this.state.firebaseProject}
            validationUrl={verifyLoginUrl}
          >
            <QueryRenderer
              query={AppGql}
              SuccessComponent={(props: AppGqlResponse) => {
                let userContextValue = user;
                if (props.me && props.me.case_manager) {
                  userContextValue = { ...user, uid: props.me.case_manager.id };
                }
                setCattFeatureFlags(
                  props.application.flags.all as CattFeatureFlagDefs
                );
                return (
                  <UserProvider
                    value={{ logout: this._logout, user: userContextValue }}
                  >
                    <AppContents
                      appInfo={props}
                      zendeskToken={
                        props.me ? props.me.zendesk.token : undefined
                      }
                    />
                  </UserProvider>
                );
              }}
            />
          </LoginController>
        )}
      </div>
    );
  }

  _logout = async (evt: React.SyntheticEvent<any>) => {
    evt.preventDefault();
    await logout();
    this.setState({ user: undefined });
  };

  _setUser = (user: UserType) => this.setState({ user });
}

export default App;
