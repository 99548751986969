import React from "react";
import { Descriptions } from "antd";
import { AntdFieldState } from "./CreateClass";
import { Activity, ServiceProvider, Participant } from "../../graphql-types";
import { Moment } from "moment";

interface Props {
  activity: AntdFieldState;
  startDate: AntdFieldState;
  startTime: AntdFieldState;
  endDate: AntdFieldState;
  location: AntdFieldState;
  serviceProvidersDictionary: { [key: string]: ServiceProvider };
  activitiesDictionary: { [key: string]: Activity };
  participantsDictionary: { [key: string]: Participant };
  daysOfWeek: AntdFieldState;
  selectedParticipants: AntdFieldState;
  excludeDates: Moment[];
}

export function StepTwo(props: Props) {
  const {
    participantsDictionary,
    activitiesDictionary,
    serviceProvidersDictionary,
    excludeDates,
  } = props;
  const startDate = props.startDate.value as Moment;
  const startTime = props.startTime.value as Moment;
  const endDate = props.endDate.value as Moment;
  const daysOfWeek = props.daysOfWeek.value as string[];
  const selectedParticipants = props.selectedParticipants.value as string[];
  const activity = props.activity.value as string;
  const location = props.location.value as string;

  return (
    <div className="class-form-content">
      <Descriptions title="New Class Summary">
        <Descriptions.Item label="Activity">
          {activitiesDictionary[activity].title.en}
        </Descriptions.Item>
        <Descriptions.Item label="Days of week">
          {daysOfWeek.join(", ")}
        </Descriptions.Item>
        <Descriptions.Item label="Start Date">
          {startDate.format("MM/DD/YYYY")}
        </Descriptions.Item>
        <Descriptions.Item label="Start Time">
          {startTime.format("h:mm a")}
        </Descriptions.Item>
        <Descriptions.Item label="End Date">
          {endDate.format("MM/DD/YYYY")}
        </Descriptions.Item>
        <Descriptions.Item label="Exclude dates">
          {excludeDates
            .map((excludeDate) => excludeDate.format("MM/DD/YYYY"))
            .join(", ")}
        </Descriptions.Item>
        <Descriptions.Item label="Service Provider Name">
          {serviceProvidersDictionary[location].title}
        </Descriptions.Item>
        <Descriptions.Item label="Service Provider Address">
          {serviceProvidersDictionary[location].address}
        </Descriptions.Item>
        <Descriptions.Item label="Participants">
          {selectedParticipants
            .map((selectedParticipantId) => {
              const {
                name: { first, last },
              } = participantsDictionary[selectedParticipantId];
              return `${first} ${last}`;
            })
            .join(", ")}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
