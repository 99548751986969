import * as React from "react";
import GroupChat from "./GroupChat";
import graphql from "babel-plugin-relay/macro";
import { QueryRenderer } from "../../shared/lib/graphql";

interface Props {}

interface State {}

interface GroupChatContainerData {
  person_groups: {
    person_group: Array<{ id: string; label: string }>;
  };
}

export default class GroupChatContainer extends React.Component<Props, State> {
  state = {};

  render() {
    // For now we just support one group
    return (
      <QueryRenderer
        query={graphql`
          query GroupChatContainerQuery {
            person_groups(page_size: 1) {
              person_group {
                id
                label
              }
            }
          }
        `}
        SuccessComponent={(props: GroupChatContainerData) => {
          const groups = props.person_groups.person_group;

          if (groups.length > 0) {
            return <GroupChat groupId={groups[0].id} />;
          } else {
            return <div>Sorry you have no group chats</div>;
          }
        }}
      />
    );
  }
}
