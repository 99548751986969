import * as React from "react";
import { Form } from "react-bulma-components";
import classnames from "classnames";

import { KeyedString } from "../../lib/graphql/flowTypes";

export type SelectGroupedOptions = { [key: string]: Array<KeyedString> };

interface Props {
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  groupedOptions: SelectGroupedOptions;
  disabled?: boolean;
  forcedInitialText?: string;
}

const getClassName = (key: string) =>
  `obligation-select-option obligation-select-option-${key
    .toLowerCase()
    .replace(/[\b\s]+/, "-")}`;

export default class ObligationSelect extends React.PureComponent<Props> {
  _mapOptions = (
    options: Array<KeyedString> | undefined
  ): JSX.Element[] | undefined =>
    options &&
    options.map(({ key, value }) => (
      <option key={key} value={key} className={getClassName(key)}>
        {value}
      </option>
    ));

  _renderOptions(): JSX.Element[] | undefined {
    const { groupedOptions } = this.props;
    return Object.keys(groupedOptions).map((key) => (
      <optgroup label={key} key={key}>
        {this._mapOptions(groupedOptions[key])}
      </optgroup>
    ));
  }

  render() {
    const { forcedInitialText, onChange, disabled } = this.props;
    const optionsToRender = this._renderOptions();

    const selectClassname = classnames(
      "obligation-select",
      disabled ? "disabled" : null
    );

    return (
      <Form.Field>
        <Form.Select
          className={selectClassname}
          onChange={onChange}
          value={forcedInitialText}
          disabled={disabled}
          {
            ...({
              "data-class": "obligation-select-element ",
            } as any) /* extra props get spread onto the child <select>  */
          }
        >
          {/*
            forcedInitialText allow this to behave a bit like a button e.g. "Add Obligation"
          */}
          {forcedInitialText && (
            <option disabled hidden value={forcedInitialText}>
              {forcedInitialText}
            </option>
          )}
          {optionsToRender}
        </Form.Select>
      </Form.Field>
    );
  }
}
