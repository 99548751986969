/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type MutationResult = "failure" | "skipped" | "success" | "%future added value";
export type tasksRemoveParticipantTaskMutationVariables = {
    readonly participant_id: string;
    readonly task_id: string;
};
export type tasksRemoveParticipantTaskMutationResponse = {
    readonly removeParticipantTask: ({
        readonly task: ({
            readonly id: string;
            readonly label: string | null;
            readonly completed_at: string | null;
            readonly created_at: string;
        }) | null;
        readonly result: MutationResult;
        readonly description: string | null;
        readonly errors: ReadonlyArray<string | null> | null;
    }) | null;
};
export type tasksRemoveParticipantTaskMutation = {
    readonly response: tasksRemoveParticipantTaskMutationResponse;
    readonly variables: tasksRemoveParticipantTaskMutationVariables;
};



/*
mutation tasksRemoveParticipantTaskMutation(
  $participant_id: String!
  $task_id: String!
) {
  removeParticipantTask(participant_id: $participant_id, task_id: $task_id) {
    task {
      id
      label
      completed_at
      created_at
    }
    result
    description
    errors
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "participant_id",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "task_id",
            "type": "String!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "removeParticipantTask",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "participant_id",
                    "variableName": "participant_id"
                },
                {
                    "kind": "Variable",
                    "name": "task_id",
                    "variableName": "task_id"
                }
            ],
            "concreteType": "NewParticipantTask",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "task",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ParticipantTask",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "label",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "completed_at",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "created_at",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "result",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "errors",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "tasksRemoveParticipantTaskMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "tasksRemoveParticipantTaskMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "tasksRemoveParticipantTaskMutation",
            "id": null,
            "text": "mutation tasksRemoveParticipantTaskMutation(\n  $participant_id: String!\n  $task_id: String!\n) {\n  removeParticipantTask(participant_id: $participant_id, task_id: $task_id) {\n    task {\n      id\n      label\n      completed_at\n      created_at\n    }\n    result\n    description\n    errors\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '6e38e0317ddcb1086f5c4c0b8a331609';
export default node;
