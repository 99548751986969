import React from "react";
import { Form } from "react-bulma-components";
import { Tooltip } from "antd";
import "./antd-tooltip.css";
import { InfoOutlined } from "@material-ui/icons";

type Props = {
  tipText?: string;
  labelText: string;
};

export const FormLabelWithToolTip = ({ tipText, labelText }: Props) =>
  tipText ? (
    <Form.Label>
      <Tooltip title={tipText}>
        {labelText}
        <InfoOutlined
          style={{
            fontSize: 14,
            top: 2,
            position: "relative",
            left: 3,
          }}
        />
      </Tooltip>
    </Form.Label>
  ) : (
    <Form.Label>{labelText}</Form.Label>
  );
