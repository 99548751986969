import React, { Component } from "react";
import idx from "idx.macro";
import {
  KeyedString,
  QueryRenderer,
  NOT_SET,
} from "../../../shared/lib/graphql/index";
import graphql from "babel-plugin-relay/macro";
import ChangeParticipantActiveStatusModal, {
  ChangeParticipantActivePropsExternal,
} from "./ChangeParticipantActiveStatusModal";
import { ChangeParticipantIsActiveContext } from "../../../utils/context";
import { Operation } from "../types";
import { getFeatureFlags } from "../../../featureFlags";

const {
  deactivation: { allowDeactivation },
} = getFeatureFlags();

interface ChangeParticipantActiveStatusGqlResponse {
  application: {
    deactivate_reasons: KeyedString[];
    reactivate_reasons: KeyedString[];
  };
}

interface Props extends ChangeParticipantActivePropsExternal {
  operation: Operation;
  type: string;
  buttonComponent?: React.ReactElement<any>;
}

class ChangeParticipantActiveQueryWrapper extends Component<Props> {
  render() {
    if (!allowDeactivation) return null;

    const { operation, type, participant } = this.props;

    return (
      <QueryRenderer
        query={graphql`
          query ChangeParticipantActiveStatusQueryWrapperQuery {
            application {
              deactivate_reasons {
                key
                value
              }
              reactivate_reasons {
                key
                value
              }
            }
          }
        `}
        SuccessComponent={(props: ChangeParticipantActiveStatusGqlResponse) => {
          const deactivateReasons =
            idx(props, (_) => _.application.deactivate_reasons) || [];
          const reactivateReasons =
            idx(props, (_) => _.application.reactivate_reasons) || [];

          const deactivateReasonsSerialized: KeyedString[] = [
            {
              key: NOT_SET,
              value: `Select a reason...`,
            } as KeyedString,
          ].concat(deactivateReasons);

          const reactivateReasonsSerialized: KeyedString[] = [
            {
              key: NOT_SET,
              value: `Select a reason...`,
            } as KeyedString,
          ].concat(reactivateReasons);
          const isActive =
            participant && participant.is_active
              ? participant.is_active
              : false;
          return (
            <>
              {reactivateReasonsSerialized &&
                reactivateReasonsSerialized.length > 0 && (
                  <ChangeParticipantIsActiveContext.Provider
                    value={{
                      deactivateReasonsSerialized,
                      reactivateReasonsSerialized,
                      isActive: isActive,
                    }}
                  >
                    <ChangeParticipantActiveStatusModal
                      operation={operation}
                      type={type}
                      callback={this.props.callback}
                      participant={participant}
                      metaDataObject={{
                        deactivateReasonsSerialized,
                        reactivateReasonsSerialized,
                        isActive,
                      }}
                      buttonComponent={this.props.buttonComponent}
                    />
                  </ChangeParticipantIsActiveContext.Provider>
                )}
            </>
          );
        }}
      />
    );
  }
}

export default ChangeParticipantActiveQueryWrapper;
