import React, { useState, useReducer } from "react";
import { Columns, Form } from "react-bulma-components";
import { Button } from "antd";
import DatePicker from "react-datepicker";
import { Moment } from "moment";
import { Maybe } from "../../graphql-types";
import { ParticipantFormCellOptions } from "./participantDetailsFormData";
import { Create } from "@material-ui/icons";
import { arrayToDictionarySpecifyKeyValue } from "../../shared/lib/modalFormHelpers";

interface ParticipantFormCellProps {
  label: string;
  size: number;
  value: Maybe<string | Moment>;
  type: string;
  fieldName: string;
  onSave: (
    fieldname: string,
    data: string | { [key: string]: boolean },
    storageType?: StorageType
  ) => void;
  options?: ParticipantFormCellOptions[];
  onEditCallback: (fieldname: string) => void;
  selectedEditField: string;
  storageType?: StorageType;
}

export enum StorageType {
  Array = "array",
  Object = "object",
  None = "none",
}

export function ParticipantFormCell(props: ParticipantFormCellProps) {
  const {
    label,
    size,
    value,
    type,
    fieldName,
    onSave,
    options,
    onEditCallback,
    selectedEditField,
    storageType,
  } = props;

  const optionLabelToValueDictionary = options
    ? arrayToDictionarySpecifyKeyValue<ParticipantFormCellOptions>(
        options,
        "label",
        "value"
      )
    : {};

  const optionValueToLabelDictionary = options
    ? arrayToDictionarySpecifyKeyValue<ParticipantFormCellOptions>(
        options,
        "value",
        "label"
      )
    : {};

  const [inputValue, setInputValue] = useState(
    type === "select" && value
      ? optionValueToLabelDictionary[(value as any).toString()]
      : value
  );
  const [dateValue, setDateValue] = useState(
    typeof value === "string" || !value ? null : value
  );

  const isEditable = selectedEditField === fieldName;

  const initialOptionsValues = options
    ? options.reduce(
        (acc: { [key: string]: string | boolean }, { fieldname, value }) => {
          acc[fieldname] = value;
          return acc;
        },
        {}
      )
    : {};

  const [optionsValues, optionDispatch] = useReducer((state, action) => {
    if (!action) {
      return {
        ...initialOptionsValues,
      };
    }

    return {
      ...state,
      [action]: !state[action],
    };
  }, initialOptionsValues);

  const handleCancelButtonClicked = () => {
    onEditCallback("");
    setInputValue(value);
    optionDispatch("");
  };

  const handleEditButtonClicked = () => {
    if (!isEditable) {
      onEditCallback(fieldName);
    }
  };

  const handleSaveButtonClicked = async () => {
    if (
      !inputValue &&
      (type === "input" || type === "select" || type === "textarea")
    ) {
      return;
    }

    if (
      type === "input" ||
      (type === "textarea" && typeof inputValue === "string")
    ) {
      onSave(fieldName, inputValue);
    }

    if (type === "date" && dateValue) {
      onSave(fieldName, dateValue.toISOString());
    }

    if (type === "checkbox") {
      onSave(fieldName, optionsValues, storageType || StorageType.None);
    }

    if (type === "select" && inputValue && typeof inputValue === "string") {
      const value = optionLabelToValueDictionary[inputValue];
      onSave(fieldName, value);
    }
    onEditCallback("");
  };

  const handleFormInputChange = (event: any) => {
    const { value } = event.target;
    setInputValue(value);
  };

  const handleFormDatePickerChange = (date: Moment) => {
    setDateValue(date);
  };

  const valueFormatted =
    type === "date"
      ? dateValue
        ? dateValue.format("MM/DD/YYYY")
        : null
      : inputValue;

  return (
    <Columns.Column
      size={size as any}
      className={type === "textarea" ? "" : "participant-details-column"}
    >
      <Form.Field
        onClick={handleEditButtonClicked}
        className={type === "textarea" ? "participant-details-textarea" : ""}
      >
        <Form.Label color="grey" style={{ marginBottom: "0px" }}>
          {` ${label}`}
        </Form.Label>

        <Form.Control>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              margin: "0px",
            }}
          >
            {isEditable && type === "input" && (
              <Form.Input
                onChange={handleFormInputChange}
                value={inputValue}
              ></Form.Input>
            )}

            {isEditable && type === "date" && (
              <DatePicker
                className="react-datepicker-input"
                onChange={handleFormDatePickerChange}
                selected={dateValue}
              />
            )}

            {!isEditable && (type === "input" || type === "date") && (
              <p>{valueFormatted}</p>
            )}

            {type === "checkbox" && options && (
              <Form.Control>
                {options.map(({ label, fieldname }) => (
                  <Form.Checkbox
                    disabled={!isEditable}
                    checked={optionsValues[fieldname]}
                    onChange={() => optionDispatch(fieldname)}
                    key={fieldname}
                    style={{ marginRight: "10px" }}
                  >
                    {` ${label}`}
                  </Form.Checkbox>
                ))}
              </Form.Control>
            )}

            {type === "select" && options && (
              <Form.Control>
                <Form.Select
                  onChange={handleFormInputChange}
                  disabled={!isEditable}
                  name={fieldName}
                  value={inputValue}
                >
                  {options.map(({ label }) => (
                    <option key={label}>{label}</option>
                  ))}
                </Form.Select>
              </Form.Control>
            )}

            {type === "textarea" && (
              <Form.Control style={{ width: "100%" }}>
                <Form.Textarea
                  onChange={handleFormInputChange}
                  disabled={!isEditable}
                  name={fieldName}
                  value={inputValue}
                />
              </Form.Control>
            )}

            {!isEditable && (
              <Create
                className="show-edit"
                nativeColor="#3b7abe"
                style={{
                  fontSize: 16,
                  marginRight: 5,
                  marginLeft: 10,
                }}
              />
            )}

            {isEditable && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  onClick={handleSaveButtonClicked}
                  style={{ margin: "0px 5px" }}
                  size={"small"}
                  type="primary"
                >
                  Save
                </Button>
                <Button
                  onClick={handleCancelButtonClicked}
                  style={{ margin: "0px" }}
                  size={"small"}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </Form.Control>
      </Form.Field>
    </Columns.Column>
  );
}
