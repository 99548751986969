import * as React from "react";
import { Today, Call, AccountBalance, DriveEta } from "@material-ui/icons";

let ActivityIcons = {
  CASE_MANAGER: "Case Manager Appointment",
  CASE_MANAGER_CALL: "Case Manager Call Appointment",
  COURT_DATE: "Court Date",
  DMV_APPOINTMENT: "DMV Appointment",
  EMPLOYMENT_APPOINTMENT: "Employment Appointment",
};
interface Props {
  icon: string;
  className: string;
}

export class ActivityIconWeb extends React.PureComponent<Props> {
  render() {
    switch (this.props.icon) {
      case ActivityIcons.CASE_MANAGER:
        return <Today className={this.props.className} />;
      case ActivityIcons.CASE_MANAGER_CALL:
        return <Call className={this.props.className} />;
      case ActivityIcons.COURT_DATE:
        return <AccountBalance className={this.props.className} />;
      case ActivityIcons.DMV_APPOINTMENT:
        return <DriveEta className={this.props.className} />;
      case ActivityIcons.EMPLOYMENT_APPOINTMENT:
      // return "briefcase";
      default:
        //   console.error(
        //     `Unknown TimelineEventIcon received. Rendered empty. Recieved: "${icon}".`
        //   );
        return <Today className={this.props.className} />;
    }
  }
}
