import { ComponentType } from "react";
import { CarePlanContainer } from "../care-plan/CarePlanContainer";
import { ChatAndNotesPage } from "../screens/ChatAndNotesPage";
import { ParticipantTimelineContainer } from "../timeline/ParticipantTimelineContainer";
import { ParticipantGoalsTasksContainer } from "../goals-tasks/GoalsTasksContainer";
import { getFeatureFlags } from "../../featureFlags";
import { toPathIsh } from "../../utils/path";
import { Participant } from "../../shared/lib/graphql/index";
import { ParticipantProfileContainer } from "../participant-profile/ParticipantProfileContainer";
import { ParticipantAccountingContainer } from "../participant-accounting/ParticipantAccountingContainer";
import { ParticipantDocumentsContainer } from "../participant-documents/ParticipantDocumentsContainer";
import { ParticipantAssessmentContainer } from "../participant-assessments/ParticipantAssessmentsContainer";
import { ParticipantCaseContainer } from "../participant-cases/ParticipantCaseContainer";
import { ParticipantVictimsContainer } from "../participant-victims/ParticipantVictimsContainer";

export interface ParticipantDetailsTabComponentProps {
  id: string;
}

export interface ParticipantDetailsTabDefinition {
  key: string;
  title: string;
  Component: ComponentType<ParticipantDetailsTabComponentProps>;
}

const featureFlags = getFeatureFlags();

const {
  goalsAndTasksTabLabel = "Goals & Tasks",
} = getFeatureFlags().goalsTasks;

/**
 * If you add additional keys to this,
 * you will also need to modify the type of defaultTabInClientDetails
 * in FeatureFlagsDefs in types.ts
 */
export const participantDetailsTab: { [key: string]: string } = {
  carePlan: "care-plan",
  messagesNotes: "messages-notes",
  timeline: "timeline",
  goalsTasks: "goals-tasks",
  profile: "profile",
  accounting: "accounting",
  documents: "documents",
  assessment: "assessment",
  case: "case",
  victims: "victims",
};

const defaultParticipantUrl =
  participantDetailsTab[featureFlags.defaultTabInClientDetails || "carePlan"];

const carePlanTab: ParticipantDetailsTabDefinition = {
  key: participantDetailsTab.carePlan,
  title: "Care Plan",
  Component: CarePlanContainer,
};

const messagesNotesTab: ParticipantDetailsTabDefinition = {
  key: participantDetailsTab.messagesNotes,
  title: `Chat${featureFlags.supportsNotes !== false ? " & Notes" : ""}`,
  Component: ChatAndNotesPage,
};
const timelineTab: ParticipantDetailsTabDefinition = {
  key: participantDetailsTab.timeline,
  title: "Timeline",
  Component: ParticipantTimelineContainer,
};

const goalsTasksTab: ParticipantDetailsTabDefinition = {
  key: participantDetailsTab.goalsTasks,
  title: goalsAndTasksTabLabel,
  Component: ParticipantGoalsTasksContainer,
};

const participantProfile: ParticipantDetailsTabDefinition = {
  key: participantDetailsTab.profile,
  title: "Client Profile",
  Component: ParticipantProfileContainer,
};

const participantAccounting: ParticipantDetailsTabDefinition = {
  key: participantDetailsTab.accounting,
  title: "Accounting",
  Component: ParticipantAccountingContainer,
};

const participantDocuments: ParticipantDetailsTabDefinition = {
  key: participantDetailsTab.documents,
  title: "Documents",
  Component: ParticipantDocumentsContainer,
};

const participantCases: ParticipantDetailsTabDefinition = {
  key: participantDetailsTab.case,
  title: "Cases",
  Component: ParticipantCaseContainer,
};

const participantAssessments: ParticipantDetailsTabDefinition = {
  key: participantDetailsTab.assessment,
  title: "Assessments",
  Component: ParticipantAssessmentContainer,
};

const ParticipantVictims: ParticipantDetailsTabDefinition = {
  key: participantDetailsTab.victims,
  title: "Victims",
  Component: ParticipantVictimsContainer,
};

const tabList: Array<ParticipantDetailsTabDefinition> = [];

if (featureFlags.supportsVictims) {
  tabList.push(ParticipantVictims);
}

if (featureFlags.goalsTasks.display !== false) {
  tabList.push(goalsTasksTab);
}

if (featureFlags.messages.display !== false) {
  tabList.push(messagesNotesTab);
}

// If Timeline display, push it as last
if (featureFlags.timeline.display !== false) {
  tabList.push(timelineTab);
}
// If careplan, it goes in first
if (featureFlags.supportsCarePlanEditing) {
  tabList.splice(0, 0, carePlanTab);
}

if (featureFlags.supportsClientProfile) {
  tabList.unshift(participantProfile);
}

if (featureFlags.supportsClientAccounting) {
  tabList.push(participantAccounting);
}

if (featureFlags.supportsCases) {
  tabList.push(participantCases);
}

if (featureFlags.supportsParticipantRiskAssessments) {
  tabList.push(participantAssessments);
}

if (featureFlags.supportsClientDocuments) {
  tabList.push(participantDocuments);
}

export const ParticipantDetailsTabs: Array<
  ParticipantDetailsTabDefinition
> = tabList;

type MinParticipantType = Pick<Participant, "id" | "name">;

export type TabUrlBuilderFn = (
  baseUrl: string
) => (participant: MinParticipantType) => { [key: string]: string };

// TODO: This should be a cleaner one-piece arch… works for now
export const urlBuilderFn = (baseUrl: string) => ({
  id,
}: MinParticipantType): { [key: string]: string } => {
  const paths: any = {};

  const baseUrlTrailingSlash =
    baseUrl.substring(baseUrl.length - 1) === "/" ? baseUrl : `${baseUrl}/`;

  const basePersonUrl = `${baseUrlTrailingSlash}${toPathIsh(`id`)}/${id}`;

  ParticipantDetailsTabs.forEach(({ key }) => {
    paths[key] = `${basePersonUrl}/${key}`;
  });

  paths.base = basePersonUrl;
  paths.default = `${basePersonUrl}/${defaultParticipantUrl}`;

  return paths;
};
