import graphql from "babel-plugin-relay/macro";

import { Participant } from "../../shared/lib/graphql/";

export type ChatParticipant = Pick<
  Participant,
  | "id"
  | "name"
  | "language"
  | "messages"
  | "is_active"
  | "sms_consent"
  | "sms_enabled"
>;

export interface ChatContainerResult {
  me: {
    case_manager: any;
  };
  participants: {
    participant: ChatParticipant;
  };
}

export const ChatContainerGql = graphql`
  query ChatGqlQuery($id: String!, $cursor: String, $group_id: String) {
    application {
      flags {
        all(flag_ids: "office_hours.label.en")
      }
    }
    me {
      case_manager {
        id
      }
    }
    messages(
      page_cursor: $cursor
      page_size: 15
      participant_id: $id
      group_id: $group_id
    ) {
      cursor
      page_info {
        has_next_page
      }
      message {
        id
        text
        created_at
        sender_type
        recipient_type
        read_at
        sent_as
        delivery_status
        sender {
          uid
          name {
            first
            last
          }
        }
      }
    }

    participants(id: $id) {
      participant {
        id
        name {
          first
          last
        }
        is_active
        preferred_name
        language
        sms_consent
        sms_enabled
        messages(page_cursor: $cursor, page_size: 15) {
          cursor
          page_info {
            has_next_page
          }
          message {
            id
            text
            created_at
            sender_type
            recipient_type
            read_at
            sent_as
            delivery_status
          }
        }
        phone {
          mobile
        }
        current_device {
          device_id
        }
      }
    }
  }
`;
