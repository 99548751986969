import * as React from "react";
import { Modal, Typography, Button } from "antd";

interface State {
  modalIsOpen: boolean;
}
interface Props {
  warningText: string;
  itemName: string;
  onYes: Function;
  button: React.ReactElement<any>;
  secondaryOptionRender?: (callback: Function) => React.ReactElement<any>;
  onSecondary?: Function;
  onYesText?: string;
}

export class ScarePrompt extends React.Component<Props, State> {
  state = {
    modalIsOpen: false,
  };

  _openModal = () => this.setState({ modalIsOpen: true });
  _closeModal = () => this.setState({ modalIsOpen: false });

  _onYes = () => {
    this._closeModal();
    this.props.onYes();
  };

  _onYesSecondary = () => {
    this._closeModal();
    if (this.props.onSecondary) {
      this.props.onSecondary();
    }
  };

  render() {
    const { Text } = Typography;
    const { onYesText } = this.props;
    return (
      <>
        {React.cloneElement(this.props.button, { onClick: this._openModal })}
        <Modal
          visible={this.state.modalIsOpen}
          onOk={this._onYes}
          onCancel={this._closeModal}
          footer={[
            <Button type={"danger"} onClick={this._onYes} key="1">
              {onYesText ? onYesText : `Yes, delete ${this.props.itemName}`}
            </Button>,

            this.props.secondaryOptionRender &&
              this.props.onSecondary &&
              this.props.secondaryOptionRender(this._onYesSecondary),
            <Button key="2" type="primary" onClick={this._closeModal}>
              Cancel
            </Button>,
          ]}
        >
          <Text>{this.props.warningText}</Text>
        </Modal>
      </>
    );
  }
}
