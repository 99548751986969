import graphql from "babel-plugin-relay/macro";

export const classGraphqlQuery = graphql`
  query ClassGraphqlQuery {
    group_classes {
      id
      participants {
        id
        name {
          first
          last
        }
      }
      start_date
      end_date
      exclude_dates
      service_provider {
        id
        title
      }
      activity {
        id
        title {
          en
        }
      }
      days_of_week
    }
    service_providers {
      service_provider {
        id
        address
        phone
        sub_address_for_event
        title
      }
    }
    activities {
      activity {
        id
        type
        title {
          en
        }
      }
    }
    participants(active_clients_only: true) {
      participant {
        id
        name {
          first
          last
        }
      }
    }
  }
`;
