import React from "react";
import { Form } from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { AntdFieldState } from "./CreateClass";
import {
  DatePicker,
  TimePicker,
  Button,
  Icon,
  Row,
  Col,
  Checkbox,
  Select,
} from "antd";
import { Activity, ServiceProvider, Participant } from "../../graphql-types";
import { getFeatureFlags } from "../../featureFlags";
import moment, { Moment } from "moment";

const dateFormatList = ["MM/DD/YYYY"];

interface Props {
  form: WrappedFormUtils;
  onChange: (field: object) => void;
  activity: AntdFieldState;
  startDate: AntdFieldState;
  startTime: AntdFieldState;
  keys?: any;
  serviceProviders: ServiceProvider[];
  activities: Activity[];
  participants: Participant[];
  excludeDates: Moment[];
  addExcludeDate: (excludeDate: Moment) => void;
  removeExcludeDate: (index: number) => void;
  changeExcludeDate: (newExcludeDate: Moment | null, index: number) => void;
}

class StepOneForm extends React.Component<Props, any> {
  render() {
    const {
      serviceProviders,
      activities,
      participants,
      removeExcludeDate,
      excludeDates,
      changeExcludeDate,
      addExcludeDate,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    const { Option } = Select;

    const formItems = excludeDates.map((endDate, index: number) => (
      <Form.Item required={false} key={index} label="No Class On">
        <DatePicker
          value={endDate}
          format={dateFormatList}
          style={{ marginRight: "5px" }}
          onChange={(moment) => changeExcludeDate(moment, index)}
        />

        <Icon
          className="dynamic-delete-button"
          type="minus-circle-o"
          onClick={() => removeExcludeDate(index)}
        />
      </Form.Item>
    ));

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    return (
      <div className="class-form-content">
        <Form {...formItemLayout}>
          <Row>
            <Col span={12}>
              <Form.Item label="Activity" hasFeedback>
                {getFieldDecorator("activity", {
                  rules: [
                    { required: true, message: "An activity is required!" },
                  ],
                })(
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return (
                        //@ts-ignore
                        option.props.children
                          //@ts-ignore
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    placeholder="Please select an activity"
                    style={{ width: "300px" }}
                  >
                    {activities.map((activity) => {
                      const {
                        id,
                        title: { en: activityTitle },
                      } = activity;
                      return (
                        <Option value={id as string}>{activityTitle}</Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Location" hasFeedback>
                {getFieldDecorator("location", {
                  rules: [{ required: true, message: "Location is required" }],
                })(
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Please select a location"
                    style={{ width: "300px" }}
                    filterOption={(input, option) => {
                      return (
                        //@ts-ignore
                        option.props.children
                          //@ts-ignore
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {serviceProviders.map((serviceProvider) => {
                      const { id, title } = serviceProvider;
                      return <Option value={id as string}>{title}</Option>;
                    })}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Start Date">
                {getFieldDecorator("startDate", {
                  rules: [
                    {
                      type: "object",
                      required: true,
                      message: "Start date is required!",
                    },
                  ],
                })(<DatePicker format={dateFormatList} />)}
              </Form.Item>
              <Form.Item label="Start Time">
                {getFieldDecorator("startTime", {
                  rules: [
                    {
                      type: "object",
                      required: true,
                      message: "Start time is required!",
                    },
                  ],
                })(
                  <TimePicker
                    use12Hours={true}
                    minuteStep={30}
                    format={"h:mm a"}
                  />
                )}
              </Form.Item>
              <Form.Item label="End Date">
                {getFieldDecorator("endDate", {
                  rules: [
                    {
                      type: "object",
                      required: true,
                      message: "End date is required!",
                    },
                  ],
                })(<DatePicker format={dateFormatList} />)}
              </Form.Item>
              <Form.Item label="Days of the week">
                {getFieldDecorator("daysOfWeek", {
                  initialValue: [],
                })(
                  <Checkbox.Group style={{ width: "100%" }}>
                    <Row>
                      <Col span={8}>
                        <Checkbox value="Monday">Monday</Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox value="Tuesday">Tuesday</Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox value="Wednesday">Wednesday</Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox value="Thursday">Thursday</Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox value="Friday">Friday</Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox value="Saturday">Saturday</Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox value="Sunday">Sunday</Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={`${getFeatureFlags().clientDisplayTerm}s`}
                hasFeedback
              >
                {getFieldDecorator(
                  "selectedParticipants",
                  {}
                )(
                  <Select
                    showSearch
                    mode="multiple"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return (
                        //@ts-ignore
                        option.props.children
                          //@ts-ignore
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    placeholder="Please select participants"
                    style={{ width: "300px" }}
                  >
                    {participants.map((participant) => {
                      const {
                        id,
                        name: { first, last },
                      } = participant;
                      return (
                        <Option
                          value={id as string}
                        >{`${first} ${last}`}</Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
              <Form.Item>
                <Button type="dashed" onClick={() => addExcludeDate(moment())}>
                  <Icon type="plus" /> Exclude dates
                </Button>
              </Form.Item>
              {formItems}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export const StepOne = Form.create<Props>({
  name: "step_one",
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields);
  },
  mapPropsToFields(props) {
    const fieldKeys = Object.keys(props).filter((key) => key !== "onChange");

    return fieldKeys.reduce((acc: any, key: any) => {
      acc[key] = Form.createFormField({
        //@ts-ignore
        ...props[key],
        //@ts-ignore
        value: props[key].value,
      });

      return acc;
    }, {});
  },
})(StepOneForm);
