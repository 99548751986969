/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type ParticipantCountQueryVariables = {
    readonly cmId?: string | null;
    readonly showActive?: boolean | null;
};
export type ParticipantCountQueryResponse = {
    readonly participants: ({
        readonly participant: ReadonlyArray<({
            readonly id: string;
        }) | null> | null;
    }) | null;
};
export type ParticipantCountQuery = {
    readonly response: ParticipantCountQueryResponse;
    readonly variables: ParticipantCountQueryVariables;
};



/*
query ParticipantCountQuery(
  $cmId: String
  $showActive: Boolean
) {
  participants(case_manager_id: $cmId, active_clients_only: $showActive) {
    participant {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "cmId",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "showActive",
            "type": "Boolean",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "participants",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "active_clients_only",
                    "variableName": "showActive"
                },
                {
                    "kind": "Variable",
                    "name": "case_manager_id",
                    "variableName": "cmId"
                }
            ],
            "concreteType": "Pagination",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participant",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Participant",
                    "plural": true,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "ParticipantCountQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "ParticipantCountQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "ParticipantCountQuery",
            "id": null,
            "text": "query ParticipantCountQuery(\n  $cmId: String\n  $showActive: Boolean\n) {\n  participants(case_manager_id: $cmId, active_clients_only: $showActive) {\n    participant {\n      id\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '55c1b322a2f90641c1d6e3db2595ba05';
export default node;
