/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type DataInputField = "aa_meeting" | "address" | "employment" | "law_enforcement_contact" | "%future added value";
export type DataInputType = "confirmation" | "verification" | "%future added value";
export type dataInputGraphqlMutationVariables = {
    readonly participant_id: string;
    readonly field: DataInputField;
    readonly value?: string | null;
    readonly input_type: DataInputType;
    readonly offline_mode?: string | null;
};
export type dataInputGraphqlMutationResponse = {
    readonly dataInput: ({
        readonly result: string;
    }) | null;
};
export type dataInputGraphqlMutation = {
    readonly response: dataInputGraphqlMutationResponse;
    readonly variables: dataInputGraphqlMutationVariables;
};



/*
mutation dataInputGraphqlMutation(
  $participant_id: String!
  $field: DataInputField!
  $value: String
  $input_type: DataInputType!
  $offline_mode: String
) {
  dataInput(field: $field, value: $value, participant_id: $participant_id, input_type: $input_type, offline_mode: $offline_mode) {
    result
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "participant_id",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "field",
            "type": "DataInputField!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "value",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "input_type",
            "type": "DataInputType!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "offline_mode",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "dataInput",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "field",
                    "variableName": "field"
                },
                {
                    "kind": "Variable",
                    "name": "input_type",
                    "variableName": "input_type"
                },
                {
                    "kind": "Variable",
                    "name": "offline_mode",
                    "variableName": "offline_mode"
                },
                {
                    "kind": "Variable",
                    "name": "participant_id",
                    "variableName": "participant_id"
                },
                {
                    "kind": "Variable",
                    "name": "value",
                    "variableName": "value"
                }
            ],
            "concreteType": "MutationResponse",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "result",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "dataInputGraphqlMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "dataInputGraphqlMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "dataInputGraphqlMutation",
            "id": null,
            "text": "mutation dataInputGraphqlMutation(\n  $participant_id: String!\n  $field: DataInputField!\n  $value: String\n  $input_type: DataInputType!\n  $offline_mode: String\n) {\n  dataInput(field: $field, value: $value, participant_id: $participant_id, input_type: $input_type, offline_mode: $offline_mode) {\n    result\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '3b1c2937307fca7c313c757a66bee887';
export default node;
