import { identify } from "./heap/index";

export interface SetHeapUserProps {
  me?: {
    email?: string;
  };
}

let hasSetUser = false;

export const SetHeapUser = (props: SetHeapUserProps): null => {
  if (hasSetUser) return null;
  if (!props || !props.me || !props.me.email) return null;

  identify(props.me.email);
  hasSetUser = true;

  return null;
};
