import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { ProspectDetail } from "../prospects/ProspectDetail";

export class ProspectDetailScreen extends React.PureComponent<
  RouteComponentProps
> {
  render() {
    return <ProspectDetail {...this.props} />;
  }
}
