import * as React from "react";
import classnames from "classnames";

import { getByKey } from "./tableUtil";

import {
  TableColumn,
  TableData,
  TableRenderExpandedRow,
  TableSortings,
} from "./tableTypes";

type RowProps<T, TKey> = {
  className?: string;
  data: T;
  tableKey?: string;
  columns: Array<TableColumn<T, TKey>>;
  rowIdx: number;
};

type Props<T, TKey> = {
  customExpandedRowClassName?: string;
  data: TableData<T>;
  tableKey?: string;
  columns: Array<TableColumn<T, TKey>>;
  sortings: TableSortings;
  renderExpandedRow?: TableRenderExpandedRow;
};

class SortableTableRow<T, TKey> extends React.Component<RowProps<T, TKey>> {
  render() {
    const { columns, data, rowIdx, tableKey } = this.props;

    var tds = columns.map((item: TableColumn<T, TKey>, index: number) => {
      var value = getByKey(data, item.key, rowIdx);
      if (item.render) {
        value = item.render(value, rowIdx, data, tableKey);
      }
      return (
        <td key={index} style={item.dataStyle} {...(item.dataProps || {})}>
          {value}
        </td>
      );
    });

    // this for debugging only, but let it live in prod
    const id = (this.props.data as any).id || (this.props.data as any).key;

    return (
      <tr className={this.props.className} data-id={id}>
        {tds}
      </tr>
    );
  }
}

export default class SortableTableBody<T, TKey> extends React.Component<
  Props<T, TKey>
> {
  render() {
    const { tableKey } = this.props;
    var bodies: Array<JSX.Element> = [];
    this.props.data.forEach((item: T, index: number) => {
      const expandedRow =
        this.props.renderExpandedRow &&
        this.props.renderExpandedRow(item, index, tableKey);
      const className = expandedRow ? "expandedParent" : "";
      const customCls = this.props.customExpandedRowClassName || "";
      bodies.push(
        <SortableTableRow
          className={classnames(className, customCls)}
          key={index}
          data={item}
          tableKey={tableKey}
          columns={this.props.columns}
          rowIdx={index}
        />
      );
      if (expandedRow) {
        bodies.push(
          <tr
            className={classnames("expandedChild", customCls)}
            key={index + "_child"}
          >
            <td colSpan={this.props.columns.length}>{expandedRow}</td>
          </tr>
        );
      }
    });

    return <tbody>{bodies}</tbody>;
  }
}
