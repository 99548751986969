import * as React from "react";
import moment, { Moment } from "moment-timezone";
import { Variables } from "relay-runtime";
import idx from "idx.macro";
import {
  Message as RCEMessage,
  MessageListDataSource,
} from "react-chat-elements";

import {
  fetchQuery,
  Message,
  MessageActor,
  MutationResult,
} from "../../shared/lib/graphql";
import { Button } from "antd";
import { formatParticipantName } from "../../utils/participant";
import { withTimer, WithTimerProps } from "../../shared/components/withTimer";
import { Loader } from "../../shared/components/elements/Loader";
import { ParticipantDetailsTabComponentProps } from "../screens/ParticipantDetailsTabs";
import { ChatContainerGql, ChatParticipant } from "./ChatGql";
import { Chat } from "./Chat";
import {
  sendMessage as actionSendMessage,
  SendMessageInnerResult,
  markAsRead,
} from "../../actions/messages";
import { MessageReadReceipt } from "../../shared/lib/graphql";
import { getFeatureFlags } from "../../featureFlags";
import {
  flattenSMSControls,
  MessagingStates,
} from "../../shared/util/determine_sms_status";
import { OfficeHoursType } from "../../shared/lib/types";
import OfficeHours from "./OfficeHours";

const REFRESH_RATE = 1500; // ms
const MARK_AS_READ_DELAY = 1500; // ms
const IS_FETCHING_DEBOUNCE_DELAY = 400; // ms

const INITIAL_MESSAGE_KEY = "INITIAL_MESSAGE_KEY";

export type RCEMessagePlus = RCEMessage & {
  key: string;
  date: Date | undefined;
  dateString: string | undefined;
  read_at?: string;
};

export function areMomentsOneDayApart(startTime: Moment): Boolean {
  if (!startTime) {
    return false;
  }

  const endTime = moment();
  const duration = moment.duration(endTime.diff(startTime));
  const diffInHours = duration.asHours();
  return diffInHours >= 24;
}

function calculateMessageDateString(
  date: Date | undefined,
  created_at: string
): string | undefined {
  if (!date) {
    return undefined;
  }

  return areMomentsOneDayApart(moment(date))
    ? moment(date).format("MM/DD/YYYY h:mm a")
    : moment(created_at).fromNow().toString();
}

const mapMessageToRCE = (
  {
    id: key,
    text,
    created_at,
    read_at,
    recipient_type,
    sender_type,
    sender,
  }: Message,
  userId?: string
): RCEMessagePlus => {
  const date =
    created_at && moment(created_at).isValid
      ? moment(created_at).toDate()
      : undefined;

  let position: "left" | "right" | undefined = "left";
  let type: "text" | "system" | "location" | "photo" | "file" | "spotify";
  let data = {
    uri: "",
    status: {
      click: false,
      loading: 0,
    },
  };

  if (recipient_type === MessageActor.Group) {
    position = sender.uid === userId ? "right" : "left";
  } else {
    position = sender_type === MessageActor.Participant ? "left" : "right";
  }

  // Temporary fix: media URLs currently hosted in twilio.
  // Will change after implementing image save into firestore
  if (text.startsWith("https://api.twilio.com")) {
    type = "photo";
    data.uri = text;
    text = "";
  } else {
    type = "text";
  }

  return {
    key,
    text,
    type,
    date,
    data,
    dateString: calculateMessageDateString(date, created_at),
    position,
    read_at,
    notch: false,
    title: sender
      ? `${sender.name.first} ${sender.name.last || ""}`.trim()
      : "",
  };
};

export interface ChatContainerExternalProps
  extends ParticipantDetailsTabComponentProps {
  groupId?: string;
  userId?: string;
}

type ChatContainerProps = WithTimerProps & ChatContainerExternalProps;

interface ChatContainerState {
  hasMore: boolean;
  isCaseManager: boolean;
  isFetching: boolean;
  isFetchingMore: boolean;
  participant: ChatParticipant;
  cursor?: string;
  language: string;
  messages: MessageListDataSource;
  messagesKey: string;
  messageIdsToMarkAsRead: MessageReadReceipt[];
  officeHours: OfficeHoursType;
}

export class ChatContainerComponent extends React.Component<
  ChatContainerProps,
  ChatContainerState
> {
  state = {
    hasMore: true,
    isCaseManager: false,
    isFetching: false,
    isFetchingMore: false,
    cursor: (undefined as any) as string,
    messages: [] as Array<RCEMessagePlus>,
    messageIdsToMarkAsRead: [] as MessageReadReceipt[],
    language: (undefined as any) as string,
    participant: undefined as any,
    messagesKey: INITIAL_MESSAGE_KEY,
    officeHours: { label: "" },
  };
  private isFetchingDebounce: number = undefined as any;
  private isFetchingMoreDebounce: number = undefined as any;
  private markAsReadDebounce: number = undefined as any;

  componentDidMount() {
    this.fetchMoreMessages();
    this.props.setInterval(this.fetchMessages, REFRESH_RATE);
  }

  shouldComponentUpdate(
    nextProps: ChatContainerProps,
    nextState: ChatContainerState
  ) {
    return (
      nextProps !== this.props ||
      ["hasMore", "isFetchingMore", "messagesKey", "name", "language"].reduce(
        (should: boolean, val: string) =>
          should || (this.state as any)[val] !== (nextState as any)[val],
        false
      )
    );
  }

  private sendMessage = async (
    text: string
  ): Promise<SendMessageInnerResult> => {
    const { addMessageFromCaseManager } = await actionSendMessage({
      text,
      participant_id: this.props.id,
      group_id: this.props.groupId || "",
    });
    if (addMessageFromCaseManager.result === MutationResult.Success) {
      const { newMessage } = addMessageFromCaseManager;

      const messages = this.state.messages.slice();

      messages.push(mapMessageToRCE(newMessage, this.props.userId));

      const messagesKey = this.getMessagesKey(
        new Set(messages.map(({ key }) => key))
      );
      this.setState({ messages, messagesKey });
    }
    return addMessageFromCaseManager;
  };

  private fetchMoreMessages = () => {
    if (
      !this.state.hasMore ||
      this.state.isFetchingMore ||
      this.isFetchingMoreDebounce
    ) {
      return;
    }

    this.startIsFetchingMore();

    this.doFetch(
      {
        id: this.props.id,
        group_id: this.props.groupId,
        cursor: this.state.cursor,
      },
      this.stopIsFetchingMore,
      true
    );
  };

  private fetchMessages = async () => {
    if (this.state.isFetching || this.isFetchingDebounce) return;

    this.startIsFetching();

    this.doFetch(
      { id: this.props.id, group_id: this.props.groupId },
      this.stopIsFetching
    );
  };

  private doFetch = async (
    variables: Variables,
    stopFetching: () => void,
    isMore: boolean = false
  ) => {
    const data = await fetchQuery(ChatContainerGql, variables, false);

    const participant: ChatParticipant = idx(
      data,
      (_) => _.participants.participant[0]
    );

    const flags = idx(data, (_) => _.application.flags.all);
    const officeHours = {
      label: flags["office_hours.label.en"] as string,
    };

    const cursor = idx(data, (_) => _.messages.cursor);
    const hasMore = idx(data, (_) => _.messages.page_info.has_next_page);
    const isCaseManager = !!idx(data, (_) => _.me.case_manager);

    if (!this.state.participant) {
      const language = idx(participant, (_) => _.language);
      this.setState({
        participant,
        language,
        cursor,
        isCaseManager,
        officeHours,
      });
    } else if (isMore && (!!cursor || typeof hasMore !== undefined)) {
      this.setState({ cursor, hasMore, isCaseManager, officeHours });
    } else {
      this.setState({ isCaseManager, officeHours });
    }

    const messages = idx(data, (_) => _.messages.message) || [];

    this.mergeMessagesIntoState(messages);

    stopFetching();
  };

  private getMessagesKey = (seen: Set<string>): string => {
    let messagesKey = "";
    seen.forEach((key) => {
      messagesKey = messagesKey.concat(`|${key}`);
    });
    return messagesKey;
  };

  private mergeMessagesIntoState = (messagesFetched: Array<Message>) => {
    const messagesMapped = messagesFetched.map((message) =>
      mapMessageToRCE(message, this.props.userId)
    );

    // de-dup
    const seen = new Set(this.state.messages.map(({ key }: any) => key));
    const newMessages = messagesMapped.filter((message) => {
      if (seen.has(message.key)) return false;

      seen.add(message.key);
      return true;
    });

    const messagesKey = this.getMessagesKey(seen);

    if (newMessages.length === 0) {
      if (this.state.messagesKey === INITIAL_MESSAGE_KEY) {
        this.setState({ messagesKey });
      }
      return;
    }

    const messages = newMessages.concat(this.state.messages);
    const newMessageIds = new Set(newMessages.map(({ key }) => key));
    messages.sort(({ date: dateA }, { date: dateB }) => {
      const a = moment(dateA);
      const b = moment(dateB);
      if (a.isSame(b)) return 0;
      return a.isSameOrBefore(b) ? -1 : 1;
    });

    const messageIdsToMarkAsRead: MessageReadReceipt[] = messagesFetched
      .filter(
        ({ id, read_at, recipient_type }) =>
          newMessageIds.has(id) &&
          (!read_at || read_at === null) &&
          recipient_type === MessageActor.CaseManager
      )
      .map(({ id, recipient_type: recipientType }) => {
        return {
          id,
          read_by: recipientType,
        };
      });
    messageIdsToMarkAsRead.concat(this.state.messageIdsToMarkAsRead);

    this.setState(
      { messages, messagesKey, messageIdsToMarkAsRead },
      this.debounceMarkAsRead
    );
  };

  private debounceMarkAsRead = () => {
    if (this.markAsReadDebounce) {
      this.props.clearTimeout(this.markAsReadDebounce);
    }

    this.markAsReadDebounce = this.props.setTimeout(async () => {
      const { messageIdsToMarkAsRead } = this.state;
      if (!messageIdsToMarkAsRead) return;
      await markAsRead(messageIdsToMarkAsRead);
      this.setState({ messageIdsToMarkAsRead: [] });
    }, MARK_AS_READ_DELAY);
  };

  private startIsFetching = () => {
    this.isFetchingDebounce = this.props.setTimeout(() => {
      this.setState({ isFetching: true } as any);
      this.isFetchingDebounce = null as any;
    }, IS_FETCHING_DEBOUNCE_DELAY);
  };

  private startIsFetchingMore = () => {
    this.isFetchingMoreDebounce = this.props.setTimeout(() => {
      this.setState({ isFetchingMore: true } as any);
      this.isFetchingMoreDebounce = null as any;
    }, IS_FETCHING_DEBOUNCE_DELAY);
  };

  private stopIsFetching = () => {
    if (this.isFetchingDebounce) {
      this.props.clearTimeout(this.isFetchingDebounce);
      this.isFetchingDebounce = null as any;
    } else {
      this.setState({ isFetching: false } as any);
    }
  };

  private stopIsFetchingMore = () => {
    if (this.isFetchingMoreDebounce) {
      this.props.clearTimeout(this.isFetchingMoreDebounce);
      this.isFetchingMoreDebounce = null as any;
    } else {
      this.setState({ isFetchingMore: false } as any);
    }
  };

  _downloadTranscript = () => {
    const fileName = `${formatParticipantName(
      this.state.participant
    )} - chat log.csv`;
    const chatLog = this.generateChatLog();
    // Check for IE
    if (
      window.navigator.userAgent.indexOf("MSIE ") > 0 ||
      !!navigator.userAgent.match(/Trident.*rv\:11\./)
    ) {
      let IEwindow = window.open();
      if (IEwindow) {
        IEwindow.document.open("text/csv; charset=utf-8", "replace");
        IEwindow.document.write("sep=,\r\n" + chatLog);
        IEwindow.document.close();
        IEwindow.document.execCommand("SaveAs", true, fileName);
        IEwindow.close();
      }
    } else {
      const element = document.createElement("a");
      const file = new Blob([chatLog], { type: "text/csv" });
      element.href = URL.createObjectURL(file);
      element.download = fileName;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }
  };

  private generateChatLog(): string {
    //  make a csv from messages
    const messages = this.state.messages;
    const displayName = formatParticipantName(this.state.participant);
    const header = "Date,Sender,Message\n";
    const log = messages
      .map((msg: RCEMessagePlus) => {
        const sender = msg.key.includes("participant")
          ? displayName
          : msg.key.includes("case_manager")
          ? "Case Manager"
          : "Other";

        return `${
          msg.date ? msg.date.toString() : "unknown date"
        },${sender},${`"${msg.text}"`}`;
      })
      .join("\n");

    return `${header}${log}`;
  }

  render() {
    const {
      hasMore,
      isCaseManager,
      isFetchingMore,
      language,
      messages,
      participant,
    } = this.state;

    if (!participant) return <Loader />;

    const displayName = formatParticipantName(participant);
    const isActive = idx(participant, (_) => _.is_active);
    const currentDevice = idx(participant, (_) => _.current_device);
    const appEnabled = !!(currentDevice && currentDevice.device_id);
    const smsState: MessagingStates = flattenSMSControls(
      idx(participant, (_) => _.sms_enabled),
      idx(participant, (_) => _.sms_consent),
      idx(participant, (_) => _.phone.mobile)
    );

    return (
      <div className="message-list-container">
        <div className={"chat-page-header"}>
          {!!this.props.groupId ? (
            <p>
              Welcome to the Fellowship Forum.{" "}
              <span
                style={{ fontWeight: "normal" }}
                className="has-text-grey-dark"
              >
                Chatting with all active fellows and staff.
              </span>
            </p>
          ) : (
            <p>{`Chatting with ${displayName}`}</p>
          )}
          {getFeatureFlags().supprtsChatLogDownload ? (
            <Button
              type="primary"
              onClick={this._downloadTranscript}
              icon="download"
            >
              Download chat log
            </Button>
          ) : null}
        </div>
        <Chat
          fetchMessages={this.fetchMoreMessages}
          canLoadMore={!isFetchingMore}
          canWriteMessages={isCaseManager && getFeatureFlags().supportsChat}
          // TODO: remove the check for message length once hasMore works correctly
          // this is purely an aesthetic fix so chat doesn't have a "load more"
          // button while empty or near-empty
          hasMore={hasMore && messages.length > 14}
          isGroupChat={!!this.props.groupId}
          participantName={displayName}
          participantLanguage={language}
          participantIsActive={isActive}
          appEnabled={appEnabled}
          smsState={smsState}
          messages={messages}
          sendMessage={this.sendMessage}
        />
        <OfficeHours officeHours={this.state.officeHours} />
      </div>
    );
  }
}

export const ChatContainer = withTimer<
  ChatContainerExternalProps,
  ChatContainerProps
>(ChatContainerComponent);
