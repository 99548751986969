import React, { PureComponent } from "react";
import { GoalTaskList } from "../goals-tasks/GoalTaskList";
import {
  addParticipantNote,
  removeParticipantNote,
  updateParticipantNote,
} from "../../actions/participant-notes";
import { QueryRenderer } from "../../shared/lib/graphql";
import {
  ParticipantNotesType,
  ParticipantNotesQuery,
} from "./ParticipantNotesQuery";
import { ListItem } from "../../actions/types";
import idx from "idx.macro";
import { getFeatureFlags } from "../../featureFlags";

interface NotesContainerProps {
  id: string;
}

interface RemoveParticipantPartialType {
  participant_id: string;
  note_id: string;
}

export interface UpdateParticipantPartialType {
  note_id: string;
  label: string;
}

export class NotesContainer extends PureComponent<NotesContainerProps> {
  render() {
    const { id } = this.props;
    return (
      <div>
        <QueryRenderer
          query={ParticipantNotesQuery}
          variables={{ id }}
          SuccessComponent={(props: ParticipantNotesType) => {
            const notes = idx(
              props,
              (_) => _.participants.participant[0].notes
            );
            const participant = idx(
              props,
              (_) => _.participants.participant[0]
            );

            const { id: participantId } = participant;

            const caseManager = idx(props, (_) => _.me.case_manager) || {};

            const { id: loggedInCaseManagerId } = caseManager;
            return (
              <div>
                {loggedInCaseManagerId && (
                  <GoalTaskList
                    titlePlural={`Notes on this account (not visible to ${
                      getFeatureFlags().clientDisplayTerm
                    })`}
                    type={"note"}
                    titleSingular="Note"
                    listItems={notes}
                    caseManagerId={loggedInCaseManagerId}
                    addMutation={(variables: ListItem) => {
                      const variablesWithCaseManagerId = {
                        ...variables,
                        case_manager_id: loggedInCaseManagerId,
                      };
                      addParticipantNote(
                        variablesWithCaseManagerId,
                        participant,
                        caseManager
                      );
                    }}
                    removeMutation={(
                      variables: RemoveParticipantPartialType
                    ) => {
                      const variablesWithCaseManagerId = {
                        ...variables,
                        case_manager_id: loggedInCaseManagerId,
                        participant_note_id: variables.note_id,
                      };
                      removeParticipantNote(
                        variablesWithCaseManagerId,
                        participant
                      );
                    }}
                    updateTextMutation={(
                      variables: UpdateParticipantPartialType
                    ) => {
                      const variablesWithCaseManagerId = {
                        participant_id: participantId,
                        case_manager_id: loggedInCaseManagerId,
                        participant_note_id: variables.note_id,
                        label: variables.label,
                      };
                      updateParticipantNote(
                        variablesWithCaseManagerId,
                        participant
                      );
                    }}
                    participantId={this.props.id}
                    border={false}
                  />
                )}
              </div>
            );
          }}
        />
      </div>
    );
  }
}
