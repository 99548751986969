import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import idx from "idx.macro";

import {
  BroadcastMessageRecipientType,
  QueryRenderer,
  Activity,
} from "../../shared/lib/graphql";
import {
  BroadcastMessageRecipientDisplay,
  BroadcastMessagesContainerResult,
  BroadcastMessagesGql,
} from "./BroadcastMessagesGql";
import { BroadcastMessages } from "./BroadcastMessages";
import { BroadcastMessageContext } from "../../utils/context";
import { mergeRecipients } from "./BroadcastMessageMapRecipientTypes";
import { NOT_SET } from "../../shared/lib/graphql/flowTypes";

const allRecipients = {
  key: "all",
  value: "All",
  query: { queryType: BroadcastMessageRecipientType.All },
};

const recipientsBase: Array<BroadcastMessageRecipientDisplay> = [
  { key: NOT_SET, value: "Select recipients...", query: null as any },
];

export class BroadcastMessagesContainer extends React.PureComponent<
  RouteComponentProps
> {
  render() {
    return (
      <QueryRenderer
        query={BroadcastMessagesGql}
        SuccessComponent={(props: BroadcastMessagesContainerResult) => {
          const messages =
            idx(props, (_) => _.broadcast_messages.broadcast_message) || [];
          const messagesDesc =
            idx(props, (_) => _.broadcast_messages_desc.broadcast_message) ||
            [];

          const caseManagers =
            idx(props, (_) => _.case_managers.case_manager) || [];

          const riskLevels = idx(props, (_) => _.application.risk_levels) || [];

          const activities = idx(props, (_) => _.activities.activity) || [];

          const sortedActivities = [...activities].sort(
            (a: Activity, b: Activity) => {
              if (a.title.en < b.title.en) {
                return -1;
              }
              if (a.title.en > b.title.en) {
                return 1;
              }
              return 0;
            }
          );

          const recipientsStart = recipientsBase.slice(); // since we mutate it, we need a copy

          // if All isn't a risk level, add it in as an option
          const riskLevelsHasAll = !!riskLevels.find(
            ({ key }) => key === "All"
          );
          if (!riskLevelsHasAll) recipientsStart.push(allRecipients);

          const recipients = mergeRecipients(
            recipientsStart,
            riskLevels,
            caseManagers,
            sortedActivities
          );

          return (
            <BroadcastMessageContext.Provider value={{ recipients }}>
              <BroadcastMessages
                messages={messages}
                messagesDesc={messagesDesc}
                recipients={recipients}
              />
            </BroadcastMessageContext.Provider>
          );
        }}
      />
    );
  }
}
