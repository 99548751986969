/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GoalTaskQueryVariables = {
    readonly id: string;
};
export type GoalTaskQueryResponse = {
    readonly application: ({
        readonly task_config: ReadonlyArray<({
            readonly key: string;
            readonly label: string;
        }) | null> | null;
    }) | null;
    readonly participants: ({
        readonly participant: ReadonlyArray<({
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
            readonly goals: ReadonlyArray<({
                readonly id: string;
                readonly label: string;
                readonly completed_at: string | null;
                readonly created_at: string;
            }) | null> | null;
            readonly tasks: ReadonlyArray<({
                readonly id: string;
                readonly label: string | null;
                readonly completed_at: string | null;
                readonly created_at: string;
                readonly type: string | null;
            }) | null> | null;
        }) | null> | null;
    }) | null;
};
export type GoalTaskQuery = {
    readonly response: GoalTaskQueryResponse;
    readonly variables: GoalTaskQueryVariables;
};



/*
query GoalTaskQuery(
  $id: String!
) {
  application {
    task_config {
      key
      label
    }
    id
  }
  participants(id: $id) {
    participant {
      id
      name {
        first
        last
      }
      goals {
        id
        label
        completed_at
        created_at
      }
      tasks {
        id
        label
        completed_at
        created_at
        type
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "String!",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "label",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "task_config",
        "storageKey": null,
        "args": null,
        "concreteType": "TaskConfig",
        "plural": true,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "key",
                "args": null,
                "storageKey": null
            },
            (v1 /*: any*/)
        ]
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "completed_at",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "created_at",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "participants",
        "storageKey": null,
        "args": [
            {
                "kind": "Variable",
                "name": "id",
                "variableName": "id"
            }
        ],
        "concreteType": "Pagination",
        "plural": false,
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "participant",
                "storageKey": null,
                "args": null,
                "concreteType": "Participant",
                "plural": true,
                "selections": [
                    (v3 /*: any*/),
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "name",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PersonName",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "first",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "last",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "goals",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ParticipantGoal",
                        "plural": true,
                        "selections": [
                            (v3 /*: any*/),
                            (v1 /*: any*/),
                            (v4 /*: any*/),
                            (v5 /*: any*/)
                        ]
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "tasks",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ParticipantTask",
                        "plural": true,
                        "selections": [
                            (v3 /*: any*/),
                            (v1 /*: any*/),
                            (v4 /*: any*/),
                            (v5 /*: any*/),
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "type",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    }
                ]
            }
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GoalTaskQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "application",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Application",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/)
                    ]
                },
                (v6 /*: any*/)
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "GoalTaskQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "application",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Application",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/)
                    ]
                },
                (v6 /*: any*/)
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "GoalTaskQuery",
            "id": null,
            "text": "query GoalTaskQuery(\n  $id: String!\n) {\n  application {\n    task_config {\n      key\n      label\n    }\n    id\n  }\n  participants(id: $id) {\n    participant {\n      id\n      name {\n        first\n        last\n      }\n      goals {\n        id\n        label\n        completed_at\n        created_at\n      }\n      tasks {\n        id\n        label\n        completed_at\n        created_at\n        type\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'f732060c2a4188b92bdcdbf5c3415cd5';
export default node;
