import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "../shared/lib/graphql/commitMutation";
import {
  NotesParticipant,
  NotesCaseManager,
} from "../components/participant-notes/ParticipantNotesQuery";
import moment from "moment";
const uuid = require("uuid");

interface AddParticipantNotesGqlArguments {
  participant_id: string;
  label: string;
  case_manager_id: string;
}

interface RemoveParticipantNotesGqlArguments {
  participant_id: string;
  case_manager_id: string;
  participant_note_id: string;
}

interface UpdateParticipantNotesGqlArguments {
  participant_id: string;
  case_manager_id: string;
  participant_note_id: string;
  label: string;
}

export const addParticipantNote = async (
  variables: AddParticipantNotesGqlArguments,
  participant: NotesParticipant,
  caseManager: NotesCaseManager
) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation participantNotesAddMutation(
          $participant_id: String!
          $case_manager_id: String!
          $label: String!
        ) {
          addParticipantNote(
            participant_id: $participant_id
            case_manager_id: $case_manager_id
            label: $label
          ) {
            participant {
              id
              name {
                first
                last
              }
              notes {
                id
                label
                case_manager {
                  id
                  name {
                    first
                    last
                  }
                }
                created_at
                updated_at
              }
            }
            result
            description
            errors
          }
        }
      `,
      variables,
      optimisticResponse: optimisticAddParticipantNote(
        variables,
        participant,
        caseManager
      ),
    },
    "Error while adding a participant note"
  );
};

export const removeParticipantNote = async (
  variables: RemoveParticipantNotesGqlArguments,
  participant: NotesParticipant
) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation participantNotesDeleteMutation(
          $participant_id: String!
          $case_manager_id: String!
          $participant_note_id: String!
        ) {
          deleteParticipantNote(
            participant_id: $participant_id
            case_manager_id: $case_manager_id
            participant_note_id: $participant_note_id
          ) {
            participant {
              id
              name {
                first
                last
              }
              notes {
                id
                label
                created_at
                updated_at
                case_manager {
                  id
                  name {
                    first
                    last
                  }
                }
              }
            }
            result
            description
            errors
          }
        }
      `,
      variables,
      optimisticResponse: optimisticRemoveParticipantNote(
        variables.participant_note_id,
        participant
      ),
    },
    "Error while removing a participant note"
  );
};

export const updateParticipantNote = async (
  variables: UpdateParticipantNotesGqlArguments,
  participant: NotesParticipant
) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation participantNotesUpdateMutation(
          $participant_id: String!
          $case_manager_id: String!
          $participant_note_id: String!
          $label: String!
        ) {
          updateParticipantNote(
            participant_id: $participant_id
            case_manager_id: $case_manager_id
            participant_note_id: $participant_note_id
            label: $label
          ) {
            participant {
              id
              name {
                first
                last
              }
              notes {
                id
                label
                created_at
                updated_at
                case_manager {
                  id
                  name {
                    first
                    last
                  }
                }
              }
            }
            result
            description
            errors
          }
        }
      `,
      variables,
      optimisticResponse: optimisticUpdateParticipantNote(
        variables,
        participant
      ),
    },
    "Error while updating a participant note"
  );
};

const optimisticAddParticipantNote = (
  variables: AddParticipantNotesGqlArguments,
  participant: NotesParticipant,
  caseManager: NotesCaseManager
) => {
  return {
    addParticipantNote: {
      participant: {
        ...participant,
        notes: [
          {
            id: uuid(),
            label: variables.label,
            case_manager: {
              id: caseManager ? caseManager.id : "",
              name: {
                first:
                  caseManager && caseManager.name ? caseManager.name.first : "",
                last:
                  caseManager && caseManager.name ? caseManager.name.last : "",
              },
            },
            created_at: moment().toISOString(),
            updated_at: null,
          },
          ...participant.notes,
        ],
      },
      result: "success",
      description: null,
      errors: [],
    },
  };
};

const optimisticRemoveParticipantNote = (
  participantNoteId: string,
  participant: NotesParticipant
) => {
  return {
    deleteParticipantNote: {
      participant: {
        ...participant,
        notes: participant.notes.filter(
          (note) => note.id !== participantNoteId
        ),
      },
      result: "success",
      description: null,
      errors: [],
    },
  };
};

const optimisticUpdateParticipantNote = (
  variables: UpdateParticipantNotesGqlArguments,
  participant: NotesParticipant
) => {
  return {
    updateParticipantNote: {
      participant: {
        ...participant,
        notes: participant.notes.map((note) => {
          if (note.id === variables.participant_note_id) {
            return {
              ...note,
              label: variables.label,
            };
          }
          return note;
        }),
      },
      result: "success",
      description: null,
      errors: [],
    },
  };
};
