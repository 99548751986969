import React from "react";

export type IconProps = {
  style?: Object;
};

export type Props = IconProps & {
  icon: string;
};

class FaIcon extends React.Component<Props> {
  render() {
    const className = `fa fa-lg ${this.props.icon}`;
    return (
      <i
        className={className}
        style={this.props.style}
        {...({ align: "right" } as any)}
      />
    );
  }
}

export class SortIconBoth extends React.Component<IconProps> {
  render() {
    return <FaIcon icon="fa-sort" style={this.props.style} />;
  }
}

export class SortIconAsc extends React.Component<IconProps> {
  render() {
    return <FaIcon icon="fa-sort-asc" style={this.props.style} />;
  }
}

export class SortIconDesc extends React.Component<IconProps> {
  render() {
    return <FaIcon icon="fa-sort-desc" style={this.props.style} />;
  }
}
