import * as React from "react";
import RLScript from "react-load-script";

export interface ZenDeskWidgetProps {
  token: string;
}

const ZENDESK_WEB_WIDGET_URL =
  "https://static.zdassets.com/ekr/snippet.js?key=7a48176b-3caa-43b0-be70-4e8592748fed";

export class ZenDeskWidget extends React.PureComponent<ZenDeskWidgetProps> {
  private hasToken = () =>
    this.props.token && this.props.token.trim().length > 0;

  componentDidMount() {
    if (this.hasToken()) {
      ((window || {}) as any).zESettings = {
        authenticate: { jwt: this.props.token },
      };
    }
  }

  render() {
    if (!this.hasToken()) return null;

    return (
      <RLScript
        url={ZENDESK_WEB_WIDGET_URL}
        attributes={{ id: "ze-snippet" }}
        onError={(err: any) => {
          console.error("Error when loading ZenDesk script", err);
        }}
      />
    );
  }
}
