/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type MessageActor = "case_manager" | "participant" | "person_group" | "system" | "%future added value";
export type MessageReadReceipt = {
    readonly id: string;
    readonly read_by?: MessageActor | null;
};
export type messagesMarkMessagesAsReadMutationVariables = {
    readonly read_message_receipts: ReadonlyArray<MessageReadReceipt | null>;
};
export type messagesMarkMessagesAsReadMutationResponse = {
    readonly markMessagesAsRead: ({
        readonly result: string;
        readonly description: string | null;
        readonly errors: ReadonlyArray<string | null> | null;
    }) | null;
};
export type messagesMarkMessagesAsReadMutation = {
    readonly response: messagesMarkMessagesAsReadMutationResponse;
    readonly variables: messagesMarkMessagesAsReadMutationVariables;
};



/*
mutation messagesMarkMessagesAsReadMutation(
  $read_message_receipts: [MessageReadReceipt]!
) {
  markMessagesAsRead(read_message_receipts: $read_message_receipts) {
    result
    description
    errors
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "read_message_receipts",
            "type": "[MessageReadReceipt]!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "markMessagesAsRead",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "read_message_receipts",
                    "variableName": "read_message_receipts"
                }
            ],
            "concreteType": "MutationResponse",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "result",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "errors",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "messagesMarkMessagesAsReadMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "messagesMarkMessagesAsReadMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "messagesMarkMessagesAsReadMutation",
            "id": null,
            "text": "mutation messagesMarkMessagesAsReadMutation(\n  $read_message_receipts: [MessageReadReceipt]!\n) {\n  markMessagesAsRead(read_message_receipts: $read_message_receipts) {\n    result\n    description\n    errors\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '2119a029c150f6133825746c76de45d5';
export default node;
