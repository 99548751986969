import React from "react";
import moment from "moment";

interface ConfirmationParams<TValue = string> {
  name: string;
  value: TValue;
}

function parseValue(value: any): string {
  if (value instanceof moment) {
    return value.format("dddd, MMMM Do YYYY h:mm a");
  }

  // handle case where we have a fake select value (i.e. a placeholder of "Select xyx...")
  // quite hacky
  if (
    value &&
    typeof value === "string" &&
    value.slice(0, 6) === "Select" &&
    value.slice(value.length - 3) === "..."
  ) {
    return "--";
  }

  return value === "" ? "--" : value;
}

const ConfirmationModalRow = function <TValue>({
  name,
  value,
}: ConfirmationParams<TValue>) {
  const displayValue = parseValue(value);
  return (
    <tr>
      <td className="table-field-label">{name}</td>
      <td>{displayValue}</td>
    </tr>
  );
};

export default ConfirmationModalRow;
