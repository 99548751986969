import graphql from "babel-plugin-relay/macro";

export const bookingsGraphqlQuery = graphql`
  query ProspectsGraphqlQuery($prospect_status: ProspectStatus) {
    bookings(prospect_status: $prospect_status) {
      booking {
        id
        booking_defendent {
          cii_number
          local_id
          cdl_id
          name {
            first
            last
          }
          date_of_birth
          sex
          race
        }
        booking_info {
          booking_sid
          local_id
          booking_number
          booking_datetime
          booking_address
        }
        booking_charges {
          id
          booking_charge_sid
          booking_sid
          booking_number
          booking_datetime
          charge_auth_type
          charge_code_section
          charge_type
          charge_datetime
          charge_description
          arrest_agency
          arrest_datetime
          crime_case_number
          court_warrant_number
        }
        booking_releases {
          charge_code_section
          charge_description
          charge_type
          charge_release_datetime
          charge_release_type
          warrant_number
          court_case_number
          jc_control
          created_datetime
        }
        booking_status {
          booking_number
          court_appearance_datetime
          court_reason
          court_room
        }
        classification
        imported_date
        comments
        is_eligible
        spanish_speaker
        has_wait
        prospect_status
        interview_and_assessment {
          research_factors {
            has_prior_misdemeanor_conviction
            has_prior_felony_conviction
            prior_violent_convictions_count
            prior_FTAs_past_two_years_count
            has_prior_FTAs_older_than_two_years
            has_prior_sentence_to_incarceration
          }
          risk_factors {
            has_active_community_supervision
            is_charge_felony_drug_theft_or_fraud
            has_pending_charges
            has_criminal_history
            has_two_or_more_FTAs
            has_two_or_more_violent_convictions
            has_history_of_drug_abuse
            employed_at_time_of_arrest
          }
          completed_by {
            id
            name {
              first
              last
            }
          }
          court_date
          charge_bond_type_amount_court
          instrument_completed_at
          last_updated_at
        }
        is_interview_declined
        recommendation_review_status
      }
    }
  }
`;

export const bookingsProspectStatusCountQuery = graphql`
  query ProspectsGraphqlStatusCountGraphqlQuery {
    booking_prospect_status_counts {
      new
      pending_interview
      pending_recommendation
      pending_court_decision
      enroll_or_archive
    }
  }
`;
