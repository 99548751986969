import React from "react";
import "./RecommendationForm.scss";
import { Moment } from "moment";
import { Maybe } from "../../graphql-types";

interface CourtReportPreambleProps {
  bookingDate: Maybe<Moment>;
  arrestAgency: string;
  arrestedFor: string;
}

export function CourtReportPreamble(props: CourtReportPreambleProps) {
  const { bookingDate, arrestAgency, arrestedFor } = props;
  return (
    <div className="paragraph-text">
      {`On ${
        bookingDate ? bookingDate.format("MM/DD/YYYY") : ""
      } the defendant was arrested by the ${arrestAgency.trim()} for ${arrestedFor.trim()}. Pretrial staff attempt to interview defendants for the purpose of assessing for early release prior to conviction.
      The Virginia Pretrial Risk Assessment Instrument (VPRAI) is used to assist in making our recommendations in this report.
      In addition to this instrument, the information provided by the defendant, if they agreed to be interviewed, is also included for your review as follows:`}
    </div>
  );
}
