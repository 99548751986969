import firebase from "@firebase/app";
// import "@firebase/firestore";
import "@firebase/auth";
import sniffProject from "../sniffProject";
import { isValidEmail } from "../util";
import { makePhoneString } from "../../util/make_phone_string";

// See https://dev.tapjoy.com/faq/how-to-find-sender-id-and-api-key-for-gcm/
// for instructions on how to get the messagingSenderId

// See https://console.firebase.google.com/u/0/project/<PROJECT_ID>/settings/general/
// to get the API key (it's called Web API Key on that page)

export interface ProjectConfigType {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  emailLogin?: boolean;
  smsLogin?: boolean;
  googleLogin?: boolean; // by default Google login *is* enabled which is different from the others,
  sessionExpiryInMinutes?: number;
}

export const PROJECT_CONFIGS: { [key: string]: ProjectConfigType } = {
  "shane-dev-2": {
    apiKey: "AIzaSyBc9BOA2Og3atVGN_znW2XPlTE_lo-_9wg",
    authDomain: "shane-dev-2.firebaseapp.com",
    databaseURL: "https://shane-dev-2.firebaseio.com",
    projectId: "shane-dev-2",
    storageBucket: "shane-dev-2.appspot.com",
    messagingSenderId: "64885895861",
  },
  "stay-fly-santa-clara": {
    apiKey: "AIzaSyCFgJ4WHwbb6W1G-X-OeRGaYrSYkYLBoWA",
    authDomain: "stay-fly-santa-clara.firebaseapp.com",
    databaseURL: "https://stay-fly-santa-clara.firebaseio.com",
    projectId: "stay-fly-santa-clara",
    storageBucket: "stay-fly-santa-clara.appspot.com",
    messagingSenderId: "815494994190",
  },
  "lca-alameda": {
    apiKey: "AIzaSyAT4-oV-4oie2RiMDFMsv9y5b2q3IxDmzg",
    authDomain: "lca-alameda.firebaseapp.com",
    databaseURL: "https://lca-alameda.firebaseio.com",
    projectId: "lca-alameda",
    storageBucket: "lca-alameda.appspot.com",
    messagingSenderId: "354738378312",
  },
  "impression-sunrise": {
    apiKey: "AIzaSyAeIyv58CSWpUC4U3hb6WKtpb_VMBWaR5g",
    authDomain: "impression-sunrise.firebaseapp.com",
    databaseURL: "https://impression-sunrise.firebaseio.com",
    projectId: "impression-sunrise",
    storageBucket: "impression-sunrise.appspot.com",
    messagingSenderId: "705312934548",
  },
  "promise-demo-dashboard": {
    apiKey: "AIzaSyDdda-nRqulW8H0ic37ineOEGJS3dxOhvE",
    authDomain: "promise-demo-dashboard.firebaseapp.com",
    databaseURL: "https://promise-demo-dashboard.firebaseio.com",
    projectId: "promise-demo-dashboard",
    storageBucket: "promise-demo-dashboard.appspot.com",
    messagingSenderId: "", //876194000914 - not included for safety
  },
  "jp-dev-3ede9": {
    apiKey: "AIzaSyD5nMpJfCix3AqyI6nvjblHDYvRy3IXAPA",
    authDomain: "jp-dev-3ede9.firebaseapp.com",
    databaseURL: "https://jp-dev-3ede9.firebaseio.com",
    projectId: "jp-dev-3ede9",
    storageBucket: "jp-dev-3ede9.appspot.com",
    messagingSenderId: "1055180202263",
  },
  "cchu-dev": {
    apiKey: "AIzaSyB_nPTHW64s0S5uoYklfC4hH1_V11qXP9A",
    authDomain: "cchu-dev.firebaseapp.com",
    databaseURL: "https://cchu-dev.firebaseio.com",
    projectId: "cchu-dev",
    storageBucket: "cchu-dev.appspot.com",
    messagingSenderId: "514779419250",
  },
  "cchu-test": {
    apiKey: "AIzaSyA9BHD2SgM_zloNBVkJm9lpL57fxMIIrnY",
    authDomain: "cchu-test.firebaseapp.com",
    databaseURL: "https://cchu-test.firebaseio.com",
    projectId: "cchu-test",
    storageBucket: "cchu-test.appspot.com",
    messagingSenderId: "1041551177678",
  },
  "marin-county": {
    apiKey: "AIzaSyCPqGM0CH8-276MbCilQx0TBgG3dQIaMqI",
    authDomain: "marin-county.firebaseapp.com",
    databaseURL: "https://marin-county.firebaseio.com",
    projectId: "marin-county",
    storageBucket: "marin-county.appspot.com",
    messagingSenderId: "443153252823",
    emailLogin: true,
  },
  "marin-county-staging": {
    apiKey: "AIzaSyC8qNHLWD54Iz7uonxFrluNddpDsIbaws0",
    authDomain: "marin-county-staging.firebaseapp.com",
    databaseURL: "https://marin-county-staging.firebaseio.com",
    projectId: "marin-county-staging",
    storageBucket: "marin-county-staging.appspot.com",
    messagingSenderId: "471525003353",
  },
  "jp-test-dev": {
    apiKey: "AIzaSyD5nMpJfCix3AqyI6nvjblHDYvRy3IXAPA",
    authDomain: "jp-dev-3ede9.firebaseapp.com",
    databaseURL: "https://jp-test-dev.firebaseio.com",
    projectId: "jp-test-dev",
    storageBucket: "jp-test-dev.appspot.com",
    messagingSenderId: "",
  },
  "mj-dev-5f417": {
    apiKey: "AIzaSyA-8vcObqcRzdEdV6IM2bPemvafgM0KdF0",
    authDomain: "mj-dev-5f417.firebaseapp.com",
    databaseURL: "https://mj-dev-5f417.firebaseio.com",
    projectId: "mj-dev-5f417",
    storageBucket: "mj-dev-5f417.appspot.com",
    messagingSenderId: "331189530142",
  },
  "ihmoda-dev": {
    apiKey: "AIzaSyBqYCNfYHHjYeNa3nos6wfmW3B2p2_YRcw",
    authDomain: "ihmoda-dev.firebaseapp.com",
    databaseURL: "https://ihmoda-dev.firebaseio.com",
    projectId: "ihmoda-dev",
    storageBucket: "ihmoda-dev.appspot.com",
    messagingSenderId: "155039797286",
  },
  "mj-analytics": {
    apiKey: "AIzaSyBFpApx6OAT7biNeAKtKDtGRNl1kn5zqxg",
    authDomain: "mj-analytics.firebaseapp.com",
    databaseURL: "https://mj-analytics.firebaseio.com",
    projectId: "mj-analytics",
    storageBucket: "mj-analytics.appspot.com",
    messagingSenderId: "653286016194",
  },
  "kaiton-dev": {
    apiKey: "AIzaSyDBJLqXkSmq3n7PkdAZfkw3Lr7KDkx_zz0",
    authDomain: "kaiton-dev.firebaseapp.com",
    databaseURL: "https://kaiton-dev.firebaseio.com",
    projectId: "kaiton-dev",
    storageBucket: "kaiton-dev.appspot.com",
    messagingSenderId: "932749736866",
  },
  "kaiton-integration": {
    apiKey: "AIzaSyDfyuvO61a7sYRT0ELHPGj6H-xENkv6UEw",
    authDomain: "kaiton-integration.firebaseapp.com",
    databaseURL: "https://kaiton-integration.firebaseio.com",
    projectId: "kaiton-integration",
    storageBucket: "kaiton-integration.appspot.com",
    messagingSenderId: "53671781109",
  },
  "solano-cpc": {
    apiKey: "AIzaSyADlQSuvafkuZQxk-xEkkIz_ZFQGRAmBG8",
    authDomain: "solano-cpc.clients.joinpromise.com",
    databaseURL: "https://solano-cpc.firebaseio.com",
    projectId: "solano-cpc",
    storageBucket: "solano-cpc.appspot.com",
    messagingSenderId: "285419331705",
    emailLogin: true,
    googleLogin: false,
  },
  "solano-cpc-dev": {
    apiKey: "AIzaSyCQkd3fh8Fg9U3EHDjG5bBm6i6HDl1aVXs",
    authDomain: "solano-cpc-dev.firebaseapp.com",
    databaseURL: "https://solano-cpc-dev.firebaseio.com",
    projectId: "solano-cpc-dev",
    storageBucket: "solano-cpc-dev.appspot.com",
    messagingSenderId: "535489638611",
    emailLogin: true,
    googleLogin: true,
  },
  "fellowship-kentucky": {
    apiKey: "AIzaSyCx1xoNTCxDGbGB6TR8ZI4jM6cnQlcPtQI",
    authDomain: "fellowship-kentucky.firebaseapp.com",
    databaseURL: "https://fellowship-kentucky.firebaseio.com",
    projectId: "fellowship-kentucky",
    storageBucket: "fellowship-kentucky.appspot.com",
    messagingSenderId: "71764066277",
    emailLogin: true,
    smsLogin: true,
  },
  "cass-county-north-dakota": {
    apiKey: "AIzaSyBqC963UdIbHLKBllUqzHKUcDd_F96Awq0",
    authDomain: "cass-county-north-dakota.firebaseapp.com",
    databaseURL: "https://cass-county-north-dakota.firebaseio.com",
    projectId: "cass-county-north-dakota",
    storageBucket: "cass-county-north-dakota.appspot.com",
    messagingSenderId: "396867872443",
    emailLogin: true,
  },
  "lca-alameda-staging": {
    apiKey: "AIzaSyC-ibSg_Ab-_tiz3I--JaXoBEXgcE3VJe4",
    authDomain: "lca-alameda-staging.firebaseapp.com",
    databaseURL: "https://lca-alameda-staging.firebaseio.com",
    projectId: "lca-alameda-staging",
    storageBucket: "lca-alameda-staging.appspot.com",
    messagingSenderId: "820479244400",
  },
  "emarshak-promise-dev": {
    apiKey: "AIzaSyAmUlMKlvtVGfmkIarlPwITSmDhcosO0EU",
    authDomain: "emarshak-promise-dev.firebaseapp.com",
    databaseURL: "https://emarshak-promise-dev.firebaseio.com",
    projectId: "emarshak-promise-dev",
    storageBucket: "emarshak-promise-dev.appspot.com",
    messagingSenderId: "315087461623",
    emailLogin: true,
    smsLogin: true,
  },
  "fellowship-kentucky-staging": {
    apiKey: "AIzaSyAD7Ua3FRwoN3ACsCKi1Ex24I3bNYbQSB8",
    authDomain: "fellowship-kentucky-staging.clients.joinpromise.com",
    databaseURL: "https://fellowship-kentucky-staging.firebaseio.com",
    projectId: "fellowship-kentucky-staging",
    storageBucket: "fellowship-kentucky-staging.appspot.com",
    messagingSenderId: "779024203505",
    emailLogin: true,
    smsLogin: true,
  },
  "pdp-sf": {
    apiKey: "AIzaSyBJan6CHIP6SqSl3CwrbYOmiskMDnpKdek",
    authDomain: "sfpdp.clients.joinpromise.com",
    databaseURL: "https://pdp-sf.firebaseio.com",
    projectId: "pdp-sf",
    storageBucket: "pdp-sf.appspot.com",
    messagingSenderId: "190904869950",
    emailLogin: true,
  },
  "shane-dev-3": {
    apiKey: "AIzaSyAHGtLpMVwBazjN61Wdy7KnqrlIBHiMBSE",
    authDomain: "shane-dev-3.firebaseapp.com",
    databaseURL: "https://shane-dev-3.firebaseio.com",
    projectId: "shane-dev-3",
    storageBucket: "shane-dev-3.appspot.com",
    messagingSenderId: "496971721436",
  },
  "fellowship-kentucky-training": {
    apiKey: "AIzaSyBpbOSh1JZoGd4CI9VcpCYxUjy14JLb0wA",
    authDomain: "fellowship-kentucky-training.firebaseapp.com",
    databaseURL: "https://fellowship-kentucky-training.firebaseio.com",
    projectId: "fellowship-kentucky-training",
    storageBucket: "fellowship-kentucky-training.appspot.com",
    messagingSenderId: "896347598099",
    emailLogin: true,
  },
  "pdp-sf-staging": {
    apiKey: "AIzaSyBuNBn8H8Iryq3Je5kcJfmlGM-kDzXozdU",
    authDomain: "pdp-sf-staging.firebaseapp.com",
    databaseURL: "https://pdp-sf-staging.firebaseio.com",
    projectId: "pdp-sf-staging",
    storageBucket: "pdp-sf-staging.appspot.com",
    messagingSenderId: "386759128269",
    emailLogin: true,
  },
  "promise-playground": {
    apiKey: "AIzaSyDkvDSl-mQVZ5T9ku0EsXzFI9V9TIEOUAA",
    authDomain: "promise-playground.firebaseapp.com",
    databaseURL: "https://promise-playground.firebaseio.com",
    projectId: "promise-playground",
    storageBucket: "promise-playground.appspot.com",
    messagingSenderId: "1006736881766",
    emailLogin: true,
    smsLogin: true,
  },
  "pdp-sf-dev": {
    apiKey: "AIzaSyBaNszDmpV5i9nx9hy01AZ051kAaS_EyWw",
    authDomain: "pdp-sf-dev.firebaseapp.com",
    databaseURL: "https://pdp-sf-dev.firebaseio.com",
    projectId: "pdp-sf-dev",
    storageBucket: "pdp-sf-dev.appspot.com",
    messagingSenderId: "441479088893",
    emailLogin: true,
  },
  "promise-ci-test": {
    apiKey: "AIzaSyBkWy4kGgyAwCQkC55ANhoy2QiH69MXo6M",
    authDomain: "promise-ci-test.firebaseapp.com",
    databaseURL: "https://promise-ci-test.firebaseio.com",
    projectId: "promise-ci-test",
    storageBucket: "promise-ci-test.appspot.com",
    messagingSenderId: "930727946079",
  },
  "solano-cpc-training": {
    apiKey: "AIzaSyBN_VIc2kgdBNqOqXeQhpOxJamso9fRJi0",
    authDomain: "solano-cpc-training.firebaseapp.com",
    databaseURL: "https://solano-cpc-training.firebaseio.com",
    projectId: "solano-cpc-training",
    storageBucket: "solano-cpc-training.appspot.com",
    messagingSenderId: "126367749058",
    emailLogin: true,
    smsLogin: true,
  },
  "mash-dev-b01a3": {
    apiKey: "AIzaSyCDRbew5YYes6C1MmcnIAfFzxujbNAZv2k",
    authDomain: "mash-dev-b01a3.firebaseapp.com",
    databaseURL: "https://mash-dev-b01a3.firebaseio.com",
    projectId: "mash-dev-b01a3",
    storageBucket: "mash-dev-b01a3.appspot.com",
    messagingSenderId: "861845737012",
  },
  "cass-county-north-dakota-stg": {
    apiKey: "AIzaSyBbsgLfcMIB0BCHj0CMiNDtNvFMifAXnX8",
    authDomain: "cass-county-north-dakota-stg.firebaseapp.com",
    databaseURL: "https://cass-county-north-dakota-stg.firebaseio.com",
    projectId: "cass-county-north-dakota-stg",
    storageBucket: "cass-county-north-dakota-stg.appspot.com",
    messagingSenderId: "265220162249",
    emailLogin: true,
    smsLogin: true,
    sessionExpiryInMinutes: 15,
  },
  "nd-cal-pretrial": {
    apiKey: "AIzaSyCjnp-Mta3yXhJlQ1GKIGhGixajmPoolTE",
    authDomain: "nd-cal-pretrial.firebaseapp.com",
    databaseURL: "https://nd-cal-pretrial.firebaseio.com",
    projectId: "nd-cal-pretrial",
    storageBucket: "nd-cal-pretrial.appspot.com",
    messagingSenderId: "1014199608142",
    emailLogin: true,
    googleLogin: false,
  },
  "promise-master": {
    apiKey: "AIzaSyCydj3Eqt-p9vaPvg7mIrnSL5Ojvt11W7s",
    authDomain: "promise-master.firebaseapp.com",
    databaseURL: "https://promise-master.firebaseio.com",
    projectId: "promise-master",
    storageBucket: "promise-master.appspot.com",
    messagingSenderId: "461746793232",
    emailLogin: false,
    smsLogin: false,
  },
  "mash-inglis-test": {
    apiKey: "AIzaSyBiigAffS3qwxeltIBENlmwX3kmwT1gTEo",
    authDomain: "mash-inglis-test.firebaseapp.com",
    databaseURL: "https://mash-inglis-test.firebaseio.com",
    projectId: "mash-inglis-test",
    storageBucket: "mash-inglis-test.appspot.com",
    messagingSenderId: "668551837167",
  },
  "tdrc-alameda-stg": {
    apiKey: "AIzaSyCY0BeLGusFuC6Xk1Z375U7SG1xPbEI5YA",
    authDomain: "tdrc-alameda-stg.firebaseapp.com",
    databaseURL: "https://tdrc-alameda-stg.firebaseio.com",
    projectId: "tdrc-alameda-stg",
    storageBucket: "tdrc-alameda-stg.appspot.com",
    messagingSenderId: "237859742550",
  },
  "tdrc-alameda": {
    apiKey: "AIzaSyC78pihU9LabzPFQLOqqJbOa1U75Ad4_2I",
    authDomain: "tdrc-alameda.firebaseapp.com",
    databaseURL: "https://tdrc-alameda.firebaseio.com",
    projectId: "tdrc-alameda",
    storageBucket: "tdrc-alameda.appspot.com",
    messagingSenderId: "934459621043",
  },
  "marin-pretrial-staging": {
    apiKey: "AIzaSyARVvLuLlV5QU0_xUPcnNMFZG72syOxUBY",
    authDomain: "marin-pretrial-staging.firebaseapp.com",
    databaseURL: "https://marin-pretrial-staging.firebaseio.com",
    projectId: "marin-pretrial-staging",
    storageBucket: "marin-pretrial-staging.appspot.com",
    messagingSenderId: "50547207069",
    emailLogin: true,
  },
  "marin-pretrial": {
    apiKey: "AIzaSyAxK4ErchYCVkIxUjyQfQQ-UjwFzNfkFwo",
    authDomain: "marin-pretrial.firebaseapp.com",
    databaseURL: "https://marin-pretrial.firebaseio.com",
    projectId: "marin-pretrial",
    storageBucket: "marin-pretrial.appspot.com",
    messagingSenderId: "195997869395",
    emailLogin: true,
  },
  "alameda-pretrial-staging": {
    apiKey: "AIzaSyCorc9aYy2Nbq7hbQsnEkJKGihNTR5AISU",
    authDomain: "alameda-pretrial-staging.firebaseapp.com",
    databaseURL: "https://alameda-pretrial-staging.firebaseio.com",
    projectId: "alameda-pretrial-staging",
    storageBucket: "alameda-pretrial-staging.appspot.com",
    messagingSenderId: "263849573513",
    emailLogin: true,
  },
  "alameda-pre-trial": {
    apiKey: "AIzaSyAH3XQ14WVerP3NvQWu35i6WDWZhesd_wg",
    authDomain: "alameda-pre-trial.firebaseapp.com",
    databaseURL: "https://alameda-pre-trial.firebaseio.com",
    projectId: "alameda-pre-trial",
    storageBucket: "alameda-pre-trial.appspot.com",
    messagingSenderId: "883947417797",
    emailLogin: true,
  },
  "josh-dev-f284a": {
    apiKey: "AIzaSyA2hewRpmrl-xgSLSia7340ikOrsN8jcHk",
    authDomain: "josh-dev-f284a.firebaseapp.com",
    databaseURL: "https://josh-dev-f284a.firebaseio.com",
    projectId: "josh-dev-f284a",
    storageBucket: "josh-dev-f284a.appspot.com",
    messagingSenderId: "",
    emailLogin: true,
  },
  "josh-test-f494a": {
    apiKey: "AIzaSyDzXBMfKelmzcfSNXS5YS9T6TrAE15l3VU",
    authDomain: "josh-test-f494a.firebaseapp.com",
    databaseURL: "https://josh-test-f494a.firebaseio.com",
    projectId: "josh-test-f494a",
    storageBucket: "josh-test-f494a.appspot.com",
    messagingSenderId: "",
    emailLogin: true,
  },
  "cathy-casemgmt-dev": {
    apiKey: "AIzaSyAQaesdoObSwto9xTRwZT2MrO9zg8krXEQ",
    authDomain: "cathy-casemgmt-dev.firebaseapp.com",
    databaseURL: "https://cathy-casemgmt-dev.firebaseio.com",
    projectId: "cathy-casemgmt-dev",
    storageBucket: "cathy-casemgmt-dev.appspot.com",
    messagingSenderId: "",
    emailLogin: true,
  },
}; // export const firestore = firebase.firestore();

const DEFAULT_GOOGLE_LOGIN = true;
const DEFAULT_EMAIL_LOGIN = false;
const DEFAULT_SMS_LOGIN = false;

// TODO: Get Typings
const provider = new (firebase as any).auth.GoogleAuthProvider();

let token: any;
let user: any;

let googleLoginAllowed = DEFAULT_GOOGLE_LOGIN;
let emailLoginAllowed = DEFAULT_EMAIL_LOGIN;
let smsLoginAllowed = DEFAULT_SMS_LOGIN;
let sessionExpiryInMinutes: number | undefined;

let recaptchaVerifier: any;
let recaptchaVerified = false;

export type EmailLoginState = "none" | "email_sent" | "logging_in";

const EMAIL_FOR_SIGNIN = "emailForSignIn";

export const initializeFirebase = (
  project: string | undefined | null
): string => {
  if (!project) {
    project = sniffProject();
  }

  if (PROJECT_CONFIGS[project]) {
    firebase.initializeApp(PROJECT_CONFIGS[project]);
    emailLoginAllowed = !!PROJECT_CONFIGS[project].emailLogin;
    smsLoginAllowed = !!PROJECT_CONFIGS[project].smsLogin;
    googleLoginAllowed =
      PROJECT_CONFIGS[project].googleLogin !== undefined
        ? PROJECT_CONFIGS[project].googleLogin!
        : DEFAULT_GOOGLE_LOGIN;

    sessionExpiryInMinutes = PROJECT_CONFIGS[project].sessionExpiryInMinutes;
  } else {
    throw new Error(`Unrecognized Firebase project ${project}. You should add
      the configuration to the auth/firebase.js file`);
  }

  return project;
};

export const login = function (sms_login?: boolean) {
  if (sms_login) {
    return initiateSmsLogin();
  }
  if (getEmailLoginState() === "logging_in") {
    // this is from a link
    const email = window.localStorage.getItem(EMAIL_FOR_SIGNIN);
    return (firebase as any)
      .auth()
      .signInWithEmailLink(email, window.location.href)
      .then((result: any) => {
        return { user: result.user };
      })
      .catch((e: any) => {
        console.error(e);
        throw e;
      });
  } else {
    // TODO: Get Typings
    return (
      (firebase as any)
        .auth()
        .signInWithPopup(provider)
        // TODO: Get Typings
        .then((result: any) => {
          token = result.credential.accessToken; // The signed-in user info.

          user = result.user;
          return {
            token,
            user,
          };
        })
    );
  }
};

// return true iff an email was submitted, and login has started
export const initiateEmailLogin = function () {
  // need to send a link
  const email = window.prompt("Please enter your email address.", "");
  if (!email) {
    return Promise.resolve(false);
  } else {
    if (!isValidEmail(email)) {
      return new Promise(() => {
        throw new Error("Invalid email address");
      });
    }
  }
  return (
    (firebase as any)
      .auth()
      .sendSignInLinkToEmail(email, {
        url: window.location.href,
        handleCodeInApp: true,
      })
      // TODO report if user does not exist
      .then(() => {
        window.localStorage.setItem(EMAIL_FOR_SIGNIN, email as string);
        return true;
      })
      .catch((e: any) => {
        console.error(e);
        return false;
      })
  );
};

const initiateSmsLogin = () => {
  try {
    recaptchaVerified = false;
    const rawPhone = window.prompt("Please enter your phone number", "");
    if (!rawPhone) {
      throw new Error("Phone login cancelled");
    }
    const phoneNumber = makePhoneString(rawPhone);
    return (firebase as any)
      .auth()
      .signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
      .then((result: any) => {
        return waitForRecaptchaVerify().then(() => {
          recaptchaVerifier.clear(); // seems to be necessary or else the window.prompt gets destroyed
          const code = window.prompt(
            "Please enter the code sent to you by text message.",
            ""
          );
          if (!code) {
            throw new Error("Phone login cancelled");
          }
          return result
            .confirm(code)
            .then((result: any) => {
              return result;
            })
            .catch((e: any) => {
              console.error(e);
              throw e;
            });
        });
      })
      .catch((e: any) => {
        console.error(e);
        throw e;
      });
  } catch (e) {
    return Promise.reject(e);
  }
};

const sleep = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const waitForRecaptchaVerify = async () => {
  while (recaptchaVerified === false) {
    await sleep(500);
  }
};

export const getEmailLoginState = (): EmailLoginState => {
  if (!!window.localStorage.getItem(EMAIL_FOR_SIGNIN)) {
    if ((firebase as any).auth().isSignInWithEmailLink(window.location.href)) {
      return "logging_in";
    }
    return "email_sent";
  }
  return "none";
};

export const isEmailLoginAllowed = (): boolean => {
  return emailLoginAllowed;
};

export const isSmsLoginAllowed = (): boolean => {
  return smsLoginAllowed;
};

export const isGoogleLoginAllowed = (): boolean => {
  return googleLoginAllowed;
};

export const getSessionExpiryInMinutes = (): number | undefined => {
  return sessionExpiryInMinutes;
};

export const clearEmailLoginState = () => {
  window.localStorage.removeItem(EMAIL_FOR_SIGNIN);
};

export const initRecaptcha = (containerId: string) => {
  // @ts-ignore:disable-next-line
  recaptchaVerifier = new firebase.auth.RecaptchaVerifier(containerId, {
    callback: () => {
      recaptchaVerified = true;
    },
  });
};

// TODO: Get Typings
export const logout = () => (firebase as any).auth().signOut();
export const onLoginChanged = function (callback: Function) {
  // Returns a function that can be used to unsubscribe when called
  // TODO: Get Typings
  return (firebase as any).auth().onAuthStateChanged(callback);
};
export default firebase;
