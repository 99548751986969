import * as React from "react";

import { Form } from "react-bulma-components";
import {
  flattenSMSControls,
  MessagingStates,
  smsOptions,
} from "../../shared/util/determine_sms_status";

type SMSPair = {
  sms_enabled: boolean;
  sms_consent?: boolean;
};

interface Props {
  onChange: (newState: SMSPair) => void;
  sms_enabled: boolean;
  sms_consent?: boolean;
  phone: string;
}

export class SMSSelect extends React.PureComponent<Props> {
  _expandSMSControls = (value: MessagingStates): SMSPair => {
    switch (value) {
      case MessagingStates.MESSAGING_OFF_CLIENT_OPTED_OUT:
        return { sms_enabled: this.props.sms_enabled, sms_consent: false };
      case MessagingStates.MESSAGING_OFF_CLIENT_NOT_OPTED_IN:
        return { sms_enabled: this.props.sms_enabled, sms_consent: undefined };
      case MessagingStates.MESSAGING_ON:
        return { sms_enabled: true, sms_consent: this.props.sms_consent };
      case MessagingStates.MESSAGING_OFF:
        return { sms_enabled: false, sms_consent: this.props.sms_consent };
      case MessagingStates.PHONE_NUMBER_NOT_PROVIDED:
        return {
          sms_enabled: this.props.sms_enabled,
          sms_consent: this.props.sms_consent,
        };
    }
  };
  _isSelectActive = (): boolean | undefined => {
    switch (
      flattenSMSControls(
        this.props.sms_enabled,
        this.props.sms_consent!,
        this.props.phone
      )
    ) {
      case MessagingStates.MESSAGING_OFF_CLIENT_OPTED_OUT:
        return false;
      case MessagingStates.MESSAGING_OFF_CLIENT_NOT_OPTED_IN:
        return false;
      case MessagingStates.MESSAGING_ON:
        return true;
      case MessagingStates.MESSAGING_OFF:
        return true;
      case MessagingStates.PHONE_NUMBER_NOT_PROVIDED:
        return false;
      default:
        return true;
    }
  };
  _renderOptions = (): (JSX.Element | null)[] | undefined => {
    const currentValue = flattenSMSControls(
      this.props.sms_enabled,
      this.props.sms_consent!,
      this.props.phone
    );
    return (
      smsOptions &&
      smsOptions.map(({ key, value, hidden }) => {
        return !hidden || currentValue === key ? (
          <option key={key} value={key} hidden={hidden}>
            {value}
          </option>
        ) : null;
      })
    );
  };

  render() {
    const { onChange } = this.props;
    const optionsToRender = this._renderOptions();
    const isSelectActive = this._isSelectActive();
    const currentValue = flattenSMSControls(
      this.props.sms_enabled,
      this.props.sms_consent!,
      this.props.phone
    );
    return (
      <Form.Field>
        <Form.Select
          onChange={(evt: React.SyntheticEvent<HTMLInputElement>) => {
            onChange(
              this._expandSMSControls(
                evt.currentTarget.value as MessagingStates
              )
            );
          }}
          name={name}
          value={currentValue}
          disabled={!isSelectActive}
        >
          {optionsToRender}
        </Form.Select>
      </Form.Field>
    );
  }
}
