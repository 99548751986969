import React, { useState } from "react";
import { Level } from "react-bulma-components";
import Select from "../../shared/components/elements/Select";
import { getFeatureFlags } from "../../featureFlags";
import "./CarePlanSection.scss";
import {
  addObligationEvent,
  UpdateObligationGqlArguments,
} from "../../actions/obligations";
import { AddEventFormData } from "../modals/events/AddEventModalWrapper";
import {
  CarePlanObligationType,
  CarePlanParticipantType,
  CarePlanEventType,
} from "./CarePlanQuery";
import { CarePlanItemObligation } from "./CarePlanItemObligation";
import ObligationDatePicker from "./ObligationDatePicker";
import { getStatusBulmaColor } from "../../shared/lib/util";
import { FormLabelWithToolTip } from "../../shared/components/elements/FormLabelWithToolTip";
import { createRRuleSetString } from "./createRRuleSetString";
import { SelectScarePrompt } from "./SelectScarePrompt";
import { KeyedString } from "../../../../shared/src/lib/graphql";
import { Typography, Tag, Button, Modal } from "antd";
import moment from "moment";
import {
  CadenceOptions,
  DataInputType,
  DataInputField,
} from "../../graphql-types";
import { CarePlanCheckIn } from "./CarePlanSetup";
import { editCheckInObligationCadenceSettings } from "../../actions/obligations";

const { whatIsComplianceCalled = "Compliance" } = getFeatureFlags();

type Props = {
  obligation: CarePlanObligationType;
  toggleObligationCollapse: (obligation_id: string) => void;
  expanded: Boolean;
  participant: CarePlanParticipantType;
  compliance: any;
  obligationStatus: any;
  updateObligationMutation: any;
  index: number;
  isDeleteDisabled?: boolean;
};

type MutationCallBackSignature = (
  variables: UpdateObligationGqlArguments,
  obligation: CarePlanObligationType
) => void;

type State = {
  obligationToSetAsEnded: string;
};

export class CarePlanItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      obligationToSetAsEnded: "",
    };
  }

  _handleComplianceMutation = (
    mutationCallback: (
      variables: UpdateObligationGqlArguments,
      obligation: CarePlanObligationType
    ) => void,
    mutationPayload: UpdateObligationGqlArguments
  ) => {
    const { obligation } = this.props;
    mutationCallback(mutationPayload, obligation);
  };

  _handleObligationStatusMutation = (
    mutationCallback: (
      variables: UpdateObligationGqlArguments,
      obligation: CarePlanObligationType
    ) => void,
    mutationPayload: UpdateObligationGqlArguments
  ) => {
    const { obligation } = this.props;
    mutationCallback(mutationPayload, obligation);
  };

  _handleSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    obligationId: string,
    mutationCallback: (
      variables: UpdateObligationGqlArguments,
      obligation: CarePlanObligationType
    ) => void
  ) => {
    const { name, value } = event.target;

    const mutationPayload = {
      obligation_id: obligationId,
      [name]: value,
    };

    if (name === "status") {
      if (value === "ended") {
        this._showObligationWarningModal(obligationId);
      } else {
        this._handleObligationStatusMutation(mutationCallback, mutationPayload);
      }
    } else {
      this._handleComplianceMutation(mutationCallback, mutationPayload);
    }
  };

  _showObligationWarningModal = (obligationId: string) => {
    this.setState({ obligationToSetAsEnded: obligationId });
  };

  _setObligationToEnded = (
    mutationCallback: MutationCallBackSignature | null
  ) => {
    const { obligationToSetAsEnded } = this.state;

    const mutationPayload = {
      obligation_id: obligationToSetAsEnded,
      status: "ended",
    };

    if (mutationCallback === null) {
      return;
    }

    this._handleObligationStatusMutation(mutationCallback, mutationPayload);
    this.setState({ obligationToSetAsEnded: "" });
  };

  _unSetObligationToSetAsEnded = () => {
    this.setState({ obligationToSetAsEnded: "" });
  };

  _toggleExpansion = () => {
    const {
      toggleObligationCollapse,
      obligation: { id },
    } = this.props;
    toggleObligationCollapse(id);
  };
  _onEventAdded = async (
    { start, end, rrule }: AddEventFormData,
    closeModal: () => void
  ) => {
    const { obligation } = this.props;

    await addObligationEvent({
      obligation,
      date: (start as any) as string,
      end: (end as any) as string,
      rrule: createRRuleSetString(rrule, start, obligation.end),
    });
    closeModal();
  };

  render() {
    const {
      obligation,
      updateObligationMutation,
      isDeleteDisabled = false,
    } = this.props;
    const { obligationToSetAsEnded } = this.state;

    const isReadOnly =
      typeof obligation.is_writable === "boolean" && !obligation.is_writable;
    const {
      title: { en: title },
    } = obligation;

    return (
      <div key={`${title}-${obligation.activity.type}`}>
        <SelectScarePrompt
          warningText={`Are you sure you want to set this obligation to ended? Doing so will remove all future scheduled events.`}
          modalIsOpen={obligationToSetAsEnded ? true : false}
          onYes={() => this._setObligationToEnded(updateObligationMutation)}
          onNo={this._unSetObligationToSetAsEnded}
          yesButtonText={"Yes, end obligation and cancel all future events"}
          noButtonText={"Cancel"}
        />

        <div>
          <CarePlanItemObligation
            obligation={obligation}
            isReadOnly={isReadOnly}
            isDeleteDisabled={isDeleteDisabled}
          />
        </div>
      </div>
    );
  }
}

interface CarePlanHeaderProps {
  isReadOnly: boolean;
  obligation: CarePlanObligationType;
  updateObligationMutation: MutationCallBackSignature;
  obligationStatuses: KeyedString[];
  complianceOptions: KeyedString[];
  complianceValue: string;
  participantId: string;
}

export function CarePlanHeader(props: CarePlanHeaderProps) {
  const {
    isReadOnly,
    complianceOptions,
    obligation,
    updateObligationMutation,
    obligationStatuses,
    complianceValue,
    participantId,
  } = props;

  const [showEndObligationWarning, setEndObligationWarning] = useState(false);
  const [showChangeCadenceModal, setChangeCadenceModal] = useState(false);
  const [checkInData, setCheckInData] = useState({
    cadence: obligation.cadence_option,
    dayOfWeek: obligation.day_of_week,
    dayOfMonth: obligation.day_of_month,
    verificationType: obligation.verification_option,
  });

  const handleComplianceSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    const { id: obligationId } = obligation;

    const mutationPayload = {
      obligation_id: obligationId,
      [name]: value.toLowerCase(),
    };

    updateObligationMutation(mutationPayload, obligation);
  };

  const handleStatusSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = event.target;

    if (value.toLowerCase() === "ended") {
      setEndObligationWarning(true);
      return;
    }

    handleComplianceSelectChange(event);
  };

  const setObligationToEnded = () => {
    const { id: obligationId } = obligation;
    const mutationPayload = {
      obligation_id: obligationId,
      status: "ended",
    };

    updateObligationMutation(mutationPayload, obligation);

    setEndObligationWarning(false);
  };

  const { status } = obligation;

  const { Title } = Typography;

  const {
    title: { en: title },
    tag,
  } = obligation;

  const obligationEvents = obligation.event_templates.reduce(
    (acc: CarePlanEventType[], eventTemplate) => {
      acc = [...acc, ...eventTemplate.events];
      return acc;
    },
    []
  );

  return (
    <div>
      <Modal
        title={`Change Cadence: ${obligation.title.en}`}
        visible={showChangeCadenceModal}
        onOk={async (event) => {
          event.stopPropagation();
          // add mutation code here
          const payload = {
            participantId: participantId,
            obligationId: obligation.id,
            checkInData: checkInData as any,
          };

          await editCheckInObligationCadenceSettings(payload);

          setChangeCadenceModal(false);
        }}
        onCancel={(event) => {
          event.stopPropagation();
          setChangeCadenceModal(false);
        }}
      >
        <div
          onClick={(event: any) => {
            event.stopPropagation();
          }}
        >
          <CarePlanCheckIn
            hasVerification={true}
            updateObligationCarePlanData={(
              _checkInId: string,
              value: any,
              field: string,
              _type: "checkInData" | "obligationData"
            ) =>
              setCheckInData({
                ...checkInData,
                [field]: value,
              })
            }
            obligationCarePlanItem={{
              id: obligation.id,
              title: obligation.title.en,
              isCheckIn: true,
              hasVerification:
                obligation.activity.data_input_type !==
                DataInputField.LawEnforcementContact,
              //@ts-ignore
              checkInData: checkInData,
              obligationData: null,
              serviceProviderId: obligation.service_provider.id,
              activityId: obligation.activity.id,
              verificationActivityId: null,
            }}
          />
        </div>
      </Modal>

      <SelectScarePrompt
        warningText={`Are you sure you want to set this obligation to ended? Doing so will remove all future scheduled events.`}
        modalIsOpen={showEndObligationWarning}
        onYes={(event: React.ChangeEvent<HTMLSelectElement>) => {
          event.stopPropagation();
          setObligationToEnded();
        }}
        onNo={(event: React.ChangeEvent<HTMLSelectElement>) => {
          event.stopPropagation();
          setEndObligationWarning(false);
        }}
        yesButtonText={"Ok"}
        noButtonText={"Cancel"}
      />

      <Level>
        <Level.Side align="left">
          <Level.Item className="width-bounded-level-item">
            <Title level={4} style={{ margin: 0 }}>
              {title}
            </Title>
          </Level.Item>
          {tag && (
            <Level.Item>
              <Tag color="green">{tag}</Tag>
            </Level.Item>
          )}
          <Level.Item>
            <Tag>{`${obligationEvents.length} event${
              obligationEvents.length !== 1 ? "s" : ""
            } `}</Tag>
          </Level.Item>
          {obligation.cadence_option && (
            <>
              <Level.Item>
                <p style={{ margin: "0px" }}>{`Check-in occurs ${
                  obligation.cadence_option
                } on ${
                  obligation.cadence_option === CadenceOptions.Weekly
                    ? moment().isoWeekday(obligation.day_of_week).format("dddd")
                    : moment()
                        .set("month", obligation.day_of_month)
                        .format("Qo")
                }`}</p>
              </Level.Item>
              {obligation.activity.data_input_type ===
                DataInputType.Confirmation && (
                <Level.Item
                  onClick={(event: React.ChangeEvent<HTMLSelectElement>) =>
                    event.stopPropagation()
                  }
                >
                  <Button
                    type="primary"
                    size="small"
                    onClick={() =>
                      setChangeCadenceModal(!showChangeCadenceModal)
                    }
                  >
                    {`Change Cadence`}
                  </Button>
                </Level.Item>
              )}
            </>
          )}
        </Level.Side>
        <Level.Side align="right">
          {getFeatureFlags().supportsDateRangesOnObligations ? (
            <Level.Item className="statusSelect">
              <div>
                <FormLabelWithToolTip
                  tipText="When this obligation begins and when it is estimated to end."
                  labelText="Start and End Date"
                />
                <ObligationDatePicker
                  start={obligation.start}
                  end={obligation.end}
                  obligation={obligation}
                  mutationCallback={updateObligationMutation}
                  obligation_id={obligation.id}
                  isReadOnly={isReadOnly}
                />
              </div>
            </Level.Item>
          ) : null}
          <Level.Item className="statusSelect">
            <div>
              <FormLabelWithToolTip
                tipText={`Status of the obligation will be "ongoing" if the client is working on it.`}
                labelText="Status"
              />
              <Select
                onClick={(event: React.ChangeEvent<HTMLSelectElement>) =>
                  event.stopPropagation()
                }
                value={status}
                options={obligationStatuses}
                instructionalDefault="Select..."
                onChange={(event) => handleStatusSelectChange(event)}
                name="status"
                disabled={isReadOnly}
              />
            </div>
          </Level.Item>
          <Level.Item className="complianceSelect">
            <div>
              <FormLabelWithToolTip
                tipText={`${whatIsComplianceCalled} is the marker of how the client is performing in the program.`}
                labelText={whatIsComplianceCalled}
              />
              <Select
                onClick={(event: React.ChangeEvent<HTMLSelectElement>) =>
                  event.stopPropagation()
                }
                value={complianceValue}
                options={complianceOptions}
                instructionalDefault="Select..."
                color={getStatusBulmaColor(complianceValue)}
                onChange={(event) => handleComplianceSelectChange(event)}
                name="compliance"
                disabled={isReadOnly}
              />
            </div>
          </Level.Item>
        </Level.Side>
      </Level>
    </div>
  );
}
