import * as React from "react";

import { SortIconBoth, SortIconDesc, SortIconAsc } from "./icons";

import {
  TableColumn,
  TableData,
  TableSortingItem,
  TableSortings,
} from "./tableTypes";

export type ItemProps<T> = {
  data: TableData<T>;
  header?: React.ReactNode;
  headerProps?: Object & { className?: String };
  iconAsc?: React.ReactNode;
  iconBoth?: React.ReactNode;
  iconDesc?: React.ReactNode;
  iconStyle?: Object;
  index: number;
  isSorted: boolean;
  sortable: boolean;
  sorting: TableSortingItem;
  style?: Object;
  onClick: (index: number) => void;
};

type Props<T, TKey> = {
  columns: Array<TableColumn<T, TKey>>;
  data: TableData<T>;
  sortings: TableSortings;
  iconAsc?: React.ReactNode;
  iconBoth?: React.ReactNode;
  iconDesc?: React.ReactNode;
  iconStyle?: Object;
  sortingIndex: number;
  onStateChange: (index: number) => void;
};

class SortableTableHeaderItem<T> extends React.Component<ItemProps<T>> {
  static defaultProps = {
    headerProps: {},
    sortable: true,
  };

  _onClick = () => {
    if (this.props.sortable) {
      this.props.onClick(this.props.index);
    }
  };

  _getSortIconAndClass = (): { className: string; sortIcon: any } => {
    const { className: classNameProps } = this.props.headerProps || ({} as any);
    if (!this.props.sortable) {
      return {
        className: classNameProps,
        sortIcon: null,
      };
    }

    let sortIcon: any;

    let sortedClassName = "";
    if (this.props.isSorted) {
      sortedClassName = "sorted ";
      if (this.props.iconBoth) {
        sortIcon = this.props.iconBoth;
      } else {
        sortIcon = <SortIconBoth style={this.props.iconStyle} />;
      }
      if (this.props.sorting === "desc") {
        if (this.props.iconDesc) {
          sortIcon = this.props.iconDesc;
        } else {
          sortIcon = <SortIconDesc style={this.props.iconStyle} />;
        }
      } else if (this.props.sorting === "asc") {
        if (this.props.iconAsc) {
          sortIcon = this.props.iconAsc;
        } else {
          sortIcon = <SortIconAsc style={this.props.iconStyle} />;
        }
      }
    }

    const className =
      sortedClassName + ((classNameProps || "") + " sortable").trim();

    return { className, sortIcon };
  };

  render() {
    if (!this.props) return null;

    const { data, header } = this.props;

    const { className, sortIcon } = this._getSortIconAndClass();

    const headerText = typeof header === "function" ? header(data) : header;

    return (
      <th
        style={this.props.style}
        onClick={this._onClick}
        {...this.props.headerProps}
        className={className}
      >
        <span>{headerText}</span>
        {sortIcon}
      </th>
    );
  }
}

export default class SortableTableHeader<T, TKey> extends React.Component<
  Props<T, TKey>
> {
  _onClick = (index: number) => {
    this.props.onStateChange.call(this, index);
  };

  render() {
    const headers = this.props.columns.map((column, index) => {
      const sorting = this.props.sortings[index];
      const isSorted = this.props.sortingIndex === index;
      return (
        <SortableTableHeaderItem
          sortable={column.sortable}
          isSorted={isSorted}
          key={index}
          index={index}
          header={column.header}
          data={this.props.data}
          sorting={sorting}
          onClick={this._onClick}
          style={column.headerStyle}
          headerProps={column.headerProps}
          iconStyle={this.props.iconStyle}
          iconDesc={this.props.iconDesc}
          iconAsc={this.props.iconAsc}
          iconBoth={this.props.iconBoth}
        />
      );
    });

    return (
      <thead>
        <tr>{headers}</tr>
      </thead>
    );
  }
}
