import graphql from "babel-plugin-relay/macro";

export const participantCasesGraphqlQuery = graphql`
  query ParticipantCasesGraphqlQuery($id: String!) {
    me {
      case_manager {
        id
        name {
          first
          last
        }
      }
    }
    participants(id: $id) {
      participant {
        id
        name {
          first
          last
        }
        cases {
          id
          case_number
          supervision_start_date
          supervision_end_date
          created_at
          updated_at
          housing {
            status
          }
          employment {
            status
          }
          education {
            status
          }
          treatment_types {
            id
          }
          group_meetings {
            id
          }
          courts {
            id
          }
          transportation {
            status
          }
          primary_treatment_facilities {
            id
          }
          did_recidivate
          recidivism_reason
          case_type
          medicated
          program_result
          risk_level
          risk_score
          assessment_score
          hand_off_to_probation
          absconded
          is_emh
        }
      }
    }
  }
`;
