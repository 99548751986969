import React, { useState } from "react";
import { Columns, Button, Modal } from "react-bulma-components";
import { QueryRenderer } from "../../shared/lib/graphql";
import {
  hydrateParticipantFormDetailsData,
  ParticipantFormCellData,
  getParticipantFormDetailsData,
} from "./participantDetailsFormData";
import { ParticipantFormCell, StorageType } from "./ParticipantFormCell";
import idx from "idx.macro";
import { updateParticipant } from "../../actions/participants";
import {
  ParticipantDetailsQuery,
  ParticipantDetailsQueryResult,
} from "../screens/ParticipantDetailsQueryTypes";
import "./ParticipantProfile.scss";
import moment, { Moment } from "moment";
import { Maybe, PersonImage } from "../../graphql-types";
import { Publish } from "@material-ui/icons";
import { Create } from "@material-ui/icons";

export interface CarePlanContainerProps {
  id: string;
}

export function ParticipantProfileContainer(props: CarePlanContainerProps) {
  const { id } = props;

  const [selectedEditField, setSelectedEditField] = useState("");
  const [selectedUploadImage, setSelectedUploadImage] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [base64Image, setBase64Image] = useState("");

  const onEdit = (fieldname: string) => {
    setSelectedEditField(fieldname);
  };

  const onSave = async (
    fieldName: string,
    data: string | { [key: string]: any },
    participant: any,
    storageType?: StorageType
  ) => {
    const baseFields = getUpdateParticipantBaseVariables(participant);

    const finalFields =
      typeof data !== "object"
        ? { ...baseFields, [fieldName]: data }
        : storageType === StorageType.Array
        ? {
            ...baseFields,
            [fieldName]: Object.keys(data).reduce(
              (acc: string[], key: string) => {
                if (data[key] === true) {
                  acc.push(key);
                }
                return acc;
              },
              [] as string[]
            ),
          }
        : { ...baseFields, ...data };

    await updateParticipant(finalFields, participant as any);
  };

  const handleFileUpload = async (participant: any) => {
    const { type } = selectedUploadImage as any;
    const base64 = await toBase64(selectedUploadImage);

    await onSave("", { base64_media: base64, mime_type: type }, participant);
    setSelectedUploadImage(null);
    setIsImageModalOpen(false);
  };

  const toBase64 = (file: any): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      //@ts-ignore
      reader.onload = () => resolve(reader.result);

      //@ts-ignore
      reader.onerror = (error) => reject(error);
    });

  const handleFileInputChange = async (event: any) => {
    setSelectedUploadImage(event.target.files[0]);
    const base64 = await toBase64(event.target.files[0]);
    setBase64Image(base64);
  };

  const handleImageModalClose = () => {
    setIsImageModalOpen(false);
    setSelectedUploadImage(null);
    setBase64Image("");
  };

  return (
    <QueryRenderer
      query={ParticipantDetailsQuery}
      variables={{ id }}
      SuccessComponent={(props: ParticipantDetailsQueryResult) => {
        const participant = idx(props, (_) => _.participants.participant[0]);
        const profileImage = participant.profile_image as PersonImage;
        const participantFields = extractFieldsFromParticipant(participant);
        const participantFormDetailsField = getParticipantFormDetailsData(
          participantFields
        );
        const participantFormDetailsDataHydrated = hydrateParticipantFormDetailsData(
          participantFields,
          participantFormDetailsField
        );
        let myInput: any;

        return (
          <Columns>
            <Modal
              show={isImageModalOpen}
              onClose={() => handleImageModalClose()}
            >
              <Modal.Card>
                <Modal.Card.Head onClose={() => handleImageModalClose()}>
                  <Modal.Card.Title>Edit profile image</Modal.Card.Title>
                </Modal.Card.Head>
                <Modal.Card.Body align="center">
                  {base64Image && (
                    <img style={{ height: 274 }} src={base64Image} />
                  )}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Button onClick={() => myInput.click()}>
                      <Publish nativeColor={"#797879"} />
                      {`${base64Image ? "Change" : "Select"} image`}
                    </Button>

                    {selectedUploadImage && (
                      <>
                        <Button
                          style={{ marginLeft: 5 }}
                          onClick={() => handleFileUpload(participant)}
                          color="primary"
                        >
                          Save
                        </Button>
                        <Button
                          style={{ marginLeft: 5 }}
                          onClick={() => handleImageModalClose()}
                          color="danger"
                        >
                          Cancel
                        </Button>
                      </>
                    )}
                  </div>
                </Modal.Card.Body>
              </Modal.Card>
            </Modal>
            <Columns.Column size={4}>
              <Columns align="center">
                <Columns.Column size={12}>
                  <div className="profile-image-container">
                    {profileImage ? (
                      <img
                        style={{ height: 274 }}
                        src={`data:${profileImage.mime_type};base64,${profileImage.base64_media}`}
                      />
                    ) : (
                      <img
                        style={{ height: 274 }}
                        src={
                          "https://thelightingagency.com/wp-content/uploads/2017/01/person-placeholder.jpg"
                        }
                      />
                    )}

                    <div
                      className="edit-image-box"
                      onClick={() => setIsImageModalOpen(true)}
                    >
                      <p>Edit Image</p>
                      <Create
                        className="show-edit"
                        nativeColor="#f2f2f2"
                        style={{
                          fontSize: 16,
                          marginRight: 5,
                          marginLeft: 10,
                        }}
                      />
                    </div>
                  </div>
                </Columns.Column>
                <Columns.Column size={12}>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    onChange={handleFileInputChange}
                    ref={(fileInput) => (myInput = fileInput)}
                  />
                </Columns.Column>
              </Columns>
              {participantFormDetailsDataHydrated[0].map(
                (participantFormRow: ParticipantFormCellData[], index) => (
                  <Columns key={`participantFormRow_${index}`}>
                    {participantFormRow.map(
                      (
                        participantFormCell: ParticipantFormCellData,
                        innderIndex
                      ) => (
                        <ParticipantFormCell
                          key={`participantFormCellData_row${index}_column_${innderIndex}`}
                          size={12 / participantFormRow.length}
                          value={participantFormCell.value}
                          label={participantFormCell.label}
                          type={participantFormCell.type}
                          fieldName={participantFormCell.fieldName}
                          options={participantFormCell.options}
                          onSave={async (
                            fieldName: string,
                            data: string | { [key: string]: boolean },
                            storageType: StorageType | undefined
                          ) =>
                            await onSave(
                              fieldName,
                              data,
                              participant,
                              storageType
                            )
                          }
                          onEditCallback={onEdit}
                          selectedEditField={selectedEditField}
                          storageType={participantFormCell.storageType}
                        />
                      )
                    )}
                  </Columns>
                )
              )}
            </Columns.Column>

            <Columns.Column size={8}>
              {participantFormDetailsDataHydrated[1].map(
                (participantFormRow: ParticipantFormCellData[], index) => (
                  <Columns key={`participantFormRow_${index}`}>
                    {participantFormRow.map(
                      (
                        participantFormCell: ParticipantFormCellData,
                        innderIndex
                      ) => (
                        <ParticipantFormCell
                          key={`participantFormCellData_row${index}_column_${innderIndex}`}
                          size={12 / participantFormRow.length}
                          value={participantFormCell.value}
                          label={participantFormCell.label}
                          type={participantFormCell.type}
                          fieldName={participantFormCell.fieldName}
                          options={participantFormCell.options}
                          onSave={async (
                            fieldName: string,
                            data: string | Object,
                            storageType: StorageType | undefined
                          ) =>
                            await onSave(
                              fieldName,
                              data,
                              participant,
                              storageType
                            )
                          }
                          onEditCallback={onEdit}
                          selectedEditField={selectedEditField}
                          storageType={participantFormCell.storageType}
                        />
                      )
                    )}
                  </Columns>
                )
              )}
            </Columns.Column>
            <Columns.Column size={12} style={{ marginTop: "20px" }}>
              <ParticipantFormCell
                size={12}
                value={participantFields["incident_notes"] as string}
                label={"File Notes"}
                type={"textarea"}
                fieldName={"incident_notes"}
                onSave={async (
                  fieldName: string,
                  data: string | Object,
                  storageType: StorageType | undefined
                ) => await onSave(fieldName, data, participant, storageType)}
                onEditCallback={onEdit}
                selectedEditField={selectedEditField}
              />
            </Columns.Column>
          </Columns>
        );
      }}
    />
  );
}

function extractFieldsFromParticipant(
  participant: any
): { [key: string]: Maybe<string | Moment | { [key: string]: any }> } {
  return {
    last: participant.name.last,
    first: participant.name.first,
    supervision_begin_date: participant.supervision_begin_date
      ? moment(participant.supervision_begin_date)
      : null,
    supervision_end_date: participant.supervision_end_date
      ? moment(participant.supervision_end_date)
      : null,
    date_of_birth: participant.date_of_birth
      ? moment(participant.date_of_birth)
      : null,
    mobile: participant.cell_phone_human_readable,
    home_phone: participant.home_phone_human_readable,
    devices: participant.devices,
    hair_color: participant.hair_color,
    driver_license_number: participant.driver_license_number,
    state_id_number: participant.state_id_number,
    hispanic_or_latino: participant.hispanic_or_latino,
    race: participant.race,
    risk_level: participant.risk_level,
    assessment_tool: participant.assessment.tool,
    assessment_score: participant.assessment.score,
    monitoring_devices: {
      alcohol_monitoring: participant.alcohol_monitoring,
      gps_monitoring: participant.gps_monitoring,
    },
    sex: participant.sex,
    equipment_returned: participant.equipment_returned
      ? participant.equipment_returned.reduce(
          (acc: { [key: string]: boolean }, key: string) => {
            acc[key] = true;
            return acc;
          },
          {} as { [key: string]: boolean }
        )
      : [],
    employer_name: participant.employer ? participant.employer.name : "",
    hair: participant.hair,
    eye_color: participant.eye_color,
    height: participant.height,
    weight: participant.weight,
    person_address: participant.person_address,
    place_of_birth: participant.place_of_birth,
    id_number: participant.person_id.number,
    id_type: participant.person_id.type,
    id_state: participant.person_id.state,
    victims: participant.victims,
    case_number: participant.case_number,
    extra_equipment: participant.extra_equipment,
    incident_notes: participant.incident_notes,
    offense_info: participant.offense_info,
    protective_order: {
      stay_away: participant.stay_away,
      peaceful_contact: participant.peaceful_contact,
    },
    record_source: participant.record_source,
    court_recommendation: participant.court_recommendation,
    court_decision: participant.court_decision,
    case_disposition: participant.case_disposition,
  };
}

export function getUpdateParticipantBaseVariables(participant: any) {
  return {
    first: participant.name.first || "",
    last: participant.name.last || "",
    mobile: participant.cell_phone_human_readable || "",
    home_phone: participant.home_phone_human_readable || "",
    language: participant.language || "",
    assessment_score: participant.assessment.score || "",
    assessment_tool: participant.assessment.tool || "",
    id: participant.id,
    risk_level: participant.risk_level || "Unknown",
    case_manager_id: participant.case_manager.id,
    preferred_name: participant.preferred_name || "",
    supervision_begin_date: participant.supervision_begin_date || "",
    supervision_end_date: participant.supervision_end_date || "",
    compliance: participant.compliance || "",
    sms_enabled: participant.sms_enabled || "",
    sms_disabled_by: participant.sms_disabled_by || "",
    sms_consent: participant.sms_consent || "",
    hispanic_or_latino: participant.hispanic_or_latino || "",
    race: participant.race || "Unknown",
    alcohol_monitoring: participant.alcohol_monitoring || "",
    gps_monitoring: participant.gps_monitoring || "",
    date_of_birth: participant.date_of_birth || "",
    sex: participant.sex || "unknown",
    employer_name: participant.employer ? participant.employer.name : "",
    hair: participant.hair || "unknown",
    eye_color: participant.eye_color || "unknown",
    height: participant.height || "",
    weight: participant.weight || "",
    person_address: participant.person_address || "",
    place_of_birth: participant.place_of_birth || "",
    id_number: participant.person_id.number || "",
    id_type: participant.person_id.type || "unknown",
    id_state: participant.person_id.state || "",
    victims: participant.victims || [],
    case_number: participant.case_number || "",
    extra_equipment: participant.extra_equipment || "",
    incident_notes: participant.incident_notes || "",
    offense_info: participant.offense_info || "",
    base64_media: participant.base64_media || "",
    mime_type: participant.mime_type || "",
    peaceful_contact: participant.peaceful_contact,
    stay_away: participant.stay_away,
    record_source: participant.record_source || "",
    court_recommendation: participant.court_recommendation || "",
    court_decision: participant.court_decision || "",
    case_disposition: participant.case_disposition || "",
    equipment_returned: participant.equipment_returned,
    protective_order_notes: participant.protective_order_notes || "",
  };
}
