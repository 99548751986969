import React, { useReducer, useState } from "react";
import {
  ParticipantAssessmentResultScore,
  Maybe,
  AssessmentTemplateRange,
  ParticipantAssessmentResultStatus,
} from "../../graphql-types";
import { safelyUnwrapArrayWithPossibleNullOrUndefined } from "../../utils/safeUnwrap";
import { Form, Level } from "react-bulma-components";
import { Button } from "antd";
import { scoreParticipantResult } from "../../actions/participantAssessmentResult";
import { DownloadPDF } from "../../shared/components/elements/DownloadPDF";
import { Loader } from "../../shared/components/elements/Loader";
import { DownloadMessage } from "../screens/AnalyticsReportContainer";

interface ScoreContainerProps {
  scores: ParticipantAssessmentResultScore[];
  totalScore: Maybe<number>;
  rating: Maybe<string>;
  ranges: AssessmentTemplateRange[];
  assessmentResultId: string;
}

interface ScoreState {
  value: string;
  score: number;
  id: string;
  type?: string;
  verified: Maybe<boolean> | string;
}

export function ScoresContainer(props: ScoreContainerProps) {
  const { scores, ranges, assessmentResultId } = props;

  const initialOptionsValues: {
    [key: string]: ScoreState;
  } = scores.reduce(
    (acc, participantAssessmentResultScore) => {
      const { score, value, verified, id } = participantAssessmentResultScore;
      acc[id] = {
        id,
        score: score || 0,
        value: value || "Select",
        verified: verified,
      };
      return acc;
    },
    {} as {
      [key: string]: ScoreState;
    }
  );

  const [questionData, questionDataDispatch] = useReducer(
    (state, action: ScoreState) => {
      if (!action) {
        return state;
      }

      if (!action.id) {
        return state;
      }

      if (action.type === "score") {
        const { score, value, id } = action;

        return {
          ...state,
          [id]: {
            ...state[id],
            score,
            value,
          },
        };
      }

      const { verified, id } = action;

      return {
        ...state,
        [id]: {
          ...state[id],
          verified: verified === "Y" ? true : false,
        },
      };
    },
    initialOptionsValues
  );

  const [downloadingMessage, setDownloadingMessage] = useState(
    null as Maybe<DownloadMessage>
  );

  const totalScore = Object.keys(questionData).reduce((acc, questionId) => {
    if (!questionData[questionId].score) {
      return acc;
    }
    acc += questionData[questionId].score;
    return acc;
  }, 0);

  const rating = ranges.reduce((acc: string, range) => {
    const {
      min_score: minScore,
      max_score: maxScore,
      rating: rangeRating,
    } = range;

    if (totalScore < minScore) {
      return acc;
    }

    if (maxScore && totalScore > maxScore) {
      return acc;
    }

    acc = rangeRating;
    return acc;
  }, "Low");

  const isFinalizeDisabled = !scores.every((score) => {
    return (
      questionData.hasOwnProperty(score.id) &&
      questionData[score.id].value !== "Select" &&
      questionData[score.id].verified !== undefined &&
      questionData[score.id].verified !== null
    );
  });

  const handleFinalizeButtonClicked = async () => {
    await scoreParticipantResult({
      participant_assessment_result_id: assessmentResultId,
      status: ParticipantAssessmentResultStatus.Finalized,
      assessment_result_scores: Object.keys(questionData).map((id) => {
        return {
          id,
          ...questionData[id],
        };
      }),
    });
  };

  const handleDownloadButtonClicked = async (
    downloadPDF: (id?: string, name?: string) => Promise<void>
  ) => {
    setDownloadingMessage(DownloadMessage.DOWNLOADING_REPORT);
    await downloadPDF(assessmentResultId, "Assessment");
    setDownloadingMessage(DownloadMessage.DOWNLOADED);
    setTimeout(() => {
      setDownloadingMessage(null);
    }, 1000);
  };

  return (
    <>
      <DownloadPDF>
        {({
          downloadPDF,
        }: {
          downloadPDF: (id?: string, name?: string) => Promise<void>;
        }) => {
          return (
            <div className="assessment-info-container">
              <div style={{ margin: "5px" }}>
                <Level>
                  <Level.Side align="right"></Level.Side>
                  <Level.Side align="right">
                    <Level.Item className="assessment-score-box-label">
                      <p>Verified</p>
                    </Level.Item>
                  </Level.Side>
                </Level>
              </div>
              <div>
                {scores.map((score) => (
                  <ScoreContainer
                    score={score}
                    key={score.id}
                    scoreCallback={questionDataDispatch}
                    questionData={questionData}
                  />
                ))}
              </div>
              <Level>
                <Level.Side align="left"></Level.Side>
                <Level.Side align="right">
                  <Level.Item>
                    <p style={{ margin: "0px" }}>Total Score:</p>
                  </Level.Item>
                  <Level.Item>
                    <p className="assessment-score-box">{totalScore}</p>
                  </Level.Item>
                  <Level.Item>
                    <p style={{ margin: "0px" }}>Rating:</p>
                  </Level.Item>
                  <Level.Item>
                    <p className="assessment-score-box">{rating}</p>
                  </Level.Item>
                </Level.Side>
              </Level>
              <Level>
                <Level.Side align="left"></Level.Side>
                <Level.Side align="right" className="hide-on-print">
                  <Level.Item>
                    <Button
                      type="primary"
                      disabled={isFinalizeDisabled}
                      onClick={handleFinalizeButtonClicked}
                    >
                      Save
                    </Button>
                  </Level.Item>
                  <Level.Item>
                    <Button
                      onClick={() => handleDownloadButtonClicked(downloadPDF)}
                    >
                      Download PDF
                    </Button>
                  </Level.Item>
                </Level.Side>
              </Level>
            </div>
          );
        }}
      </DownloadPDF>
      {downloadingMessage === DownloadMessage.DOWNLOADING_REPORT ? (
        <div className="status-message saving">
          <span className="statusSpace">
            {downloadingMessage}
            <Loader />
          </span>
        </div>
      ) : null}
      {downloadingMessage === DownloadMessage.DOWNLOADED ? (
        <div className="status-message saving">
          <span className="statusSpace">{downloadingMessage}</span>
        </div>
      ) : null}
    </>
  );
}

function ScoreContainer(props: {
  score: ParticipantAssessmentResultScore;
  scoreCallback: React.Dispatch<any>;
  questionData: { [key: string]: ScoreState };
}) {
  const { score, questionData, scoreCallback } = props;
  const {
    id: questionId,
    question_number: questionNumber,
    question_text: questionText,
    question_options: questionOptions,
  } = score;

  const unwrappedQuestionOptions = safelyUnwrapArrayWithPossibleNullOrUndefined(
    questionOptions
  );

  const handleScoreSelectChange = (event: any) => {
    const { value } = event.target;

    const questionValue = unwrappedQuestionOptions.find(
      (questionOption) => questionOption.text === value
    );

    scoreCallback({
      id: questionId,
      value: value,
      score: questionValue ? questionValue.value : 0,
      type: "score",
    });
  };

  const handleVerificationSelectChange = (event: any) => {
    const { value } = event.target;

    scoreCallback({
      id: questionId,
      verified: value,
      type: "verification",
    });
  };

  return (
    <div style={{ margin: 5 }}>
      <Level style={{ marginBottom: 0 }}>
        <Level.Side align="right" style={{ marginBottom: 0 }}>
          <Level.Item>
            <p>{`${questionNumber || 0}.`}</p>
            <p
              style={{
                marginLeft: 10,
                fontWeight: "bold",
              }}
            >
              {questionText}
            </p>
          </Level.Item>
        </Level.Side>

        <Level.Side align="left">
          <Level.Item>
            <Form.Control className="hide-on-print">
              <Form.Select
                value={
                  questionData[questionId] && questionData[questionId].value
                    ? questionData[questionId].value
                    : null
                }
                onChange={handleScoreSelectChange}
                name={questionId}
              >
                <option>Select</option>
                {unwrappedQuestionOptions.map((unwrappedQuestionOption) => (
                  <option
                    key={unwrappedQuestionOption.text}
                    value={unwrappedQuestionOption.text}
                  >
                    {unwrappedQuestionOption.text}
                  </option>
                ))}
              </Form.Select>
            </Form.Control>
          </Level.Item>
          <Level.Item>
            <Form.Control>
              <p className="assessment-score-box">
                {questionData[questionId] && questionData[questionId].score
                  ? questionData[questionId].score
                  : 0}
              </p>
            </Form.Control>
          </Level.Item>
          <Level.Item>
            <Form.Control className="hide-on-print">
              <Form.Select
                value={
                  questionData[questionId]
                    ? questionData[questionId].verified === true
                      ? "Y"
                      : questionData[questionId].verified === false
                      ? "N"
                      : "Select"
                    : "Select"
                }
                onChange={handleVerificationSelectChange}
                name={questionNumber}
              >
                <option>Select</option>
                <option>N</option>
                <option>Y</option>
              </Form.Select>
            </Form.Control>
          </Level.Item>
          <Level.Item className="show-on-print-only">
            <Form.Control>
              <p className="assessment-score-box">
                {questionData[questionId]
                  ? questionData[questionId].verified === true
                    ? "Y"
                    : questionData[questionId].verified === false
                    ? "N"
                    : "Select"
                  : "Select"}
              </p>
            </Form.Control>
          </Level.Item>
        </Level.Side>
      </Level>
      <div style={{ marginLeft: 30 }}>
        {unwrappedQuestionOptions.map((unwrappedQuestionOption, index) => (
          <p
            key={index}
            style={{ marginBottom: "2px" }}
          >{`${unwrappedQuestionOption.value}=${unwrappedQuestionOption.text}`}</p>
        ))}
      </div>
    </div>
  );
}
