import * as React from "react";
import idx from "idx.macro";
import { Collapse } from "antd";
import { CarePlanContext } from "../../utils/context";
import "./CarePlan.scss";
import { ActivityMeta, KeyedString } from "../../shared/lib/graphql/index";
import ObligationSelect, {
  SelectGroupedOptions,
} from "../../shared/components/elements/ObligationSelect";
import { AddObligationModal } from "../modals/obligations/AddObligationModal";
import {
  CarePlanParticipantType,
  CarePlanObligationType,
} from "./CarePlanQuery";
import { CarePlanItem, CarePlanHeader } from "./CarePlanItem";
import { Level, Tag } from "react-bulma-components";
import { Operation } from "../modals/types";
import { getFeatureFlags } from "../../featureFlags";
import { getStatusColorGeneric } from "../../shared/lib/util";
import { Empty, Button } from "antd";
import { CarePlanSetup } from "./CarePlanSetup";

export interface CarePlanProps {
  participant: CarePlanParticipantType;
  activityInformation: Array<ActivityMeta>;
}

interface GroupedDataItem {
  key: string;
  options: Array<KeyedString>;
  title: string;
  // The activity ids within this group (all match to the same type key)
  ids: string[];
  obligations: Array<CarePlanObligationType>;
}

interface GroupedData {
  [key: string]: GroupedDataItem;
}

type obligationCollapseStateType = { [key: string]: boolean };

interface CarePlanStateType {
  obligationCollapseState: obligationCollapseStateType;
  activityToAdd: string | null;
  isAddCarePlanOpen: boolean;
}

export const customPanelStyle = {
  background: "#f7f9fa",
  borderRadius: 4,
  marginBottom: "24px",
  border: 0,
  overflow: "hidden",
};

function convertObligationsToInitialCollapseState(
  obligations: Array<CarePlanObligationType>
): obligationCollapseStateType {
  return obligations.reduce((acc, obligation) => {
    acc[obligation.id] = false;
    return acc;
  }, {} as obligationCollapseStateType);
}

export class CarePlan extends React.PureComponent<
  CarePlanProps,
  CarePlanStateType
> {
  constructor(props: CarePlanProps) {
    super(props);
    const {
      participant: { obligations },
    } = props;
    const obligationCollapseState = convertObligationsToInitialCollapseState(
      obligations
    );

    this.state = {
      obligationCollapseState: {
        ...obligationCollapseState,
      },
      activityToAdd: null,
      isAddCarePlanOpen: false,
    };
  }

  private onAddObligationSelectChanged = ({
    target,
  }: React.ChangeEvent<HTMLSelectElement>) => {
    const activityToAdd = idx(target, (_) => _.value) || null;
    this.setState({ activityToAdd });
  };

  private onObligationModalClose = () => {
    this.setState({ activityToAdd: null });
  };

  _toggleObligationCollapse = (obligation_id: string) => {
    this.setState((state) => ({
      obligationCollapseState: {
        [obligation_id]: !state.obligationCollapseState[obligation_id],
      },
    }));
  };

  toggleIsAddCarePlanOpen = (isAddCarePlanOpen: boolean) => {
    this.setState({ isAddCarePlanOpen });
  };

  _groupDataOnActivityType = () => {
    const { activityInformation, participant } = this.props;

    const groupedData: GroupedData = {};

    // ensures that we have all activities as defined, and in order
    activityInformation.forEach(({ key, title, care_plan: { type } }) => {
      if (!groupedData[type]) {
        groupedData[type] = {
          key: type,
          ids: [key],
          title: type,
          options: [],
          obligations: [],
        };
      } else {
        groupedData[type].ids.push(key);
      }
      groupedData[type].options.push({
        key,
        value: title || key,
      });
    });

    // add obligations to the activityInformation sections
    // this cast is pretty dumb, ts
    (participant.obligations as CarePlanObligationType[]).forEach(
      (obligation: any) => {
        const {
          care_plan: { type: title },
        } =
          activityInformation.find(({ key }) => {
            const id = idx(obligation, (_) => _.activity.id);
            const type = idx(obligation, (_) => _.activity.type);
            // really we should only match on one of these but given our perpetual battle with activities, a quick fix to more often succeed than fail
            return key === id || key === type;
          }) || ({ care_plan: {} } as any);

        if (!title) return;

        groupedData[title].obligations.push(obligation);
      }
    );

    // pick the .options for use with the select
    const groupedActivitiesForSelect = {} as SelectGroupedOptions;
    Object.keys(groupedData).forEach((key) => {
      groupedActivitiesForSelect[key] = groupedData[key].options;
    });

    return { groupedData, groupedActivitiesForSelect };
  };

  render() {
    const OBLIGATIONS_TITLE = {
      singular: "obligation",
      plural: "Obligations",
    };

    const { participant } = this.props;
    const {
      obligationCollapseState,
      activityToAdd,
      isAddCarePlanOpen,
    } = this.state;

    const { groupedActivitiesForSelect } = this._groupDataOnActivityType();
    const { obligations } = participant;

    const nonNullObligations = obligations.filter((obligation) => {
      return obligation !== null;
    });

    const sortedObligations = nonNullObligations.sort((a, b) => {
      if (!a.start) {
        return -1;
      }
      if (a.start > b.start) {
        return 1;
      }

      if (a.start < b.start) {
        return -1;
      }

      return 0;
    });

    const editableObligations = sortedObligations.filter((obligation) => {
      if (!obligation) {
        return false;
      }
      const { is_writable } = obligation;
      return is_writable !== false;
    });

    const permanentObligations = sortedObligations.filter((obligation) => {
      if (!obligation) {
        return false;
      }
      const { is_writable } = obligation;
      return is_writable === false;
    });

    const { Panel } = Collapse;

    const hasEditableObligations = editableObligations.length > 0;

    return (
      <div className="carePlan">
        <Level>
          <Level.Side align="left">
            <Level.Item>
              <h3 className="title is-4">{OBLIGATIONS_TITLE.plural}</h3>
            </Level.Item>
            {getFeatureFlags().supportsAddingObligationsToCarePlan ? (
              <Level.Item>
                <div className="obligationSelect is-pulled-right">
                  <ObligationSelect
                    groupedOptions={groupedActivitiesForSelect}
                    onChange={this.onAddObligationSelectChanged}
                    forcedInitialText="Add Obligation..."
                  />
                </div>
              </Level.Item>
            ) : null}
          </Level.Side>
        </Level>
        {activityToAdd && (
          <AddObligationModal
            type={OBLIGATIONS_TITLE.singular}
            operation={Operation.Add}
            participant={participant}
            activityId={activityToAdd}
            handleModalClose={this.onObligationModalClose}
          />
        )}

        {
          <CarePlanContext.Consumer>
            {({ compliance, obligation_status, updateObligationMutation }) => (
              <>
                {getFeatureFlags().supportsCarePlanSetup && (
                  <Button
                    type={isAddCarePlanOpen ? "default" : "primary"}
                    style={{ marginBottom: "20px" }}
                    onClick={() => {
                      this.toggleIsAddCarePlanOpen(!isAddCarePlanOpen);
                    }}
                  >
                    {isAddCarePlanOpen
                      ? "Close Careplan Setup"
                      : hasEditableObligations
                      ? "Edit Careplan"
                      : "Setup Careplan"}
                  </Button>
                )}

                {isAddCarePlanOpen && (
                  <CarePlanSetup
                    participant={participant}
                    toggleIsCarePlanOpen={this.toggleIsAddCarePlanOpen}
                    editableObligations={
                      editableObligations as CarePlanObligationType[]
                    }
                  />
                )}

                {getFeatureFlags().supportsCarePlanSetup &&
                  !isAddCarePlanOpen &&
                  !hasEditableObligations && (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}

                <Collapse bordered={false}>
                  {editableObligations.map((obligation, index) => (
                    <Panel
                      style={{
                        ...customPanelStyle,
                        borderLeft: `3px solid ${getStatusColorGeneric(
                          obligation.compliance
                        )}`,
                      }}
                      header={
                        <CarePlanHeader
                          participantId={participant.id}
                          isReadOnly={false}
                          complianceOptions={compliance}
                          obligation={obligation}
                          updateObligationMutation={updateObligationMutation}
                          obligationStatuses={obligation_status}
                          complianceValue={obligation.compliance}
                        />
                      }
                      key={index.toString()}
                    >
                      <CarePlanItem
                        isDeleteDisabled={!!obligation.activity.data_input_type}
                        index={index}
                        compliance={compliance}
                        obligationStatus={obligation_status}
                        updateObligationMutation={updateObligationMutation}
                        participant={participant}
                        key={obligation.id}
                        obligation={obligation}
                        toggleObligationCollapse={
                          this._toggleObligationCollapse
                        }
                        expanded={obligationCollapseState[obligation.id]}
                      />
                    </Panel>
                  ))}
                </Collapse>
                {permanentObligations.length > 0 && (
                  <div style={{ marginBottom: "10px", marginTop: "30px" }}>
                    <Level>
                      <Level.Side align="left">
                        <Level.Item>
                          <h3 className="title is-4">
                            Synced Obligations
                            <Tag
                              className="has-text-info"
                              style={{ marginLeft: 20 }}
                            >
                              Can't be edited - Updated Automatically
                            </Tag>
                          </h3>
                        </Level.Item>
                      </Level.Side>
                    </Level>
                  </div>
                )}
                <Collapse bordered={false}>
                  {permanentObligations.map((obligation, index) => (
                    <Panel
                      header={
                        <CarePlanHeader
                          participantId={participant.id}
                          isReadOnly={true}
                          complianceOptions={compliance}
                          obligation={obligation}
                          updateObligationMutation={updateObligationMutation}
                          obligationStatuses={obligation_status}
                          complianceValue={obligation.compliance}
                        />
                      }
                      style={{
                        ...customPanelStyle,
                        borderLeft: `3px solid ${getStatusColorGeneric(
                          obligation.compliance
                        )}`,
                      }}
                      key={index.toString()}
                    >
                      <CarePlanItem
                        index={index}
                        compliance={compliance}
                        obligationStatus={obligation_status}
                        updateObligationMutation={updateObligationMutation}
                        participant={participant}
                        key={obligation.id}
                        obligation={obligation}
                        toggleObligationCollapse={
                          this._toggleObligationCollapse
                        }
                        expanded={obligationCollapseState[obligation.id]}
                      />
                    </Panel>
                  ))}
                </Collapse>
              </>
            )}
          </CarePlanContext.Consumer>
        }
      </div>
    );
  }
}
