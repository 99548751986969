import graphql from "babel-plugin-relay/macro";
import { Application, Participant } from "../../../../shared/src/lib/graphql";

export type GoalTaskParticipant = Pick<
  Participant,
  "id" | "name" | "goals" | "tasks"
>;

export interface GoalTaskParticipants {
  participant: GoalTaskParticipant[];
}

export interface GoalsTasksQueryResult {
  application: Pick<Application, "task_config">;
  participants: GoalTaskParticipants;
}

export const GoalTaskQuery = graphql`
  query GoalTaskQuery($id: String!) {
    application {
      task_config {
        key
        label
      }
    }
    participants(id: $id) {
      participant {
        id
        name {
          first
          last
        }
        goals {
          id
          label
          completed_at
          created_at
        }
        tasks {
          id
          label
          completed_at
          created_at
          type
        }
      }
    }
  }
`;
