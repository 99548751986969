import React from "react";
import { Form, Row, Button } from "antd";
import { FormComponentProps } from "antd/es/form";
import { InterviewFormSection, InterviewFormItem } from "./shared";
import "./prospects.scss";
import { InputFormDataWithInputType } from "./types";
import { updateBookingInfoData } from "../../actions/bookings";
import { combineDateAndTimeMoment } from "../bookings/TransmittalCreation";

interface BookingInfoFormProps extends FormComponentProps {
  bookingFormFieldsWithInitialValue: InputFormDataWithInputType[];
  bookingId: string;
}

class BookingInfoForm extends React.Component<BookingInfoFormProps> {
  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err: any, values: any) => {
      if (!err) {
        const { bookingId } = this.props;
        const courtAppearanceDateTime = combineDateAndTimeMoment(
          values.court_date,
          values.court_time
        );

        await updateBookingInfoData({
          booking_id: bookingId,
          booking_info_update_data: {
            booking_date: values.booking_date,
            arrest_agency: values.arrest_agency,
            arrested_for: values.arrested_for,
            court_appearance_datetime: courtAppearanceDateTime
              ? courtAppearanceDateTime.toISOString()
              : null,
            court_room: values.court_room,
          },
        });
      }
    });
  };

  render() {
    const { bookingFormFieldsWithInitialValue } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit}>
        <InterviewFormSection
          sectionTitle={"Booking Information"}
          renderContents={() => (
            <>
              {bookingFormFieldsWithInitialValue.map(
                (bookingInformationFormField) => {
                  return (
                    <InterviewFormItem
                      key={bookingInformationFormField.id}
                      formData={bookingInformationFormField}
                      getFieldDecorator={getFieldDecorator}
                    />
                  );
                }
              )}
            </>
          )}
        />
        <Row type="flex" justify="center" gutter={24}>
          <Form.Item>
            <Button type="primary" size={"large"} htmlType="submit">
              Save Booking Info
            </Button>
          </Form.Item>
        </Row>
      </Form>
    );
  }
}

export const BookingInfoFormWrapped = Form.create<BookingInfoFormProps>({
  name: "Booking Info Form",
})(BookingInfoForm);
