import React, { useEffect } from "react";
import { Booking } from "../../graphql-types";
import { RecommendationFormPrintContainer } from "../prospects/RecommendationForm";

interface Props {
  booking: Booking;
}

export default function PrintRecommendation(props: Props) {
  const { booking } = props;

  useEffect(() => {
    document.title = `RECOMMENDATION FORM: ${booking.id}`;

    setTimeout(() => {
      window.print();
    }, 2000);
  });
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <RecommendationFormPrintContainer booking={booking} />
    </div>
  );
}
