import React from "react";
import { OfficeHoursType } from "../../../../shared/src/lib/types";

type Props = {
  officeHours: OfficeHoursType;
};

export default (props: Props) => {
  const { officeHours } = props;

  if (!officeHours.label) {
    return null;
  }
  return <div className="has-text-info">{officeHours.label}</div>;
};
