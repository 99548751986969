import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import graphql from "babel-plugin-relay/macro";
import idx from "idx.macro";
import { StringLike } from "@firebase/util";
import { Participant, QueryRenderer } from "../../../shared/lib/graphql/index";
import sniffProject from "../../../shared/lib/sniffProject";
import EventDetailsModal from "./EventDetailsModal";

interface Props {
  eventId: string;
  participantId: StringLike;
  button?: React.ReactElement<any>;
  mediaOnly?: boolean;
}

interface EventDetailsResponse {
  participants: {
    participant: Participant[];
  };
}

export default class EventDetailsQueryWrapper extends React.Component<
  RouteComponentProps<Props>
> {
  render() {
    return (
      <QueryRenderer
        query={graphql`
          query EventDetailsQueryWrapperQuery(
            $participant_id: String
            $event_id: String
          ) {
            participants(id: $participant_id) {
              participant {
                id
                name {
                  first
                  last
                }
                events(id: $event_id) {
                  id
                  date
                  attended
                  activity {
                    title {
                      en
                      es
                    }
                  }
                  obligation {
                    title {
                      en
                      es
                    }
                  }
                  input_field_name
                  input_response_text
                  input_response_media_mime_type
                  input_response_media_base64
                  offline_mode
                }
              }
            }
          }
        `}
        variables={{
          participant_id: this.props.participantId,
          event_id: this.props.eventId,
        }}
        SuccessComponent={(props: EventDetailsResponse) => {
          const participant = idx(props, (_) => _.participants.participant[0]);
          const event = idx(
            props,
            (_) => _.participants.participant[0].events[0]
          );

          const { input_response_media_base64 } = event;

          if (this.props.mediaOnly === true && !input_response_media_base64) {
            return null;
          }

          return (
            <EventDetailsModal
              projectId={sniffProject()}
              participant={participant}
              event={event}
            />
          );
        }}
      />
    );
  }
}
