import React from "react";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Icon,
  Row,
  Col,
  Typography,
  TimePicker,
} from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { AntdFieldState } from "../classes/CreateClass";
import { BookingChargeFormInput } from "./TransmittalCreation";

interface Props {
  form: WrappedFormUtils;
  onChange: (field: object) => void;
  bookingNumber: AntdFieldState;
  addCharge: (charge: BookingChargeFormInput) => void;
  charges: BookingChargeFormInput[];
  updateCharge: (
    chargeToUpdate: BookingChargeFormInput,
    indexToUpdate: number
  ) => void;
  removeCharge: (index: number) => void;
}

const { Text } = Typography;
const dateFormatList = ["MM/DD/YYYY", "MM/D/YYYY", "M/D/YYYY"];

class BookingForm extends React.Component<Props, any> {
  render() {
    const { addCharge, charges, updateCharge, removeCharge } = this.props;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 8 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 14 },
      },
    };

    const bookingChargeFormItems = charges.map((charge, index: number) => (
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Form {...formItemLayout}>
            <Form.Item required={false} label="Code">
              <Input
                name="chargeCode"
                value={charge.chargeCode}
                onChange={(event) => {
                  const { value, name } = event.target;
                  updateCharge({ ...charge, [name]: value }, index);
                }}
              />
            </Form.Item>
            <Form.Item required={false} label="Section">
              <Input
                name="chargeSection"
                value={charge.chargeSection}
                onChange={(event) => {
                  const { value, name } = event.target;
                  updateCharge({ ...charge, [name]: value }, index);
                }}
              />
            </Form.Item>
          </Form>

          {index > 0 && (
            <Icon
              className="dynamic-delete-button"
              type="minus-circle-o"
              onClick={() => removeCharge(index)}
            />
          )}
        </div>
      </>
    ));
    return (
      <Form {...formItemLayout}>
        <Form.Item label="Booking #" hasFeedback>
          {getFieldDecorator("bookingNumber", {
            rules: [
              { required: true, message: "A booking number is required" },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="First Name" hasFeedback>
          {getFieldDecorator("firstName", {
            rules: [{ required: true, message: "First name is required" }],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Last Name" hasFeedback>
          {getFieldDecorator("lastName", {
            rules: [{ required: true, message: "Last name is required" }],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Date of Birth" hasFeedback>
          {getFieldDecorator("dateOfBirth", {
            rules: [{ required: true, message: "Date of birth is required" }],
          })(<DatePicker format={dateFormatList} />)}
        </Form.Item>
        <Form.Item label="Court Date" hasFeedback>
          {getFieldDecorator("courtDate", {
            rules: [{ required: true, message: "Court date is required" }],
          })(<DatePicker format={dateFormatList} />)}
        </Form.Item>
        <Form.Item label="Court Time">
          {getFieldDecorator("courtTime", {
            rules: [
              {
                type: "object",
                required: true,
                message: "Court time is required!",
              },
            ],
          })(
            <TimePicker use12Hours={true} minuteStep={15} format={"h:mm a"} />
          )}
        </Form.Item>
        <Form.Item label="Court Room" hasFeedback>
          {getFieldDecorator("courtRoom", {
            rules: [{ required: true, message: "Court room is required" }],
          })(<Input placeholder={"e.g. 77"} />)}
        </Form.Item>
        <Form.Item label="PC" hasFeedback>
          {getFieldDecorator("pc", {
            rules: [{ required: true, message: "PC is required" }],
          })(<Input placeholder={"e.g. M"} />)}
        </Form.Item>
        <Row>
          <Col span="24">
            <Text>Charges</Text>
          </Col>
        </Row>
        {bookingChargeFormItems}
        <Row>
          <Col span="6">
            <Button
              type="dashed"
              onClick={() => addCharge({ chargeCode: "", chargeSection: "" })}
            >
              <Icon type="plus" /> Charge
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export const CreateBookingForm = Form.create<Props>({
  name: "step_one",
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields);
  },
  mapPropsToFields(props) {
    const fieldKeys = Object.keys(props).filter((key) => key !== "onChange");

    return fieldKeys.reduce((acc: any, key: any) => {
      acc[key] = Form.createFormField({
        //@ts-ignore
        ...props[key],
        //@ts-ignore
        value: props[key].value,
      });

      return acc;
    }, {});
  },
})(BookingForm);
