import React, { Component } from "react";
import DatePickerWrapper from "../../DatePickerWrapper";
import moment, { Moment } from "moment";
import "../ModalForm.scss";
import { Form, Columns } from "react-bulma-components";
import { Button } from "antd";
import ModalSelect from "../ModalSelect";
import ModalInput from "../ModalInput";
import {
  participantSelectOptions,
  serviceProviderSelectOptions,
} from "../../../stories/utils/formOptionData";
import "react-datepicker/dist/react-datepicker.css";
import {
  EVENT_TIME_INTERVAL,
  roundTimeIntervals,
} from "../../../shared/lib/modalFormHelpers";
import { FormField, ValidationItemType } from "../types";
import { ObligationAndParticipantDetailsType } from "./AddEventModalWrapper";
import { getFeatureFlags } from "../../../featureFlags";
import {
  getRecurrenceOptions,
  DO_NOT_REPEAT,
} from "../../care-plan/recurrenceOptions";
import { rrulestr } from "rrule";
import { createRRuleSetString } from "../../care-plan/createRRuleSetString";
import { capitalizeString } from "../../../shared/lib/format";
import { ModalInputTypes } from "../../../shared/lib/graphql/flowTypes";

type EventFormData = {
  participant: FormField;
  activity: FormField;
  serviceProvider: FormField;
  roomNumber: FormField;
  startDate: FormField<Moment>;
  endDate: FormField<Moment>;
  endDateIsEstimate: FormField;
  isRequiredByCourt: FormField;
  rrule: FormField;
};

type EventFormValidations = {
  participant: ValidationItemType;
  activity: ValidationItemType;
  serviceProvider: ValidationItemType;
  roomNumber: ValidationItemType;
  startDate: ValidationItemType;
  endDate: ValidationItemType;
};

export interface AddEventFormOptions {
  selectParticipant?: boolean;
  selectServiceProvider?: boolean;
}

interface Props extends AddEventFormOptions {
  handleModalFormInputChange: Function;
  addEvent: Function;
  handleDatePickerChange: Function;
  handleDatePickerRawChange: Function;
  attemptedSubmission: boolean;
  handleFormSubmit: Function;
  modalFormData: EventFormData;
  validations: EventFormValidations;
  updateObject: ObligationAndParticipantDetailsType;
}

class AddEventForm extends Component<Props> {
  _calculateEndTimeMinTime = () => {
    const { startDate, endDate } = this.props.modalFormData;
    if (!startDate.value || !endDate.value) {
      return roundTimeIntervals({
        date: moment().clone().startOf("day"),
        interval: EVENT_TIME_INTERVAL,
      });
    }
    return startDate.value.format("L") === endDate.value.format("L")
      ? roundTimeIntervals({
          date: startDate.value.clone().add(EVENT_TIME_INTERVAL, "minutes"),
          interval: EVENT_TIME_INTERVAL,
        })
      : roundTimeIntervals({
          date: endDate.value.clone().startOf("day"),
          interval: EVENT_TIME_INTERVAL,
        });
  };

  _calculateEndTimeMaxTime = () => {
    const { startDate, endDate } = this.props.modalFormData;
    if (!startDate.value || !endDate.value) {
      return roundTimeIntervals({
        date: moment().clone().endOf("day"),
        interval: EVENT_TIME_INTERVAL,
      });
    }
    return startDate.value.clone().endOf("day");
  };

  _handleFormSubmit = () => {
    const { selectParticipant, handleFormSubmit } = this.props;
    handleFormSubmit({ selectParticipant });
  };

  _createRuleSetString = (rrule: FormField, startDate: FormField<Moment>) => {
    const {
      updateObject: { end },
    } = this.props;
    if (rrule.value !== DO_NOT_REPEAT && startDate.value) {
      let rruleSetString = createRRuleSetString(
        rrule,
        startDate.value.toISOString(),
        end
      );
      return rruleSetString
        ? capitalizeString(rrulestr(rruleSetString).toText())
        : "";
    } else {
      return "";
    }
  };

  render() {
    const {
      handleModalFormInputChange,
      modalFormData,
      validations,
      attemptedSubmission,
      handleDatePickerChange,
      handleDatePickerRawChange,
      selectParticipant = true,
      selectServiceProvider = true,
      updateObject,
    } = this.props;

    const { participant, serviceProvider, startDate, rrule } = modalFormData;

    return (
      <div className="modal-form add-event-form">
        {selectParticipant ? (
          <ModalSelect
            modalStateKey="participant"
            label={participant.label}
            validations={validations}
            value={participant.value}
            name="participant"
            inputType={ModalInputTypes.Select}
            attemptedSubmission={attemptedSubmission}
            handleModalFormInputChange={handleModalFormInputChange}
            options={participantSelectOptions}
          />
        ) : (
          <ModalInput
            label={getFeatureFlags().clientDisplayTerm}
            value={`${updateObject.first} ${updateObject.last}`}
            name={"firstName"}
            placeholder={"Andy Rew"}
            inputType={ModalInputTypes.Name}
            disabled={true}
            attemptedSubmission={attemptedSubmission}
            handleModalFormInputChange={handleModalFormInputChange}
          />
        )}
        {selectServiceProvider ? (
          <Form.Field>
            <Columns className="modal-column">
              <Columns.Column className="first-half">
                <ModalSelect
                  modalStateKey="serviceProvider"
                  label={serviceProvider.label}
                  validations={validations}
                  value={serviceProvider.value}
                  name="serviceProvider"
                  inputType={ModalInputTypes.Select}
                  attemptedSubmission={attemptedSubmission}
                  handleModalFormInputChange={handleModalFormInputChange}
                  options={serviceProviderSelectOptions}
                />
              </Columns.Column>
            </Columns>
          </Form.Field>
        ) : (
          <ModalInput
            label={"Where"}
            value={`${updateObject.where}`}
            name={"lastName"}
            placeholder={"Program Office"}
            inputType={ModalInputTypes.Select}
            disabled={true}
            attemptedSubmission={attemptedSubmission}
            handleModalFormInputChange={handleModalFormInputChange}
          />
        )}

        <Columns className="modal-column">
          <Columns.Column size="8">
            <DatePickerWrapper
              inline={true}
              selectedDate={startDate}
              label={"Start Date"}
              placeholder={"--/--/----"}
              name={"startDate"}
              popperPlacement={"top"}
              validations={validations}
              onChange={(date: Moment) => {
                handleDatePickerChange({
                  value: date,
                  name: "startDate",
                  type: ModalInputTypes.StartDate,
                });
              }}
              attemptedSubmission={attemptedSubmission}
              onChangeRaw={handleDatePickerRawChange}
              type={ModalInputTypes.StartDate}
            />
          </Columns.Column>
          <Columns.Column size="4">
            <DatePickerWrapper
              selectedDate={startDate}
              inline={true}
              name={"startDate"}
              label={"Start Time"}
              placeholder={"--:-- --"}
              validations={validations}
              onChange={(date: Moment) => {
                handleDatePickerChange({
                  value: date,
                  name: "startDate",
                  type: "start-time-no-input",
                });
              }}
              attemptedSubmission={attemptedSubmission}
              onChangeRaw={handleDatePickerRawChange}
              showTimeSelect={true}
              showTimeSelectOnly={true}
              timeIntervals={EVENT_TIME_INTERVAL}
              dateFormat="LT"
              timeCaption="Time"
              popperPlacement="top"
              type={ModalInputTypes.StartTime}
            />
          </Columns.Column>
        </Columns>

        {getFeatureFlags().showRecurrence ? (
          <Columns className="modal-column">
            <Columns.Column>
              <ModalSelect
                modalStateKey="rrule"
                label={rrule.label}
                validations={validations}
                value={rrule.value}
                name="rrule"
                inputType={ModalInputTypes.SelectOptional}
                attemptedSubmission={attemptedSubmission}
                handleModalFormInputChange={handleModalFormInputChange}
                options={getRecurrenceOptions(startDate.value)}
                disabled={!startDate.value}
              />
              <p className="modal-extra-padded-p">
                {this._createRuleSetString(
                  modalFormData.rrule,
                  modalFormData.startDate
                )}
              </p>
            </Columns.Column>
          </Columns>
        ) : null}

        <Button
          type="primary"
          style={{ width: "100%" }}
          onClick={this._handleFormSubmit}
        >
          Next
        </Button>
      </div>
    );
  }
}

export default AddEventForm;
