/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type MinMaxInput = {
    readonly min: number;
    readonly max?: number | null;
};
export type CaseAnalyticsGraphqlQueryVariables = {
    readonly risk_levels?: ReadonlyArray<string | null> | null;
    readonly primary_treatment_facilities?: ReadonlyArray<string | null> | null;
    readonly treatment_types?: ReadonlyArray<string | null> | null;
    readonly medicated?: ReadonlyArray<string | null> | null;
    readonly group_meetings?: ReadonlyArray<string | null> | null;
    readonly hand_off_to_probation?: ReadonlyArray<string | null> | null;
    readonly housing_statuses?: ReadonlyArray<string | null> | null;
    readonly education_statuses?: ReadonlyArray<string | null> | null;
    readonly employment_statuses?: ReadonlyArray<string | null> | null;
    readonly transportation_statuses?: ReadonlyArray<string | null> | null;
    readonly courts?: ReadonlyArray<string | null> | null;
    readonly case_type?: ReadonlyArray<string | null> | null;
    readonly weeks_in_program?: ReadonlyArray<MinMaxInput | null> | null;
    readonly absconded?: ReadonlyArray<string | null> | null;
    readonly is_emh?: ReadonlyArray<string | null> | null;
    readonly gender?: ReadonlyArray<string | null> | null;
};
export type CaseAnalyticsGraphqlQueryResponse = {
    readonly case_stats: ReadonlyArray<{
        readonly total: number;
        readonly number_completed_total: number;
        readonly number_recidivated_total: number;
        readonly number_completed_successful: number;
        readonly number_completed_unsuccessful: number;
        readonly number_recidivism: number;
        readonly number_no_recidivism: number;
        readonly year: string;
    }>;
};
export type CaseAnalyticsGraphqlQuery = {
    readonly response: CaseAnalyticsGraphqlQueryResponse;
    readonly variables: CaseAnalyticsGraphqlQueryVariables;
};



/*
query CaseAnalyticsGraphqlQuery(
  $risk_levels: [String]
  $primary_treatment_facilities: [String]
  $treatment_types: [String]
  $medicated: [String]
  $group_meetings: [String]
  $hand_off_to_probation: [String]
  $housing_statuses: [String]
  $education_statuses: [String]
  $employment_statuses: [String]
  $transportation_statuses: [String]
  $courts: [String]
  $case_type: [String]
  $weeks_in_program: [MinMaxInput]
  $absconded: [String]
  $is_emh: [String]
  $gender: [String]
) {
  case_stats(risk_levels: $risk_levels, primary_treatment_facilities: $primary_treatment_facilities, treatment_types: $treatment_types, medicated: $medicated, group_meetings: $group_meetings, hand_off_to_probation: $hand_off_to_probation, housing_statuses: $housing_statuses, education_statuses: $education_statuses, employment_statuses: $employment_statuses, transportation_statuses: $transportation_statuses, courts: $courts, case_type: $case_type, weeks_in_program: $weeks_in_program, absconded: $absconded, is_emh: $is_emh, gender: $gender) {
    total
    number_completed_total
    number_recidivated_total
    number_completed_successful
    number_completed_unsuccessful
    number_recidivism
    number_no_recidivism
    year
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "risk_levels",
            "type": "[String]",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "primary_treatment_facilities",
            "type": "[String]",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "treatment_types",
            "type": "[String]",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "medicated",
            "type": "[String]",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "group_meetings",
            "type": "[String]",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "hand_off_to_probation",
            "type": "[String]",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "housing_statuses",
            "type": "[String]",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "education_statuses",
            "type": "[String]",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "employment_statuses",
            "type": "[String]",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "transportation_statuses",
            "type": "[String]",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "courts",
            "type": "[String]",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "case_type",
            "type": "[String]",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "weeks_in_program",
            "type": "[MinMaxInput]",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "absconded",
            "type": "[String]",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "is_emh",
            "type": "[String]",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "gender",
            "type": "[String]",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "case_stats",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "absconded",
                    "variableName": "absconded"
                },
                {
                    "kind": "Variable",
                    "name": "case_type",
                    "variableName": "case_type"
                },
                {
                    "kind": "Variable",
                    "name": "courts",
                    "variableName": "courts"
                },
                {
                    "kind": "Variable",
                    "name": "education_statuses",
                    "variableName": "education_statuses"
                },
                {
                    "kind": "Variable",
                    "name": "employment_statuses",
                    "variableName": "employment_statuses"
                },
                {
                    "kind": "Variable",
                    "name": "gender",
                    "variableName": "gender"
                },
                {
                    "kind": "Variable",
                    "name": "group_meetings",
                    "variableName": "group_meetings"
                },
                {
                    "kind": "Variable",
                    "name": "hand_off_to_probation",
                    "variableName": "hand_off_to_probation"
                },
                {
                    "kind": "Variable",
                    "name": "housing_statuses",
                    "variableName": "housing_statuses"
                },
                {
                    "kind": "Variable",
                    "name": "is_emh",
                    "variableName": "is_emh"
                },
                {
                    "kind": "Variable",
                    "name": "medicated",
                    "variableName": "medicated"
                },
                {
                    "kind": "Variable",
                    "name": "primary_treatment_facilities",
                    "variableName": "primary_treatment_facilities"
                },
                {
                    "kind": "Variable",
                    "name": "risk_levels",
                    "variableName": "risk_levels"
                },
                {
                    "kind": "Variable",
                    "name": "transportation_statuses",
                    "variableName": "transportation_statuses"
                },
                {
                    "kind": "Variable",
                    "name": "treatment_types",
                    "variableName": "treatment_types"
                },
                {
                    "kind": "Variable",
                    "name": "weeks_in_program",
                    "variableName": "weeks_in_program"
                }
            ],
            "concreteType": "CaseStat",
            "plural": true,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "total",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "number_completed_total",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "number_recidivated_total",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "number_completed_successful",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "number_completed_unsuccessful",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "number_recidivism",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "number_no_recidivism",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "year",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "CaseAnalyticsGraphqlQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "CaseAnalyticsGraphqlQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "CaseAnalyticsGraphqlQuery",
            "id": null,
            "text": "query CaseAnalyticsGraphqlQuery(\n  $risk_levels: [String]\n  $primary_treatment_facilities: [String]\n  $treatment_types: [String]\n  $medicated: [String]\n  $group_meetings: [String]\n  $hand_off_to_probation: [String]\n  $housing_statuses: [String]\n  $education_statuses: [String]\n  $employment_statuses: [String]\n  $transportation_statuses: [String]\n  $courts: [String]\n  $case_type: [String]\n  $weeks_in_program: [MinMaxInput]\n  $absconded: [String]\n  $is_emh: [String]\n  $gender: [String]\n) {\n  case_stats(risk_levels: $risk_levels, primary_treatment_facilities: $primary_treatment_facilities, treatment_types: $treatment_types, medicated: $medicated, group_meetings: $group_meetings, hand_off_to_probation: $hand_off_to_probation, housing_statuses: $housing_statuses, education_statuses: $education_statuses, employment_statuses: $employment_statuses, transportation_statuses: $transportation_statuses, courts: $courts, case_type: $case_type, weeks_in_program: $weeks_in_program, absconded: $absconded, is_emh: $is_emh, gender: $gender) {\n    total\n    number_completed_total\n    number_recidivated_total\n    number_completed_successful\n    number_completed_unsuccessful\n    number_recidivism\n    number_no_recidivism\n    year\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'c9e2401aa0abfceb163af3534621100d';
export default node;
