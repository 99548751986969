/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type MutationResult = "failure" | "skipped" | "success" | "%future added value";
export type ParticipantAssessmentResultStatus = "draft" | "finalized" | "new" | "%future added value";
export type ParticipantAssessmentResultScoresInput = {
    readonly value: string;
    readonly score: number;
    readonly verified: boolean;
    readonly id: string;
};
export type participantAssessmentResultScoreParticipantAssessmentResultMutationVariables = {
    readonly participant_assessment_result_id: string;
    readonly status: ParticipantAssessmentResultStatus;
    readonly assessment_result_scores: ReadonlyArray<ParticipantAssessmentResultScoresInput | null>;
};
export type participantAssessmentResultScoreParticipantAssessmentResultMutationResponse = {
    readonly scoreParticipantAssessmentResult: ({
        readonly participant_assessment_result: ({
            readonly id: string;
            readonly status: ParticipantAssessmentResultStatus;
            readonly case_number: string;
            readonly assessment_name: string;
            readonly participant_id: string;
            readonly assessment_id: string;
            readonly rating: string | null;
            readonly total_score: number | null;
            readonly scored_by: string;
            readonly created_at: string;
            readonly updated_at: string | null;
            readonly scores: ReadonlyArray<({
                readonly id: string;
                readonly assessment_template_question_id: string;
                readonly score: number | null;
                readonly value: string | null;
                readonly question_text: string;
                readonly question_number: number;
                readonly question_options: ReadonlyArray<({
                    readonly value: number;
                    readonly text: string;
                }) | null>;
            }) | null>;
            readonly ranges: ReadonlyArray<({
                readonly rating: string;
                readonly min_score: number;
                readonly max_score: number | null;
                readonly failure_rate: number | null;
                readonly failure_to_appear_rate: number | null;
                readonly new_arrest_rate: number | null;
            }) | null>;
        }) | null;
        readonly result: MutationResult;
        readonly description: string | null;
        readonly errors: ReadonlyArray<string | null> | null;
    }) | null;
};
export type participantAssessmentResultScoreParticipantAssessmentResultMutation = {
    readonly response: participantAssessmentResultScoreParticipantAssessmentResultMutationResponse;
    readonly variables: participantAssessmentResultScoreParticipantAssessmentResultMutationVariables;
};



/*
mutation participantAssessmentResultScoreParticipantAssessmentResultMutation(
  $participant_assessment_result_id: String!
  $status: ParticipantAssessmentResultStatus!
  $assessment_result_scores: [ParticipantAssessmentResultScoresInput]!
) {
  scoreParticipantAssessmentResult(participant_assessment_result_id: $participant_assessment_result_id, status: $status, assessment_result_scores: $assessment_result_scores) {
    participant_assessment_result {
      id
      status
      case_number
      assessment_name
      participant_id
      assessment_id
      rating
      total_score
      scored_by
      created_at
      updated_at
      scores {
        id
        assessment_template_question_id
        score
        value
        question_text
        question_number
        question_options {
          value
          text
        }
      }
      ranges {
        rating
        min_score
        max_score
        failure_rate
        failure_to_appear_rate
        new_arrest_rate
      }
    }
    result
    description
    errors
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "participant_assessment_result_id",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "status",
            "type": "ParticipantAssessmentResultStatus!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "assessment_result_scores",
            "type": "[ParticipantAssessmentResultScoresInput]!",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "rating",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "value",
        "args": null,
        "storageKey": null
    } as any), v4 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "scoreParticipantAssessmentResult",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "assessment_result_scores",
                    "variableName": "assessment_result_scores"
                },
                {
                    "kind": "Variable",
                    "name": "participant_assessment_result_id",
                    "variableName": "participant_assessment_result_id"
                },
                {
                    "kind": "Variable",
                    "name": "status",
                    "variableName": "status"
                }
            ],
            "concreteType": "NewParticipantAssessmentResult",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participant_assessment_result",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ParticipantAssessmentResult",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "status",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "case_number",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "assessment_name",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "participant_id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "assessment_id",
                            "args": null,
                            "storageKey": null
                        },
                        (v2 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "total_score",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "scored_by",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "created_at",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "updated_at",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "scores",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ParticipantAssessmentResultScore",
                            "plural": true,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "assessment_template_question_id",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "score",
                                    "args": null,
                                    "storageKey": null
                                },
                                (v3 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "question_text",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "question_number",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "question_options",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "AssessmentTemplateQuestionOption",
                                    "plural": true,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "text",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "ranges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "AssessmentTemplateRange",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "min_score",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "max_score",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "failure_rate",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "failure_to_appear_rate",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "new_arrest_rate",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "result",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "errors",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "participantAssessmentResultScoreParticipantAssessmentResultMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v4 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "participantAssessmentResultScoreParticipantAssessmentResultMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v4 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "participantAssessmentResultScoreParticipantAssessmentResultMutation",
            "id": null,
            "text": "mutation participantAssessmentResultScoreParticipantAssessmentResultMutation(\n  $participant_assessment_result_id: String!\n  $status: ParticipantAssessmentResultStatus!\n  $assessment_result_scores: [ParticipantAssessmentResultScoresInput]!\n) {\n  scoreParticipantAssessmentResult(participant_assessment_result_id: $participant_assessment_result_id, status: $status, assessment_result_scores: $assessment_result_scores) {\n    participant_assessment_result {\n      id\n      status\n      case_number\n      assessment_name\n      participant_id\n      assessment_id\n      rating\n      total_score\n      scored_by\n      created_at\n      updated_at\n      scores {\n        id\n        assessment_template_question_id\n        score\n        value\n        question_text\n        question_number\n        question_options {\n          value\n          text\n        }\n      }\n      ranges {\n        rating\n        min_score\n        max_score\n        failure_rate\n        failure_to_appear_rate\n        new_arrest_rate\n      }\n    }\n    result\n    description\n    errors\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '475f6e16704ab97eb5706266b91c22e5';
export default node;
