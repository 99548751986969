import React from "react";
import { SelectFormData, SelectOption, InputFormData } from "./types";
import {
  ZeroToThreeOrMore,
  Maybe,
  YesNo,
  PretrialDecision,
  SentenceDecision,
} from "../../graphql-types";
import { Form, Select, Input, DatePicker, Card, TimePicker } from "antd";
import { InputType, InputFormDataWithInputType } from "./types";
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;

export const dateFormatList = ["MM/DD/YYYY", "MM/D/YYYY", "M/D/YYYY"];

export const formItemLayout = {
  labelCol: {
    xs: { span: 14 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 8 },
  },
};

export const DATE_PICKER_PLACEHOLDER = "MM/DD/YYYY";

export const FIELD_IS_REQUIRED_MESSAGE = "Field is required.";

export const NUMBER_REQUIRED_MESSAGE = "Must be a number.";

export const VALID_PHONE_NUMBER_MESSAGE =
  "Please enter valid, 10-digit, telephone number, e.g. (555)-555-5555";

export const pretrialDecisionOptions: SelectOption[] = [
  { id: PretrialDecision.OrGranted, title: "OR granted" },
  { id: PretrialDecision.OrDenied, title: "OR denied" },
  {
    id: PretrialDecision.PretrialReleaseWithConditions,
    title: "Pretrial release with conditions",
  },
  {
    id: PretrialDecision.ReleasedNoComplaintFiled,
    title: "Release no complaint filed",
  },
  {
    id: PretrialDecision.BailedOut,
    title: "Bail granted",
  },
  {
    id: PretrialDecision.CaseDismissed,
    title: "Case dismissed",
  },
  {
    id: PretrialDecision.Sentenced,
    title: "Sentenced",
  },
  {
    id: PretrialDecision.PetitionResolved,
    title: "Petition Resolved",
  },
];

export const pretrialSentenceOptions: SelectOption[] = [
  { id: SentenceDecision.Diversion, title: "Diversion" },
  { id: SentenceDecision.RestitutionOnly, title: "Restitution Only" },
  {
    id: SentenceDecision.ConditionalCase,
    title: "Conditional Case",
  },
  {
    id: SentenceDecision.SupervisedCase,
    title: "Supervised Case",
  },
];

export const zeroToThreeOrMoreOptions: SelectOption[] = [
  {
    id: ZeroToThreeOrMore.Zero,
    title: "0",
  },
  {
    id: ZeroToThreeOrMore.One,
    title: "1",
  },
  {
    id: ZeroToThreeOrMore.Two,
    title: "2",
  },
  {
    id: ZeroToThreeOrMore.Three,
    title: "3",
  },
  {
    id: ZeroToThreeOrMore.More,
    title: "More",
  },
];

export function SelectFormItem(
  props: SelectFormData & { getFieldDecorator: Function; handleBlur?: Function }
) {
  const {
    id,
    label,
    errorMessage,
    optionsArray,
    getFieldDecorator,
    initialValue,
    required,
    handleBlur,
  } = props;
  return (
    <Form.Item {...formItemLayout} label={label}>
      {getFieldDecorator(id, {
        initialValue: initialValue,
        rules: [
          {
            required,
            message: errorMessage,
          },
        ],
      })(
        <Select
          onBlur={() => {
            if (handleBlur) {
              handleBlur();
            }
          }}
        >
          {optionsArray.map(({ id, title }) => {
            return (
              <Option key={id} value={id}>
                {title}
              </Option>
            );
          })}
        </Select>
      )}
    </Form.Item>
  );
}

export function InputFormItem(
  props: InputFormData & { getFieldDecorator: Function; handleBlur?: Function }
) {
  const {
    id,
    label,
    errorMessage,
    getFieldDecorator,
    initialValue,
    required,
    handleBlur,
    validationPattern,
  } = props;
  return (
    <Form.Item {...formItemLayout} label={label}>
      {getFieldDecorator(id, {
        initialValue: initialValue,
        rules: [
          {
            required,
            pattern: validationPattern || undefined,
            message: errorMessage,
          },
        ],
      })(
        <Input
          onBlur={() => {
            if (handleBlur) {
              handleBlur();
            }
          }}
        />
      )}
    </Form.Item>
  );
}

export function TextAreaFormItem(
  props: InputFormData & { getFieldDecorator: Function; handleBlur?: Function }
) {
  const {
    id,
    label,
    errorMessage,
    getFieldDecorator,
    initialValue,
    required,
    handleBlur,
    validationPattern,
  } = props;
  return (
    <Form.Item {...formItemLayout} label={label}>
      {getFieldDecorator(id, {
        initialValue: initialValue,
        rules: [
          {
            required,
            pattern: validationPattern || undefined,
            message: errorMessage,
          },
        ],
      })(
        <TextArea
          onBlur={() => {
            if (handleBlur) {
              handleBlur();
            }
          }}
        />
      )}
    </Form.Item>
  );
}

export function DatePickerFormItem(
  props: InputFormData & { getFieldDecorator: Function; handleBlur?: Function }
) {
  const {
    id,
    label,
    errorMessage,
    getFieldDecorator,
    initialValue,
    placeholder,
    required,
    handleBlur,
  } = props;
  return (
    <Form.Item {...formItemLayout} label={label}>
      {getFieldDecorator(id, {
        initialValue: initialValue ? moment(initialValue) : null,
        rules: [
          {
            required,
            message: errorMessage,
          },
        ],
      })(
        <DatePicker
          onBlur={() => {
            if (handleBlur) {
              handleBlur();
            }
          }}
          format={dateFormatList}
          placeholder={placeholder}
        />
      )}
    </Form.Item>
  );
}

export function TimePickerFormItem(
  props: InputFormData & { getFieldDecorator: Function; handleBlur?: Function }
) {
  const {
    id,
    label,
    errorMessage,
    getFieldDecorator,
    initialValue,
    placeholder,
    required,
  } = props;
  return (
    <Form.Item {...formItemLayout} label={label}>
      {getFieldDecorator(id, {
        initialValue: initialValue ? moment(initialValue) : null,
        rules: [
          {
            required,
            message: errorMessage,
          },
        ],
      })(
        <TimePicker
          use12Hours={true}
          minuteStep={15}
          format={"h:mm a"}
          placeholder={placeholder}
        />
      )}
    </Form.Item>
  );
}

export const yesNoOptions: SelectOption[] = [
  {
    id: YesNo.Yes,
    title: "Yes",
  },
  {
    id: YesNo.No,
    title: "No",
  },
];

export function booleanToYesNo(value: boolean): YesNo {
  return value === true ? YesNo.Yes : YesNo.No;
}

export function yesNoToBoolean(value: Maybe<YesNo>): boolean {
  return value === YesNo.Yes ? true : false;
}

export function InterviewFormSection(props: {
  sectionTitle: string;
  renderContents: () => JSX.Element;
}) {
  const { sectionTitle, renderContents } = props;
  return (
    <div style={{ margin: "15px 0px" }}>
      <Card
        headStyle={{ backgroundColor: "#f7f9fa" }}
        title={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p style={{ margin: "0px" }}>{sectionTitle}</p>
          </div>
        }
      >
        {renderContents()}
      </Card>
    </div>
  );
}

export function InterviewFormItem(props: {
  formData: InputFormDataWithInputType;
  getFieldDecorator: Function;
  handleBlur?: Function;
}) {
  const { getFieldDecorator, handleBlur } = props;
  const {
    formData: { inputType, optionsArray },
  } = props;
  return (
    <>
      {inputType === InputType.Input && (
        <InputFormItem
          handleBlur={handleBlur}
          getFieldDecorator={getFieldDecorator}
          {...props.formData}
        />
      )}
      {inputType === InputType.TextArea && (
        <TextAreaFormItem
          handleBlur={handleBlur}
          getFieldDecorator={getFieldDecorator}
          {...props.formData}
        />
      )}
      {inputType === InputType.Date && (
        <DatePickerFormItem
          handleBlur={handleBlur}
          getFieldDecorator={getFieldDecorator}
          {...props.formData}
        />
      )}
      {inputType === InputType.Select && optionsArray && (
        <SelectFormItem
          handleBlur={handleBlur}
          getFieldDecorator={getFieldDecorator}
          {...props.formData}
          optionsArray={optionsArray}
        />
      )}
      {inputType === InputType.Time && (
        <TimePickerFormItem
          handleBlur={handleBlur}
          getFieldDecorator={getFieldDecorator}
          {...props.formData}
        />
      )}
    </>
  );
}

export const policeDepartmentAbbreviations: { [key: string]: string } = {
  SR: "San Rafael PD",
  NP: "Novato PD",
  SO: "Marin County Sheriff Department",
  CA: "California Highway Patrol",
  FP: "Fairfax PD",
  MV: "Mill Valley PD",
  BV: "Belvedere PD",
  RP: "Ross PD",
  SP: "Sausalito PD",
  SA: "San Anselmo PD",
  TP: "Tiburon PD",
  CM: "College of Marin Police",
  DE: "Drug Enforcement Agency",
  DC: "Department of Corrections",
  FB: "Federal Bureau of Investigations",
  FD: "Federal Officers",
  GG: "Golden Gate National Park",
  IN: "Immigration and Naturalization Service",
  IR: "Internal Revenue Service",
  OT: "Other",
  PR: "Point Reyes National Seashore",
  AP: "Marin County Probation",
  ST: "California Agencies/State Police",
  TF: "Drug Task Force",
  TC: "Twin Cities PD",
  TR: "Tri-County",
  CG: "United States Coast Guard",
  UM: "US Marshal",
  PP: "US Park Police",
  DA: "DA Investigation",
  CR: "Court",
  IF: "State of California Dept. Insurance Fraud",
  CMPA: "Central Marin Police Authority",
  FG: "California Department of Fish and Game",
};

export function getPoliceDepartmentAbbreviation(abbreviation: string) {
  return policeDepartmentAbbreviations[abbreviation] || abbreviation;
}
