import { TableDataItem, TableColumnKey } from "./tableTypes";

export function getByKey<T = TableDataItem, TKey = any, TResult = any>(
  obj: T,
  key: TableColumnKey<T, TKey>,
  index?: number
): any {
  if (typeof key === "string" || typeof key === "number") {
    if (typeof obj === "object") {
      // We don't know what this is, if caller specifies it, it will return typed
      return (obj as any)[key] as TResult;
    } else {
      throw new Error(
        "Invalid table column key. A string key requires that the data be an Object" +
          "but the type is " +
          typeof obj
      );
    }
  } else if (Array.isArray(key)) {
    var tmpObj = obj;

    for (let i = 0; i < key.length; i++) {
      if (typeof tmpObj === "object") {
        tmpObj = (tmpObj as any)[key[i]];
      } else {
        throw new Error(
          "Invalid table column key. A string key requires that the data be an Object" +
            "but the type is " +
            typeof tmpObj
        );
      }
    }

    return tmpObj;
  } else if (typeof key === "function") {
    return key(obj, index);
  } else {
    throw new Error("Unknown SortableTable key type (" + typeof key + ")");
  }
}
export function getEmailStatus(email: TableDataItem): string {
  if ((email as any).muted) {
    return "MUTED";
  } else if ((email as any).disabled) {
    return "LOCKED";
  }

  return "ACTIVE";
}
