// const dashboardBucketQueryIds = require("../queries/dashboard_bucket_query_ids_base.js");
export enum YesNo {
  Yes = "yes",
  No = "no",
}

export enum EventAttendence {
  Yes = "yes",
  No = "no",
  Excused = "excused",
  Rescheduled = "rescheduled",
}

export const NOT_SET = "Not provided";

export enum ModalInputTypes {
  Select = "select",
  SelectOptional = "selectOptional",
  Name = "name",
  NameOptional = "nameOptional",
  MiddleInitial = "middleInitial",
  FutureFuture = "futureFuture",
  Phone = "phone",
  StartTime = "startTime",
  StartDate = "startDate",
  EndTime = "endTime",
  EndDate = "endDate",
  Textarea = "Textarea",
  Required = "required",
  DateFuture = "dateFuture",
  Number = "number",
}

interface ParticipantMobileDevice {
  app_version: string;
  device_id: string;
  device_name: string;
  last_app_launch: string;
  platform: string;
  token: string;
}

interface PersonImage {
  base64_media: string;
  mime_type: string;
}

interface FinesAndFees {
  fines_and_fees_amount_owed: number;
  fines_and_fees_last_payment_amount: number;
  fines_and_fees_last_payment_date: string;
  fines_and_fees_expected_payment_amount: number;
  restitution_amount_owed: number;
  restitution_last_payment_amount: number;
  restitution_last_payment_date: string;
  restitution_expected_payment_amount: number;
}

/**
 * Use a type, but without one or more keys
 */
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type PagingParams = {
  id: string;
  ids: string;
  page_size: number;
  page_cursor: string;
};

export type Flags = {
  chat_enabled: boolean;
};

export interface ParticipantEmployer {
  name: string;
  address: string;
}

export type Application = {
  id: string;
  timezone: string;
  dashboard_buckets?: Array<DashboardBucket>;
  risk_levels: Array<KeyedString>;
  deactivate_reasons: Array<KeyedString>;
  reactivate_reasons: Array<KeyedString>;
  application_information: Array<ActivityMeta>;
  task_config: [TaskConfig];
  flags: Flags;
};

export type ActivityMeta = {
  key: string;
  title: string;
  care_plan: ActivityMetaCarePlan;
  timeline_event_type: string;
};

export type ActivityMetaCarePlan = {
  type: string;
};

export type PersonName = {
  first: string;
  last?: string;
};
export type KeyedString = {
  key: string;
  value?: string;
};
export type PersonPhone = {
  mobile: string;
  home: string;
};

interface PersonId {
  number: string;
  type: string;
  state: string;
}

export type ParticipantCoordinatorContact = {
  id: string;
  name: string;
  phone: string;
};
export type ServiceProvider = {
  id: string;
  address: string;
  phone?: string;
  sub_address_for_event?: string;
  title: string;
};

export type TaskConfig = {
  key: string;
  label: string;
  notifications: TaskNotificationConfig;
};

export type TaskNotificationConfig = {
  create: i18nText;
  update: i18nText;
  delete: i18nText;
};

export type LatLng = {
  lat: number;
  lng: number;
};
export type GeoLocationGeometry = {
  location: LatLng;
};
export type GeoLocation = {
  geometry: GeoLocationGeometry;
  place_id: string;
};
export type Event = {
  acknowledged?: boolean;
  attended?: string;
  date: string;
  id: string;
  geo?: GeoLocation;
  required: boolean;
  obligation?: Obligation;
  service_provider?: ServiceProvider;
  fs_path?: string;
  activity?: Activity;
  participant_id: string;
  time_specified: boolean;
  disposition: string;
  event_template: EventTemplate;
  input_response_media_base64: string;
  input_response_text: string;
  input_field_name: string;
  input_response_media_mime_type: string;
  offline_mode?: string;
};

export type EventTemplate = {
  id: string;
  exdate?: string;
  rrule?: string;
  events: Array<Event>;
};

export type Obligation = {
  id: string;
  activity: Activity;
  participant: Participant;
  service_provider: ServiceProvider;
  event_templates: Array<EventTemplate>;
  title: i18nText;
  description: i18nText;
  start: string;
  end: string;
  status: string;
  compliance: string;
  sub_address_for_event: string;
  is_writable: boolean;
  tag: string;
  cadence_option: string;
  day_of_week: number;
  day_of_month: number;
  verification_option: string;
};

export type Participant = {
  id: string;
  name: PersonName;
  created_at: string;
  language: string;
  notify_via: string;
  obligations: Array<Obligation>;
  phone: PersonPhone;
  events: Array<Event>;
  auth_token: string;
  fs_path: string;
  risk_level: string;
  status: string;
  compliance: string;
  case_manager: CaseManager;
  tasks: Array<ParticipantTask>;
  goals: Array<ParticipantGoal>;
  messages: {
    cursor?: string;
    page_info: PaginationInfo;
    message: Array<Message>;
  };
  sms_enabled: boolean;
  sms_disabled_by: SmsMessageActor;
  sms_consent: boolean;
  sms_can_enable: boolean;
  assessment: ParticipantAssessment;
  preferred_name?: string;
  is_active?: boolean;
  supervision_begin_date: string;
  supervision_end_date: string;
  address: {
    value: string;
  };
  hispanic_or_latino: boolean;
  race: string;
  alcohol_monitoring: boolean;
  gps_monitoring: boolean;
  date_of_birth: string;
  sex: string;
  employer: ParticipantEmployer;
  hair: string;
  eye_color: string;
  height: string;
  weight: string;
  person_address: string;
  place_of_birth: string;
  person_id: PersonId;
  victims: string;
  stay_away: boolean;
  peaceful_contact: boolean;
  case_number: string;
  extra_equipment: string;
  incident_notes: string;
  offense_info: string;
  current_device: ParticipantMobileDevice;
  profile_image: PersonImage;
  fines_and_fees: FinesAndFees;
  record_source: string;
  court_recommendation: string;
  court_decision: string;
  case_disposition: string;
  home_phone_human_readable: string;
  cell_phone_human_readable: string;
  equipment_returned: string[];
  protective_order_notes: string;
  probation_number: string;
};

export type ParticipantTask = {
  id: string;
  label: string;
  completed_at?: string;
  created_at: string;
  due_at?: string;
  type?: string;
};

export type ParticipantGoal = {
  id: string;
  label: string;
  completed_at?: string;
  created_at: string;
};

export type ParticipantNote = {
  id: string;
  label: string;
  created_at: string;
  updated_at: string;
  case_manager: CaseManager;
};

export type ParticipantAssessment = {
  score: string;
  tool: string;
};

export interface ReviewItem {
  id: string;
  created_at: string;
  details?: object;
  document_id: string;
  event?: Event;
  expires_at?: string;
  expired: boolean;
  participant: Participant;
  reviewed: boolean;
  reviewed_at?: string;
  reviewed_by?: object;
  reviewed_by_id?: string;
  type: string;
  uid: string;
}

export enum LogEntryType {
  CSVImportAddEventsError = "CSVImportAddEventsError",
  CSVImportAddReminderTemplatesError = "CSVImportAddReminderTemplatesError",
  CSVImportStart = "CSVImportStart",
  CSVImportSuccess = "CSVImportSuccess",
  CSVImportDownloadComplete = "CSVImportDownloadComplete",
  CSVImportDownloadError = "CSVImportDownloadError",
  EventCreated = "EventCreated",
  EventDeleted = "EventDeleted",
  EventUpdated = "EventUpdated",
  IntegrationStart = "IntegrationStart",
  IntegrationEnd = "IntegrationEnd",
  IntegrationError = "IntegrationError",
  MessageOnNotificationSent = "MessageOnNotificationSent",
  NotificationDelete = "NotificationDelete",
  NotificationDeliveryFailure = "NotificationDeliveryFailure",
  NotificationDeliverySuccess = "NotificationDeliverySuccess",
  NotificationDeploy = "NotificationDeploy",
  NotificationMessageParticipant = "NotificationMessageParticipant",
  NotificationQueue = "NotificationQueue",
  ObligationCreated = "ObligationCreated",
  ObligationDeleted = "ObligationDeleted",
  ObligationUpdated = "ObligationUpdated",
  ParticipantLogin = "ParticipantLogin",
  ParticipantUpdated = "ParticipantUpdated",
  ParticipantDeleted = "ParticipantDeleted",
  ParticipantPhoneUpdated = "ParticipantPhoneUpdated",
  ServiceProviderUpdated = "ServiceProviderUpdated",
  StatsError = "StatsError",
  ParticipantActiveStatusChanged = "ParticipantActiveStatusChanged",
}
export type LogEntry = {
  id: string;
  log_type: LogEntryType;
  log_category: string;
  effective_timestamp: string;
  timestamp: string;
  participant: Participant;
  event: Event;
  details: LogEntryDetails;
  notification: Notification;
};
export type TimelineEntry = {
  id: string;
  type: LogEntryType;
  timestamp: string;
  effective_timestamp: string;
  note?: string;
  participant: Participant;
  event: Event;
  details: LogEntryDetails;
  notification: Notification;
};
// functions/src/util/careplan.ts > NotificationType
export enum TimelineEntryDetailsTypes {
  NewEvent = "new_event",
  UpcomingEvent = "upcoming_event",
  DeletedEvent = "deleted_event",
  ChangedEvent = "changed_event",
  NewParticipant = "new_participant",
  NewMessageForParticipant = "new_message_for_participant",
  NewTask = "new_task",
  DeletedTask = "deleted_task",
  ChangedTask = "changed_task",
}
export type LogEntryDetails = {
  body: string;
  type: string;
  delivery_status_verfiied: boolean;
  reason?: string;
  note?: string;
  after?: any;
  before?: any;
};
export type i18nText = {
  en: string;
  es: string;
};
export type ReminderStyleContent = {
  type: string;
  body: i18nText;
  time_to: number;
  time_required: boolean;
};
export type ReminderStyle = {
  id: string;
  scheduled: Array<ReminderStyleContent>;
  new: ReminderStyleContent;
  updated: ReminderStyleContent;
  deleted: ReminderStyleContent;
};

export type Activity = {
  id: string;
  reminder_style: ReminderStyle;
  title: i18nText;
  type: string;
  data_input_type: string | null;
  data_input_field: string | null;
};

export type Notification = {
  action: string;
  body: string;
  event: Event;
  id: string;
  participant: Participant;
  time_acknowledged: string;
  time_delivered: string;
  time_deployed: string;
  time_to_deploy: string;
  title: string;
  type: string;
  final_delivery_status: string;
  delivery_status_verified: boolean;
  twilio_message_id: string;
  fs_path: string;
};
export type PaginationInfo = {
  has_next_page: boolean;
  page_size: number;
};
export type Pagination = {
  activity?: Array<Activity>;
  cursor: string;
  log?: Array<LogEntry>;
  notification?: Array<Notification>;
  page_info: PaginationInfo;
  participant?: Array<Participant>;
  reminder?: Array<ReminderStyle>;
  timeline_entry?: Array<TimelineEntry>;
};

export type Stats = {
  court_date: StatItem;
  caseLoad: StatItem;
  compliance: StatItem;
  total: StatItem;
  [key: string]: StatItem;
};

export type StatItem = {
  current: Number;
  previous: Number;
  [key: string]: Number;
};

export enum MessageActor {
  CaseManager = "case_manager",
  Group = "person_group",
  Participant = "participant",
  System = "system",
}

export type MessageSender = {
  uid: string;
  name: PersonName;
};

export type MessageReadReceipt = {
  id: string;
  read_by: MessageActor;
};

export enum MessageStatus {
  SUCCEEDED = "succeeded",
  FAILED = "failed",
}

export enum BroadcastMessageRecipientType {
  All = "all",
  Caseload = "caseload",
  RiskLevel = "risk_level",
  Activity = "activity",
  Program = "program",
  RiskLevelBySex = "risk_level_by_sex",
}

export type BroadcastMessageRecipient = {
  queryType: BroadcastMessageRecipientType;
  arg?: string;
};

export interface BroadcastMessage {
  /**
   * Id in the system
   */
  id: string;
  /**
   * Auto generate on the backend, may not be needed to display
   */
  created_at: string;
  /**
   * Once sent, this is the date(-ish) that it was deployed/sent
   * To know if something have been sent check for this exists (!!messsage.deployed_at)
   */
  deployed_at?: string;
  /**
   * Whether this message has been deployed
   */
  deployed: boolean;
  /**
   * The test of the message
   */
  text: string;
  /**
   * The query definition (BroadcaseMessageRecipient) to whom this message will be sent
   */
  to: BroadcastMessageRecipient;
  /**
   * The case manager who last edited* this broadcast messaage
   */
  case_manager: CaseManager;
  case_manager_id: string;
  /**
   * It's scheduled to go out
   */
  scheduled: boolean;
  /**
   * When the message is scheduled to be deployed
   */
  time_to_deploy?: string;
}

export type Message = {
  id: string;
  participant_id: string;
  case_manager_i?: string;
  text: string;
  created_at: string;
  sender_type: MessageActor;
  sender: MessageSender;
  recipient_type: MessageActor;
  read_at?: string;
  sent_as?: [string];
  delivery_status: MessageStatus;
};

export type DashboardBucket = {
  key: DashboardBucketQueryIdValues;
  title: string;
  description: string;
};

export enum DashboardBucketQueryIdValues {
  UpcomingUnreviewedCourtDate = "UpcomingUnreviewedCourtDate",
  FailureToAppear = "FailureToAppear",
  NoAttendanceData = "NoAttendanceData",
  NoFutureEvents = "NoFutureEvents",
  SupervisionDateReview = "SupervisionDateReview",
  StaleDataInput = "StaleDataInput",
  RecentDataInput = "RecentDataInput",
  DataInputChange = "DataInputChange",
}

export type CaseManager = {
  id: string;
  name: PersonName;
  phone: PersonPhone;
  email: String;
};

export type AssessmentTool = {
  id: string;
  name: string;
  scale: KeyedString[];
};

export enum MutationResult {
  Failure = "failure",
  Invalid = "invalid",
  Skipped = "skipped",
  Success = "success",
}

export interface MutationResponseBase {
  result: MutationResult;
  description?: string;
  errors?: Array<string>;
}

export interface MutationResponse extends MutationResponseBase {
  ids?: Array<string>;
}

export enum SmsMessageActor {
  Promise = "promise",
  Twilio = "twilio",
}

export enum DataSource {
  User = "user",
  SolanoIntegration = "solano-integration",
}
