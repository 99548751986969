import moment, { Moment } from "moment-timezone";

import ModalWrapperHOC from "../../HOCs/ModalWrapperHOC";
import ModalWrapper from "../../modals/ModalWrapper";
import { FormField, FormDataBase, ValidationsType } from "../../modals/types";
import { BroadcastMessageFormSecond } from "./BroadcastMessageFormSecond";

export interface BroadcastMessageFormData
  extends FormDataBase<string | Moment> {
  recipient: FormField;
  message: FormField;
  scheduledFor: FormField<Moment>;
  // updateObject: BroadcastMessageObjectType;
}

export interface BroadcastMessageObjectType {
  recipient: string;
  message: string;
  scheduledFor: Date;
}

export interface BroadcastMessageForAPI {
  recipient: string;
  message: string;
  scheduledFor: Moment;
}

class BroadcastMessageModalWrapperComponent extends ModalWrapper<
  BroadcastMessageFormData
> {
  static formState = getAddEventModalStatic(null);
  componentDidMount() {
    // TODO: We need to cleanup this `static` vs. "change in this here cdm instance method"

    // on remount, refresh the static class prop formState
    // allows us to pass in some data (obligationAndParticipantDetails)
    // to hydrate the formState static prop
    const { updateObject: broadcastMessage } = this.props;

    if (broadcastMessage) {
      BroadcastMessageModalWrapperComponent.formState = getAddEventModalStatic(
        broadcastMessage
      );
    } else {
      BroadcastMessageModalWrapperComponent.formState = getAddEventModalStatic(
        null
      );
    }
  }
}

function getAddEventModalStatic(
  updateObject: BroadcastMessageObjectType | null | undefined
) {
  return {
    modalFormData: {
      recipient: {
        label: "Recipients",
        value: updateObject ? updateObject.recipient : "",
      },
      message: {
        label: "Message",
        value: updateObject ? updateObject.message : "",
      },
      scheduledFor: {
        id: "", // ?
        label: "Message Send Time",
        value:
          updateObject && updateObject.scheduledFor
            ? moment(updateObject.scheduledFor)
            : null,
      },
    } as BroadcastMessageFormData,
    constitute: (data: BroadcastMessageFormData): BroadcastMessageForAPI => {
      const {
        recipient: { value: recipient },
        message: { value: message },
        scheduledFor: { value: scheduledFor },
      } = data;

      return {
        message,
        recipient,
        scheduledFor,
      };
    },
    validations: {
      recipient: {
        state: updateObject && updateObject.recipient ? "valid" : "pristine",
        errors: [] as string[],
      },
      message: {
        state: updateObject && updateObject.message ? "valid" : "pristine",
        errors: [] as string[],
      },
      scheduledFor: {
        state: "valid",
        errors: [] as string[],
      },
    } as ValidationsType,
    attemptedSubmission: false,
    secondPage: false,
    secondPageMessageWarning: true,
    showModal: false,
  };
}

export const BroadcastMessageModalWrapper = ModalWrapperHOC(
  BroadcastMessageModalWrapperComponent,
  {
    modalWrapperHOCControlsOpenState: false,
    SecondPageComponent: BroadcastMessageFormSecond,
  }
);
