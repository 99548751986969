import * as React from "react";
import { ScarePrompt } from "../../shared/components/elements/ScarePrompt";
import { Button } from "antd";

interface Props {
  title: string;
  isReadOnly: boolean;
  buttonText: string;
  onYes: Function;
  eventsCount: number;
}

export function DeleteObligationWarning(props: Props) {
  const { title, isReadOnly, buttonText, onYes, eventsCount } = props;
  const eventsPluralized = eventsCount === 1 ? "event" : "events";
  return (
    <ScarePrompt
      warningText={`Are you sure you want to delete ${title}? This will delete ${eventsCount} ${eventsPluralized} along with any upcoming notifications. This cannot be undone.`}
      itemName={title}
      button={
        <Button type={"danger"} disabled={isReadOnly}>
          {buttonText}
        </Button>
      }
      onYes={onYes}
    />
  );
}
