import * as React from "react";
import { Tooltip } from "@material-ui/core";

/**
 * If you have a Button inside a Tooltip and it is expected to get its onClick
 * from the Modals (passing in <Tooltip><Button /> to @buttonComponent)
 * Sometimes… it will click and open
 *  and sometimes… it won't
 *
 * This is just a Tooltip that is smart enough to pick off the onClick and pass it
 *  through to the children (e.g. <Button />)
 */
export const PassThroughTooltip = (
  {
    children,
    onClick,
    ...rest
  }: {
    onClick: () => void;
  } & any /* TooltipProps - but this is not exported from material-ui */
) => {
  return (
    <Tooltip {...rest}>
      <>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, { onClick })
        )}
      </>
    </Tooltip>
  );
};
