import { MutationDataInputArgs } from "../graphql-types";
import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "../shared/lib/graphql/commitMutation";

export const dataInput = async (variables: MutationDataInputArgs) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation dataInputGraphqlMutation(
          $participant_id: String!
          $field: DataInputField!
          $value: String
          $input_type: DataInputType!
          $offline_mode: String
        ) {
          dataInput(
            field: $field
            value: $value
            participant_id: $participant_id
            input_type: $input_type
            offline_mode: $offline_mode
          ) {
            result
          }
        }
      `,
      variables,
    },
    "Error while performing data input."
  );
};
