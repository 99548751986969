/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type AppGqlQueryVariables = {};
export type AppGqlQueryResponse = {
    readonly me: ({
        readonly email: string;
        readonly case_manager: ({
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
        }) | null;
        readonly zendesk: ({
            readonly expires_at: string | null;
            readonly token: string | null;
        }) | null;
    }) | null;
    readonly application: ({
        readonly flags: ({
            readonly all: any | null;
        }) | null;
    }) | null;
};
export type AppGqlQuery = {
    readonly response: AppGqlQueryResponse;
    readonly variables: AppGqlQueryVariables;
};



/*
query AppGqlQuery {
  me {
    email
    case_manager {
      id
      name {
        first
        last
      }
    }
    zendesk {
      expires_at
      token
    }
  }
  application {
    flags {
      all
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v1 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "case_manager",
                "storageKey": null,
                "args": null,
                "concreteType": "CaseManager",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "name",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PersonName",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "first",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "last",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    }
                ]
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "zendesk",
                "storageKey": null,
                "args": null,
                "concreteType": "ZenDesk",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "expires_at",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "token",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    } as any), v2 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "flags",
        "storageKey": null,
        "args": null,
        "concreteType": "Flags",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "all",
                "args": null,
                "storageKey": null
            }
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "AppGqlQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": [
                (v1 /*: any*/),
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "application",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Application",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "AppGqlQuery",
            "argumentDefinitions": [],
            "selections": [
                (v1 /*: any*/),
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "application",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Application",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v0 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "AppGqlQuery",
            "id": null,
            "text": "query AppGqlQuery {\n  me {\n    email\n    case_manager {\n      id\n      name {\n        first\n        last\n      }\n    }\n    zendesk {\n      expires_at\n      token\n    }\n  }\n  application {\n    flags {\n      all\n    }\n    id\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '73bf23f3e939009690f2daa9334abbf4';
export default node;
