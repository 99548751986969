import * as React from "react";
import { Message, Columns, Level, Heading } from "react-bulma-components";
import { ModalConfirmationPageProps } from "../../modals/ModalConfirmationPage";
import { getFeatureFlags } from "../../../featureFlags";
import ConfirmationModalRow from "../../ConfirmationModalRow";
import "../Broadcast.scss";
import { BroadcastMessageContext } from "../../../utils/context";
import { isSoon, getNextButtonText } from "./BroadcastMessageUtil";
import { ScarePrompt } from "../../../shared/components/elements/ScarePrompt";
import { Button } from "antd";

export class BroadcastMessageFormSecond extends React.PureComponent<
  ModalConfirmationPageProps
> {
  render() {
    const {
      handleFormConfirmSubmit,
      modalFormData,
      onDelete,
      togglePage,
    } = this.props;

    const scheduledSoon = isSoon(modalFormData.scheduledFor);

    const nextButtonText = getNextButtonText(modalFormData.scheduledFor, true);

    return (
      <BroadcastMessageContext.Consumer>
        {({ recipients }) => (
          <div className="modal-form">
            <Level>
              <Level.Side align="left">
                <Level.Item>
                  <Heading className="is-4">
                    Confirm Broadcast Message{" "}
                    {scheduledSoon && (
                      <span className="sendingSoon">
                        This message is scheduled to send soon.
                      </span>
                    )}
                  </Heading>
                </Level.Item>
              </Level.Side>
              <Level.Side align="left">
                <Level.Item>
                  {onDelete && (
                    <ScarePrompt
                      warningText={`Are you sure you want to delete this message? This cannot be undone.`}
                      itemName="message"
                      button={<Button type="danger">Delete</Button>}
                      onYes={onDelete}
                    />
                  )}
                </Level.Item>
                <Level.Item>
                  <Button onClick={togglePage}>Cancel</Button>
                </Level.Item>
                <Level.Item>
                  <Button type="primary" onClick={handleFormConfirmSubmit}>
                    {nextButtonText}
                  </Button>
                </Level.Item>
              </Level.Side>
            </Level>
            <Columns>
              <Columns.Column size={4}>
                <Message color="warning">
                  <Message.Body>
                    {/* <Notification> */}
                    <div className="has-text-warning has-text-weight-bold">
                      Things to consider:{" "}
                    </div>
                    <ol className="things-to-consider">
                      <li>
                        Be sure this is the message you want to send to multiple{" "}
                        {getFeatureFlags().clientDisplayTerm}s.
                      </li>
                      <li>
                        Replies to this message will show up in each{" "}
                        {getFeatureFlags().clientDisplayTerm}s' account page
                        (accessible from the{" "}
                        {getFeatureFlags().clientDisplayTerm}s list).
                      </li>
                    </ol>
                  </Message.Body>
                </Message>
                {/* The various values are the keys in modalFormData */}
                {/* <div className="table-wrapper">
            <table>
              <tbody>
                {Object.keys(modalFormData).map(key => {
                  const { label, text, value } = modalFormData[key];
                  return (
                    <ConfirmationModalRow
                      key={key}
                      name={label}
                      value={text || value}
                    />
                  );
                })}
                <tr>
                  <td colSpan={2}>
                    <button className="edit-button" onClick={togglePage}>
                      Edit {name}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}
              </Columns.Column>
              <Columns.Column size={8}>
                <table className="confirm-table SortableTable table is-striped participantTable">
                  <tbody>
                    {Object.keys(modalFormData).map((key) => {
                      const item = modalFormData[key];
                      const val =
                        item.value && item.value.toDate
                          ? item.value.format("DD MMM YYYY hh:mm a")
                          : item.value;

                      const recipient = recipients.find(
                        ({ key }) => key === item.value
                      );

                      const valueToShow = recipient ? recipient.value : val;

                      return (
                        <ConfirmationModalRow
                          key={key}
                          name={item.label}
                          value={valueToShow}
                        />
                      );
                    })}
                    <tr>
                      <td colSpan={2}>
                        <Button type="primary" onClick={togglePage}>
                          Edit Message
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Columns.Column>
            </Columns>
          </div>
        )}
      </BroadcastMessageContext.Consumer>
    );
  }
}
