function removeChars(chars: string, input: string) {
  let str = input;
  chars.split("").forEach((char) => {
    str = str.split(char).join("");
  });
  return str;
}

function normalizePhoneNumber(phoneNumber: string): string {
  return removeChars("()- .,+", phoneNumber);
}

const DEFAULT_COUNTRY_CODE = "1";
const VALID_PHONE_REGEX = /^((\+?\d{1,2}[\s\-]?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}|\d{10})$/;

export function makePhoneString(str: string) {
  const phoneNumber = normalizePhoneNumber(str);

  if (!VALID_PHONE_REGEX.test(phoneNumber)) {
    throw new Error("Invalid phone string " + str);
  }

  const parts = [
    phoneNumber.substring(
      Math.max(0, phoneNumber.length - 13),
      phoneNumber.length - 10
    ),
    phoneNumber.substring(phoneNumber.length - 10, phoneNumber.length - 7),
    phoneNumber.substring(phoneNumber.length - 7, phoneNumber.length - 4),
    phoneNumber.substring(phoneNumber.length - 4),
  ];

  // Default to US as the country code
  if (!parts[0]) {
    parts[0] = DEFAULT_COUNTRY_CODE;
  }
  parts[0] = `+${parts[0]}`;
  return parts.filter((part) => !!part).join("");
}
