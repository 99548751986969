import { Maybe } from "../graphql-types";

export function safelyUnwrapArrayWithPossibleNullOrUndefined<T>(
  documents: Maybe<T>[]
): T[] {
  function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
  }

  const filteredArray: T[] = documents.filter(notEmpty);

  return filteredArray;
}
