import React, { useState } from "react";
import { Table, Image } from "react-bulma-components";
import { Button, Modal } from "antd";
import {
  Participant,
  Event,
  EventAttendence,
} from "../../../shared/lib/graphql/flowTypes";
import { getEventTitle } from "../../../../src/utils/eventTitle";
import { capitalizeString } from "../../../shared/lib/format";
import { formatDateTimeForDisplay } from "../../../shared/components/participantListTable/events/displayEvent";
const titleCase = require("title-case");

interface Props {
  projectId: string;
  participant: Participant;
  event: Event;
}

export default function EventDetailsModal(props: Props) {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);
  const event = props.event;

  const eventTitle = getEventTitle(event);

  const {
    date,
    attended,
    input_field_name,
    input_response_text,
    input_response_media_mime_type,
    input_response_media_base64,
    offline_mode: offlineMode,
  } = event;

  const isMediaEventDetails = !!(
    input_response_media_mime_type && input_response_media_base64
  );

  let capitalizedInputType: string = "";

  if (input_field_name) {
    capitalizedInputType = capitalizeString(input_field_name).replace(
      /_/g,
      " "
    );
  }

  const eventTime = formatDateTimeForDisplay(date);

  return (
    <>
      <Button onClick={openModal}>
        {isMediaEventDetails ? "Image" : "Details"}
      </Button>
      <Modal
        visible={modalVisible}
        onCancel={closeModal}
        footer={
          <Button type="primary" onClick={closeModal}>
            Ok
          </Button>
        }
      >
        <div style={{ paddingTop: "2em" }}>
          <Table>
            <tbody>
              <tr>
                <td style={{ fontWeight: "bold" }}>Type:</td>
                <td>{eventTitle}</td>
              </tr>
              {!isMediaEventDetails && (
                <tr>
                  <td style={{ fontWeight: "bold" }}>Status:</td>
                  <td>
                    {attended === EventAttendence.Yes
                      ? "Completed"
                      : "Incomplete"}
                  </td>
                </tr>
              )}
              <tr>
                <td style={{ fontWeight: "bold" }}>Time:</td>
                <td>{eventTime}</td>
              </tr>
              {input_response_text && (
                <tr>
                  <td style={{ fontWeight: "bold" }}>
                    {capitalizedInputType}:
                  </td>
                  <td>{capitalizeString(input_response_text)}</td>
                </tr>
              )}
              {input_response_media_mime_type && input_response_media_base64 && (
                <tr>
                  <td style={{ fontWeight: "bold" }}>Image:</td>
                  <td>
                    <Image
                      src={`data:${input_response_media_mime_type};base64,${input_response_media_base64}`}
                    ></Image>
                  </td>
                </tr>
              )}
              <tr>
                <td style={{ fontWeight: "bold" }}>Offline Update Method</td>
                <td>{offlineMode ? titleCase(offlineMode) : "False"}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal>
    </>
  );
}
