/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type DataInputField = "aa_meeting" | "address" | "employment" | "law_enforcement_contact" | "%future added value";
export type EventDetailsQueryWrapperQueryVariables = {
    readonly participant_id?: string | null;
    readonly event_id?: string | null;
};
export type EventDetailsQueryWrapperQueryResponse = {
    readonly participants: ({
        readonly participant: ReadonlyArray<({
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
            readonly events: ReadonlyArray<({
                readonly id: string | null;
                readonly date: string | null;
                readonly attended: string | null;
                readonly activity: {
                    readonly title: {
                        readonly en: string | null;
                        readonly es: string | null;
                    };
                };
                readonly obligation: ({
                    readonly title: ({
                        readonly en: string | null;
                        readonly es: string | null;
                    }) | null;
                }) | null;
                readonly input_field_name: DataInputField | null;
                readonly input_response_text: string | null;
                readonly input_response_media_mime_type: string | null;
                readonly input_response_media_base64: string | null;
                readonly offline_mode: string | null;
            }) | null> | null;
        }) | null> | null;
    }) | null;
};
export type EventDetailsQueryWrapperQuery = {
    readonly response: EventDetailsQueryWrapperQueryResponse;
    readonly variables: EventDetailsQueryWrapperQueryVariables;
};



/*
query EventDetailsQueryWrapperQuery(
  $participant_id: String
  $event_id: String
) {
  participants(id: $participant_id) {
    participant {
      id
      name {
        first
        last
      }
      events(id: $event_id) {
        id
        date
        attended
        activity {
          title {
            en
            es
          }
          id
        }
        obligation {
          title {
            en
            es
          }
          id
        }
        input_field_name
        input_response_text
        input_response_media_mime_type
        input_response_media_base64
        offline_mode
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "participant_id",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "event_id",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "participant_id"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "name",
        "storageKey": null,
        "args": null,
        "concreteType": "PersonName",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "first",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "last",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v4 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "event_id"
        } as any)
    ], v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "date",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "attended",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "title",
        "storageKey": null,
        "args": null,
        "concreteType": "i18nText",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "en",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "es",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v8 = [
        (v7 /*: any*/)
    ], v9 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "input_field_name",
        "args": null,
        "storageKey": null
    } as any), v10 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "input_response_text",
        "args": null,
        "storageKey": null
    } as any), v11 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "input_response_media_mime_type",
        "args": null,
        "storageKey": null
    } as any), v12 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "input_response_media_base64",
        "args": null,
        "storageKey": null
    } as any), v13 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "offline_mode",
        "args": null,
        "storageKey": null
    } as any), v14 = [
        (v7 /*: any*/),
        (v2 /*: any*/)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "EventDetailsQueryWrapperQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participants",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Pagination",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "participant",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Participant",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "events",
                                    "storageKey": null,
                                    "args": (v4 /*: any*/),
                                    "concreteType": "Event",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "activity",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "Activity",
                                            "plural": false,
                                            "selections": (v8 /*: any*/)
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "obligation",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "Obligation",
                                            "plural": false,
                                            "selections": (v8 /*: any*/)
                                        },
                                        (v9 /*: any*/),
                                        (v10 /*: any*/),
                                        (v11 /*: any*/),
                                        (v12 /*: any*/),
                                        (v13 /*: any*/)
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "EventDetailsQueryWrapperQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participants",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Pagination",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "participant",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Participant",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "events",
                                    "storageKey": null,
                                    "args": (v4 /*: any*/),
                                    "concreteType": "Event",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "activity",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "Activity",
                                            "plural": false,
                                            "selections": (v14 /*: any*/)
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "obligation",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "Obligation",
                                            "plural": false,
                                            "selections": (v14 /*: any*/)
                                        },
                                        (v9 /*: any*/),
                                        (v10 /*: any*/),
                                        (v11 /*: any*/),
                                        (v12 /*: any*/),
                                        (v13 /*: any*/)
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "EventDetailsQueryWrapperQuery",
            "id": null,
            "text": "query EventDetailsQueryWrapperQuery(\n  $participant_id: String\n  $event_id: String\n) {\n  participants(id: $participant_id) {\n    participant {\n      id\n      name {\n        first\n        last\n      }\n      events(id: $event_id) {\n        id\n        date\n        attended\n        activity {\n          title {\n            en\n            es\n          }\n          id\n        }\n        obligation {\n          title {\n            en\n            es\n          }\n          id\n        }\n        input_field_name\n        input_response_text\n        input_response_media_mime_type\n        input_response_media_base64\n        offline_mode\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'f46b5be9ec3ac5510a157aa36863d805';
export default node;
