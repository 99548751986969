import React, { useState } from "react";
import { Participant } from "../../graphql-types";
import { Checkbox, Row, Col, Typography, Input, Button } from "antd";
import { getUpdateParticipantBaseVariables } from "../participant-profile/ParticipantProfileContainer";
import { updateParticipant } from "../../actions/participants";

interface Props {
  participant: Participant;
}

interface ProtectiveOrderDataType {
  stayAway: boolean;
  peacefulContact: boolean;
  notes: string;
}

export function ProtectiveOrderForm(props: Props) {
  const { participant } = props;
  const { Title } = Typography;
  const { TextArea } = Input;

  const initialProtectiveOrderData: ProtectiveOrderDataType = {
    stayAway: participant.stay_away || false,
    peacefulContact: participant.peaceful_contact || false,
    notes: participant.protective_order_notes || "",
  };
  const [protectiveOrderData, setProtectiveOrderData] = useState(
    initialProtectiveOrderData
  );

  const handleProtectiveOrderInputChange = (event: any) => {
    const { name, value } = event.target;
    const newProtectiveOrder = { ...protectiveOrderData, [name]: value };
    setProtectiveOrderData(newProtectiveOrder);
  };

  const handleProtectiveOrderDataChange = (event: any) => {
    const { name, checked } = event.target;
    const newProtectiveOrder = { ...protectiveOrderData, [name]: checked };
    setProtectiveOrderData(newProtectiveOrder);
  };

  const handleProtectiveOrderSaveClicked = async () => {
    const baseFields = getUpdateParticipantBaseVariables(participant);
    await updateParticipant(
      {
        ...baseFields,
        protective_order_notes: protectiveOrderData.notes,
        stay_away: protectiveOrderData.stayAway,
        peaceful_contact: protectiveOrderData.peacefulContact,
      },
      participant as any
    );
  };

  return (
    <div className={"participant-victims-wrapper"}>
      <div className={"participant-victims-box"}>
        <Title level={4}>Protective Order</Title>
        <Row>
          <Col span={12}>
            <Checkbox
              checked={protectiveOrderData.stayAway}
              name="stayAway"
              onChange={handleProtectiveOrderDataChange}
            >
              Stay Away
            </Checkbox>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Checkbox
              checked={protectiveOrderData.peacefulContact}
              name="peacefulContact"
              onChange={handleProtectiveOrderDataChange}
            >
              Peaceful Contact
            </Checkbox>
          </Col>
        </Row>
        <TextArea
          value={protectiveOrderData.notes}
          name={"notes"}
          rows={4}
          style={{ marginTop: "0.5em" }}
          placeholder={"additional notes on protective order"}
          onChange={handleProtectiveOrderInputChange}
        />
        <Button
          style={{ marginTop: "0.5em" }}
          type="primary"
          onClick={handleProtectiveOrderSaveClicked}
        >
          Save
        </Button>
      </div>
    </div>
  );
}
