import React from "react";
import { Typography } from "antd";
import { Booking } from "../../graphql-types";
import { BookingChargesTable } from "../bookings/TransmittalCreation";
import { BookingInfoFormWrapped } from "./BookingInfoForm";
import { bookingInformationFormFields } from "./FormFields";
import { getPoliceDepartmentAbbreviation } from "./shared";
import moment from "moment";

const { Title } = Typography;

export function ProspectSummary({ booking }: { booking: Booking }) {
  const bookingFormFieldsWithInitialValue = bookingInformationFormFields.map(
    (bookingInformationFormField) => {
      if (bookingInformationFormField.id === "booking_date") {
        return {
          ...bookingInformationFormField,
          initialValue: booking.booking_info.booking_date
            ? booking.booking_info.booking_date
            : booking.booking_info.booking_datetime,
        };
      } else if (bookingInformationFormField.id === "arrest_agency") {
        return {
          ...bookingInformationFormField,
          initialValue:
            booking.booking_info.arrest_agency ||
            (booking.booking_charges &&
              booking.booking_charges[0].arrest_agency &&
              getPoliceDepartmentAbbreviation(
                booking.booking_charges[0].arrest_agency
              )) ||
            "",
        };
      } else if (
        bookingInformationFormField.id === "court_date" ||
        bookingInformationFormField.id === "court_time"
      ) {
        return {
          ...bookingInformationFormField,
          initialValue:
            booking.booking_status &&
            booking.booking_status.court_appearance_datetime
              ? moment(booking.booking_status.court_appearance_datetime)
              : undefined,
        };
      } else if (bookingInformationFormField.id === "court_room") {
        return {
          ...bookingInformationFormField,
          initialValue:
            booking.booking_status && booking.booking_status.court_room
              ? booking.booking_status.court_room
              : "",
        };
      } else {
        return {
          ...bookingInformationFormField,
          initialValue: booking.booking_info.arrested_for
            ? booking.booking_info.arrested_for
            : booking.booking_charges && booking.booking_charges.length
            ? booking.booking_charges
                .map((bookingCharge, index) => {
                  if (index > 0) {
                    return `${bookingCharge.charge_code_section}`;
                  } else {
                    return bookingCharge.charge_code_section;
                  }
                })
                .join(", ")
            : "",
        };
      }
    }
  );
  return (
    <>
      <div className="form-container">
        <Title level={4}>Additional Booking Information</Title>
        <BookingInfoFormWrapped
          bookingFormFieldsWithInitialValue={bookingFormFieldsWithInitialValue}
          bookingId={booking.id}
        />
      </div>
      <BookingChargesTable bookingCharges={booking.booking_charges || []} />
    </>
  );
}

export function BookingInfoForm() {}
