/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type MutationResult = "failure" | "skipped" | "success" | "%future added value";
export type participantDocumentsSoftDeleteParticipantDocumentMutationVariables = {
    readonly participant_id: string;
    readonly document_id: string;
};
export type participantDocumentsSoftDeleteParticipantDocumentMutationResponse = {
    readonly softDeleteParticipantDocument: ({
        readonly participant: ({
            readonly id: string;
        }) | null;
        readonly document: ({
            readonly id: string;
        }) | null;
        readonly result: MutationResult;
        readonly description: string | null;
        readonly errors: ReadonlyArray<string | null> | null;
    }) | null;
};
export type participantDocumentsSoftDeleteParticipantDocumentMutation = {
    readonly response: participantDocumentsSoftDeleteParticipantDocumentMutationResponse;
    readonly variables: participantDocumentsSoftDeleteParticipantDocumentMutationVariables;
};



/*
mutation participantDocumentsSoftDeleteParticipantDocumentMutation(
  $participant_id: String!
  $document_id: String!
) {
  softDeleteParticipantDocument(participant_id: $participant_id, document_id: $document_id) {
    participant {
      id
    }
    document {
      id
    }
    result
    description
    errors
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "participant_id",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "document_id",
            "type": "String!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
        } as any)
    ], v2 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "softDeleteParticipantDocument",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "document_id",
                    "variableName": "document_id"
                },
                {
                    "kind": "Variable",
                    "name": "participant_id",
                    "variableName": "participant_id"
                }
            ],
            "concreteType": "NewParticipantDocument",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participant",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Participant",
                    "plural": false,
                    "selections": (v1 /*: any*/)
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "document",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ParticipantDocument",
                    "plural": false,
                    "selections": (v1 /*: any*/)
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "result",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "errors",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "participantDocumentsSoftDeleteParticipantDocumentMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v2 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "participantDocumentsSoftDeleteParticipantDocumentMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v2 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "participantDocumentsSoftDeleteParticipantDocumentMutation",
            "id": null,
            "text": "mutation participantDocumentsSoftDeleteParticipantDocumentMutation(\n  $participant_id: String!\n  $document_id: String!\n) {\n  softDeleteParticipantDocument(participant_id: $participant_id, document_id: $document_id) {\n    participant {\n      id\n    }\n    document {\n      id\n    }\n    result\n    description\n    errors\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'e51fe3743f042a1f8a17b778dfcbc6a0';
export default node;
