/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type SearchResultsBoxQueryVariables = {
    readonly name_contains?: string | null;
};
export type SearchResultsBoxQueryResponse = {
    readonly participants: ({
        readonly participant: ReadonlyArray<({
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
        }) | null> | null;
    }) | null;
};
export type SearchResultsBoxQuery = {
    readonly response: SearchResultsBoxQueryResponse;
    readonly variables: SearchResultsBoxQueryVariables;
};



/*
query SearchResultsBoxQuery(
  $name_contains: String
) {
  participants(name_contains: $name_contains) {
    participant {
      id
      name {
        first
        last
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "name_contains",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "participants",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "name_contains",
                    "variableName": "name_contains"
                }
            ],
            "concreteType": "Pagination",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participant",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Participant",
                    "plural": true,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "name",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PersonName",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "first",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "last",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "SearchResultsBoxQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "SearchResultsBoxQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "SearchResultsBoxQuery",
            "id": null,
            "text": "query SearchResultsBoxQuery(\n  $name_contains: String\n) {\n  participants(name_contains: $name_contains) {\n    participant {\n      id\n      name {\n        first\n        last\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '964a3f8a4ab3bd2c0d704daa232d5fc8';
export default node;
