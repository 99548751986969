import { RRule, RRuleSet } from "rrule";
import { rruleDictionary } from "./rruleDictionary";
import { FormField } from "../modals/types";
import moment, { Moment, ISO_8601 } from "moment";

export function createRRuleSetString(
  rrule: FormField,
  start: string,
  until: string
): string {
  let rruleSet;
  if (
    rruleDictionary.hasOwnProperty(rrule.value) &&
    rruleDictionary[rrule.value].hasOwnProperty("freq")
  ) {
    rruleSet = new RRuleSet();
    // the start date will be set to either the set start date
    // or if start date is in the past, the current time
    const dtstartFormatted = start ? moment(start, ISO_8601) : moment();
    const untilFormatted = until
      ? moment(until, ISO_8601)
      : moment(dtstartFormatted).add(5, "years");

    // Add a rrule to rruleSet
    rruleSet.rrule(
      new RRule({
        freq: rruleDictionary[rrule.value].freq,
        byweekday: rruleDictionary[rrule.value].byweekday
          ? rruleDictionary[rrule.value].byweekday
          : null,
        dtstart: getPureUTCDate(dtstartFormatted),
        until: getPureUTCDate(untilFormatted),
      })
    );
  }
  return rruleSet ? rruleSet.toString() : "";
}

// very ugly, but dealing with UTC for rrule
// is kind of a pain
function getPureUTCDate(momentDate: Moment) {
  const year = parseInt(momentDate.format("YYYY"));
  const month = parseInt(momentDate.format("MM")) - 1;
  const day = parseInt(momentDate.format("DD"));
  const hour = parseInt(momentDate.format("HH"));

  return new Date(Date.UTC(year, month, day, hour, momentDate.minutes(), 0, 0));
}
