import React from "react";
import { Checkbox } from "antd";
import { Typography } from "antd";
import "./prospects.scss";

const { Title } = Typography;

export function ConditionsOfRelease() {
  return (
    <div className={"page-break-before"}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Title level={3} style={{ margin: "0px" }}>
          MARIN COUNTY PROBATION DEPARTMENT
        </Title>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Title level={4} style={{ margin: "0px" }}>
          PRETRIAL CONDITIONS OF RELEASE
        </Title>
      </div>
      <div>
        <div className="checkbox-container avoid-page-break">
          <Checkbox checked={false} name="supervision_and_custody">
            The defendant is hereby placed under the supervision and custody of
            the Pre-Trial Unit of the County of Marin.
          </Checkbox>
          <br />

          <Checkbox checked={false} name="not_leave_state_or_residence">
            Defendant shall not leave the State of California nor move his/her
            residence from the County in which he/she resides, without prior
            written approval of the Pre- Trial Unit or the Court.
          </Checkbox>
          <br />

          <Checkbox
            checked={false}
            name="notify_change_employment_or_residence"
          >
            The defendant shall notify the Pre-Trial Unit immediately of any
            change in employment or place of residence.
          </Checkbox>
          <br />

          <Checkbox checked={false} name="report_regularly_pretrial">
            The defendant shall report regularly as directed by the Pre-Trial
            Unit.
          </Checkbox>
          <br />

          <Checkbox checked={false} name="report_to_probation">
            Report to the Probation Department upon release.
          </Checkbox>
          <br />

          <Checkbox checked={false} name="tcemp">
            <strong>TCEMP</strong> Defendant to seek and maintain full-time
            employment/ education/vocational training, as directed by the
            Probation Officer.
          </Checkbox>
          <br />

          <Checkbox checked={false} name="tcv3a">
            <strong>TCV3A</strong> Defendant shall submit to search and seizure
            of his/her person, vehicle, residence, or any other property under
            his/her control, at any time of the day or night, by any Probation
            Officer or Peace Officer, with or without probable cause, with or
            without a warrant.
          </Checkbox>
          <br />

          <Checkbox checked={false} name="curfew">
            Curfew Monitoring (EM)
          </Checkbox>
          <br />

          <Checkbox checked={false} name="location_tracking">
            Continuous Location Tracking (GPS)
          </Checkbox>
          <br />

          <Checkbox checked={false} name="alcohol_detection">
            Transdermal Alcohol Detection (TAD)
          </Checkbox>
          <br />

          <Checkbox checked={false} name="alcohol_detection_with_curfew">
            Transdermal Alcohol Detection (TAD) with Curfew Monitoring (RF)
          </Checkbox>
          <br />

          <Checkbox checked={false} name="follow_court_protective_orders">
            Follow any and all Court ordered protective orders.
          </Checkbox>
          <br />

          <Checkbox checked={false} name="tcaap">
            TCAAP Attend AA meetings as directed by Probation Officer.
          </Checkbox>
          <br />

          <Checkbox checked={false} name="tcbaf">
            TCBAF Defendant to submit to chemical testing at the request of any
            Probation Officer or Peace Officer to determine DRUG/ALCOHOL
            content.
          </Checkbox>
          <br />

          <Checkbox checked={false} name="tcnax">
            <strong>TCNAX</strong> Shall not possess, abuse, consume or
            transport any alcohol, intoxicants, non-prescribed or illegal drugs,
            or associated paraphernalia, including medicinal marijuana.
          </Checkbox>
          <br />

          <>
            <Checkbox checked={false} name="tcnae">
              <strong>TCNAE</strong> Defendant shall not use, abuse, consume, or
              transport alcohol.
            </Checkbox>
            <br />
          </>

          <Checkbox checked={false} name="tcgnx">
            <strong>TCGNX</strong> Shall not be a gang member, shall not
            associate with gangs or be where gangs congregate, or wear/exhibit
            clothing. items, or emblems associated with gang membership.
          </Checkbox>
          <br />

          <Checkbox checked={false} name="tcgwr">
            <strong>TCGWR</strong> Defendant shall not wear clothing, nor wear
            or display items or emblems, nor acquire and/or display tattoos
            known or reasonably understood or believed by him/her to be
            associated with or symbolic of gang membership, including clothing,
            tattoos and items specifically so identified for him/her by the
            Probation Officer.
          </Checkbox>
          <br />

          <Checkbox checked={false} name="follow_court_probation_conditions">
            Follow all Court ordered conditions of your Supervised/Conditional
            Probation.
          </Checkbox>
          <br />

          <Checkbox checked={false} name="pretrial_program">
            You are being placed on the Marin County Pretrial Program. As part
            of that program you are to report to the Marin County Superior Court
            at the direction of the Probation Department. Any violations of the
            program may result in a notice being filed with the court and/or
            your arrest for any violations.
          </Checkbox>
        </div>
      </div>
    </div>
  );
}
