import * as React from "react";
import {
  addWindowFocusListener,
  removeWindowFocusListener,
} from "../../lib/windowListener";

import { UserType } from "../../lib/contexts/UserContext";

import firebase, {
  clearEmailLoginState,
  getEmailLoginState,
  initiateEmailLogin,
  isEmailLoginAllowed,
  isGoogleLoginAllowed,
  isSmsLoginAllowed,
  getSessionExpiryInMinutes,
  login,
  logout as fbLogout,
  onLoginChanged,
} from "../../lib/auth/firebase";
import fetcher from "../../lib/fetcher/fetcher";
import { wrapComponent } from "../../lib/types";

const VERIFY_INTERVAL_MINUTES = 10;
const AUTHORIZATION_HEADER_NAME = "authorization";

interface ChildLoginProps {
  loginStatus: LoginStatus;
  loginError: LoginError;
  onLogin: () => void;
  onEmailLogin: () => void;
  onSmsLogin: () => void;
  emailLoginAllowed: boolean;
  smsLoginAllowed: boolean;
  googleLoginAllowed: boolean;
  getSessionExpiryInMinutes: number | undefined;
  user: UserType;
}

export interface WithLoginChildProps extends WithLoginProps, ChildLoginProps {}

export interface WithLoginProps {
  firebaseProject?: string;
  setUser: (user: UserType) => void;
  validationUrl: string;
}

export type LoginError = string | undefined;
export type LoginStatus =
  | "loggedin"
  | "loggedout"
  | "loading"
  | "failed"
  | "emailsent";

type LoginState = {
  error: LoginError;
  status: LoginStatus;
  user: UserType | undefined;
  refreshToken?: string;
  userLoggedIn: boolean;
};

const updateDefaultHeader = (token: string) => {
  fetcher.setDefaultHeaders({ [AUTHORIZATION_HEADER_NAME]: `Bearer ${token}` });
};

const SESSION_KEY = "__session";

const getTokenFromStorage = (
  firebaseProject?: string
): { token?: string; refreshToken?: string } => {
  const session = window.localStorage.getItem(SESSION_KEY) || "";

  if (!session) return {};

  const sessionInfo = JSON.parse(session);
  return sessionInfo.project !== firebaseProject
    ? {}
    : {
        token: sessionInfo.token,
        refreshToken: (sessionInfo.user || {}).refreshToken,
      };
};

export const logout = async () => {
  fetcher.removeHeader(AUTHORIZATION_HEADER_NAME);
  await fbLogout();
  window.localStorage.removeItem(SESSION_KEY);
};

export function withLogin<
  TCall extends WithLoginProps,
  TWrapped extends WithLoginChildProps
>(
  WrappedComponent: React.ComponentType<TWrapped>
): React.ComponentClass<TCall, LoginState> {
  const C = class WithLogin extends React.PureComponent<TCall, LoginState> {
    // n.b. purposefully _not_ using withTimer so that we don't have any conflicts/confusion for
    //  any component that tries to use both HOCs
    //  also, we have only one interval to track
    protected _verifyInterval: number = undefined as any;
    protected _expiryInterval: number = undefined as any;

    constructor(props: any) {
      super(props);

      this.state = {
        status: "loading" as LoginStatus,
        error: undefined,
        user: undefined,
        refreshToken: undefined,
        userLoggedIn: false,
      };
    }

    componentDidMount() {
      // TODO: We can probably fish this user type out of FirebaseFirestore types
      onLoginChanged((user: any) => {
        const { token: cookieToken, refreshToken } = getTokenFromStorage(
          this.props.firebaseProject
        );
        if (user && cookieToken) {
          updateDefaultHeader(cookieToken);
          // If already logged in, just use that user.
          if (this.props.setUser) this.props.setUser(user);
          this.setState({ user, refreshToken }, this._validateLoginValid);
        } else {
          if (getEmailLoginState() === "logging_in") {
            this.setState({ status: "loading" });
          } else {
            this.setState({ status: "loggedout" });
          }
        }
      });

      addWindowFocusListener(this._validateStillLoggedIn);

      if (getEmailLoginState() === "logging_in") {
        this._userLogin();
      }

      this._validateStillLoggedIn(true);

      this._verifyInterval = window.setInterval(() => {
        this._validateStillLoggedIn(true);
      }, VERIFY_INTERVAL_MINUTES * 60 * 1000);

      const sessionExpiryInMinutes = getSessionExpiryInMinutes();

      if (sessionExpiryInMinutes) {
        this._expiryInterval = window.setInterval(() => {
          this._expireSession();
        }, sessionExpiryInMinutes * 60 * 1000);
      }
    }

    componentWillUnmount() {
      removeWindowFocusListener(this._validateStillLoggedIn);
      if (this._verifyInterval) window.clearInterval(this._verifyInterval);
    }

    _validateStillLoggedIn = (isVisible: boolean) => {
      if (isVisible && this.state.status === "loggedin") {
        this._validateLoginValid();
      }
    };

    _expireSession = async () => {
      await logout();
      this.setState({
        status: "loggedout",
        error: "Invalid login",
      });
    };

    _userLogin = () => {
      this.setState(
        {
          userLoggedIn: true,
        },
        this._login
      );
    };

    _emailLogin = () => {
      initiateEmailLogin()
        .then((emailSent: boolean) => {
          if (emailSent) {
            this.setState({ status: "emailsent" });
          }
        })
        .catch((e: any) => {
          this.setState({ status: "failed", error: e.message });
        });
    };

    _login = (sms?: boolean) => {
      login(sms)
        // TODO: Get typings
        .then((result: any) => {
          const user = result.user;
          // TODO: Get typings
          (firebase as any)
            .auth()
            .currentUser.getIdToken(/* forceRefresh */ true)
            // TODO: Get typing for this, remove the string type - it should flow from the function
            .then((idToken: string) => {
              if (getEmailLoginState() === "logging_in" || sms === true) {
                // email login; ensure that the backend validates the login
                // successfully logging in to Firebase is insufficient; must also make sure the backend allows this login
                // see auth_email.ts
                updateDefaultHeader(idToken);
                fetcher(this.props.validationUrl)
                  .then(() => {
                    this._finalizeLogin(idToken, user);
                    this.setState({ user, status: "loggedin" }, () => {
                      clearEmailLoginState(); // don't clear until component state is updated, or else the login screen will flash
                    });
                  })
                  .catch((e) => {
                    clearEmailLoginState();
                    console.error(e);
                    logout()
                      .then(() =>
                        this.setState({
                          status: "failed",
                          error: "Invalid login",
                        })
                      )
                      .catch((e) => {
                        console.error(e);
                        this.setState({
                          status: "failed",
                          error: "Invalid login",
                        });
                      });
                  });
              } else {
                // normal login; carry-on
                this._finalizeLogin(idToken, user);
                this.setState({ user, status: "loggedin" });
              }
            });
        })
        .catch((err: Error) => {
          console.error(err);
          this.setState({ status: "failed", error: err.message });
        });
    };

    _finalizeLogin(idToken: string, user: any) {
      this.writeLocalAuth(
        idToken,
        user.refreshToken,
        this.props.firebaseProject
      );

      if (this.props.setUser) this.props.setUser(user);
    }

    _validateLoginValid = () => {
      // Validate that the cookie is still valid by doing a cheap api call
      fetcher(this.props.validationUrl)
        .then(() => {
          this.setState({ status: "loggedin" });
        })
        .catch(this.onInvalidLoginTryRefresh);
    };

    private onInvalidLoginTryRefresh = async () => {
      const onLoggedOut = () => {
        this.setState({
          status: this.state.userLoggedIn ? "failed" : "loggedout",
        });
      };

      const { currentUser } = (firebase as any).auth();
      if (!currentUser) {
        onLoggedOut();
        return;
      }

      const previousToken = getTokenFromStorage(this.props.firebaseProject)
        .token;

      // makes a call to (and refreshes the token): https://securetoken.googleapis.com/v1/token?key=AIzaSyD5nMpJfCix3AqyI6nvjblHDYvRy3IXAPA
      const idToken = await currentUser.getIdToken(true);

      if (!idToken || idToken == previousToken) {
        // didn't get a new token or it isn't new, call logged out
        onLoggedOut();
      } else {
        // refreshed, write the new idToken
        this.writeLocalAuth(idToken);
        if (this.state.status !== "loggedin") {
          this.setState({ status: "loggedin" });
        } else {
          this.forceUpdate();
        }
      }
    };

    private writeLocalAuth = (
      token: string,
      refreshToken?: string,
      project?: string
    ) => {
      const { refreshToken: existingRefreshToken } = getTokenFromStorage(
        project
      );

      window.localStorage.setItem(
        SESSION_KEY,
        JSON.stringify({
          token,
          refreshToken: refreshToken || existingRefreshToken,
          project: project || this.props.firebaseProject,
        })
      );

      updateDefaultHeader(token);
    };

    render() {
      return (
        <WrappedComponent
          loginStatus={this.state.status}
          loginError={this.state.error}
          onLogin={this._userLogin}
          onEmailLogin={this._emailLogin}
          emailLoginAllowed={isEmailLoginAllowed()}
          onSmsLogin={() => this._login(true)}
          smsLoginAllowed={isSmsLoginAllowed()}
          googleLoginAllowed={isGoogleLoginAllowed()}
          expir
          user={this.state.user}
          {...(this.props as any)}
        />
      );
    }
  };

  return wrapComponent(C, WrappedComponent, "withLogin");
}
