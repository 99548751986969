import React, { Component } from "react";
import "../ModalForm.scss";
import { Columns, Form } from "react-bulma-components";
import { Button } from "antd";
import ModalSelect from "../ModalSelect";
import { ChangeParticipantIsActiveContext } from "../../../utils/context";
import {
  KeyedString,
  NOT_SET,
  ModalInputTypes,
} from "../../../shared/lib/graphql/index";
import { ValidationItemType, ValidationItemStates } from "../types";
import { ChangeParticipantActiveStatusFormData } from "./ChangeParticipantActiveStatusModalWrapper";
import DatePickerWrapper from "../../DatePickerWrapper";
import { Moment } from "moment";
import ModalInput from "../ModalInput";
import { FormLabelWithToolTip } from "../../../shared/components/elements/FormLabelWithToolTip";

const INCARCERATED = "Incarcerated";

interface State {
  accountChangeReasonIndex: number;
}

interface ParticipantFormValidations {
  reason: ValidationItemType;
  note: ValidationItemType;
  startDate: ValidationItemType;
  endDate: ValidationItemType;
}

interface Props {
  handleModalFormInputChange: Function;
  handleFormSubmit: Function;
  attemptedSubmission: boolean;
  modalFormData: ChangeParticipantActiveStatusFormData;
  validations: ParticipantFormValidations;
  modalType: string;
  handlePhoneValidation: Function;
  handleDatePickerChange: Function;
  handleDatePickerRawChange: Function;
  updateSpecifiedFields: Function;
}

class ChangeParticipantActiverStatusForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      accountChangeReasonIndex: 0,
    };
  }

  componentDidMount() {
    const { modalFormData } = this.props;
    const { reason } = modalFormData;

    if (reason.value === INCARCERATED) {
      this.setState({ accountChangeReasonIndex: 1 });
    }
  }

  _handleReasonSelectChange = (event: any) => {
    const index =
      event.nativeEvent &&
      event.nativeEvent.target &&
      event.nativeEvent.target.selectedIndex;
    const reasonValue = index ? event.nativeEvent.target[index].value : "";

    const { handleModalFormInputChange, updateSpecifiedFields } = this.props;
    handleModalFormInputChange(event);

    const fieldsToUpdate = {
      recidivismDate: {
        label: "Recidivism Date",
        dateFormat: "dddd, MMMM Do YYYY",
        value: "",
      },
      recidivismReason: {
        label: "Recidivism Reason",
        value: "",
      },
    };

    if (reasonValue !== INCARCERATED.toLowerCase()) {
      const validationsToUpdate = {
        recidivismReason: {
          state: ValidationItemStates.valid,
          errors: [] as string[],
        },
      };
      updateSpecifiedFields(fieldsToUpdate, validationsToUpdate);
    } else {
      const validationsToUpdate = {
        recidivismReason: {
          state: ValidationItemStates.pristine,
          errors: [] as string[],
        },
      };
      updateSpecifiedFields({}, validationsToUpdate);
    }
  };

  _handleAccountChange = (event: React.SyntheticEvent<HTMLSelectElement>) => {
    const { updateSpecifiedFields } = this.props;
    const { value } = event.target as HTMLSelectElement;
    const valueAsInteger = parseInt(value);
    this.setState({ accountChangeReasonIndex: valueAsInteger });

    const fieldsToUpdate =
      valueAsInteger === 1
        ? {
            reason: {
              label: "Reason",
              value: INCARCERATED,
            },
          }
        : {
            reason: {
              label: "Reason",
              value: "",
            },
            recidivismDate: {
              label: "Recidivism Date",
              dateFormat: "dddd, MMMM Do YYYY",
              value: null,
            },
            recidivismReason: {
              label: "Recidivism Reason",
              value: "",
            },
          };

    const validationsToUpdate =
      valueAsInteger === 1
        ? {
            reason: {
              state: ValidationItemStates.valid,
              errors: [] as string[],
            },
            recidivismReason: {
              state: ValidationItemStates.pristine,
              errors: [] as string[],
            },
          }
        : {
            reason: {
              state: ValidationItemStates.pristine,
              errors: [] as string[],
            },
            recidivismReason: {
              state: ValidationItemStates.valid,
              errors: [] as string[],
            },
          };

    updateSpecifiedFields(fieldsToUpdate, validationsToUpdate);
  };

  render() {
    const { accountChangeReasonIndex } = this.state;
    const {
      handleModalFormInputChange,
      modalFormData,
      handleFormSubmit,
      validations,
      attemptedSubmission,
      handleDatePickerChange,
      handleDatePickerRawChange,
    } = this.props;

    const {
      firstName,
      lastName,
      reason,
      note,
      recidivismDate,
      recidivismReason,
    } = modalFormData;

    type CreateParticipantContextData = {
      deactivateReasonsSerialized: KeyedString[];
      reactivateReasonsSerialized: KeyedString[];
      isActive: boolean;
    };

    const recidivismReasonsSerialized: KeyedString[] = [
      { key: NOT_SET, value: "Select recidivism reason..." },
      { key: "new_charge", value: "New Criminal Charge" },
      { key: "civil", value: "Civil" },
      { key: "unknown", value: "Unknown" },
    ];

    const accountChangeOptionsSerialized: KeyedString[] = [
      { key: "reactivate_account", value: "Reactivate Account" },
      { key: "Add recidivism event", value: "Add recidivism event" },
    ];

    return (
      <ChangeParticipantIsActiveContext.Consumer>
        {(data: CreateParticipantContextData) => (
          <div className="modal-form">
            <Columns className="modal-column">
              <Columns.Column>
                {data.isActive === true ? (
                  <div>
                    <p>{`You are deactivating an account for ${firstName.value} ${lastName.value}. Doing this will:`}</p>
                    <ul className="modal-ul">
                      <li>{`- Remove ${firstName.value} from the active client list`}</li>
                      <li>{`- Stop delivery of SMS reminders from promise`}</li>
                      <li>{`- Add ${firstName.value}  to the inactive clients list`}</li>
                    </ul>
                    <p className="modal-padded-p">
                      Please add the reason for the account deactivation below.
                    </p>
                  </div>
                ) : (
                  <Form.Field>
                    <Form.Control>
                      <FormLabelWithToolTip
                        labelText={"Account Change"}
                        tipText={""}
                      />

                      <Form.Select
                        className="modal-select"
                        name={name}
                        value={accountChangeReasonIndex}
                        onChange={this._handleAccountChange}
                      >
                        {accountChangeOptionsSerialized.map(
                          ({ key, value }: KeyedString, index) => (
                            <option key={key} value={index}>
                              {value}
                            </option>
                          )
                        )}
                      </Form.Select>
                    </Form.Control>
                  </Form.Field>
                )}
                {accountChangeReasonIndex === 0 && !data.isActive ? (
                  <>
                    <div style={{ marginTop: "10px" }}>
                      <p>{`You are reactivating an account for ${firstName.value} ${lastName.value}. Doing this will:`}</p>
                      <ul className="modal-ul">
                        <li>{`- Add ${firstName.value} to the active client list`}</li>
                        <li>{`- Remove ${firstName.value}  from the inactive client list`}</li>
                        <li>{`- Reset the supervision begin and supervision end dates for  ${firstName.value}`}</li>
                      </ul>
                      <p className="modal-padded-p">
                        Please add the reason for the account reactivation
                        below.
                      </p>
                    </div>
                  </>
                ) : null}

                {accountChangeReasonIndex === 0 || data.isActive ? (
                  <ModalSelect
                    modalStateKey="reason"
                    label={reason.label}
                    validations={validations}
                    value={reason.value}
                    name="reason"
                    inputType={ModalInputTypes.Select}
                    attemptedSubmission={attemptedSubmission}
                    handleModalFormInputChange={this._handleReasonSelectChange}
                    options={
                      data.isActive
                        ? data.deactivateReasonsSerialized
                        : data.reactivateReasonsSerialized
                    }
                  />
                ) : null}
                {(reason.value === INCARCERATED.toLowerCase() &&
                  data.isActive === true) ||
                accountChangeReasonIndex === 1 ? (
                  <Columns>
                    <Columns.Column size="half" className="first-half">
                      <DatePickerWrapper
                        selectedDate={recidivismDate}
                        label={"Date of Recidivism"}
                        placeholder={"--/--/----"}
                        name={"recidivismDate"}
                        popperPlacement={"bottom"}
                        validations={validations}
                        onChange={(date: Moment) => {
                          handleDatePickerChange({
                            value: date,
                            name: "recidivismDate",
                            type: "start-date-optional",
                          });
                        }}
                        onChangeRaw={handleDatePickerRawChange}
                        type={"start-date-optional"}
                      />
                    </Columns.Column>
                    <Columns.Column size="half" className="second-half">
                      <ModalSelect
                        modalStateKey="recidivismReason"
                        label={reason.label}
                        validations={validations}
                        value={recidivismReason.value}
                        name="recidivismReason"
                        inputType={ModalInputTypes.Select}
                        attemptedSubmission={attemptedSubmission}
                        handleModalFormInputChange={handleModalFormInputChange}
                        options={recidivismReasonsSerialized}
                      />
                    </Columns.Column>
                  </Columns>
                ) : null}
                <div className="modal-input-note">
                  <ModalInput
                    label={note.label}
                    value={note.value}
                    inputType={"text"}
                    name={"note"}
                    placeholder="Leave a note (optional)..."
                    handleModalFormInputChange={handleModalFormInputChange}
                    attemptedSubmission={attemptedSubmission}
                    textArea={true}
                  />
                </div>
              </Columns.Column>
            </Columns>

            <Columns className="modal-column">
              <Button
                type="primary"
                style={{ width: "100%" }}
                onClick={(event: any) => handleFormSubmit(event)}
              >
                Next
              </Button>
            </Columns>
          </div>
        )}
      </ChangeParticipantIsActiveContext.Consumer>
    );
  }
}

export default ChangeParticipantActiverStatusForm;
