import * as React from "react";
import { Link } from "@reach/router";
import { DashboardBucket, MutationResult } from "../../shared/lib/graphql";
import { PageHeader } from "../../shared/components/elements/PageHeader";
import { WorkflowBucket } from "./WorkflowBucket";
import { urlBuilderFn } from "../screens/ParticipantDetailsTabs";
import { LinkedNameBuilder } from "../elements/LinkedName";
import { reviewReviewItem } from "../../actions/reviewItems";
import { getFeatureFlags } from "../../featureFlags";
import { CheckInReport } from "./CheckInReport";

export interface WorkflowContainerProps {
  baseUrl: string;
  caseManagerId: string;
  buckets: DashboardBucket[];
  pageTitleTag?: React.ReactNode;
}

const onReview = async (id: string, reviewed: boolean): Promise<boolean> => {
  const result: any = await reviewReviewItem({
    id,
    reviewed,
    expire: true,
  });
  return result.reviewReviewItem.result !== MutationResult.Failure;
};

export class WorkflowContainer extends React.PureComponent<
  WorkflowContainerProps
> {
  render() {
    const { baseUrl, buckets, caseManagerId, pageTitleTag } = this.props;
    // validate the shape of the buckets, log an error if something is off
    const nonNullBuckets = buckets.filter((bucket) => {
      if (!bucket || !bucket.title || !bucket.key) {
        console.error("Invalid bucket detected.");
        return false;
      } else {
        return true;
      }
    });

    return (
      <div className="workflow">
        <PageHeader
          pageName="Workflow"
          pageBlurb={`${
            getFeatureFlags().clientDisplayTerm
          }s who need your attention. Please review and take the appropriate action. Each report will be updated daily.`}
          titleTag={pageTitleTag}
        />
        <div className="constrained-section activeContentContainer">
          {getFeatureFlags().workflow.showCheckInDownload && <CheckInReport />}

          {nonNullBuckets.map((bucket) => (
            <WorkflowBucket
              key={`bucket-${bucket.key}`}
              caseManagerId={caseManagerId}
              bucket={bucket}
              nameComponent={LinkedNameBuilder(urlBuilderFn)({ baseUrl, Link })}
              onReview={onReview}
            />
          ))}
        </div>
      </div>
    );
  }
}
