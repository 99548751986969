import React, { Component } from "react";
import "../ModalForm.scss";
import { Columns, Form } from "react-bulma-components";
import { Button } from "antd";
import ModalInput from "../ModalInput";
import ModalSelect from "../ModalSelect";
import { CreateParticipantContext } from "../../../utils/context";
import {
  KeyedString,
  ModalInputTypes,
} from "../../../shared/lib/graphql/index";
import { ValidationItemType } from "../types";
import { ParticipantModalFormData } from "./AddParticipantModalWrapper";
import { getFeatureFlags } from "../../../featureFlags";
import DatePickerWrapper from "../../DatePickerWrapper";
import { Moment } from "moment";

interface State {}

interface ParticipantFormValidations {
  firstName: ValidationItemType;
  lastName: ValidationItemType;
  mobileNumber: ValidationItemType;
  language: ValidationItemType;
  assessmentScore: ValidationItemType;
  assessmentInstrument: ValidationItemType;
  assessmentRiskLevel: ValidationItemType;
  caseManager: ValidationItemType;
  startDate: ValidationItemType;
  endDate: ValidationItemType;
}

interface Props {
  handleModalFormInputChange: Function;
  handleFormSubmit: Function;
  attemptedSubmission: boolean;
  modalFormData: ParticipantModalFormData;
  validations: ParticipantFormValidations;
  modalType: string;
  handlePhoneValidation: Function;
  handleDatePickerChange: Function;
  handleDatePickerRawChange: Function;
}

class AddParticipantForm extends Component<Props, State> {
  render() {
    const {
      handleModalFormInputChange,
      modalFormData,
      handleFormSubmit,
      validations,
      attemptedSubmission,
      handlePhoneValidation,
      handleDatePickerChange,
      handleDatePickerRawChange,
    } = this.props;

    const { supportsAssessment, supportsProbationNumber } = getFeatureFlags();

    const {
      firstName,
      lastName,
      mobileNumber,
      language,
      assessmentScore,
      caseManager,
      preferredName,
      probationNumber,
      assessmentInstrument = supportsAssessment ? ({} as any) : undefined,
      assessmentRiskLevel = supportsAssessment ? ({} as any) : undefined,
      compliance,
      startDate,
      endDate,
    } = modalFormData;

    type CreateParticipantContextData = {
      riskLevelsSerialized: KeyedString[];
      caseManagersSerialized: KeyedString[];
      assessmentToolsSerialized: KeyedString[];
      languagesSerialized: KeyedString[];
      assessmentToolDictionary: { [key: string]: any };
      complianceOptionsSerialized: KeyedString[];
    };

    return (
      <CreateParticipantContext.Consumer>
        {(data: CreateParticipantContextData) => (
          <div className="modal-form">
            <Columns className="modal-column">
              <Columns.Column size={"one-third"} className={"first-half"}>
                <ModalInput
                  label={firstName.label}
                  validations={validations}
                  value={firstName.value}
                  name={"firstName"}
                  placeholder={"First name"}
                  inputType={ModalInputTypes.Name}
                  attemptedSubmission={attemptedSubmission}
                  handleModalFormInputChange={handleModalFormInputChange}
                />
              </Columns.Column>
              <Columns.Column size={"one-third"} className={"first-half"}>
                <ModalInput
                  label={lastName.label}
                  validations={validations}
                  value={lastName.value}
                  name={"lastName"}
                  placeholder={"Last name"}
                  inputType={ModalInputTypes.Name}
                  attemptedSubmission={attemptedSubmission}
                  handleModalFormInputChange={handleModalFormInputChange}
                />
              </Columns.Column>

              <Columns.Column size={"one-third"} className={"first-half"}>
                <ModalInput
                  label={preferredName.label}
                  validations={validations}
                  value={preferredName.value}
                  name={"preferredName"}
                  placeholder={"Preferred name"}
                  inputType={ModalInputTypes.NameOptional}
                  attemptedSubmission={attemptedSubmission}
                  handleModalFormInputChange={handleModalFormInputChange}
                />
              </Columns.Column>
            </Columns>

            <Columns className="modal-column">
              <Columns.Column size={"half"} className={"first-half"}>
                <ModalInput
                  label={mobileNumber.label}
                  validations={validations}
                  value={mobileNumber.value}
                  toolTipText={`This must be a mobile number capable of receiving SMS messages.`}
                  name={"mobileNumber"}
                  placeholder={"Mobile number (optional)"}
                  inputType={ModalInputTypes.Phone}
                  attemptedSubmission={attemptedSubmission}
                  handleModalFormInputChange={handleModalFormInputChange}
                  onBlur={handlePhoneValidation}
                />
              </Columns.Column>

              <Columns.Column size={"half"} className={"first-half"}>
                <ModalSelect
                  modalStateKey="language"
                  label={language.label}
                  validations={validations}
                  value={language.value}
                  name="language"
                  inputType={ModalInputTypes.Select}
                  attemptedSubmission={attemptedSubmission}
                  handleModalFormInputChange={handleModalFormInputChange}
                  options={data.languagesSerialized}
                />
              </Columns.Column>
            </Columns>

            <Columns className="modal-column">
              <Columns.Column size={"half"} className={"first-half"}>
                <ModalSelect
                  modalStateKey="assessmentRiskLevel"
                  label={assessmentRiskLevel.label}
                  validations={validations}
                  value={assessmentRiskLevel.value}
                  name="assessmentRiskLevel"
                  inputType={ModalInputTypes.Select}
                  attemptedSubmission={attemptedSubmission}
                  handleModalFormInputChange={handleModalFormInputChange}
                  options={data.riskLevelsSerialized}
                />
              </Columns.Column>

              <Columns.Column size={"half"} className={"first-half"}>
                <ModalSelect
                  modalStateKey="compliance"
                  label={compliance.label}
                  validations={validations}
                  value={compliance.value}
                  toolTipText={`${
                    getFeatureFlags().clientDisplayTerm || "Client"
                  }'s overall ${
                    getFeatureFlags().whatIsComplianceCalled || "Compliance"
                  } in the program`}
                  name="compliance"
                  inputType={ModalInputTypes.SelectOptional}
                  attemptedSubmission={attemptedSubmission}
                  handleModalFormInputChange={handleModalFormInputChange}
                  options={data.complianceOptionsSerialized}
                />
              </Columns.Column>
            </Columns>

            {supportsAssessment && (
              <Columns className="modal-column">
                <Columns.Column size="half" className="first-half">
                  <ModalSelect
                    modalStateKey="assessmentInstrument"
                    label={assessmentInstrument.label}
                    validations={validations}
                    value={assessmentInstrument.value}
                    name="assessmentInstrument"
                    inputType={ModalInputTypes.Select}
                    attemptedSubmission={attemptedSubmission}
                    handleModalFormInputChange={handleModalFormInputChange}
                    options={data.assessmentToolsSerialized}
                  />
                </Columns.Column>
                <Columns.Column size="half" className="first-half">
                  <ModalSelect
                    modalStateKey="assessmentScore"
                    label={assessmentScore.label}
                    validations={validations}
                    value={assessmentScore.value}
                    name="assessmentScore"
                    inputType={ModalInputTypes.Select}
                    attemptedSubmission={attemptedSubmission}
                    handleModalFormInputChange={handleModalFormInputChange}
                    options={
                      data.assessmentToolDictionary[
                        assessmentInstrument.value
                      ] || []
                    }
                  />
                </Columns.Column>
              </Columns>
            )}
            <Form.Field>
              <Columns className="modal-column">
                <Columns.Column size="half" className="first-half">
                  <DatePickerWrapper
                    selectedDate={startDate}
                    label={"Supervision Begin Date"}
                    placeholder={"--/--/----"}
                    name={"startDate"}
                    popperPlacement={"top"}
                    validations={validations}
                    onChange={(date: Moment) => {
                      handleDatePickerChange({
                        value: date,
                        name: "startDate",
                        type: "start-date-optional",
                      });
                    }}
                    onChangeRaw={handleDatePickerRawChange}
                    type={"start-date-optional"}
                  />
                </Columns.Column>
                <Columns.Column size="half" className="second-half">
                  <DatePickerWrapper
                    selectedDate={endDate}
                    name={"endDate"}
                    label={"Supervision End Date"}
                    placeholder={"--/--/----"}
                    popperPlacement={"top"}
                    validations={validations}
                    onChange={(date: Moment) => {
                      handleDatePickerChange({
                        value: date,
                        name: "endDate",
                        type: "end-date-optional",
                      });
                    }}
                    onChangeRaw={handleDatePickerRawChange}
                    minDate={startDate.value ? startDate.value : undefined}
                    type={"end-date-optional"}
                  />
                </Columns.Column>
              </Columns>
            </Form.Field>

            {supportsProbationNumber && (
              <Columns className="modal-column">
                <Columns.Column>
                  <ModalInput
                    label={probationNumber.label}
                    validations={validations}
                    value={probationNumber.value}
                    name={"probationNumber"}
                    placeholder={"Probation Number"}
                    inputType={ModalInputTypes.Number}
                    attemptedSubmission={attemptedSubmission}
                    handleModalFormInputChange={handleModalFormInputChange}
                  />
                </Columns.Column>
              </Columns>
            )}

            <ModalSelect
              modalStateKey="caseManager"
              label={caseManager.label}
              validations={validations}
              value={caseManager.value}
              name="caseManager"
              inputType={ModalInputTypes.Select}
              attemptedSubmission={attemptedSubmission}
              handleModalFormInputChange={handleModalFormInputChange}
              options={data.caseManagersSerialized}
            />
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={(event: any) => handleFormSubmit(event)}
            >
              Next
            </Button>
          </div>
        )}
      </CreateParticipantContext.Consumer>
    );
  }
}

export default AddParticipantForm;
