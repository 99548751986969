/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type PrintEventContainerQueryVariables = {
    readonly participant_id?: string | null;
    readonly event_id?: string | null;
};
export type PrintEventContainerQueryResponse = {
    readonly participants: ({
        readonly participant: ReadonlyArray<({
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
            readonly case_manager: ({
                readonly id: string;
                readonly phone: ({
                    readonly mobile: string | null;
                }) | null;
                readonly name: {
                    readonly first: string;
                    readonly last: string | null;
                };
            }) | null;
            readonly language: string;
            readonly events: ReadonlyArray<({
                readonly id: string | null;
                readonly date: string | null;
                readonly activity: {
                    readonly title: {
                        readonly en: string | null;
                        readonly es: string | null;
                    };
                };
                readonly obligation: ({
                    readonly title: ({
                        readonly en: string | null;
                        readonly es: string | null;
                    }) | null;
                }) | null;
                readonly service_provider: {
                    readonly address: string | null;
                };
            }) | null> | null;
        }) | null> | null;
    }) | null;
};
export type PrintEventContainerQuery = {
    readonly response: PrintEventContainerQueryResponse;
    readonly variables: PrintEventContainerQueryVariables;
};



/*
query PrintEventContainerQuery(
  $participant_id: String
  $event_id: String
) {
  participants(id: $participant_id) {
    participant {
      id
      name {
        first
        last
      }
      case_manager {
        id
        phone {
          mobile
        }
        name {
          first
          last
        }
      }
      language
      events(id: $event_id) {
        id
        date
        activity {
          title {
            en
            es
          }
          id
        }
        obligation {
          title {
            en
            es
          }
          id
        }
        service_provider {
          address
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "participant_id",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "event_id",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "participant_id"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "name",
        "storageKey": null,
        "args": null,
        "concreteType": "PersonName",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "first",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "last",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v4 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "case_manager",
        "storageKey": null,
        "args": null,
        "concreteType": "CaseManager",
        "plural": false,
        "selections": [
            (v2 /*: any*/),
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "phone",
                "storageKey": null,
                "args": null,
                "concreteType": "PersonPhone",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "mobile",
                        "args": null,
                        "storageKey": null
                    }
                ]
            },
            (v3 /*: any*/)
        ]
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "language",
        "args": null,
        "storageKey": null
    } as any), v6 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "event_id"
        } as any)
    ], v7 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "date",
        "args": null,
        "storageKey": null
    } as any), v8 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "title",
        "storageKey": null,
        "args": null,
        "concreteType": "i18nText",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "en",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "es",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v9 = [
        (v8 /*: any*/)
    ], v10 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "address",
        "args": null,
        "storageKey": null
    } as any), v11 = [
        (v8 /*: any*/),
        (v2 /*: any*/)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "PrintEventContainerQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participants",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Pagination",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "participant",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Participant",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "events",
                                    "storageKey": null,
                                    "args": (v6 /*: any*/),
                                    "concreteType": "Event",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v7 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "activity",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "Activity",
                                            "plural": false,
                                            "selections": (v9 /*: any*/)
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "obligation",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "Obligation",
                                            "plural": false,
                                            "selections": (v9 /*: any*/)
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "service_provider",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "ServiceProvider",
                                            "plural": false,
                                            "selections": [
                                                (v10 /*: any*/)
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "PrintEventContainerQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participants",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Pagination",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "participant",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Participant",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "events",
                                    "storageKey": null,
                                    "args": (v6 /*: any*/),
                                    "concreteType": "Event",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v7 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "activity",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "Activity",
                                            "plural": false,
                                            "selections": (v11 /*: any*/)
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "obligation",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "Obligation",
                                            "plural": false,
                                            "selections": (v11 /*: any*/)
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "service_provider",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "ServiceProvider",
                                            "plural": false,
                                            "selections": [
                                                (v10 /*: any*/),
                                                (v2 /*: any*/)
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "PrintEventContainerQuery",
            "id": null,
            "text": "query PrintEventContainerQuery(\n  $participant_id: String\n  $event_id: String\n) {\n  participants(id: $participant_id) {\n    participant {\n      id\n      name {\n        first\n        last\n      }\n      case_manager {\n        id\n        phone {\n          mobile\n        }\n        name {\n          first\n          last\n        }\n      }\n      language\n      events(id: $event_id) {\n        id\n        date\n        activity {\n          title {\n            en\n            es\n          }\n          id\n        }\n        obligation {\n          title {\n            en\n            es\n          }\n          id\n        }\n        service_provider {\n          address\n          id\n        }\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '135782e638a4cd6dbc76e3c649a14420';
export default node;
