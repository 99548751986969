import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "../shared/lib/graphql/commitMutation";
import { MutationEmailTransmittalArgs } from "../graphql-types";
import { updateRelayStoreAddBookingGroup } from "./booking_groups";

export const emailTransmittal = (variables: MutationEmailTransmittalArgs) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation emailTransmittalMutation($booking_group_id: String!) {
          emailTransmittal(booking_group_id: $booking_group_id) {
            result
            description
            booking_group {
              id
              bookings {
                id
                booking_defendent {
                  cii_number
                  local_id
                  cdl_id
                  name {
                    first
                    last
                  }
                  date_of_birth
                  sex
                  race
                }
                booking_info {
                  booking_sid
                  local_id
                  booking_number
                  booking_datetime
                  booking_address
                }
                booking_charges {
                  id
                  booking_charge_sid
                  booking_sid
                  booking_number
                  booking_datetime
                  charge_auth_type
                  charge_code_section
                  charge_type
                  charge_datetime
                  charge_description
                  arrest_agency
                  arrest_datetime
                  crime_case_number
                  court_warrant_number
                }
                booking_releases {
                  charge_code_section
                  charge_description
                  charge_type
                  charge_release_datetime
                  charge_release_type
                  warrant_number
                  court_case_number
                  jc_control
                  created_datetime
                }
                booking_status {
                  booking_number
                  court_appearance_datetime
                  court_reason
                }
                classification
                imported_date
                comments
              }
              ineligible_bookings {
                id
                booking_defendent {
                  cii_number
                  local_id
                  cdl_id
                  name {
                    first
                    last
                  }
                  date_of_birth
                  sex
                  race
                }
                booking_info {
                  booking_sid
                  local_id
                  booking_number
                  booking_datetime
                  booking_address
                }
                booking_charges {
                  id
                  booking_charge_sid
                  booking_sid
                  booking_number
                  booking_datetime
                  charge_auth_type
                  charge_code_section
                  charge_type
                  charge_datetime
                  charge_description
                  arrest_agency
                  arrest_datetime
                  crime_case_number
                  court_warrant_number
                }
                booking_releases {
                  charge_code_section
                  charge_description
                  charge_type
                  charge_release_datetime
                  charge_release_type
                  warrant_number
                  court_case_number
                  jc_control
                  created_datetime
                }
                booking_status {
                  booking_number
                  court_appearance_datetime
                  court_reason
                }
                classification
                imported_date
                comments
                is_eligible
                spanish_speaker
                has_wait
              }
              email_sent
            }
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreAddBookingGroup(
          store,
          "emailTransmittal",
          variables.booking_group_id
        );
      },
    },
    "Error while emailing transmittal"
  );
};
