import React, { useState } from "react";
import { Participant, PersonSex } from "../../graphql-types";
import {
  Button,
  DatePicker,
  Input,
  Form,
  Icon,
  Row,
  Col,
  Typography,
  Select,
} from "antd";
import moment, { Moment } from "moment";
import "./ParticipantVictims.scss";
import { updateParticipant } from "../../actions/participants";
import { getUpdateParticipantBaseVariables } from "../participant-profile/ParticipantProfileContainer";

interface Props {
  participant: Participant;
}

interface ParticipantVictimFormEntry {
  first_name: string;
  last_name: string;
  date_of_birth: Moment | undefined;
  address: string;
  phone: string;
  sex: PersonSex;
}

const dateFormatList = ["MM/DD/YYYY"];

export function ParticipantVictimsForm(props: Props) {
  const initialVictims =
    props.participant.victims && props.participant.victims.length > 0
      ? props.participant.victims.map((initialVictim) => {
          return {
            first_name:
              initialVictim.name && initialVictim.name.first
                ? initialVictim.name.first
                : "",
            last_name:
              initialVictim.name && initialVictim.name.last
                ? initialVictim.name.last
                : "",
            date_of_birth: initialVictim.date_of_birth
              ? moment(initialVictim.date_of_birth)
              : undefined,
            address: initialVictim.address || "",
            phone: initialVictim.phone || "",
            sex: initialVictim.sex || PersonSex.Unknown,
          };
        })
      : ([] as ParticipantVictimFormEntry[]);

  const [victims, setVictims] = useState(initialVictims);

  const addVictim = (victim: ParticipantVictimFormEntry) => {
    const newVictims = [...victims, victim];
    setVictims(newVictims);
  };

  const mapVictimsStateToVictimsInput = (
    victims: ParticipantVictimFormEntry[]
  ) => {
    return victims.map((victim) => {
      return {
        name: {
          first: victim.first_name || "",
          last: victim.last_name || "",
        },
        date_of_birth: victim.date_of_birth
          ? victim.date_of_birth.toISOString()
          : null,
        address: victim.address || "",
        phone: victim.phone || "",
        sex: victim.sex || PersonSex.Unknown,
      };
    });
  };

  const removeVictim = async (indexToRemove: number) => {
    const newVictims = victims.filter(
      (_victim, index) => index !== indexToRemove
    );

    const participantBaseVariables = getUpdateParticipantBaseVariables(
      props.participant
    );

    const victimData = mapVictimsStateToVictimsInput(newVictims);

    await updateParticipant(
      { ...participantBaseVariables, victims: victimData },
      props.participant as any
    );

    setVictims(newVictims);
  };

  const handleSavebuttonClicked = async () => {
    const victimData = mapVictimsStateToVictimsInput(victims);

    const participantBaseVariables = getUpdateParticipantBaseVariables(
      props.participant
    );

    await updateParticipant(
      { ...participantBaseVariables, victims: victimData },
      props.participant as any
    );
  };

  const updateVictim = (
    newVictim: ParticipantVictimFormEntry,
    indexToUpdate: number
  ) => {
    const newVictims = victims.map((victim, index) => {
      if (index === indexToUpdate) {
        return newVictim;
      }
      return victim;
    });

    setVictims(newVictims);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    indexToChange: number
  ) => {
    const { name, value } = event.target;

    const victimToChange = victims[indexToChange];
    updateVictim({ ...victimToChange, [name]: value }, indexToChange);
  };

  const handleSelectChange = (value: PersonSex, indexToChange: number) => {
    const victimToChange = victims[indexToChange];
    updateVictim({ ...victimToChange, sex: value }, indexToChange);
  };

  const { Title } = Typography;
  const { Option } = Select;

  return (
    <div className="participant-victims-wrapper">
      <div className="participant-victims-box">
        <Title level={4}>Victims</Title>
        {victims.map((victim: ParticipantVictimFormEntry, index) => (
          <div key={index} className="victim-box">
            <Row gutter={[16, 0]}>
              <Col span={3}>
                <Form.Item label="First Name">
                  <Input
                    value={victim.first_name}
                    name="first_name"
                    placeholder="first"
                    onChange={(event) => handleInputChange(event, index)}
                  />
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item label="Last name">
                  <Input
                    value={victim.last_name}
                    name="last_name"
                    placeholder="last"
                    onChange={(event) => handleInputChange(event, index)}
                  />
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item label="Address">
                  <Input
                    value={victim.address}
                    name="address"
                    placeholder="address"
                    onChange={(event) => handleInputChange(event, index)}
                  />
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item label="Phone">
                  <Input
                    value={victim.phone}
                    name="phone"
                    placeholder="phone"
                    onChange={(event) => handleInputChange(event, index)}
                  />
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item label="Date of birth">
                  <DatePicker
                    value={victim.date_of_birth}
                    format={dateFormatList}
                    onChange={(dateMoment) =>
                      updateVictim(
                        { ...victim, date_of_birth: dateMoment || undefined },
                        index
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="Sex">
                  <Select
                    value={victim.sex}
                    onChange={(value: PersonSex) =>
                      handleSelectChange(value, index)
                    }
                  >
                    <Option value={PersonSex.Unknown}>Unknown</Option>
                    <Option value={PersonSex.Female}>Female</Option>
                    <Option value={PersonSex.Male}>Male</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={3}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "103px",
                  }}
                >
                  <Button type="danger" onClick={() => removeVictim(index)}>
                    Remove Victim
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        ))}
        <Row>
          <Col span={3}>
            <Button
              type="dashed"
              onClick={() =>
                addVictim({
                  first_name: "",
                  last_name: "",
                  address: "",
                  date_of_birth: undefined,
                  phone: "",
                  sex: PersonSex.Unknown,
                })
              }
            >
              <Icon type="plus" /> Add Victim
            </Button>
          </Col>
          {victims.length > 0 && (
            <Col span={3}>
              <Button type="primary" onClick={handleSavebuttonClicked}>
                Save
              </Button>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
}
