import * as React from "react";
import SortableTableHeader from "./SortableTableHeader";
import SortableTableBody from "./SortableTableBody";
import { getByKey } from "./tableUtil";
import "./css/SortableTable.css";

import {
  TableColumn,
  TableColumnKey,
  TableData,
  TableRenderExpandedRow,
  TableSortingItem,
  TableSortings,
} from "./tableTypes";

type Props<T, TKey> = {
  className?: string;
  customExpandedRowClassName?: string;
  data: TableData<T>;
  tableKey?: string;
  columns: Array<TableColumn<T, TKey>>;
  iconAsc?: React.ReactNode;
  iconBoth?: React.ReactNode;
  iconDesc?: React.ReactNode;
  iconStyle?: Object;
  isUnsorted?: boolean;
  style?: Object;
  renderExpandedRow?: TableRenderExpandedRow;
};

type State = {
  sortings: TableSortings;
  sortingIndex: number;
};

export default class SortableTable<T, TKey = any> extends React.Component<
  Props<T, TKey>,
  State
> {
  constructor(props: Props<T, TKey>) {
    super(props);
    const defaultSortings = this._getDefaultSortings(props);

    this.state = {
      sortings: defaultSortings,
      sortingIndex: this._getFirstSortedIndex(defaultSortings),
    };
  }

  _getDefaultSortings(props: Props<T, TKey>): TableSortings {
    return props.columns.map((column) => {
      let sorting: TableSortingItem = "both";
      if (column.defaultSorting) {
        const defaultSorting = column.defaultSorting.toLowerCase();

        if (defaultSorting === "desc" || defaultSorting === "asc") {
          sorting = defaultSorting;
        }
      }
      return sorting;
    });
  }

  _getFirstSortedIndex(sortings: TableSortings) {
    for (let i = 0; i < sortings.length; i++) {
      if (sortings[i] !== "both") {
        return i;
      }
    }
    return 0;
  }

  sortData(data: TableData<T>, sortings: TableSortings) {
    let sortedData = data.slice();

    if (this.props.isUnsorted) {
      return sortedData;
    }

    const index = this.state.sortingIndex;

    if (!isNaN(index)) {
      const sorting = sortings[index];
      const column = this.props.columns[index];
      const key = this.props.columns[index].key;
      switch (sorting) {
        case "desc":
          if (
            column.descSortFunction &&
            typeof column.descSortFunction === "function"
          ) {
            sortedData = column.descSortFunction(sortedData, key);
          } else {
            sortedData = this.descSortData(sortedData, key);
          }
          break;
        case "asc":
          if (
            column.ascSortFunction &&
            typeof column.ascSortFunction === "function"
          ) {
            sortedData = column.ascSortFunction(sortedData, key);
          } else {
            sortedData = this.ascSortData(sortedData, key);
          }
          break;
        default:
      }
    }

    return sortedData;
  }

  ascSortData(data: TableData<T>, key: TableColumnKey<T, TKey>) {
    return this._sortDataByKey(data, key, (a: any, b: any) => {
      if (this.parseFloatable(a) && this.parseFloatable(b)) {
        a = this.parseIfFloat(a);
        b = this.parseIfFloat(b);
      }
      if (a >= b) {
        return 1;
      } else if (a < b) {
        return -1;
      }
      return 0;
    });
  }

  descSortData(data: TableData<T>, key: TableColumnKey<T, TKey>) {
    return this._sortDataByKey(data, key, (a, b) => {
      if (
        typeof a === "string" && // Required to double check type for Flow to pass
        typeof b === "string" &&
        this.parseFloatable(a) &&
        this.parseFloatable(b)
      ) {
        // TODO: Hrm this `as any` escape hatch seems like it is overriding a check that is useful
        a = this.parseIfFloat(a) as any;
        b = this.parseIfFloat(b) as any;
      }
      if (a <= b) {
        return 1;
      } else if (a > b) {
        return -1;
      }
      return 0;
    });
  }

  parseFloatable(value: any): boolean {
    return typeof value === "string" &&
      (/^\d+$/.test(value) || /^\d+$/.test(value.replace(/[,.%$]/g, "")))
      ? true
      : false;
  }

  parseIfFloat(value: string) {
    return parseFloat(value.replace(/,/g, ""));
  }

  _sortDataByKey(
    data: TableData<T>,
    key: TableColumnKey<T, TKey>,
    fn: (a: T, b: T) => number
  ) {
    const clone = data.slice();

    return clone.sort((a, b) => {
      return fn(getByKey<T, TKey>(a, key), getByKey(b, key));
    });
  }

  _onStateChange = (index: number) => {
    const sortings = this.state.sortings
      .map((sorting: TableSortingItem, i: number) => {
        if (i === index) {
          sorting = this.nextSortingState(sorting);
        }

        return sorting;
      })
      .filter((sorting) => sorting !== "");

    this.setState({
      sortings,
      sortingIndex: index,
    });
  };

  nextSortingState(state: string): TableSortingItem {
    let next = "";
    switch (state) {
      case "both":
        next = "desc";
        break;
      case "desc":
        next = "asc";
        break;
      case "asc":
        next = "desc";
        break;
      default:
        console.error(`Unknown sort state ${state}.`);
    }
    return next as TableSortingItem;
  }

  render() {
    if (this.props.columns.length === 0) {
      return null;
    }
    const { tableKey } = this.props;
    const sortedData = this.sortData(this.props.data, this.state.sortings);

    return (
      <table
        className={"SortableTable " + (this.props.className || "")}
        style={this.props.style}
      >
        <SortableTableHeader
          columns={this.props.columns}
          sortings={this.state.sortings}
          sortingIndex={this.state.sortingIndex}
          onStateChange={this._onStateChange}
          iconStyle={this.props.iconStyle}
          iconDesc={this.props.iconDesc}
          iconAsc={this.props.iconAsc}
          iconBoth={this.props.iconBoth}
          data={sortedData}
        />
        <SortableTableBody
          columns={this.props.columns}
          data={sortedData}
          tableKey={tableKey}
          sortings={this.state.sortings}
          renderExpandedRow={this.props.renderExpandedRow}
          customExpandedRowClassName={this.props.customExpandedRowClassName}
        />
      </table>
    );
  }
}
