import graphql from "babel-plugin-relay/macro";

import { commitMutation } from "../shared/lib/graphql/commitMutation";
import { checkMutationReturn } from "./util";
import { RecordSourceSelectorProxy } from "relay-runtime";
import { MutationReviewReviewItemArgs } from "../graphql-types";

export const reviewReviewItem = (args: MutationReviewReviewItemArgs) => {
  const { id, reviewed, expire } = args;

  return commitMutation(
    {
      mutation: graphql`
        mutation reviewItemsReviewMutation(
          $id: String!
          $reviewed: Boolean!
          $expire: Boolean
        ) {
          reviewReviewItem(id: $id, reviewed: $reviewed, expire: $expire) {
            result
            description
            review_item {
              id
              details
              expired
              reviewed
              reviewed_by_id
              reviewed_by_case_manager {
                id
                name {
                  first
                  last
                }
              }
              type
            }
          }
        }
      `,
      variables: {
        id,
        reviewed,
        expire,
      },

      updater: (store) => {
        const payload = checkMutationReturn(store, "reviewReviewItem");

        if (!payload) return;

        const existing = store.get(id);

        existing!.copyFieldsFrom(payload);
      },
    },
    "Error while reviewing a review item"
  );
};

export const expireReviewItem = (args: MutationReviewReviewItemArgs) => {
  const { id, reviewed, expire_immediately } = args;

  return commitMutation(
    {
      mutation: graphql`
        mutation reviewItemsExpireReviewItemMutation(
          $id: String!
          $reviewed: Boolean!
          $expire_immediately: Boolean
        ) {
          reviewReviewItem(
            id: $id
            reviewed: $reviewed
            expire_immediately: $expire_immediately
          ) {
            result
            description
            review_item {
              id
              details
              expired
              reviewed
              reviewed_by_id
              reviewed_by_case_manager {
                id
                name {
                  first
                  last
                }
              }
              type
            }
          }
        }
      `,
      variables: {
        id,
        reviewed,
        expire_immediately,
      },

      updater: (store) => {
        updateRelayStoreRemoveReiewItem(store, id);
      },
    },
    "Error while expiring a review item"
  );
};

const updateRelayStoreRemoveReiewItem = (
  store: RecordSourceSelectorProxy,
  deletedId: string
) => {
  store.delete(deletedId);
};
