import * as React from "react";
import { Columns, Section } from "react-bulma-components";

import "./PrintEvent.scss";
import { Event, Participant } from "../../shared/lib/graphql/flowTypes";
import moment from "moment";
import { getFeatureFlags } from "../../featureFlags";
import { getEventTitle } from "../../../src/utils/eventTitle";

interface Props {
  projectId: string;
  event: Event;
  participant: Participant;
  onReady: Function;
}

function formatPhoneNumber(phoneNumberString: string | number): string {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return "";
}

class PrintEvent extends React.Component<Props> {
  componentDidMount() {
    document.title = `Promise: Printing Event (${getEventTitle(
      this.props.event
    )})`;
    this.props.onReady();
  }
  render() {
    const event: Event = this.props.event;
    const participant: Participant = this.props.participant;
    const title = getEventTitle(event);
    const logo = getFeatureFlags().logoDataURI;

    const address = (
      <div>
        {(event.service_provider
          ? event.service_provider.address
          : "Unknown address"
        )
          .split(",")
          .map((part, idx) => (
            <div key={idx}>{part}</div>
          ))}
      </div>
    );

    return (
      <div className="print-event">
        <div className="print-message">
          Preparing to print your event reminder...
          <div className="morePrintingInfo">
            If you don't see the print pop-up in a few seconds, you may need{" "}
            <br />
            to select "File" and then "Print" on your browser window.
          </div>
        </div>
        <div className="print-page">
          <div className="title">
            {typeof logo === "string" ? (
              <>
                <img src={logo} />
                <br />
              </>
            ) : null}
            {headers[this.props.projectId] || ""}
          </div>
          <Section>
            <Columns>
              <Columns.Column>
                <div className="page-value has-text-weight-bold">
                  Appointment
                  <br />
                  Reminder
                </div>
              </Columns.Column>
              <Columns.Column>
                <div className="event-label">For:</div>
                <div className="page-value">
                  {`${participant.name.first} ${
                    participant.name.last || ""
                  }`.trim()}
                </div>
              </Columns.Column>
              <Columns.Column>
                <div className="event-label">Print Date:</div>
                <div className="page-value">
                  {moment().format("ddd MMM DD, YYYY")}
                </div>
              </Columns.Column>
            </Columns>
          </Section>
          <Section>
            <div className="event-block">
              <div className="event-title">{title}</div>
              <Columns>
                <Columns.Column>
                  <Columns className="event-box lines-top lines-right">
                    <Columns.Column size="one-quarter" className="event-label">
                      Date:{" "}
                    </Columns.Column>
                    <Columns.Column className="event-value">
                      {moment(event.date).format("ddd MMM DD, YYYY")}
                    </Columns.Column>
                  </Columns>
                  <Columns className="event-box lines-top lines-right">
                    <Columns.Column size="one-quarter" className="event-label">
                      Time:{" "}
                    </Columns.Column>
                    <Columns.Column className="event-value">
                      {moment(event.date).format("h:mm A")}
                    </Columns.Column>
                  </Columns>
                </Columns.Column>
                <Columns.Column>
                  <Columns className="event-box lines-top">
                    <Columns.Column size="one-quarter" className="event-label">
                      Location:{" "}
                    </Columns.Column>
                    <Columns.Column className="event-value">
                      {address}
                    </Columns.Column>
                  </Columns>
                </Columns.Column>
              </Columns>
            </div>
          </Section>
          <Section style={{ fontSize: 20 }}>
            If you have any questions, call{" "}
            {`${participant.case_manager.name.first} ${
              participant.case_manager.name.last
            } at ${formatPhoneNumber(participant.case_manager.phone.mobile)}.`}
          </Section>
        </div>
      </div>
    );
  }
}

const headers = {
  "promise-demo-dashboard": "CENTER FOR POSITIVE CHANGE",
  "solano-cpc": "CENTER FOR POSITIVE CHANGE",
  "solano-cpc-staging": "CENTER FOR POSITIVE CHANGE",
  "solano-cpc-dev": "CENTER FOR POSITIVE CHANGE",
  "solano-cpc-training": "CENTER FOR POSITIVE CHANGE",
} as { [key: string]: string };

export default PrintEvent;
