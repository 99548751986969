import * as React from "react";
import { MessageList, MessageListDataSource } from "react-chat-elements";

import "./rce.scss";
import { ChatInput, ChatInputProps } from "./ChatInput";
import { ChatLoadMore, ChatLoadMorePropsExternal } from "./ChatLoadMore";
import { RCEMessagePlus } from "./ChatContainer";

interface ChatPropsBase {
  canLoadMore: boolean;
  canWriteMessages: boolean;
  messages: MessageListDataSource;
  isGroupChat: boolean;
}

export type ChatProps = ChatPropsBase &
  ChatInputProps &
  ChatLoadMorePropsExternal;

export const Chat = (props: ChatProps) => {
  const {
    canWriteMessages,
    canLoadMore,
    fetchMessages,
    hasMore,
    messages,
    participantLanguage,
    participantName,
    smsState,
    sendMessage,
    participantIsActive,
    appEnabled,
  } = props;

  const _onDownload = (message: RCEMessagePlus) => {
    if (message.type === "photo" && message.data) {
      window.open(message.data.uri);
    }
  };

  return (
    <>
      <ChatLoadMore
        enabled={canLoadMore}
        hasMore={hasMore}
        fetchMessages={fetchMessages}
      />
      <MessageList dataSource={messages} onDownload={_onDownload} />
      <ChatInput
        participantName={participantName}
        participantLanguage={participantLanguage}
        participantIsActive={participantIsActive}
        smsState={smsState}
        isGroupChat={props.isGroupChat}
        sendMessage={sendMessage}
        disabled={!canWriteMessages}
        appEnabled={appEnabled}
      />
    </>
  );
};
