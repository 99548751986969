import React from "react";
import { Row, Col } from "antd";
import moment from "moment";
import { Booking } from "../../graphql-types";

interface Props {
  booking: Booking;
  reportType?: string;
}

export function CourtReportHeader(props: Props) {
  const { booking, reportType } = props;
  const { booking_defendent: bookingDefendant } = booking;
  const defendantDOBFormatted = moment(bookingDefendant.date_of_birth).format(
    "MM/DD/YYYY"
  );

  const hearingDate =
    booking.booking_status && booking.booking_status.court_appearance_datetime
      ? moment(booking.booking_status.court_appearance_datetime)
      : null;

  const department =
    booking.booking_status && booking.booking_status.court_room
      ? booking.booking_status.court_room
      : "N/A";
  return (
    <div className="recommendation-form">
      <Row gutter={24}>
        <Col span={8}>
          <p style={{ fontWeight: "bold" }}>{`DEPT.: ${department}`}</p>
        </Col>
        <Col span={8} style={{ fontWeight: "bold" }}>
          {`Date.: ${hearingDate ? hearingDate.format("MM/DD/YYYY") : "N/A"}`}
        </Col>
        <Col span={8} style={{ fontWeight: "bold" }}>
          {`Time.: ${hearingDate ? hearingDate.format("hh:mm a") : "N/A"}`}
        </Col>
      </Row>
      <table>
        <tr>
          <td>SUPERIOR COURT OF CALIFORNIA, COUNTY OF MARIN BRANCH</td>
          <td
            rowSpan={5}
            className={`border-left bold-text italic-text center-text`}
          >
            FOR COURT USE ONLY
          </td>
        </tr>
        <tr className="border-none">
          <td className={`center-text bold-text`}>
            PEOPLE OF THE STATE OF CALIFORNIA
          </td>
        </tr>
        <tr className="border-none">
          <td className={`center-text padding-none`}>vs.</td>
        </tr>
        <tr className="border-none">
          <td>{`Defendant: ${bookingDefendant.name.last}, ${bookingDefendant.name.first}`}</td>
        </tr>
        <tr className="border-none"></tr>
        <tr>
          <td rowSpan={2} className={`bold-text border-left vertical-center`}>
            {reportType ? reportType : "PRETRIAL REPORT"}
          </td>
          <td className="border-left">{`Booking: ${booking.id}`}</td>
        </tr>
        <tr>
          <td>{`DOB: ${defendantDOBFormatted}`}</td>
        </tr>
      </table>
    </div>
  );
}
