const aliases = [
  { matches: [/pdp-sf\b/], alias: "sfpdp" },
  { matches: [/solano\b/], alias: "solano" },
  { matches: [/promise-demo\b/], alias: "demo" },
];

/**
 * Find in our list of known customer aliases,
 *  matching on any regex
 *  and return the 'accepted alias' for a customer string
 *
 * @param name The name that may be an alias
 */
export const findAlias = (name: string): string => {
  const match = aliases.find(({ matches }) =>
    matches.reduce(
      (matched: boolean, check: RegExp) => matched || check.test(name),
      false
    )
  );

  return match ? match.alias : name;
};
