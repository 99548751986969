import React, { PureComponent, ChangeEvent } from "react";
import { ListItemType } from "./GoalTaskList";
import { Level, Form } from "react-bulma-components";
import { Button } from "antd";
import { KeyedString, CaseManager } from "../../shared/lib/graphql/index";
import "./GoalsTasks.scss";
import { ScarePrompt } from "../../shared/components/elements/ScarePrompt";
import moment from "moment-timezone";
import { Check } from "@material-ui/icons";
import EditItemBox from "./EditItemBox";
import { UpdateParticipantPartialType } from "../participant-notes/NotesContainer";

interface SharedProps {
  id: string;
  onChangeCompletionStatus?: (taskId: string, isCompleted: boolean) => void;
  onTypeChange?: (taskId: string, value: string) => void;
  taskTypes?: KeyedString[];
  color?: string;
}

interface ListItemTaskContentsProps extends SharedProps {
  completedValue: string;
  typeValue?: string;
}

class ListItemTaskContents extends PureComponent<ListItemTaskContentsProps> {
  private onTypeChange = ({
    target: { value },
  }: ChangeEvent<HTMLSelectElement>) => {
    const { id, typeValue, onTypeChange } = this.props;
    if (onTypeChange && value !== typeValue) {
      onTypeChange(id, value);
    }
  };

  render() {
    const { taskTypes, typeValue } = this.props;

    return (
      <>
        {!(taskTypes && taskTypes.length) ? null : (
          <Level.Item>
            <Form.Field>
              <Form.Control>
                <Form.Select
                  size="small"
                  value={typeValue}
                  onChange={this.onTypeChange}
                >
                  <option key="" value="">
                    Task Type
                  </option>
                  {taskTypes.map(({ key, value }) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </Form.Select>
              </Form.Control>
            </Form.Field>
          </Level.Item>
        )}
      </>
    );
  }
}

interface ListItemPropsBase {
  itemType: string;
  completed_at?: string;
  type?: string;
  created_at?: string;
  updated_at?: string;
  case_manager?: CaseManager;
  caseManagerId?: string;
  handleRemoveItem: (id: string) => void;
  handleChangeItemText?: (variables: UpdateParticipantPartialType) => void;
  index: number;
  selectedEditIndex: number;
  handleEditSelectIndex: (selectedEditIndex: number) => void;
  handleResetSelectIndex: () => void;
}

interface ListItemState {
  editedItemText: string;
}

export type ListItemProps = ListItemPropsBase & ListItemType & SharedProps;

export class ListItem extends PureComponent<ListItemProps, ListItemState> {
  constructor(props: ListItemProps) {
    super(props);
    this.state = {
      editedItemText: "",
    };
  }
  _getSelectColor = (completedValue: string | undefined) => {
    return (
      (completedValue && completedValue == "complete" ? "success" : "danger") ||
      null
    );
  };

  _handleEditedItemTextChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    this.setState({
      editedItemText: value,
    });
  };

  _clearEditItemText = () => {
    this.setState({
      editedItemText: "",
    });
  };

  _handleSaveEditClicked = () => {
    const { id, handleChangeItemText, handleResetSelectIndex } = this.props;

    if (!handleChangeItemText) {
      return;
    }

    const { editedItemText } = this.state;

    handleChangeItemText({
      note_id: id,
      label: editedItemText,
    });
    handleResetSelectIndex();
  };

  _handleCancelEditClicked = () => {
    const { handleResetSelectIndex } = this.props;
    this._clearEditItemText();
    handleResetSelectIndex();
  };

  render() {
    const {
      label,
      itemType,
      id,
      handleRemoveItem,
      onChangeCompletionStatus,
      taskTypes,
      onTypeChange,
      completed_at,
      type: typeValue,
      case_manager,
      created_at,
      updated_at,
      caseManagerId,
      handleEditSelectIndex,
      index,
      selectedEditIndex,
    } = this.props;

    const { editedItemText } = this.state;

    const editOpened = index === selectedEditIndex;

    const completedValue = completed_at ? "complete" : "incomplete";

    const completedAtFormatted = completed_at
      ? moment(completed_at).format("MM/DD/YYY h:mm a")
      : null;

    const createdAtFormatted = created_at
      ? moment(created_at).format("MM/DD/YYYY h:mm a")
      : null;

    const updatedAtFormatted = updated_at
      ? moment(updated_at).format("MM/DD/YYYY h:mm a")
      : null;

    const dateToShow = updatedAtFormatted
      ? updatedAtFormatted
      : createdAtFormatted;

    const isEditable =
      itemType !== "Note" ||
      (case_manager && case_manager.id === caseManagerId);

    const isComplete = completed_at ? true : false;

    const listClass = isComplete
      ? "goals-tasks-list-item goals-tasks-completed"
      : "goals-tasks-list-item";

    const datesToDisplay = isComplete
      ? [
          { date: createdAtFormatted, label: "Created:" },
          { date: completedAtFormatted, label: "Marked complete:" },
        ]
      : [{ date: createdAtFormatted, label: "Created:" }];

    return (
      <>
        <Level className={listClass}>
          {!editOpened && (
            <Level.Side align="left">
              {itemType !== "Note" && isComplete && <Check />}
              <Level.Item className="goals-tasks-label-select">
                {label}
              </Level.Item>
            </Level.Side>
          )}

          {editOpened && (
            <Level.Side align="left">
              <Level.Item className="goals-tasks-label-select">
                {`Edit ${itemType}`}
              </Level.Item>
            </Level.Side>
          )}

          <Level.Side align="right">
            {itemType === "Note" &&
              case_manager &&
              case_manager.name &&
              !editOpened && (
                <Level.Item>
                  <div className="note-details">
                    <p>{`${case_manager.name.first} ${case_manager.name.last}`}</p>
                    <p>{dateToShow}</p>
                  </div>
                </Level.Item>
              )}
            {itemType !== "Note" && onChangeCompletionStatus && (
              <Level.Item>
                <Button
                  onClick={() => onChangeCompletionStatus(id, !isComplete)}
                  size="small"
                >
                  {isComplete ? "Mark as Not Done" : "Mark as Done"}
                </Button>
              </Level.Item>
            )}
            {onChangeCompletionStatus && itemType === "Task" && (
              <ListItemTaskContents
                id={id}
                onChangeCompletionStatus={onChangeCompletionStatus}
                onTypeChange={onTypeChange}
                completedValue={completedValue}
                typeValue={typeValue}
                taskTypes={taskTypes}
                color={this._getSelectColor(completedValue)}
              />
            )}
            {!editOpened && (
              <Level.Item className={"goals-tasks-remove"}>
                <div>
                  {isEditable && itemType === "Note" && (
                    <Button
                      className="editButton is-small"
                      type={"link"}
                      onClick={() => handleEditSelectIndex(index)}
                    >
                      Edit
                    </Button>
                  )}
                </div>
              </Level.Item>
            )}

            {!editOpened && (
              <Level.Item className={"goals-tasks-remove"}>
                <div>
                  {isEditable && (
                    <ScarePrompt
                      warningText={`Are you sure you want to delete this ${itemType}? This cannot be undone.`}
                      itemName={itemType}
                      onYes={() => handleRemoveItem(id)}
                      button={<Button type={"danger"}>Delete</Button>}
                    />
                  )}
                </div>
              </Level.Item>
            )}

            {editOpened && (
              <div
                style={
                  editOpened
                    ? {
                        display: "flex",
                        width: "150px",
                        justifyContent: "space-between",
                      }
                    : undefined
                }
              >
                <Button onClick={this._handleCancelEditClicked}>Cancel</Button>
                <Button
                  disabled={!editedItemText}
                  type="primary"
                  onClick={this._handleSaveEditClicked}
                >
                  Save
                </Button>
              </div>
            )}
          </Level.Side>
        </Level>

        {editOpened && (
          <>
            <EditItemBox
              handleItemChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                this._handleEditedItemTextChanged(event)
              }
              newItem={editedItemText || label}
            />
          </>
        )}

        {itemType !== "Note" && (
          <div style={{ marginBottom: "10px" }}>
            {datesToDisplay.map(({ date, label }) => (
              <Level.Side align="left">
                <Level.Item>
                  <p className="display-dates-label"> {label}</p>
                </Level.Item>
                <Level.Item>
                  <p className="display-dates">{date}</p>
                </Level.Item>
              </Level.Side>
            ))}
          </div>
        )}
      </>
    );
  }
}
