import * as React from "react";
import { Link } from "@reach/router";
import { Tag } from "react-bulma-components";
import {
  LinkedTabs,
  LinkedTab,
} from "../../shared/components/elements/LinkedTabs";
import {
  ParticipantDetailsTabs,
  ParticipantDetailsTabComponentProps,
  ParticipantDetailsTabDefinition,
} from "../screens/ParticipantDetailsTabs";
import { ParticipantDetailsType } from "../screens/ParticipantDetailsQueryTypes";
import { ParticipantAccountInfo } from "./ParticipantAccountInfo";
import AddParticipantQueryWrapper from "../modals/participants/AddParticipantQueryWrapper";
import { Operation } from "../modals/types";
import { formatParticipantName } from "../../utils/participant";
import { formatPhoneNumberForDisplay } from "../../shared/lib/util";
import "./ParticipantAccount.scss";
import { KeyedString } from "../../shared/lib/graphql";
import ChangeParticipantActiveQueryWrapper from "../modals/participant-is-active/ChangeParticipantActiveStatusQueryWrapper";
import { PageHeader } from "../../shared/components/elements/PageHeader";
import { Button } from "antd";

type Props = {
  participant: ParticipantDetailsType;
  selectedTab?: string;
  riskLevels?: Array<KeyedString>;
  complianceOptions?: Array<KeyedString>;
  twilioNumber?: string;
};

interface LinkedTabWithComponent extends LinkedTab {
  Component: React.ComponentType<ParticipantDetailsTabComponentProps>;
}

/**
 * For a given ParticipantDetailsTabDefinition, map it to work for LinkedTabs to display.
 * link is its key
 *
 */
const mapParticipantTabToLinkedTab: (
  tab: ParticipantDetailsTabDefinition & { selected: boolean }
) => LinkedTabWithComponent = ({ key, Component, selected, title: value }) =>
  ({
    key,
    Component,
    linkTo: `../${key}`,
    selected,
    value,
  } as LinkedTabWithComponent);

export class ParticipantAccount extends React.PureComponent<Props> {
  render() {
    const {
      participant,
      selectedTab,
      riskLevels,
      twilioNumber,
      complianceOptions,
    } = this.props;

    const tabsToShow = ParticipantDetailsTabs.map((tab) => ({
      ...tab,
      selected: tab.key === selectedTab,
    }))
      // only show tabs that have components
      .filter(({ Component }) => !!Component)
      .map(mapParticipantTabToLinkedTab);

    const displayName = formatParticipantName(participant);

    const displayTwilio = formatPhoneNumberForDisplay(twilioNumber);

    return (
      <div className="participant">
        <PageHeader
          pageName={displayName}
          turnOffBottomPadding={true}
          headerRight={
            <>
              <ChangeParticipantActiveQueryWrapper
                operation={
                  participant.is_active
                    ? Operation.Deactivate
                    : Operation.Reactivate
                }
                type={""}
                participant={participant}
                buttonComponent={
                  <Button type="link">
                    {participant.is_active
                      ? "Deactivate account"
                      : "Record account change"}
                  </Button>
                }
              />
              <AddParticipantQueryWrapper
                operation={Operation.Edit}
                type={"Account"}
                participant={participant}
              />
            </>
          }
          titleTag={
            participant.is_active ? (
              <Tag style={{ marginTop: 6 }} color="success">
                Active
              </Tag>
            ) : participant.is_active === false ? (
              <Tag style={{ marginTop: 6 }} color="light">
                Inactive
              </Tag>
            ) : null
          }
        >
          <>
            <div className="accountInfoContainer">
              <ParticipantAccountInfo
                participant={participant}
                riskLevels={riskLevels}
                twilioNumber={displayTwilio}
                complianceOptions={complianceOptions}
              />
            </div>
            <div className="tabsContainer">
              <LinkedTabs
                LinkComponent={Link}
                tabs={tabsToShow}
                tabProps={{ type: "boxed", className: "risk-tabs" }}
              />
            </div>
          </>
        </PageHeader>
        <div className="activeContentContainer constrained-section">
          {
            // Display the component of the active tab
            tabsToShow
              .filter(({ selected }) => selected)
              .map(({ Component, key }) => (
                <Component key={key} id={participant.id} />
              ))
          }
        </div>
      </div>
    );
  }
}
