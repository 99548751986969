import React, { useState } from "react";
import { Steps, Button } from "antd";
import { StepOne } from "./StepOne";
import { StepTwo } from "./StepTwo";
import { Participant, Activity, ServiceProvider } from "../../graphql-types";
import { Moment } from "moment";
import "./Classes.scss";
import { addGroupClass } from "../../actions/groupClasses";

const { Step } = Steps;

interface Props {
  participants: Participant[];
  activities: Activity[];
  serviceProviders: ServiceProvider[];
  onCompleteCallback: () => void;
  initialFields: any;
  groupClassId?: string;
  initialExcludeDates?: Moment[];
}

export interface AntdFieldState {
  dirty?: boolean;
  errors?: { message: string; field: string }[] | undefined;
  name?: string;
  touched?: boolean;
  validating?: boolean;
  value: string | null | string[] | Moment;
}

function hasErrors(fields: {
  [key: string]: AntdFieldState | AntdFieldState[];
}) {
  const hasError = Object.keys(fields)
    .filter((key) => {
      return !key.includes("keys");
    })
    .some((key: string) => {
      const field = fields[key];
      if (Array.isArray(field)) {
        return false;
      }
      if (field.touched !== true) {
        return true;
      }

      if (field.errors !== undefined) {
        return true;
      }

      if (Array.isArray(field.value) && field.value.length === 0) {
        return true;
      }

      return false;
    });

  return hasError;
}

export function CreateClass(props: Props) {
  const {
    activities,
    participants,
    serviceProviders,
    initialFields,
    initialExcludeDates,
  } = props;

  const activitiesDictionary = dataArrayToObjectWithSpecifiedFieldToUseAsKey(
    activities,
    "id"
  );

  const participantsDictionary = dataArrayToObjectWithSpecifiedFieldToUseAsKey(
    participants,
    "id"
  );

  const serviceProvidersDictionary = dataArrayToObjectWithSpecifiedFieldToUseAsKey(
    serviceProviders,
    "id"
  );

  const [current, setCurrent] = useState(0);
  const [fields, setFields] = useState(initialFields);

  const [excludeDates, setExclueDates] = useState(
    initialExcludeDates || ([] as Moment[])
  );

  const removeExcludeDate = (excludeDateToRemoveIndex: number) => {
    const newExcludeDates = excludeDates.filter((_excludeDate, index) => {
      return index !== excludeDateToRemoveIndex;
    });

    setExclueDates(newExcludeDates);
  };

  const changeExcludeDate = (
    newExcludeDate: Moment,
    endDateIndexToChange: number
  ) => {
    const newExcludeDates = excludeDates.map((originalExcludeDate, index) => {
      if (endDateIndexToChange === index) {
        return newExcludeDate;
      }
      return originalExcludeDate;
    });

    setExclueDates(newExcludeDates);
  };

  const addExcludeDate = (excludeDate: Moment) => {
    const newExcludeDates = [...excludeDates, excludeDate];
    setExclueDates(newExcludeDates);
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleConfirmAddClassClicked = async () => {
    const { onCompleteCallback, groupClassId } = props;
    await addGroupClass({
      end_date: fields.endDate.value,
      start_date: fields.startDate.value,
      start_time: fields.startTime.value,
      participant_ids: fields.selectedParticipants.value,
      days_of_week: fields.daysOfWeek.value,
      activity_id: fields.activity.value,
      service_provider_id: fields.location.value,
      exclude_dates: excludeDates.map((excludeDate) =>
        excludeDate.toISOString()
      ),
      group_class_id: groupClassId || null,
    });

    onCompleteCallback();
    setCurrent(0);
    setFields(initialFields);
  };

  const setFieldsCalled = (
    setFieldsCallback: Function,
    fields: any,
    oldFields: any
  ) => {
    // weirdly necessary as setFields will get
    // called with an empty object literal when
    // a form is re-navigated to
    if (Object.keys(fields).length === 0) {
      return;
    }

    setFieldsCallback({
      ...oldFields,
      ...fields,
    });
  };

  const steps = [
    {
      title: "Enter Class Details",
      content: (
        <StepOne
          onChange={(newFields: object) =>
            setFieldsCalled(setFields, newFields, fields)
          }
          serviceProviders={serviceProviders}
          activities={activities}
          participants={participants}
          {...fields}
          addExcludeDate={addExcludeDate}
          removeExcludeDate={removeExcludeDate}
          changeExcludeDate={changeExcludeDate}
          excludeDates={excludeDates}
        />
      ),
    },
    {
      title: "Confirm",
      content: (
        <StepTwo
          {...fields}
          excludeDates={excludeDates}
          serviceProvidersDictionary={serviceProvidersDictionary}
          activitiesDictionary={activitiesDictionary}
          participantsDictionary={participantsDictionary}
        />
      ),
    },
  ];

  return (
    <div className="create-class-container">
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
      <div className="steps-action">
        {current < steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => next()}
            disabled={hasErrors(fields)}
          >
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={handleConfirmAddClassClicked}>
            Create Class
          </Button>
        )}
        {current > 0 && (
          <Button style={{ marginLeft: 8 }} onClick={() => prev()}>
            Edit
          </Button>
        )}
      </div>
    </div>
  );
}

export function dataArrayToObjectWithSpecifiedFieldToUseAsKey<T>(
  dataArray: Array<T>,
  key: keyof T
): { [key: string]: T } {
  return dataArray.reduce((acc: any, dataItem: T) => {
    acc[dataItem[key]] = dataItem;
    return acc;
  }, {});
}
