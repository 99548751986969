import * as React from "react";
import { RouteComponentProps } from "@reach/router";

import { BroadcastMessagesContainer } from "../broadcast/BroadcastMessagesContainer";

export class BroadcastMessagesScreen extends React.PureComponent<
  RouteComponentProps
> {
  render() {
    return <BroadcastMessagesContainer />;
  }
}
