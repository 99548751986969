import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "../shared/lib/graphql/commitMutation";
import { RecordSourceSelectorProxy, RecordProxy } from "relay-runtime";
import moment from "moment";
import { checkMutationReturn, getParticipant } from "./util";
const uuid = require("uuid");

interface AddTasksGqlArguments {
  participant_id: string;
  label: string;
}

interface RemoveTaskGqlArguments {
  participant_id: string;
  task_id: string;
}

interface CompleteTaskGqlArguments extends RemoveTaskGqlArguments {
  is_completed?: boolean;
  type?: string;
}

export const addTask = async (variables: AddTasksGqlArguments) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation tasksAddTaskMutation(
          $participant_id: String!
          $label: String!
        ) {
          addParticipantTask(participant_id: $participant_id, label: $label) {
            task {
              id
              label
              completed_at
              created_at
            }
            result
            description
            errors
          }
        }
      `,
      variables,

      updater: (store) => {
        updateRelayStoreAddTask(
          store,
          "addParticipantTask",
          variables.participant_id
        );
      },
      optimisticUpdater: (store) => {
        optimisticUpdateRelayStoreAddTask(
          store,
          "addParticipantTask",
          variables
        );
      },
    },
    "Error while adding a task"
  );
};

export const removeTask = async (variables: RemoveTaskGqlArguments) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation tasksRemoveParticipantTaskMutation(
          $participant_id: String!
          $task_id: String!
        ) {
          removeParticipantTask(
            participant_id: $participant_id
            task_id: $task_id
          ) {
            task {
              id
              label
              completed_at
              created_at
            }
            result
            description
            errors
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreRemoveTask(
          store,
          "removeParticipantTask",
          variables.participant_id
        );
      },
      optimisticUpdater: (store) => {
        optimisticRelayStoreRemoveTask(
          store,
          "removeParticipantTask",
          variables
        );
      },
    },
    "Error while removing a task"
  );
};

const updateRelayStoreAddTask = (
  store: RecordSourceSelectorProxy,
  rootFieldName: string,
  participant_id: string
) => {
  const payload = checkMutationReturn(store, rootFieldName);

  if (!payload) return;

  const newTask = payload.getLinkedRecord("task");

  const participant = getParticipant(store, rootFieldName, participant_id);

  if (!participant) return;

  const tasksList = participant.getLinkedRecords("tasks");

  //add new obligation to front of obligationsList
  const combinedTasks = [newTask].concat(tasksList);

  //set the new Obligation
  participant.setLinkedRecords(combinedTasks, "tasks");
};

const optimisticUpdateRelayStoreAddTask = (
  store: RecordSourceSelectorProxy,
  rootFieldName: string,
  variables: AddTasksGqlArguments
) => {
  const participant = getParticipant(
    store,
    rootFieldName,
    variables.participant_id
  );

  if (!participant) return;

  const tasksList = participant.getLinkedRecords("tasks");

  const newTask = store.create(uuid(), rootFieldName);

  newTask.setValue(variables.label, "label");

  //add new obligation to front of obligationsList
  const combinedTasks = [newTask as RecordProxy | null].concat(tasksList);
  //set the new Obligation
  participant.setLinkedRecords(combinedTasks, "tasks");
};

const updateRelayStoreRemoveTask = (
  store: RecordSourceSelectorProxy,
  rootFieldName: string,
  participant_id: string
) => {
  const payload = checkMutationReturn(store, rootFieldName);

  if (!payload) return;

  const deletedTask = payload.getLinkedRecord("task");

  if (!deletedTask) {
    console.error(`Error returned from API during ${rootFieldName}. \n`);
    return;
  }

  const deletedTaskId = deletedTask.getValue("id");

  const participant = getParticipant(store, rootFieldName, participant_id);

  if (!participant) return;

  const tasksList = participant.getLinkedRecords("tasks");

  if (!tasksList) {
    console.error(
      `Error while updating the store after ${rootFieldName}.\n Can't task ${deletedTaskId} doesn't exist on participant ${participant_id}`
    );
    return;
  }

  const newTaskList = tasksList.filter(
    (task) => task && task.getValue("id") !== deletedTaskId
  );

  participant.setLinkedRecords(newTaskList, "tasks");
};

const optimisticRelayStoreRemoveTask = (
  store: RecordSourceSelectorProxy,
  rootFieldName: string,
  variables: RemoveTaskGqlArguments
) => {
  const participant = getParticipant(
    store,
    rootFieldName,
    variables.participant_id
  );

  if (!participant) return;

  const tasksList = participant.getLinkedRecords("tasks");

  if (!tasksList) {
    console.error(
      `Error while updating the store after ${rootFieldName}.\n Can't task ${variables.task_id} doesn't exist on participant ${variables.participant_id}`
    );
    return;
  }

  const newTaskList = tasksList.filter(
    (task) => task && task.getValue("id") !== variables.task_id
  );

  participant.setLinkedRecords(newTaskList, "tasks");
};

export const updateTask = async (variables: CompleteTaskGqlArguments) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation tasksUpdateTaskMutation(
          $participant_id: String!
          $task_id: String!
          $is_completed: Boolean
          $type: String
        ) {
          updateParticipantTask(
            participant_id: $participant_id
            task_id: $task_id
            is_completed: $is_completed
            type: $type
          ) {
            task {
              id
              label
              completed_at
              type
            }
            result
            description
            errors
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreCompleteTask(
          store,
          "updateParticipantTask",
          variables.participant_id
        );
      },
      optimisticUpdater: (store) => {
        optimisticUpdateRelayStoreCompleteTask(
          store,
          "updateParticipantTask",
          variables
        );
      },
    },
    "Error while completing a task"
  );
};

const updateRelayStoreCompleteTask = (
  store: RecordSourceSelectorProxy,
  rootFieldName: string,
  participant_id: string
) => {
  const payload = checkMutationReturn(store, rootFieldName);

  if (!payload) return;

  const completedTask = payload.getLinkedRecord("task");

  if (!completedTask) {
    console.error(`Error returned from API during ${rootFieldName}. \n`);
    return;
  }

  const completedTaskId = completedTask.getValue("id");

  const participant = store.get(participant_id);

  if (!participant) {
    console.error(
      `Error while updating the store after ${rootFieldName}.\n` +
        " Can't find the participant in the store."
    );
    return;
  }

  const tasksList = participant.getLinkedRecords("tasks");

  if (!tasksList) {
    console.error(
      `Error while updating the store after ${rootFieldName}.\n Can't task ${completedTaskId} doesn't exist on participant ${participant_id}`
    );
    return;
  }

  const newTaskList = tasksList.map((task) => {
    if (task && task.getValue("id") === completedTaskId) {
      task.setValue("completed_at", moment().toISOString());
    }

    return task;
  });

  participant.setLinkedRecords(newTaskList, "tasks");
};

const optimisticUpdateRelayStoreCompleteTask = (
  store: RecordSourceSelectorProxy,
  rootFieldName: string,
  variables: CompleteTaskGqlArguments
) => {
  const participant = store.get(variables.participant_id);

  if (!participant) {
    console.error(
      `Error while updating the store after ${rootFieldName}.\n` +
        " Can't find the participant in the store."
    );
    return;
  }

  const tasksList = participant.getLinkedRecords("tasks");

  if (!tasksList) {
    console.error(
      `Error while updating the store after ${rootFieldName}.\n Can't task ${variables.task_id} doesn't exist on participant ${variables.participant_id}`
    );
    return;
  }

  const newTaskList = tasksList.map((task) => {
    if (task && task.getValue("id") === variables.task_id) {
      const valueToUpdate = variables.is_completed
        ? moment().toISOString()
        : "";

      if (variables.is_completed !== undefined) {
        task.setValue("completed_at", valueToUpdate);
      }

      if (variables.type !== undefined) {
        task.setValue("type", variables.type);
      }
    }

    return task;
  });

  participant.setLinkedRecords(newTaskList, "tasks");
};
