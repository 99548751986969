import React, { Component } from "react";
import "../ModalForm.scss";
import { Button } from "antd";
import { FormField, ValidationItemType } from "../types";
import { ModalInputTypes } from "../../../shared/lib/graphql/flowTypes";
import ModalInput from "../ModalInput";
import { ObligationAndParticipantDetailsType } from "../events/AddEventModalWrapper";

type AssessmentResultFormData = {
  participant: FormField;
  caseNumber: FormField;
  assessmentType: FormField;
  createdBy: FormField;
};

type EventFormValidations = {
  participant: ValidationItemType;
  activity: ValidationItemType;
  serviceProvider: ValidationItemType;
  roomNumber: ValidationItemType;
  startDate: ValidationItemType;
  endDate: ValidationItemType;
};

interface Props {
  handleModalFormInputChange: Function;
  addEvent: Function;
  handleDatePickerChange: Function;
  handleDatePickerRawChange: Function;
  attemptedSubmission: boolean;
  handleFormSubmit: Function;
  modalFormData: AssessmentResultFormData;
  validations: EventFormValidations;
  updateObject: ObligationAndParticipantDetailsType;
}

class AddEventForm extends Component<Props> {
  render() {
    const {
      handleModalFormInputChange,
      modalFormData,
      validations,
      attemptedSubmission,
      handleFormSubmit,
    } = this.props;

    const {
      participant,
      caseNumber,
      assessmentType,
      createdBy,
    } = modalFormData;
    return (
      <div className="modal-form add-event-form">
        <ModalInput
          label={"Participant"}
          value={participant.value}
          disabled={true}
          name={"participant"}
          placeholder={"participant"}
          inputType={ModalInputTypes.Name}
          attemptedSubmission={attemptedSubmission}
          validations={validations}
          handleModalFormInputChange={handleModalFormInputChange}
        />

        <ModalInput
          label={"Assessment Type"}
          value={assessmentType.value}
          name={"assessmentType"}
          disabled={true}
          placeholder={"Assessment type"}
          inputType={ModalInputTypes.Textarea}
          attemptedSubmission={attemptedSubmission}
          handleModalFormInputChange={handleModalFormInputChange}
          validations={validations}
        />

        <ModalInput
          label={"Created by"}
          value={createdBy.value}
          disabled={true}
          name={"createdBy"}
          placeholder={"Created by"}
          inputType={ModalInputTypes.Textarea}
          attemptedSubmission={attemptedSubmission}
          handleModalFormInputChange={handleModalFormInputChange}
          validations={validations}
        />

        <ModalInput
          label={"Case Number"}
          value={caseNumber.value}
          name={"caseNumber"}
          placeholder={"Case Number"}
          inputType={ModalInputTypes.Textarea}
          attemptedSubmission={attemptedSubmission}
          handleModalFormInputChange={handleModalFormInputChange}
          validations={validations}
        />

        <Button
          type="primary"
          style={{ width: "100%" }}
          onClick={(event: any) => handleFormSubmit(event)}
        >
          Next
        </Button>
      </div>
    );
  }
}

export default AddEventForm;
