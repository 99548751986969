import graphql from "babel-plugin-relay/macro";

export const participantAssessmentsQuery = graphql`
  query ParticipantAssessmentGraphqlQuery($id: String!) {
    me {
      case_manager {
        id
        name {
          first
          last
        }
      }
    }
    participants(id: $id) {
      participant {
        id
        name {
          first
          last
        }
        assessment_results {
          id
          status
          case_number
          assessment_name
          participant_id
          assessment_id
          rating
          total_score
          scored_by
          created_at
          updated_at
          rating
          scores {
            id
            assessment_template_question_id
            score
            value
            verified
            question_text
            question_number
            question_options {
              value
              text
            }
          }
          ranges {
            rating
            min_score
            max_score
            failure_rate
            failure_to_appear_rate
            new_arrest_rate
          }
        }
      }
    }
  }
`;
