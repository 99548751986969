import React, { PureComponent } from "react";
import { Check } from "@material-ui/icons";

import { CaseManager } from "../../shared/lib/graphql/index";

import { getFeatureFlags } from "../../featureFlags";
import "./ReviewCheck.scss";

const {
  workflow: { reviewIsLegacy },
} = getFeatureFlags();

interface Props {
  caseManager: CaseManager | null;
  id: string;
  disabled: boolean;
  onChange: (
    id: string,
    evt: React.SyntheticEvent<HTMLInputElement>
  ) => Promise<boolean>;
}

function getNameString(caseManager: CaseManager): string {
  return `${caseManager.name.first} ${caseManager.name.last || ""}`.trim();
}

function getInitialsString(caseManager: CaseManager): string {
  const first = caseManager.name.first || "";
  const last = caseManager.name.last || "";
  return `${first.substr(0, 1)}${last.substr(0, 1)}`.trim();
}

/**
 * The "old / previous behavior where a checkbox is check-able and does something"
 */
class ReviewCheckLegacy extends PureComponent<Props> {
  render() {
    return (
      <span className="review-check-legacy">
        <input
          type="checkbox"
          name={`reviewed-${this.props.id}`}
          className="review-check-input"
          onChange={async (evt: React.SyntheticEvent<HTMLInputElement>) => {
            if (!this.props.onChange) return;
            else await this.props.onChange(this.props.id, evt);
          }}
          /*
            NOTE: Don't simply change this to @defaultChecked
              It won't work in scenarios where we want to re-render
              and display checked/unchecked based solely on props
              where the user hasn't interacted with this input.
            If you're here to make it update quickly,
              implement a relay optimistic update.
          */
          checked={!!this.props.caseManager}
        />
        {this.props.caseManager ? (
          <span
            title={getNameString(this.props.caseManager)}
            className="is-size-7"
          >
            {getInitialsString(this.props.caseManager)}
          </span>
        ) : null}
      </span>
    );
  }
}
export class ReviewCheck extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    const { onChange } = this.props;
    if (!!onChange && !reviewIsLegacy) {
      console.debug(
        "ReviewCheck: `onChange` property provided but not in `reviewIsLegacy` mode (flag). `onChange` does nothing if not operating in `legacy` mode."
      );
    }
  }

  render() {
    if (reviewIsLegacy) {
      return <ReviewCheckLegacy {...this.props} />;
    }

    return !!this.props.caseManager ? (
      <span className="review-check">
        <Check />
        <span
          title={getNameString(this.props.caseManager)}
          className="initials"
        >
          {getInitialsString(this.props.caseManager)}
        </span>
      </span>
    ) : null;
  }
}
