import { from, Observable, Subject, ConnectableObservable } from "rxjs";
import { map, distinctUntilChanged, tap, multicast } from "rxjs/operators";

export enum TRACKED_GRAPHQL_CALL_TYPES {
  Mutation = "mutation",
  Query = "query",
}

export enum GRAPHQL_FLIGHT_STATUS {
  NULL = "NULL",
  Inflight = "Inflight",
  // these match to the backend MutationResult
  Failure = "failure",
  Success = "success",
  Skipped = "skipped",
}

enum INFLIGHT_RESULT {
  NEW = "NEW",
  SUCCESS = "SUCESS",
  ERROR = "ERROR",
}

const CLEAR_ERROR: ErrorItem = {
  identifier: "CLEAR_ERROR",
  errors: ["CLEAR_ERROR"],
};

export interface ErrorItem {
  readonly identifier: string;
  readonly errors: string[] | null;
}

export interface GraphqlFlightTrackerType {
  readonly key: TRACKED_GRAPHQL_CALL_TYPES;
  readonly dummy: boolean;
  readonly $errors: Observable<ErrorItem>;
  readonly $defaultMessage: Observable<string>;
  readonly $inFlightStatus: Observable<GRAPHQL_FLIGHT_STATUS>;

  setDefaultMessage: (message: string) => void;
  start: () => void;
  stop: (identifier: string, errors?: string[]) => void;
}

export class GraphqlFlightTracker implements GraphqlFlightTrackerType {
  private count: number;
  readonly dummy: boolean;
  private errorsStream: Subject<ErrorItem>;
  readonly $errors: Observable<ErrorItem>;
  private isInFlightStream: Subject<INFLIGHT_RESULT>;
  private inFlightStatuses: INFLIGHT_RESULT[];
  private $isInFlight: Observable<boolean>;
  readonly $inFlightStatus: Observable<GRAPHQL_FLIGHT_STATUS>;
  private defaultMessageStream: Subject<string>;
  readonly $defaultMessage: Observable<string>;

  constructor(readonly key: TRACKED_GRAPHQL_CALL_TYPES) {
    this.count = 0;
    this.dummy = false;
    this.errorsStream = new Subject();
    this.isInFlightStream = new Subject();
    this.defaultMessageStream = new Subject();
    this.inFlightStatuses = [];

    this.$errors = this.errorsStream.pipe(
      map((x) =>
        x === CLEAR_ERROR ? ((null as any) as ErrorItem) : (x as ErrorItem)
      ),
      distinctUntilChanged(
        (a: ErrorItem, b: ErrorItem) => JSON.stringify(a) === JSON.stringify(b)
      )
      // TODO: Fix that this is firing twice per flight .stop
      //  Note: It was prior to this ErrorItem change as well
    );

    // prime with our clear
    this.errorsStream.next(CLEAR_ERROR);

    this.$isInFlight = this.isInFlightStream.pipe(
      // when a new value is `next`ed
      // add it to our count
      map((inflightResult) => {
        this.count += inflightResult === INFLIGHT_RESULT.NEW ? 1 : -1;
        if (this.count < 0) {
          this.count = 0;
        }
        return this.count;
      }),
      // are there any in flight (value > 0)?
      map((x) => x > 0),
      // only emit when this value changes (e.g. going from 2 to 3 will still be `true` and won't emit a new value)
      distinctUntilChanged()
    );

    // track statuses of those in flight together
    //  cleared in pipe of $inFlightStatus
    this.isInFlightStream.subscribe((inFlightStatus) => {
      this.inFlightStatuses.push(inFlightStatus);
    });

    // summarize the status, if any errored, the result is error
    this.$inFlightStatus = this.$isInFlight.pipe(
      tap((isInFlight) => {
        if (isInFlight) {
          this.inFlightStatuses = [];
        }
      }),
      map((isInFlight) => {
        if (isInFlight) return GRAPHQL_FLIGHT_STATUS.Inflight;
        return this.inFlightStatuses.reduce(
          (outputStatus, inFlightStatus) =>
            outputStatus === GRAPHQL_FLIGHT_STATUS.Failure ||
            inFlightStatus === INFLIGHT_RESULT.ERROR
              ? GRAPHQL_FLIGHT_STATUS.Failure
              : GRAPHQL_FLIGHT_STATUS.Success,
          GRAPHQL_FLIGHT_STATUS.Success
        );
      }),
      distinctUntilChanged(),
      multicast(() => new Subject<GRAPHQL_FLIGHT_STATUS>())
    );

    this.$defaultMessage = this.defaultMessageStream.pipe(
      distinctUntilChanged()
    );

    (this.$inFlightStatus as ConnectableObservable<
      GRAPHQL_FLIGHT_STATUS
    >).connect();
  }

  public setDefaultMessage = (message: string) => {
    this.defaultMessageStream.next(message);
  };

  public start = () => {
    this.errorsStream.next(CLEAR_ERROR);
    this.isInFlightStream.next(INFLIGHT_RESULT.NEW);
  };

  public stop = (identifier: string, errors?: string[]) => {
    if (errors) {
      this.errorsStream.next({ identifier, errors });
      this.isInFlightStream.next(INFLIGHT_RESULT.ERROR);
    } else {
      this.isInFlightStream.next(INFLIGHT_RESULT.SUCCESS);
    }
  };
}

class DefaultFlightTrackers {
  private trackers: { [key: string]: GraphqlFlightTrackerType } = {
    mutation: new GraphqlFlightTracker(TRACKED_GRAPHQL_CALL_TYPES.Mutation),
    query: new GraphqlFlightTracker(TRACKED_GRAPHQL_CALL_TYPES.Query),
  };
  public get = (key: TRACKED_GRAPHQL_CALL_TYPES): GraphqlFlightTrackerType => {
    const tracker = this.trackers[key];
    if (tracker) return tracker;
    else {
      console.error(`Unexpected operation type ${key}.`);
      return {
        key: "dummy" as any,
        dummy: true,
        $defaultMessage: from([""]),
        $errors: from([]),
        $inFlightStatus: from([GRAPHQL_FLIGHT_STATUS.NULL]),
        setDefaultMessage: () => {},
        start: () => {},
        stop: () => {},
      };
    }
  };
}

export const FlightTrackers = new DefaultFlightTrackers();
