import React from "react";
import { AssessmentTemplateRange, Maybe } from "../../graphql-types";
import { Table } from "react-bulma-components";

interface RangeContainerProps {
  ranges: AssessmentTemplateRange[];
}

export function RangeContainer(props: RangeContainerProps) {
  const { ranges } = props;
  return (
    <div className="assessment-info-container-first">
      <Table striped={false} bordered={false}>
        <thead>
          <tr>
            <th>Scores</th>
            <th>Rating</th>
            <th>% of Failures</th>
            <th>% of Failure to Appear</th>
            <th>% of New Arrest</th>
          </tr>
        </thead>
        <tbody>
          {ranges.map((range) => (
            <tr key={range.rating}>
              <td>
                {range.max_score
                  ? `${range.min_score}-${range.max_score}`
                  : `${range.min_score}+`}
              </td>
              <td>{range.rating}</td>
              <td>{convertFloatToPercent(range.failure_rate)}</td>
              <td>{convertFloatToPercent(range.failure_to_appear_rate)}</td>
              <td>{convertFloatToPercent(range.new_arrest_rate)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

function convertFloatToPercent(float: Maybe<number>) {
  if (!float) {
    return "0%";
  }
  return `${Math.round(float * 100)}%`;
}
