import React, { Component } from "react";
import { Button, Modal } from "antd";
import "./ModalContainer.scss";
const titleCase = require("title-case");
import { Operation } from "./types";

interface Props {
  children: any;
  operation: Operation;
  type: string;
  handleModalOpen: Function;
  handleModalClose: Function;
  showModal: boolean;
  showButton?: boolean;
  disableButton?: boolean;
  buttonComponent?: React.ReactElement<any>;
  secondPage: boolean;
}

export class ModalContainer extends Component<Props> {
  render() {
    const {
      operation,
      type,
      handleModalOpen,
      handleModalClose,
      showModal,
      showButton,
      disableButton,
      buttonComponent,
      secondPage,
    } = this.props;

    const title = `${titleCase(operation)} ${type.toLowerCase()}`;

    const modalTitle = !secondPage ? title : `Confirm ${title.toLowerCase()}`;

    return (
      <div className={`mcontainer-${title.replace(" ", "-")}`}>
        {buttonComponent
          ? React.cloneElement(buttonComponent, { onClick: handleModalOpen })
          : showButton ||
            (typeof showButton === "undefined" && (
              <Button
                onClick={(event) => handleModalOpen(event)}
                type={"primary"}
                disabled={disableButton}
              >
                {title}
              </Button>
            ))}
        <Modal
          visible={showModal}
          onCancel={(event: any) => handleModalClose(event)}
          title={modalTitle}
          footer={[]}
        >
          <div>{this.props.children}</div>
        </Modal>
      </div>
    );
  }
}

export default ModalContainer;
