/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type AppHeaderGqlQueryVariables = {};
export type AppHeaderGqlQueryResponse = {
    readonly me: ({
        readonly email: string;
        readonly case_manager: ({
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
        }) | null;
    }) | null;
    readonly application: ({
        readonly flags: ({
            readonly all: any | null;
        }) | null;
    }) | null;
};
export type AppHeaderGqlQuery = {
    readonly response: AppHeaderGqlQueryResponse;
    readonly variables: AppHeaderGqlQueryVariables;
};



/*
query AppHeaderGqlQuery {
  me {
    email
    case_manager {
      id
      name {
        first
        last
      }
    }
  }
  application {
    flags {
      all(flag_ids: "group_chat_enabled")
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v1 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "case_manager",
                "storageKey": null,
                "args": null,
                "concreteType": "CaseManager",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "name",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PersonName",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "first",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "last",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    }
                ]
            }
        ]
    } as any), v2 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "flags",
        "storageKey": null,
        "args": null,
        "concreteType": "Flags",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "all",
                "args": [
                    {
                        "kind": "Literal",
                        "name": "flag_ids",
                        "value": "group_chat_enabled"
                    }
                ],
                "storageKey": "all(flag_ids:\"group_chat_enabled\")"
            }
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "AppHeaderGqlQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": [
                (v1 /*: any*/),
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "application",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Application",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "AppHeaderGqlQuery",
            "argumentDefinitions": [],
            "selections": [
                (v1 /*: any*/),
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "application",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Application",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v0 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "AppHeaderGqlQuery",
            "id": null,
            "text": "query AppHeaderGqlQuery {\n  me {\n    email\n    case_manager {\n      id\n      name {\n        first\n        last\n      }\n    }\n  }\n  application {\n    flags {\n      all(flag_ids: \"group_chat_enabled\")\n    }\n    id\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '107e3b93de0393db4b2ff46d3b85768d';
export default node;
