import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import graphql from "babel-plugin-relay/macro";
import { QueryRenderer } from "../../shared/lib/graphql";
import { AnalyticsReportData } from "../analytics-report/analyticsTypes";
import AnalyticsReportPage from "../analytics-report/AnalyticsReportPage";
import { Loader } from "../../shared/components/elements/Loader";
import { DownloadPDF } from "../../shared/components/elements/DownloadPDF";
import "../../shared/components/layout/AppHeader.scss";

export enum DownloadMessage {
  DOWNLOADING_REPORT = "Downloading",
  DOWNLOADED = "Downloaded",
}

export interface AnalyticsReportContainerProps {}

interface State {
  selectedTime: {
    week: number | null;
    month: number | null;
    year: number | null;
  } | null;
  downloadingMessage: DownloadMessage | null;
}

export class AnalyticsReportContainer extends React.PureComponent<
  RouteComponentProps<AnalyticsReportContainerProps>,
  State
> {
  constructor(props: RouteComponentProps<AnalyticsReportContainerProps>) {
    super(props);
    this.state = {
      selectedTime: null,
      downloadingMessage: null,
    };
  }
  render() {
    const { downloadingMessage } = this.state;
    const selectedTime = this.state.selectedTime || {
      week: null,
      month: null,
      year: null,
    };

    return (
      <QueryRenderer
        query={graphql`
          query AnalyticsReportContainerQuery(
            $week: Int
            $month: Int
            $year: Int
          ) {
            stats_report(week: $week, month: $month, year: $year) {
              from_date
              to_date
              report_weeks {
                start_time
                week
                year
              }
              report_months {
                start_time
                month
                year
              }
              notification_counts {
                totals {
                  title
                  total
                  sent
                  upcoming
                  failed
                }
                breakdowns {
                  title
                  total
                  sent
                  upcoming
                  failed
                }
              }
              event_counts {
                totals {
                  title
                  total
                  with_appearance
                  attended
                  avg_attended
                  rescheduled
                  not_attended
                  no_data
                  future_event
                }
                breakdowns {
                  title
                  total
                  with_appearance
                  attended
                  avg_attended
                  rescheduled
                  not_attended
                  no_data
                  future_event
                }
              }
              event_list {
                attended
                date
                participant {
                  id
                  name
                }
                type
              }
              participant_list {
                attendance_data_count
                attendance_rate
                date
                event_count
                notification_count
                rescheduled_data_count
                participant {
                  id
                  name
                }
              }
            }
          }
        `}
        variables={{
          week: selectedTime.week,
          month: selectedTime.month,
          year: selectedTime.year,
        }}
        SuccessComponent={(props: AnalyticsReportData) => {
          return (
            <DownloadPDF>
              {({ downloadPDF }: { downloadPDF: () => Promise<void> }) => {
                return (
                  <>
                    <AnalyticsReportPage
                      data={props.stats_report}
                      week={
                        this.state.selectedTime
                          ? this.state.selectedTime.week
                          : null
                      }
                      month={
                        this.state.selectedTime
                          ? this.state.selectedTime.month
                          : null
                      }
                      year={
                        this.state.selectedTime
                          ? this.state.selectedTime.year
                          : null
                      }
                      onTimeSelected={this._handleTimeSelected}
                      onAllTimeSelected={this._handleAllTimeSelected}
                      onDownloadPdf={() =>
                        this._triggerPDFDownload(downloadPDF)
                      }
                    />
                    {downloadingMessage ===
                    DownloadMessage.DOWNLOADING_REPORT ? (
                      <div className="status-message saving">
                        <span className="statusSpace">
                          {downloadingMessage}
                          <Loader />
                        </span>
                      </div>
                    ) : null}
                    {downloadingMessage === DownloadMessage.DOWNLOADED ? (
                      <div className="status-message saving">
                        <span className="statusSpace">
                          {downloadingMessage}
                        </span>
                      </div>
                    ) : null}
                  </>
                );
              }}
            </DownloadPDF>
          );
        }}
      />
    );
  }

  _handleTimeSelected = (
    week: number | null,
    month: number | null,
    year: number
  ): void => {
    this.setState({
      selectedTime: {
        week,
        month,
        year: week == null && month === null ? null : year,
      },
    });
  };

  _handleAllTimeSelected = () => {
    this.setState({ selectedTime: null });
  };

  _hideDownloadingToaster = () => {
    this.setState({ downloadingMessage: DownloadMessage.DOWNLOADED });
    setTimeout(() => {
      this.setState({
        downloadingMessage: null,
      });
    }, 1000);
  };

  _showDownloadingToaster = () => {
    this.setState({ downloadingMessage: DownloadMessage.DOWNLOADING_REPORT });
  };

  _triggerPDFDownload = async (downloadPDF: () => Promise<void>) => {
    this._showDownloadingToaster();
    await downloadPDF();
    this._hideDownloadingToaster();
  };
}
