import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { Prospects } from "../prospects/Prospects";
import { QueryRenderer } from "../../shared/lib/graphql";
import { bookingsProspectStatusCountQuery } from "../prospects/ProspectsGraphql";
import { ProspectStatusCounts } from "../../graphql-types";

export class ProspectsScreen extends React.PureComponent<RouteComponentProps> {
  render() {
    return (
      <QueryRenderer
        query={bookingsProspectStatusCountQuery}
        SuccessComponent={(props: {
          booking_prospect_status_counts: ProspectStatusCounts;
        }) => {
          const {
            booking_prospect_status_counts: bookingProspectStatusCounts,
          } = props;

          return (
            <Prospects
              {...this.props}
              bookingProspectStatusCounts={bookingProspectStatusCounts}
            />
          );
        }}
      />
    );
  }
}
