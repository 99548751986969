import * as React from "react";
import { Heading } from "react-bulma-components";
import { Button } from "antd";

import {
  withLogin,
  WithLoginChildProps,
  WithLoginProps,
} from "./shared/components/withLogin/withLogin";
import { initRecaptcha } from "./shared/lib/auth/firebase";
import { Logo } from "./shared/components/assets/Logo";

export { logout } from "./shared/components/withLogin/withLogin";

export interface LoginControllerExtProps extends WithLoginProps {}

export interface LoginControllerProps
  extends LoginControllerExtProps,
    WithLoginChildProps {}

export class LoginControllerComp extends React.Component<LoginControllerProps> {
  private recaptchaInitialized = false;

  render() {
    const {
      loginStatus,
      children,
      emailLoginAllowed,
      smsLoginAllowed,
      loginError,
      firebaseProject,
      onLogin,
      setUser,
      validationUrl,
      ...rest
    } = this.props;

    let content;
    switch (loginStatus) {
      case "loading":
        content = <div className="loading" />;
        break;
      case "loggedout":
        content = this._renderLoginForm();
        break;
      case "failed":
        content = this._renderLoginForm(
          loginError
            ? loginError
            : "There was an error signing in, please try again. If problems persist, please contact technical support: support@joinpromise.com"
        );
        break;
      case "loggedin":
        return (
          <>
            {React.Children.map(children, (child) =>
              React.cloneElement(child as any, rest)
            )}
          </>
        );
      case "emailsent":
        content = this._renderEmailSentMessage();
        break;
      default:
        throw new Error("Unexpected location.");
    }

    return (
      <div className="LoginController">
        <div
          style={{
            margin: "100px auto",
            width: 330,
            padding: 60,
          }}
        >
          <Heading
            style={{
              color: "#3b7abe",
              fontSize: 40,
              margin: "0px 0px 30px 0px",
              fontWeight: "bold",
            }}
          >
            <Logo />
            <p
              style={{
                color: "darkgray",
                fontWeight: "normal",
                fontSize: 20,
              }}
            >
              Case Management
            </p>
          </Heading>
          {content}
        </div>
      </div>
    );
  }

  componentDidUpdate() {
    if (
      this.props.loginStatus === "loggedout" &&
      this.props.smsLoginAllowed &&
      !this.recaptchaInitialized
    ) {
      initRecaptcha("recaptcha-container");
      this.recaptchaInitialized = true;
    }
    if (
      this.props.smsLoginAllowed &&
      (this.props.loginStatus === "failed" ||
        this.props.loginStatus === "loggedout")
    ) {
      initRecaptcha("recaptcha-container");
      this.recaptchaInitialized = true;
    }
  }
  _renderEmailSentMessage() {
    return (
      <>
        <div style={{ fontSize: 22, marginBottom: 30 }}>
          <strong>Please check your email for a link to sign in.</strong>
        </div>

        <div className="has-text-info">
          If you don't receive an email from us in the next few minutes,{" "}
          <a
            href="#"
            style={{ textDecoration: "underline" }}
            onClick={() => {
              window.location.reload();
            }}
          >
            click here to try signing in again
          </a>
          .
        </div>
      </>
    );
  }

  _renderLoginForm(errorMessage?: string) {
    const {
      googleLoginAllowed,
      emailLoginAllowed,
      smsLoginAllowed,
      onLogin,
      onEmailLogin,
      onSmsLogin,
    } = this.props;
    return (
      <>
        {googleLoginAllowed && (
          <Button
            type="primary"
            size={"large"}
            style={{ width: "250px" }}
            onClick={onLogin}
          >
            <span>
              {emailLoginAllowed || smsLoginAllowed
                ? "Click to Sign In With Google"
                : "Click to Sign In"}
            </span>
          </Button>
        )}
        {emailLoginAllowed && (
          <Button
            style={{
              marginTop: "1em",
              width: "250px",
            }}
            type="primary"
            size="large"
            onClick={onEmailLogin}
          >
            <span>Click to Sign In With Email</span>
          </Button>
        )}
        {smsLoginAllowed && (
          <div>
            <Button
              style={{
                marginTop: "1em",
                width: "250px",
              }}
              type="primary"
              size="large"
              onClick={onSmsLogin}
              id="sms_login_button"
            >
              <span>Click to Sign In With Phone</span>
            </Button>
            <div id="recaptcha-container" style={{ marginTop: "1em" }} />
          </div>
        )}
        {errorMessage && (
          <p className="help is-danger" style={{ fontSize: 14 }}>
            {errorMessage}
          </p>
        )}
      </>
    );
  }
}

const LoginController = withLogin<
  LoginControllerExtProps,
  LoginControllerProps
>(LoginControllerComp);

export default LoginController;
