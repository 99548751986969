import * as React from "react";

import "./SectionTitle.scss";

interface Props {
  children: JSX.Element | string;
}

export default class SectionTitle extends React.Component<Props> {
  render() {
    return <h2 className="sectionTitle">{this.props.children}</h2>;
  }
}
