import * as React from "react";
import idx from "idx.macro";
import { Tag } from "react-bulma-components";
import { Tooltip } from "antd";
import { InfoOutlined } from "@material-ui/icons";
import moment from "moment-timezone";
import { getStatusBulmaColor } from "../../shared/lib/util";
import {
  updateParticipantConsent,
  UpdateParticipantGqlArguments,
  updateParticipantLanguage,
} from "../../actions/participants";
import { ParticipantDetailsType } from "../screens/ParticipantDetailsQueryTypes";
import { getFeatureFlags } from "../../featureFlags";
import isMagicPhoneNumber from "../../shared/util/is_magic_phone_number";
import { KeyedString } from "../../shared/lib/graphql";
import { SMSSelect } from "./SMSSelect";
import "../../shared/components/elements/antd-tooltip.css";
import { humanizeDateOverLimit } from "../../utils/formatTime";
import { AddressComponent } from "../../shared/components/elements/AddressComponent";
import { LanguageSelect } from "./LanguageSelect";

type Props = {
  participant: ParticipantDetailsType;
  riskLevels?: Array<KeyedString>;
  complianceOptions?: Array<KeyedString>;
  twilioNumber?: string;
};

interface SectionType {
  title: string;
  val: string | boolean;
  render?: (val: string | boolean) => React.ReactNode;
  toolTipText?: string;
}

function formatDate(date: string): string {
  return moment(date).format("DD MMM 'YY");
}

export class ParticipantAccountInfo extends React.PureComponent<Props> {
  _getSections = () => {
    const {
      participant,
      riskLevels,
      complianceOptions,
      twilioNumber,
    } = this.props;
    const sections: Array<SectionType> = getSectionsFromFeatureFlags(
      participant,
      twilioNumber,
      riskLevels,
      complianceOptions
    );

    return sections;
  };

  render() {
    let sections = this._getSections();

    return (
      <div className="accountInfo columns">
        {sections.map(({ title, val, render, toolTipText }) => (
          <div key={title} className="accountInfoSection column">
            <div className="subtitle is-6">
              {toolTipText ? (
                <Tooltip title={toolTipText}>
                  {title}
                  <InfoOutlined
                    style={{
                      fontSize: 14,
                      top: 2,
                      position: "relative",
                      left: 3,
                    }}
                  />
                </Tooltip>
              ) : (
                title
              )}
            </div>
            {!render ? (
              <div className="title is-5">{val || "Not set"}</div>
            ) : (
              render(val) || "Not set"
            )}
          </div>
        ))}
      </div>
    );
  }
}

function getSectionsFromFeatureFlags(
  participant: ParticipantDetailsType,
  twilioNumber?: string,
  riskLevels?: Array<KeyedString>,
  complianceOptions?: Array<KeyedString>
) {
  const {
    id,
    phone,
    case_manager,
    language,
    created_at,
    auth_token,
    sms_enabled,
    sms_consent,
    supervision_begin_date,
    supervision_end_date,
    // sms_can_enable,
    risk_level,
    assessment,
    compliance,
    address,
    current_device: currentDevice,
    cell_phone_human_readable,
    probation_number: probationNumber,
  } = participant;
  const currentMobile = idx(phone, (_) => _.mobile);
  let mobile = cell_phone_human_readable || currentMobile;
  const cmFirst = idx(case_manager, (_) => _.name.first);
  const cmLast = idx(case_manager, (_) => _.name.last);
  const assessmentScore = idx(assessment, (_) => _.score);
  const cmName = `${cmFirst} ${cmLast}`;
  const clientTerm = getFeatureFlags().clientDisplayTerm || "Client";
  const flatAddress = idx(address, (_) => _.value);
  const platform = idx(currentDevice, (_) => _.platform);

  if (isMagicPhoneNumber(currentMobile)) {
    mobile = "";
  }

  const clientColumnsToDisplayDictionary: {
    [key: string]: any;
  } = getFeatureFlags().clientDetailsColumnsToDisplay;

  const columnDictionary: {
    [key: string]: SectionType;
  } = {
    phone: {
      title: "Phone",
      val: mobile,
      toolTipText: `${clientTerm}'s phone number, which is also where they receive SMS reminders`,
    },
    name: {
      title: getFeatureFlags().caseManagerDisplayTerm,
      val: cmName,
    },
    language: {
      title: "Language",
      val: language == "en" ? "English" : "Spanish",
      toolTipText: `The ${clientTerm}'s preferred language, in which automated SMS reminders are sent`,
      render: (_: string | boolean) => (
        <div style={{ marginTop: "-1.25rem" }}>
          <LanguageSelect
            value={language}
            onChange={(language: string) => {
              updateParticipantLanguage({
                id: participant.id,
                language: language,
              });
            }}
            options={[
              { key: "en", value: "English" },
              { key: "es", value: "Spanish" },
            ]}
          />
        </div>
      ),
    },
    currentDevice: {
      title: "Has Mobile App",
      val: platform ? `Yes` : "No",
      toolTipText: `Indicates if the ${clientTerm} has downloaded and logged-into the mobile app.`,
    },
    access_code: {
      title: "App Login Code",
      val: auth_token,
      toolTipText: `Code the ${clientTerm} needs to enter to sign into the Promise Client Mobile App`,
    },
    supervision_begin_date: {
      title: "Start Date",
      val: null as any,
      render: (_: any) =>
        !supervision_begin_date ? (
          "None set"
        ) : (
          <>
            <div className="title is-5" style={{ marginBottom: 0 }}>
              {formatDate(supervision_begin_date)}
            </div>
            <span style={{ fontSize: 12 }} className="has-text-grey-dark">
              {humanizeDateOverLimit(supervision_begin_date)}
            </span>
          </>
        ),
      toolTipText: "Date the supervision started",
    },
    supervision_end_date: {
      title: "End Date",
      val: null as any,
      render: (_: any) =>
        !supervision_end_date ? (
          "None set"
        ) : (
          <>
            <div className="title is-5" style={{ marginBottom: 0 }}>
              {formatDate(supervision_end_date)}
            </div>
            <span style={{ fontSize: 12 }} className="has-text-grey-dark">
              {moment(supervision_end_date)
                .fromNow()
                .toString()}
            </span>
          </>
        ),
      toolTipText: "Date the supervision ended",
    },
    joined: {
      title: "Joined",
      val: formatDate(created_at),
      toolTipText: "Date the client was added to your agency's caseload",
    },
    sms_enabled: {
      title: "SMS Reminders",
      val: sms_enabled,
      toolTipText: `This tells you if the ${clientTerm} is receiving automated SMS reminders. If SMS reminders are Off, there will be additional information about why (e.g. "${clientTerm} opt out")`,
      render: (_: string | boolean) => (
        <div style={{ marginTop: "-1.25rem" }}>
          <SMSSelect
            onChange={(newState) => {
              const { sms_consent, sms_enabled } = newState;
              const args: UpdateParticipantGqlArguments = {
                id,
                sms_consent: sms_consent,
                sms_enabled: sms_enabled,
              };
              updateParticipantConsent(args);
            }}
            sms_enabled={sms_enabled}
            sms_consent={sms_consent}
            phone={mobile}
          />
          <div style={{ fontSize: 12 }}>
            {sms_consent === false && twilioNumber
              ? `${clientTerm}s can opt-in directly by sending "start" to ${twilioNumber}`
              : null}
          </div>
        </div>
      ),
    },
    compliance: {
      title: getFeatureFlags().whatIsComplianceCalled || "Compliance",
      val: compliance || "Not set",
      toolTipText: `${clientTerm}'s overall ${getFeatureFlags()
        .whatIsComplianceCalled || "Compliance"} in the program`,
      render: (val) => {
        let displayString = val;
        if (complianceOptions) {
          const complianceDisplayValue = (
            complianceOptions.find((opt) => opt.key === compliance) || {
              value: undefined,
            }
          ).value;
          if (complianceDisplayValue) {
            displayString = complianceDisplayValue;
          }
        }
        return (
          <Tag color={getStatusBulmaColor(displayString.toString())}>
            {displayString || "Not Set"}
          </Tag>
        );
      },
    },
    address: {
      title: "Address",
      val: flatAddress || "",
      toolTipText: `${clientTerm}'s address`,
      render: (val) => (
        <div style={{ marginTop: "-1.25rem" }}>
          <AddressComponent address={val as string} />
        </div>
      ),
    },
    probationNumber: {
      title: "Probation Number",
      val: probationNumber,
      toolTipText: `${clientTerm}'s probation number`,
      render: (val) => val,
    },
  };
  // risk levels is a bit of a special case, as we want to hide
  // clients with risk levels of 'N/A' or 'All'
  const riskLevelsToHide = ["N/A", "All"];
  if (!riskLevelsToHide.includes(risk_level)) {
    columnDictionary["risk_level"] = {
      title: `${getFeatureFlags().whatIsRiskLevelCalled || "Risk Level"}${
        assessmentScore ? " (Score)" : ""
      }`,
      val: risk_level,
      render: (val) => {
        if (riskLevels) {
          let riskLevelDisplayValue = (
            riskLevels.find((riskLevel) => riskLevel.key === val) || {
              value: undefined,
            }
          ).value;
          if (riskLevelDisplayValue) {
            riskLevelDisplayValue += assessmentScore
              ? ` (${assessmentScore})`
              : "";
            return riskLevelDisplayValue;
          }
        }
        return val;
      },
    };
  }

  // the above object defines all of the sections, just a quick reduce based on
  // the client columns to clientColumnsToDisplayDictionary feature flag object
  // to decide which ones we should actually show
  return Object.keys(clientColumnsToDisplayDictionary).reduce(
    (acc, key) => {
      if (
        clientColumnsToDisplayDictionary[key] === true &&
        columnDictionary.hasOwnProperty(key)
      ) {
        acc.push(columnDictionary[key]);
      }
      return acc;
    },
    [] as SectionType[]
  );
}
