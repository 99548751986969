import { getFeatureFlags } from "../featureFlags";
import { humanizeDateOverLimit as humanizeDateOverLimitShared } from "../shared/lib/util";

const {
  formatting: { startDateHumanizeCutoff },
} = getFeatureFlags();

export function format24HourTimeAs12Hour(time: string): string {
  // Expects 24 hour time string like "18:00"
  // Returns a string like "6PM"
  if (!time) {
    return time;
  }
  const parts = time.split(":");
  if (parts.length !== 2) {
    throw new Error("Invalid time value " + time);
  }

  const hour = parseInt(parts[0], 10);
  const minute = parseInt(parts[1], 10);

  let minuteStr = `${minute}`;
  if (minute === 0) {
    minuteStr = "";
  } else if (minuteStr.length === 1) {
    minuteStr = "0" + minuteStr;
  }
  if (minuteStr.length > 0) {
    minuteStr = `:${minuteStr}`;
  }

  let modifier = hour > 11 ? "PM" : "AM";
  return `${hour % 12}${minuteStr}${modifier}`;
}

export const humanizeDateOverLimit = (date: string, limit?: number): string => {
  return humanizeDateOverLimitShared(
    date,
    limit || startDateHumanizeCutoff || 90
  );
};
