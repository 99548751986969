import React from "react";
import { Tabs, Badge } from "antd";
import { KeyedString } from "../../lib/graphql/flowTypes";

export interface BulmaTabProps {
  className: string;
  align: string;
  size: string;
  type: string;
  fullwidth: boolean;
}

export interface LinkedTab extends KeyedString {
  linkTo: string;
  selected: boolean;
  renderAs?: (props: { children?: React.ReactChildren }) => JSX.Element;
  count?: number;
}

export interface LinkComponentProps {
  to: string;
  children?: React.ReactChildren;
}

export interface LinkedTabsProps<TLinkProps extends LinkComponentProps = any> {
  tabs: Array<LinkedTab>;
  tabProps?: Partial<BulmaTabProps>;
  LinkComponent: React.ComponentClass<TLinkProps>;
}

export class LinkedTabs extends React.PureComponent<LinkedTabsProps> {
  public linkRefs: any;

  constructor(props: any) {
    super(props);
    const { tabs } = props;

    // quick hack to guarantee that the link tab
    // will be clicked regardless of where the user clicks in the link
    // tab card
    this.linkRefs = tabs.map(() => React.createRef());
  }

  render() {
    const { tabs, LinkComponent } = this.props;
    if (!tabs || !Array.isArray(tabs) || tabs.length === 0) return <div />;
    const { TabPane } = Tabs;
    const [activeTabIndex] = tabs.reduce(
      (acc: number[], tab, index) => {
        const { selected } = tab;
        if (selected) {
          acc[0] = index;
        }
        return acc;
      },
      [0]
    );
    return (
      <Tabs
        defaultActiveKey={activeTabIndex.toString()}
        type="card"
        size="large"
        tabBarStyle={{ marginBottom: "-1px", marginTop: "2em" }}
        onChange={(index: any) => this.linkRefs[index].click()}
      >
        {tabs.map(({ value, linkTo, count }, index) => (
          <TabPane
            key={index.toString()}
            style={{ margin: 0, padding: 0 }}
            tab={
              <>
                <LinkComponent
                  to={linkTo}
                  style={{ margin: "-5px", padding: "-5px" }}
                  ref={(input: any) => (this.linkRefs[index] = input)}
                >
                  {value}
                </LinkComponent>
                {count !== undefined && (
                  <Badge style={{ marginLeft: "10px" }} count={count}></Badge>
                )}
              </>
            }
          ></TabPane>
        ))}
      </Tabs>
    );
  }
}
