import moment from "moment";
import { KeyedString, NOT_SET } from "../../shared/lib/graphql/index";

export const DO_NOT_REPEAT = "do_not_repeat";

const recurrenceOptions = [
  {
    key: NOT_SET,
    value: "Do not repeat",
    include: [],
  },
  {
    key: "daily",
    value: "Every day",
    include: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
  },
  {
    key: "every_weekday",
    value: "Every weekday (Monday-Friday)",
    include: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
  },
  {
    key: "every_monday",
    value: "Every Monday",
    include: ["Monday"],
  },
  {
    key: "every_tuesday",
    value: "Every Tuesday",
    include: ["Tuesday"],
  },
  {
    key: "every_wednesday",
    value: "Every Wednesday",
    include: ["Wednesday"],
  },
  {
    key: "every_thursday",
    value: "Every Thursday",
    include: ["Thursday"],
  },
  {
    key: "every_friday",
    value: "Every Friday",
    include: ["Friday"],
  },
  {
    key: "every_saturday",
    value: "Every Saturday",
    include: ["Saturday"],
  },
  {
    key: "every_sunday",
    value: "Every Sunday",
    include: ["Sunday"],
  },
  {
    key: "tuesday_thursday",
    value: "Every Tuesday and Thursday",
    include: ["Tuesday", "Thursday"],
  },
  {
    key: "monday_wednesday_friday",
    value: "Every Monday, Wednesday, and Friday",
    include: ["Monday", "Wednesday", "Friday"],
  },
];

export function getRecurrenceOptions(start: any): Array<KeyedString> {
  // first item is "does not repeat"
  return [
    recurrenceOptions[0],
    ...recurrenceOptions.filter((obj) =>
      obj.include.includes(moment(start).format("dddd"))
    ),
  ];
}
