import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "../shared/lib/graphql/commitMutation";
import { MutationAddBookingGroupArgs, Maybe } from "../graphql-types";
import { RecordSourceSelectorProxy } from "relay-runtime";
import { checkMutationReturn } from "./util";

export const addBookingGroup = (variables: MutationAddBookingGroupArgs) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation bookingGroupsAddBookingGroupMutation(
          $bookings_to_modify: [BookingToModifyInput]!
          $id: String
        ) {
          addBookingGroup(bookings_to_modify: $bookings_to_modify, id: $id) {
            result
            description
            booking_group {
              id
              bookings {
                id
                booking_defendent {
                  cii_number
                  local_id
                  cdl_id
                  name {
                    first
                    last
                  }
                  date_of_birth
                  sex
                  race
                }
                booking_info {
                  booking_sid
                  local_id
                  booking_number
                  booking_datetime
                  booking_address
                }
                booking_charges {
                  id
                  booking_charge_sid
                  booking_sid
                  booking_number
                  booking_datetime
                  charge_auth_type
                  charge_code_section
                  charge_type
                  charge_datetime
                  charge_description
                  arrest_agency
                  arrest_datetime
                  crime_case_number
                  court_warrant_number
                }
                booking_releases {
                  charge_code_section
                  charge_description
                  charge_type
                  charge_release_datetime
                  charge_release_type
                  warrant_number
                  court_case_number
                  jc_control
                  created_datetime
                }
                booking_status {
                  booking_number
                  court_appearance_datetime
                  court_reason
                  court_room
                }
                classification
                imported_date
                comments
                is_eligible
                spanish_speaker
                has_wait
              }
              ineligible_bookings {
                id
                booking_defendent {
                  cii_number
                  local_id
                  cdl_id
                  name {
                    first
                    last
                  }
                  date_of_birth
                  sex
                  race
                }
                booking_info {
                  booking_sid
                  local_id
                  booking_number
                  booking_datetime
                  booking_address
                }
                booking_charges {
                  id
                  booking_charge_sid
                  booking_sid
                  booking_number
                  booking_datetime
                  charge_auth_type
                  charge_code_section
                  charge_type
                  charge_datetime
                  charge_description
                  arrest_agency
                  arrest_datetime
                  crime_case_number
                  court_warrant_number
                }
                booking_releases {
                  charge_code_section
                  charge_description
                  charge_type
                  charge_release_datetime
                  charge_release_type
                  warrant_number
                  court_case_number
                  jc_control
                  created_datetime
                }
                booking_status {
                  booking_number
                  court_appearance_datetime
                  court_reason
                  court_room
                }
                classification
                imported_date
                comments
                is_eligible
                spanish_speaker
                has_wait
              }
              email_sent
            }
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreAddBookingGroup(store, "addBookingGroup", variables.id);
      },
    },
    "Error while adding a booking group"
  );
};

export const updateRelayStoreAddBookingGroup = (
  store: RecordSourceSelectorProxy,
  rootFieldName: string,
  id: Maybe<string>
) => {
  const payload = checkMutationReturn(store, rootFieldName);

  if (!payload) return;

  if (id) {
    const existing = store.get(id);

    existing!.copyFieldsFrom(payload);
    return;
  }

  const newBookingGroup = payload.getLinkedRecord("booking_group");

  const root = store.getRoot();

  const bookingGroups = root.getLinkedRecord("booking_groups");

  if (!bookingGroups) {
    console.error(`Didn't find root bookingGroup after ${rootFieldName}`);
    return;
  }

  const existingBookingGroups = bookingGroups!.getLinkedRecords(
    "booking_group"
  );

  const combined = [newBookingGroup].concat(existingBookingGroups);

  bookingGroups!.setLinkedRecords(combined, "booking_group");
};
