/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type broadcastMessagesDeleteMutationVariables = {
    readonly id: string;
};
export type broadcastMessagesDeleteMutationResponse = {
    readonly deleteBroadcastMessageFromCaseManager: ({
        readonly result: string;
        readonly description: string | null;
        readonly errors: ReadonlyArray<string | null> | null;
        readonly ids: ReadonlyArray<string | null> | null;
    }) | null;
};
export type broadcastMessagesDeleteMutation = {
    readonly response: broadcastMessagesDeleteMutationResponse;
    readonly variables: broadcastMessagesDeleteMutationVariables;
};



/*
mutation broadcastMessagesDeleteMutation(
  $id: String!
) {
  deleteBroadcastMessageFromCaseManager(id: $id) {
    result
    description
    errors
    ids
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "String!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "deleteBroadcastMessageFromCaseManager",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                }
            ],
            "concreteType": "MutationResponse",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "result",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "errors",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "ids",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "broadcastMessagesDeleteMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "broadcastMessagesDeleteMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "broadcastMessagesDeleteMutation",
            "id": null,
            "text": "mutation broadcastMessagesDeleteMutation(\n  $id: String!\n) {\n  deleteBroadcastMessageFromCaseManager(id: $id) {\n    result\n    description\n    errors\n    ids\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'f2c0006b47436995c7b671ecef4d711c';
export default node;
