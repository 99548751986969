import * as React from "react";
import moment from "moment-timezone";
import idx from "idx.macro";
import { Button } from "antd";
import { Drafts, Send, WatchLater, Edit } from "@material-ui/icons";
import { PageHeader } from "../../shared/components/elements/PageHeader";
import SortableTable from "../../shared/components/table/SortableTable";
import { getFeatureFlags } from "../../featureFlags";
import {
  AddBroadcastMessageGqlArguments,
  addBroadcastMessage,
  deleteBroadcastMessage,
  updateBroadcastMessage,
} from "../../actions/broadcastMessages";
import {
  BroadcastMessage,
  BroadcastMessageRecipientDisplay,
  BroadcastMessageCaseManager,
} from "./BroadcastMessagesGql";
import { TableColumn } from "../../shared/components/table/tableTypes";
import { BroadcastMessageModal } from "./edit-messages/BroadcastMessageModal";
import { Operation } from "../modals/types";
import {
  BroadcastMessageRecipient,
  BroadcastMessageRecipientType,
  MutationResult,
} from "../../shared/lib/graphql";
import { BroadcastMessageForAPI } from "./edit-messages/BroadcastMessageModalWrapper";
import "./Broadcast.scss";
import "../../css/sectionBucket.scss"; // TODO turn this into its own component

const {
  clientDisplayTerm,
  messages: { broadcastMessagesHeaderString },
} = getFeatureFlags();

type TableColumnKeyType = string;

export interface BroadcastMessagesProps {
  messages: Array<BroadcastMessage>;
  messagesDesc: Array<BroadcastMessage>;
  recipients: Array<BroadcastMessageRecipientDisplay>;
}

interface BroadcastMessagesState {
  editing: {
    row: number;
    tableKey?: string;
  };
  isComposing: boolean;
}

function formatDate(date: Date) {
  return moment(date).format("MM/DD/YY hh:mm A");
}

const queryMatches = (
  query: BroadcastMessageRecipient | undefined,
  queryType: BroadcastMessageRecipientType,
  arg: string | undefined
): boolean =>
  !!query &&
  query.queryType === queryType &&
  ((!query.arg && !arg) || query.arg === arg);

export class BroadcastMessages extends React.PureComponent<
  BroadcastMessagesProps,
  BroadcastMessagesState
> {
  state = {
    editing: {} as any,
    isComposing: false,
  };

  private columns: Array<TableColumn<BroadcastMessage, TableColumnKeyType>> = [
    {
      key: "text",
      id: "text",
      headerStyle: { width: "40%" },
      header: "Message",
      sortable: false,
      render: (text: string, _: number, __: BroadcastMessage) => {
        return text;
      },
    },
    {
      key: "to",
      id: "to",
      headerStyle: { width: "15%" },
      header: "Recipients",
      sortable: false,
      render: (
        { queryType, arg }: BroadcastMessageRecipient,
        _: number,
        __: BroadcastMessage
      ) => {
        const { recipients } = this.props;
        const recipient = recipients.find(({ query }) =>
          queryMatches(query, queryType, arg)
        );

        const display = recipient ? recipient.value : arg;

        switch (queryType) {
          case BroadcastMessageRecipientType.Caseload:
            return `${display}`;
          default:
            return `${display} ${clientDisplayTerm}s`;
        }
      },
    },
    {
      key: "time_to_deploy",
      id: "time_to_deploy",
      headerStyle: { width: "15%" },
      header: "Scheduled Time",
      sortable: false,
      render: (scheduled: string, _: number, __: BroadcastMessage) => {
        return scheduled
          ? formatDate(new Date(scheduled))
          : "Not yet scheduled";
      },
    },
    {
      key: "deployed_at",
      id: "deployed_at",
      headerStyle: { width: "15%" },
      header: "Time Sent",
      sortable: false,
      render: (deployed_at: string, _: number, __: BroadcastMessage) => {
        return deployed_at ? formatDate(new Date(deployed_at)) : "";
      },
    },
    {
      key: "created_at",
      id: "created_at",
      headerStyle: { width: "15%" },
      header: "Time Created",
      sortable: false,
      render: (created: string, _: number, __: BroadcastMessage) => {
        return created ? formatDate(new Date(created)) : "";
      },
    },
    {
      key: "case_manager",
      id: "case_manager",
      headerStyle: { width: "15%" },
      header: "Author",
      sortable: false,
      render: (
        { name: { first, last } }: BroadcastMessageCaseManager,
        _: number,
        __: BroadcastMessage
      ) => `${first} ${last}`.replace(" ", "\u00a0").replace("-", "\u2011"),
    },
    {
      key: "",
      id: "button",
      header: "",
      sortable: false,
      render: (
        ___: string,
        row: number,
        item: BroadcastMessage,
        tableKey?: string
      ) =>
        item.deployed ? null : (
          <Button
            size="small"
            onClick={() => {
              this.setState({ editing: { tableKey, row } });
            }}
          >
            <Edit fontSize="small" /> <span>Edit</span>
          </Button>
        ),
    },
  ];

  private renderExpandedRow = (item: any, index: number, tableKey?: string) => {
    const { editing } = this.state;
    if (editing.tableKey !== tableKey || editing.row !== index) return;

    const deconstitute = ({
      id,
      text: message,
      time_to_deploy: scheduledFor,
      to: { queryType, arg },
    }: BroadcastMessage) => {
      const recipient = this.props.recipients.find(({ query }) =>
        queryMatches(query, queryType, arg)
      );
      if (!recipient) {
        console.error(
          `On expanding row for editing Broadcast Message, couldn't match recipient messageId: "${id}"`
        );
        return {};
      }

      return {
        message,
        scheduledFor,
        recipient: recipient.key,
      };
    };

    const updateObject = deconstitute(item);

    return (
      <BroadcastMessageModal
        callBackForAPI={this.onUpdateModal(item.id)}
        onCancel={this.stopEditing}
        onDelete={async (evt: React.SyntheticEvent<HTMLButtonElement>) => {
          const callResult = await deleteBroadcastMessage(item.id);
          const result = idx<MutationResult, any>(
            callResult,
            (_) => _.deleteBroadcastMessageFromCaseManager.result
          );

          // if not, we're trusting that the graphql call caught this error
          if (result === MutationResult.Success) {
            this.stopEditing(evt as any); // close the modal
          }
        }}
        type="BroadcastMessage"
        operation={Operation.Edit}
        recipients={this.props.recipients}
        updateObject={updateObject}
      />
    );
  };

  private stopEditing = (_: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.setState({ editing: {} as any });
  };

  private startComposingNew = (
    _: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    this.setState({ isComposing: true });
  };

  private stopComposingNew = (_: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.setState({ isComposing: false });
  };

  private prepModaldata = (
    data: BroadcastMessageForAPI
  ): AddBroadcastMessageGqlArguments => {
    const { message: text, recipient: recipientKey, scheduledFor } = data;
    const { recipients } = this.props;

    const recipient = recipients.find(({ key }) => key === recipientKey);
    if (!recipient) {
      // The mutation call will fail - but continuing means we'll throw the modal on fail
      // Currently, we don't have a way of throwing that modal from here
      console.error(
        `Didn't find the specified recipient, key: "${recipientKey}".`
      );
    }

    const variables: AddBroadcastMessageGqlArguments = {
      text,
      to: recipient ? recipient.query : (null as any),
      time_to_deploy: scheduledFor ? scheduledFor.toDate() : (null as any),
    };

    return variables;
  };

  private onAddModal = async (data: BroadcastMessageForAPI) => {
    const variables = this.prepModaldata(data);

    // close, then await - feels faster
    this.setState({ isComposing: false });

    await addBroadcastMessage(variables);

    // TODO: Would be nice to return the original state and leave the modal open server side has failed
  };

  private onUpdateModal = (id: string) => async (
    data: BroadcastMessageForAPI
  ) => {
    let variables = {
      id,
      ...this.prepModaldata(data),
    };

    // close, then await - feels faster
    this.setState({ editing: {} as any });

    await updateBroadcastMessage(variables);

    // TODO: Would be nice to return the original state and leave the modal open server side has failed
  };

  render() {
    const { messages, messagesDesc } = this.props;
    const { isComposing } = this.state;

    const messages_drafts = messages.filter(
      (msg) => !!msg && !msg.deployed && !msg.scheduled
    );

    const messages_scheduled = messages.filter(
      (msg) => !!msg && !msg.deployed && msg.scheduled
    );

    const messages_deployed = messagesDesc.filter(
      (msg) => !!msg && msg.deployed && msg.scheduled
    );

    const columns_drafts = this.columns.filter(
      (col) => col.id !== "deployed_at" && col.id !== "time_to_deploy"
    );

    const columns_scheduled = this.columns.filter(
      (col) => col.id !== "deployed_at" && col.id !== "created_at"
    );

    const columns_deployed = this.columns.filter(
      (col) => col.id !== "time_to_deploy" && col.id !== "created_at"
    );

    return (
      <div className="broadcast">
        <PageHeader
          pageName="Broadcast Messages"
          pageBlurb={broadcastMessagesHeaderString}
          headerRight={
            <Button
              type="primary"
              onClick={(event) => this.startComposingNew(event)}
            >
              Schedule New Message
            </Button>
          }
        />
        <div className="constrained-section">
          <section className="bucket">
            {isComposing && (
              <div className="modal-wrapper">
                <BroadcastMessageModal
                  callBackForAPI={this.onAddModal}
                  onCancel={this.stopComposingNew}
                  type="BroadcastMessage"
                  operation={Operation.Add}
                  recipients={this.props.recipients}
                />
              </div>
            )}
            <div className="section-info">
              <Drafts />
              <div>
                <div className="section-title">Drafts</div>
                <div className="section-blurb">
                  These messages have not yet been scheduled. Complete the
                  message scheduling by clicking the edit button and adding a
                  date and time.
                </div>
              </div>
            </div>
            {messages_drafts.length ? (
              <div className="table-container">
                <SortableTable
                  className="table is-striped participantTable"
                  tableKey="drafts"
                  data={messages_drafts}
                  columns={columns_drafts}
                  renderExpandedRow={this.renderExpandedRow}
                />
              </div>
            ) : (
              <div className="section-empty">No message drafts</div>
            )}
          </section>
          <section className="bucket">
            <div className="section-info">
              <WatchLater />
              <div>
                <div className="section-title">Messages To Be Sent</div>
                <div className="section-blurb">
                  These messages will be sent at the scheduled date and time.
                  They can be edited anytime until then.
                </div>
              </div>
            </div>
            {messages_scheduled.length ? (
              <div className="table-container">
                <SortableTable
                  className="table is-striped participantTable"
                  tableKey="scheduled"
                  data={messages_scheduled}
                  columns={columns_scheduled}
                  renderExpandedRow={this.renderExpandedRow}
                />
              </div>
            ) : (
              <div className="section-empty">No scheduled messages</div>
            )}
          </section>
          <section className="bucket">
            <div className="section-info">
              <Send />
              <div>
                <div className="section-title">Sent Messages</div>
                <div className="section-blurb">
                  These are the messages that have already been sent.
                </div>
              </div>
            </div>
            {messages_deployed.length ? (
              <div className="table-container">
                <SortableTable
                  className="table is-striped participantTable"
                  tableKey="deployed"
                  data={messages_deployed}
                  columns={columns_deployed}
                  renderExpandedRow={this.renderExpandedRow}
                />
              </div>
            ) : (
              <div className="section-empty">No sent messages</div>
            )}
          </section>
        </div>
      </div>
    );
  }
}
