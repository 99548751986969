import * as React from "react";
import {
  AnalyticsReportStats,
  AnalyticsParticipantListItem,
} from "./analyticsTypes";
import moment from "moment";

import "./AnalyticsReportPage.scss";
import SummariesAndBreakdowns from "./SummariesAndBreakdowns";
import EventAttendanceTable from "./EventAttendanceTable";
import ClientSummaryTable from "./ClientSummaryTable";
import SectionTitle from "./SectionTitle";
import SummaryTable from "./SummaryTable";
import Select from "../../shared/components/elements/Select";
import { Button } from "react-bulma-components";

interface Props {
  data: AnalyticsReportStats;
  week: number | null;
  month: number | null;
  year: number | null;
  onAllTimeSelected: () => void;
  onTimeSelected: (
    week: number | null,
    month: number | null,
    year: number
  ) => void;
  onDownloadPdf: () => void;
}

interface State {
  attendanceHidden: boolean;
}

function formatDate(date: Date) {
  return moment(date).format("MM/DD/YYYY");
}

function displayAsPercentage(num: number) {
  return `${Math.round(num * 100)}%`;
}

function sortByClientName(
  a: AnalyticsParticipantListItem,
  b: AnalyticsParticipantListItem
) {
  const nameA = a.participant.name;
  const nameB = b.participant.name;

  if (nameA < nameB) {
    return -1;
  }
  if (nameB < nameA) {
    return 1;
  }
  return 0;
}

export default class AnalyticsReportPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      attendanceHidden: false,
    };
  }

  render() {
    let content;

    if (
      !this.props.data ||
      !this.props.data.notification_counts ||
      !this.props.data.event_counts
    ) {
      content = this._renderEmpty();
    } else {
      content = (
        <div>
          {this._renderOverallSummary()}
          {this._renderSummariesAndBreakdowns()}
          {this._renderDetailToggle()}
          {!this.state.attendanceHidden ? (
            <div>
              <div className="section">
                {this._renderEventAttendanceTable()}
              </div>
              <div className="newPageOnPrint">
                <div className="section">
                  {this._renderClientSummaryTable()}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      );
    }
    return (
      <div className="analyticsReportPage">
        {this._renderHeader()}
        {content}
      </div>
    );
  }

  _renderHeader() {
    let toDateString = "";
    if (!!this.props.data.to_date) {
      if (this.props.month !== null || this.props.week !== null) {
        let toDate = moment(this.props.data.to_date).toDate();

        if (toDate.getTime() > Date.now()) {
          toDate = moment().toDate();
        }

        toDateString = `to ${formatDate(toDate)}`;
      }
    }
    if (!toDateString) {
      const toDate = this.props.data.to_date
        ? moment(this.props.data.to_date)
        : moment();
      toDateString = `to ${formatDate(toDate.toDate())}`;
    }

    const timePeriodType = this._getTimePeriodType();

    return (
      <div className="header">
        <div className="header-left">
          <h1 className="title">Promise Messaging Report </h1>
          <div className="reportDate">
            From {formatDate(moment(this.props.data.from_date).toDate())}{" "}
            {toDateString}
            <div>
              <Button
                className="downloadButton"
                outlined={true}
                color={"primary"}
                size="small"
                onClick={this.props.onDownloadPdf}
              >
                Download Report
              </Button>
            </div>
          </div>
        </div>
        <div className="reportPeriod">
          <label>Report period</label>
          <div>
            <Select
              fieldClassName="inlineField"
              value={timePeriodType}
              options={[
                {
                  key: "all",
                  value: "All",
                },
                {
                  key: "month",
                  value: "Month",
                },
                {
                  key: "week",
                  value: "Week",
                },
              ]}
              onChange={this._handleTimePeriodChange}
            />
            {this._renderTimePeriodSelect(timePeriodType)}
          </div>
        </div>
      </div>
    );
  }

  _renderTimePeriodSelect(timePeriodType: string) {
    let fromDate;
    let toDate;
    let label;

    switch (timePeriodType) {
      case "all":
        fromDate = moment(this.props.data.from_date);
        toDate = this.props.data.to_date
          ? moment(this.props.data.to_date)
          : moment();

        label = `${fromDate.format("MMM 'YY")} - ${toDate.format("MMM 'YY")}`;
        return (
          <Select
            fieldClassName="inlineField"
            disabled={true}
            options={[
              {
                key: "all-time",
                value: label,
              },
            ]}
            value={"all-time"}
            onChange={this._handleMonthChange}
          />
        );

      case "week":
        const week = this.props.data.report_weeks.filter((weekData) => {
          return (
            weekData.week === this.props.week &&
            weekData.year === this.props.year
          );
        })[0];

        fromDate = moment(week.start_time);
        toDate = moment(fromDate.toDate()).add(6, "days");

        const weekOptions = this.props.data.report_weeks.map((weekData) => {
          const weekStart = moment(weekData.start_time);
          const weekEnd = moment(weekStart.toDate()).add(6, "days");

          return {
            key: `${weekData.week}:${weekData.year}`,
            value: `${weekStart.format("MMM DD")} - ${
              weekEnd.month() === weekStart.month()
                ? weekEnd.format("DD 'YY")
                : weekEnd.format("MMM DD 'YY")
            }`,
          };
        });
        const weekValue = `${week.week}:${week.year}`;

        return (
          <Select
            fieldClassName="inlineField"
            options={weekOptions}
            value={weekValue}
            onChange={this._handleWeekChange}
          />
        );

      case "month":
        const month = this.props.data.report_months.filter((monthData) => {
          return (
            monthData.month === this.props.month &&
            monthData.year === this.props.year
          );
        })[0];

        fromDate = moment(month.start_time);
        toDate = moment(fromDate.toDate()).add(6, "days");

        const monthOptions = this.props.data.report_months.map((monthData) => {
          const monthStart = moment()
            .month(monthData.month)
            .year(monthData.year);

          return {
            key: `${monthData.month}:${monthData.year}`,
            value: `${monthStart.format("MMMM 'YY")} `,
          };
        });

        return (
          <Select
            fieldClassName="inlineField"
            options={monthOptions}
            value={`${month.month}:${month.year}`}
            onChange={this._handleMonthChange}
          />
        );
      default:
        return null;
    }
  }

  _getTimePeriodType() {
    if (this.props.week !== null) {
      return "week";
    }
    if (this.props.month !== null) {
      return "month";
    }
    return "all";
  }

  _renderEmpty() {
    return <div>No data available</div>;
  }

  _renderOverallSummary() {
    const stats = this.props.data;
    if (
      stats.participant_list &&
      stats.event_counts &&
      stats.notification_counts
    ) {
      const data = [
        {
          label: "Unique Clients To Message",
          value: stats.participant_list.length,
        },
        {
          label: "Total Events",
          value: stats.event_counts.totals.total,
        },
        {
          label: "Total Notifications Sent",
          value: stats.notification_counts.totals.sent,
        },
        {
          label: "Overall Attendance Rate",
          value: displayAsPercentage(stats.event_counts.totals.avg_attended),
        },
      ];

      return (
        <div className="overallSummary section">
          <SummaryTable
            // title={`Overall Summary ${formatDate(startOfProgram) - formatDate(new Date())}`}
            title={`Overall Summary`}
            data={data}
          />
        </div>
      );
    }
    return null;
  }

  _renderSummariesAndBreakdowns() {
    return <SummariesAndBreakdowns data={this.props.data} />;
  }

  _renderDetailToggle() {
    return (
      <div className="detailToggle">
        <p onClick={this._handleToggleAttendanceDetails}>
          {this.state.attendanceHidden
            ? "Show Attendance Details"
            : "Hide Attendance Details"}
        </p>
      </div>
    );
  }

  _renderEventAttendanceTable() {
    return (
      <div>
        <SectionTitle>Event Attendance Detail by Date</SectionTitle>
        <EventAttendanceTable data={this.props.data.event_list} />
      </div>
    );
  }

  _renderClientSummaryTable() {
    const participants = this.props.data.participant_list.slice();
    participants.sort(sortByClientName);
    return (
      <div className="newPageOnPrint">
        <SectionTitle>Client Summary</SectionTitle>
        <ClientSummaryTable data={participants} />
      </div>
    );
  }

  _handleToggleAttendanceDetails = (evt: React.SyntheticEvent<any>) => {
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({ attendanceHidden: !this.state.attendanceHidden });
  };

  _handleTimePeriodChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const value = evt.currentTarget.value;
    let week: number | null = null;
    let month: number | null = null;
    let year = this.props.year || new Date().getFullYear();

    switch (value) {
      case "all":
        break;
      case "month":
        month = new Date().getMonth();
        break;
      case "week":
        week = moment().week();
        break;
      default:
        throw new Error("Invalid time period type " + value);
    }

    this.props.onTimeSelected(week, month, year);
  };

  _handleWeekChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const value = evt.currentTarget.value;

    const [week, year] = value.split(":").map((numStr) => parseInt(numStr, 10));

    this.props.onTimeSelected(week, null, year);
  };

  _handleMonthChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const value = evt.currentTarget.value;

    const [month, year] = value
      .split(":")
      .map((numStr) => parseInt(numStr, 10));

    this.props.onTimeSelected(null, month, year);
  };
}
