import * as React from "react";
import moment from "moment-timezone";
import { Level, Tag } from "react-bulma-components";
import { Button } from "antd";
import { Typography } from "antd";
const titleCase = require("title-case");

import {
  deleteObligationEvent,
  updateObligationEvent,
} from "../../actions/obligations";
import {
  CarePlanEventType,
  CarePlanObligationType,
  CarePlanParticipantType,
} from "./CarePlanQuery";
import Select from "../../shared/components/elements/Select";
import { deleteEventTemplate } from "../../actions/obligations";
import { ScarePrompt } from "../../shared/components/elements/ScarePrompt";
import EventDetailsQueryWrapper from "../modals/events/EventDetailsQueryWrapper";
import { ROOT_PATH } from "../../AppInfo";
import { KeyedString } from "../../../../shared/src/lib/graphql";
import { getFeatureFlags } from "../../featureFlags";
import { RRule } from "rrule";
import { Repeat, FiberManualRecord } from "@material-ui/icons";
import { EventAttendanceSelect } from "../elements/EventAttendanceSelect";

interface ObligationEventProps {
  obligation: CarePlanObligationType;
  event: CarePlanEventType;
  participant: CarePlanParticipantType;
  isReadOnly?: boolean;
  dispositionOptions: KeyedString[];
}

const renderFutureEventsDeletion = (onSecondary: Function) => (
  <Button onClick={(event: any) => onSecondary(event)} type="danger">
    Delete all repeating events
  </Button>
);

export class ObligationEvent extends React.PureComponent<ObligationEventProps> {
  _deleteObligationEvent = async () => {
    const { obligation, event } = this.props;

    await deleteObligationEvent({ obligation, event });
  };

  _updateEvent = async (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const { obligation, event } = this.props;
    const { name, value } = evt.target;

    await updateObligationEvent({
      obligation,
      event,
      eventData: {
        [name]: value,
      },
    });
  };

  _handleEventTemplateDeletion = async () => {
    const {
      obligation: { id: obligationId },
      event: {
        event_template: { id: eventTemplateId },
      },
    } = this.props;

    await deleteEventTemplate(obligationId, eventTemplateId);
  };

  render() {
    const {
      event: { date, id: eventId, attended, disposition, event_template },
      participant: { id: participantId },
      isReadOnly,
      dispositionOptions,
    } = this.props;
    let momentDate = moment(date);

    const dispositionLabel = getFeatureFlags().whatIsDispositionCalled;

    const { rrule = "" } = event_template;

    // let rule: RRule;
    let recurrenceDescription = "";

    if (rrule) {
      // try catching in case the rrule is malformed
      try {
        const newRRule = createSimpleRRuleCopyFromRRuleString(rrule);
        recurrenceDescription = newRRule.toText();
      } catch (error) {
        console.log(error);
      }
    }

    const dateStringFormatted = momentDate
      .format("MM/DD/YY h:mm A")
      .replace(/\s/g, "\u00A0");

    const dateString = dateStringFormatted.includes("12:00 AM")
      ? dateStringFormatted.substring(0, 8)
      : dateStringFormatted;

    const inFuture: boolean = momentDate.isAfter(moment());

    const { Text } = Typography;
    return (
      <Level className="obligationEvent">
        <Level.Side align="left">
          <Level.Item>
            <span style={{ marginRight: 5, width: 16 }}>
              {inFuture ? (
                rrule ? (
                  <Repeat
                    nativeColor="#3b7abe"
                    style={{
                      fontSize: 16,
                      marginRight: 5,
                    }}
                  />
                ) : (
                  <FiberManualRecord
                    nativeColor="#3b7abe"
                    style={{
                      fontSize: 16,
                      marginRight: 5,
                    }}
                  />
                )
              ) : null}
            </span>
            {dateString}
            {inFuture ? (
              <Tag
                className="upcoming"
                color="light"
                style={{ marginLeft: "5px" }}
              >
                Upcoming
              </Tag>
            ) : null}
          </Level.Item>

          {rrule && inFuture ? (
            <Level.Item>
              <span className="has-text-grey-dark">
                {recurrenceDescription}
              </span>
            </Level.Item>
          ) : null}
        </Level.Side>
        <Level.Side align="right">
          {!inFuture ? (
            <>
              <Level.Item>
                <Text strong style={{ marginRight: "5px" }}>
                  Attendance
                </Text>
                <EventAttendanceSelect
                  isReadOnly={isReadOnly}
                  attended={attended}
                  onChange={this._updateEvent}
                />
              </Level.Item>

              {dispositionOptions && dispositionOptions.length > 0 && (
                <Level.Item style={{ marginLeft: 20 }}>
                  <Text strong style={{ marginRight: "5px" }}>
                    {titleCase(dispositionLabel)}
                  </Text>
                  <Select
                    name="disposition"
                    disabled={isReadOnly}
                    instructionalDefault={`Select ${dispositionLabel}...`}
                    options={dispositionOptions}
                    value={disposition}
                    onChange={this._updateEvent}
                  />
                </Level.Item>
              )}
            </>
          ) : null}

          {inFuture ? (
            <Level.Item>
              <Button type="primary" size={"small"} ghost={true}>
                <a
                  href={`/${ROOT_PATH}/print_event/${participantId}/${eventId}`}
                  target="_blank"
                >
                  Print Reminder
                </a>
              </Button>
            </Level.Item>
          ) : (
            <Level.Item>
              <EventDetailsQueryWrapper
                participantId={participantId}
                eventId={eventId}
              />
            </Level.Item>
          )}
          <Level.Item>
            <ScarePrompt
              warningText={`Are you sure you want to delete this event? This cannot be undone.`}
              itemName={rrule && inFuture ? "this event" : "event"}
              secondaryOptionRender={
                rrule && inFuture ? renderFutureEventsDeletion : undefined
              }
              button={
                <Button
                  type={"danger"}
                  ghost={true}
                  size={"small"}
                  disabled={isReadOnly}
                >
                  Delete Event
                </Button>
              }
              onYes={this._deleteObligationEvent}
              onSecondary={this._handleEventTemplateDeletion}
            />
          </Level.Item>
        </Level.Side>
      </Level>
    );
  }
}

// takes a complex rrule string and gives us back a simple
// rrule that doesn't contain exdates and other unnecessary
// fields which is handy for display purposes
function createSimpleRRuleCopyFromRRuleString(rrule: string) {
  const rruleStringNoExDate = rrule.slice(
    0,
    rrule.indexOf("EXDATE") || rrule.length
  );
  const rruleCurrentOptions = RRule.parseString(rruleStringNoExDate);
  const newRule = new RRule({
    freq: rruleCurrentOptions.freq,
    interval: rruleCurrentOptions.interval,
    byweekday: rruleCurrentOptions.byweekday,
    until: rruleCurrentOptions.until,
    dtstart: rruleCurrentOptions.dtstart,
  });

  return newRule;
}
