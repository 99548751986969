import * as React from "react";
import {
  AnalyticsReportStats,
  AnalyticsNotificationItem,
  AnalyticsEventItem,
} from "./analyticsTypes";
import SummaryTable from "./SummaryTable";
import BreakdownTable from "./BreakdownTable";

interface Props {
  data: AnalyticsReportStats;
}

function displayAsPercentage(num: number) {
  return `${Math.round(num * 100)}%`;
}

const note =
  "*Attendance rate is equal to total events marked attended divided by the number of events with attendance data (excludes rescheduled events and events with no data).";

const NOTIFICATION_BREAKDOWN_COLUMNS = [
  {
    key: "title",
    header: "Notification Type",
  },
  {
    key: "sent",
    header: "Notifications Already Sent",
  },
  {
    key: "upcoming",
    header: "Notifications to be Sent",
  },
  {
    key: "failed",
    header: "Delivery Failures",
  },
].map((d) => ({ ...d, sortable: false }));

const EVENT_BREAKDOWN_COLUMNS = [
  {
    key: "title",
    header: "Activity Type",
  },
  {
    key: "total",
    header: "Total",
  },
  {
    key: "attended",
    header: "Attended",
  },
  {
    key: "not_attended",
    header: "Did Not Attend",
  },
  {
    key: "rescheduled",
    header: "Rescheduled",
  },
  {
    key: "no_data",
    header: "No Data",
  },
  { key: "future_event", header: "Future Event" },
  {
    key: (item: any) => {
      const value = item["avg_attended"] || 0;
      return displayAsPercentage(value);
    },
    header: "Recorded Attendance Rate*",
  },
].map((d) => ({ ...d, sortable: false }));

export default class SummariesAndBreakdowns extends React.Component<Props> {
  render() {
    const stats: AnalyticsReportStats = this.props.data;
    return (
      <div className="summariesAndBreakdowns">
        <div className="section">
          {this._renderMessageSummary(stats.notification_counts.totals)}
        </div>
        <div className="section">
          {this._renderMessageBreakdowns(stats.notification_counts.breakdowns)}
        </div>
        <div className="section">
          {this._renderEventSummary(stats.event_counts.totals)}
        </div>
        <div className="section">
          {this._renderEventBreakdowns(stats.event_counts.breakdowns)}
        </div>
      </div>
    );
  }

  _renderMessageSummary(totals: AnalyticsNotificationItem) {
    const data = [
      // {
      //   label: "Total notifications to be sent",
      //   value: totals.total
      // },
      {
        label: "Total Notifications Already Sent",
        value: totals.sent,
      },
      {
        label: "Total Notifications To Be Sent",
        value: totals.upcoming,
      },
      {
        label: "Failed Deliveries",
        value: totals.failed,
      },
    ];
    return <SummaryTable title="Notification Summary" data={data} />;
  }

  _renderEventSummary(totals: AnalyticsEventItem) {
    const data = [
      {
        label: "Total Events",
        value: totals.total,
      },
      {
        label: "Total Events Attended",
        value: totals.attended,
      },
      {
        label: "Total Events Not Attended",
        value: totals.not_attended,
      },
      {
        label: "Total Events Rescheduled",
        value: totals.rescheduled,
      },
      {
        label: "Total Events Without Attendance Data",
        value: totals.no_data,
      },
      {
        label: "Total Future Events",
        value: totals.future_event,
      },
      {
        label: "Overall Attendance Rate*",
        value: displayAsPercentage(totals.avg_attended),
      },
    ];

    return <SummaryTable title="Event Summary" data={data} />;
  }

  _renderMessageBreakdowns(breakdowns: Array<AnalyticsNotificationItem>) {
    return (
      <>
        <BreakdownTable
          columns={NOTIFICATION_BREAKDOWN_COLUMNS}
          data={breakdowns}
        />
        <div className="pagebreak" />
      </>
    );
  }

  _renderEventBreakdowns(breakdowns: Array<AnalyticsEventItem>) {
    return (
      <BreakdownTable
        columns={EVENT_BREAKDOWN_COLUMNS}
        data={breakdowns}
        note={note}
      />
    );
  }
}
