/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type MessageActor = "case_manager" | "participant" | "person_group" | "system" | "%future added value";
export type MessageStatus = "failed" | "succeeded" | "%future added value";
export type ChatGqlQueryVariables = {
    readonly id: string;
    readonly cursor?: string | null;
    readonly group_id?: string | null;
};
export type ChatGqlQueryResponse = {
    readonly application: ({
        readonly flags: ({
            readonly all: any | null;
        }) | null;
    }) | null;
    readonly me: ({
        readonly case_manager: ({
            readonly id: string;
        }) | null;
    }) | null;
    readonly messages: ({
        readonly cursor: string | null;
        readonly page_info: ({
            readonly has_next_page: boolean | null;
        }) | null;
        readonly message: ReadonlyArray<({
            readonly id: string;
            readonly text: string | null;
            readonly created_at: string | null;
            readonly sender_type: MessageActor | null;
            readonly recipient_type: MessageActor | null;
            readonly read_at: string | null;
            readonly sent_as: ReadonlyArray<string | null> | null;
            readonly delivery_status: MessageStatus | null;
            readonly sender: ({
                readonly uid: string;
                readonly name: ({
                    readonly first: string;
                    readonly last: string | null;
                }) | null;
            }) | null;
        }) | null> | null;
    }) | null;
    readonly participants: ({
        readonly participant: ReadonlyArray<({
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
            readonly is_active: boolean | null;
            readonly preferred_name: string | null;
            readonly language: string;
            readonly sms_consent: boolean | null;
            readonly sms_enabled: boolean | null;
            readonly messages: ({
                readonly cursor: string | null;
                readonly page_info: ({
                    readonly has_next_page: boolean | null;
                }) | null;
                readonly message: ReadonlyArray<({
                    readonly id: string;
                    readonly text: string | null;
                    readonly created_at: string | null;
                    readonly sender_type: MessageActor | null;
                    readonly recipient_type: MessageActor | null;
                    readonly read_at: string | null;
                    readonly sent_as: ReadonlyArray<string | null> | null;
                    readonly delivery_status: MessageStatus | null;
                }) | null> | null;
            }) | null;
            readonly phone: ({
                readonly mobile: string | null;
            }) | null;
            readonly current_device: ({
                readonly device_id: string | null;
            }) | null;
        }) | null> | null;
    }) | null;
};
export type ChatGqlQuery = {
    readonly response: ChatGqlQueryResponse;
    readonly variables: ChatGqlQueryVariables;
};



/*
query ChatGqlQuery(
  $id: String!
  $cursor: String
  $group_id: String
) {
  application {
    flags {
      all(flag_ids: "office_hours.label.en")
    }
    id
  }
  me {
    case_manager {
      id
    }
  }
  messages(page_cursor: $cursor, page_size: 15, participant_id: $id, group_id: $group_id) {
    cursor
    page_info {
      has_next_page
    }
    message {
      id
      text
      created_at
      sender_type
      recipient_type
      read_at
      sent_as
      delivery_status
      sender {
        uid
        name {
          first
          last
        }
      }
    }
  }
  participants(id: $id) {
    participant {
      id
      name {
        first
        last
      }
      is_active
      preferred_name
      language
      sms_consent
      sms_enabled
      messages(page_cursor: $cursor, page_size: 15) {
        cursor
        page_info {
          has_next_page
        }
        message {
          id
          text
          created_at
          sender_type
          recipient_type
          read_at
          sent_as
          delivery_status
        }
      }
      phone {
        mobile
      }
      current_device {
        device_id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "cursor",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "group_id",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "flags",
        "storageKey": null,
        "args": null,
        "concreteType": "Flags",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "all",
                "args": [
                    {
                        "kind": "Literal",
                        "name": "flag_ids",
                        "value": "office_hours.label.en"
                    }
                ],
                "storageKey": "all(flag_ids:\"office_hours.label.en\")"
            }
        ]
    } as any), v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "case_manager",
                "storageKey": null,
                "args": null,
                "concreteType": "CaseManager",
                "plural": false,
                "selections": [
                    (v2 /*: any*/)
                ]
            }
        ]
    } as any), v4 = ({
        "kind": "Variable",
        "name": "page_cursor",
        "variableName": "cursor"
    } as any), v5 = ({
        "kind": "Literal",
        "name": "page_size",
        "value": 15
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "cursor",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "page_info",
        "storageKey": null,
        "args": null,
        "concreteType": "PaginationInfo",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "has_next_page",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v8 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "text",
        "args": null,
        "storageKey": null
    } as any), v9 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "created_at",
        "args": null,
        "storageKey": null
    } as any), v10 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "sender_type",
        "args": null,
        "storageKey": null
    } as any), v11 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "recipient_type",
        "args": null,
        "storageKey": null
    } as any), v12 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "read_at",
        "args": null,
        "storageKey": null
    } as any), v13 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "sent_as",
        "args": null,
        "storageKey": null
    } as any), v14 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "delivery_status",
        "args": null,
        "storageKey": null
    } as any), v15 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "name",
        "storageKey": null,
        "args": null,
        "concreteType": "PersonName",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "first",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "last",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v16 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "messages",
        "storageKey": null,
        "args": [
            {
                "kind": "Variable",
                "name": "group_id",
                "variableName": "group_id"
            },
            (v4 /*: any*/),
            (v5 /*: any*/),
            {
                "kind": "Variable",
                "name": "participant_id",
                "variableName": "id"
            }
        ],
        "concreteType": "Pagination",
        "plural": false,
        "selections": [
            (v6 /*: any*/),
            (v7 /*: any*/),
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "message",
                "storageKey": null,
                "args": null,
                "concreteType": "Message",
                "plural": true,
                "selections": [
                    (v2 /*: any*/),
                    (v8 /*: any*/),
                    (v9 /*: any*/),
                    (v10 /*: any*/),
                    (v11 /*: any*/),
                    (v12 /*: any*/),
                    (v13 /*: any*/),
                    (v14 /*: any*/),
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "sender",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "MessageSender",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "uid",
                                "args": null,
                                "storageKey": null
                            },
                            (v15 /*: any*/)
                        ]
                    }
                ]
            }
        ]
    } as any), v17 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "participants",
        "storageKey": null,
        "args": [
            {
                "kind": "Variable",
                "name": "id",
                "variableName": "id"
            }
        ],
        "concreteType": "Pagination",
        "plural": false,
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "participant",
                "storageKey": null,
                "args": null,
                "concreteType": "Participant",
                "plural": true,
                "selections": [
                    (v2 /*: any*/),
                    (v15 /*: any*/),
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "is_active",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "preferred_name",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "language",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "sms_consent",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "sms_enabled",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "messages",
                        "storageKey": null,
                        "args": [
                            (v4 /*: any*/),
                            (v5 /*: any*/)
                        ],
                        "concreteType": "Pagination",
                        "plural": false,
                        "selections": [
                            (v6 /*: any*/),
                            (v7 /*: any*/),
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "message",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "Message",
                                "plural": true,
                                "selections": [
                                    (v2 /*: any*/),
                                    (v8 /*: any*/),
                                    (v9 /*: any*/),
                                    (v10 /*: any*/),
                                    (v11 /*: any*/),
                                    (v12 /*: any*/),
                                    (v13 /*: any*/),
                                    (v14 /*: any*/)
                                ]
                            }
                        ]
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "phone",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PersonPhone",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "mobile",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "current_device",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ParticipantMobileDevice",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "device_id",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    }
                ]
            }
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "ChatGqlQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "application",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Application",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/)
                    ]
                },
                (v3 /*: any*/),
                (v16 /*: any*/),
                (v17 /*: any*/)
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "ChatGqlQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "application",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Application",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/)
                    ]
                },
                (v3 /*: any*/),
                (v16 /*: any*/),
                (v17 /*: any*/)
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "ChatGqlQuery",
            "id": null,
            "text": "query ChatGqlQuery(\n  $id: String!\n  $cursor: String\n  $group_id: String\n) {\n  application {\n    flags {\n      all(flag_ids: \"office_hours.label.en\")\n    }\n    id\n  }\n  me {\n    case_manager {\n      id\n    }\n  }\n  messages(page_cursor: $cursor, page_size: 15, participant_id: $id, group_id: $group_id) {\n    cursor\n    page_info {\n      has_next_page\n    }\n    message {\n      id\n      text\n      created_at\n      sender_type\n      recipient_type\n      read_at\n      sent_as\n      delivery_status\n      sender {\n        uid\n        name {\n          first\n          last\n        }\n      }\n    }\n  }\n  participants(id: $id) {\n    participant {\n      id\n      name {\n        first\n        last\n      }\n      is_active\n      preferred_name\n      language\n      sms_consent\n      sms_enabled\n      messages(page_cursor: $cursor, page_size: 15) {\n        cursor\n        page_info {\n          has_next_page\n        }\n        message {\n          id\n          text\n          created_at\n          sender_type\n          recipient_type\n          read_at\n          sent_as\n          delivery_status\n        }\n      }\n      phone {\n        mobile\n      }\n      current_device {\n        device_id\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'ad48d467eede9e49e4a128abf9a04b6a';
export default node;
