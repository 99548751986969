import React, { useState } from "react";
import { QueryRenderer } from "../../shared/lib/graphql";
import { participantDocumentsQuery } from "./ParticipantDocumentsGraphql";
import idx from "idx.macro";
import { CarePlanContainerProps } from "../care-plan/CarePlanContainer";
import { Participant, Me, ParticipantDocument } from "../../graphql-types";
import { CaseManager } from "../../../../shared/src/lib/graphql";
import { saveAs } from "file-saver";
import ParticipantDocumentContainer from "./ParticipantDocumentContainer";
import { softDeleteParticipantDocument } from "../../actions/participantDocuments";
import fetcher from "../../shared/lib/fetcher/fetcher";
import { Button } from "antd";
import { Loader } from "react-bulma-components";
import { safelyUnwrapArrayWithPossibleNullOrUndefined } from "../../utils/safeUnwrap";

export interface ParticipantDocumentsQueryResult {
  me: Me;
  participants: {
    participant: Array<Participant>;
  };
}

export function ParticipantDocumentsContainer(props: CarePlanContainerProps) {
  const { id } = props;

  const [newUploads, setNewUploads] = useState([] as ParticipantDocument[]);
  // hacky way to make our little new document style effect work
  const [downloadingMessage, setDownloadingMessage] = useState("");

  const handleDownloadClicked = async (filepath: string, name: string) => {
    const downloadUrl = "/documents/download/";
    const data = await fetcher(downloadUrl, filepath, "POST-NO-STRING", true, {
      Accept: "application/pdf",
    });

    const bufferData = await data.arrayBuffer();

    const blob = new Blob([bufferData], { type: "application/pdf" });
    saveAs(blob, name);
  };

  const hideDownloadingToaster = () => {
    setDownloadingMessage("Uploaded");
  };

  const showDownloadingToaster = () => {
    setDownloadingMessage("Uploading");
  };

  const handleDeleteClicked = async (
    participantId: string,
    documentId: string
  ) => {
    await softDeleteParticipantDocument({
      participant_id: participantId,
      document_id: documentId,
    });

    const newUploadsFiltered = newUploads.filter(
      (document: ParticipantDocument) => document.id !== documentId
    );

    setNewUploads([...newUploadsFiltered]);
  };

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
    caseManagerName: string
  ) => {
    if (event && event.target && event.target.files) {
    } else {
      return;
    }

    showDownloadingToaster();

    const file = event.target.files[0];

    const { type } = file;

    const formData = new FormData();

    formData.append("data", file);
    formData.append("mime_type", type);
    formData.append("participant_id", id);
    formData.append("case_manager_name", caseManagerName);

    const uploadUrl = "/documents/upload/participant-document";

    const response = await fetcher(uploadUrl, formData, "FORM", true, {
      "Content-Type": "multipart/form-data",
    });

    const data = (await response.json()) as ParticipantDocument;

    const allUploads = [data, ...newUploads];

    setNewUploads(allUploads);

    hideDownloadingToaster();
  };

  return (
    <QueryRenderer
      query={participantDocumentsQuery}
      variables={{ id }}
      SuccessComponent={(props: ParticipantDocumentsQueryResult) => {
        const participant = idx(
          props,
          (_) => _.participants.participant[0]
        ) as Participant;

        const caseManager =
          idx(props, (_) => _.me.case_manager) ||
          ({ name: { first: "", last: "" } } as CaseManager);

        const {
          name: { first, last },
        } = caseManager;

        const fullName = `${first} ${last}`;

        const { documents, id: participantId } = participant;
        const unwrappedDocuments = safelyUnwrapArrayWithPossibleNullOrUndefined(
          documents ? documents : []
        );

        let myInput: any;

        return (
          <div>
            <input
              type="file"
              onChange={(event) =>
                handleFileInputChange(event, participantId, fullName)
              }
              style={{ display: "none" }}
              ref={(fileInput) => (myInput = fileInput)}
            />
            <div style={{ margin: 10 }}>
              <Button
                onClick={() => myInput.click()}
                type="primary"
                icon="upload"
              >
                Upload new file
              </Button>
            </div>
            <ul>
              {newUploads.map((participantDocument: ParticipantDocument) => {
                const {
                  id: documentId,
                  filepath,
                  name,
                  created_at: createdAt,
                  case_manager_name: uploadedBy,
                } = participantDocument;
                return (
                  <ParticipantDocumentContainer
                    key={filepath}
                    animateOnAppearance={false}
                    filepath={filepath}
                    name={name}
                    createdAt={createdAt}
                    downloadHandler={handleDownloadClicked}
                    deletionHandler={handleDeleteClicked}
                    uploadedBy={uploadedBy}
                    documentId={documentId}
                    participantId={participantId}
                  />
                );
              })}
              {unwrappedDocuments.map(
                (participantDocument: ParticipantDocument) => {
                  const {
                    id: documentId,
                    filepath,
                    name,
                    created_at: createdAt,
                    case_manager_name: uploadedBy,
                  } = participantDocument;
                  return (
                    <ParticipantDocumentContainer
                      key={filepath}
                      filepath={filepath}
                      name={name}
                      createdAt={createdAt}
                      downloadHandler={handleDownloadClicked}
                      deletionHandler={handleDeleteClicked}
                      uploadedBy={uploadedBy}
                      documentId={documentId}
                      participantId={participantId}
                    />
                  );
                }
              )}
              {unwrappedDocuments.length === 0 && newUploads.length === 0 && (
                <p className="no-documents-p">
                  No documents currently uploaded for this client.
                </p>
              )}
            </ul>
            {downloadingMessage === "Uploading" ? (
              <div
                className="status-message saving"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <span className="statusSpace" style={{ marginRight: 10 }}>
                  {downloadingMessage}
                </span>
                <Loader />
              </div>
            ) : null}
          </div>
        );
      }}
    />
  );
}
