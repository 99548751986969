import React from "react";
import AddAssessmentForm from "./AddAssessmentResultForm";
import AddAssessmentResultModalWrapper, {
  AddAssessmentResultFormData,
} from "./AddAssessmentResultModalWrapper";
import { ModalProps } from "../ModalWrapper";

export type AddEventModalProps = ModalProps<AddAssessmentResultFormData>;

export default function ({
  callBackForAPI,
  operation,
  type,
  disableButton,
  buttonComponent,
  updateObject,
  ...formOptions
}: AddEventModalProps) {
  return (
    <AddAssessmentResultModalWrapper
      callBackForAPI={callBackForAPI}
      type={type}
      disableButton={disableButton}
      buttonComponent={buttonComponent}
      operation={operation}
      updateObject={updateObject}
    >
      {(data: any) => (
        <AddAssessmentForm
          {...formOptions}
          {...data}
          updateObject={updateObject}
        />
      )}
    </AddAssessmentResultModalWrapper>
  );
}
