/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type MutationResult = "failure" | "skipped" | "success" | "%future added value";
export type groupClassesAddGroupClassMutationVariables = {
    readonly start_date: string;
    readonly start_time: string;
    readonly end_date: string;
    readonly days_of_week: ReadonlyArray<string | null>;
    readonly participant_ids: ReadonlyArray<string | null>;
    readonly activity_id: string;
    readonly service_provider_id: string;
    readonly exclude_dates?: ReadonlyArray<string> | null;
    readonly group_class_id?: string | null;
};
export type groupClassesAddGroupClassMutationResponse = {
    readonly addGroupClass: ({
        readonly result: MutationResult;
        readonly description: string | null;
        readonly group_class: ({
            readonly id: string;
            readonly participants: ReadonlyArray<{
                readonly name: {
                    readonly first: string;
                    readonly last: string | null;
                };
            }>;
            readonly start_date: string;
            readonly end_date: string;
            readonly exclude_dates: ReadonlyArray<string>;
            readonly service_provider: {
                readonly id: string | null;
                readonly title: string | null;
            };
            readonly activity: {
                readonly id: string;
                readonly title: {
                    readonly en: string | null;
                };
            };
            readonly days_of_week: ReadonlyArray<string>;
        }) | null;
    }) | null;
};
export type groupClassesAddGroupClassMutation = {
    readonly response: groupClassesAddGroupClassMutationResponse;
    readonly variables: groupClassesAddGroupClassMutationVariables;
};



/*
mutation groupClassesAddGroupClassMutation(
  $start_date: String!
  $start_time: String!
  $end_date: String!
  $days_of_week: [String]!
  $participant_ids: [String]!
  $activity_id: String!
  $service_provider_id: String!
  $exclude_dates: [String!]
  $group_class_id: String
) {
  addGroupClass(start_date: $start_date, start_time: $start_time, end_date: $end_date, days_of_week: $days_of_week, participant_ids: $participant_ids, activity_id: $activity_id, service_provider_id: $service_provider_id, exclude_dates: $exclude_dates, group_class_id: $group_class_id) {
    result
    description
    group_class {
      id
      participants {
        name {
          first
          last
        }
        id
      }
      start_date
      end_date
      exclude_dates
      service_provider {
        id
        title
      }
      activity {
        id
        title {
          en
        }
      }
      days_of_week
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "start_date",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "start_time",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "end_date",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "days_of_week",
            "type": "[String]!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "participant_ids",
            "type": "[String]!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "activity_id",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "service_provider_id",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "exclude_dates",
            "type": "[String!]",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "group_class_id",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "activity_id",
            "variableName": "activity_id"
        } as any),
        ({
            "kind": "Variable",
            "name": "days_of_week",
            "variableName": "days_of_week"
        } as any),
        ({
            "kind": "Variable",
            "name": "end_date",
            "variableName": "end_date"
        } as any),
        ({
            "kind": "Variable",
            "name": "exclude_dates",
            "variableName": "exclude_dates"
        } as any),
        ({
            "kind": "Variable",
            "name": "group_class_id",
            "variableName": "group_class_id"
        } as any),
        ({
            "kind": "Variable",
            "name": "participant_ids",
            "variableName": "participant_ids"
        } as any),
        ({
            "kind": "Variable",
            "name": "service_provider_id",
            "variableName": "service_provider_id"
        } as any),
        ({
            "kind": "Variable",
            "name": "start_date",
            "variableName": "start_date"
        } as any),
        ({
            "kind": "Variable",
            "name": "start_time",
            "variableName": "start_time"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "result",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "description",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "name",
        "storageKey": null,
        "args": null,
        "concreteType": "PersonName",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "first",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "last",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "start_date",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "end_date",
        "args": null,
        "storageKey": null
    } as any), v8 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "exclude_dates",
        "args": null,
        "storageKey": null
    } as any), v9 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "service_provider",
        "storageKey": null,
        "args": null,
        "concreteType": "ServiceProvider",
        "plural": false,
        "selections": [
            (v4 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "title",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v10 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "activity",
        "storageKey": null,
        "args": null,
        "concreteType": "Activity",
        "plural": false,
        "selections": [
            (v4 /*: any*/),
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "title",
                "storageKey": null,
                "args": null,
                "concreteType": "i18nText",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "en",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    } as any), v11 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "days_of_week",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "groupClassesAddGroupClassMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "addGroupClass",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "NewGroupClassResult",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "group_class",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "GroupClass",
                            "plural": false,
                            "selections": [
                                (v4 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "participants",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Participant",
                                    "plural": true,
                                    "selections": [
                                        (v5 /*: any*/)
                                    ]
                                },
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                (v9 /*: any*/),
                                (v10 /*: any*/),
                                (v11 /*: any*/)
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "groupClassesAddGroupClassMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "addGroupClass",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "NewGroupClassResult",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "group_class",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "GroupClass",
                            "plural": false,
                            "selections": [
                                (v4 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "participants",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Participant",
                                    "plural": true,
                                    "selections": [
                                        (v5 /*: any*/),
                                        (v4 /*: any*/)
                                    ]
                                },
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                (v9 /*: any*/),
                                (v10 /*: any*/),
                                (v11 /*: any*/)
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "mutation",
            "name": "groupClassesAddGroupClassMutation",
            "id": null,
            "text": "mutation groupClassesAddGroupClassMutation(\n  $start_date: String!\n  $start_time: String!\n  $end_date: String!\n  $days_of_week: [String]!\n  $participant_ids: [String]!\n  $activity_id: String!\n  $service_provider_id: String!\n  $exclude_dates: [String!]\n  $group_class_id: String\n) {\n  addGroupClass(start_date: $start_date, start_time: $start_time, end_date: $end_date, days_of_week: $days_of_week, participant_ids: $participant_ids, activity_id: $activity_id, service_provider_id: $service_provider_id, exclude_dates: $exclude_dates, group_class_id: $group_class_id) {\n    result\n    description\n    group_class {\n      id\n      participants {\n        name {\n          first\n          last\n        }\n        id\n      }\n      start_date\n      end_date\n      exclude_dates\n      service_provider {\n        id\n        title\n      }\n      activity {\n        id\n        title {\n          en\n        }\n      }\n      days_of_week\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '17da3d3f67dbd190058aeaf054f8776d';
export default node;
