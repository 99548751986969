/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type AddParticipantQueryWrapperQueryVariables = {};
export type AddParticipantQueryWrapperQueryResponse = {
    readonly application: ({
        readonly risk_levels: ReadonlyArray<({
            readonly key: string;
            readonly value: string | null;
        }) | null> | null;
        readonly compliance_options: ReadonlyArray<({
            readonly key: string;
            readonly value: string | null;
        }) | null> | null;
        readonly assessment_tools: ReadonlyArray<({
            readonly id: string;
            readonly name: string;
            readonly scale: ReadonlyArray<({
                readonly key: string;
                readonly value: string | null;
            }) | null>;
        }) | null> | null;
        readonly languages: ReadonlyArray<({
            readonly key: string;
            readonly value: string | null;
        }) | null> | null;
    }) | null;
    readonly case_managers: ({
        readonly case_manager: ReadonlyArray<({
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
            readonly phone: ({
                readonly mobile: string | null;
            }) | null;
            readonly email: string | null;
        }) | null> | null;
    }) | null;
};
export type AddParticipantQueryWrapperQuery = {
    readonly response: AddParticipantQueryWrapperQueryResponse;
    readonly variables: AddParticipantQueryWrapperQueryVariables;
};



/*
query AddParticipantQueryWrapperQuery {
  application {
    risk_levels {
      key
      value
    }
    compliance_options {
      key
      value
    }
    assessment_tools {
      id
      name
      scale {
        key
        value
      }
    }
    languages {
      key
      value
    }
    id
  }
  case_managers {
    case_manager {
      id
      name {
        first
        last
      }
      phone {
        mobile
      }
      email
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "key",
            "args": null,
            "storageKey": null
        } as any),
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "value",
            "args": null,
            "storageKey": null
        } as any)
    ], v1 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "risk_levels",
        "storageKey": null,
        "args": null,
        "concreteType": "KeyedString",
        "plural": true,
        "selections": (v0 /*: any*/)
    } as any), v2 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "compliance_options",
        "storageKey": null,
        "args": null,
        "concreteType": "KeyedString",
        "plural": true,
        "selections": (v0 /*: any*/)
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "assessment_tools",
        "storageKey": null,
        "args": null,
        "concreteType": "AssessmentTool",
        "plural": true,
        "selections": [
            (v3 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "scale",
                "storageKey": null,
                "args": null,
                "concreteType": "KeyedString",
                "plural": true,
                "selections": (v0 /*: any*/)
            }
        ]
    } as any), v5 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "languages",
        "storageKey": null,
        "args": null,
        "concreteType": "KeyedString",
        "plural": true,
        "selections": (v0 /*: any*/)
    } as any), v6 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "case_managers",
        "storageKey": null,
        "args": null,
        "concreteType": "Pagination",
        "plural": false,
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "case_manager",
                "storageKey": null,
                "args": null,
                "concreteType": "CaseManager",
                "plural": true,
                "selections": [
                    (v3 /*: any*/),
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "name",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PersonName",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "first",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "last",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "phone",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PersonPhone",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "mobile",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "email",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "AddParticipantQueryWrapperQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "application",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Application",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/)
                    ]
                },
                (v6 /*: any*/)
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "AddParticipantQueryWrapperQuery",
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "application",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Application",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v3 /*: any*/)
                    ]
                },
                (v6 /*: any*/)
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "AddParticipantQueryWrapperQuery",
            "id": null,
            "text": "query AddParticipantQueryWrapperQuery {\n  application {\n    risk_levels {\n      key\n      value\n    }\n    compliance_options {\n      key\n      value\n    }\n    assessment_tools {\n      id\n      name\n      scale {\n        key\n        value\n      }\n    }\n    languages {\n      key\n      value\n    }\n    id\n  }\n  case_managers {\n    case_manager {\n      id\n      name {\n        first\n        last\n      }\n      phone {\n        mobile\n      }\n      email\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '4a0a6d439785e9559460b4939ebd385e';
export default node;
