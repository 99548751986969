import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "../shared/lib/graphql/commitMutation";
import { RecordSourceSelectorProxy, RecordProxy } from "relay-runtime";
import { checkMutationReturn } from "./util";
const uuid = require("uuid");

interface AddGoalsGqlArguments {
  participant_id: string;
  label: string;
}

interface RemoveGoalGqlArguments {
  participant_id: string;
  goal_id: string;
}

interface UpdateGoalGqlArguments extends RemoveGoalGqlArguments {
  is_completed: boolean;
  label: string;
}

export const addGoal = async (variables: AddGoalsGqlArguments) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation goalsAddGoalMutation(
          $participant_id: String!
          $label: String!
        ) {
          addParticipantGoal(participant_id: $participant_id, label: $label) {
            goal {
              id
              label
              created_at
              completed_at
            }
            result
            description
            errors
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreAddGoal(
          store,
          "addParticipantGoal",
          variables.participant_id
        );
      },
      optimisticUpdater: (store) =>
        optimisticUpdateRelayStoreAddGoal(
          store,
          "addParticipantGoal",
          variables
        ),
    },
    "Error while adding a goal"
  );
};

export const removeGoal = async (variables: RemoveGoalGqlArguments) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation goalsRemoveParticipantGoalMutation(
          $participant_id: String!
          $goal_id: String!
        ) {
          removeParticipantGoal(
            participant_id: $participant_id
            goal_id: $goal_id
          ) {
            goal {
              id
              label
              created_at
              completed_at
            }
            result
            description
            errors
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreRemoveGoal(
          store,
          "removeParticipantGoal",
          variables.participant_id
        );
      },
      optimisticUpdater: (store) =>
        optimisticUpdateRelayStoreRemoveGoal(
          store,
          "removeParticipantGoal",
          variables
        ),
    },
    "Error while removing a goal"
  );
};

export const updateGoal = async (variables: UpdateGoalGqlArguments) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation goalsMarkParticipantGoalCompleteMutation(
          $participant_id: String!
          $goal_id: String!
          $is_completed: Boolean
          $label: String
        ) {
          updateParticipantGoal(
            participant_id: $participant_id
            goal_id: $goal_id
            is_completed: $is_completed
            label: $label
          ) {
            goal {
              id
              label
              created_at
              completed_at
            }
            result
          }
        }
      `,
      variables,
    },
    "Error while updating a goal."
  );
};

const updateRelayStoreAddGoal = (
  store: RecordSourceSelectorProxy,
  rootFieldName: string,
  participant_id: string
) => {
  const payload = checkMutationReturn(store, rootFieldName);

  if (!payload) return;

  const newGoal = payload.getLinkedRecord("goal");

  const participant = store.get(participant_id);

  if (!participant) {
    console.error(
      `Error while updating the store after ${rootFieldName}.\n` +
        " Can't find the participant in the store."
    );
    return;
  }

  const goalsList = participant.getLinkedRecords("goals");

  //add new obligation to front of obligationsList
  const combinedGoals = [newGoal].concat(goalsList);

  //set the new Obligation
  participant.setLinkedRecords(combinedGoals, "goals");
};

const optimisticUpdateRelayStoreAddGoal = (
  store: RecordSourceSelectorProxy,
  rootFieldName: string,
  variables: AddGoalsGqlArguments
) => {
  const newGoal = store.create(uuid(), rootFieldName);

  newGoal.setValue(variables.label, "label");

  const participant = store.get(variables.participant_id);

  if (!participant) {
    console.error(
      `Error while updating the store after ${rootFieldName}.\n` +
        " Can't find the participant in the store."
    );
    return;
  }

  const goalsList = participant.getLinkedRecords("goals");

  //add new obligation to front of obligationsList
  const combinedGoals = [newGoal as RecordProxy | null].concat(goalsList);

  //set the new Obligation
  participant.setLinkedRecords(combinedGoals, "goals");
};

const updateRelayStoreRemoveGoal = (
  store: RecordSourceSelectorProxy,
  rootFieldName: string,
  participant_id: string
) => {
  const payload = checkMutationReturn(store, rootFieldName);

  if (!payload) return;

  const deletedGoal = payload.getLinkedRecord("goal");

  if (!deletedGoal) {
    console.error(`Error returned from API during ${rootFieldName}. \n`);
    return;
  }

  const deletedGoalId = deletedGoal.getValue("id");

  const participant = store.get(participant_id);

  if (!participant) {
    console.error(
      `Error while updating the store after ${rootFieldName}.\n` +
        " Can't find the participant in the store."
    );
    return;
  }

  const goalsList = participant.getLinkedRecords("goals");

  if (!goalsList) {
    console.error(
      `Error while updating the store after ${rootFieldName}.\n Can't goal ${deletedGoalId} doesn't exist on participant ${participant_id}`
    );
    return;
  }

  const newGoalList = goalsList.filter(
    (goal) => goal && goal.getValue("id") !== deletedGoalId
  );

  participant.setLinkedRecords(newGoalList, "goals");
};

const optimisticUpdateRelayStoreRemoveGoal = (
  store: RecordSourceSelectorProxy,
  rootFieldName: string,
  variables: RemoveGoalGqlArguments
) => {
  const participant = store.get(variables.participant_id);

  if (!participant) {
    console.error(
      `Error while updating the store after ${rootFieldName}.\n` +
        " Can't find the participant in the store."
    );
    return;
  }

  const goalsList = participant.getLinkedRecords("goals");

  if (!goalsList) {
    console.error(
      `Error while updating the store after ${rootFieldName}.\n Can't goal ${variables.goal_id} doesn't exist on participant ${variables.participant_id}`
    );
    return;
  }

  const newGoalList = goalsList.filter(
    (goal) => goal && goal.getValue("id") !== variables.goal_id
  );

  participant.setLinkedRecords(newGoalList, "goals");
};
