import { ErrorInfo } from "react";
import * as Sentry from "@sentry/browser";

import { isStaging, skipIsDev } from "../util";

import { WebApps, APP_CONFIGS } from "./configs";

export interface KeyedAny {
  [key: string]: any;
}

export const sendErrorInfoToSentry = (
  error: Error,
  errorInfo?: ErrorInfo & KeyedAny
) => {
  Sentry.withScope((scope) => {
    if (errorInfo) {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
    }
    Sentry.captureException(error);
  });
};

export const initSentry = (app: WebApps) => {
  if (skipIsDev()) {
    console.log("not initializing Sentry because this is a dev environment");
    return;
  }

  if (isStaging()) {
    console.log(
      "not initializing Sentry because this is a staging environment"
    );
    return;
  }

  const config = APP_CONFIGS[app];

  if (!config) {
    console.error(`Unknown app "${app}"`);
    return;
  } else if (!config.sentry) {
    console.warn(`No Sentry configuration for app "${app}".`);
    return;
  }

  const { sentry } = config;
  (sentry as any).release = config.release;

  Sentry.init(sentry);

  wrapConsole(console, "error", function consolerErrorCb(message: string) {
    sendErrorInfoToSentry(new Error(message));
  });
};

const wrapConsole = (
  console: Console,
  level: string,
  callback: (message: string) => void
) => {
  const originalConsoleLevel = (console as any)[level];
  const originalConsole = console;

  if (!originalConsoleLevel) return;

  (console as any)[level] = function consoleWrapper() {
    const args = [].slice.call(arguments);
    const message = (args || [])
      .map((arg: any) => {
        try {
          return String(arg);
        } catch {
          return null;
        }
      })
      .filter((x: any) => !!x)
      .join(" ");

    if (callback) callback(message);

    Function.prototype.apply.call(originalConsoleLevel, originalConsole, args);
  };
};
