import React from "react";
import moment from "moment";
import { Maybe } from "../../graphql-types";
import { Button } from "antd";
import "./ParticipantDocumentContainer.scss";
import { ScarePrompt } from "../../shared/components/elements/ScarePrompt";

interface ParticipantDocumentProps {
  downloadHandler: (filepath: string, name: string) => void;
  deletionHandler: (participantId: string, documentId: string) => void;
  filepath: string;
  name: string;
  createdAt: string;
  uploadedBy: Maybe<string>;
  participantId: string;
  documentId: string;
  animateOnAppearance?: boolean;
}

export default function ParticipantDocumentContainer(
  props: ParticipantDocumentProps
) {
  const {
    downloadHandler,
    filepath,
    name,
    createdAt,
    uploadedBy,
    deletionHandler,
    documentId,
    participantId,
    animateOnAppearance = false,
  } = props;
  return (
    <div
      className={
        animateOnAppearance
          ? "participant-document-container participant-document-container-appear"
          : "participant-document-container"
      }
    >
      <div>
        <p className="participant-document-title">{name}</p>
        <p className="participant-document-info">
          Uploaded At:
          {moment(createdAt).format("MM/DD/YYYY h:mm a")}
        </p>
        <p className="participant-document-info">
          Uploaded By: {uploadedBy ? uploadedBy : "unknown"}
        </p>
      </div>
      <div className="participant-document-button-column">
        <div className="participant-document-button-column">
          <Button
            icon="download"
            onClick={() => downloadHandler(filepath, name)}
            type={"primary"}
          >
            Download
          </Button>
          <ScarePrompt
            warningText={`Are you sure you want to delete this document? This cannot be undone.`}
            itemName="document"
            button={<Button type={"danger"}>Delete</Button>}
            onYes={() => deletionHandler(participantId, documentId)}
          />
        </div>
      </div>
    </div>
  );
}
