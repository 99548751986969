import graphql from "babel-plugin-relay/macro";

import {
  BroadcastMessage as ftBroadcastMessage,
  BroadcastMessageRecipient,
  CaseManager,
  KeyedString,
  Omit,
} from "../../shared/lib/graphql";
import { Activity } from "../../../../shared/src/lib/graphql";

export type BroadcastMessageCaseManager = Pick<CaseManager, "name">;

export type BroadcaseMessageCaseManagerRecipientTarget = Pick<
  CaseManager,
  "id" | "name"
>;

// A BroadcastMessage but CaseManager is limited
export type BroadcastMessage = Omit<
  ftBroadcastMessage,
  "case_manager" | "case_manager_id"
> & { case_manager: BroadcastMessageCaseManager };

export interface BroadcastMessageRecipientDisplay {
  key: string;
  value: string;
  query: BroadcastMessageRecipient;
}

export interface BroadcastMessagesContainerResult {
  application: {
    risk_levels: KeyedString[];
  };
  activities: {
    activity: Activity[];
  };
  case_managers: {
    case_manager: Array<BroadcaseMessageCaseManagerRecipientTarget>;
  };
  broadcast_messages: {
    broadcast_message: BroadcastMessage[];
  };
  broadcast_messages_desc: {
    broadcast_message: BroadcastMessage[];
  };
}

export const BroadcastMessagesGql = graphql`
  query BroadcastMessagesGqlQuery {
    application {
      risk_levels {
        key
        value
      }
    }
    activities {
      activity {
        id
        type
        title {
          en
        }
      }
    }
    broadcast_messages_desc: broadcast_messages(order_by: "deployed_at_desc") {
      broadcast_message {
        id
        created_at
        deployed_at
        deployed
        text
        to {
          queryType
          arg
        }
        case_manager {
          name {
            first
            last
          }
        }
        scheduled
        time_to_deploy
      }
    }
    broadcast_messages {
      broadcast_message {
        id
        created_at
        deployed_at
        deployed
        text
        to {
          queryType
          arg
        }
        case_manager {
          name {
            first
            last
          }
        }
        scheduled
        time_to_deploy
      }
    }
    case_managers {
      case_manager {
        id
        name {
          first
          last
        }
      }
    }
  }
`;
