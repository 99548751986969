import React, { useState } from "react";
import {
  Case,
  Maybe,
  MutationUpdateCaseArgs,
  Participant,
} from "../../graphql-types";
import moment, { Moment } from "moment";
import "./ParticipantCaseContainer.scss";
import { CASE_FORM_DETAILS_DATA } from "./CaseFormData";
import {
  ParticipantFormCellData,
  hydrateParticipantFormDetailsData,
} from "../participant-profile/participantDetailsFormData";
import { Columns } from "react-bulma-components";
import { ParticipantFormCell } from "../participant-profile/ParticipantFormCell";
import { updateCase } from "../../actions/case";
import { safelyUnwrapArrayWithPossibleNullOrUndefined } from "../../utils/safeUnwrap";

interface CaseContainerProps {
  case: Case;
  participant: Participant;
}

export function CaseHeader(props: CaseContainerProps) {
  const { case_number: caseNumber, created_at: createdAt } = props.case;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <p className="participant-case-title">
        <strong>Case Number: </strong>
        {caseNumber}
      </p>

      <p className="participant-case-title">
        <strong>Created on: </strong>
        {moment(createdAt).format("MM/DD/YYYY")}
      </p>
    </div>
  );
}

export function CaseForm(props: CaseContainerProps) {
  const [selectedEditField, setSelectedEditField] = useState("");

  const onEdit = (fieldname: string) => {
    setSelectedEditField(fieldname);
  };

  const caseFields = extractFieldsFromCase(props.case);

  const caseFormDetailsData = hydrateParticipantFormDetailsData(
    caseFields,
    CASE_FORM_DETAILS_DATA
  );

  const onSave = async (
    fieldName: string,
    data: string | { [key: string]: boolean },
    participantCase: Case
  ) => {
    const participantBaseVariables = getParticipantCaseBaseVariables(
      participantCase
    );

    const finalFields =
      typeof data !== "object"
        ? { ...participantBaseVariables, [fieldName]: data }
        : {
            ...participantBaseVariables,
            [fieldName]: Object.keys(data)
              .filter((id) => data[id] === true)
              .map((id) => {
                return { id };
              }),
          };

    await updateCase({
      ...finalFields,
    });
  };

  return (
    <>
      {caseFormDetailsData[0].map(
        (participantFormRow: ParticipantFormCellData[], index) => (
          <Columns key={`caseFormRow_${index}`}>
            {participantFormRow.map(
              (participantFormCell: ParticipantFormCellData, innderIndex) => (
                <ParticipantFormCell
                  key={`participantFormCellData_row${index}_column_${innderIndex}`}
                  size={12 / participantFormRow.length}
                  value={participantFormCell.value}
                  label={participantFormCell.label}
                  type={participantFormCell.type}
                  fieldName={participantFormCell.fieldName}
                  options={participantFormCell.options}
                  onSave={async (
                    fieldName: string,
                    data: string | { [key: string]: boolean }
                  ) => await onSave(fieldName, data, props.case)}
                  onEditCallback={onEdit}
                  selectedEditField={selectedEditField}
                />
              )
            )}
          </Columns>
        )
      )}
    </>
  );
}

function extractFieldsFromCase(
  participantCase: Case
): {
  [key: string]: Maybe<string | Moment | { [key: string]: any } | boolean>;
} {
  const courtsObject = getIdFieldsFromParticipantCase(
    participantCase,
    "courts"
  );

  const groupMeetingsObject = getIdFieldsFromParticipantCase(
    participantCase,
    "group_meetings"
  );

  const treatmentTypesObject = getIdFieldsFromParticipantCase(
    participantCase,
    "treatment_types"
  );

  const primaryTreatmentFacilitiesObject = getIdFieldsFromParticipantCase(
    participantCase,
    "primary_treatment_facilities"
  );

  return {
    supervision_start_date: participantCase.supervision_start_date
      ? moment(participantCase.supervision_start_date)
      : null,
    supervision_end_date: participantCase.supervision_end_date
      ? moment(participantCase.supervision_end_date)
      : null,
    case_type: participantCase.case_type,
    program_result: participantCase.program_result,
    did_recidivate: participantCase.did_recidivate,
    housing: participantCase.housing ? participantCase.housing.status : null,
    employment: participantCase.employment
      ? participantCase.employment.status
      : null,
    education: participantCase.education
      ? participantCase.education.status
      : null,
    transportation: participantCase.transportation
      ? participantCase.transportation.status
      : null,
    medicated: participantCase.medicated,
    recidivism_reason: participantCase.recidivism_reason || "unknown",
    courts: {
      ...courtsObject,
    },
    group_meetings: {
      ...groupMeetingsObject,
    },
    treatment_types: {
      ...treatmentTypesObject,
    },
    primary_treatment_facilities: {
      ...primaryTreatmentFacilitiesObject,
    },
    risk_level: participantCase.risk_level || null,
    hand_off_to_probation: participantCase.hand_off_to_probation || null,
    absconded: participantCase.absconded || null,
    is_emh: participantCase.is_emh || null,
    risk_score: participantCase.risk_score || null,
  };
}

export function getParticipantCaseBaseVariables(caseData: Case) {
  return {
    case_id: caseData.id,
    supervision_start_date: caseData.supervision_start_date || "",
    supervision_end_date: caseData.supervision_end_date || "",
    case_type: caseData.case_type || "",
    program_result: caseData.program_result || "",
    did_recidivate: caseData.did_recidivate || "unknown",
    housing:
      caseData.housing && caseData.housing.status
        ? caseData.housing.status
        : "unknown",
    education:
      caseData.education && caseData.education.status
        ? caseData.education.status
        : "unknown",
    transportation:
      caseData.transportation && caseData.transportation.status
        ? caseData.transportation.status
        : "unknown",
    employment:
      caseData.employment && caseData.employment.status
        ? caseData.employment.status
        : "unknown",
    medicated: caseData.medicated || "unknown",
    courts: caseData.courts || [],
    group_meetings: caseData.group_meetings || [],
    treatment_types: caseData.treatment_types || [],
    recidivism_reason: caseData.recidivism_reason || "unknown",
    primary_treatment_facilities: caseData.primary_treatment_facilities || [],
    risk_level: caseData.risk_level || null,
    hand_off_to_probation: caseData.hand_off_to_probation || null,
    absconded: caseData.absconded || null,
    is_emh: caseData.is_emh || null,
    risk_score: caseData.risk_score || null,
  } as MutationUpdateCaseArgs;
}

function getIdFieldsFromParticipantCase(
  participantCase: Case,
  fieldName:
    | "courts"
    | "treatment_types"
    | "group_meetings"
    | "primary_treatment_facilities"
) {
  const fieldIdsUnwrapped = safelyUnwrapArrayWithPossibleNullOrUndefined(
    participantCase[fieldName] || []
  );
  return fieldIdsUnwrapped.reduce((acc, field) => {
    const { id } = field;
    acc[id] = true;
    return acc;
  }, {} as { [key: string]: boolean });
}
