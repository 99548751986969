import { MutationSoftDeleteParticipantDocumentArgs } from "../graphql-types";
import { commitMutation } from "../shared/lib/graphql/commitMutation";
import graphql from "babel-plugin-relay/macro";
import { RecordSourceSelectorProxy } from "relay-runtime";
import { checkMutationReturn } from "./util";

export const softDeleteParticipantDocument = async (
  variables: MutationSoftDeleteParticipantDocumentArgs
) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation participantDocumentsSoftDeleteParticipantDocumentMutation(
          $participant_id: String!
          $document_id: String!
        ) {
          softDeleteParticipantDocument(
            participant_id: $participant_id
            document_id: $document_id
          ) {
            participant {
              id
            }
            document {
              id
            }
            result
            description
            errors
          }
        }
      `,
      variables,
      updater: (store) => {
        updateParticipantStoreRemoveDocument(
          store,
          "softDeleteParticipantDocument",
          variables.participant_id
        );
      },
    },
    "Error while adding a participant document"
  );
};

const updateParticipantStoreRemoveDocument = (
  store: RecordSourceSelectorProxy,
  rootFieldName: string,
  participant_id: string
) => {
  const payload = checkMutationReturn(store, rootFieldName);

  if (!payload) return;

  const deletedDocument = payload.getLinkedRecord("document");

  if (!deletedDocument) {
    console.error(`Error returned from API during ${rootFieldName}. \n`);
    return;
  }

  const deletedDocumentId = deletedDocument.getValue("id");

  const participant = store.get(participant_id);

  if (!participant) {
    console.error(
      `Error while updating the store after ${rootFieldName}.\n` +
        " Can't find the participant in the store."
    );
    return;
  }

  const documentList = participant.getLinkedRecords("documents");

  if (!documentList) {
    console.error(
      `Error while updating the store after ${rootFieldName}.\n Can't goal ${deletedDocumentId} doesn't exist on participant ${participant_id}`
    );
    return;
  }

  const newGoalList = documentList.filter(
    (document) => document && document.getValue("id") !== deletedDocumentId
  );

  participant.setLinkedRecords(newGoalList, "documents");
};
