import graphql from "babel-plugin-relay/macro";

import {
  Activity,
  ActivityMeta,
  Event,
  TimelineEntry,
} from "../../lib/graphql";

export type TimelineEventType = Pick<Event, "id" | "attended"> & {
  attended: boolean;
  activity: Activity;
};

export type TimelineTimelineEntryType = Pick<
  TimelineEntry,
  | "id"
  | "effective_timestamp"
  | "details"
  | "event"
  | "note"
  | "notification"
  | "participant"
  | "type"
> & { event: TimelineEventType };

export interface TimelineGraphqlResult {
  application: {
    activity_information: Array<ActivityMeta>;
  };
  timeline: {
    timeline_entry: Array<TimelineTimelineEntryType>;
  };
}

export const TimelineGraphql = graphql`
  query TimelineGqlQuery($id: String) {
    application {
      activity_information {
        key
        timeline_event_type
      }
    }
    timeline(participant: $id) {
      timeline_entry {
        id
        effective_timestamp
        type
        details
        participant {
          name {
            first
            last
          }
        }
        event {
          id
          date
          activity {
            id
            title {
              en
            }
            type
          }
          attended
          time_specified
        }
        notification {
          id
          body
          time_deployed
        }
      }
    }
  }
`;
