import React, { PureComponent } from "react";
import { ChatContainer } from "../chat/ChatContainer";
import { Columns } from "react-bulma-components";
import { NotesContainer } from "../participant-notes/NotesContainer";
import {
  UserConsumer,
  UserContextType,
} from "../../shared/lib/contexts/UserContext";
import { getFeatureFlags } from "../../featureFlags";

const { supportsNotes } = getFeatureFlags();

interface ChatPageProps {
  id: string;
}

export class ChatAndNotesPage extends PureComponent<ChatPageProps> {
  render() {
    const className = supportsNotes !== false ? "has-notes" : "no-notes";

    return (
      <Columns className={className}>
        <Columns.Column size={6} style={{ paddingRight: 40 }}>
          <UserConsumer>
            {(userContext: UserContextType) => {
              return (
                <ChatContainer
                  id={this.props.id}
                  groupId={""}
                  userId={userContext.user ? userContext.user.uid : ""}
                />
              );
            }}
          </UserConsumer>
        </Columns.Column>
        {supportsNotes !== false && (
          <Columns.Column size={6}>
            <NotesContainer id={this.props.id} />
          </Columns.Column>
        )}
      </Columns>
    );
  }
}
