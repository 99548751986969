/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type ChangeParticipantActiveStatusQueryWrapperQueryVariables = {};
export type ChangeParticipantActiveStatusQueryWrapperQueryResponse = {
    readonly application: ({
        readonly deactivate_reasons: ReadonlyArray<({
            readonly key: string;
            readonly value: string | null;
        }) | null> | null;
        readonly reactivate_reasons: ReadonlyArray<({
            readonly key: string;
            readonly value: string | null;
        }) | null> | null;
    }) | null;
};
export type ChangeParticipantActiveStatusQueryWrapperQuery = {
    readonly response: ChangeParticipantActiveStatusQueryWrapperQueryResponse;
    readonly variables: ChangeParticipantActiveStatusQueryWrapperQueryVariables;
};



/*
query ChangeParticipantActiveStatusQueryWrapperQuery {
  application {
    deactivate_reasons {
      key
      value
    }
    reactivate_reasons {
      key
      value
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "key",
            "args": null,
            "storageKey": null
        } as any),
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "value",
            "args": null,
            "storageKey": null
        } as any)
    ], v1 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "deactivate_reasons",
        "storageKey": null,
        "args": null,
        "concreteType": "KeyedString",
        "plural": true,
        "selections": (v0 /*: any*/)
    } as any), v2 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "reactivate_reasons",
        "storageKey": null,
        "args": null,
        "concreteType": "KeyedString",
        "plural": true,
        "selections": (v0 /*: any*/)
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "ChangeParticipantActiveStatusQueryWrapperQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "application",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Application",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "ChangeParticipantActiveStatusQueryWrapperQuery",
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "application",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Application",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "ChangeParticipantActiveStatusQueryWrapperQuery",
            "id": null,
            "text": "query ChangeParticipantActiveStatusQueryWrapperQuery {\n  application {\n    deactivate_reasons {\n      key\n      value\n    }\n    reactivate_reasons {\n      key\n      value\n    }\n    id\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '8e2c61a35e8f9a7446849cba7220efe4';
export default node;
