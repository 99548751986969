import * as React from "react";
import { pageview as trackGAPageview } from "react-ga";
import { windowIsLocal } from "../util";

export interface PageviewTrackerProps {
  pathname: string;
}

export class PageviewTracker extends React.PureComponent<PageviewTrackerProps> {
  componentDidMount() {
    this.pageview(this.props.pathname);
  }

  componentDidUpdate({ pathname }: PageviewTrackerProps) {
    if (this.props.pathname !== pathname) {
      this.pageview(this.props.pathname);
    }
  }

  private pageview = (pathname: string) => {
    if (!windowIsLocal()) trackGAPageview(pathname);
  };

  render() {
    return this.props.children || null;
  }
}
