import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "../shared/lib/graphql/commitMutation";
import { checkMutationReturn, getParticipant } from "./util";
import { RecordSourceSelectorProxy } from "relay-runtime";
import { ParticipantDetailsType } from "../components/screens/ParticipantDetailsQueryTypes";
import { ParticipantContact } from "../graphql-types";

export interface AddParticipantGqlArguments {
  first?: string;
  last?: string;
  mobile?: string;
  language?: string;
  assessment_score?: string;
  assessment_tool?: string;
  risk_level?: string;
  case_manager_id?: string;
  sms_enabled?: boolean;
  sms_disabled_by?: string;
  sms_consent?: boolean;
  compliance?: string;
  preferred_name?: string;
  supervision_begin_date?: string;
  supervision_end_date?: string;
  probation_number?: string;
}

export interface UpdateParticipantGqlArguments
  extends AddParticipantGqlArguments {
  id: string;
  recidivism_reason?: string;
  recidivism_date?: string;
  hispanic_or_latino?: boolean;
  race?: string;
  alcohol_monitoring?: boolean;
  gps_monitoring?: boolean;
  date_of_birth?: string;
  sex?: string;
  home_phone?: string;
  employer_name?: string;
  hair?: string;
  eye_color?: string;
  height?: string;
  weight?: string;
  person_address?: string;
  place_of_birth?: string;
  id_number?: string;
  id_type?: string;
  id_state?: string;
  victims?: ParticipantContact[];
  stay_away?: boolean;
  peaceful_contact?: boolean;
  case_number?: string;
  extra_equipment?: string;
  incident_notes?: string;
  offense_info?: string;
  record_source?: string;
  court_recommendation?: string;
  court_decision?: string;
  case_disposition?: string;
  equipment_returned?: string[];
  protective_order_notes?: string;
  probation_number?: string;
}

export interface ChangeParticipantActiveStatusGqlArguments {
  id: string;
  reason: string;
  note: string;
  is_active: boolean;
  supervision_begin_date: string;
  supervision_end_date: string;
}

export const addParticipant = async (variables: AddParticipantGqlArguments) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation participantsAddParticipantMutation(
          $first: String!
          $last: String
          $mobile: String
          $language: String
          $assessment_score: String
          $assessment_tool: String
          $risk_level: String
          $case_manager_id: String
          $preferred_name: String
          $compliance: String
          $supervision_begin_date: String
          $supervision_end_date: String
        ) {
          addParticipant(
            first: $first
            last: $last
            mobile: $mobile
            language: $language
            assessment_score: $assessment_score
            assessment_tool: $assessment_tool
            risk_level: $risk_level
            case_manager_id: $case_manager_id
            preferred_name: $preferred_name
            compliance: $compliance
            supervision_begin_date: $supervision_begin_date
            supervision_end_date: $supervision_end_date
          ) {
            participant {
              id
              name {
                first
                last
              }
              case_manager {
                id
                name {
                  first
                  last
                }
              }
              risk_level
              assessment {
                score
                tool
              }
              phone {
                mobile
                home
              }
              language
              status
              compliance
              events {
                id
                activity {
                  id
                  title {
                    en
                  }
                }
                date
              }
              preferred_name
              sms_enabled
              sms_disabled_by
              sms_consent
              sms_can_enable
              supervision_begin_date
              supervision_end_date
              auth_token
              created_at
              case_manager_id
              hispanic_or_latino
              race
              alcohol_monitoring
              gps_monitoring
              sex
              employer {
                name
              }
              hair
              eye_color
              weight
              height
              person_address
              place_of_birth
              person_id {
                number
                state
                type
              }
              victims {
                name {
                  first
                  last
                }
                phone
                date_of_birth
                address
                sex
              }
              stay_away
              peaceful_contact
              case_number
              extra_equipment
              incident_notes
              offense_info
              record_source
              court_recommendation
              court_decision
              case_disposition
              home_phone_human_readable
              cell_phone_human_readable
              equipment_returned
              protective_order_notes
              probation_number
            }
            result
            description
            errors
          }
        }
      `,
      variables,
    },
    "Error while adding a participant"
  );
};

export const updateParticipant = async (
  variables: UpdateParticipantGqlArguments,
  participant: ParticipantDetailsType
) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation participantsUpdateParticipantMutation(
          $id: String!
          $first: String
          $last: String
          $mobile: String
          $home_phone: String
          $language: String
          $assessment_score: String
          $assessment_tool: String
          $risk_level: String
          $case_manager_id: String
          $preferred_name: String
          $compliance: String
          $supervision_begin_date: String
          $supervision_end_date: String
          $hispanic_or_latino: Boolean
          $race: PersonRace
          $alcohol_monitoring: Boolean
          $gps_monitoring: Boolean
          $date_of_birth: String
          $sex: PersonSex
          $employer_name: String
          $hair: PersonHair
          $eye_color: PersonEyeColor
          $height: String
          $weight: String
          $person_address: String
          $place_of_birth: String
          $id_number: String
          $id_type: IdCardType
          $id_state: String
          $victims: [ParticipantVictimInput]
          $stay_away: Boolean
          $peaceful_contact: Boolean
          $case_number: String
          $extra_equipment: String
          $incident_notes: String
          $offense_info: String
          $base64_media: String
          $mime_type: String
          $record_source: String
          $court_recommendation: String
          $court_decision: String
          $case_disposition: String
          $equipment_returned: [String]
          $protective_order_notes: String
          $probation_number: String
        ) {
          updateParticipant(
            id: $id
            first: $first
            last: $last
            mobile: $mobile
            home_phone: $home_phone
            language: $language
            assessment_score: $assessment_score
            assessment_tool: $assessment_tool
            risk_level: $risk_level
            case_manager_id: $case_manager_id
            preferred_name: $preferred_name
            compliance: $compliance
            supervision_begin_date: $supervision_begin_date
            supervision_end_date: $supervision_end_date
            hispanic_or_latino: $hispanic_or_latino
            race: $race
            alcohol_monitoring: $alcohol_monitoring
            gps_monitoring: $gps_monitoring
            date_of_birth: $date_of_birth
            sex: $sex
            employer_name: $employer_name
            hair: $hair
            eye_color: $eye_color
            height: $height
            weight: $weight
            person_address: $person_address
            place_of_birth: $place_of_birth
            id_number: $id_number
            id_type: $id_type
            id_state: $id_state
            victims: $victims
            stay_away: $stay_away
            peaceful_contact: $peaceful_contact
            case_number: $case_number
            extra_equipment: $extra_equipment
            incident_notes: $incident_notes
            offense_info: $offense_info
            base64_media: $base64_media
            mime_type: $mime_type
            record_source: $record_source
            court_recommendation: $court_recommendation
            court_decision: $court_decision
            case_disposition: $case_disposition
            equipment_returned: $equipment_returned
            protective_order_notes: $protective_order_notes
            probation_number: $probation_number
          ) {
            participant {
              id
              name {
                first
                last
              }
              case_manager {
                id
                name {
                  first
                  last
                }
              }
              risk_level
              assessment {
                score
                tool
              }
              language
              phone {
                mobile
                home
              }
              status
              compliance
              events {
                id
                activity {
                  id
                  title {
                    en
                  }
                }
                date
              }
              preferred_name
              sms_enabled
              sms_disabled_by
              sms_consent
              sms_can_enable
              is_active
              supervision_begin_date
              supervision_end_date
              auth_token
              created_at
              case_manager_id
              hispanic_or_latino
              race
              alcohol_monitoring
              gps_monitoring
              date_of_birth
              sex
              employer {
                name
              }
              hair
              eye_color
              weight
              height
              person_address
              place_of_birth
              person_id {
                number
                type
                state
              }
              victims {
                name {
                  first
                  last
                }
                phone
                date_of_birth
                address
                sex
              }
              stay_away
              peaceful_contact
              case_number
              extra_equipment
              incident_notes
              offense_info
              profile_image {
                base64_media
                mime_type
              }
              record_source
              court_recommendation
              court_decision
              case_disposition
              home_phone_human_readable
              cell_phone_human_readable
              equipment_returned
              protective_order_notes
              probation_number
            }
            result
            description
            errors
          }
        }
      `,
      variables,
      updater: updateParticipantStore(variables.id),
      optimisticResponse: buildOptimisticResponse(participant, variables),
    },
    "Error while updating a participant"
  );
};

export const updateParticipantConsent = async (
  variables: UpdateParticipantGqlArguments
) => {
  // with any sms disabling, disabled by promise
  if (
    (typeof variables.sms_enabled !== "undefined" && !variables.sms_enabled) ||
    (typeof variables.sms_consent !== "undefined" && !variables.sms_consent)
  ) {
    variables.sms_disabled_by = "promise";
  }

  return commitMutation(
    {
      mutation: graphql`
        mutation participantsUpdateParticipantConsentMutation(
          $id: String!
          $sms_enabled: Boolean
          $sms_disabled_by: SmsMessageActor
          $sms_consent: Boolean
        ) {
          updateParticipant(
            id: $id
            sms_enabled: $sms_enabled
            sms_consent: $sms_consent
            sms_disabled_by: $sms_disabled_by
          ) {
            participant {
              id
              name {
                first
                last
              }
              case_manager {
                id
                name {
                  first
                  last
                }
              }
              risk_level
              assessment {
                score
                tool
              }
              language
              phone {
                mobile
                home
              }
              status
              compliance
              events {
                id
                activity {
                  id
                  title {
                    en
                  }
                }
                date
              }
              preferred_name
              sms_enabled
              sms_disabled_by
              sms_consent
              sms_can_enable
              is_active
              supervision_begin_date
              supervision_end_date
              auth_token
              created_at
              case_manager_id
              hispanic_or_latino
              race
              alcohol_monitoring
              gps_monitoring
              date_of_birth
              sex
              employer {
                name
              }
              hair
              eye_color
              weight
              height
              person_address
              place_of_birth
              person_id {
                number
                type
                state
              }
              victims {
                name {
                  first
                  last
                }
                phone
                date_of_birth
                address
                sex
              }
              stay_away
              peaceful_contact
              case_number
              extra_equipment
              incident_notes
              offense_info
              record_source
              court_recommendation
              court_decision
              case_disposition
              home_phone_human_readable
              cell_phone_human_readable
              equipment_returned
              protective_order_notes
              probation_number
            }
            result
            description
            errors
          }
        }
      `,
      variables,
    },
    "Error while updating a participant sms info"
  );
};

export const updateParticipantLanguage = async (
  variables: UpdateParticipantGqlArguments
) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation participantsUpdateParticipantLanguageMutation(
          $id: String!
          $language: String
        ) {
          updateParticipant(id: $id, language: $language) {
            participant {
              id
              name {
                first
                last
              }
              case_manager {
                id
                name {
                  first
                  last
                }
              }
              risk_level
              assessment {
                score
                tool
              }
              language
              phone {
                mobile
              }
              status
              compliance
              events {
                id
                activity {
                  id
                  title {
                    en
                  }
                }
                date
              }
              preferred_name
              sms_enabled
              sms_disabled_by
              sms_consent
              sms_can_enable
              is_active
              supervision_begin_date
              supervision_end_date
              auth_token
              created_at
              case_manager_id
              home_phone_human_readable
              cell_phone_human_readable
              equipment_returned
              protective_order_notes
              probation_number
            }
            result
            description
            errors
          }
        }
      `,
      variables,
    },
    "Error while updating a participant language"
  );
};

export const updateParticipantActiveStatus = async (variables: any) => {
  const filteredVariables = Object.keys(variables)
    .filter((key) => {
      return !(key === "recidivism_reason" && variables[key] === "");
    })
    .reduce(
      (acc, key) => {
        acc[key] = variables[key];
        return acc;
      },
      {} as { [key: string]: string }
    );
  return commitMutation(
    {
      mutation: graphql`
        mutation participantsChangeParticipantIsActiveStatusMutation(
          $id: String!
          $is_active: Boolean!
          $reason: String
          $note: String
          $recidivism_reason: RecidivismReason
          $recidivism_date: String
        ) {
          changeParticipantIsActiveStatus(
            participant_id: $id
            is_active: $is_active
            reason: $reason
            note: $note
            recidivism_reason: $recidivism_reason
            recidivism_date: $recidivism_date
          ) {
            participant {
              id
              name {
                first
                last
              }
              case_manager {
                id
                name {
                  first
                  last
                }
              }
              risk_level
              assessment {
                score
                tool
              }
              language
              phone {
                mobile
                home
              }
              status
              compliance
              events {
                id
                activity {
                  id
                  title {
                    en
                  }
                }
                date
              }
              preferred_name
              sms_enabled
              sms_disabled_by
              sms_consent
              sms_can_enable
              is_active
              supervision_begin_date
              supervision_end_date
              auth_token
              created_at
              case_manager_id
              hispanic_or_latino
              race
              alcohol_monitoring
              gps_monitoring
              date_of_birth
              sex
              employer {
                name
              }
              hair
              eye_color
              weight
              height
              person_address
              place_of_birth
              person_id {
                number
                type
                state
              }
              victims {
                name {
                  first
                  last
                }
                phone
                date_of_birth
                address
                sex
              }
              stay_away
              peaceful_contact
              case_number
              extra_equipment
              incident_notes
              offense_info
              record_source
              court_recommendation
              court_decision
              case_disposition
              home_phone_human_readable
              cell_phone_human_readable
              equipment_returned
              protective_order_notes
              probation_number
            }
            result
            description
            errors
          }
        }
      `,
      variables: filteredVariables,
      updater: updateParticipantStore(
        variables.id,
        "changeParticipantIsActiveStatus"
      ),
    },
    "Error while updating a participant sms info"
  );
};

const updateParticipantStore = (
  participantId: string,
  mutationName: string = "updateParticipant"
) => (store: RecordSourceSelectorProxy) => {
  const payload = checkMutationReturn(store, mutationName);

  if (!payload) return;

  const participant = getParticipant(store, "updateParticipant", participantId);

  if (!participant) return;

  participant.copyFieldsFrom(payload);
};

function buildOptimisticResponse(
  participant: ParticipantDetailsType,
  variables: UpdateParticipantGqlArguments
) {
  return {
    updateParticipant: {
      participant: {
        ...participant,
        id: variables.id || participant.id,
        name: {
          first: variables.first || participant.name.first,
          last: variables.last || participant.name.last,
        },
        phone: {
          mobile:
            variables.mobile || (participant.phone && participant.phone.mobile),
          home:
            variables.home_phone ||
            (participant.phone && participant.phone.home),
        },
        language: variables.language || participant.language,
        assessment: {
          score:
            variables.assessment_score ||
            (participant.assessment && participant.assessment.score),
          tool:
            variables.assessment_tool ||
            (participant.assessment && participant.assessment.tool),
        },
        risk_level: variables.risk_level || participant.risk_level,
        case_manager_id:
          variables.case_manager_id ||
          (participant.case_manager && participant.case_manager.id),
        case_manager: {
          id: participant.case_manager && participant.case_manager.id,
          name: {
            first:
              participant.case_manager && participant.case_manager.name.first,
            last:
              participant.case_manager && participant.case_manager.name.last,
          },
        },
        preferred_name: variables.preferred_name || participant.preferred_name,
        status: participant.status,
        compliance: variables.compliance || participant.compliance,
        supervision_begin_date:
          variables.supervision_begin_date ||
          participant.supervision_begin_date,
        supervision_end_date:
          variables.supervision_end_date || participant.supervision_end_date,
        sms_consent: variables.sms_consent || participant.sms_consent,
        sms_disabled_by:
          variables.sms_disabled_by || participant.sms_disabled_by,
        is_active: participant.is_active,
        hispanic_or_latino: variables.hispanic_or_latino,
        race: variables.race || participant.race,
        alcohol_monitoring: variables.alcohol_monitoring,
        gps_monitoring: variables.gps_monitoring,
        date_of_birth: variables.date_of_birth || participant.date_of_birth,
        sex: variables.sex || participant.sex,
        employer: {
          name: variables.employer_name || participant.employer.name,
        },
        hair: variables.hair || participant.hair,
        eye_color: variables.eye_color || participant.hair,
        weight: variables.weight || participant.weight,
        height: variables.height || participant.height,
        person_address: variables.person_address || participant.person_address,
        place_of_birth: variables.place_of_birth || participant.place_of_birth,
        person_id: {
          number: variables.id_number || participant.person_id.number,
          type: variables.id_type || participant.person_id.type,
          state: variables.id_state || participant.person_id.state,
        },
        victims: variables.victims || participant.victims,
        stay_away: variables.stay_away,
        peaceful_contact: variables.peaceful_contact,
        case_number: variables.case_number || participant.case_number,
        extra_equipment:
          variables.extra_equipment || participant.extra_equipment,
        incident_notes: variables.incident_notes || participant.incident_notes,
        offense_info: variables.offense_info || participant.offense_info,
        record_source: variables.record_source || participant.record_source,
        court_recommendation:
          variables.court_recommendation || participant.court_recommendation,
        court_decision: variables.court_decision || participant.court_decision,
        case_disposition:
          variables.case_disposition || participant.case_disposition,
        home_phone_human_readable:
          variables.home_phone || participant.home_phone_human_readable,
        cell_phone_human_readable:
          variables.mobile || participant.cell_phone_human_readable,
        equipment_returned:
          variables.equipment_returned || participant.equipment_returned,
        protective_order_notes:
          variables.protective_order_notes ||
          participant.protective_order_notes,
        probation_number:
          variables.probation_number || participant.probation_number,
      },
      result: "success",
      description: null,
      errors: null,
    },
  };
}
