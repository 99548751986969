/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type NotificationDeliveryIndicatorQueryVariables = {
    readonly id?: string | null;
};
export type NotificationDeliveryIndicatorQueryResponse = {
    readonly notifications: ({
        readonly notification: ReadonlyArray<({
            readonly final_delivery_error_message: string | null;
            readonly final_delivery_status: string | null;
            readonly time_deployed: string | null;
        }) | null> | null;
    }) | null;
};
export type NotificationDeliveryIndicatorQuery = {
    readonly response: NotificationDeliveryIndicatorQueryResponse;
    readonly variables: NotificationDeliveryIndicatorQueryVariables;
};



/*
query NotificationDeliveryIndicatorQuery(
  $id: String
) {
  notifications(id: $id) {
    notification {
      final_delivery_error_message
      final_delivery_status
      time_deployed
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "final_delivery_error_message",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "final_delivery_status",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "time_deployed",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "NotificationDeliveryIndicatorQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "notifications",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Pagination",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "notification",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Notification",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/)
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "NotificationDeliveryIndicatorQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "notifications",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Pagination",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "notification",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Notification",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "id",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "NotificationDeliveryIndicatorQuery",
            "id": null,
            "text": "query NotificationDeliveryIndicatorQuery(\n  $id: String\n) {\n  notifications(id: $id) {\n    notification {\n      final_delivery_error_message\n      final_delivery_status\n      time_deployed\n      id\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'aafbafb103b97acab817fe0de6b61395';
export default node;
