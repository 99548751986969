import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { PageHeader } from "../../shared/components/elements/PageHeader";
import { getFeatureFlags } from "../../featureFlags";
import "./main.scss";
import { QueryRenderer } from "../../shared/lib/graphql";
import { CalendarGraphqlQuery } from "./CalendarGraphql";
import {
  UserConsumer,
  UserContextType,
} from "../../shared/lib/contexts/UserContext";
import idx from "idx.macro";
import moment from "moment-timezone";
import { Participant } from "../../graphql-types";
import { safelyUnwrapArrayWithPossibleNullOrUndefined } from "../../utils/safeUnwrap";

export function Calendar() {
  return (
    <UserConsumer>
      {(userContext: UserContextType) => {
        const id = userContext.user ? userContext.user.uid : "";
        return (
          <QueryRenderer
            query={CalendarGraphqlQuery}
            variables={{ id }}
            SuccessComponent={(props: any) => {
              const participants = idx(
                props,
                (_) => _.participants.participant
              ) as Participant[];

              const events = flattenParticipantEvents(participants || []);

              return (
                <>
                  <PageHeader
                    pageName="Calendar"
                    pageBlurb={`Events for your assigned ${getFeatureFlags().clientDisplayTerm.toLowerCase()}s.`}
                  />
                  <div className="full-calendar-wrapper">
                    <FullCalendar
                      defaultView="dayGridMonth"
                      header={{
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                      }}
                      events={events}
                      plugins={[dayGridPlugin, timeGridPlugin]}
                    />
                  </div>
                </>
              );
            }}
          />
        );
      }}
    </UserConsumer>
  );
}

const flattenParticipantEvents = (participants: Participant[]) => {
  const unwrappedParticipants = safelyUnwrapArrayWithPossibleNullOrUndefined(
    participants
  );

  const unflattenedEvents = unwrappedParticipants.map(
    (participant: Participant) => {
      if (!participant.events) {
        return [];
      }

      return participant.events.map((event) => {
        return { name: participant.name, event: event };
      });
    }
  );

  const events = unflattenedEvents.reduce((acc, eventArray) => {
    acc = [...acc, ...eventArray];

    return acc;
  }, []);

  return events.map(({ name, event }) => {
    if (!name || !event) {
      return null;
    }

    if (!event.date) {
      return null;
    }

    const { activity } = event;
    return {
      title: activity ? `${name.first} ${name.last}: ${activity.title.en}` : "",
      start: moment(event.date).toDate(),
      end: event.end
        ? moment(event.end).toDate()
        : moment(event.date).add(1, "hour").toDate(),
      allDay: false,
    };
  });
};
