/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type MutationResult = "failure" | "skipped" | "success" | "%future added value";
export type goalsRemoveParticipantGoalMutationVariables = {
    readonly participant_id: string;
    readonly goal_id: string;
};
export type goalsRemoveParticipantGoalMutationResponse = {
    readonly removeParticipantGoal: ({
        readonly goal: ({
            readonly id: string;
            readonly label: string;
            readonly created_at: string;
            readonly completed_at: string | null;
        }) | null;
        readonly result: MutationResult;
        readonly description: string | null;
        readonly errors: ReadonlyArray<string | null> | null;
    }) | null;
};
export type goalsRemoveParticipantGoalMutation = {
    readonly response: goalsRemoveParticipantGoalMutationResponse;
    readonly variables: goalsRemoveParticipantGoalMutationVariables;
};



/*
mutation goalsRemoveParticipantGoalMutation(
  $participant_id: String!
  $goal_id: String!
) {
  removeParticipantGoal(participant_id: $participant_id, goal_id: $goal_id) {
    goal {
      id
      label
      created_at
      completed_at
    }
    result
    description
    errors
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "participant_id",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "goal_id",
            "type": "String!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "removeParticipantGoal",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "goal_id",
                    "variableName": "goal_id"
                },
                {
                    "kind": "Variable",
                    "name": "participant_id",
                    "variableName": "participant_id"
                }
            ],
            "concreteType": "NewParticipantGoal",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "goal",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ParticipantGoal",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "label",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "created_at",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "completed_at",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "result",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "errors",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "goalsRemoveParticipantGoalMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "goalsRemoveParticipantGoalMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "goalsRemoveParticipantGoalMutation",
            "id": null,
            "text": "mutation goalsRemoveParticipantGoalMutation(\n  $participant_id: String!\n  $goal_id: String!\n) {\n  removeParticipantGoal(participant_id: $participant_id, goal_id: $goal_id) {\n    goal {\n      id\n      label\n      created_at\n      completed_at\n    }\n    result\n    description\n    errors\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'd4e5f43c96df5c939c5b57131d9ec3e5';
export default node;
