import ModalWrapperHOC from "../../HOCs/ModalWrapperHOC";
import { FormDataBase, FormField } from "../types";
import ModalWrapper from "../ModalWrapper";
import { ValidationsType, ValidationItemStates } from "../types";
import { ParticipantDetailsType } from "../../screens/ParticipantDetailsQueryTypes";
import { Moment } from "moment";

export interface ChangeParticipantActiveStatusFormData extends FormDataBase {
  firstName: FormField;
  lastName: FormField;
  reason: FormField;
  note: FormField;
  recidivismDate: FormField<Moment> | FormField<null>;
}

class ChangeParticipantActiveStatusModalWrapper extends ModalWrapper<
  ChangeParticipantActiveStatusFormData,
  ParticipantDetailsType
> {
  static formState = getParticipantModalStatic(null);

  componentDidMount() {
    // on remount, refresh the static class prop formState
    // handles the two possible cases of receiving a specific participant (update)
    // or not receiving a specific participant (add)
    const { updateObject: participant } = this.props;

    ChangeParticipantActiveStatusModalWrapper.formState = getParticipantModalStatic(
      participant
    );
  }
}

export default ModalWrapperHOC(ChangeParticipantActiveStatusModalWrapper);

function getParticipantModalStatic(
  participant: ParticipantDetailsType | null | undefined
) {
  return {
    modalFormData: {
      firstName: {
        label: "First Name",
        value: participant ? participant.name.first : "",
      },
      lastName: {
        label: "Last Name",
        value: participant ? participant.name.last : "",
      },
      reason: {
        label: "Reason",
        value: "",
      },
      note: {
        label: "Note",
        value: "",
      },
      recidivismDate: {
        label: "Recidivism Date",
        dateFormat: "dddd, MMMM Do YYYY",
        value: null,
      },
      recidivismReason: {
        label: "Recidivism Reason",
        value: "new_charge",
      },
    } as ChangeParticipantActiveStatusFormData,
    validations: {
      reason: {
        state: ValidationItemStates.pristine,
        errors: [] as string[],
      },
      recidivismReason: {
        state: ValidationItemStates.pristine,
        errors: [] as string[],
      },
      notes: {
        state: ValidationItemStates.valid,
        errors: [] as string[],
      },
      recidivismDate: {
        state: ValidationItemStates.valid,
        errors: [] as string[],
      },
    } as ValidationsType,
    attemptedSubmission: false,
    secondPage: false,
    showModal: false,
  };
}
