import React, { useState, useReducer } from "react";
import {
  Typography,
  Row,
  Col,
  Table,
  Checkbox,
  Steps,
  Button,
  Statistic,
  Icon,
  Select,
  Collapse,
  Input,
  Form,
  Modal,
  DatePicker,
  TimePicker,
} from "antd";
import {
  Booking,
  BookingClassification,
  YesNoUnknown,
  YesNo,
  Maybe,
  BookingCharge,
} from "../../graphql-types";
import { customPanelStyle } from "../care-plan/CarePlan";
import {
  bookingsGraphqlByDOB,
  bookingsGraphqlByIdQuery,
} from "./BookingsGraphql";
import { QueryRenderer } from "../../shared/lib/graphql";
import { addBookingGroup } from "../../actions/booking_groups";
import { ClassesQueryGqlProps } from "./Bookings";
import moment, { Moment } from "moment";
import { CreateBookingForm } from "./CreateBookingForm";
import { addBooking } from "../../actions/bookings";
const uuid = require("uuid");
import "./Bookings.scss";

const { Panel } = Collapse;
const { Text, Title } = Typography;
const { Step } = Steps;
const { Option } = Select;
const { TextArea } = Input;

const dateFormatList = ["MM/DD/YYYY", "MM/D/YYYY", "M/D/YYYY"];

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

interface TransmittalCreationProps {
  bookings: Booking[];
  bookingGroupId?: string;
}

type BookingWithReferral = Omit<
  Booking,
  "is_eligible" | "has_wait" | "spanish_speaker" | "comments" | "classification"
> & {
  referral?: boolean;
  is_eligible: boolean;
  has_wait: YesNo;
  spanish_speaker: YesNoUnknown;
  comments: string;
  classification: Maybe<string>;
};

export interface BookingChargeFormInput {
  chargeCode: string;
  chargeSection: string;
}

export function combineDateAndTimeMoment(
  dateMoment: Maybe<Moment>,
  timeMoment: Maybe<Moment>
) {
  if (!dateMoment || !timeMoment) {
    return null;
  }

  if (!dateMoment) {
    return timeMoment;
  }

  if (!timeMoment) {
    return dateMoment;
  }

  const hour = timeMoment.hour();
  const minute = timeMoment.minute();
  const courtDateMoment = dateMoment.hour(hour).minute(minute);
  return courtDateMoment;
}

const mapBookingToBookingWithReferral = (
  booking: Booking,
  isReferral: boolean
): BookingWithReferral => {
  return {
    ...booking,
    is_eligible:
      booking.is_eligible === YesNoUnknown.Yes && !isReferral ? true : false,
    spanish_speaker:
      booking.is_eligible === YesNoUnknown.Unknown
        ? YesNoUnknown.No
        : booking.spanish_speaker || YesNoUnknown.No,
    has_wait:
      booking.is_eligible === YesNoUnknown.Unknown
        ? YesNo.No
        : booking.has_wait === YesNo.Yes
        ? YesNo.Yes
        : YesNo.No,
    referral: isReferral,
    comments:
      booking.is_eligible === YesNoUnknown.Unknown
        ? ""
        : booking.comments || "",
    classification:
      booking.is_eligible === YesNoUnknown.Unknown ||
      booking.classification === BookingClassification.NotClassified
        ? null
        : booking.classification,
  };
};

export const getRowClassName = (record: any, _index: number) => {
  const { booking_number: bookingNumber } = record;

  return bookingNumber ? "" : "grey-row-booking-table";
};

export function isBookingEligible(booking: BookingWithReferral) {
  const {
    booking_charges: bookingCharges,
    booking_releases: bookingReleases,
  } = booking;
  return (
    bookingCharges &&
    bookingReleases &&
    bookingCharges.every((charge) => charge.charge_auth_type === "OPEN") &&
    bookingReleases.length === 0
  );
}

export function TransmittalCreation(props: TransmittalCreationProps) {
  const { bookings } = props;

  const bookingReducer = (
    state: BookingWithReferral[],
    action: {
      type: "update" | "add" | "new";
      updatedBooking: BookingWithReferral;
      newBookings?: BookingWithReferral[];
    }
  ) => {
    if (action.type === "update") {
      const bookingExists = !!state.find(
        (booking) => booking.id === action.updatedBooking.id
      );

      if (bookingExists) {
        return state.map((booking) => {
          if (booking.id === action.updatedBooking.id) {
            return action.updatedBooking;
          }
          return booking;
        });
      } else {
        return [...state, action.updatedBooking];
      }
    }

    if (action.type === "add") {
      return [...state, action.updatedBooking];
    }

    if (action.type === "new") {
      if (action.newBookings) {
        return [...state, ...action.newBookings];
      }
    }

    return state;
  };

  const [allBookings, dispatchBookings] = useReducer(
    bookingReducer,
    bookings.map((booking) => mapBookingToBookingWithReferral(booking, false))
  );

  const [referralBookings, dispatchReferralBookings] = useReducer(
    bookingReducer,
    [] as BookingWithReferral[]
  );

  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);

    window.scrollTo(0, 150);
  };

  const prev = () => {
    setCurrent(current - 1);

    window.scrollTo(0, 150);
  };

  const bookingsForTransmittal = [
    ...allBookings.filter((booking) => booking.is_eligible),
    ...referralBookings.filter((booking) => booking.is_eligible),
  ];

  const handleCreateOrReportClicked = async () => {
    const bookingsToModifyArray = [...allBookings, ...referralBookings].map(
      (booking) => {
        const {
          id,
          has_wait: hasWait,
          spanish_speaker: spanishSpeaker,
          classification,
          comments,
          is_eligible: isEligible,
          booking_status: bookingStatus,
          booking_defendent: bookingDefendent,
          booking_charges: bookingCharges,
        } = booking;
        return {
          id,
          is_eligible: isEligible,
          has_wait: hasWait,
          spanish_speaker: spanishSpeaker,
          classification,
          comments: comments || "",
          booking_status: bookingStatus,
          booking_defendent: bookingDefendent,
          booking_charges: bookingCharges,
        };
      }
    );

    await addBookingGroup({
      id: props.bookingGroupId,
      bookings_to_modify: bookingsToModifyArray,
    });
  };

  const steps = [
    {
      title: "Select Bookings",
      content: (
        <SelectBookings
          bookings={allBookings}
          dispatchBookings={dispatchBookings}
        />
      ),
    },
    {
      title: "Add referrals",
      content: (
        <Referral
          referralBookings={referralBookings}
          dispatchReferralBookings={dispatchReferralBookings}
        />
      ),
    },
    {
      title: "Classify bookings",
      content: (
        <div style={{ padding: "20px" }}>
          {bookingsForTransmittal.map((booking) => (
            <div key={booking.id}>
              <Collapse key={booking.id} defaultActiveKey={[booking.id]}>
                <Panel
                  key={booking.id}
                  header={
                    <BookingsPanelHeader
                      booking={booking}
                      classificationMode={true}
                      dispatchBookings={
                        booking.referral === true
                          ? dispatchReferralBookings
                          : dispatchBookings
                      }
                    />
                  }
                  style={{ ...customPanelStyle }}
                >
                  <BookingsExpanded {...booking} />
                </Panel>
              </Collapse>
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "Create Report",
      content: (
        <ORTransmittal
          bookings={bookingsForTransmittal}
          dispatchBookings={dispatchBookings}
          dispatchReferralBookings={dispatchReferralBookings}
        />
      ),
    },
  ];

  return (
    <div className="bookings-create-or-container">
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
      <div style={{ margin: "20px" }}>
        <Row justify="end" type="flex">
          <Col>
            <Statistic
              title="Selected bookings"
              value={bookingsForTransmittal.length}
              prefix={<Icon type="user-add" />}
            />
          </Col>
        </Row>
      </div>
      <Row justify="end" type="flex">
        <Col>
          {current > 0 && (
            <Button size={"large"} onClick={() => prev()}>
              Back
            </Button>
          )}
        </Col>
        <Col>
          {current < steps.length - 1 && (
            <Button
              type="primary"
              size={"large"}
              style={{ marginLeft: 8 }}
              onClick={() => next()}
            >
              Next
            </Button>
          )}
        </Col>
        <Col>
          {current === steps.length - 1 && (
            <Button
              type="primary"
              size={"large"}
              style={{ marginLeft: 8 }}
              onClick={() => handleCreateOrReportClicked()}
            >
              Create OR Report
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
}

function BookingsPanelHeader(props: {
  booking: BookingWithReferral;
  classificationMode: boolean;
  dispatchBookings: React.Dispatch<{
    type: "update" | "add";
    updatedBooking: BookingWithReferral;
  }>;
}) {
  const { classificationMode, booking, dispatchBookings } = props;
  const { id } = booking;

  const handleBookingSelectChange = (name: string, value: string | boolean) => {
    const updatedBooking = {
      ...booking,
      [name]: value,
    };

    dispatchBookings({ type: "update", updatedBooking });
  };

  return (
    <Row type="flex" justify="space-around" align="middle">
      <Col span={5}>
        <Row>
          <Text strong>Booking Number: </Text>
          <Text>{` ${id}`}</Text>
        </Row>
      </Col>

      {classificationMode ? (
        <>
          <Col span={6}>
            <Row onClick={(event) => event.stopPropagation()}>
              <Text strong>Classification: </Text>
              <Select
                placeholder="Classification"
                style={{ width: "150px" }}
                value={booking.classification as string}
                onChange={(value: string) =>
                  handleBookingSelectChange("classification", value as string)
                }
              >
                <Option value={BookingClassification.Referral}>Referral</Option>
                <Option value={BookingClassification.NewArrest}>
                  New Arrest
                </Option>
                <Option value={BookingClassification.Supervised}>
                  Supervised
                </Option>
                <Option value={BookingClassification.Conditional}>
                  Conditional
                </Option>
              </Select>
            </Row>
          </Col>
          <Col span={5}>
            <Row onClick={(event) => event.stopPropagation()}>
              <Text strong>Spanish Speaker: </Text>
              <Select
                placeholder="Spanish speaker"
                style={{ width: "100px" }}
                value={booking.spanish_speaker}
                onChange={(value: string) =>
                  handleBookingSelectChange("spanish_speaker", value)
                }
              >
                <Option value={YesNoUnknown.Yes}>Yes</Option>
                <Option value={YesNoUnknown.No}>No</Option>
                <Option value={YesNoUnknown.Unknown}>Maybe</Option>
              </Select>
            </Row>
          </Col>
          <Col span={5}>
            <Row onClick={(event) => event.stopPropagation()}>
              <Text strong>Wait: </Text>
              <Select
                placeholder="Wait"
                style={{ width: "100px" }}
                value={booking.has_wait}
                onChange={(value: any) =>
                  handleBookingSelectChange("has_wait", value)
                }
              >
                <Option value={"yes"}>Yes</Option>
                <Option value={"no"}>No</Option>
              </Select>
            </Row>
          </Col>
        </>
      ) : (
        <Col span={16}></Col>
      )}

      <Col span={3} onClick={(event) => event.stopPropagation()}>
        <Text strong>Add to transmittal: </Text>
        <Checkbox
          disabled={classificationMode}
          checked={booking.is_eligible}
          onChange={() =>
            handleBookingSelectChange("is_eligible", !booking.is_eligible)
          }
        />
      </Col>
    </Row>
  );
}

function BookingsExpanded(props: BookingWithReferral) {
  const { booking_charges: bookingCharges } = props;

  return (
    <div>
      <div style={{ marginBottom: "10px" }}>
        <BookingDetail booking={props} />
      </div>
      <div style={{ marginBottom: "10px" }}>
        {bookingCharges && (
          <BookingChargesTable bookingCharges={bookingCharges} />
        )}
      </div>
    </div>
  );
}

export function BookingDetail({
  booking,
}: {
  booking: Booking | BookingWithReferral;
}) {
  const {
    booking_status: bookingStatus,
    booking_releases: bookingReleases,
    booking_defendent: {
      name: { first, last },
      sex,
      race,
      cdl_id: cdlId,
      local_id: localId,
      cii_number: ciiNumber,
      date_of_birth: dateOfBirth,
    },
    booking_info: { booking_datetime: bookingDateTime },
  } = booking;
  return (
    <>
      <Col span={6}>
        <Row>
          <Text strong>Name: </Text>
          <Text>{`${last}, ${first}`}</Text>
        </Row>
        <Row>
          <Text strong>Local ID: </Text>
          <Text>{localId}</Text>
        </Row>
        <Row>
          <Text strong>CDL ID: </Text>
          <Text>{cdlId}</Text>
        </Row>
        <Row>
          <Text strong>CII Number: </Text>
          <Text>{ciiNumber || "N/A"}</Text>
        </Row>
      </Col>
      <Col span={4}>
        <Row>
          <Text strong>DOB: </Text>
          <Text>{`${moment(dateOfBirth).format("MM/DD/YYYY")}`}</Text>
        </Row>
        <Row>
          <Text strong>Age: </Text>
          <Text>{`${moment().diff(moment(dateOfBirth), "years")}`}</Text>
        </Row>
        <Row>
          <Text strong>Sex: </Text>
          <Text>{sex}</Text>
        </Row>
        <Row>
          <Text strong>Race: </Text>
          <Text>{race}</Text>
        </Row>
      </Col>
      <Col span={8}>
        <Row>
          <Text strong>Booking Date/Time: </Text>
          <Text>
            {bookingDateTime
              ? moment(bookingDateTime).format("MM/DD/YYYY h:mm a")
              : ""}
          </Text>
        </Row>
        <Row>
          <Text strong>Court Appearance Date/Time: </Text>
          {bookingStatus && bookingStatus.court_appearance_datetime ? (
            <Text>
              {moment(bookingStatus.court_appearance_datetime).format(
                "MM/DD/YYYY h:mm a"
              )}
            </Text>
          ) : (
            <Text>N/A</Text>
          )}
        </Row>
        <Row>
          <Text strong>Release Reason: </Text>
          {bookingReleases && bookingReleases.length > 0 ? (
            <Text>{bookingReleases[0].charge_release_type}</Text>
          ) : (
            <Text>N/A</Text>
          )}
        </Row>
      </Col>
    </>
  );
}

export function BookingChargesTable(props: {
  bookingCharges: BookingCharge[];
}) {
  const { bookingCharges } = props;
  const chargeTableColumns = [
    {
      title: "Code & Section",
      dataIndex: "charge_code_section",
      key: "charge_code_section",
    },
    {
      title: "Type",
      dataIndex: "charge_type",
      key: "charge_type",
    },
    {
      title: "Description",
      dataIndex: "charge_description",
      key: "charge_description",
    },
    {
      title: "Auth",
      dataIndex: "charge_auth_type",
      key: "charge_auth_type",
    },
    {
      title: "Agency",
      dataIndex: "arrest_agency",
      key: "arrest_agency",
    },
    {
      title: "Crime Case Number",
      dataIndex: "crime_case_number",
      key: "crime_case_number",
    },
    {
      title: "Charge Date/Time",
      dataIndex: "charge_datetime",
      key: "charge_datetime",
    },
    {
      title: "Court/Wrnt No",
      dataIndex: "court_warrant_number",
      key: "court_warrant_number",
    },
  ];
  return (
    <Table
      rowKey={(_record: any, index: number) => {
        return index.toString();
      }}
      rowClassName={getRowClassName}
      pagination={false}
      columns={chargeTableColumns}
      dataSource={bookingCharges.map((bookingCharge) => {
        return {
          ...bookingCharge,
          charge_datetime: bookingCharge.charge_datetime
            ? moment(bookingCharge.charge_datetime).format("MM/DD/YYYY h:mm a")
            : "",
          crime_case_number: bookingCharge.crime_case_number || "N/A",
        };
      })}
      title={() => <Text strong>Charges</Text>}
    />
  );
}

function SelectBookings({
  bookings,
  dispatchBookings,
}: {
  bookings: BookingWithReferral[];
  dispatchBookings: React.Dispatch<{
    type: "update" | "add";
    updatedBooking: BookingWithReferral;
  }>;
}) {
  return (
    <div style={{ padding: "20px" }}>
      {bookings.map((booking) => (
        <div key={booking.id}>
          <Collapse key={booking.id} defaultActiveKey={[booking.id]}>
            <Panel
              key={booking.id}
              header={
                <BookingsPanelHeader
                  classificationMode={false}
                  booking={booking}
                  dispatchBookings={dispatchBookings}
                />
              }
              style={{
                ...customPanelStyle,
                border: `1px solid ${
                  isBookingEligible(booking) ? "green" : "red"
                }`,
              }}
            >
              <BookingsExpanded {...booking} />
            </Panel>
          </Collapse>
        </div>
      ))}
    </div>
  );
}

function Referral(props: {
  referralBookings: BookingWithReferral[];
  dispatchReferralBookings: React.Dispatch<{
    type: "update" | "add";
    updatedBooking: BookingWithReferral;
  }>;
}) {
  const { referralBookings, dispatchReferralBookings } = props;

  const [showHistoricalBookings, setShowHistoricalBookings] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState(null as Maybe<Moment>);
  const [searchDOB, setSearchDOB] = useState(false);
  const [bookingNumber, setBookingNumber] = useState("");
  const [searchBookingNumber, setSearchBookingNumber] = useState(false);

  const initialFields = {
    bookingNumber: {
      value: "",
    },
    firstName: {
      value: "",
    },
    lastName: {
      value: "",
    },
    dateOfBirth: {
      value: moment(),
    },
    courtDate: {
      value: moment(),
    },
    courtTime: {
      value: moment(),
    },
    courtRoom: {
      value: "",
    },
    pc: {
      value: "",
    },
  };

  const [fields, setFields] = useState(initialFields);
  const [charges, setCharges] = useState([
    { chargeCode: "", chargeSection: "" },
  ]);

  const addCharge = (charge: BookingChargeFormInput) => {
    const newCharges = [...charges, charge];
    setCharges(newCharges);
  };

  const updateCharge = (
    chargeToUpdate: BookingChargeFormInput,
    indexToUpdate: number
  ) => {
    const newCharges = charges.map((charge, index) => {
      if (indexToUpdate === index) {
        return chargeToUpdate;
      }

      return charge;
    });
    setCharges(newCharges);
  };

  const removeCharge = (indexToRemove: number) => {
    const newCharges = charges.filter((_charge, index) => {
      if (indexToRemove === index) {
        return false;
      }

      return true;
    });
    setCharges(newCharges);
  };

  const setFieldsCalled = (
    setFieldsCallback: Function,
    fields: any,
    oldFields: any
  ) => {
    // weirdly necessary as setFields will get
    // called with an empty object literal when
    // a form is re-navigated to
    if (Object.keys(fields).length === 0) {
      return;
    }

    setFieldsCallback({
      ...oldFields,
      ...fields,
    });
  };

  const addHistoricalBooking = async () => {
    const courtDateMoment = combineDateAndTimeMoment(
      fields.courtDate.value,
      fields.courtTime.value
    );

    const data = await addBooking({
      id: fields.bookingNumber.value,
      booking_defendent: {
        date_of_birth: fields.dateOfBirth.value.toISOString(),
        name: {
          first: fields.firstName.value,
          last: fields.lastName.value,
        },
      },
      booking_info: {
        booking_number: fields.bookingNumber.value,
      },
      booking_charges: charges.map((charge, index) => {
        return {
          id: `${fields.bookingNumber.value}_${index}`,
          booking_number: fields.bookingNumber.value,
          charge_code_section: `${charge.chargeCode} ${charge.chargeSection}`,
          charge_type: fields.pc.value,
        };
      }),
      booking_releases: null,
      classification: BookingClassification.NotClassified,
      booking_status: {
        booking_number: fields.bookingNumber.value,
        court_appearance_datetime: courtDateMoment
          ? courtDateMoment.toISOString()
          : "",
        court_room: fields.courtRoom.value,
        court_reason: "",
      },
    });

    const {
      addBooking: { booking },
    } = data;

    setShowHistoricalBookings(false);
    setFields(initialFields);

    dispatchReferralBookings({
      type: "add",
      updatedBooking: mapBookingToBookingWithReferral(booking, true),
    });
  };

  return (
    <div style={{ padding: "20px" }}>
      <Row>
        <Col span={6}>
          <Form.Item label="DOB">
            <DatePicker
              format={dateFormatList}
              value={dateOfBirth ? dateOfBirth : undefined}
              onChange={(date) => setDateOfBirth(date)}
            />
            <Button
              style={{ marginLeft: "5px" }}
              shape="circle"
              icon="search"
              type="primary"
              onClick={() => setSearchDOB(true)}
            ></Button>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Booking #">
            <Input
              style={{ width: "150px" }}
              value={bookingNumber}
              onChange={(e: any) => {
                const { value } = e.target;
                setBookingNumber(value);
              }}
            />
            <Button
              style={{ marginLeft: "5px" }}
              shape="circle"
              icon="search"
              type="primary"
              onClick={() => setSearchBookingNumber(true)}
            ></Button>
          </Form.Item>
        </Col>
      </Row>
      {searchDOB && dateOfBirth && (
        <QueryRenderer
          query={bookingsGraphqlByDOB}
          variables={{ date_of_birth: dateOfBirth.format("M/DD/YYYY") }}
          SuccessComponent={(props: ClassesQueryGqlProps) => {
            const {
              bookings: { booking: bookings },
            } = props;

            const dobBookings = bookings.map((booking) =>
              mapBookingToBookingWithReferral(booking, true)
            );

            const referralBookingSet = new Set(
              referralBookings.map((booking) => booking.id)
            );

            const dobNonDuplicateBookings = dobBookings.filter(
              (booking) => !referralBookingSet.has(booking.id)
            );

            return (
              <div style={{ margin: "20px" }}>
                {dobNonDuplicateBookings.map((booking) => (
                  <div key={booking.id}>
                    <Collapse key={booking.id} defaultActiveKey={[booking.id]}>
                      <Panel
                        key={booking.id}
                        header={
                          <BookingsPanelHeader
                            classificationMode={false}
                            booking={booking}
                            dispatchBookings={dispatchReferralBookings}
                          />
                        }
                        style={{ ...customPanelStyle }}
                      >
                        <BookingsExpanded {...booking} />
                      </Panel>
                    </Collapse>
                  </div>
                ))}
              </div>
            );
          }}
        />
      )}
      {searchBookingNumber && bookingNumber && (
        <QueryRenderer
          query={bookingsGraphqlByIdQuery}
          variables={{ id: bookingNumber }}
          SuccessComponent={(props: ClassesQueryGqlProps) => {
            const {
              bookings: { booking: bookings },
            } = props;

            const idBookings = bookings.map((booking) =>
              mapBookingToBookingWithReferral(booking, true)
            );

            const referralBookingSet = new Set(
              referralBookings.map((booking) => booking.id)
            );

            const idNonDuplicateBookings = idBookings.filter(
              (booking) => !referralBookingSet.has(booking.id)
            );

            return (
              <div style={{ margin: "20px" }}>
                {idNonDuplicateBookings.map((booking) => (
                  <div key={booking.id}>
                    <Collapse key={booking.id} defaultActiveKey={[booking.id]}>
                      <Panel
                        key={booking.id}
                        header={
                          <BookingsPanelHeader
                            classificationMode={false}
                            booking={booking}
                            dispatchBookings={dispatchReferralBookings}
                          />
                        }
                        style={{ ...customPanelStyle }}
                      >
                        <BookingsExpanded {...booking} />
                      </Panel>
                    </Collapse>
                  </div>
                ))}
              </div>
            );
          }}
        />
      )}
      {referralBookings.map((booking) => (
        <div key={booking.id}>
          <Collapse key={booking.id} defaultActiveKey={[booking.id]}>
            <Panel
              key={booking.id}
              header={
                <BookingsPanelHeader
                  classificationMode={false}
                  booking={booking}
                  dispatchBookings={dispatchReferralBookings}
                />
              }
              style={{ ...customPanelStyle }}
            >
              <BookingsExpanded {...booking} />
            </Panel>
          </Collapse>
        </div>
      ))}
      <Button
        type="primary"
        onClick={() => setShowHistoricalBookings(!showHistoricalBookings)}
      >
        Add Historical Booking
      </Button>
      <Modal
        title="Add historical booking"
        visible={showHistoricalBookings}
        onOk={() => addHistoricalBooking()}
        onCancel={() => setShowHistoricalBookings(false)}
      >
        <CreateBookingForm
          onChange={(newFields: object) =>
            setFieldsCalled(setFields, newFields, fields)
          }
          {...fields}
          charges={charges}
          addCharge={addCharge}
          updateCharge={updateCharge}
          removeCharge={removeCharge}
        />
      </Modal>
    </div>
  );
}

export const orTransmittalColumns = [
  {
    title: "Booking #",
    dataIndex: "booking_number",
    key: "booking_number",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Rm/Time",
    dataIndex: "room_time",
    key: "room_time",
  },
  {
    title: "Date of Birth",
    dataIndex: "date_of_birth",
    key: "date_of_birth",
  },
  {
    title: "PC",
    dataIndex: "pc",
    key: "pc",
  },
  {
    title: "Charges",
    dataIndex: "charges",
    key: "charges",
  },
  {
    title: "Comments",
    dataIndex: "comments",
    key: "comments",
  },
];

function ORTransmittal({
  bookings,
  dispatchBookings,
  dispatchReferralBookings,
}: {
  bookings: BookingWithReferral[];
  dispatchBookings: React.Dispatch<{
    type: "update" | "add";
    updatedBooking: BookingWithReferral;
  }>;
  dispatchReferralBookings: React.Dispatch<{
    type: "update" | "add";
    updatedBooking: BookingWithReferral;
  }>;
}) {
  const date = moment().format("dddd, MMMM DD, YYYY");
  const title = `OR TRANSMITTAL SHEET FOR ${date}`;

  const handleBookingCommentsChange = (
    value: string,
    booking: BookingWithReferral
  ) => {
    const updatedBooking = {
      ...booking,
      comments: value,
    };

    const dispatchFunction = booking.referral
      ? dispatchReferralBookings
      : dispatchBookings;

    dispatchFunction({ type: "update", updatedBooking: updatedBooking });
  };

  const handleBookingCourtTimeChange = (
    valMoment: Maybe<Moment>,
    booking: BookingWithReferral,
    isTimeMoment: boolean
  ) => {
    const dispatchFunction = booking.referral
      ? dispatchReferralBookings
      : dispatchBookings;

    const courtDateMoment = isTimeMoment
      ? combineDateAndTimeMoment(
          moment(
            booking.booking_status
              ? booking.booking_status.court_appearance_datetime
              : undefined
          ),
          valMoment
        )
      : combineDateAndTimeMoment(
          valMoment,
          moment(
            booking.booking_status
              ? booking.booking_status.court_appearance_datetime
              : undefined
          )
        );

    const bookingStatus = booking.booking_status
      ? {
          ...booking.booking_status,
          court_appearance_datetime: courtDateMoment
            ? courtDateMoment.toISOString()
            : "",
        }
      : {
          booking_number: booking.id,
          court_reason: "",
          court_appearance_datetime: courtDateMoment
            ? courtDateMoment.toISOString()
            : "",
        };

    const updatedBooking = {
      ...booking,
      booking_status: bookingStatus,
    };

    dispatchFunction({ type: "update", updatedBooking: updatedBooking });
  };

  const handleBookingRoomChange = (
    event: any,
    booking: BookingWithReferral
  ) => {
    const { value } = event.target;
    const dispatchFunction = booking.referral
      ? dispatchReferralBookings
      : dispatchBookings;

    const bookingStatus = booking.booking_status
      ? {
          ...booking.booking_status,
          court_room: value,
        }
      : {
          booking_number: booking.id,
          court_reason: "",
          court_appearance_datetime: "",
          court_room: value,
        };

    const updatedBooking = {
      ...booking,
      booking_status: bookingStatus,
    };

    dispatchFunction({ type: "update", updatedBooking: updatedBooking });
  };

  const handleDobChange = (
    dateOfBirth: Maybe<Moment>,
    booking: BookingWithReferral
  ) => {
    const dispatchFunction = booking.referral
      ? dispatchReferralBookings
      : dispatchBookings;

    const updatedBooking = {
      ...booking,
      booking_defendent: {
        ...booking.booking_defendent,
        date_of_birth: dateOfBirth ? dateOfBirth.format("MM/DD/YYYY") : "",
      },
    };

    dispatchFunction({ type: "update", updatedBooking: updatedBooking });
  };

  const handlePCChange = (event: any, booking: BookingWithReferral) => {
    const { value } = event.target;
    const dispatchFunction = booking.referral
      ? dispatchReferralBookings
      : dispatchBookings;

    const bookingCharges = booking.booking_charges
      ? booking.booking_charges.map((bookingCharge) => {
          return {
            ...bookingCharge,
            charge_type: value,
          };
        })
      : ([{ charge_type: value }] as BookingCharge[]);

    const updatedBooking = {
      ...booking,
      booking_charges: bookingCharges,
    };

    dispatchFunction({ type: "update", updatedBooking: updatedBooking });
  };

  const handleAddChargeClicked = (booking: BookingWithReferral) => {
    const dispatchFunction = booking.referral
      ? dispatchReferralBookings
      : dispatchBookings;

    const existingCharges = booking.booking_charges
      ? booking.booking_charges
      : [];

    const bookingCharges = [
      ...existingCharges,
      {
        id: uuid(),
        booking_charge_sid: "",
        booking_sid: "",
        booking_number: "",
        booking_datetime: "",
        charge_auth_type: "OPEN",
        charge_code_section: "",
        charge_type: "",
        charge_datetime: "",
        charge_description: "",
        arrest_agency: "",
        arrest_datetime: "",
        crime_case_number: "",
        court_warrant_number: "",
      },
    ] as BookingCharge[];

    const updatedBooking = {
      ...booking,
      booking_charges: bookingCharges,
    };

    dispatchFunction({ type: "update", updatedBooking: updatedBooking });
  };

  const handleRemoveChargeClicked = (
    chargeId: string,
    booking: BookingWithReferral
  ) => {
    const dispatchFunction = booking.referral
      ? dispatchReferralBookings
      : dispatchBookings;

    if (!booking.booking_charges) {
      return;
    }

    if (booking.booking_charges.length === 1) {
      return;
    }

    const bookingCharges = booking.booking_charges.filter(
      (bookingCharge: BookingCharge) => {
        return bookingCharge.id !== chargeId;
      }
    );

    const updatedBooking = {
      ...booking,
      booking_charges: bookingCharges,
    };

    dispatchFunction({ type: "update", updatedBooking: updatedBooking });
  };

  const handleChargeChange = (
    event: any,
    chargeId: string,
    booking: BookingWithReferral
  ) => {
    const dispatchFunction = booking.referral
      ? dispatchReferralBookings
      : dispatchBookings;

    const { value } = event.target;

    if (!booking.booking_charges) {
      return;
    }

    const bookingCharges = booking.booking_charges.map(
      (bookingCharge: BookingCharge) => {
        if (bookingCharge.id === chargeId) {
          return {
            ...bookingCharge,
            charge_code_section: value,
          };
        }
        return bookingCharge;
      }
    );

    const updatedBooking = {
      ...booking,
      booking_charges: bookingCharges,
    };

    dispatchFunction({ type: "update", updatedBooking: updatedBooking });
  };

  const bookingsMapFunction = (booking: BookingWithReferral) => {
    return {
      booking_number:
        booking.has_wait === YesNo.Yes ? (
          <>
            <Col>
              <Row type="flex" justify="center">
                <Text>{booking.id}</Text>
              </Row>
            </Col>
            <Col>
              <Text strong>WAIT</Text>
            </Col>
          </>
        ) : (
          <Col>
            <Row type="flex" justify="center">
              <Text>{booking.id}</Text>
            </Row>
          </Col>
        ),
      name: `${booking.booking_defendent.name.last}, ${booking.booking_defendent.name.first} `,
      room_time: (
        <>
          <Col>
            <Form.Item label="Court Date">
              <DatePicker
                onChange={(val) =>
                  handleBookingCourtTimeChange(val, booking, false)
                }
                format={dateFormatList}
                value={
                  booking.booking_status
                    ? moment(
                        booking.booking_status &&
                          booking.booking_status.court_appearance_datetime
                          ? booking.booking_status.court_appearance_datetime
                          : undefined
                      )
                    : undefined
                }
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Court Time">
              <TimePicker
                use12Hours={true}
                minuteStep={15}
                format={"h:mm a"}
                onChange={(val) =>
                  handleBookingCourtTimeChange(val, booking, true)
                }
                value={
                  booking.booking_status
                    ? moment(
                        booking.booking_status &&
                          booking.booking_status.court_appearance_datetime
                          ? booking.booking_status.court_appearance_datetime
                          : undefined
                      )
                    : undefined
                }
              />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item label="Court Room">
              <Input
                onChange={(e) => handleBookingRoomChange(e, booking)}
                value={
                  booking.booking_status && booking.booking_status.court_room
                    ? booking.booking_status.court_room
                    : ""
                }
              />
            </Form.Item>
          </Col>
        </>
      ),
      date_of_birth: (
        <Form.Item>
          <DatePicker
            onChange={(val) => handleDobChange(val, booking)}
            format={dateFormatList}
            value={moment(booking.booking_defendent.date_of_birth)}
          />
        </Form.Item>
      ),
      pc: (
        <Form.Item>
          <Input
            style={{ width: "50px" }}
            onChange={(e: any) => {
              handlePCChange(e, booking);
            }}
            value={
              booking.booking_charges && booking.booking_charges.length > 0
                ? booking.booking_charges[0].charge_type
                  ? booking.booking_charges[0].charge_type
                  : ""
                : ""
            }
          />
        </Form.Item>
      ),

      charges: booking.booking_charges ? (
        <>
          {booking.booking_charges.map((bookingCharge, index) => {
            return (
              <Row type="flex" align="middle">
                <Form.Item>
                  <Input
                    onChange={(e: any) => {
                      handleChargeChange(e, bookingCharge.id, booking);
                    }}
                    value={bookingCharge.charge_code_section}
                  />
                </Form.Item>
                {index > 0 && (
                  <Form.Item style={{ marginLeft: "5px" }}>
                    <Icon
                      type="minus-circle-o"
                      onClick={() =>
                        handleRemoveChargeClicked(bookingCharge.id, booking)
                      }
                    />
                  </Form.Item>
                )}
              </Row>
            );
          })}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => handleAddChargeClicked(booking)}
            >
              <Icon type="plus" /> Add charge
            </Button>
          </Form.Item>
        </>
      ) : null,
      comments: (
        <TextArea
          value={booking.comments as string}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
            const { value } = event.target;
            handleBookingCommentsChange(value, booking);
          }}
        ></TextArea>
      ),
    };
  };

  const classificationFilterFunction = (
    booking: BookingWithReferral,
    classification: string
  ) => {
    return booking.classification === classification;
  };

  const newReferrals = bookings
    .filter((booking) =>
      classificationFilterFunction(booking, BookingClassification.Referral)
    )
    .map(bookingsMapFunction);

  const newArrests = bookings
    .filter((booking) =>
      classificationFilterFunction(booking, BookingClassification.NewArrest)
    )
    .map(bookingsMapFunction);

  const conditional = bookings
    .filter((booking) =>
      classificationFilterFunction(booking, BookingClassification.Conditional)
    )
    .map(bookingsMapFunction);

  const supervised = bookings
    .filter((booking) =>
      classificationFilterFunction(booking, BookingClassification.Supervised)
    )
    .map(bookingsMapFunction);

  const orBookings = [
    { name: <Text strong>NEW REFERRALS</Text> },
    ...newReferrals,
    { name: <Text strong>NEW ARRESTS</Text> },
    ...newArrests,
    { name: <Text strong>VOP CONDITIONAL</Text> },
    ...conditional,
    { name: <Text strong>VOP SUPERVISED</Text> },
    ...supervised,
  ];

  return (
    <div style={{ margin: "30px 0px" }}>
      <Table
        rowKey={(_record: any, index: number) => {
          return index.toString();
        }}
        rowClassName={getRowClassName}
        pagination={false}
        columns={orTransmittalColumns}
        dataSource={orBookings}
        title={() => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Title level={4}>{title}</Title>{" "}
          </div>
        )}
      />
    </div>
  );
}
