import * as React from "react";
import { Navbar } from "react-bulma-components";

export interface NavItemUnclickableProps {
  className?: string;
  children: React.ReactChildren | React.ReactChild | React.ReactNode;
}

type childrenAndMore = any & { children: React.ReactChildren };

const simpleDiv: (props: childrenAndMore) => JSX.Element = ({
  children: c,
  ...r
}) => <div {...r}>{c}</div>;

export const NavItemUnclickable = ({
  className,
  children,
}: NavItemUnclickableProps) => (
  <Navbar.Item className={className} renderAs={simpleDiv}>
    {children}
  </Navbar.Item>
);
