import { createContext } from "react";

export interface ParticipantListSearchContextType {
  query: string;
  onChange: (query: string) => void;
}

const ParticipantListSearchContext = createContext<
  ParticipantListSearchContextType
>({} as any);

export const ParticipantListSearchProvider =
  ParticipantListSearchContext.Provider;

export const ParticipantListSearchConsumer =
  ParticipantListSearchContext.Consumer;
