import * as React from "react";
import { Form } from "react-bulma-components";
import { KeyedString } from "../../shared/lib/graphql";

interface Props {
  onChange: (value: string) => void;
  options: KeyedString[];
  value: string;
}

export class LanguageSelect extends React.PureComponent<Props> {
  render() {
    const { onChange, options, value } = this.props;
    return (
      <Form.Field>
        <Form.Select
          onChange={(event: any) => {
            onChange(event.currentTarget.value);
          }}
          name={"language_select"}
          value={value}
        >
          {options.map(({ key, value }: KeyedString) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </Form.Select>
      </Form.Field>
    );
  }
}
