import graphql from "babel-plugin-relay/macro";

export const bookingGroupsGraphqlQuery = graphql`
  query BookingGroupsGraphqlQuery {
    booking_groups {
      booking_group {
        id
        created_at
        bookings {
          id
          booking_defendent {
            cii_number
            local_id
            cdl_id
            name {
              first
              last
            }
            date_of_birth
            sex
            race
          }
          booking_info {
            booking_sid
            local_id
            booking_number
            booking_datetime
            booking_address
          }
          booking_charges {
            id
            booking_charge_sid
            booking_sid
            booking_number
            booking_datetime
            charge_auth_type
            charge_code_section
            charge_type
            charge_datetime
            charge_description
            arrest_agency
            arrest_datetime
            crime_case_number
            court_warrant_number
          }
          booking_releases {
            charge_code_section
            charge_description
            charge_type
            charge_release_datetime
            charge_release_type
            warrant_number
            court_case_number
            jc_control
            created_datetime
          }
          booking_status {
            booking_number
            court_appearance_datetime
            court_reason
            court_room
          }
          classification
          imported_date
          comments
          is_eligible
          spanish_speaker
          has_wait
        }
        ineligible_bookings {
          id
          booking_defendent {
            cii_number
            local_id
            cdl_id
            name {
              first
              last
            }
            date_of_birth
            sex
            race
          }
          booking_info {
            booking_sid
            local_id
            booking_number
            booking_datetime
            booking_address
            booking_date
            arrest_agency
          }
          booking_charges {
            id
            booking_charge_sid
            booking_sid
            booking_number
            booking_datetime
            charge_auth_type
            charge_code_section
            charge_type
            charge_datetime
            charge_description
            arrest_agency
            arrest_datetime
            crime_case_number
            court_warrant_number
          }
          booking_releases {
            charge_code_section
            charge_description
            charge_type
            charge_release_datetime
            charge_release_type
            warrant_number
            court_case_number
            jc_control
            created_datetime
          }
          booking_status {
            booking_number
            court_appearance_datetime
            court_reason
            court_room
          }
          classification
          imported_date
          comments
          is_eligible
          spanish_speaker
          has_wait
        }
        email_sent
      }
    }
  }
`;
