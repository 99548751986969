import moment from "moment-timezone";

const DEV_HOSTNAME_RE = /^.*-dev(\b|\d)/;
const STG_HOSTNAME_RE = /^.*-(stg|staging)(\b|\d)/;

// is local or some -dev instances
export const skipIsDev = (hostname = window.location.hostname) => {
  if (windowIsLocal(hostname)) {
    return true;
  }

  // solano-cpc-dev is actually a staging environment. special case it
  if (hostname.startsWith("solano-cpc-dev")) {
    return false;
  }

  return DEV_HOSTNAME_RE.test(hostname);
};

export const isStaging = (hostname = window.location.hostname) => {
  // solano-cpc-dev is actually a staging environment. special case it
  if (hostname.startsWith("solano-cpc-dev")) {
    return true;
  }

  return STG_HOSTNAME_RE.test(hostname);
};

export const windowIsLocal = (hostname = window.location.hostname) =>
  hostname === "localhost" ||
  hostname.indexOf("192.168.") === 0 ||
  hostname.indexOf("10.") === 0 ||
  hostname.indexOf("172.16.") === 0;

export enum KnownLanguages {
  en = "en",
  es = "es",
}

export const languageName = (language: KnownLanguages): string => {
  switch (language) {
    case KnownLanguages.en:
      return "English";
    case KnownLanguages.es:
      return "Español";
    default:
      console.error(
        `Tried to display a language that was unexpected: ${language}`
      );
      return "";
  }
};

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmail = (email: string | null): boolean => {
  return email ? emailRegex.test(email) : false;
};

const colorMap: { [key: string]: RegExp } = {
  success: /^(good|green|success(ful)?)$/i,
  warning: /^(fair|yellow|warning|transferred|nonstarter|deceased|other)$/i,
  danger: /^(at[-\s]risk|red|unsuccess(ful)?|non\_compliant|incarcerated)$/i,
};

const colorMapGeneric: { [key: string]: string } = {
  success: "#6fbc22",
  warning: "#f5a800",
  danger: "#d02c2f",
  none: "#bfbfb",
};

export const formatPhoneNumberForDisplay = (phone?: string): string => {
  if (!phone) return "";
  // group phone pieces, lazy on the last "final", fewer than 6 digits will be null
  const result = /(\d{3})-?(\d{3})-?(\d{0,4})$/.exec(phone);

  if (!result || result.length < 4) return "";
  const [, area, prefix, final] = result;
  return `(${area}) ${prefix}-${final}`;
};

export const getStatusBulmaColor = (val?: string) => {
  if (!val || !val.trim()) {
    return null;
  }

  const result = Object.keys(colorMap).find((key) => colorMap[key].test(val));

  return result || "light";
};

export const getStatusColorGeneric = (val?: string) => {
  if (!val || !val.trim()) {
    return null;
  }

  const key =
    Object.keys(colorMapGeneric).find(
      (key) => colorMap[key] && colorMap[key].test(val)
    ) || "none";

  return colorMapGeneric[key];
};

export const humanizeDateOverLimit = (date: string, limit: number): string => {
  const mdate = moment(date);
  if (!mdate.isValid()) return "";

  const daysSince = Math.round(
    moment.duration(moment(new Date()).diff(mdate)).asDays()
  );

  return daysSince <= limit
    ? `${daysSince} days ago`
    : mdate.fromNow().toString();
};
