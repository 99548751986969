import * as React from "react";
import { Heading } from "react-bulma-components";
import { SentryErrorComponentProps } from "./SentryErrorBoundary";

export interface GenericErrorProps extends SentryErrorComponentProps {
  whatHappened?: string;
  whatShouldIDo?: string;
}

export class GenericErrorComponent extends React.PureComponent<
  GenericErrorProps
> {
  render() {
    const {
      whatHappened = "Something went wrong.",
      whatShouldIDo = "Promise has been alerted and will respond to this as soon as possible.",
    } = this.props;

    return (
      <div
        style={{
          margin: "100px auto",
          width: 420,
          padding: 60,
        }}
      >
        <Heading
          style={{
            color: "#4a6ada",
            fontSize: 40,
            margin: "0px 0px 30px 0px",
            fontWeight: "bold",
          }}
        >
          Promise.
        </Heading>
        <p className="is-size-5 has-text-weight-bold">{whatHappened}</p>
        <p
          style={{
            marginTop: 10,
          }}
        >
          {whatShouldIDo}
        </p>
      </div>
    );
  }
}
