import {
  fetchQuery as rrFetchQuery,
  GraphQLTaggedNode,
  Variables,
} from "relay-runtime";

import DefaultEnvironment, {
  getUncachedConfiguredEnvironment,
} from "./ConfiguredEnvironment";

export const fetchQuery = async (
  query: GraphQLTaggedNode,
  variables: Variables,
  useCache: boolean = true
) => {
  const environment = useCache
    ? DefaultEnvironment.relayEnvironment
    : getUncachedConfiguredEnvironment().relayEnvironment;

  return rrFetchQuery(environment, query, variables);
};
