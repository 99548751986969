import fetcher from "./fetcher/fetcher";
import { windowIsLocal } from "./util";
import { PROJECT_CONFIGS, ProjectConfigType } from "./auth/firebase";

const localStorageFbProjectKey = "fb_project";

export function getProjectIdFromConfig(
  host: string,
  projectConfigs: { [p: string]: ProjectConfigType }
): string | undefined {
  const projectConfig = Object.keys(projectConfigs)
    .map((key) => projectConfigs[key])
    .find((config) => config.authDomain === host);
  return projectConfig ? projectConfig.projectId : undefined;
}

export default function sniffProject(): string {
  let firebaseProject: string | null = "";
  if (windowIsLocal()) {
    const q = (window.location.search || "?").substring(1);

    const projectKVP = (q as any)
      .split("&")
      .map((part: string) => part.split("="))
      // eslint-disable-next-line array-callback-return
      .find(([key]: [string, string]) => key === "project");

    // TODO: not as any
    firebaseProject = projectKVP ? projectKVP[1] : undefined;

    if (window.location.href.indexOf("/iframe.html") > 0) {
      firebaseProject = "unknown-storybook-project";
    }

    // without a project (when in dev), check localStorage
    if (!firebaseProject) {
      firebaseProject = localStorage.getItem(localStorageFbProjectKey);
    }

    if (!firebaseProject) {
      throw new Error(
        "Invalid dev environment. In dev mode you must specify a firebase project to use by adding" +
          " the query param to the url, e.g. ?project=impression-sunrise"
      );
    } else {
      localStorage.setItem(localStorageFbProjectKey, firebaseProject);
      fetcher.setRootUrl(
        `/${firebaseProject}/us-central1/firestoreGraphqlServer`
      );
    }
  } else {
    const projectIdFromConfig = getProjectIdFromConfig(
      window.location.host,
      PROJECT_CONFIGS
    );
    if (projectIdFromConfig) {
      firebaseProject = projectIdFromConfig;
    } else {
      const match = matchingDomainRe.exec(window.location.host);
      if (match) {
        firebaseProject = match[1];
      } else {
        // Note that this will have to be configured as part of the deploy process,
        // and have the variable written to the HTML file.  Shane hasn't done
        // this yet so the app will only work in dev mode!
        firebaseProject = "default";
      }
    }
  }

  return firebaseProject;
}

const matchingDomainRe = /(.*)\.(firebaseapp\.com|clients\.joinpromise\.com)/;
