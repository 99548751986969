import React, { useState } from "react";
import { PageHeader } from "../../shared/components/elements/PageHeader";
import { bookingsGraphqlQuery } from "./BookingsGraphql";
import { QueryRenderer } from "../../shared/lib/graphql";
import { Booking, BookingGroup } from "../../graphql-types";
import { Button, Typography, Collapse } from "antd";
import { TransmittalCreation } from "./TransmittalCreation";
import { bookingGroupsGraphqlQuery } from "./BookingGroupsGraphql";
import { BookingGroupContainer } from "./BookingGroupContainer";
import moment from "moment-timezone";
import idx from "idx.macro";
import "./Bookings.scss";

interface BookingGroupGqlProps {
  booking_groups: {
    booking_group: BookingGroup[];
  };
}

const { Text } = Typography;
const { Panel } = Collapse;

export interface ClassesQueryGqlProps {
  bookings: {
    booking: Booking[];
  };
}

export function Bookings() {
  return (
    <>
      <QueryRenderer
        query={bookingGroupsGraphqlQuery}
        SuccessComponent={(props: BookingGroupGqlProps) => {
          const bookingGroups = idx(
            props,
            (_) => _.booking_groups.booking_group
          ) as BookingGroup[];

          const bookingGroupsNonNull = bookingGroups.filter(
            (bookingGroup) => !!bookingGroup
          );

          const bookingGroupsToday = bookingGroupsNonNull.filter(
            (bookingGroup) => {
              const { created_at: createdAt } = bookingGroup;
              const createdAtMoment = moment(createdAt);
              return (
                moment().format("MM/DD/YYYY") ===
                createdAtMoment.format("MM/DD/YYYY")
              );
            }
          );

          const bookingGroupsOld = bookingGroupsNonNull.filter(
            (bookingGroup) => {
              const { created_at: createdAt } = bookingGroup;
              const createdAtMoment = moment(createdAt);
              return moment().date() !== createdAtMoment.date();
            }
          );

          return (
            <QueryRenderer
              query={bookingsGraphqlQuery}
              SuccessComponent={(props: ClassesQueryGqlProps) => {
                const {
                  bookings: { booking: bookings },
                } = props;

                const [isNewOrReportOpen, setIsNewOrReportOpen] = useState(
                  false
                );

                return (
                  <div>
                    <PageHeader
                      pageName="OR Transmittal Report"
                      pageBlurb={`View and create OR Transmittal reports`}
                      headerRight={
                        isNewOrReportOpen ? (
                          <Button
                            type="primary"
                            onClick={() => setIsNewOrReportOpen(false)}
                          >
                            Cancel
                          </Button>
                        ) : (
                          <Button
                            type="primary"
                            onClick={() => setIsNewOrReportOpen(true)}
                          >
                            Create New OR Transmittal Report
                          </Button>
                        )
                      }
                    ></PageHeader>
                    <div className="constrained-section">
                      <div style={{ margin: "20px 0px" }}>
                        <div style={{ marginBottom: "20px" }}>
                          <Text strong>Today's OR Transmittal Report</Text>
                        </div>
                        {isNewOrReportOpen && (
                          <TransmittalCreation bookings={bookings} />
                        )}

                        {bookingGroupsToday.length === 0 && !isNewOrReportOpen && (
                          <Button
                            type="primary"
                            onClick={() =>
                              setIsNewOrReportOpen(!isNewOrReportOpen)
                            }
                          >
                            {isNewOrReportOpen
                              ? "Cancel"
                              : "Create Today's OR Transmittal Report"}
                          </Button>
                        )}

                        {bookingGroupsToday.length > 0 &&
                          bookingGroupsToday.map((bookingGroup) => (
                            <BookingGroupContainer
                              isToday={true}
                              bookingGroup={bookingGroup}
                              key={bookingGroup.id}
                            />
                          ))}
                      </div>
                      <div style={{ margin: "20px 0px" }}>
                        <Collapse key={"past-bookings-collapse"}>
                          <Panel
                            key={"past-bookings"}
                            header={
                              <Text strong>Past Transmittal Reports</Text>
                            }
                          >
                            {bookingGroupsOld.map((bookingGroup) => (
                              <BookingGroupContainer
                                key={bookingGroup.id}
                                bookingGroup={bookingGroup}
                                isToday={false}
                              />
                            ))}
                          </Panel>
                        </Collapse>
                      </div>
                    </div>
                  </div>
                );
              }}
            />
          );
        }}
      />
    </>
  );
}
