export interface FormDataBase<T = any> {
  [key: string]: FormField<T>;
}

export interface FormField<TValue = string> {
  label: string;
  value: TValue;
  dateFormat?: string;
  text?: string;
}

export interface ValidationsType {
  [key: string]: ValidationItemType;
}

export enum ValidationItemStates {
  pristine = "pristine",
  valid = "valid",
}

export interface ValidationItemType {
  state: ValidationItemStates;
  errors: string[];
}

export enum Operation {
  Add = "Add",
  Edit = "Edit",
  Reactivate = "Record account change",
  Deactivate = "Deactivate account",
}
