/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type DeactivatedReason = "bench_warrant" | "deceased" | "incarcerated" | "non_compliant" | "nonstarter" | "other" | "success" | "transferred_alternative" | "transferred_another_county" | "transferred_treatment" | "%future added value";
export type ParticipantListTableGqlWithEventsSynchronousQueryVariables = {
    readonly risk?: string | null;
    readonly name_contains?: string | null;
    readonly case_manager_id?: string | null;
    readonly active_clients_only?: boolean | null;
    readonly secondary_case_manager_id?: string | null;
};
export type ParticipantListTableGqlWithEventsSynchronousQueryResponse = {
    readonly application: ({
        readonly risk_levels: ReadonlyArray<({
            readonly key: string;
            readonly value: string | null;
        }) | null> | null;
        readonly compliance_options: ReadonlyArray<({
            readonly key: string;
            readonly value: string | null;
        }) | null> | null;
    }) | null;
    readonly participants: ({
        readonly participant: ReadonlyArray<({
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
            readonly preferred_name: string | null;
            readonly risk_level: string | null;
            readonly program_title: string | null;
            readonly supervision_begin_date: string | null;
            readonly supervision_end_date: string | null;
            readonly status: string | null;
            readonly compliance: string | null;
            readonly sms_enabled: boolean | null;
            readonly sms_consent: boolean | null;
            readonly phone: ({
                readonly mobile: string | null;
            }) | null;
            readonly case_manager: ({
                readonly id: string;
                readonly name: {
                    readonly first: string;
                    readonly last: string | null;
                };
            }) | null;
            readonly tasks: ReadonlyArray<({
                readonly id: string;
                readonly created_at: string;
                readonly completed_at: string | null;
                readonly label: string | null;
                readonly type: string | null;
            }) | null> | null;
            readonly sent_message_unread_count: number | null;
            readonly deactivated_reason: DeactivatedReason | null;
            readonly events: ReadonlyArray<({
                readonly id: string | null;
                readonly date: string | null;
                readonly activity: {
                    readonly title: {
                        readonly en: string | null;
                    };
                };
            }) | null> | null;
            readonly address: ({
                readonly value: string | null;
            }) | null;
            readonly current_device: ({
                readonly platform: string | null;
            }) | null;
            readonly case_number: string | null;
            readonly is_active: boolean | null;
        }) | null> | null;
    }) | null;
};
export type ParticipantListTableGqlWithEventsSynchronousQuery = {
    readonly response: ParticipantListTableGqlWithEventsSynchronousQueryResponse;
    readonly variables: ParticipantListTableGqlWithEventsSynchronousQueryVariables;
};



/*
query ParticipantListTableGqlWithEventsSynchronousQuery(
  $risk: String
  $name_contains: String
  $case_manager_id: String
  $active_clients_only: Boolean
  $secondary_case_manager_id: String
) {
  application {
    risk_levels {
      key
      value
    }
    compliance_options {
      key
      value
    }
    id
  }
  participants(risk: $risk, name_contains: $name_contains, case_manager_id: $case_manager_id, active_clients_only: $active_clients_only, secondary_case_manager_id: $secondary_case_manager_id) {
    participant {
      id
      name {
        first
        last
      }
      preferred_name
      risk_level
      program_title
      supervision_begin_date
      supervision_end_date
      status
      compliance
      sms_enabled
      sms_consent
      phone {
        mobile
      }
      case_manager {
        id
        name {
          first
          last
        }
      }
      tasks {
        id
        created_at
        completed_at
        label
        type
      }
      sent_message_unread_count(unread_for: "all")
      deactivated_reason
      events {
        id
        date
        activity {
          title {
            en
          }
          id
        }
      }
      address {
        value
      }
      current_device {
        platform
      }
      case_number
      is_active
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "risk",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "name_contains",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "case_manager_id",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "active_clients_only",
            "type": "Boolean",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "secondary_case_manager_id",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "value",
        "args": null,
        "storageKey": null
    } as any), v2 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "key",
            "args": null,
            "storageKey": null
        } as any),
        (v1 /*: any*/)
    ], v3 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "risk_levels",
        "storageKey": null,
        "args": null,
        "concreteType": "KeyedString",
        "plural": true,
        "selections": (v2 /*: any*/)
    } as any), v4 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "compliance_options",
        "storageKey": null,
        "args": null,
        "concreteType": "KeyedString",
        "plural": true,
        "selections": (v2 /*: any*/)
    } as any), v5 = [
        ({
            "kind": "Variable",
            "name": "active_clients_only",
            "variableName": "active_clients_only"
        } as any),
        ({
            "kind": "Variable",
            "name": "case_manager_id",
            "variableName": "case_manager_id"
        } as any),
        ({
            "kind": "Variable",
            "name": "name_contains",
            "variableName": "name_contains"
        } as any),
        ({
            "kind": "Variable",
            "name": "risk",
            "variableName": "risk"
        } as any),
        ({
            "kind": "Variable",
            "name": "secondary_case_manager_id",
            "variableName": "secondary_case_manager_id"
        } as any)
    ], v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "name",
        "storageKey": null,
        "args": null,
        "concreteType": "PersonName",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "first",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "last",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v8 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "preferred_name",
        "args": null,
        "storageKey": null
    } as any), v9 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "risk_level",
        "args": null,
        "storageKey": null
    } as any), v10 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "program_title",
        "args": null,
        "storageKey": null
    } as any), v11 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "supervision_begin_date",
        "args": null,
        "storageKey": null
    } as any), v12 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "supervision_end_date",
        "args": null,
        "storageKey": null
    } as any), v13 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
    } as any), v14 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "compliance",
        "args": null,
        "storageKey": null
    } as any), v15 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "sms_enabled",
        "args": null,
        "storageKey": null
    } as any), v16 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "sms_consent",
        "args": null,
        "storageKey": null
    } as any), v17 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "phone",
        "storageKey": null,
        "args": null,
        "concreteType": "PersonPhone",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "mobile",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v18 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "case_manager",
        "storageKey": null,
        "args": null,
        "concreteType": "CaseManager",
        "plural": false,
        "selections": [
            (v6 /*: any*/),
            (v7 /*: any*/)
        ]
    } as any), v19 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "tasks",
        "storageKey": null,
        "args": null,
        "concreteType": "ParticipantTask",
        "plural": true,
        "selections": [
            (v6 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "created_at",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "completed_at",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "label",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "type",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v20 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "sent_message_unread_count",
        "args": [
            {
                "kind": "Literal",
                "name": "unread_for",
                "value": "all"
            }
        ],
        "storageKey": "sent_message_unread_count(unread_for:\"all\")"
    } as any), v21 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "deactivated_reason",
        "args": null,
        "storageKey": null
    } as any), v22 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "date",
        "args": null,
        "storageKey": null
    } as any), v23 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "title",
        "storageKey": null,
        "args": null,
        "concreteType": "i18nText",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "en",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v24 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "address",
        "storageKey": null,
        "args": null,
        "concreteType": "VerifiedAddress",
        "plural": false,
        "selections": [
            (v1 /*: any*/)
        ]
    } as any), v25 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "current_device",
        "storageKey": null,
        "args": null,
        "concreteType": "ParticipantMobileDevice",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "platform",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v26 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "case_number",
        "args": null,
        "storageKey": null
    } as any), v27 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "is_active",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "ParticipantListTableGqlWithEventsSynchronousQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "application",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Application",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        (v4 /*: any*/)
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participants",
                    "storageKey": null,
                    "args": (v5 /*: any*/),
                    "concreteType": "Pagination",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "participant",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Participant",
                            "plural": true,
                            "selections": [
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                (v9 /*: any*/),
                                (v10 /*: any*/),
                                (v11 /*: any*/),
                                (v12 /*: any*/),
                                (v13 /*: any*/),
                                (v14 /*: any*/),
                                (v15 /*: any*/),
                                (v16 /*: any*/),
                                (v17 /*: any*/),
                                (v18 /*: any*/),
                                (v19 /*: any*/),
                                (v20 /*: any*/),
                                (v21 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "events",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Event",
                                    "plural": true,
                                    "selections": [
                                        (v6 /*: any*/),
                                        (v22 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "activity",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "Activity",
                                            "plural": false,
                                            "selections": [
                                                (v23 /*: any*/)
                                            ]
                                        }
                                    ]
                                },
                                (v24 /*: any*/),
                                (v25 /*: any*/),
                                (v26 /*: any*/),
                                (v27 /*: any*/)
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "ParticipantListTableGqlWithEventsSynchronousQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "application",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Application",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v6 /*: any*/)
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participants",
                    "storageKey": null,
                    "args": (v5 /*: any*/),
                    "concreteType": "Pagination",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "participant",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Participant",
                            "plural": true,
                            "selections": [
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                (v9 /*: any*/),
                                (v10 /*: any*/),
                                (v11 /*: any*/),
                                (v12 /*: any*/),
                                (v13 /*: any*/),
                                (v14 /*: any*/),
                                (v15 /*: any*/),
                                (v16 /*: any*/),
                                (v17 /*: any*/),
                                (v18 /*: any*/),
                                (v19 /*: any*/),
                                (v20 /*: any*/),
                                (v21 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "events",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Event",
                                    "plural": true,
                                    "selections": [
                                        (v6 /*: any*/),
                                        (v22 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "activity",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "Activity",
                                            "plural": false,
                                            "selections": [
                                                (v23 /*: any*/),
                                                (v6 /*: any*/)
                                            ]
                                        }
                                    ]
                                },
                                (v24 /*: any*/),
                                (v25 /*: any*/),
                                (v26 /*: any*/),
                                (v27 /*: any*/)
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "ParticipantListTableGqlWithEventsSynchronousQuery",
            "id": null,
            "text": "query ParticipantListTableGqlWithEventsSynchronousQuery(\n  $risk: String\n  $name_contains: String\n  $case_manager_id: String\n  $active_clients_only: Boolean\n  $secondary_case_manager_id: String\n) {\n  application {\n    risk_levels {\n      key\n      value\n    }\n    compliance_options {\n      key\n      value\n    }\n    id\n  }\n  participants(risk: $risk, name_contains: $name_contains, case_manager_id: $case_manager_id, active_clients_only: $active_clients_only, secondary_case_manager_id: $secondary_case_manager_id) {\n    participant {\n      id\n      name {\n        first\n        last\n      }\n      preferred_name\n      risk_level\n      program_title\n      supervision_begin_date\n      supervision_end_date\n      status\n      compliance\n      sms_enabled\n      sms_consent\n      phone {\n        mobile\n      }\n      case_manager {\n        id\n        name {\n          first\n          last\n        }\n      }\n      tasks {\n        id\n        created_at\n        completed_at\n        label\n        type\n      }\n      sent_message_unread_count(unread_for: \"all\")\n      deactivated_reason\n      events {\n        id\n        date\n        activity {\n          title {\n            en\n          }\n          id\n        }\n      }\n      address {\n        value\n      }\n      current_device {\n        platform\n      }\n      case_number\n      is_active\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '1bdb957fc438f4c834fc235ce7eeb7d7';
export default node;
