import ModalWrapperHOC from "../../HOCs/ModalWrapperHOC";
import ModalWrapper from "../ModalWrapper";
import { FormField, FormDataBase, ValidationsType } from "../types";
import { getFeatureFlags } from "../../../featureFlags";

export interface AddCaseFormData extends FormDataBase {
  participant: FormField;
  caseNumber: FormField;
}

export interface ObligationAndParticipantDetailsType {
  first: string;
  last: string;
  createdBy: string;
}

class AddCaseModalWrapper extends ModalWrapper<AddCaseFormData> {
  static formState = getAddEventModalStatic(null);
  componentDidMount() {
    const { updateObject: obligationAndParticipantDetails } = this.props;

    if (obligationAndParticipantDetails) {
      AddCaseModalWrapper.formState = getAddEventModalStatic(
        obligationAndParticipantDetails
      );
    } else {
      AddCaseModalWrapper.formState = getAddEventModalStatic(null);
    }
  }
}

function getAddEventModalStatic(
  obligationAndParticipantDetails:
    | ObligationAndParticipantDetailsType
    | null
    | undefined
) {
  return {
    modalFormData: {
      participant: {
        label: getFeatureFlags().clientDisplayTerm || "Client",
        value: obligationAndParticipantDetails
          ? `${obligationAndParticipantDetails.first} ${obligationAndParticipantDetails.last}`
          : "",
      },
      caseNumber: {
        label: "Case Number",
        value: "",
      },
    } as AddCaseFormData,
    validations: {
      participant: {
        state: "valid",
        errors: [] as string[],
      },
      caseNumber: {
        state: "pristine",
        errors: [] as string[],
      },
    } as ValidationsType,
    attemptedSubmission: false,
    secondPage: false,
    secondPageMessageWarning: false,
    showModal: false,
  };
}

export default ModalWrapperHOC(AddCaseModalWrapper);
