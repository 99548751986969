import * as React from "react";
// import { SizeProp } from "@fortawesome/fontawesome-svg-core"; // wtf are the types here? maybe somewhere else more obvious?
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faCalendar,
//   // faCalendarCheck,
//   // faCalendarTimes,
//   faCommentAlt,
//   faEdit
// } from "@fortawesome/free-regular-svg-icons";
// import { faPaperclip } from "@fortawesome/free-solid-svg-icons";

import {
  Check,
  Redo,
  CalendarToday,
  Clear,
  Delete,
  Edit,
  Notes,
  MessageOutlined,
} from "@material-ui/icons";

import {
  ActivityMeta,
  LogEntryType,
  EventAttendence,
} from "../../lib/graphql/flowTypes";

export enum TimelineTypeModifiers {
  DELETED = "DELETED",
}

export enum TimelineEventIcons {
  CALENDAR = "CALENDAR",
  CALENDAR_ATTENDED = "CALENDAR_ATTENDED",
  CALENDAR_MISSED = "CALENDAR_MISSED",
  CALENDAR_DELETED = "CALENDAR_DELETED",
  CALENDAR_EDITED = "CALENDAR_EDITED",
  CHANGED = "CHANGED",
  NOTE_ADDED = "NOTE_ADDED",
  REMINDER_SENT = "REMINDER_SENT",
}

export const TimelineEventIcon = (
  icon: TimelineEventIcons,
  past: boolean
): JSX.Element => {
  switch (icon) {
    case TimelineEventIcons.CALENDAR:
      return <CalendarToday nativeColor={past ? "#bfbfbf" : "#3b7abe"} />;
    case TimelineEventIcons.CALENDAR_DELETED:
      return <Delete nativeColor="black" />;
    case TimelineEventIcons.CALENDAR_ATTENDED:
      return <Check nativeColor="#6fbc22" />;
    case TimelineEventIcons.CALENDAR_MISSED:
      return <Clear nativeColor="#d02c2f" />;
    case TimelineEventIcons.CALENDAR_EDITED:
      return <Redo nativeColor="#f5a800" />;
    case TimelineEventIcons.CHANGED:
      return <Edit nativeColor="#f5a800" />;
    case TimelineEventIcons.NOTE_ADDED:
      return <Notes nativeColor="#f5a800" />;
    case TimelineEventIcons.REMINDER_SENT:
      return <MessageOutlined nativeColor="black" />;
    default:
      console.error(
        `Unknown TimelineEventIcon received. Rendered empty. Received: "${icon}" "${past}".`
      );
      return <CalendarToday nativeColor="#4a6ada" />;
  }
};

const findEventType = (type: string, eventTypes?: Array<ActivityMeta>) => {
  if (!eventTypes || eventTypes.length === 0) return type;
  const foundEventType = eventTypes.find(({ key }: ActivityMeta) => {
    return key === type || key === `activity_${type}`;
  });

  return foundEventType ? foundEventType.timeline_event_type : type;
};

const getAttendedIcon = (attended: string) => {
  switch (attended) {
    case EventAttendence.Yes:
      return TimelineEventIcons.CALENDAR_ATTENDED;
    case EventAttendence.No:
      return TimelineEventIcons.CALENDAR_MISSED;
    case EventAttendence.Excused:
      return TimelineEventIcons.CALENDAR_ATTENDED;
    case EventAttendence.Rescheduled:
      return TimelineEventIcons.CALENDAR_EDITED;
    default:
      return TimelineEventIcons.CALENDAR;
  }
};

const getTimelineEventFromLogEntryAppointment = ({
  attended,
  past,
  typeModifier,
}: {
  attended: string;
  past: boolean;
  typeModifier: TimelineTypeModifiers | null;
}): JSX.Element => {
  const attendedIcon = getAttendedIcon(attended);

  if (typeModifier) {
    switch (typeModifier) {
      case TimelineTypeModifiers.DELETED:
        return TimelineEventIcon(TimelineEventIcons.CALENDAR_DELETED, past);
      default:
        console.error(
          `TimelineEventIcon got an unknown typeModifier "${typeModifier}"`
        );
        return TimelineEventIcon(undefined as any, undefined as any);
    }
  }

  if (attended == null || !attended.trim()) {
    return TimelineEventIcon(TimelineEventIcons.CALENDAR, past);
  } else {
    return TimelineEventIcon(attendedIcon, past);
  }
};

export const TimelineEventFromLogEntry = ({
  attended,
  eventTypes,
  past,
  type,
  typeModifier,
}: {
  attended: string;
  eventTypes?: Array<ActivityMeta>;
  past: boolean;
  type: string;
  typeModifier: TimelineTypeModifiers | null;
}): JSX.Element => {
  // use the eventTypes mapping if provided

  const switchOn = findEventType(type, eventTypes);
  switch (switchOn) {
    case "appointment":
      return getTimelineEventFromLogEntryAppointment({
        attended,
        past,
        typeModifier,
      });
    case "reminder_sent":
      return TimelineEventIcon(TimelineEventIcons.REMINDER_SENT, past);
    case "note":
      return TimelineEventIcon(TimelineEventIcons.NOTE_ADDED, past);
    case "changed":
    case "ParticipantUpdated":
    case LogEntryType.ParticipantActiveStatusChanged:
      return TimelineEventIcon(TimelineEventIcons.CHANGED, past);
    // notification types
    case LogEntryType.NotificationDelete:
    case LogEntryType.NotificationDeliveryFailure:
    case LogEntryType.NotificationDeliverySuccess:
    case LogEntryType.NotificationDeploy:
    case LogEntryType.NotificationMessageParticipant:
    case LogEntryType.NotificationQueue:
    case "new_event":
    case "upcoming_event":
    case "deleted_event":
    case "changed_event":
    case "new_message_for_participant":
    case "new_participant":
    case "new_task":
    case "deleted_task":
    case "changed_task":
      return TimelineEventIcon(TimelineEventIcons.REMINDER_SENT, past);
    default:
      return TimelineEventIcon(undefined as any, undefined as any);
  }
};
