import * as React from "react";
import {
  QueryRenderer as ReactRelayQueryRenderer,
  GraphQLTaggedNode,
} from "react-relay";
import { Environment } from "relay-runtime";
import { Notification } from "react-bulma-components";

import configuredEnvironment from "./ConfiguredEnvironment";
import { Loader } from "../../components/elements/Loader";

// Error is current type per Relay-Runtime types,
//  JP not convinced this is right / won't change.
export type ErrorType = Error;

export interface RelayResponse<T extends Object> {
  /**
   * The data requested.
   * Data key will be present for _any_ data that returns
   * Graphql will return any keys it can, even if others error. But if all error, this will be empty
   */
  data?: T;
  /**
   * `ref_params?` In Relay-Runtime typing for QueryResult,
   * Uncertain if it can actually be present - never seen it. Like a zebra
   */
  // ref_params?: { [name: string]: any };
}

export type ErrorProps = {
  error: ErrorType;
};

type LoadingComponentFn = () => JSX.Element;

export type QueryRendererProps<TResultProps> = {
  environment?: Environment;
  query: GraphQLTaggedNode;
  variables?: Object;
  ErrorComponent?: React.ComponentClass<{ error: Error }, any>;
  LoadingComponent?: React.ComponentClass<any, any> | LoadingComponentFn;
  SuccessComponent: (props: TResultProps) => JSX.Element | null;
};

const errorComponent = ({ error }: { error: Error }) => {
  console.error(error);
  return (
    <Notification color="danger">
      An error occurred while loading data.
    </Notification>
  );
};

export class QueryRenderer<TResultProps = any> extends React.PureComponent<
  QueryRendererProps<TResultProps>
> {
  render() {
    const {
      environment = configuredEnvironment.environment,
      query,
      variables,
      ErrorComponent = errorComponent,
      LoadingComponent = Loader,
      SuccessComponent,
    } = this.props;

    return (
      <ReactRelayQueryRenderer
        environment={environment}
        query={query}
        variables={variables}
        render={({ error, props }: { error: Error; props: TResultProps }) => {
          if (error) {
            console.error(error);
            return <ErrorComponent error={error} />;
          }
          if (!props) return <LoadingComponent />;
          return <SuccessComponent {...props} />;
        }}
      />
    );
  }
}
