import React from "react";
import { Form } from "react-bulma-components";

interface EditItemBoxProps {
  handleItemChange: Function;
  newItem: string;
}

function editItemBox(props: EditItemBoxProps) {
  const { handleItemChange, newItem } = props;
  return (
    <div className="goal-task-row">
      <div className="add-item-container">
        <Form.Textarea
          className="goals-tasks-input"
          onChange={handleItemChange}
          value={newItem}
        />
      </div>
    </div>
  );
}

export default editItemBox;
