import graphql from "babel-plugin-relay/macro";
import { ParticipantNote } from "../../shared/lib/graphql/index";

interface NotesParticipantPaginated {
  participant: NotesParticipant[];
}

export interface NotesParticipant {
  name: { first: string; last: string };
  id: string;
  notes: ParticipantNote[];
}

export interface ParticipantNotesType {
  participants: NotesParticipantPaginated;
  me: LoggedInCaseManager;
}

export interface NotesCaseManager {
  id: string;
  name: { first: string; last: string };
}

export interface LoggedInCaseManager {
  case_manager: NotesCaseManager;
}

export const ParticipantNotesQuery = graphql`
  query ParticipantNotesQuery($id: String!) {
    me {
      case_manager {
        id
        name {
          first
          last
        }
      }
    }
    participants(id: $id) {
      participant {
        id
        name {
          first
          last
        }
        notes {
          id
          label
          created_at
          updated_at
          case_manager {
            id
            name {
              first
              last
            }
          }
        }
      }
    }
  }
`;
