import * as React from "react";

import {
  SentryErrorBoundary,
  SentryErrorComponentProps,
} from "./SentryErrorBoundary";
import { GenericErrorComponent } from "./GenericErrorComponent";

interface AppErrorBoundaryProps {
  ErrorComponent?:
    | React.ComponentType<SentryErrorComponentProps>
    | React.SFC<SentryErrorComponentProps>;
}

/**
 * This Error Boundary is very generic
 *  Wrapping your top-level App Item is a good place to use this
 * It sends error information to Sentry
 *
 * Smaller error boundaries should be used to surround smaller
 *  portions of functionality
 */
export class AppErrorBoundary extends React.PureComponent<
  AppErrorBoundaryProps
> {
  render() {
    const { ErrorComponent = GenericErrorComponent } = this.props;
    return (
      <SentryErrorBoundary ErrorComponent={ErrorComponent}>
        {this.props.children}
      </SentryErrorBoundary>
    );
  }
}
