import * as React from "react";

import { sendErrorInfoToSentry } from "../../lib/analytics/sentry";

export interface SentryErrorComponentProps {
  error?: Error;
}

interface SentryErrorBoundaryProps {
  ErrorComponent:
    | React.ComponentType<SentryErrorComponentProps>
    | React.SFC<SentryErrorComponentProps>;
}

interface SentryErrorBoundaryState extends SentryErrorComponentProps {}

/**
 * Components can pass in custom ErrorComponents with the Sentry
 *  behavior otherwise in-tact
 */
export class SentryErrorBoundary extends React.PureComponent<
  SentryErrorBoundaryProps,
  SentryErrorBoundaryState
> {
  state = {
    error: undefined,
  };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    sendErrorInfoToSentry(error, errorInfo);
  }

  render() {
    const { ErrorComponent, children } = this.props;
    const { error } = this.state;

    if (!error) {
      return children;
    } else {
      return <ErrorComponent error={error} />;
    }
  }
}
