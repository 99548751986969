/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type MessageActor = "case_manager" | "participant" | "person_group" | "system" | "%future added value";
export type MessageStatus = "failed" | "succeeded" | "%future added value";
export type MutationResult = "failure" | "skipped" | "success" | "%future added value";
export type messagesSendMessageMutationVariables = {
    readonly text?: string | null;
    readonly participant_id?: string | null;
    readonly group_id?: string | null;
};
export type messagesSendMessageMutationResponse = {
    readonly addMessageFromCaseManager: ({
        readonly result: MutationResult;
        readonly description: string | null;
        readonly errors: ReadonlyArray<string | null> | null;
        readonly newMessage: ({
            readonly id: string;
            readonly text: string | null;
            readonly created_at: string | null;
            readonly sender_type: MessageActor | null;
            readonly recipient_type: MessageActor | null;
            readonly read_at: string | null;
            readonly sent_as: ReadonlyArray<string | null> | null;
            readonly delivery_status: MessageStatus | null;
            readonly sender: ({
                readonly uid: string;
                readonly name: ({
                    readonly first: string;
                    readonly last: string | null;
                }) | null;
            }) | null;
        }) | null;
    }) | null;
};
export type messagesSendMessageMutation = {
    readonly response: messagesSendMessageMutationResponse;
    readonly variables: messagesSendMessageMutationVariables;
};



/*
mutation messagesSendMessageMutation(
  $text: String
  $participant_id: String
  $group_id: String
) {
  addMessageFromCaseManager(text: $text, participant_id: $participant_id, group_id: $group_id) {
    result
    description
    errors
    newMessage {
      id
      text
      created_at
      sender_type
      recipient_type
      read_at
      sent_as
      delivery_status
      sender {
        uid
        name {
          first
          last
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "text",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "participant_id",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "group_id",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "addMessageFromCaseManager",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "group_id",
                    "variableName": "group_id"
                },
                {
                    "kind": "Variable",
                    "name": "participant_id",
                    "variableName": "participant_id"
                },
                {
                    "kind": "Variable",
                    "name": "text",
                    "variableName": "text"
                }
            ],
            "concreteType": "NewMessage",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "result",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "errors",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "newMessage",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Message",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "text",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "created_at",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "sender_type",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "recipient_type",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "read_at",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "sent_as",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "delivery_status",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "sender",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "MessageSender",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "uid",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "name",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "PersonName",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "first",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "last",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "messagesSendMessageMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "messagesSendMessageMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "messagesSendMessageMutation",
            "id": null,
            "text": "mutation messagesSendMessageMutation(\n  $text: String\n  $participant_id: String\n  $group_id: String\n) {\n  addMessageFromCaseManager(text: $text, participant_id: $participant_id, group_id: $group_id) {\n    result\n    description\n    errors\n    newMessage {\n      id\n      text\n      created_at\n      sender_type\n      recipient_type\n      read_at\n      sent_as\n      delivery_status\n      sender {\n        uid\n        name {\n          first\n          last\n        }\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '52a68f6ea985fede46a77fc1fb292d81';
export default node;
