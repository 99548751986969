import * as React from "react";
import { AnalyticsParticipantListItem } from "./analyticsTypes";
import SortableTable from "../../shared/components/table/SortableTable";
import moment from "moment";
import "./defaultAnalyticsTable.scss";
import { getFeatureFlags } from "../../featureFlags";

interface Props {
  data: Array<AnalyticsParticipantListItem>;
}

const DATA_COLUMNS = [
  {
    key: (item: AnalyticsParticipantListItem) => {
      return item.participant.name;
    },
    header: getFeatureFlags().clientDisplayTerm,
  },
  {
    key: (item: AnalyticsParticipantListItem) => {
      const dateStr = item["date"];

      return moment(dateStr).format("MM/DD/YYYY, hh:mm A");
    },
    header: "Added To Promise",
  },
  { key: "notification_count", header: "Total Notifications" },
  { key: "event_count", header: "Total Events" },
  { key: "attendance_data_count", header: "Events With Attendance Data" },
  {
    key: (item: AnalyticsParticipantListItem) => {
      return `${Math.round(item.attendance_rate * 100)}%`;
    },
    header: "Recorded Attendance Rate",
  },
].map((d) => ({
  ...d,
  sortable: false,
}));

export default class ClientSummaryTable extends React.Component<Props> {
  render() {
    return (
      <div className="defaultAnalyticsTable">
        <SortableTable columns={DATA_COLUMNS} data={this.props.data} />
      </div>
    );
  }
}
