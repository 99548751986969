import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import idx from "idx.macro";
import { KeyedString, QueryRenderer } from "../../shared/lib/graphql";
import { ParticipantAccount } from "../participant-detail/ParticipantAccount";
import {
  ParticipantDetailsQuery,
  ParticipantDetailsQueryResult,
} from "./ParticipantDetailsQueryTypes";
import { GenericErrorComponent } from "../../shared/components/error-boundaries/GenericErrorComponent";

export interface ParticipantCarePlanContainerProps {
  id: string;
  name?: string;
  selectedTab?: string;
}

export class ParticipantDetailsContainer extends React.PureComponent<
  RouteComponentProps<ParticipantCarePlanContainerProps>
> {
  render() {
    const { id, selectedTab } = this.props;
    return (
      <QueryRenderer
        query={ParticipantDetailsQuery}
        variables={{ id }}
        SuccessComponent={(props: ParticipantDetailsQueryResult) => {
          const participant = idx(props, (_) => _.participants.participant[0]);

          if (!participant) {
            console.error(`Participant not found, id: ${id}.`);
            return (
              <GenericErrorComponent whatHappened="Sorry, we can't find the person you were looking for." />
            );
          }

          const riskLevels: Array<KeyedString> =
            idx<Array<KeyedString>>(props, (_) => _.application.risk_levels) ||
            [];

          const complianceOptions: Array<KeyedString> =
            idx<Array<KeyedString>>(
              props,
              (_) => _.application.compliance_options
            ) || [];

          const twilioNumber: string =
            idx<string>(props, (_) => _.application.twilio.source_number) || "";

          return (
            <ParticipantAccount
              participant={participant}
              selectedTab={selectedTab}
              riskLevels={riskLevels}
              twilioNumber={twilioNumber}
              complianceOptions={complianceOptions}
            />
          );
        }}
      />
    );
  }
}
