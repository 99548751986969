import { Participant } from "../graphql-types";

export const formatParticipantName = ({
  name,
  preferred_name: preferredName,
}: Pick<Participant, "name" | "preferred_name">) => {
  if (!name) {
    return "";
  } else {
    const preferredNameUse =
      preferredName && preferredName.trim().length > 0
        ? ` "${preferredName}"`
        : "";

    return `${name.first}${preferredNameUse} ${name.last}`;
  }
};

export const formatParticipantNameLastFirst = ({
  name,
}: Pick<Participant, "name" | "preferred_name">) => {
  if (!name) {
    return "";
  }
  return `${name.last}, ${name.first}`;
};
