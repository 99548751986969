import React, { Component } from "react";
import moment, { Moment } from "moment";
import { Form, Columns } from "react-bulma-components";
import { Button } from "antd";
import {
  EVENT_TIME_INTERVAL,
  roundTimeIntervals,
} from "../../../shared/lib/modalFormHelpers";
import "../ModalForm.scss";
import "react-datepicker/dist/react-datepicker.css";
import { CarePlanContext } from "../../../utils/context";
import DatePickerWrapper from "../../DatePickerWrapper";
import ModalInput from "../ModalInput";
import ModalSelect from "../ModalSelect";
import { FormField, ValidationItemType } from "../types";
import { ObligationModalFormData } from "./AddObligationModalWrapper";
import { getFeatureFlags } from "../../../featureFlags";
import {
  NOT_SET,
  ModalInputTypes,
} from "../../../shared/lib/graphql/flowTypes";

type State = {};

interface EventFormData extends ObligationModalFormData {
  endDateIsEstimate: FormField<Moment>;
  isRequiredByCourt: FormField<Moment>;
}

type EventFormValidations = {
  participant: ValidationItemType;
  title: ValidationItemType;
  description: ValidationItemType;
  activity: ValidationItemType;
  serviceProvider: ValidationItemType;
  roomNumber: ValidationItemType;
  startDate: ValidationItemType;
  endDate: ValidationItemType;
};

export type AddObligationFormProps = {
  activityId: string;
  handleModalFormInputChange: Function;
  addEvent: Function;
  handleDatePickerChange: Function;
  handleDatePickerRawChange: Function;
  attemptedSubmission: boolean;
  handleFormSubmit: Function;
  modalFormData: EventFormData;
  validations: EventFormValidations;
  modalType: string;
};

export class AddObligationForm extends Component<
  AddObligationFormProps,
  State
> {
  _calculateEndTimeMinTime = () => {
    const { startDate, endDate } = this.props.modalFormData;
    if (!startDate.value || !endDate.value) {
      return roundTimeIntervals({
        date: moment().clone().startOf("day"),
        interval: EVENT_TIME_INTERVAL,
      });
    }
    return startDate.value.format("L") === endDate.value.format("L")
      ? roundTimeIntervals({
          date: startDate.value.clone().add(EVENT_TIME_INTERVAL, "minutes"),
          interval: EVENT_TIME_INTERVAL,
        })
      : roundTimeIntervals({
          date: endDate.value.clone().startOf("day"),
          interval: EVENT_TIME_INTERVAL,
        });
  };

  _calculateEndTimeMaxTime = () => {
    const { startDate, endDate } = this.props.modalFormData;
    if (!startDate.value || !endDate.value) {
      return roundTimeIntervals({
        date: moment().clone().endOf("day"),
        interval: EVENT_TIME_INTERVAL,
      });
    }
    return startDate.value.clone().endOf("day");
  };

  render() {
    const {
      activityId,
      handleModalFormInputChange,
      modalFormData,
      handleFormSubmit,
      validations,
      attemptedSubmission,
      handleDatePickerChange,
      handleDatePickerRawChange,
    } = this.props;

    const {
      serviceProvider,
      roomNumber,
      title,
      description,
      startDate,
      endDate,
    } = modalFormData;

    return (
      <CarePlanContext.Consumer>
        {({ activityInformation, participant, serviceProviders }) => {
          const {
            name: { first, last },
          } = participant;

          const activityItem =
            activityInformation.find(({ key }) => key === activityId) ||
            ({} as any);
          const activityTitle = activityItem.title || activityItem.key;

          const serializedServiceProviders = serviceProviders.map(
            ({ id: key, title }) => ({ key, value: title || key })
          );

          serializedServiceProviders.unshift({
            key: NOT_SET,
            value: "Select a service provider...",
          });

          return (
            <div className="modal-form">
              <Form.Field>
                <Form.Label>{getFeatureFlags().clientDisplayTerm}</Form.Label>
                <Form.Control>
                  <Form.Input
                    disabled={true}
                    type="text"
                    value={`${first} ${last}`}
                  />
                </Form.Control>
              </Form.Field>
              <Form.Field>
                <Form.Label>Activity</Form.Label>
                <Form.Control>
                  <Form.Input
                    disabled={true}
                    type="text"
                    value={activityTitle}
                  />
                </Form.Control>
              </Form.Field>
              <ModalInput
                label={title.label}
                validations={validations}
                value={title ? title.value : activityTitle}
                name={"title"}
                inputType={"title"}
                toolTipText={`This title is visible to the ${
                  getFeatureFlags().clientDisplayTerm
                } in the Promise mobile app`}
                placeholder={`Attend all ${activityTitle}s`}
                attemptedSubmission={attemptedSubmission}
                handleModalFormInputChange={handleModalFormInputChange}
              />
              <ModalInput
                label={description.label}
                validations={validations}
                value={description.value}
                name={"description"}
                textArea={true}
                inputType={"description"}
                toolTipText={`This description is visible to the ${
                  getFeatureFlags().clientDisplayTerm
                } in the Promise mobile app`}
                placeholder={
                  "This obligation is an important part of your program. We want to help you get the support you need."
                }
                attemptedSubmission={attemptedSubmission}
                handleModalFormInputChange={handleModalFormInputChange}
              />
              <Form.Field>
                <Columns className="modal-column">
                  <Columns.Column size="two-thirds" className="first-half">
                    <ModalSelect
                      modalStateKey="serviceProvider"
                      label={serviceProvider.label}
                      validations={validations}
                      value={serviceProvider.value}
                      name={"serviceProvider"}
                      inputType={ModalInputTypes.Select}
                      attemptedSubmission={attemptedSubmission}
                      handleModalFormInputChange={handleModalFormInputChange}
                      options={serializedServiceProviders}
                    />
                  </Columns.Column>
                  <Columns.Column size="one-third" className="second-half">
                    <ModalInput
                      label={roomNumber.label}
                      validations={validations}
                      value={roomNumber.value}
                      name={"roomNumber"}
                      inputType={"roomNumber"}
                      placeholder={"Room 305"}
                      attemptedSubmission={attemptedSubmission}
                      handleModalFormInputChange={handleModalFormInputChange}
                    />
                  </Columns.Column>
                </Columns>
              </Form.Field>
              <Form.Field>
                <Columns className="modal-column">
                  <Columns.Column size="half" className="first-half">
                    <DatePickerWrapper
                      selectedDate={startDate}
                      label={"Obligation Start Date"}
                      placeholder={"--/--/----"}
                      name={"startDate"}
                      popperPlacement={"top"}
                      validations={validations}
                      toolTipText="The date this obligation started."
                      onChange={(date: Moment) => {
                        handleDatePickerChange({
                          value: date,
                          name: "startDate",
                          type: "start-date-optional",
                        });
                      }}
                      onChangeRaw={handleDatePickerRawChange}
                      type={"start-date-optional"}
                    />
                  </Columns.Column>
                  <Columns.Column size="half" className="second-half">
                    <DatePickerWrapper
                      selectedDate={endDate}
                      name={"endDate"}
                      label={"Obligation End Date"}
                      toolTipText="The date this obligation is expected to end. Leave blank if there is no known date."
                      placeholder={"--/--/----"}
                      popperPlacement={"top"}
                      validations={validations}
                      onChange={(date: Moment) => {
                        handleDatePickerChange({
                          value: date,
                          name: "endDate",
                          type: "end-date-optional",
                        });
                      }}
                      onChangeRaw={handleDatePickerRawChange}
                      minDate={startDate.value ? startDate.value : undefined}
                      type={"end-date-optional"}
                    />
                  </Columns.Column>
                </Columns>
              </Form.Field>
              <Button
                type="primary"
                style={{ width: "100%" }}
                onClick={(event: any) => handleFormSubmit(event)}
              >
                Next
              </Button>
            </div>
          );
        }}
      </CarePlanContext.Consumer>
    );
  }
}
