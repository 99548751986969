import {
  MutationScoreParticipantAssessmentResultArgs,
  MutationAddParticipantAssessmentResultArgs,
} from "../graphql-types";
import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "../shared/lib/graphql/commitMutation";
import { RecordSourceSelectorProxy } from "relay-runtime";
import { checkMutationReturn } from "./util";

export const addParticipantAssessmentResult = async (
  variables: MutationAddParticipantAssessmentResultArgs
) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation participantAssessmentResultAddParticipantAssessmentResultMutation(
          $participant_id: String!
          $assessment_template_id: String!
          $scored_by: String!
          $case_number: String!
        ) {
          addParticipantAssessmentResult(
            participant_id: $participant_id
            assessment_template_id: $assessment_template_id
            scored_by: $scored_by
            case_number: $case_number
          ) {
            participant_assessment_result {
              id
              status
              case_number
              assessment_name
              participant_id
              assessment_id
              rating
              total_score
              scored_by
              created_at
              updated_at
              rating
              scores {
                id
                assessment_template_question_id
                score
                value
                question_text
                question_number
                question_options {
                  value
                  text
                }
              }
              ranges {
                rating
                min_score
                max_score
                failure_rate
                failure_to_appear_rate
                new_arrest_rate
              }
            }
            result
            description
            errors
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreAddAssessmentResult(
          store,
          "addParticipantAssessmentResult",
          variables.participant_id
        );
      },
    },
    "Error while adding assessment."
  );
};

export const scoreParticipantResult = async (
  variables: MutationScoreParticipantAssessmentResultArgs
) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation participantAssessmentResultScoreParticipantAssessmentResultMutation(
          $participant_assessment_result_id: String!
          $status: ParticipantAssessmentResultStatus!
          $assessment_result_scores: [ParticipantAssessmentResultScoresInput]!
        ) {
          scoreParticipantAssessmentResult(
            participant_assessment_result_id: $participant_assessment_result_id
            status: $status
            assessment_result_scores: $assessment_result_scores
          ) {
            participant_assessment_result {
              id
              status
              case_number
              assessment_name
              participant_id
              assessment_id
              rating
              total_score
              scored_by
              created_at
              updated_at
              rating
              scores {
                id
                assessment_template_question_id
                score
                value
                question_text
                question_number
                question_options {
                  value
                  text
                }
              }
              ranges {
                rating
                min_score
                max_score
                failure_rate
                failure_to_appear_rate
                new_arrest_rate
              }
            }
            result
            description
            errors
          }
        }
      `,
      variables,
    },
    "Error while scoring a participant assessment result"
  );
};

const updateRelayStoreAddAssessmentResult = (
  store: RecordSourceSelectorProxy,
  rootFieldName: string,
  participant_id: string
) => {
  const payload = checkMutationReturn(store, rootFieldName);

  if (!payload) return;

  const newAssessmentResult = payload.getLinkedRecord(
    "participant_assessment_result"
  );

  const participant = store.get(participant_id);

  if (!participant) {
    console.error(
      `Error while updating the store after ${rootFieldName}.\n` +
        " Can't find the participant in the store."
    );
    return;
  }

  const assessmentResultsList = participant.getLinkedRecords(
    "assessment_results"
  );

  //add new obligation to front of obligationsList
  const combinedAssessmentResults = [newAssessmentResult].concat(
    assessmentResultsList
  );

  //set the new Obligation
  participant.setLinkedRecords(combinedAssessmentResults, "assessment_results");
};
