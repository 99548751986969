import { commitMutation as rrCommitMutation } from "react-relay";
import { GraphQLTaggedNode, RecordSourceSelectorProxy } from "relay-runtime";

import ConfiguredEnvironment from "./ConfiguredEnvironment";

export interface rrCommitMutationArgsType<TVariables> {
  mutation: GraphQLTaggedNode;
  variables: TVariables;
  updater?: (store: RecordSourceSelectorProxy) => void;
  optimisticResponse?: object;
  optimisticUpdater?: (store: RecordSourceSelectorProxy) => void;
}

export const commitMutation = async <TVariables = any, TType = any>(
  rrCommitMutationArgs: rrCommitMutationArgsType<TVariables>,
  errorMessagePreamble: string
): Promise<TType> => {
  return (new Promise((resolve, reject) => {
    rrCommitMutation(ConfiguredEnvironment.environment, {
      ...rrCommitMutationArgs,
      onCompleted: resolve as any,
      onError: (err) => {
        console.error(errorMessagePreamble);
        console.error(err);
        reject(err);
      },
    });
  }) as any) as TType;
};
