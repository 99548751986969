import * as React from "react";
import { sendMessage } from "../../actions/messages";
import { Button } from "antd";

interface Props {
  defaultMessage: string;
  participantId: string;
  buttonText: string;
  chatEnabled: boolean;
}

interface State {
  buttonText: string;
  enabled: boolean;
}

export class SendChatButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      buttonText: props.chatEnabled ? props.buttonText : "Chat disabled",
      enabled: props.chatEnabled,
    };
  }

  _onClick() {
    this.setState({ buttonText: "Sending ...", enabled: false }, async () => {
      await this._sendMessage();
    });
  }

  async _sendMessage() {
    await sendMessage({
      group_id: "",
      participant_id: this.props.participantId,
      text: this.props.defaultMessage,
    });
    this.setState({ buttonText: "Sent!" }, () => {
      setInterval(() => this._resetButtonText(), 3000);
    });
  }

  _resetButtonText() {
    this.setState({
      buttonText: this.props.buttonText,
      enabled: this.props.chatEnabled,
    });
  }

  render(): React.ReactNode {
    return (
      <Button
        type={"primary"}
        disabled={!this.state.enabled}
        onClick={this._onClick.bind(this)}
      >
        {this.state.buttonText}
      </Button>
    );
  }
}
