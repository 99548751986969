export const languageSelectOptions = [
  { key: "1", value: "Choose a language..." },
  { key: "en", value: "English" },
  { key: "es", value: "Spansish" },
];

export const assessmentScoreSelectOptions = [
  { key: "1", value: "Choose an assessment score..." },
  { key: "2", value: "1" },
  { key: "3", value: "1" },
];

export const assessmentInstrumentSelectOptions = [
  { key: "1", value: "Choose an assessment score..." },
  { key: "2", value: "Option A" },
  { key: "3", value: "Option B" },
];

export const participantSelectOptions = [
  { key: "1", value: "Choose a participant..." },
  { key: "2", value: "Participant A" },
  { key: "3", value: "Participant B" },
  { key: "4", value: "Participant C" },
  { key: "5", value: "Participant D" },
];

export const activitySelectOptions = [
  { key: "1", value: "Choose an activity..." },
  { key: "2", value: "Activity A" },
  { key: "3", value: "Activity B" },
  { key: "4", value: "Activity C" },
];

export const serviceProviderSelectOptions = [
  { key: "1", value: "Choose an service provider..." },
  { key: "2", value: "Service provider A..." },
  { key: "3", value: "Service provider B..." },
  { key: "4", value: "Service provider C..." },
  { key: "5", value: "Service provider D..." },
];

export const caseManagerSelectRiskLevels = [
  { key: "1", value: "Choose an service provider..." },
  { key: "2", value: "Low" },
  { key: "3", value: "Medium" },
  { key: "4", value: "High" },
];
