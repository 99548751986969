import React, { useState } from "react";
import {
  Timeline,
  TimelineProps,
} from "../../shared/components/timeline/Timeline";
import { PersonName } from "../../../../shared/src/lib/graphql/flowTypes";
import { getFeatureFlags } from "../../featureFlags";
import { Button, Level } from "react-bulma-components";

export type ParticipantTimelineProps = {
  name: PersonName;
} & TimelineProps;

export function ParticipantTimeline(props: ParticipantTimelineProps) {
  const { timelineEvents, eventTypes, eventAttendanceTitles } = props;
  const [hideNotifications, setHideNotifications] = useState(
    !!getFeatureFlags().timeline.hideNotifications
  );

  const showText = hideNotifications ? "Show" : "Hide";

  return (
    <div>
      <Level>
        <Level.Side align="left">
          <Level.Item>
            <h3 className={`title is-4`}>Timeline</h3>
          </Level.Item>
          <Level.Item>
            <Button
              color="primary"
              outlined={true}
              onClick={() => setHideNotifications(!hideNotifications)}
            >
              {`${showText} notifications`}
            </Button>
          </Level.Item>
        </Level.Side>
      </Level>
      <Timeline
        timelineEvents={timelineEvents}
        eventTypes={eventTypes}
        hideNotifications={hideNotifications}
        eventAttendanceTitles={eventAttendanceTitles}
      />
    </div>
  );
}
