import { RecordSourceSelectorProxy, RecordProxy } from "relay-runtime";

export const checkMutationReturn = (
  store: RecordSourceSelectorProxy,
  rootFieldName: string
): RecordProxy | undefined => {
  const payload = store.getRootField(rootFieldName);

  if (!payload) {
    console.error(
      `Expected a payload while performing ${rootFieldName}, got: undefined.`
    );
    return;
  }

  const result = payload.getValue("result");

  if (result !== "success") {
    console.error(`Error returned from API during ${rootFieldName}. \n`);
    return;
  }

  return payload;
};

export const getParticipant = (
  store: RecordSourceSelectorProxy,
  rootFieldName: string,
  participantId: string
): RecordProxy | undefined => {
  const participant = store.get(participantId);

  if (!participant) {
    console.error(
      `Error while updating the store after ${rootFieldName}.\n` +
        " Can't find the participant in the store."
    );
    return;
  }

  return participant;
};
