import React, { Component } from "react";
import { Button } from "antd";
import { KeyedString } from "../../shared/lib/graphql/index";
import { ListItem } from "./ListItem";
import EditItemBox from "./EditItemBox";
import { UpdateParticipantPartialType } from "../participant-notes/NotesContainer";

interface State {
  addOpened: boolean;
  newItem: string;
  selectedEditIndex: number;
}

export interface ListItemType {
  id: string;
  label: string;
  options?: KeyedString[];
}

interface Props {
  titleSingular: string;
  titlePlural: string;
  listItems: ListItemType[];
  addMutation: Function;
  removeMutation: Function;
  updateMutation?: Function;
  updateTextMutation?: (variables: UpdateParticipantPartialType) => void;
  taskTypes?: KeyedString[];
  participantId: string;
  border?: boolean;
  caseManagerId?: string;
  type: string;
}

export class GoalTaskList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      addOpened: false,
      newItem: "",
      selectedEditIndex: -1,
    };
  }
  _toggleAdd = () => {
    this.setState((state) => ({
      addOpened: !state.addOpened,
    }));
  };

  _handleNewItemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState(() => ({
      newItem: value,
    }));
  };

  _handleAddNewItem = async () => {
    const { newItem } = this.state;
    const { participantId, addMutation } = this.props;
    const mutationAddVariables = {
      participant_id: participantId,
      label: newItem,
    };
    this.setState(() => ({
      addOpened: false,
      newItem: "",
    }));
    await addMutation(mutationAddVariables);
  };

  _handleRemoveItem = (id: string) => {
    const { type } = this.props;
    const typeIdKey = `${type.toLowerCase()}_id`;

    const { removeMutation, participantId } = this.props;
    const mutationRemoveVariables = {
      participant_id: participantId,
      [typeIdKey]: id,
    };

    removeMutation(mutationRemoveVariables);
  };

  _handleChangeCompletionStatus = (itemId: string, isCompleted: boolean) => {
    const { updateMutation, participantId } = this.props;

    if (!updateMutation) {
      console.error(
        "GoalTaskList handleChangeCompletionStatus called but no updateMutation was passed. Value not saved."
      );
      return;
    }

    const { type } = this.props;

    const typeIdKey = `${type.toLowerCase()}_id`;

    const mutationUpdateVariables = {
      participant_id: participantId,
      [typeIdKey]: itemId,
      is_completed: isCompleted,
    };

    updateMutation(mutationUpdateVariables);
  };

  private handleTypeChange = (task_id: string, type: string) => {
    const { updateMutation, participantId: participant_id } = this.props;

    if (!updateMutation) {
      console.error(
        "GoalTaskList handleTypeChange called but no updateMutation was passed. Value not saved."
      );
      return;
    }

    updateMutation({
      participant_id,
      task_id,
      type,
    });
  };

  _selectEditIndex = (selectedEditIndex: number) => {
    this.setState({ selectedEditIndex });

    // if one of the sub edit menus is opened
    // hide the edit menu
    if (selectedEditIndex) {
      this.setState({ addOpened: false });
    }
  };

  _resetEditIndex = () => {
    this.setState({ selectedEditIndex: -1 });
  };

  render() {
    const {
      titlePlural,
      titleSingular,
      listItems,
      taskTypes,
      border,
      caseManagerId,
      updateTextMutation,
    } = this.props;
    const { addOpened, newItem, selectedEditIndex } = this.state;
    const addButtonTitle = addOpened ? "Cancel" : `Add ${titleSingular}`;
    const goalTaskClass =
      border === false
        ? "goal-task-title-row"
        : "goal-task-title-row border-top";

    const activeListItems = listItems.filter(
      (listItem: { [key: string]: any }) => {
        if (listItem.hasOwnProperty("completed_at")) {
          return !listItem.completed_at;
        }
        return listItem;
      }
    );

    const completedListItems = listItems.filter(
      (listItem: { [key: string]: any }) => {
        if (listItem.hasOwnProperty("completed_at")) {
          return listItem.completed_at;
        }
      }
    );

    return (
      <div>
        <div className={goalTaskClass}>
          <div>
            <p>{titlePlural}</p>
          </div>
          <div
            style={
              addOpened
                ? {
                    display: "flex",
                    width: "150px",
                    justifyContent: "space-between",
                  }
                : undefined
            }
          >
            {selectedEditIndex === -1 && (
              <Button
                type={addOpened ? undefined : "primary"}
                onClick={this._toggleAdd}
              >
                {addButtonTitle}
              </Button>
            )}

            {addOpened && (
              <Button
                disabled={!newItem.trim()}
                type="primary"
                onClick={this._handleAddNewItem}
              >
                Add
              </Button>
            )}
          </div>
        </div>

        {addOpened && (
          <EditItemBox
            handleItemChange={this._handleNewItemChange}
            newItem={newItem}
          />
        )}

        <div>
          {listItems.length ? (
            <>
              {activeListItems.length > 0 && (
                <>
                  {activeListItems.map((listItem, index) => (
                    <ListItem
                      key={listItem.id}
                      {...listItem}
                      index={index}
                      selectedEditIndex={selectedEditIndex}
                      itemType={titleSingular}
                      taskTypes={taskTypes}
                      handleRemoveItem={this._handleRemoveItem}
                      onChangeCompletionStatus={
                        this._handleChangeCompletionStatus
                      }
                      handleChangeItemText={updateTextMutation}
                      onTypeChange={this.handleTypeChange}
                      caseManagerId={caseManagerId}
                      handleEditSelectIndex={this._selectEditIndex}
                      handleResetSelectIndex={this._resetEditIndex}
                    />
                  ))}
                </>
              )}
              <>
                {completedListItems.length > 0 &&
                  completedListItems.map((listItem, index) => (
                    <ListItem
                      key={listItem.id}
                      {...listItem}
                      index={index}
                      selectedEditIndex={selectedEditIndex}
                      itemType={titleSingular}
                      taskTypes={taskTypes}
                      handleRemoveItem={this._handleRemoveItem}
                      onChangeCompletionStatus={
                        this._handleChangeCompletionStatus
                      }
                      handleChangeItemText={updateTextMutation}
                      onTypeChange={this.handleTypeChange}
                      caseManagerId={caseManagerId}
                      handleEditSelectIndex={this._selectEditIndex}
                      handleResetSelectIndex={this._resetEditIndex}
                    />
                  ))}
              </>
            </>
          ) : (
            !addOpened && (
              <p>{`Currently no ${titleSingular.toLowerCase()}s`}</p>
            )
          )}
        </div>
      </div>
    );
  }
}
