import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { GenericErrorComponent } from "../../shared/components/error-boundaries/GenericErrorComponent";

class FourOhFour extends React.Component<RouteComponentProps> {
  render() {
    return (
      <GenericErrorComponent
        whatHappened="Hi! We can't find the page you're looking for."
        whatShouldIDo="If you clicked on a link or entered a URL, please check that it's
          correct."
      />
    );
  }
}

export default FourOhFour;
