import * as React from "react";
import idx from "idx.macro";

import {
  TimelineGraphql,
  TimelineGraphqlResult,
} from "../../shared/components/timeline/TimelineGql";
import { QueryRenderer } from "../../shared/lib/graphql/QueryRenderer";
import { ParticipantTimeline } from "./ParticipantTimeline";
import { ParticipantDetailsTabComponentProps } from "../screens/ParticipantDetailsTabs";
import { getFeatureFlags } from "../../featureFlags";

export type ParticipantTimelineContainerProps = {
  uri?: string;
  name?: string;
} & ParticipantDetailsTabComponentProps;

export class ParticipantTimelineContainer extends React.PureComponent<
  ParticipantTimelineContainerProps
> {
  render() {
    const { id } = this.props;

    return (
      <QueryRenderer
        query={TimelineGraphql}
        variables={{
          id,
        }}
        SuccessComponent={(props: TimelineGraphqlResult) => {
          const timelineEvents = idx(props, (_) => _.timeline.timeline_entry);
          const eventTypes = idx(
            props,
            (_) => _.application.activity_information
          );
          const name = idx(
            props,
            (_) => _.timeline.timeline_entry[0].participant.name
          );

          const eventAttendanceTitles = getFeatureFlags().eventAttendanceTitles;

          return (
            <div>
              <ParticipantTimeline
                name={name}
                timelineEvents={timelineEvents}
                eventTypes={eventTypes}
                eventAttendanceTitles={eventAttendanceTitles}
              />
            </div>
          );
        }}
      />
    );
  }
}
