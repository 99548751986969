/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GroupChatContainerQueryVariables = {};
export type GroupChatContainerQueryResponse = {
    readonly person_groups: ({
        readonly person_group: ReadonlyArray<({
            readonly id: string;
            readonly label: string;
        }) | null> | null;
    }) | null;
};
export type GroupChatContainerQuery = {
    readonly response: GroupChatContainerQueryResponse;
    readonly variables: GroupChatContainerQueryVariables;
};



/*
query GroupChatContainerQuery {
  person_groups(page_size: 1) {
    person_group {
      id
      label
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "person_groups",
            "storageKey": "person_groups(page_size:1)",
            "args": [
                {
                    "kind": "Literal",
                    "name": "page_size",
                    "value": 1
                }
            ],
            "concreteType": "Pagination",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "person_group",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PersonGroup",
                    "plural": true,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "label",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GroupChatContainerQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": (v0 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "GroupChatContainerQuery",
            "argumentDefinitions": [],
            "selections": (v0 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "GroupChatContainerQuery",
            "id": null,
            "text": "query GroupChatContainerQuery {\n  person_groups(page_size: 1) {\n    person_group {\n      id\n      label\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '2da5468ac641b78a93d9f338f4c3179a';
export default node;
