import { Moment } from "moment";

import { FormField } from "../../modals/types";

const HOURS_IS_SOON = 1;

export const isSoon = (scheduledFor: FormField<Moment>): boolean => {
  if (!scheduledFor || !scheduledFor.value) return false;

  const hoursUntil =
    new Date(
      (scheduledFor.value.toDate() as any) - (new Date() as any)
    ).getTime() /
    1000 /
    60 /
    60;

  // dates in the past are a different kettle and it looks weird to present them as "soon"
  return hoursUntil <= HOURS_IS_SOON && hoursUntil >= 0;
};

export const getNextButtonText = (
  scheduledFor: FormField<Moment>,
  isSecondPage: boolean
) =>
  scheduledFor && scheduledFor.value
    ? isSecondPage
      ? "Confirm Message"
      : "Next"
    : "Save as Draft";
