import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "../shared/lib/graphql/commitMutation";
import {
  MutationAddGroupClassArgs,
  MutationDeleteGroupClassArgs,
  Maybe,
} from "../graphql-types";
import { RecordSourceSelectorProxy } from "relay-runtime";
import { checkMutationReturn } from "./util";

export const addGroupClass = (variables: MutationAddGroupClassArgs) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation groupClassesAddGroupClassMutation(
          $start_date: String!
          $start_time: String!
          $end_date: String!
          $days_of_week: [String]!
          $participant_ids: [String]!
          $activity_id: String!
          $service_provider_id: String!
          $exclude_dates: [String!]
          $group_class_id: String
        ) {
          addGroupClass(
            start_date: $start_date
            start_time: $start_time
            end_date: $end_date
            days_of_week: $days_of_week
            participant_ids: $participant_ids
            activity_id: $activity_id
            service_provider_id: $service_provider_id
            exclude_dates: $exclude_dates
            group_class_id: $group_class_id
          ) {
            result
            description
            group_class {
              id
              participants {
                name {
                  first
                  last
                }
              }
              start_date
              end_date
              exclude_dates
              service_provider {
                id
                title
              }
              activity {
                id
                title {
                  en
                }
              }
              days_of_week
            }
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreAddGroupClass(
          store,
          "addGroupClass",
          variables.group_class_id
        );
      },
    },
    "Error while adding a group class"
  );
};

export const deleteGroupClass = (variables: MutationDeleteGroupClassArgs) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation groupClassesDeleteGroupClassMutation(
          $group_class_id: String!
        ) {
          deleteGroupClass(group_class_id: $group_class_id) {
            result
            description
            group_class {
              id
            }
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreRemoveGroupClass(store, variables.group_class_id);
      },
    },
    "Error while deleting a group class"
  );
};

const updateRelayStoreRemoveGroupClass = (
  store: RecordSourceSelectorProxy,
  deletedId: string
) => {
  store.delete(deletedId);
};

const updateRelayStoreAddGroupClass = (
  store: RecordSourceSelectorProxy,
  rootFieldName: string,
  groupClassId: Maybe<string>
) => {
  const payload = checkMutationReturn(store, rootFieldName);

  if (!payload) return;

  const newGroupClass = payload.getLinkedRecord("group_class");

  const root = store.getRoot();

  const groupClasses = root.getLinkedRecords("group_classes");

  if (!groupClasses) {
    return;
  }

  if (groupClassId) {
    const filteredOutGroupClassId = groupClasses.map((groupClass) => {
      if (!groupClass) {
        return groupClass;
      }
      return groupClass.getValue("id") !== groupClassId && newGroupClass
        ? groupClass
        : newGroupClass;
    });
    root.setLinkedRecords(filteredOutGroupClassId, "group_classes");
  } else {
    root.setLinkedRecords([newGroupClass, ...groupClasses], "group_classes");
  }
};
