import { loadHeap } from "./loadHeap";

export const initialize = (heapId: string) => {
  const loaded = loadHeap();

  if (!loaded) return;

  (window as any).heap.load(heapId);
};

export const identify = (id: string) => {
  if (!(window as any).heap) return;

  (window as any).heap.identify(id);
};

export const setCustomer = (customer: string) => {
  addUserProperty({ customer });
};

export const addUserProperty = (values: { [key: string]: any }) => {
  if (!(window as any).heap) return;

  (window as any).heap.addUserProperties(values);
};
