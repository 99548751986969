import * as React from "react";

import { NameComponentProps } from "../../shared/components/participantListTable";

import {
  formatParticipantName,
  formatParticipantNameLastFirst,
} from "../../utils/participant";
import { TabUrlBuilderFn } from "../screens/ParticipantDetailsTabs";
import { getFeatureFlags } from "../../featureFlags";

export const LinkedNameBuilder: (
  urlBuilderFn: TabUrlBuilderFn
) => (args: {
  Link: React.ComponentClass<any, any>;
  baseUrl: string;
}) => React.SFC<NameComponentProps> = (urlBuilderFn: TabUrlBuilderFn) => ({
  baseUrl,
  Link,
}) => ({ id, ...restName }: NameComponentProps): JSX.Element => {
  const displayName = getFeatureFlags().lastNameFirst
    ? formatParticipantNameLastFirst(restName)
    : formatParticipantName(restName);

  const participantUrl = urlBuilderFn(baseUrl)({ id, name: restName.name })
    .default;

  return <Link to={participantUrl}>{displayName}</Link>;
};
