import * as React from "react";
import idx from "idx.macro";
import moment from "moment-timezone";
import { debounce } from "lodash";
import { Columns, Level, Form } from "react-bulma-components";
import { Button } from "antd";
import { AddObligationModal } from "../modals/obligations/AddObligationModal";
import { Operation } from "../modals/types";
import {
  deleteObligation,
  addObligationEvent,
} from "../../actions/obligations";
import { AddEventFormData } from "../modals/events/AddEventModalWrapper";
import { Typography } from "antd";

import {
  CarePlanObligationType,
  CarePlanEventType,
  CarePlanEventTemplateType,
} from "./CarePlanQuery";
import { ObligationEvent } from "./ObligationEvent";
import { CarePlanContext } from "../../utils/context";
import AddEventModal from "../modals/events/AddEventModal";
import { Tooltip } from "antd";
import "../../shared/components/elements/antd-tooltip.css";
import { DeleteObligationWarning } from "./DeleteObligationWarning";
import { createRRuleSetString } from "./createRRuleSetString";

interface CarePlanItemObligationProps {
  obligation: CarePlanObligationType;
  isReadOnly?: boolean;
  isDeleteDisabled?: boolean;
}

export const getSortedEvents = (
  event_templates: CarePlanEventTemplateType[],
  descending = true
) => {
  // Hey! Sorting things on the frontend is a big no-no
  //  Wait, so why? Cuz it's not possible in a sane way on the backend ATM
  //  AND this list (event_templates / events) is an **unpaged** subcollection
  //  If we had filtering or paging then sorting on the frontend is a _terribad_ idea
  //    but we're not doing those things, so we're sorting on the frontend
  // 🚢 🐿
  // n.b. 🤓 technically we're also flattening here (cuz we want to sort on event)
  const events = event_templates.reduce(
    (allEvents, eventTemplate) => {
      allEvents.push(...eventTemplate.events);
      return allEvents;
    },
    [] as CarePlanEventType[]
  );

  if (descending) {
    events.sort(({ date: dateA }, { date: dateB }) => {
      const a = moment(dateA);
      const b = moment(dateB);
      if (b.isSame(a)) return 0;
      else return b.isSameOrBefore(a) ? -1 : 1;
    });
  } else {
    events.sort(({ date: dateA }, { date: dateB }) => {
      const a = moment(dateA);
      const b = moment(dateB);
      if (b.isSame(a)) return 0;
      else return b.isSameOrAfter(a) ? -1 : 1;
    });
  }

  return events;
};

interface CarePlanItemObligationState {
  sub_address_input: string;
  service_provider_input: string;
  showUpdateObligationModal: boolean;
}

export class CarePlanItemObligation extends React.PureComponent<
  CarePlanItemObligationProps,
  CarePlanItemObligationState
> {
  constructor(props: CarePlanItemObligationProps) {
    super(props);
    const {
      sub_address_for_event,
      service_provider: { id },
    } = this.props.obligation;

    this.state = {
      sub_address_input: sub_address_for_event || "",
      service_provider_input: id || "",
      showUpdateObligationModal: false,
    };
    this._handleInputMutation = debounce(this._handleInputMutation, 500);
    this._handleSelectMutation = debounce(this._handleSelectMutation, 500);
  }

  componentWillReceiveProps(nextProps: CarePlanItemObligationProps) {
    const {
      obligation: {
        sub_address_for_event,
        service_provider: { id },
      },
    } = nextProps;
    this.setState(() => ({
      sub_address_input: sub_address_for_event,
      service_provider_input: id,
    }));
  }
  _onEventAdded = async (
    { start, end, rrule }: AddEventFormData,
    closeModal: () => void
  ) => {
    const { obligation } = this.props;
    const date = start as string;
    const endDate = end as string;
    await addObligationEvent({
      obligation,
      date,
      end: endDate,
      rrule: createRRuleSetString(rrule, date, obligation.end),
    });
    closeModal();
  };

  _handleInputMutation = (
    mutationCallback: Function,
    mutationPayload: { [key: string]: string }
  ) => {
    mutationCallback(mutationPayload);
  };

  _handleSelectMutation = (
    mutationCallback: Function,
    mutationPayload: { [key: string]: string }
  ) => {
    mutationCallback(mutationPayload);
  };

  _handleSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    obligation_id: string,
    mutationCallback: Function
  ) => {
    const { name, value } = event.target;

    this.setState(() => ({ service_provider_input: value }));

    const mutationPayload = {
      obligation_id,
      [name]: value,
    };
    this._handleSelectMutation(mutationCallback, mutationPayload);
  };

  _handleInputChange = (
    event: any,
    obligation_id: string,
    mutationCallback: Function
  ) => {
    const { name, value } = event.target;

    this.setState(() => ({
      sub_address_input: value,
    }));

    const mutationPayload = {
      obligation_id,
      [name]: value,
    };
    this._handleInputMutation(mutationCallback, mutationPayload);
  };

  _toggleObligationModal = () => {
    this.setState((state) => ({
      showUpdateObligationModal: !state.showUpdateObligationModal,
    }));
  };

  render() {
    const { Text } = Typography;
    const {
      sub_address_input,
      service_provider_input,
      showUpdateObligationModal,
    } = this.state;
    const {
      obligation,
      isReadOnly = false,
      isDeleteDisabled = false,
    } = this.props;
    const {
      event_templates: eventTemplates,

      description: descriptionObj,
      title: { en: title },
    } = obligation;
    const description = idx(descriptionObj, (_) => _.en);
    const eventsDateAsc = getSortedEvents(eventTemplates);

    const hasRepeatingEvents = eventTemplates.filter((eventTemplate) => {
      return !!eventTemplate.rrule;
    }).length;

    return (
      <CarePlanContext.Consumer>
        {({ serviceProviders, participant, dispositionOptions }) => {
          const selectedServiceProvider = serviceProviders.filter(
            ({ id: key }) => {
              return key === service_provider_input;
            }
          );

          const { title: serviceProviderLabel } = selectedServiceProvider[0]
            ? selectedServiceProvider[0]
            : { title: "" };

          return (
            <>
              <Level className="obligation-details">
                <Level.Side align="left">
                  <Level.Item>
                    <Form.Field>
                      <Text underline strong={true}>
                        Service Provider
                      </Text>
                      <Form.Control>
                        <p>{serviceProviderLabel || "Not selected"}</p>
                      </Form.Control>
                    </Form.Field>
                  </Level.Item>
                  <Level.Item>
                    <Form.Field>
                      <Text underline strong={true}>
                        Room #
                      </Text>
                      <Form.Control>
                        <p>{sub_address_input || "Not selected"}</p>
                      </Form.Control>
                    </Form.Field>
                  </Level.Item>
                  <Level.Item>
                    <Form.Field>
                      <Text underline strong={true}>
                        Description
                      </Text>
                      <Form.Control>
                        <p>{description || "Not selected"}</p>
                      </Form.Control>
                    </Form.Field>
                  </Level.Item>
                </Level.Side>
                <Level.Side align="right">
                  <Level.Item>
                    <Button
                      onClick={this._toggleObligationModal}
                      disabled={isReadOnly}
                      type={"primary"}
                      className="edit-details edit-obligation"
                    >
                      Edit Details
                    </Button>
                  </Level.Item>
                  <Level.Item>
                    <DeleteObligationWarning
                      title={title}
                      isReadOnly={isReadOnly}
                      buttonText={"Delete obligation"}
                      onYes={() => deleteObligation(obligation)}
                      eventsCount={eventsDateAsc.length}
                    />
                  </Level.Item>
                </Level.Side>
              </Level>
              <Columns className="carePlanItemSetup">
                {showUpdateObligationModal && (
                  <AddObligationModal
                    operation={Operation.Edit}
                    type={"Obligation"}
                    updateObject={obligation}
                    participant={participant}
                    activityId={obligation.activity.id}
                    handleModalClose={this._toggleObligationModal}
                    disableButton={isReadOnly}
                  />
                )}
                <Columns.Column
                  style={{
                    flexDirection: "column",
                    backgroundColor: "white",
                    padding: "1em",
                    marginBottom: "1em",
                  }}
                >
                  <Level>
                    <Level.Side align="left">
                      <h5 className="title is-5">
                        Event Schedule
                        {hasRepeatingEvents ? (
                          <span
                            className="has-text-grey is-size-6"
                            style={{ marginLeft: 10 }}
                          >
                            Showing repeating events only for the next 8 days
                          </span>
                        ) : null}
                      </h5>
                    </Level.Side>
                    <Level.Side align="right">
                      <AddEventModal
                        updateObject={{
                          first: participant.name.first,
                          last: participant.name.last,
                          where: obligation.service_provider.title,
                          end: obligation.end,
                        }}
                        callBackForAPI={this._onEventAdded}
                        selectParticipant={false}
                        selectServiceProvider={false}
                        type="Event"
                        operation={Operation.Add}
                        buttonComponent={
                          !isReadOnly ? (
                            <Tooltip
                              title={`Schedule a new ${obligation.title.en} event`}
                            >
                              <Button
                                type="primary"
                                disabled={isReadOnly}
                                className="add-event add-event-inner"
                              >
                                Add Event
                              </Button>
                            </Tooltip>
                          ) : (
                            <Button
                              type="primary"
                              disabled={isReadOnly}
                              className="add-event-disabled add-event-disabled-inner"
                            >
                              Add Event
                            </Button>
                          )
                        }
                      />
                    </Level.Side>
                  </Level>

                  {eventsDateAsc.length > 0 &&
                    eventsDateAsc.map((event) => (
                      <ObligationEvent
                        key={event.id}
                        participant={participant}
                        event={event}
                        obligation={obligation}
                        isReadOnly={isReadOnly || isDeleteDisabled}
                        dispositionOptions={dispositionOptions}
                      />
                    ))}
                  {eventsDateAsc.length === 0 && (
                    <p>This obligation has no past or future events.</p>
                  )}
                </Columns.Column>
              </Columns>
            </>
          );
        }}
      </CarePlanContext.Consumer>
    );
  }
}
