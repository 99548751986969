import React, { useState } from "react";
import { Button, Modal, Form, Select, Typography } from "antd";
import { dataInput } from "../../actions/dataInput";
import { expireReviewItem } from "../../actions/reviewItems";

interface Props {
  workflowItem: any;
}

const { Option } = Select;
const { Text } = Typography;

export function OfflineDataEntryForm(props: Props) {
  const { workflowItem } = props;
  const [isModalOpen, setIsModalOpen] = useState();
  const [offlineMode, setOfflineMode] = useState();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const {
    participant: {
      name: { first, last },
    },
    obligation: {
      title: { en: titleEn },
    },
  } = workflowItem;

  const submitOfflineEntryForm = async () => {
    const {
      review_id: id,
      participant: { id: participantId },
      obligation: {
        activity: {
          data_input_field: dataInputField,
          data_input_type: dataInputType,
        },
      },
    } = workflowItem;

    const offlineDataEntryPayload = {
      participant_id: participantId,
      field: dataInputField,
      value: `Updated offline`,
      input_type: dataInputType,
      offline_mode: offlineMode,
    };

    await dataInput(offlineDataEntryPayload);

    await expireReviewItem({ id, reviewed: true, expire_immediately: true });

    toggleModal();
  };

  return (
    <>
      <Button onClick={toggleModal}>Report Offline</Button>
      <Modal
        title="Offline Reporting"
        visible={isModalOpen}
        onOk={submitOfflineEntryForm}
        onCancel={toggleModal}
      >
        <Text strong>{`${last}, ${first}: ${titleEn}`}</Text>
        <Form.Item label="Reporting Method">
          <Select
            placeholder="Please select reporting method"
            style={{ width: "200px" }}
            onChange={(value: string) => setOfflineMode(value)}
            value={offlineMode}
          >
            <Option value="email">Email</Option>
            <Option value="phone">Phone</Option>
            <Option value="walk_in">Walk-In</Option>
          </Select>
        </Form.Item>
      </Modal>
    </>
  );
}
