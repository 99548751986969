import * as React from "react";
import { Link, WindowLocation, LinkGetProps } from "@reach/router";
import { Navbar, Button, Dropdown } from "react-bulma-components";
import idx from "idx.macro";

import { withUser, WithUserProps } from "../../lib/contexts/UserContext";
import { QueryRenderer, CaseManager } from "../../lib/graphql";
import HeaderNavMenu from "./HeaderNavMenu";

import "./AppHeader.scss";
import { AppHeaderStatus } from "./AppHeaderStatus";
import { NavItemUnclickable } from "../elements/NavItemUnclickable";
import { AppHeaderGql, AppHeaderGqlResponse } from "./AppHeaderGql";

export interface SubNavOption {
  /**
   * Text to display for link
   */
  label: string;
  /**
   * Link for a sub navigation item.
   */
  link: string;
}

export interface NavigationOption {
  /**
   * Text to display for link
   */
  label: string;
  /**
   * Link for a navigation item. Disregarded if `subNavOptions` is included
   */
  link?: string;
  /**
   * Items to appear in a sub menu
   */
  subNavOptions?: SubNavOption[];

  disabled?: boolean;

  isExternalLink?: boolean;
}

export type isActiveLocationResult = {
  isACM: boolean;
  isSelf: boolean;
  isMatch: boolean;
};

export type isActiveLocationFn = (
  location: WindowLocation,
  isPartiallyCurrent: boolean,
  matchForAllClients: boolean
) => isActiveLocationResult;

/**
 * Why this logic for "is active" calculations?
 * route params would be the "right" way to go, but: https://github.com/PromiseNetwork/ts-cloud-functions/pull/476#issuecomment-477404951
 *
 */
const isActiveCalculator = (caseManager: CaseManager) => (
  location: WindowLocation,
  isPartiallyCurrent: boolean,
  matchForAllClients: boolean
): isActiveLocationResult => {
  const clientsMatch = /\/clients\/[^\/]+\/?(\w*)/i.exec(location.pathname);
  const isACM = clientsMatch !== null;
  const caseManagerId = (clientsMatch || ([] as any))[1];
  const loggedInUserIsCM = !!caseManager;
  const isSelf = loggedInUserIsCM ? caseManager.id === caseManagerId : false;
  let isMatch = false;
  if (!isPartiallyCurrent || !isACM) {
    isMatch = isPartiallyCurrent;
  } else {
    // special logic only for isACM urls
    if (!loggedInUserIsCM) {
      isMatch = matchForAllClients ? !caseManagerId : !!caseManagerId;
    } else {
      isMatch = matchForAllClients ? isACM && isSelf : isACM && !isSelf;
    }
  }

  return {
    isACM,
    isSelf,
    isMatch,
  };
};

export type NavigationOptionBuilder = (
  data?: AppHeaderGqlResponse
) => NavigationOption | null;

interface AdditionalProps {
  navigationOptions?: Array<NavigationOption | NavigationOptionBuilder>;
  renderSearchBar?: () => React.ReactElement<any>;
}

interface AppHeaderProps extends WithUserProps {}

type AppHeaderPropsAll = AdditionalProps & AppHeaderProps;
type AppHeaderState = {
  navBurgerIsOpen: boolean;
};

export class AppHeaderComp extends React.PureComponent<
  AppHeaderPropsAll,
  AppHeaderState
> {
  constructor(props: AppHeaderPropsAll) {
    super(props);
    this.state = {
      navBurgerIsOpen: false,
    };
  }
  componentDidMount() {
    this.validateNavigationOptions(this.props, undefined);
  }

  private getNavigationOptions = (
    { navigationOptions }: AppHeaderPropsAll,
    data: AppHeaderGqlResponse | undefined
  ): NavigationOption[] => {
    if (!navigationOptions) return [];

    return (
      navigationOptions
        .map((option) => {
          if (typeof option === "function") return option(data);
          else return option;
        })
        // typescript is failing to infer non-null on this filter
        .filter((x) => !!x) as NavigationOption[]
    );
  };

  private validateNavigationOptions = (
    props: AppHeaderPropsAll,
    data: AppHeaderGqlResponse | undefined
  ) => {
    const brokenOptions = this.getNavigationOptions(props, data).filter(
      (option) =>
        !option ||
        (!option.link && !option.subNavOptions) ||
        (option.subNavOptions && option.subNavOptions!.length === 0)
    );

    if (brokenOptions.length > 0) {
      throw new Error("App Header: Misconfigured Navigation Options");
    }
  };

  render() {
    const {
      userContext: { logout, user },
      renderSearchBar,
    } = this.props;

    return (
      <Navbar className="header">
        <Navbar.Brand style={{ alignItems: "center" }}>
          <Link className="logo" to={"/"}>
            <svg
              width="38"
              height="38"
              viewBox="0 0 155 155"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.8065 74.8925H50.197V29.8438H29.8065V74.8925Z"
                fill="#EDA645"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.8065 123.261H50.197V94.3346H29.8065V123.261Z"
                fill="#CF3B29"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.8065 94.3334V74.5809H62.8964C69.6921 74.5809 75.2176 68.9718 75.2176 62.0802C75.2176 55.184 69.6921 49.5749 62.8964 49.5749H50.197V29.8438L63.9058 29.8438C81.7401 29.8438 96.1941 43.9805 96.1941 62.0802C96.1941 80.1798 81.7401 94.3334 63.9058 94.3334H29.8065Z"
                fill="#008CB2"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M125 108.668C124.896 117.72 117.684 125.156 108.346 125.156C98.9429 125.156 91.6945 117.612 91.6945 108.47C91.6945 99.1353 98.9429 91.3844 108.346 91.3844C117.684 91.3844 124.896 99.0239 125 108.268V108.668Z"
                fill="white"
              />
            </svg>
          </Link>
        </Navbar.Brand>
        <QueryRenderer
          query={AppHeaderGql}
          SuccessComponent={(props: AppHeaderGqlResponse) => {
            const navigationOptions = this.getNavigationOptions(
              this.props,
              props
            );
            const caseManager = idx(props, (_) => _.me.case_manager);

            const isActiveLocation = isActiveCalculator(caseManager);

            return (
              <>
                {navigationOptions &&
                  navigationOptions.length > 0 &&
                  navigationOptions.map(
                    (navOption: NavigationOption, idx: number) => {
                      const hasSubOption =
                        navOption.subNavOptions &&
                        navOption.subNavOptions.length > 0;

                      return navOption.disabled ? null : (
                        <Navbar.Item
                          renderAs="div"
                          key={navOption.link || idx + 1}
                        >
                          {hasSubOption && (
                            <HeaderNavMenu
                              data={props}
                              navOption={navOption}
                              isActiveLocation={isActiveLocation}
                            />
                          )}
                          {!hasSubOption && navOption.isExternalLink ? (
                            <a className="linkText" href={navOption.link}>
                              {navOption.label}
                            </a>
                          ) : (
                            <Link
                              getProps={({
                                isPartiallyCurrent,
                              }: LinkGetProps) => ({
                                className: isPartiallyCurrent
                                  ? "linkTextActive"
                                  : "linkText",
                              })}
                              to={navOption.link}
                            >
                              {navOption.label}
                            </Link>
                          )}
                        </Navbar.Item>
                      );
                    }
                  )}
                <Navbar.Container position="end" className="user">
                  <AppHeaderStatus />
                  {renderSearchBar && renderSearchBar()}
                  {user ? (
                    <>
                      <NavItemUnclickable className="userName">
                        <Dropdown className="is-outlined is-small">
                          <Dropdown.Item
                            value="item"
                            className="dropdown-label"
                          >
                            {caseManager
                              ? `${caseManager.name.first} ${caseManager.name.last}`
                              : user.displayName}
                          </Dropdown.Item>
                          <Dropdown.Item value="item">
                            <Button text={true} onClick={logout}>
                              Sign Out
                            </Button>
                          </Dropdown.Item>
                        </Dropdown>
                      </NavItemUnclickable>
                    </>
                  ) : (
                    <Navbar.Item>Signed Out</Navbar.Item>
                  )}
                </Navbar.Container>
              </>
            );
          }}
        />
      </Navbar>
    );
  }
}

export const AppHeader = withUser<AdditionalProps, AppHeaderPropsAll>(
  AppHeaderComp
);
