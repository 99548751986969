import React, { useState } from "react";
import { LinkedTabs } from "../../shared/components/elements/LinkedTabs";
import { Link, RouteComponentProps } from "@reach/router";
import { PageHeader } from "../../shared/components/elements/PageHeader";
import { ROOT_PATH } from "../../AppInfo";
import { QueryRenderer } from "../../shared/lib/graphql";
import { bookingsGraphqlByIdQuery } from "../bookings/BookingsGraphql";
import { TabWithLinkTo } from "./Prospects";
import idx from "idx.macro";
import { ProspectBookingGraphqlData } from "./ProspectTableQueryContainer";
import { BookingDetail } from "../bookings/TransmittalCreation";
import {
  Booking,
  YesNo,
  ProspectStatus,
  BookingReleaseType,
} from "../../graphql-types";
import { Button, Typography, Row, Col, Modal, Select, Form } from "antd";
import {
  startBookingInterviewAndAssessment,
  declineBookingInterview,
  archiveBooking,
  createParticipantFromBooking,
  addBookingRelease,
} from "../../actions/bookings";
import { WrappedAssessmentForm } from "./AssessmentForm";
import { WrappedInterviewForm } from "./InterviewForm";
import "./prospects.scss";
import { ScarePrompt } from "../../shared/components/elements/ScarePrompt";
import {
  residenceFormFields,
  employmentFormFields,
  substanceAbuseHistoryFields,
  priorRecordFields,
  victimInfoFields,
  firearmFormFields,
  courtDecisionFormFields,
  releasedWithConditionFormFields,
  sentencedFormFields,
  petitionResolvedFormFields,
} from "./FormFields";
import { RecommendationFormPage } from "./RecommendationForm";
import { CourtMemoPage } from "./CourtMemoForm";
import { ProspectSummary } from "./ProspectSummary";
import { CourtDecisionFormWrapped } from "./CourtDecisionForm";

const { Text, Title } = Typography;

const { Option } = Select;

export enum ProspectDetailSections {
  Summary = "summary",
  InterviewAndAssessment = "interview_and_assessment",
  Recommendation = "recommendation",
  CourtDecision = "court_decision",
  EnrollOrArchive = "enroll_or_archive",
}

const tabTitlesAndKeys: { value: string; key: ProspectDetailSections }[] = [
  { value: "Booking Info and Charges", key: ProspectDetailSections.Summary },
  {
    value: "Interview and Assessment",
    key: ProspectDetailSections.InterviewAndAssessment,
  },
  { value: "Recommendation", key: ProspectDetailSections.Recommendation },
  { value: "Court Decision", key: ProspectDetailSections.CourtDecision },
  { value: "Enroll/Archive", key: ProspectDetailSections.EnrollOrArchive },
];

const getValidSection = (
  section: string | undefined
): ProspectDetailSections => {
  const validatedSection = tabTitlesAndKeys.find(({ key }) => {
    return key === section;
  });

  return validatedSection && validatedSection.key
    ? validatedSection.key
    : ProspectDetailSections.Summary;
};

export function ProspectDetail(
  props: RouteComponentProps<{ id: string; section: string }>
) {
  const { id, section } = props;

  const [showReleasedModal, setShowReleasedModal] = useState<boolean>(false);
  const [releaseType, setReleaseType] = useState<BookingReleaseType>(
    BookingReleaseType.Bail
  );

  const handleChange = (value: BookingReleaseType) => {
    setReleaseType(value);
  };

  const handleBookingReleaseModalOkPressed = async () => {
    if (!id) {
      return;
    }
    await addBookingRelease({
      booking_id: id,
      release_type: releaseType,
    });

    setShowReleasedModal(false);
  };

  const validatedSection = getValidSection(section);

  const prospectTabsList: TabWithLinkTo[] = tabTitlesAndKeys.map((tab) => {
    const { key } = tab;
    return {
      ...tab,
      linkTo: `/${ROOT_PATH}/prospects/${id}/${key}`,
      selected: key === validatedSection,
    };
  });

  return (
    <>
      <Modal
        title="Released from Custody"
        visible={showReleasedModal}
        onOk={handleBookingReleaseModalOkPressed}
        onCancel={() => setShowReleasedModal(false)}
      >
        <Form.Item label="Release Type">
          <Select
            style={{ width: 120 }}
            value={releaseType}
            onChange={handleChange}
          >
            <Option value={BookingReleaseType.Bail}>BAIL</Option>
            <Option value={BookingReleaseType.Pta}>PTA</Option>
            <Option value={BookingReleaseType.Reor}>REOR</Option>
            <Option value={BookingReleaseType.Rncf}>RNCF</Option>
            <Option value={BookingReleaseType.Aowp}>AOWP</Option>
          </Select>
        </Form.Item>
      </Modal>
      <QueryRenderer
        query={bookingsGraphqlByIdQuery}
        variables={{ id }}
        SuccessComponent={(props: ProspectBookingGraphqlData) => {
          const [booking] = idx(props, (_) => _.bookings.booking);
          return (
            <>
              <PageHeader
                turnOffBottomPadding={true}
                pageName={`Prospect Details`}
              >
                <div
                  style={{
                    padding: "15px 0px 15px 0px",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <div style={{ width: "80%" }}>
                    <BookingDetail booking={booking} />
                  </div>
                </div>
              </PageHeader>

              <div className="constrained-section">
                <Button
                  type="primary"
                  onClick={() => setShowReleasedModal(!showReleasedModal)}
                >
                  Released from custody
                </Button>

                <LinkedTabs
                  tabs={prospectTabsList}
                  LinkComponent={Link}
                  tabProps={{ type: "boxed", className: "risk-tabs" }}
                />
                <div className="constrained-section">
                  {section === ProspectDetailSections.Summary && (
                    <ProspectSummary booking={booking} />
                  )}
                  {section ===
                    ProspectDetailSections.InterviewAndAssessment && (
                    <ProspectInterview booking={booking} />
                  )}
                  {section === ProspectDetailSections.Recommendation && (
                    <ProspectRecommendation booking={booking} />
                  )}
                  {section === ProspectDetailSections.CourtDecision && (
                    <ProspectCourtDecision booking={booking} />
                  )}

                  {section === ProspectDetailSections.EnrollOrArchive && (
                    <ProspectEnrollOrArchive booking={booking} />
                  )}
                </div>
              </div>
            </>
          );
        }}
      />
    </>
  );
}

function ProspectInterview({ booking }: { booking: Booking }) {
  const residenceFormFieldsWithInitialValue = residenceFormFields.map(
    (residenceFormField) => {
      return {
        ...residenceFormField,
        initialValue:
          booking.interview_and_assessment &&
          booking.interview_and_assessment.interview_data &&
          booking.interview_and_assessment.interview_data.residence
            ? booking.interview_and_assessment.interview_data.residence[
                residenceFormField.id
              ]
            : null,
      };
    }
  );

  const employmentFormFieldsWithInitialValue = employmentFormFields.map(
    (employmentFormField) => {
      return {
        ...employmentFormField,
        initialValue:
          booking.interview_and_assessment &&
          booking.interview_and_assessment.interview_data &&
          booking.interview_and_assessment.interview_data
            .employment_and_education
            ? booking.interview_and_assessment.interview_data
                .employment_and_education[employmentFormField.id]
            : null,
      };
    }
  );

  const substanceAbuseHistoryFieldsWithInitialValue = substanceAbuseHistoryFields.map(
    (substanceAbuseHistoryField) => {
      return {
        ...substanceAbuseHistoryField,
        initialValue:
          booking.interview_and_assessment &&
          booking.interview_and_assessment.interview_data &&
          booking.interview_and_assessment.interview_data.substance_abuse
            ? booking.interview_and_assessment.interview_data.substance_abuse[
                substanceAbuseHistoryField.id
              ]
            : null,
      };
    }
  );

  const priorRecordFieldsWithInitialValue = priorRecordFields.map(
    (priorRecordField) => {
      return {
        ...priorRecordField,
        initialValue:
          booking.interview_and_assessment &&
          booking.interview_and_assessment.interview_data &&
          booking.interview_and_assessment.interview_data.prior_record
            ? booking.interview_and_assessment.interview_data.prior_record[
                priorRecordField.id
              ]
            : null,
      };
    }
  );

  const victimInfoFieldsWithInitialValue = victimInfoFields.map(
    (priorRecordField) => {
      return {
        ...priorRecordField,
        initialValue:
          booking.interview_and_assessment &&
          booking.interview_and_assessment.interview_data &&
          booking.interview_and_assessment.interview_data.victim_info
            ? booking.interview_and_assessment.interview_data.victim_info[
                priorRecordField.id
              ]
            : null,
      };
    }
  );

  const firearmFieldsWithInitialValue = firearmFormFields.map(
    (firearmFormFields) => {
      return {
        ...firearmFormFields,
        initialValue:
          booking.interview_and_assessment &&
          booking.interview_and_assessment.interview_data &&
          booking.interview_and_assessment.interview_data.firearm_info
            ? booking.interview_and_assessment.interview_data.firearm_info[
                firearmFormFields.id
              ]
            : null,
      };
    }
  );

  const contacts =
    booking.interview_and_assessment &&
    booking.interview_and_assessment.interview_data &&
    booking.interview_and_assessment.interview_data.contacts
      ? booking.interview_and_assessment.interview_data.contacts
      : null;

  const victims =
    booking.interview_and_assessment &&
    booking.interview_and_assessment.interview_data &&
    booking.interview_and_assessment.interview_data.victim_info &&
    booking.interview_and_assessment.interview_data.victim_info.victims
      ? booking.interview_and_assessment.interview_data.victim_info.victims
      : null;

  return (
    <>
      <Row gutter={24}>
        {booking.interview_and_assessment === null && (
          <Col span={3}>
            <Button
              type="primary"
              onClick={async () => {
                await startBookingInterviewAndAssessment({
                  booking_id: booking.id,
                });
              }}
            >
              Begin Interview
            </Button>
          </Col>
        )}

        {booking.is_interview_declined !== YesNo.Yes && (
          <Col span={3}>
            <ScarePrompt
              warningText={`Confirm that the prospect is ineligible to receive an interview / assessment. This will generate a memo to court to be completed in the recommendation tab.`}
              itemName={"Prospect is Ineligible"}
              button={<Button type="danger">Mark Ineligible</Button>}
              onYes={async () => {
                await declineBookingInterview({
                  booking_id: booking.id,
                });
              }}
              onYesText="Yes, prospect is ineligible"
            />
          </Col>
        )}
      </Row>

      {booking.interview_and_assessment !== null && (
        <>
          <div className="form-container">
            <Title level={4}>Interview Form</Title>
            <WrappedInterviewForm
              contacts={contacts}
              victims={victims}
              bookingId={booking.id}
              residenceFormFields={residenceFormFieldsWithInitialValue}
              employmentFormFields={employmentFormFieldsWithInitialValue}
              substanceAbuseHistoryFields={
                substanceAbuseHistoryFieldsWithInitialValue
              }
              priorRecordFields={priorRecordFieldsWithInitialValue}
              victimInfoFields={victimInfoFieldsWithInitialValue}
              firearmFormFields={firearmFieldsWithInitialValue}
            />
          </div>
          <div className="form-container">
            <Title level={4}>VPRAI Assessment Form</Title>
            <WrappedAssessmentForm booking={booking} />
          </div>
        </>
      )}

      {booking.is_interview_declined === YesNo.Yes && (
        <div className="form-container">
          <p>
            Client has been marked as ineligible to receive pre-trial interview
            and assessment. Memo for court is available in recommendation.
          </p>
        </div>
      )}
    </>
  );
}

function ProspectRecommendation({ booking }: { booking: Booking }) {
  const [
    viewRecommendationFormOverride,
    setViewRecommendationFormOverride,
  ] = useState(false);
  return (
    <>
      <Row gutter={24}>
        {booking.is_interview_declined !== YesNo.Yes &&
          !booking.recommendation_form && (
            <Col span={12}>
              <Text>
                You must complete the VPRAI assessment prior to completing a
                recommendation.
              </Text>
            </Col>
          )}
      </Row>

      {booking.is_interview_declined === YesNo.Yes &&
      booking.recommendation_form ? (
        <Button
          type="primary"
          onClick={() =>
            setViewRecommendationFormOverride(!viewRecommendationFormOverride)
          }
        >
          {viewRecommendationFormOverride
            ? "Show court memo"
            : "Show recommendation"}
        </Button>
      ) : null}

      {booking.is_interview_declined === YesNo.Yes &&
        viewRecommendationFormOverride === false && (
          <CourtMemoPage booking={booking} />
        )}
      {booking.is_interview_declined !== YesNo.Yes ||
      viewRecommendationFormOverride == true ? (
        <RecommendationFormPage booking={booking} />
      ) : null}
    </>
  );
}

function ProspectCourtDecision({ booking }: { booking: Booking }) {
  const courtDecisionFormFieldsWithInitialValue = courtDecisionFormFields.map(
    (courtDecisionFormField) => {
      return {
        ...courtDecisionFormField,
        initialValue: booking.pretrial_decision_form
          ? booking.pretrial_decision_form.decision
          : null,
      };
    }
  );

  const releasedWithConditionsFormFieldsWithInitialValue = releasedWithConditionFormFields.map(
    (courtDecisionFormField) => {
      const { id } = courtDecisionFormField;
      return {
        ...courtDecisionFormField,
        initialValue: booking.pretrial_decision_form
          ? booking.pretrial_decision_form[id]
          : null,
      };
    }
  );

  const sentencedFormFieldsWithInitialValue = sentencedFormFields.map(
    (sentencedFormField) => {
      const { id } = sentencedFormField;
      return {
        ...sentencedFormField,
        initialValue: booking.pretrial_decision_form
          ? booking.pretrial_decision_form[id]
          : null,
      };
    }
  );

  const petitionResolvedFormFieldsWithInitialValue = petitionResolvedFormFields.map(
    (petitionResolvedFormField) => {
      const { id } = petitionResolvedFormField;
      return {
        ...petitionResolvedFormField,
        initialValue: booking.pretrial_decision_form
          ? booking.pretrial_decision_form[id]
          : null,
      };
    }
  );

  return (
    <>
      <Row gutter={24}>
        {booking.prospect_status !== ProspectStatus.PendingCourtDecision && (
          <Col span={12}>
            <Text>
              {booking.prospect_status === ProspectStatus.Archived ||
              booking.prospect_status === ProspectStatus.EnrollOrArchive
                ? "Court decision data recorded."
                : "You must complete a recommendation before recording court decision data."}
            </Text>
          </Col>
        )}
      </Row>

      {booking.prospect_status == ProspectStatus.PendingCourtDecision && (
        <CourtDecisionFormWrapped
          bookingId={booking.id}
          courtDecisionFormFieldsWithInitialValue={
            courtDecisionFormFieldsWithInitialValue
          }
          releasedWithConditionsFormFieldsWithInitialValue={
            releasedWithConditionsFormFieldsWithInitialValue
          }
          sentencedFormFieldsWithInitialValue={
            sentencedFormFieldsWithInitialValue
          }
          petitionResolvedFormFieldsWithInitialValue={
            petitionResolvedFormFieldsWithInitialValue
          }
        />
      )}
    </>
  );
}

function ProspectEnrollOrArchive({ booking }: { booking: Booking }) {
  const archiveClient = async () => {
    await archiveBooking({ booking_id: booking.id });
  };

  const enrollProspect = async () => {
    await createParticipantFromBooking({ booking_id: booking.id });
  };

  return (
    <Row gutter={24}>
      {booking.prospect_status !== ProspectStatus.EnrollOrArchive &&
      booking.prospect_status ? (
        <Col span={12}>
          <Text>
            {booking.prospect_status === ProspectStatus.Archived
              ? "This prospect has been archived or enrolled in pretrial supervision."
              : "You must complete a risk assesment and recommendation and record court disposition before archiving a prospect"}
          </Text>
        </Col>
      ) : (
        <>
          <Col span={3}>
            <ScarePrompt
              warningText={`Confirm that you would like to enroll this prospect.`}
              itemName={"Enroll prospect"}
              button={<Button type="primary">Enroll Prospect</Button>}
              onYes={enrollProspect}
              onYesText="Yes, enroll prospect"
            />
          </Col>
          <Col span={3}>
            <ScarePrompt
              warningText={`Confirm that you would like to archive this prospect.`}
              itemName={"Archive prospect"}
              button={<Button type="danger">Archive Prospect</Button>}
              onYes={archiveClient}
              onYesText="Yes, archive prospect"
            />
          </Col>
        </>
      )}
    </Row>
  );
}
