import idx from "idx.macro";

import {
  AppHeaderGqlResponse,
  NavigationOption,
  NavigationOptionBuilder,
} from "./shared/components/layout";

import { getFeatureFlags } from "./featureFlags";

const {
  clientDisplayTerm,
  workflow: { display: workflow },
} = getFeatureFlags();

export const ROOT_PATH: string = "case-manager";

export const TOP_LEVEL_NAV_ITEMS: Array<
  NavigationOption | NavigationOptionBuilder
> = [
  () =>
    !workflow
      ? null
      : {
          label: "Workflow",
          link: `/${ROOT_PATH}/workflow`,
        },
  (data?: AppHeaderGqlResponse): NavigationOption => {
    const caseManagerId = data ? idx(data, (_) => _.me.case_manager.id) : null;

    const link = `/${ROOT_PATH}/clients`;

    return caseManagerId
      ? {
          label: `Caseload`,
          link: `${link}/-/${caseManagerId}/status/active`,
        }
      : {
          label: `All ${clientDisplayTerm}s`,
          link,
        };
  },
  (gqlData: any) => {
    if (
      !gqlData ||
      !idx(gqlData, (_) => _.application.flags.all.group_chat_enabled)
    ) {
      return null;
    }
    return {
      label: "Group Chat",
      link: `/${ROOT_PATH}/group-messages`,
      disabled: false,
    };
  },
  {
    label: "Broadcast Messages",
    link: `/${ROOT_PATH}/broadcast`,
    disabled: !getFeatureFlags().supportsBroadcastMessaging,
  },
  {
    label: "Analytics Report",
    link: `/${ROOT_PATH}/analytics-report`,
    disabled: !getFeatureFlags().showAnalyticsReportLink,
  },
  {
    label: "Health Dashboard",
    link: `/health`,
    disabled: !getFeatureFlags().showHealthLink,
    isExternalLink: true,
  },
  {
    label: "Case Analytics",
    link: `/${ROOT_PATH}/case-analytics`,
    disabled: !getFeatureFlags().supportsCaseAnalytics,
  },
  {
    label: "Calendar",
    link: `/${ROOT_PATH}/calendar`,
    disabled: !getFeatureFlags().supportsCalendar,
  },
  {
    label: "Classes",
    link: `/${ROOT_PATH}/classes`,
    disabled: !getFeatureFlags().supportsClasses,
  },
  {
    label: "Bookings",
    link: `/${ROOT_PATH}/bookings`,
    disabled: !getFeatureFlags().supportsBookings,
  },
  {
    label: "Prospects",
    link: `/${ROOT_PATH}/prospects`,
    disabled: !getFeatureFlags().supportsProspects,
  },

  // {
  //   label: "System",
  //   link: "/system",
  //   subNavOptions: [
  //     {
  //       label: "Message Templates",
  //       link: `${ROOT_PATH}/system/message-templates`
  //     },
  //     {
  //       label: "Activities",
  //       link: `${ROOT_PATH}/system/activities`
  //     },
  //     {
  //       label: "Service Provider List",
  //       link: `${ROOT_PATH}/system/service-provider-list`
  //     }
  //   ]
  // }
];
