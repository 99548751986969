import graphql from "babel-plugin-relay/macro";
import { RecordProxy, RecordSourceSelectorProxy } from "relay-runtime";
import { commitMutation } from "../shared/lib/graphql/commitMutation";
import { DataSource } from "../shared/lib/graphql/flowTypes";
import {
  CarePlanEventType,
  CarePlanObligationType,
} from "../components/care-plan/CarePlanQuery";
import { checkMutationReturn, getParticipant } from "./util";
import moment from "moment";
import { CarePlanItem, CheckInData } from "../graphql-types";

const uuid = require("uuid");

// totally unsure about this being a good idea,
// but didn't want to repeat all this code
enum AddOrReplaceEventTemplate {
  ADD,
  REPLACE,
}

interface AddObligationGqlArguments {
  participant_id: string;
  activity_id: string;
  service_provider_id: string;
  start: string | null;
  end: string | null;
  title_en: string;
  description_en: string;
  sub_address_for_event: String;
}

export interface UpdateObligationGqlArguments {
  obligation_id: String;
  result?: String;
  compliance?: String;
  start?: String | null;
  end?: String | null;
  service_provider_id?: String;
  sub_address_for_event?: String;
  status?: String;
  description_en?: String;
  title_en?: string;
}

const addOrReplaceEventTemplate = (
  newEventTemplate: RecordProxy,
  eventTemplates: ReadonlyArray<RecordProxy | null> | null,
  action: AddOrReplaceEventTemplate
): (RecordProxy | null)[] => {
  if (!eventTemplates) return [];

  if (action === AddOrReplaceEventTemplate.ADD) {
    return eventTemplates && eventTemplates.length > 0
      ? [newEventTemplate].concat((eventTemplates as any) as RecordProxy[])
      : [newEventTemplate];
  } else {
    const newEventTemplateId = newEventTemplate.getValue("id");

    return eventTemplates.map((eventTemplate) => {
      if (eventTemplate!.getValue("id") == newEventTemplateId) {
        return newEventTemplate;
      } else {
        return eventTemplate;
      }
    });
  }
};

const updateRelayStoreObligationEventTemplates = async (
  store: RecordSourceSelectorProxy,
  rootFieldName: string,
  obligationId: string,
  action: AddOrReplaceEventTemplate
) => {
  const payload = checkMutationReturn(store, rootFieldName);

  if (!payload) return;

  const newEventTemplate = payload.getLinkedRecord("event_template");

  if (!newEventTemplate) {
    console.error(
      `Error while updating after ${rootFieldName}, "event_template" missing`
    );
    return;
  }

  const obligation = store.get(obligationId);

  if (!obligation) {
    console.error(
      `Error while updating the store after ${rootFieldName}.\n` +
        " Can't find the obligation in the store."
    );
    return;
  }

  const eventTemplates = obligation.getLinkedRecords("event_templates");

  const newEventTemplates = addOrReplaceEventTemplate(
    newEventTemplate,
    eventTemplates,
    action
  );

  obligation.setLinkedRecords(newEventTemplates, "event_templates");
};

export const addObligationEvent = async ({
  obligation,
  date,
  end,
  rrule = "",
}: {
  obligation: CarePlanObligationType;
  date: string;
  end: string;
  rrule?: string;
}) => {
  const { id: obligation_id } = obligation;

  return commitMutation(
    {
      mutation: graphql`
        mutation obligationsAddObligationEventMutation(
          $obligation_id: String!
          $date: String!
          $end: String
          $rrule: String
          $timezone: String
        ) {
          addObligationEvent(
            obligation_id: $obligation_id
            date: $date
            end: $end
            rrule: $rrule
            timezone: $timezone
          ) {
            result
            event_template {
              id
              rrule
              exdate
              events {
                id
                date
                activity {
                  id
                  title {
                    en
                  }
                  type
                }
                event_template {
                  id
                }
                required
                service_provider {
                  address
                  phone
                  sub_address_for_event
                  title
                }
                attended
                disposition
              }
            }
          }
        }
      `,
      variables: {
        obligation_id,
        date,
        end,
        rrule,
        timezone: moment.tz.guess(),
      },
      updater: (store) => {
        updateRelayStoreObligationEventTemplates(
          store,
          "addObligationEvent",
          obligation_id,
          AddOrReplaceEventTemplate.ADD
        );
      },
      optimisticUpdater: (store) => {
        optimisticAddRelayStoreObligationEventTemplates(
          store,
          "addObligationEvent",
          { obligation: obligation, date, end, rrule },
          AddOrReplaceEventTemplate.ADD
        );
      },
    },
    "Error while adding an event to obligation"
  );
};

export const deleteObligationEvent = async ({
  obligation,
  event,
}: {
  obligation: CarePlanObligationType;
  event: CarePlanEventType;
}) => {
  const { id: obligation_id } = obligation;
  const {
    id: event_id,
    event_template: { id: eventTemplateId },
  } = event;

  return commitMutation(
    {
      mutation: graphql`
        mutation obligationsDeleteObligationEventMutation(
          $obligation_id: String!
          $event_id: String!
        ) {
          deleteObligationEvent(
            obligation_id: $obligation_id
            event_id: $event_id
          ) {
            event_template_id
            result
            description
            errors
          }
        }
      `,
      variables: {
        obligation_id,
        event_id,
      },
      updater: (store) => {
        deleteObligationEventUpdater(store, event_id, eventTemplateId);
      },
      optimisticUpdater: (store) => {
        deleteObligationEventUpdater(store, event_id, eventTemplateId);
      },
    },
    "Error while deleting an obligation event"
  );
};

export const updateObligationEvent = async ({
  obligation,
  event,
  eventData: event_data,
}: {
  obligation: CarePlanObligationType;
  event: CarePlanEventType;
  eventData: { [key: string]: string | number | null };
}) => {
  const { id: obligation_id } = obligation;
  const { id: event_id } = event;
  return commitMutation(
    {
      mutation: graphql`
        mutation obligationsUpdateObligationEventMutation(
          $obligation_id: String!
          $event_id: String!
          $event_data: Object!
        ) {
          updateObligationEvent(
            obligation_id: $obligation_id
            event_id: $event_id
            event_data: $event_data
          ) {
            result
            event_template {
              id
              rrule
              exdate
              events {
                id
                date
                activity {
                  id
                  title {
                    en
                  }
                  type
                }
                event_template {
                  id
                }
                required
                service_provider {
                  address
                  phone
                  sub_address_for_event
                  title
                }
                attended
                disposition
              }
            }
          }
        }
      `,
      variables: {
        obligation_id,
        event_id,
        event_data,
      },
      updater: (store) => {
        updateRelayStoreObligationEventTemplates(
          store,
          "updateObligationEvent",
          obligation_id,
          AddOrReplaceEventTemplate.REPLACE
        );
      },
      optimisticUpdater: (store) => {
        optimisticUpdateRelayStoreObligationEventTemplates(
          store,
          {
            obligation: obligation,
            eventData: event_data,
          },
          event.id
        );
      },
    },
    "Error while updating an obligation event"
  );
};

export const addObligation = async (variables: AddObligationGqlArguments) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation obligationsAddObligationMutation(
          $participant_id: String!
          $activity_id: String!
          $title_en: String
          $description_en: String
          $service_provider_id: String!
          $sub_address_for_event: String
          $start: String
          $end: String
          $source: String
        ) {
          addObligation(
            participant_id: $participant_id
            activity_id: $activity_id
            title_en: $title_en
            description_en: $description_en
            service_provider_id: $service_provider_id
            sub_address_for_event: $sub_address_for_event
            start: $start
            end: $end
            source: $source
          ) {
            obligation {
              id
              title {
                en
              }
              description {
                en
              }
              activity {
                type
                data_input_type
                data_input_field
              }
              service_provider {
                title
              }
              sub_address_for_event
              status
              compliance
              start
              end
              is_writable
              cadence_option
              day_of_week
              day_of_month
              verification_option
              event_templates {
                rrule
                exdate
                events {
                  id
                  date
                  activity {
                    id
                    title {
                      en
                    }
                    type
                  }
                  event_template {
                    id
                  }
                  required
                  service_provider {
                    address
                    phone
                    sub_address_for_event
                    title
                  }
                  attended
                  disposition
                }
              }
            }
            result
            description
            errors
          }
        }
      `,
      variables: {
        source: DataSource.User,
        ...variables,
      },
      updater: (store) => {
        updateRelayStoreAddObligation(
          store,
          "addObligation",
          variables.participant_id
        );
      },
    },
    "Error while adding an obligation"
  );
};

const updateRelayStoreAddObligation = (
  store: RecordSourceSelectorProxy,
  rootFieldName: string,
  participant_id: string
) => {
  const payload = checkMutationReturn(store, rootFieldName);

  if (!payload) return;

  const newObligation = payload.getLinkedRecord("obligation");

  const participant = getParticipant(store, rootFieldName, participant_id);

  if (!participant) return;

  const obligationsList = participant.getLinkedRecords("obligations");
  //add new obligation to front of obligationsList
  const combinedObligations = [newObligation].concat(obligationsList);

  // set the new Obligation
  participant.setLinkedRecords(combinedObligations, "obligations");
};

const updateRelayStoreAddObligations = (
  store: RecordSourceSelectorProxy,
  rootFieldName: string,
  participant_id: string
) => {
  const payload = checkMutationReturn(store, rootFieldName);

  if (!payload) return;

  const newObligations = payload.getLinkedRecords("obligations");

  if (!newObligations) {
    return;
  }

  const participant = getParticipant(store, rootFieldName, participant_id);

  if (!participant) return;

  const obligationsList = participant.getLinkedRecords("obligations");
  //add new obligation to front of obligationsList
  const combinedObligations = newObligations.concat(obligationsList);

  // set the new Obligation
  participant.setLinkedRecords(combinedObligations, "obligations");
};

const updateRelayStoreUpdateObligation = (
  store: RecordSourceSelectorProxy,
  rootFieldName: string,
  participant_id: string
) => {
  const payload = checkMutationReturn(store, rootFieldName);

  if (!payload) return;

  const newObligation = payload.getLinkedRecord("obligation");

  if (!newObligation) return;

  const newObligationId = newObligation.getDataID();
  const participant = getParticipant(store, rootFieldName, participant_id);

  if (!participant) return;

  const obligationsList = participant.getLinkedRecords("obligations");

  if (!obligationsList) return;

  // replace the obligation that was updated with the newly updated obligation within
  // the relay store's obligationsList

  const updatedObligationsList = obligationsList.map((obligation) => {
    const oldObligationId = obligation ? obligation.getDataID() : null;

    if (!oldObligationId) {
      return obligation;
    }

    if (oldObligationId === newObligationId) {
      return newObligation;
    }
    return obligation;
  });

  // set the new obligations list
  participant.setLinkedRecords(updatedObligationsList, "obligations");
};

export const deleteObligation = async (obligation: CarePlanObligationType) => {
  const { id: obligation_id } = obligation;

  return commitMutation(
    {
      mutation: graphql`
        mutation obligationsDeleteObligationMutation($obligation_id: String!) {
          deleteObligation(obligation_id: $obligation_id) {
            obligation_id
            result
            description
            errors
          }
        }
      `,
      variables: { obligation_id },
      updater: (store) => {
        const payload = checkMutationReturn(store, "deleteObligation");

        if (!payload) return;

        store.delete(obligation_id);
      },
    },
    "Error while deleted an obligation"
  );
};

export const updateObligation = async (
  variables: UpdateObligationGqlArguments,
  participantId: string,
  obligation: CarePlanObligationType
): Promise<{}> => {
  return commitMutation(
    {
      mutation: graphql`
        mutation obligationsUpdateObligationMutation(
          $obligation_id: ID!
          $status: String
          $compliance: String
          $start: String
          $end: String
          $service_provider_id: String
          $sub_address_for_event: String
          $title_en: String
          $description_en: String
        ) {
          updateObligation(
            obligation_id: $obligation_id
            status: $status
            compliance: $compliance
            start: $start
            end: $end
            service_provider_id: $service_provider_id
            sub_address_for_event: $sub_address_for_event
            title_en: $title_en
            description_en: $description_en
          ) {
            obligation {
              id
              title {
                en
              }
              description {
                en
              }
              activity {
                type
                data_input_type
                data_input_field
              }
              service_provider {
                title
              }
              sub_address_for_event
              status
              compliance
              start
              end
              is_writable
              cadence_option
              day_of_week
              day_of_month
              verification_option
              event_templates {
                rrule
                exdate
                events {
                  id
                  date
                  activity {
                    id
                    title {
                      en
                    }
                    type
                  }
                  event_template {
                    id
                  }
                  required
                  service_provider {
                    address
                    phone
                    sub_address_for_event
                    title
                  }
                  attended
                  disposition
                }
              }
            }
            result
            description
            errors
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreUpdateObligation(
          store,
          "updateObligation",
          participantId
        );
      },
      optimisticResponse: buildOptimisticResponse(obligation, variables),
    },
    "Error while updating an obligation"
  );
};

export const deleteEventTemplate = async (
  obligationId: string,
  eventTemplateId: string
): Promise<{}> => {
  return commitMutation(
    {
      mutation: graphql`
        mutation obligationsDeleteEventTemplateMutation(
          $obligationId: String!
          $eventTemplateId: String!
        ) {
          deleteEventTemplate(
            obligation_id: $obligationId
            event_template_id: $eventTemplateId
          ) {
            event_template_id
            result
            description
            errors
          }
        }
      `,
      variables: { obligationId, eventTemplateId },
      updater: (store) => {
        deleteEventTemplateUpdate(store, eventTemplateId);
      },
      optimisticUpdater: (store) => {
        deleteEventTemplateUpdate(store, eventTemplateId);
      },
    },
    "Error while deleting event template."
  );
};

function buildOptimisticResponse(
  obligation: CarePlanObligationType,
  variables: UpdateObligationGqlArguments
) {
  return {
    updateObligation: {
      obligation: {
        ...obligation,
        id: variables.obligation_id || obligation.id,
        compliance: variables.compliance || obligation.compliance,
        start: variables.start || obligation.start,
        end: variables.end || obligation.end,
        sub_address_for_event:
          variables.sub_address_for_event || obligation.sub_address_for_event,
        status: variables.status || obligation.status,
        is_writable: true,
        description: {
          en:
            variables.description_en ||
            (obligation.description && obligation.description.en) ||
            "",
        },
        title: {
          en: variables.title_en || obligation.title.en,
        },
      },
      result: "success",
      description: null,
      errors: null,
    },
  };
}

const optimisticAddRelayStoreObligationEventTemplates = async (
  store: RecordSourceSelectorProxy,
  rootFieldName: string,
  variables: {
    obligation: CarePlanObligationType;
    date: string;
    end: string;
    rrule: string;
  },
  action: AddOrReplaceEventTemplate
) => {
  const eventTemplateId = uuid();
  const newEventTemplate = store.create(eventTemplateId, "event_template");
  newEventTemplate.setValue(variables.rrule, "rrule");
  const event = store.create(uuid(), "events");
  event.setValue(variables.date, "date");
  event.setValue(variables.end, "end");
  event.setLinkedRecord(newEventTemplate, "event_template");

  newEventTemplate.setLinkedRecords([event], "events");

  if (!newEventTemplate) {
    console.error(
      `Error while updating after ${rootFieldName}, "event_template" missing`
    );
    return;
  }

  const obligation = store.get(variables.obligation.id);

  if (!obligation) {
    console.error(
      `Error while updating the store after ${rootFieldName}.\n` +
        " Can't find the obligation in the store."
    );
    return;
  }

  const eventTemplates = obligation.getLinkedRecords("event_templates");

  const newEventTemplates = addOrReplaceEventTemplate(
    newEventTemplate,
    eventTemplates,
    action
  );

  obligation.setLinkedRecords(newEventTemplates, "event_templates");
};

const optimisticUpdateRelayStoreObligationEventTemplates = async (
  store: RecordSourceSelectorProxy,
  variables: {
    obligation: CarePlanObligationType;
    eventData: { [key: string]: string | number | null };
  },
  eventId: string
) => {
  const event = store.get(eventId);
  if (event && event.getDataID() === eventId) {
    if (variables.eventData.hasOwnProperty("disposition")) {
      event.setValue(variables.eventData.disposition, "disposition");
    }
    if (variables.eventData.hasOwnProperty("attended")) {
      event.setValue(variables.eventData.attended, "attended");
    }
  }
};

function deleteEventTemplateUpdate(
  store: RecordSourceSelectorProxy,
  eventTemplateId: string
) {
  const eventTemplateRecordProxy = store.get(eventTemplateId);

  if (!eventTemplateRecordProxy) {
    return;
  }
  eventTemplateRecordProxy.setValue("", "rrule");

  const events = eventTemplateRecordProxy.getLinkedRecords("events");

  if (!events) {
    return;
  }

  const remainingEvents = events.filter((event) => {
    if (!event) {
      return false;
    }
    const date = event.getValue("date");
    return moment(date).isBefore(moment());
  });

  eventTemplateRecordProxy.setLinkedRecords(remainingEvents, "events");
}

function deleteObligationEventUpdater(
  store: RecordSourceSelectorProxy,
  eventId: string,
  eventTemplateId: string
) {
  const eventTemplateRecordProxy = store.get(eventTemplateId);

  if (!eventTemplateRecordProxy) {
    return;
  }

  const events = eventTemplateRecordProxy.getLinkedRecords("events");

  if (!events) {
    return;
  }

  const remainingEvents = events.filter((event) => {
    if (!event) {
      return false;
    }
    const id = event.getValue("id");
    return id !== eventId;
  });

  eventTemplateRecordProxy.setLinkedRecords(remainingEvents, "events");
}

export const addObligationsFromCarePlanItems = async (
  participantId: string,
  carePlanItems: CarePlanItem[]
) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation obligationsAddObligationFromCarePlanItemsMutation(
          $participant_id: String!
          $care_plan_items: [CarePlanItem]
        ) {
          addObligationsFromCarePlanItems(
            participant_id: $participant_id
            care_plan_items: $care_plan_items
          ) {
            result
            description
            obligations {
              id
              title {
                en
              }
              activity {
                id
                type
                data_input_type
                data_input_field
              }
              service_provider {
                id
                title
              }
              sub_address_for_event
              status
              compliance
              start
              end
              tag
              is_writable
              cadence_option
              day_of_week
              day_of_month
              verification_option
              event_templates {
                id
                rrule
                exdate
                events {
                  id
                  date
                  event_template {
                    id
                    rrule
                  }
                  activity {
                    id
                    title {
                      en
                    }
                    type
                  }
                  required
                  service_provider {
                    id
                    address
                    phone
                    sub_address_for_event
                    title
                  }
                  attended
                  disposition
                }
              }
            }
            errors
          }
        }
      `,
      variables: {
        participant_id: participantId,
        care_plan_items: carePlanItems,
      },
      updater: (store) => {
        updateRelayStoreAddObligations(
          store,
          "addObligationsFromCarePlanItems",
          participantId
        );
      },
    },
    "Error while adding care plan items"
  );
};

export const editCheckInObligationCadenceSettings = async ({
  obligationId,
  participantId,
  checkInData,
}: {
  obligationId: string;
  participantId: string;
  checkInData: CheckInData;
}) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation obligationsEditCheckInObligationCadenceSettingsMutation(
          $obligation_id: String!
          $check_in_data: CheckInData!
        ) {
          editCheckInObligationCadenceSettings(
            obligation_id: $obligation_id
            check_in_data: $check_in_data
          ) {
            result
            description
            obligation {
              id
              title {
                en
              }
              activity {
                id
                type
                data_input_type
                data_input_field
              }
              service_provider {
                id
                title
              }
              sub_address_for_event
              status
              compliance
              start
              end
              tag
              is_writable
              cadence_option
              day_of_week
              day_of_month
              verification_option
              event_templates {
                id
                rrule
                exdate
                events {
                  id
                  date
                  event_template {
                    id
                    rrule
                  }
                  activity {
                    id
                    title {
                      en
                    }
                    type
                  }
                  required
                  service_provider {
                    id
                    address
                    phone
                    sub_address_for_event
                    title
                  }
                  attended
                  disposition
                }
              }
            }
            errors
          }
        }
      `,
      variables: {
        obligation_id: obligationId,
        check_in_data: checkInData,
      },
      updater: (store) => {
        updateRelayStoreAddObligation(
          store,
          "editCheckInObligationCadenceSettings",
          participantId
        );
      },
    },
    "Error while adding care plan items"
  );
};
