type DateRepresentation = number | string;

export function padLeft(str: string, toLength: number): string {
  while (str && str.length < toLength) {
    str = "0" + str;
  }
  return str;
}

export function padRight(str: string, toLength: number): string {
  while (str && str.length < toLength) {
    str = str + "0";
  }
  return str;
}

export function formatMoney(cents: number): string {
  let formatted = "" + cents / 100;
  if (formatted.indexOf(".") > -1) {
    const parts = formatted.split(".");
    formatted = parts[0] + "." + padRight(parts[1], 2);
  }
  return "$" + formatted;
}

export function capitalizeString(str: string): string {
  if (!str) {
    return str;
  }
  return `${str.substring(0, 1).toUpperCase()}${str.substring(1)}`;
}

const MONTH_NAMES = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function formatDate(time: DateRepresentation): string {
  // Going to use format
  // {day of month} {month short name} {year abbreviated}

  // Check if it's a number and in 1970, if so we need to multiply it by 1000.
  if (typeof time === "number" && time < 31564800398) {
    time *= 1000;
  }

  let date = new Date(time);
  const yearAbbreviated = `${date.getFullYear()}`.substring(2);

  return `${formatDateDayAndMonth(time)} '${yearAbbreviated}`;
}

export function formatTime(time: DateRepresentation): string {
  const date = new Date(time);

  let hours = date.getHours() % 12;
  let ampm = hours >= 12 ? "PM" : "AM";

  return `${hours ? hours : 12}:${padLeft(date.getMinutes() + "", 2)} ${ampm}`;
}

export function formatDateAndTime(dateAndTime: DateRepresentation): string {
  return `${formatDate(dateAndTime)} ${formatTime(dateAndTime)}`;
}

export function formatDateDayAndMonth(time: DateRepresentation): string {
  const date = new Date(time);
  const dayOfMonth = date.getDate();
  const monthAbbreviated = MONTH_NAMES[date.getMonth()];

  return `${dayOfMonth} ${monthAbbreviated}`;
}
