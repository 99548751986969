import React, { useState } from "react";
import { Row, Col, DatePicker, Form, Button, Input } from "antd";
import { Maybe, Booking } from "../../graphql-types";
import { Moment } from "moment";
import { QueryRenderer } from "../../shared/lib/graphql";
import { bookingsGraphqlByDOBAndName } from "../bookings/BookingsGraphql";
import { SearchProspectBookingTable } from "./SearchProspectBookingTable";

const dateFormatList = ["MM/DD/YYYY", "MM/D/YYYY", "M/D/YYYY"];

export function ProspectSearch() {
  const [dateOfBirth, setDateOfBirth] = useState<Maybe<Moment>>(null);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [shouldSearch, setShouldSearch] = useState<boolean>(false);

  console.log(setFirstName);
  console.log(setLastName);

  return (
    <>
      <Row gutter={[16, 0]} type="flex" justify="center">
        <Col span={3}>
          <Form.Item label="DOB">
            <DatePicker
              format={dateFormatList}
              value={dateOfBirth ? dateOfBirth : undefined}
              onChange={(date) => {
                setShouldSearch(false);
                setDateOfBirth(date);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="First Name">
            <Input
              placeholder="e.g. John"
              value={firstName}
              onChange={(e: any) => {
                setShouldSearch(false);
                const { value } = e.target;
                setFirstName(value);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Last Name">
            <Input
              placeholder="e.g. Doe"
              value={lastName}
              onChange={(e: any) => {
                setShouldSearch(false);
                const { value } = e.target;
                setLastName(value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Button
          icon="search"
          type="primary"
          size={"large"}
          onClick={() => setShouldSearch(true)}
        >
          Search Prospects
        </Button>
      </Row>

      {shouldSearch && (dateOfBirth || firstName || lastName) ? (
        <QueryRenderer
          query={bookingsGraphqlByDOBAndName}
          variables={{
            date_of_birth: dateOfBirth
              ? dateOfBirth.format("M/DD/YYYY")
              : undefined,
            first_name: firstName,
            last_name: lastName,
          }}
          SuccessComponent={(props: { bookings: { booking: Booking[] } }) => {
            const bookings = props.bookings.booking;

            return (
              <div style={{ margin: "20px" }}>
                <SearchProspectBookingTable bookings={bookings} />
              </div>
            );
          }}
        />
      ) : null}
    </>
  );
}
