import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import graphql from "babel-plugin-relay/macro";
import idx from "idx.macro";
import { StringLike } from "@firebase/util";
import { Participant } from "../../graphql-types";
import { Tag } from "react-bulma-components";
import { QueryRenderer } from "../../shared/lib/graphql";

interface Props {
  participantId: StringLike;
}

interface EventDetailsResponse {
  participants: {
    participant: Participant[];
  };
}

export default class DataUpdateNeededQueryWrapper extends React.Component<
  RouteComponentProps<Props>
> {
  render() {
    return (
      <QueryRenderer
        query={graphql`
          query DataUpdateNeededQueryWrapperQuery($participant_id: String) {
            participants(id: $participant_id) {
              participant {
                data_update_needed
              }
            }
          }
        `}
        variables={{
          participant_id: this.props.participantId,
        }}
        SuccessComponent={(props: EventDetailsResponse) => {
          console.log("in query component");
          const participant = idx(props, (_) => _.participants.participant[0]);
          const text = participant.data_update_needed ? "Yes" : "No";
          return (
            <Tag color={participant.data_update_needed ? "danger" : "success"}>
              {text}
            </Tag>
          );
        }}
      />
    );
  }
}
