import React, { useState } from "react";
import { PageHeader } from "../../shared/components/elements/PageHeader";
import { CreateClass } from "./CreateClass";
import { Button } from "antd";
import { classGraphqlQuery } from "./ClassGraphql";
import { QueryRenderer } from "../../shared/lib/graphql";
import {
  Participant,
  Activity,
  ServiceProvider,
  GroupClass,
} from "../../graphql-types";
import idx from "idx.macro";
import { Collapse, Typography } from "antd";
import { customPanelStyle } from "../care-plan/CarePlan";
import moment from "moment";
import { deleteGroupClass } from "../../actions/groupClasses";

interface ClassesQueryGqlProps {
  group_classes: GroupClass[];
  activities: {
    activity: Activity[];
  };
  service_providers: {
    service_provider: ServiceProvider[];
  };
  participants: {
    participant: Participant[];
  };
}

export function Classes() {
  const [isNewClassOpen, setIsNewClassOpen] = useState(false);

  const { Panel } = Collapse;

  return (
    <>
      <QueryRenderer
        query={classGraphqlQuery}
        SuccessComponent={(props: ClassesQueryGqlProps) => {
          const participants = idx(
            props,
            (_) => _.participants.participant
          ) as Participant[];

          const activities = idx(
            props,
            (_) => _.activities.activity
          ) as Activity[];

          const serviceProviders = idx(
            props,
            (_) => _.service_providers.service_provider
          ) as ServiceProvider[];

          const groupClasses = idx(
            props,
            (_) => _.group_classes
          ) as GroupClass[];

          const groupClassesNoNulls = groupClasses.filter(
            (groupClass) => !!groupClass
          );

          const initialFieldsNewClass = {
            activity: {
              value: "",
            },
            location: {
              value: "",
            },
            startDate: {
              value: "",
            },
            startTime: {
              value: "",
            },
            endDate: {
              value: "",
            },
            daysOfWeek: {
              value: [],
            },
            selectedParticipants: {
              value: [],
            },
          };

          return (
            <>
              <PageHeader
                pageName="Classes"
                pageBlurb={`Create and manage group classes.`}
                headerRight={
                  isNewClassOpen ? (
                    <Button
                      type="primary"
                      onClick={() => setIsNewClassOpen(false)}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      onClick={() => setIsNewClassOpen(true)}
                    >
                      Create New Class
                    </Button>
                  )
                }
              ></PageHeader>
              <div className="constrained-section">
                {isNewClassOpen && (
                  <CreateClass
                    serviceProviders={serviceProviders}
                    activities={activities}
                    participants={participants}
                    onCompleteCallback={() => setIsNewClassOpen(false)}
                    initialFields={initialFieldsNewClass}
                  />
                )}
                {groupClassesNoNulls.map((groupClass) => (
                  <Collapse key={groupClass.id}>
                    <Panel
                      key={groupClass.id}
                      header={<ClassPanelHeader {...groupClass} />}
                      style={{ ...customPanelStyle }}
                    >
                      <CreateClass
                        serviceProviders={serviceProviders}
                        activities={activities}
                        participants={participants}
                        onCompleteCallback={() => null}
                        groupClassId={groupClass.id}
                        initialExcludeDates={groupClass.exclude_dates.map(
                          (excludeDate) => moment(excludeDate)
                        )}
                        initialFields={{
                          activity: {
                            value: groupClass.activity.id,
                            touched: true,
                            dirty: false,
                            name: "activity",
                          },
                          location: {
                            value: groupClass.service_provider.id,
                            touched: true,
                            dirty: false,
                            name: "location",
                          },
                          startDate: {
                            value: moment(groupClass.start_date),
                            touched: true,
                            dirty: false,
                            name: "startDate",
                          },
                          startTime: {
                            value: moment(groupClass.start_date),
                            touched: true,
                            dirty: false,
                            name: "starTime",
                          },
                          endDate: {
                            value: moment(groupClass.end_date),
                            touched: true,
                            dirty: false,
                            name: "endDate",
                          },
                          daysOfWeek: {
                            value: groupClass.days_of_week,
                            touched: true,
                            dirty: false,
                            name: "daysOfWeek",
                          },
                          selectedParticipants: {
                            value: groupClass.participants.map(
                              (participant) => participant.id
                            ),
                            touched: true,
                            dirty: false,
                            name: "selectedParticipants",
                          },
                        }}
                      />
                    </Panel>
                  </Collapse>
                ))}
              </div>
            </>
          );
        }}
      />
    </>
  );
}

function ClassPanelHeader(props: GroupClass) {
  const { Title, Text } = Typography;

  const handleDeleteGroupClassButtonClicked = async (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    groupClassId: string
  ) => {
    event.stopPropagation();
    await deleteGroupClass({ group_class_id: groupClassId });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Title level={4} style={{ marginBottom: "0px" }}>
          {props.activity.title.en}
        </Title>
        <div>
          <Text>{`${moment(props.start_date).format("h:mm a")} on `}</Text>
          <Text>{props.days_of_week.join(", ")}</Text>
        </div>
        <div>
          <Text>{moment(props.start_date).format("MM/DD/YYYY")}</Text>
          <Text>{` - `}</Text>
          <Text>{moment(props.end_date).format("MM/DD/YYYY")}</Text>
        </div>
      </div>
      <div>
        <Button
          type="danger"
          onClick={(e) => handleDeleteGroupClassButtonClicked(e, props.id)}
        >
          Delete
        </Button>
      </div>
    </div>
  );
}
