import optionsToString from "./optionsToString";

import { FetcherOptions } from "./sharedTypes";
import { MatcherRequest } from "../types";

let devShim: FetcherFuncType = (null as any) as FetcherFuncType;
let defaultHeaders: { [key: string]: string } = {};
let defaultUrlRoot = "";

const HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export type FetcherFuncType = <T = any>(
  request: string | MatcherRequest,
  options?: Object,
  method?: string,
  isRawFetch?: boolean,
  headerOptions?: Object
) => Promise<T>;

export interface IFetcher extends FetcherFuncType {
  fakeShim: (fakeFetcher: FetcherFuncType) => void;
  setDefaultHeaders: (headers: { [key: string]: string }) => void;
  removeHeader: (name: string) => void;
  setRootUrl: (value: string) => void;
  getRootUrl: () => string;
}

const fetcherFunc: FetcherFuncType = function <T = any>(
  request: string | MatcherRequest,
  options?: FetcherOptions,
  method?: string,
  isRawFetch?: boolean,
  headerOptions: object = {}
): Promise<T> {
  if (process.env.NODE_ENV !== "production") {
    if (devShim) {
      return devShim(request, options, method);
    }
  }

  // TODO: Type this better
  const url: string = (request as any).url
    ? (request as any).url
    : (request as any);

  let body = undefined;
  let urlToCall = url.indexOf("http") === 0 ? url : defaultUrlRoot + url;
  const fetchMethod = (method || "GET").toUpperCase();

  const params: any = {
    method: fetchMethod,
    // credentials: "include",
    mode: "cors",
    headers: { ...HEADERS, ...defaultHeaders, ...headerOptions },
  };

  if (fetchMethod === "GET") {
    if (options) {
      let optionsStr = optionsToString(options);
      urlToCall += (urlToCall.indexOf("?") > -1 ? "&" : "?") + optionsStr;
    }
  } else if (fetchMethod === "POST") {
    body = JSON.stringify(options);
    params.body = body;
  } else if (fetchMethod === "FORM") {
    // Note that we are overriding the method
    // back to post here, just adding this
    // method to provide a quick way to not stringify the body data
    body = options;
    params.body = body;
    params.method = "POST";
    delete params.headers.Accept;
    delete params.headers["Content-Type"];
  } else if (fetchMethod === "POST-NO-STRING") {
    body = options;
    params.body = body;
    params.method = "POST";
    delete params.headers["Content-Type"];
  }

  return fetch(urlToCall, params).then((res) => {
    if (isRawFetch === true) {
      return res;
    }
    if (res.ok) {
      // return res.text();
      return res.json();
    }
    throw new Error("fetching url failed: " + url);
  });
};

const fetcher = fetcherFunc as IFetcher;

export default fetcher;

fetcher.fakeShim = (fakeFetcher: FetcherFuncType) => {
  if (process.env.NODE_ENV !== "production") {
    devShim = fakeFetcher;
  } else {
    throw new Error("Fetcher shimming is not supported in production");
  }
};

fetcher.setDefaultHeaders = (headers: { [key: string]: string }): void => {
  defaultHeaders = headers || {};
};

fetcher.removeHeader = (name: string) => {
  delete defaultHeaders[name];
};

fetcher.setRootUrl = (value: string): void => {
  defaultUrlRoot = value;
};

fetcher.getRootUrl = (): string => {
  return defaultUrlRoot;
};
