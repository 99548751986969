import React, { PureComponent } from "react";
import { QueryRenderer } from "../../shared/lib/graphql/index";
import graphql from "babel-plugin-relay/macro";
import idx from "idx.macro";
import "./ParticipantSearchInput.scss";
import { Link, Redirect } from "@reach/router";
import { ROOT_PATH } from "../../AppInfo";
import { urlBuilderFn } from "../screens/ParticipantDetailsTabs";

interface SearchResultsBoxProps {
  searchText: string;
  selectedValue: number;
  resetSelectedValue: () => void;
  resetRedirect: () => void;
  redirect: boolean;
}

interface ParticipantSearchResults {
  id: string;
  name: {
    first: string;
    last: string;
  };
}

interface ParticipantSearch {
  participant: ParticipantSearchResults[];
}

interface ParticipantSearchPagination {
  participants: ParticipantSearch;
}

export class SearchResultsBox extends PureComponent<SearchResultsBoxProps> {
  render() {
    const {
      searchText,
      selectedValue,
      resetSelectedValue,
      resetRedirect,
      redirect,
    } = this.props;

    return (
      <div className="participant-search-results-container">
        <QueryRenderer
          query={graphql`
            query SearchResultsBoxQuery($name_contains: String) {
              participants(name_contains: $name_contains) {
                participant {
                  id
                  name {
                    first
                    last
                  }
                }
              }
            }
          `}
          variables={{
            name_contains: searchText,
          }}
          SuccessComponent={(props: ParticipantSearchPagination) => {
            const participants =
              idx(props, (_) => _.participants.participant) || [];

            const participantsTrimmed = participants.slice(0, 8);
            if (selectedValue >= participantsTrimmed.length) {
              resetSelectedValue();
            }

            const urlBuilder = urlBuilderFn(`/${ROOT_PATH}/clients/`);

            // it's possible that the selected value isn't a participant (no result)
            // in this case, we want to make sure that the redirect is switched to false
            // so that when the user finds a valid user they aren't immediately redirected
            if (!participantsTrimmed[selectedValue]) {
              resetRedirect();
            }

            return (
              <>
                {redirect && participantsTrimmed[selectedValue] && (
                  <Redirect
                    to={urlBuilder(participantsTrimmed[selectedValue]).default}
                    noThrow
                  />
                )}

                <div className="participant-search-results-box">
                  {participants.length > 0 ? (
                    <>
                      {participantsTrimmed.map((participant, index) => (
                        <Link
                          key={participant.id}
                          to={urlBuilder(participant).default}
                          className={
                            selectedValue === index
                              ? "participant-search-participant participant-selected"
                              : "participant-search-participant"
                          }
                        >
                          <p className="participant-search-text">
                            {`${
                              participant.name.first
                                ? participant.name.first
                                : ""
                            } ${
                              participant.name.last ? participant.name.last : ""
                            }`}
                          </p>
                        </Link>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className={"participant-search-participant"}>
                        <p className="participant-search-text">No results</p>
                      </div>
                    </>
                  )}
                </div>
              </>
            );
          }}
        />
      </div>
    );
  }
}
