/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type MutationResult = "failure" | "skipped" | "success" | "%future added value";
export type obligationsDeleteEventTemplateMutationVariables = {
    readonly obligationId: string;
    readonly eventTemplateId: string;
};
export type obligationsDeleteEventTemplateMutationResponse = {
    readonly deleteEventTemplate: ({
        readonly event_template_id: string | null;
        readonly result: MutationResult;
        readonly description: string | null;
        readonly errors: ReadonlyArray<string | null> | null;
    }) | null;
};
export type obligationsDeleteEventTemplateMutation = {
    readonly response: obligationsDeleteEventTemplateMutationResponse;
    readonly variables: obligationsDeleteEventTemplateMutationVariables;
};



/*
mutation obligationsDeleteEventTemplateMutation(
  $obligationId: String!
  $eventTemplateId: String!
) {
  deleteEventTemplate(obligation_id: $obligationId, event_template_id: $eventTemplateId) {
    event_template_id
    result
    description
    errors
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "obligationId",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "eventTemplateId",
            "type": "String!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "deleteEventTemplate",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "event_template_id",
                    "variableName": "eventTemplateId"
                },
                {
                    "kind": "Variable",
                    "name": "obligation_id",
                    "variableName": "obligationId"
                }
            ],
            "concreteType": "DeleteEventTemplate",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "event_template_id",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "result",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "errors",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "obligationsDeleteEventTemplateMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "obligationsDeleteEventTemplateMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "obligationsDeleteEventTemplateMutation",
            "id": null,
            "text": "mutation obligationsDeleteEventTemplateMutation(\n  $obligationId: String!\n  $eventTemplateId: String!\n) {\n  deleteEventTemplate(obligation_id: $obligationId, event_template_id: $eventTemplateId) {\n    event_template_id\n    result\n    description\n    errors\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '348c42096e71f680555f6775c6d84c0c';
export default node;
