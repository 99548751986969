import React, { useReducer } from "react";
import { Form, Button } from "react-bulma-components";
import { QueryRenderer } from "../../shared/lib/graphql";
import { caseAnalyticsGraphqlQuery } from "./CaseAnalyticsGraphql";
import { QueryCase_StatsArgs, CaseStat } from "../../graphql-types";
import "./CaseAnalytics.scss";

interface CaseArgumentsAction {
  type: "select" | "checkbox";
  fieldName: string;
  value: string | { min: number; max?: number };
  checked: boolean;
}

interface CaseStateReturnType {
  case_stats: [CaseStat];
}

const RISK_LEVEL_CHECKBOXES = [
  { fieldName: "risk_levels", value: "low", label: "Low" },
  { fieldName: "risk_levels", value: "medium", label: "Medium" },
  { fieldName: "risk_levels", value: "high", label: "High" },
];

const GENDER_CHECKBOXES = [
  {
    fieldName: "gender",
    value: "male",
    label: "Male",
  },
  {
    fieldName: "gender",
    value: "female",
    label: "Female",
  },
  {
    fieldName: "gender",
    value: "unknown",
    label: "Unknown",
  },
];

const TREATMENT_TYPE_CHECKBOXES = [
  {
    fieldName: "treatment_types",
    value: "residential",
    label: "Residential",
  },
  {
    fieldName: "treatment_types",
    value: "partial_hospitalization",
    label: "Partial Hospitalization",
  },
  {
    fieldName: "treatment_types",
    value: "iop",
    label: "IOP",
  },
  {
    fieldName: "treatment_types",
    value: "recovery_living",
    label: "Recovery Living",
  },
  {
    fieldName: "treatment_types",
    value: "none",
    label: "None",
  },
];

const PRIMARY_TREATMENT_FACILITY = [
  {
    fieldName: "primary_treatment_facilities",
    value: "prairie",
    label: "Prairie",
  },
  {
    fieldName: "primary_treatment_facilities",
    value: "sharehouse",
    label: "Sharehouse",
  },
  {
    fieldName: "primary_treatment_facilities",
    value: "se_human_services",
    label: "SE Human Services",
  },
  {
    fieldName: "primary_treatment_facilities",
    value: "centre",
    label: "Centre",
  },
  {
    fieldName: "primary_treatment_facilities",
    value: "other_private",
    label: "Other Private",
  },
];

const MEDICATED_CHECKBOXES = [
  {
    fieldName: "medicated",
    value: "yes",
    label: "Yes",
  },
  {
    fieldName: "medicated",
    value: "no",
    label: "No",
  },
];

const GROUP_MEETINGS_CHECKBOXES = [
  {
    fieldName: "group_meetings",
    value: "group_meeting_aa",
    label: "AA",
  },
  {
    fieldName: "group_meetings",
    value: "group_meeting_na",
    label: "NA",
  },
  {
    fieldName: "group_meetings",
    value: "group_meeting_other",
    label: "Other",
  },
];

const HAND_OFF_TO_PROBATION_CHECKBOXES = [
  {
    fieldName: "hand_off_to_probation",
    value: "yes",
    label: "Yes",
  },
  {
    fieldName: "hand_off_to_probation",
    value: "no",
    label: "No",
  },
];

const HOUSING_CHECKBOXES = [
  {
    fieldName: "housing_statuses",
    value: "unknown",
    label: "Unknown",
  },
  {
    fieldName: "housing_statuses",
    value: "has",
    label: "Has",
  },
  {
    fieldName: "housing_statuses",
    value: "needs",
    label: "Needs",
  },
  {
    fieldName: "housing_statuses",
    value: "obtained",
    label: "Obtained",
  },
];

const EDUCATION_CHECKBOXES = [
  {
    fieldName: "education_statuses",
    value: "unknown",
    label: "Unknown",
  },
  {
    fieldName: "education_statuses",
    value: "has",
    label: "Has",
  },
  {
    fieldName: "education_statuses",
    value: "needs",
    label: "Needs",
  },
  {
    fieldName: "education_statuses",
    value: "obtained",
    label: "Obtained",
  },
];

const EMPLOYMENT_CHECKBOXES = [
  {
    fieldName: "employment_statuses",
    value: "unknown",
    label: "Unknown",
  },
  {
    fieldName: "employment_statuses",
    value: "has",
    label: "Has",
  },
  {
    fieldName: "employment_statuses",
    value: "needs",
    label: "Needs",
  },
  {
    fieldName: "employment_statuses",
    value: "obtained",
    label: "Obtained",
  },
];

const TRANSPORTATION_CHECKBOXES = [
  {
    fieldName: "transportation_statuses",
    value: "unknown",
    label: "Unknown",
  },
  {
    fieldName: "transportation_statuses",
    value: "has",
    label: "Has",
  },
  {
    fieldName: "transportation_statuses",
    value: "needs",
    label: "Needs",
  },
  {
    fieldName: "transportation_statuses",
    value: "obtained",
    label: "Obtained",
  },
];

const COURTS_CHECKBOXES = [
  {
    fieldName: "courts",
    value: "fargo",
    label: "Fargo",
  },
  {
    fieldName: "courts",
    value: "west_fargo",
    label: "West Fargo",
  },
  {
    fieldName: "courts",
    value: "cass_county_district",
    label: "Cass County District",
  },
];

const CASE_TYPE_CHECKBOXES = [
  {
    fieldName: "case_type",
    value: "sentenced",
    label: "Sentenced",
  },
  {
    fieldName: "case_type",
    value: "pre_trial",
    label: "Pre-trial",
  },
];

const WEEKS_IN_PROGRAM_CHECKBOXES = [
  {
    label: "0-2",
    fieldName: "weeks_in_program",
    value: {
      min: 0,
      max: 2,
    },
  },
  {
    label: "3-4",
    fieldName: "weeks_in_program",
    value: {
      min: 3,
      max: 4,
    },
  },
  {
    label: "5-6",
    fieldName: "weeks_in_program",
    value: {
      min: 5,
      max: 6,
    },
  },
  {
    label: "7-10",
    fieldName: "weeks_in_program",
    value: {
      min: 7,
      max: 10,
    },
  },
  {
    label: "11-15",
    fieldName: "weeks_in_program",
    value: {
      min: 11,
      max: 15,
    },
  },
  {
    label: "16-20",
    fieldName: "weeks_in_program",
    value: {
      min: 16,
      max: 20,
    },
  },
  {
    label: "21+",
    fieldName: "weeks_in_program",
    value: {
      min: 21,
    },
  },
];

const ABSCONDED_CHECKBOXES = [
  {
    fieldName: "absconded",
    value: "yes",
    label: "Yes",
  },
  {
    fieldName: "absconded",
    value: "no",
    label: "No",
  },
];

const IS_EMH_CHECKBOXES = [
  {
    fieldName: "is_emh",
    value: "yes",
    label: "Yes",
  },
  {
    fieldName: "is_emh",
    value: "no",
    label: "No",
  },
];

export function CaseAnalytics() {
  const initialcaseArgumentsValues: QueryCase_StatsArgs = {
    risk_levels: [],
    treatment_types: [],
    primary_treatment_facilities: [],
    medicated: [],
    group_meetings: [],
    hand_off_to_probation: [],
    housing_statuses: [],
    employment_statuses: [],
    education_statuses: [],
    transportation_statuses: [],
    courts: [],
    case_type: [],
    weeks_in_program: [],
    absconded: [],
    is_emh: [],
    gender: [],
  };
  const [caseArguments, caseDispatch] = useReducer(
    (state, action: CaseArgumentsAction | null) => {
      if (!action) {
        return {
          ...initialcaseArgumentsValues,
        };
      }

      // select case is pretty straightforward
      // just set the value to whatever is in the select
      if (action.type === "select") {
        return {
          ...state,
          [action.fieldName]: action.value,
        };
      }

      // for a checkbox, if the value is unchecked,
      // just remove it from the arguments
      if (action.checked === false) {
        return {
          ...state,
          [action.fieldName]: state[action.fieldName].filter(
            (selectedValue: string) => selectedValue !== action.value
          ),
        };
      }

      // we'll use a set to prevent duplicates from getting in there
      const existingValues = state[action.fieldName]
        ? [...state[action.fieldName]]
        : [];

      const set = new Set([...existingValues, action.value]);

      return {
        ...state,
        [action.fieldName]: Array.from(set),
      };
    },
    initialcaseArgumentsValues
  );

  const handleCheckboxChange = (
    event: any,
    fieldName: string,
    value: string | { min: number; max?: number }
  ) => {
    const { checked } = event.target;
    const action: CaseArgumentsAction = {
      type: "checkbox",
      value,
      fieldName,
      checked,
    };
    caseDispatch(action);
  };

  return (
    <div style={{ padding: "2em" }}>
      <div className="case-analytics-row">
        <p className="case-filters-title">Choose Filters</p>
      </div>

      <div className="case-analytics-row">
        <div className="case-analytics-section-filler-narrow">
          <div className="case-checkbox-container">
            <div>
              <Button
                color="danger"
                style={{
                  position: "relative",
                  left: "-12px",
                  marginBottom: "0.5em",
                }}
                outlined
                onClick={() => caseDispatch(null)}
              >
                Reset Filters
              </Button>
            </div>
          </div>
        </div>

        <div className="case-analytics-section-filler">
          <div className="case-checkbox-container"></div>
        </div>

        <div className="case-analytics-section-filler">
          <div className="case-checkbox-container"></div>
        </div>

        <div className="case-analytics-section-filler">
          <div className="case-checkbox-container"></div>
        </div>

        <div className="case-analytics-section-filler">
          <div className="case-checkbox-container"></div>
        </div>
      </div>

      <div className="case-analytics-row">
        <div className="case-analytics-section">
          <Form.Control>
            <Form.Label>Risk Levels</Form.Label>

            <div className="case-checkbox-container">
              {RISK_LEVEL_CHECKBOXES.map((checkboxData) => (
                <Form.Checkbox
                  key={checkboxData.value}
                  checked={
                    caseArguments[checkboxData.fieldName] &&
                    caseArguments[checkboxData.fieldName].includes(
                      checkboxData.value
                    )
                  }
                  name={checkboxData.value}
                  onChange={(event: any) =>
                    handleCheckboxChange(
                      event,
                      checkboxData.fieldName,
                      checkboxData.value
                    )
                  }
                >
                  {" "}
                  {checkboxData.label}
                </Form.Checkbox>
              ))}
            </div>
          </Form.Control>
        </div>

        <div className="case-analytics-section">
          <Form.Control>
            <Form.Label>Treatment Types</Form.Label>
            <div className="case-checkbox-container">
              {TREATMENT_TYPE_CHECKBOXES.map((checkboxData) => (
                <Form.Checkbox
                  key={checkboxData.value}
                  checked={
                    caseArguments[checkboxData.fieldName] &&
                    caseArguments[checkboxData.fieldName].includes(
                      checkboxData.value
                    )
                  }
                  name={checkboxData.value}
                  onChange={(event: any) =>
                    handleCheckboxChange(
                      event,
                      checkboxData.fieldName,
                      checkboxData.value
                    )
                  }
                >
                  {" "}
                  {checkboxData.label}
                </Form.Checkbox>
              ))}
            </div>
          </Form.Control>
        </div>

        <div className="case-analytics-section">
          <Form.Control>
            <Form.Label>Primary Treatment Facilities</Form.Label>
            <div className="case-checkbox-container">
              {PRIMARY_TREATMENT_FACILITY.map((checkboxData) => (
                <Form.Checkbox
                  key={checkboxData.value}
                  checked={
                    caseArguments[checkboxData.fieldName] &&
                    caseArguments[checkboxData.fieldName].includes(
                      checkboxData.value
                    )
                  }
                  name={checkboxData.value}
                  onChange={(event: any) =>
                    handleCheckboxChange(
                      event,
                      checkboxData.fieldName,
                      checkboxData.value
                    )
                  }
                >
                  {" "}
                  {checkboxData.label}
                </Form.Checkbox>
              ))}
            </div>
          </Form.Control>
        </div>

        <div className="case-analytics-section">
          <Form.Control>
            <Form.Label>Received Medication</Form.Label>
            <div className="case-checkbox-container">
              {MEDICATED_CHECKBOXES.map((checkboxData) => (
                <Form.Checkbox
                  key={checkboxData.value}
                  checked={
                    caseArguments[checkboxData.fieldName] &&
                    caseArguments[checkboxData.fieldName].includes(
                      checkboxData.value
                    )
                  }
                  name={checkboxData.value}
                  onChange={(event: any) =>
                    handleCheckboxChange(
                      event,
                      checkboxData.fieldName,
                      checkboxData.value
                    )
                  }
                >
                  {" "}
                  {checkboxData.label}
                </Form.Checkbox>
              ))}
            </div>
          </Form.Control>
        </div>

        <div className="case-analytics-section">
          <Form.Control>
            <Form.Label>Group Meetings</Form.Label>
            <div className="case-checkbox-container">
              {GROUP_MEETINGS_CHECKBOXES.map((checkboxData) => (
                <Form.Checkbox
                  key={checkboxData.value}
                  checked={
                    caseArguments[checkboxData.fieldName] &&
                    caseArguments[checkboxData.fieldName].includes(
                      checkboxData.value
                    )
                  }
                  name={checkboxData.value}
                  onChange={(event: any) =>
                    handleCheckboxChange(
                      event,
                      checkboxData.fieldName,
                      checkboxData.value
                    )
                  }
                >
                  {" "}
                  {checkboxData.label}
                </Form.Checkbox>
              ))}
            </div>
          </Form.Control>
        </div>
      </div>

      <div className="case-analytics-row">
        <div className="case-analytics-section">
          <Form.Control>
            <Form.Label>Hand off to Probation</Form.Label>
            <div className="case-checkbox-container">
              {HAND_OFF_TO_PROBATION_CHECKBOXES.map((checkboxData) => (
                <Form.Checkbox
                  key={checkboxData.value}
                  checked={
                    caseArguments[checkboxData.fieldName] &&
                    caseArguments[checkboxData.fieldName].includes(
                      checkboxData.value
                    )
                  }
                  name={checkboxData.value}
                  onChange={(event: any) =>
                    handleCheckboxChange(
                      event,
                      checkboxData.fieldName,
                      checkboxData.value
                    )
                  }
                >
                  {" "}
                  {checkboxData.label}
                </Form.Checkbox>
              ))}
            </div>
          </Form.Control>
        </div>

        <div className="case-analytics-section">
          <Form.Control>
            <Form.Label>Housing</Form.Label>
            <div className="case-checkbox-container">
              {HOUSING_CHECKBOXES.map((checkboxData) => (
                <Form.Checkbox
                  key={checkboxData.value}
                  checked={
                    caseArguments[checkboxData.fieldName] &&
                    caseArguments[checkboxData.fieldName].includes(
                      checkboxData.value
                    )
                  }
                  name={checkboxData.value}
                  onChange={(event: any) =>
                    handleCheckboxChange(
                      event,
                      checkboxData.fieldName,
                      checkboxData.value
                    )
                  }
                >
                  {" "}
                  {checkboxData.label}
                </Form.Checkbox>
              ))}
            </div>
          </Form.Control>
        </div>

        <div className="case-analytics-section">
          <Form.Control>
            <Form.Label>Employment</Form.Label>
            <div className="case-checkbox-container">
              {EMPLOYMENT_CHECKBOXES.map((checkboxData) => (
                <Form.Checkbox
                  key={checkboxData.value}
                  checked={
                    caseArguments[checkboxData.fieldName] &&
                    caseArguments[checkboxData.fieldName].includes(
                      checkboxData.value
                    )
                  }
                  name={checkboxData.value}
                  onChange={(event: any) =>
                    handleCheckboxChange(
                      event,
                      checkboxData.fieldName,
                      checkboxData.value
                    )
                  }
                >
                  {" "}
                  {checkboxData.label}
                </Form.Checkbox>
              ))}
            </div>
          </Form.Control>
        </div>

        <div className="case-analytics-section">
          <Form.Control>
            <Form.Label>Education</Form.Label>
            <div className="case-checkbox-container">
              {EDUCATION_CHECKBOXES.map((checkboxData) => (
                <Form.Checkbox
                  key={checkboxData.value}
                  checked={
                    caseArguments[checkboxData.fieldName] &&
                    caseArguments[checkboxData.fieldName].includes(
                      checkboxData.value
                    )
                  }
                  name={checkboxData.value}
                  onChange={(event: any) =>
                    handleCheckboxChange(
                      event,
                      checkboxData.fieldName,
                      checkboxData.value
                    )
                  }
                >
                  {" "}
                  {checkboxData.label}
                </Form.Checkbox>
              ))}
            </div>
          </Form.Control>
        </div>

        <div className="case-analytics-section">
          <Form.Control>
            <Form.Label>Transportation</Form.Label>
            <div className="case-checkbox-container">
              {TRANSPORTATION_CHECKBOXES.map((checkboxData) => (
                <Form.Checkbox
                  key={checkboxData.value}
                  checked={
                    caseArguments[checkboxData.fieldName] &&
                    caseArguments[checkboxData.fieldName].includes(
                      checkboxData.value
                    )
                  }
                  name={checkboxData.value}
                  onChange={(event: any) =>
                    handleCheckboxChange(
                      event,
                      checkboxData.fieldName,
                      checkboxData.value
                    )
                  }
                >
                  {" "}
                  {checkboxData.label}
                </Form.Checkbox>
              ))}
            </div>
          </Form.Control>
        </div>
      </div>

      <div className="case-analytics-row">
        <div className="case-analytics-section">
          <Form.Control>
            <Form.Label>Courts</Form.Label>
            <div className="case-checkbox-container">
              {COURTS_CHECKBOXES.map((checkboxData) => (
                <Form.Checkbox
                  key={checkboxData.value}
                  checked={
                    caseArguments[checkboxData.fieldName] &&
                    caseArguments[checkboxData.fieldName].includes(
                      checkboxData.value
                    )
                  }
                  name={checkboxData.value}
                  onChange={(event: any) =>
                    handleCheckboxChange(
                      event,
                      checkboxData.fieldName,
                      checkboxData.value
                    )
                  }
                >
                  {" "}
                  {checkboxData.label}
                </Form.Checkbox>
              ))}
            </div>
          </Form.Control>
        </div>

        <div className="case-analytics-section">
          <Form.Control>
            <Form.Label>Case Type</Form.Label>
            <div className="case-checkbox-container">
              {CASE_TYPE_CHECKBOXES.map((checkboxData) => (
                <Form.Checkbox
                  key={checkboxData.value}
                  checked={
                    caseArguments[checkboxData.fieldName] &&
                    caseArguments[checkboxData.fieldName].includes(
                      checkboxData.value
                    )
                  }
                  name={checkboxData.value}
                  onChange={(event: any) =>
                    handleCheckboxChange(
                      event,
                      checkboxData.fieldName,
                      checkboxData.value
                    )
                  }
                >
                  {" "}
                  {checkboxData.label}
                </Form.Checkbox>
              ))}
            </div>
          </Form.Control>
        </div>

        <div className="case-analytics-section">
          <Form.Control>
            <Form.Label>Weeks in Program</Form.Label>
            <div className="case-checkbox-container">
              {WEEKS_IN_PROGRAM_CHECKBOXES.map((checkboxData) => (
                <Form.Checkbox
                  key={checkboxData.value.min}
                  checked={
                    caseArguments[checkboxData.fieldName] &&
                    caseArguments[checkboxData.fieldName].includes(
                      checkboxData.value
                    )
                  }
                  name={String(checkboxData.value.min)}
                  onChange={(event: any) =>
                    handleCheckboxChange(
                      event,
                      checkboxData.fieldName,
                      checkboxData.value
                    )
                  }
                >
                  {" "}
                  {checkboxData.label}
                </Form.Checkbox>
              ))}
            </div>
          </Form.Control>
        </div>

        <div className="case-analytics-section">
          <Form.Control>
            <Form.Label>Absconded</Form.Label>
            <div className="case-checkbox-container">
              {ABSCONDED_CHECKBOXES.map((checkboxData) => (
                <Form.Checkbox
                  key={checkboxData.value}
                  checked={
                    caseArguments[checkboxData.fieldName] &&
                    caseArguments[checkboxData.fieldName].includes(
                      checkboxData.value
                    )
                  }
                  name={checkboxData.value}
                  onChange={(event: any) =>
                    handleCheckboxChange(
                      event,
                      checkboxData.fieldName,
                      checkboxData.value
                    )
                  }
                >
                  {" "}
                  {checkboxData.label}
                </Form.Checkbox>
              ))}
            </div>
          </Form.Control>
        </div>

        <div className="case-analytics-section">
          <Form.Control>
            <Form.Label>EMH</Form.Label>
            <div className="case-checkbox-container">
              {IS_EMH_CHECKBOXES.map((checkboxData) => (
                <Form.Checkbox
                  key={checkboxData.value}
                  checked={
                    caseArguments[checkboxData.fieldName] &&
                    caseArguments[checkboxData.fieldName].includes(
                      checkboxData.value
                    )
                  }
                  name={checkboxData.value}
                  onChange={(event: any) =>
                    handleCheckboxChange(
                      event,
                      checkboxData.fieldName,
                      checkboxData.value
                    )
                  }
                >
                  {" "}
                  {checkboxData.label}
                </Form.Checkbox>
              ))}
            </div>
          </Form.Control>
        </div>
      </div>

      <div className="case-analytics-row">
        <div className="case-analytics-section">
          <Form.Control>
            <Form.Label>Gender</Form.Label>
            <div className="case-checkbox-container">
              {GENDER_CHECKBOXES.map((checkboxData) => (
                <Form.Checkbox
                  key={checkboxData.value}
                  checked={
                    caseArguments[checkboxData.fieldName] &&
                    caseArguments[checkboxData.fieldName].includes(
                      checkboxData.value
                    )
                  }
                  name={checkboxData.value}
                  onChange={(event: any) =>
                    handleCheckboxChange(
                      event,
                      checkboxData.fieldName,
                      checkboxData.value
                    )
                  }
                >
                  {" "}
                  {checkboxData.label}
                </Form.Checkbox>
              ))}
            </div>
          </Form.Control>
        </div>
        <div className="case-analytics-section-filler">
          <div style={{ width: "225px" }}></div>
        </div>
        <div className="case-analytics-section-filler">
          <div style={{ width: "225px" }}></div>
        </div>
        <div className="case-analytics-section-filler">
          <div style={{ width: "225px" }}></div>
        </div>
        <div className="case-analytics-section-filler">
          <div style={{ width: "225px" }}></div>
        </div>
      </div>

      <QueryRenderer
        query={caseAnalyticsGraphqlQuery}
        variables={{
          ...caseArguments,
        }}
        SuccessComponent={(props: CaseStateReturnType) => {
          return <CaseAnalyticsResult {...props} />;
        }}
      />
    </div>
  );
}

function CaseAnalyticsResult(props: CaseStateReturnType) {
  const yearlyResultElements = props.case_stats.map((yearlyProgramResult) => {
    const resultYearLabel =
      yearlyProgramResult.year === "all"
        ? "Overall Result"
        : yearlyProgramResult.year;

    const overallSuccessRate =
      yearlyProgramResult.number_completed_successful &&
      yearlyProgramResult.number_completed_total
        ? `${(
            (yearlyProgramResult.number_completed_successful /
              yearlyProgramResult.number_completed_total) *
            100
          ).toFixed(2)}%`
        : "0%";

    const recidivismRate =
      yearlyProgramResult.number_no_recidivism && yearlyProgramResult.total
        ? `${(
            (yearlyProgramResult.number_no_recidivism /
              yearlyProgramResult.number_recidivated_total) *
            100
          ).toFixed(2)}%`
        : "0.00%";

    return (
      <div
        className="case-analytics-results-row"
        key={yearlyProgramResult.year}
      >
        <h1>{resultYearLabel}</h1>
        <div className="case-analytics-result-box">
          <p className="case-result-box-title">Program Completion</p>
          <p>Success Rate: {overallSuccessRate}</p>
          <p>Successful: {yearlyProgramResult.number_completed_successful}</p>
          <p>
            Unsuccessful: {yearlyProgramResult.number_completed_unsuccessful}
          </p>
          <p>Total: {yearlyProgramResult.number_completed_total}</p>
        </div>
        <div className="case-analytics-result-box">
          <p className="case-result-box-title">Recidivism</p>
          <p>% No Recidivism: {recidivismRate}</p>
          <p>No Recidivism: {yearlyProgramResult.number_no_recidivism}</p>
          <p>Recidivism: {yearlyProgramResult.number_recidivism}</p>
          <p>Total: {yearlyProgramResult.number_recidivated_total}</p>
        </div>
      </div>
    );
  });

  return <div id="case-analytics-results">{yearlyResultElements}</div>;
}
