/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type AnalyticsReportContainerQueryVariables = {
    readonly week?: number | null;
    readonly month?: number | null;
    readonly year?: number | null;
};
export type AnalyticsReportContainerQueryResponse = {
    readonly stats_report: ({
        readonly from_date: string | null;
        readonly to_date: string | null;
        readonly report_weeks: ReadonlyArray<({
            readonly start_time: string | null;
            readonly week: number | null;
            readonly year: number | null;
        }) | null> | null;
        readonly report_months: ReadonlyArray<({
            readonly start_time: string | null;
            readonly month: number | null;
            readonly year: number | null;
        }) | null> | null;
        readonly notification_counts: ({
            readonly totals: ({
                readonly title: string | null;
                readonly total: number | null;
                readonly sent: number | null;
                readonly upcoming: number | null;
                readonly failed: number | null;
            }) | null;
            readonly breakdowns: ReadonlyArray<({
                readonly title: string | null;
                readonly total: number | null;
                readonly sent: number | null;
                readonly upcoming: number | null;
                readonly failed: number | null;
            }) | null> | null;
        }) | null;
        readonly event_counts: ({
            readonly totals: ({
                readonly title: string | null;
                readonly total: number | null;
                readonly with_appearance: number | null;
                readonly attended: number | null;
                readonly avg_attended: number | null;
                readonly rescheduled: number | null;
                readonly not_attended: number | null;
                readonly no_data: number | null;
                readonly future_event: number | null;
            }) | null;
            readonly breakdowns: ReadonlyArray<({
                readonly title: string | null;
                readonly total: number | null;
                readonly with_appearance: number | null;
                readonly attended: number | null;
                readonly avg_attended: number | null;
                readonly rescheduled: number | null;
                readonly not_attended: number | null;
                readonly no_data: number | null;
                readonly future_event: number | null;
            }) | null> | null;
        }) | null;
        readonly event_list: ReadonlyArray<({
            readonly attended: string | null;
            readonly date: string | null;
            readonly participant: ({
                readonly id: string | null;
                readonly name: string | null;
            }) | null;
            readonly type: string | null;
        }) | null> | null;
        readonly participant_list: ReadonlyArray<({
            readonly attendance_data_count: number | null;
            readonly attendance_rate: number | null;
            readonly date: string | null;
            readonly event_count: number | null;
            readonly notification_count: number | null;
            readonly rescheduled_data_count: number | null;
            readonly participant: ({
                readonly id: string | null;
                readonly name: string | null;
            }) | null;
        }) | null> | null;
    }) | null;
};
export type AnalyticsReportContainerQuery = {
    readonly response: AnalyticsReportContainerQueryResponse;
    readonly variables: AnalyticsReportContainerQueryVariables;
};



/*
query AnalyticsReportContainerQuery(
  $week: Int
  $month: Int
  $year: Int
) {
  stats_report(week: $week, month: $month, year: $year) {
    from_date
    to_date
    report_weeks {
      start_time
      week
      year
    }
    report_months {
      start_time
      month
      year
    }
    notification_counts {
      totals {
        title
        total
        sent
        upcoming
        failed
      }
      breakdowns {
        title
        total
        sent
        upcoming
        failed
      }
    }
    event_counts {
      totals {
        title
        total
        with_appearance
        attended
        avg_attended
        rescheduled
        not_attended
        no_data
        future_event
      }
      breakdowns {
        title
        total
        with_appearance
        attended
        avg_attended
        rescheduled
        not_attended
        no_data
        future_event
      }
    }
    event_list {
      attended
      date
      participant {
        id
        name
      }
      type
    }
    participant_list {
      attendance_data_count
      attendance_rate
      date
      event_count
      notification_count
      rescheduled_data_count
      participant {
        id
        name
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "week",
            "type": "Int",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "month",
            "type": "Int",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "year",
            "type": "Int",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "start_time",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "year",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "title",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "total",
        "args": null,
        "storageKey": null
    } as any), v5 = [
        (v3 /*: any*/),
        (v4 /*: any*/),
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "sent",
            "args": null,
            "storageKey": null
        } as any),
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "upcoming",
            "args": null,
            "storageKey": null
        } as any),
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "failed",
            "args": null,
            "storageKey": null
        } as any)
    ], v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "attended",
        "args": null,
        "storageKey": null
    } as any), v7 = [
        (v3 /*: any*/),
        (v4 /*: any*/),
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "with_appearance",
            "args": null,
            "storageKey": null
        } as any),
        (v6 /*: any*/),
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "avg_attended",
            "args": null,
            "storageKey": null
        } as any),
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "rescheduled",
            "args": null,
            "storageKey": null
        } as any),
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "not_attended",
            "args": null,
            "storageKey": null
        } as any),
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "no_data",
            "args": null,
            "storageKey": null
        } as any),
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "future_event",
            "args": null,
            "storageKey": null
        } as any)
    ], v8 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "date",
        "args": null,
        "storageKey": null
    } as any), v9 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "participant",
        "storageKey": null,
        "args": null,
        "concreteType": "StatItemParticipant",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v10 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "stats_report",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "month",
                    "variableName": "month"
                },
                {
                    "kind": "Variable",
                    "name": "week",
                    "variableName": "week"
                },
                {
                    "kind": "Variable",
                    "name": "year",
                    "variableName": "year"
                }
            ],
            "concreteType": "StatsReport",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "from_date",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "to_date",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "report_weeks",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StatWeek",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "week",
                            "args": null,
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "report_months",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StatMonth",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "month",
                            "args": null,
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "notification_counts",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StatNotificationCountItem",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "totals",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "StatNotificationCountValues",
                            "plural": false,
                            "selections": (v5 /*: any*/)
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "breakdowns",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "StatNotificationCountValues",
                            "plural": true,
                            "selections": (v5 /*: any*/)
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "event_counts",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StatEventCountItem",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "totals",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "StatEventCountValues",
                            "plural": false,
                            "selections": (v7 /*: any*/)
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "breakdowns",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "StatEventCountValues",
                            "plural": true,
                            "selections": (v7 /*: any*/)
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "event_list",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StatEventListItem",
                    "plural": true,
                    "selections": [
                        (v6 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "type",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participant_list",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StatParticipantListItem",
                    "plural": true,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "attendance_data_count",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "attendance_rate",
                            "args": null,
                            "storageKey": null
                        },
                        (v8 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "event_count",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "notification_count",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "rescheduled_data_count",
                            "args": null,
                            "storageKey": null
                        },
                        (v9 /*: any*/)
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "AnalyticsReportContainerQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v10 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "AnalyticsReportContainerQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v10 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "AnalyticsReportContainerQuery",
            "id": null,
            "text": "query AnalyticsReportContainerQuery(\n  $week: Int\n  $month: Int\n  $year: Int\n) {\n  stats_report(week: $week, month: $month, year: $year) {\n    from_date\n    to_date\n    report_weeks {\n      start_time\n      week\n      year\n    }\n    report_months {\n      start_time\n      month\n      year\n    }\n    notification_counts {\n      totals {\n        title\n        total\n        sent\n        upcoming\n        failed\n      }\n      breakdowns {\n        title\n        total\n        sent\n        upcoming\n        failed\n      }\n    }\n    event_counts {\n      totals {\n        title\n        total\n        with_appearance\n        attended\n        avg_attended\n        rescheduled\n        not_attended\n        no_data\n        future_event\n      }\n      breakdowns {\n        title\n        total\n        with_appearance\n        attended\n        avg_attended\n        rescheduled\n        not_attended\n        no_data\n        future_event\n      }\n    }\n    event_list {\n      attended\n      date\n      participant {\n        id\n        name\n      }\n      type\n    }\n    participant_list {\n      attendance_data_count\n      attendance_rate\n      date\n      event_count\n      notification_count\n      rescheduled_data_count\n      participant {\n        id\n        name\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '59cf51d2cb166d3189caecfab7dcdf8a';
export default node;
