import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { CaseAnalytics } from "../case-analytics/CaseAnalytics";

export class CaseAnalyticsScreen extends React.PureComponent<
  RouteComponentProps
> {
  render() {
    return <CaseAnalytics />;
  }
}
