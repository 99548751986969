import * as React from "react";
import idx from "idx.macro";

import { CarePlanContext } from "../../utils/context";
import { QueryRenderer } from "../../shared/lib/graphql";

import {
  updateObligation,
  UpdateObligationGqlArguments,
} from "../../actions/obligations";
import {
  CarePlanQuery,
  CarePlanQueryResult,
  CarePlanObligationType,
} from "./CarePlanQuery";
import { CarePlan } from "./CarePlan";

export interface CarePlanContainerProps {
  id: string;
}

export class CarePlanContainer extends React.PureComponent<
  CarePlanContainerProps
> {
  render() {
    const { id } = this.props;

    return (
      <QueryRenderer
        query={CarePlanQuery}
        variables={{ id }}
        SuccessComponent={(props: CarePlanQueryResult) => {
          const activityInformation = idx(
            props,
            (_) => _.application.activity_information
          );
          const participant = idx(props, (_) => _.participants.participant[0]);
          const compliance = idx(
            props,
            (_) => _.application.compliance_options
          );
          const obligation_status = idx(
            props,
            (_) => _.application.obligation_status_options
          );
          const serviceProviders = idx(
            props,
            (_) => _.service_providers.service_provider
          );

          const dispositionOptions = idx(
            props,
            (_) => _.application.disposition_options
          );

          if (serviceProviders && serviceProviders.length === 0) {
            console.warn(
              `No service providers found, this is probably an error. Can't add Obligations without any service providers.`
            );
          }

          return (
            <CarePlanContext.Provider
              value={{
                activityInformation,
                compliance,
                obligation_status,
                participant,
                serviceProviders,
                dispositionOptions,
                updateObligationMutation: (
                  mutationPayload: UpdateObligationGqlArguments,
                  obligation: CarePlanObligationType
                ) =>
                  updateObligation(mutationPayload, participant.id, obligation),
              }}
            >
              <CarePlan
                participant={participant}
                activityInformation={activityInformation}
              />
            </CarePlanContext.Provider>
          );
        }}
      />
    );
  }
}
