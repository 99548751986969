import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "../shared/lib/graphql/commitMutation";
import { BroadcastMessage, MutationResponseBase } from "../shared/lib/graphql";
import { BroadcastMessageRecipient } from "../../../shared/src/lib/graphql";
import { RecordSourceSelectorProxy } from "relay-runtime";
import { checkMutationReturn } from "./util";

export interface AddBroadcastMessageGqlArguments {
  text: string;
  to: BroadcastMessageRecipient;
  time_to_deploy?: Date;
}

export interface UpdateBroadcastMessageGqlArguments
  extends AddBroadcastMessageGqlArguments {
  id: string;
}

export interface BroadcastMessageMutationResult extends MutationResponseBase {
  broadcast_message: BroadcastMessage;
}

export const addBroadcastMessage = async (
  variables: AddBroadcastMessageGqlArguments
): Promise<BroadcastMessageMutationResult> => {
  return commitMutation(
    {
      mutation: graphql`
        mutation broadcastMessagesAddMutation(
          $text: String!
          $to: BroadcastMessageRecipientInput!
          $time_to_deploy: String
        ) {
          addBroadcastMessageFromCaseManager(
            text: $text
            to: $to
            time_to_deploy: $time_to_deploy
          ) {
            result
            description
            errors
            broadcast_message {
              id
              created_at
              deployed_at
              deployed
              scheduled
              text
              to {
                queryType
                arg
              }
              case_manager {
                name {
                  first
                  last
                }
              }
              time_to_deploy
            }
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreAddBroadcastMessage(
          store,
          "addBroadcastMessageFromCaseManager"
        );
      },
    },
    "Error while adding broadcast message"
  ) as any;
};

const updateRelayStoreAddBroadcastMessage = (
  store: RecordSourceSelectorProxy,
  rootFieldName: string
) => {
  const payload = checkMutationReturn(store, rootFieldName);

  if (!payload) return;

  const newBroadcastMessage = payload.getLinkedRecord("broadcast_message");

  const root = store.getRoot();

  const broadcastMessages = root.getLinkedRecord("broadcast_messages");

  if (!broadcastMessages) {
    console.error(`Didn't find root broadcast_messages after ${rootFieldName}`);
    return;
  }

  const existingBroadcastMessages = broadcastMessages!.getLinkedRecords(
    "broadcast_message"
  );

  const combined = [newBroadcastMessage].concat(existingBroadcastMessages);

  broadcastMessages!.setLinkedRecords(combined, "broadcast_message");
};

export const updateBroadcastMessage = async (
  variables: UpdateBroadcastMessageGqlArguments
): Promise<BroadcastMessageMutationResult> => {
  return commitMutation(
    {
      mutation: graphql`
        mutation broadcastMessagesUpdateMutation(
          $id: String!
          $text: String!
          $to: BroadcastMessageRecipientInput!
          $time_to_deploy: String
        ) {
          updateBroadcastMessageFromCaseManager(
            id: $id
            text: $text
            to: $to
            time_to_deploy: $time_to_deploy
          ) {
            result
            description
            errors
            broadcast_message {
              id
              created_at
              deployed_at
              deployed
              scheduled
              text
              to {
                queryType
                arg
              }
              case_manager {
                name {
                  first
                  last
                }
              }
              time_to_deploy
            }
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreUpdateBroadcastMessage(
          variables.id,
          store,
          "updateBroadcastMessageFromCaseManager"
        );
      },
    },
    "Error while adding broadcast message"
  ) as any;
};

const updateRelayStoreUpdateBroadcastMessage = (
  broadcastMessageId: string,
  store: RecordSourceSelectorProxy,
  rootFieldName: string
) => {
  const payload = checkMutationReturn(store, rootFieldName);

  if (!payload) return;

  const existing = store.get(broadcastMessageId);

  existing!.copyFieldsFrom(payload);
};

export const deleteBroadcastMessage = async (
  id: string
): Promise<BroadcastMessageMutationResult> => {
  return commitMutation(
    {
      mutation: graphql`
        mutation broadcastMessagesDeleteMutation($id: String!) {
          deleteBroadcastMessageFromCaseManager(id: $id) {
            result
            description
            errors
            ids
          }
        }
      `,
      variables: { id },

      updater: (store) => {
        const payload = checkMutationReturn(
          store,
          "deleteBroadcastMessageFromCaseManager"
        );

        if (!payload) return;

        store.delete(id);
      },
    },
    "Error while deleting broadcast message"
  ) as any;
};
