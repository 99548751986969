import * as React from "react";
import { AnalyticsEventListItem } from "./analyticsTypes";
import SortableTable from "../../shared/components/table/SortableTable";
import moment from "moment";
import "./defaultAnalyticsTable.scss";
import { getFeatureFlags } from "../../featureFlags";

interface Props {
  data: Array<AnalyticsEventListItem>;
}

const DATA_COLUMNS = [
  { key: "type", header: "Activity Type" },
  {
    key: (item: AnalyticsEventListItem) => {
      const dateStr = item["date"];

      return moment(dateStr).format("MM/DD/YYYY, hh:mm A");
    },
    header: "Date",
  },
  {
    key: (item: AnalyticsEventListItem) => {
      return item.participant.name;
    },
    header: getFeatureFlags().clientDisplayTerm,
  },
  {
    key: "attended",
    header: "Attended",
  },
].map((d) => ({
  ...d,
  sortable: false,
}));

export default class EventAttendanceTable extends React.Component<Props> {
  render() {
    return (
      <div className="defaultAnalyticsTable">
        <SortableTable columns={DATA_COLUMNS} data={this.props.data} />
      </div>
    );
  }
}
