import * as React from "react";

import { EventAttendence } from "../../shared/lib/graphql/flowTypes";

import Select from "../../shared/components/elements/Select";

export interface EventAttendanceSelectProps {
  isReadOnly?: boolean;
  attended?: string;
  onChange: (evt: React.ChangeEvent<HTMLSelectElement>) => Promise<void> | void;
}

export interface EventAttendanceComplianceColors {
  [key: string]: string;
}

const getComplianceColor = (compliance: string | undefined) => {
  const cc: EventAttendanceComplianceColors = {
    yes: "success",
    no: "danger",
  };
  return (compliance && cc[compliance]) || null;
};

export const EventAttendanceTitles: { [key: string]: string } = {
  [EventAttendence.Yes]: "Attended",
  [EventAttendence.No]: "Did not attend",
  [EventAttendence.Excused]: "Excused",
  [EventAttendence.Rescheduled]: "Event Rescheduled",
};

// Just map the titles object into an array for selecting
const EventAttendanceOptions = Object.keys(EventAttendanceTitles).map(
  (key) => ({
    key,
    value: EventAttendanceTitles[key],
  })
);

export const EventAttendanceSelect = (props: EventAttendanceSelectProps) => (
  <Select
    name="attended"
    disabled={props.isReadOnly}
    instructionalDefault={"Select attendance..."}
    options={EventAttendanceOptions}
    color={getComplianceColor(props.attended)}
    value={props.attended}
    onChange={props.onChange}
  />
);
