// Deliberately have no Flow coverage on this file as it needs to do some janky metaprogramming.
// Set the name of the hidden property and the change event for visibility
var hidden: any, visibilityChange: any;
if (typeof document.hidden !== "undefined") {
  // Opera 12.10 and Firefox 18 and later support
  hidden = "hidden";
  visibilityChange = "visibilitychange";
} else if (typeof (document as any).msHidden !== "undefined") {
  hidden = "msHidden";
  visibilityChange = "msvisibilitychange";
} else if (typeof (document as any).webkitHidden !== "undefined") {
  hidden = "webkitHidden";
  visibilityChange = "webkitvisibilitychange";
}

let listeners: any[] = [];
let addedCallback = false;

function handleEvent() {
  listeners.forEach((listener) => {
    listener(!((document as any)[hidden] as any));
  });
}

export function addWindowFocusListener(callback: Function): void {
  if (typeof document.addEventListener === "undefined" || !hidden) {
    // no op
  } else {
    if (!addedCallback) {
      addedCallback = true;

      document.addEventListener(visibilityChange, handleEvent, false);
    }
    listeners.push(callback);
  }
}

export function removeWindowFocusListener(callback: Function): void {
  if (typeof document.removeEventListener === "undefined" || !hidden) {
    // no op
  } else {
    listeners = listeners.filter((listener) => listener !== callback);
  }
}
