import * as React from "react";
import idx from "idx.macro";
import { Redirect, RouteComponentProps, Location } from "@reach/router";
import { QueryRenderer } from "../../shared/lib/graphql";

import { AppGqlResponse } from "../../AppGql";
import {
  WorkflowScreenGql,
  WorkflowScreenGqlResponse,
} from "./WorkflowScreenGql";
import { GenericErrorComponent } from "../../shared/components/error-boundaries/GenericErrorComponent";
import { WorkflowContainer } from "../workflow/WorkflowContainer";
import { CaseManager } from "../../../../shared/src/lib/graphql";
import { CaseManagerSelect } from "../elements/CaseManagerSelect";
import { ROOT_PATH } from "../../AppInfo";

export const CASE_MANAGER_ALL_ITEMS = "all";

export interface WorkflowScreenProps {
  appInfo: AppGqlResponse;
  baseUri: string;
  caseManagerId?: string;
  path: string;
}

const renderCaseManagerSelect = (
  navigate: (url: string) => void,
  baseUrl: string,
  caseManagers: CaseManager[],
  caseManagerId?: string
) => (
  <CaseManagerSelect
    caseManagers={caseManagers}
    value={caseManagerId}
    onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
      const { value: id } = evt.target;
      navigate(`${baseUrl}${id}`);
    }}
  />
);

export class WorkflowScreen extends React.PureComponent<
  RouteComponentProps<WorkflowScreenProps>
> {
  render() {
    const { path, baseUri, caseManagerId } = this.props;

    return (
      <Location>
        {({ navigate }) => (
          <QueryRenderer
            query={WorkflowScreenGql}
            SuccessComponent={(props: WorkflowScreenGqlResponse) => {
              const buckets = idx(
                props,
                (_) => _.application.dashboard_buckets
              );

              if (!buckets) {
                console.error("No workflow buckets found.");
                return (
                  <GenericErrorComponent
                    whatHappened="No workflow is ready"
                    whatShouldIDo="Contact Promise or your Administrator"
                  />
                );
              }

              // Check to do redirection to default or self case manager, if not otherwise defined
              const meCaseManager = idx(props, (_) => _.me.case_manager);
              const isCaseManager = !!meCaseManager;

              if (!caseManagerId) {
                const redirectToCM = isCaseManager
                  ? meCaseManager.id
                  : CASE_MANAGER_ALL_ITEMS;
                return <Redirect to={`${path}/${redirectToCM}`} noThrow />;
              }

              const caseManagers =
                idx(props, (_) => _.all_case_managers.case_manager) || [];
              const caseManagersSelectable = [
                {
                  id: "all",
                  name: { first: "All", last: "" },
                } as CaseManager,
              ].concat(caseManagers);

              return (
                <WorkflowContainer
                  baseUrl={baseUri || ""}
                  caseManagerId={caseManagerId}
                  buckets={buckets}
                  pageTitleTag={renderCaseManagerSelect(
                    navigate,
                    `/${ROOT_PATH}/workflow/`,
                    caseManagersSelectable,
                    caseManagerId
                  )}
                />
              );
            }}
          />
        )}
      </Location>
    );
  }
}
