import { initialize as initializeReactGA, set } from "react-ga";

import { WebApps, APP_CONFIGS } from "./configs";
import { skipIsDev } from "../util";

export const initGoogleAnalytics = (app: WebApps) => {
  if (skipIsDev()) return;

  const config = APP_CONFIGS[app];

  if (!config) {
    console.error(`Unknown app "${app}"`);
    return;
  } else if (!config.googleAnalytics) {
    console.warn(`No Google Analytics configuration for app "${app}".`);
    return;
  }

  initializeReactGA(config.googleAnalytics.key);

  set({
    appName: app,
    appVersion: config.release,
  });
};
