import React from "react";
import { QueryRenderer } from "../../shared/lib/graphql";
import {
  ParticipantDetailsQueryResult,
  ParticipantDetailsQuery,
} from "../screens/ParticipantDetailsQueryTypes";
import idx from "idx.macro";
import { Table } from "react-bulma-components";
import { CarePlanContainerProps } from "../care-plan/CarePlanContainer";
import { FinesAndFees, Maybe } from "../../graphql-types";
import moment from "moment";

export function ParticipantAccountingContainer(props: CarePlanContainerProps) {
  const { id } = props;

  return (
    <QueryRenderer
      query={ParticipantDetailsQuery}
      variables={{ id }}
      SuccessComponent={(props: ParticipantDetailsQueryResult) => {
        const finesAndFees = idx(
          props,
          (_) => _.participants.participant[0].fines_and_fees
        ) as FinesAndFees;

        const {
          fines_and_fees_amount_owed,
          fines_and_fees_last_payment_amount,
          fines_and_fees_last_payment_date,
          fines_and_fees_expected_payment_amount,
        } = finesAndFees;

        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "90%" }}>
              <Table className="is-narrow is-hoverable">
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>Total Amount Owed</th>
                    <th style={{ textAlign: "center" }}>Last Payment Amount</th>
                    <th style={{ textAlign: "center" }}>Last Payment Date</th>
                    <th style={{ textAlign: "center" }}>
                      Expected Payment Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "right" }}>
                      {convertFinesAndFeesToDollarString(
                        fines_and_fees_amount_owed
                      )}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {convertFinesAndFeesToDollarString(
                        fines_and_fees_last_payment_amount
                      )}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {fines_and_fees_last_payment_date
                        ? moment(fines_and_fees_last_payment_date).format(
                            "MM/DD/YYYY"
                          )
                        : "--"}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {convertFinesAndFeesToDollarString(
                        fines_and_fees_expected_payment_amount
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        );
      }}
    />
  );
}

function convertFinesAndFeesToDollarString(
  finesAndFeesAmountInCents: Maybe<number>
) {
  const finesAndFeesAmountUnwrapped = finesAndFeesAmountInCents || 0;
  return finesAndFeesAmountUnwrapped < 0
    ? `-$${(
        Math.abs(finesAndFeesAmountUnwrapped) / 100
      ).toLocaleString(undefined, { minimumFractionDigits: 4 })}`
    : `$${(finesAndFeesAmountUnwrapped / 100).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      })}`;
}
