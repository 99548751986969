import * as React from "react";
import ModalContainer from "./ModalContainer";
import ModalConfirmationPage from "./ModalConfirmationPage";
import { ValidationsType, FormDataBase } from "./types";
import { Operation } from "./types";
import { SomeOfThePropsTypedFromModalWrapper } from "../HOCs/ModalWrapperHOC";
import { ModalConfirmationPageProps } from "./ModalConfirmationPage";

interface ModalWrapperState<T> {
  modalFormData: T;
  showModal: boolean;
  validations: ValidationsType;
}

export type ModalCallbackFnType<TData = any> = (
  data: TData,
  closeModal: () => void
) => void;

export interface ModalPropsPassThrough<T> {
  operation: Operation;
  type: string;
  SecondPageComponent?: React.ComponentType<ModalConfirmationPageProps<T>>;
  onDelete?: (evt: React.SyntheticEvent<HTMLButtonElement>) => void; // I'm not convinced this should be here 🚢 - 2nd palce
}

export type renderAsFnArg = {
  children: React.ReactNode;
};

export type renderAsFn = (props: renderAsFnArg) => React.ReactNode;

export interface ModalProps<TData, TType = any, TType2 = any>
  extends ModalPropsPassThrough<TData> {
  renderAs?: renderAsFn;
  // This callback should be either TData or a "post-constitue" data type
  callBackForAPI: ModalCallbackFnType<TData> | ModalCallbackFnType<any>;
  updateObject?: TType;
  metaDataObject?: TType2;
  disableButton?: boolean;
  buttonComponent?: React.ReactElement<any>;
}

// this name: ugg
type ModalPropsBaseBase<TData, TType, TType2> = ModalProps<
  TData,
  TType,
  TType2
> &
  SomeOfThePropsTypedFromModalWrapper;

// This constructed thing is a cluster at this point
interface ModalPropsBase<TData, TType = any, TType2 = any>
  extends ModalPropsBaseBase<TData, TType, TType2> {
  children: (ChildrenArgs: ChildrenArgs<TData>) => JSX.Element;
  name: String;
  secondPage: boolean;
  secondPageMessageWarning?: string;
  showButton?: boolean;
  disableButton?: boolean;
  buttonComponent?: React.ReactElement<any>;
  page: Number;
  showModal: boolean;
  togglePage: (event: any) => React.Component<any>;
}

interface ChildrenArgs<TData>
  extends Pick<
    SomeOfThePropsTypedFromModalWrapper,
    | "handleModalFormInputChange"
    | "handleFormSubmit"
    | "handleDatePickerChange"
    | "handleDatePickerRawChange"
    | "handlePhoneValidation"
    | "updateSpecifiedFields"
  > {
  attemptedSubmission: Function;
  modalFormData: TData;
  secondPage: boolean;
  secondPageMessageWarning?: string;
  validations: ValidationsType;
}
export type ModalWrapperProps<TData, TType> = ModalPropsBase<TData, TType> &
  ChildrenArgs<TData> &
  ModalProps<TData, TType>;

class ModalWrapper<
  TData extends FormDataBase,
  TType = any
> extends React.PureComponent<
  ModalWrapperProps<TData, TType>,
  ModalWrapperState<TData>
> {
  _handleFormConfirmSubmit = () => {
    this.props.handleFormConfirmSubmit(this.props.callBackForAPI);
  };

  _renderChildren = () => {
    const {
      children,
      modalFormData,
      validations,
      attemptedSubmission,
      secondPage,
      secondPageMessageWarning,
      handleModalFormInputChange,
      handleDatePickerChange,
      handleDatePickerRawChange,
      handleFormSubmit,
      handlePhoneValidation,
      updateSpecifiedFields,
    } = this.props;

    return children({
      modalFormData,
      validations,
      attemptedSubmission,
      secondPage,
      secondPageMessageWarning,
      handleModalFormInputChange,
      handleFormSubmit,
      handleDatePickerChange,
      handleDatePickerRawChange,
      handlePhoneValidation,
      updateSpecifiedFields,
    });
  };

  /**
   * This is the "definitive" ModalWrapper result, a renderAs prop short circuits this
   */
  private renderModalContainer = (
    children: React.ReactNode
  ): React.ReactNode => {
    const {
      secondPage,
      handleModalOpen,
      handleModalClose,
      showModal,
      showButton,
      disableButton,
      buttonComponent,
      operation,
      type,
    } = this.props;

    return (
      <ModalContainer
        operation={operation}
        type={type}
        handleModalOpen={handleModalOpen}
        handleModalClose={handleModalClose}
        showModal={showModal}
        secondPage={secondPage}
        showButton={showButton}
        disableButton={disableButton}
        buttonComponent={buttonComponent}
      >
        {children}
      </ModalContainer>
    );
  };

  private renderSecondPage = () => {
    const {
      secondPageMessageWarning,
      modalFormData,
      SecondPageComponent,
      onDelete,
      togglePage,
      type,
    } = this.props;

    return SecondPageComponent ? (
      <SecondPageComponent
        name={type.toLowerCase()}
        modalFormData={modalFormData}
        togglePage={togglePage}
        onDelete={onDelete}
        secondPageMessageWarning={secondPageMessageWarning}
        handleFormConfirmSubmit={this._handleFormConfirmSubmit}
      />
    ) : (
      <ModalConfirmationPage
        name={type.toLowerCase()}
        modalFormData={modalFormData}
        onDelete={onDelete}
        togglePage={togglePage}
        secondPageMessageWarning={secondPageMessageWarning}
        handleFormConfirmSubmit={this._handleFormConfirmSubmit}
      />
    );
  };

  render() {
    const { secondPage, renderAs } = this.props;

    const children =
      secondPage === false ? this._renderChildren() : this.renderSecondPage();

    return !renderAs
      ? this.renderModalContainer(children)
      : renderAs({ children });
  }
}

export default ModalWrapper;
