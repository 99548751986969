import { ParticipantFormCellData } from "../participant-profile/participantDetailsFormData";

export const CASE_FORM_DETAILS_DATA: ParticipantFormCellData[][][] = [
  [
    [
      {
        label: "Supervision Begin Date",
        value: "",
        type: "date",
        fieldName: "supervision_start_date",
      },
      {
        label: "Supervision End Date",
        value: "",
        type: "date",
        fieldName: "supervision_end_date",
      },
      {
        label: "Case Type",
        value: "select",
        type: "select",
        fieldName: "case_type",
        options: [
          {
            label: "None",
            fieldname: "case_type",
            value: "none",
          },
          {
            label: "Sentenced",
            fieldname: "case_type",
            value: "sentenced",
          },
          {
            label: "Pre-Trial",
            fieldname: "case_type",
            value: "pre_trial",
          },
        ],
      },
      {
        label: "EMH",
        value: "",
        type: "select",
        fieldName: "is_emh",
        options: [
          {
            fieldname: "is_emh",
            value: "unknown",
            label: "Unknown",
          },
          {
            fieldname: "is_emh",
            value: "yes",
            label: "Yes",
          },
          {
            fieldname: "is_emh",
            value: "no",
            label: "No",
          },
        ],
      },
    ],
    [
      {
        label: "Treatment Type",
        value: "",
        type: "checkbox",
        fieldName: "treatment_types",
        options: [
          {
            fieldname: "residential",
            value: false,
            label: "Residential",
          },
          {
            fieldname: "partial_hospitalization",
            value: false,
            label: "Partial Hospitalization",
          },
          {
            fieldname: "iop",
            value: false,
            label: "Outpatient (IOP)",
          },
          {
            fieldname: "recovery_living",
            value: false,
            label: "Recovery Living",
          },
        ],
      },
      {
        label: "Primary Treatment Facility",
        value: "",
        type: "checkbox",
        fieldName: "primary_treatment_facilities",
        options: [
          {
            fieldname: "prairie",
            value: false,
            label: "Prairie",
          },
          {
            fieldname: "sharehouse",
            value: false,
            label: "Sharehouse",
          },
          {
            fieldname: "se_human_services",
            value: false,
            label: "SE Human Services",
          },
          {
            fieldname: "centre",
            value: false,
            label: "Centre",
          },
          {
            fieldname: "other_private",
            value: false,
            label: "Other Private",
          },
        ],
      },
    ],
    [
      {
        label: "Medicated",
        value: "",
        type: "select",
        fieldName: "medicated",
        options: [
          {
            fieldname: "medicated",
            value: "unknown",
            label: "Unknown",
          },
          {
            fieldname: "medicated",
            value: "no",
            label: "No",
          },
          {
            fieldname: "medicated",
            value: "yes",
            label: "Yes",
          },
        ],
      },
      {
        label: "Group Meetings",
        value: "",
        type: "checkbox",
        fieldName: "group_meetings",
        options: [
          {
            fieldname: "group_meeting_aa",
            label: "AA",
            value: false,
          },
          {
            fieldname: "group_meeting_na",
            label: "NA",
            value: false,
          },
          {
            fieldname: "group_meeting_other",
            label: "Other Group",
            value: false,
          },
        ],
      },
      {
        label: "Hand off to probation",
        value: "",
        type: "select",
        fieldName: "hand_off_to_probation",
        options: [
          {
            fieldname: "hand_off_to_probation",
            label: "Unknown",
            value: "unknown",
          },
          {
            fieldname: "hand_off_to_probation",
            label: "No",
            value: "no",
          },
          {
            fieldname: "hand_off_to_probation",
            label: "Yes",
            value: "yes",
          },
        ],
      },
    ],
    [
      {
        label: "Housing",
        value: "",
        type: "select",
        fieldName: "housing",
        options: [
          {
            fieldname: "housing",
            value: "unknown",
            label: "Unknown",
          },
          {
            fieldname: "housing",
            value: "has",
            label: "Has",
          },
          {
            fieldname: "housing",
            value: "needs",
            label: "Needs",
          },
          {
            fieldname: "housing",
            value: "obtained",
            label: "Obtained",
          },
        ],
      },
      {
        label: "Employment",
        value: "",
        type: "select",
        fieldName: "employment",
        options: [
          {
            fieldname: "employment",
            value: "unknown",
            label: "Unknown",
          },
          {
            fieldname: "employment",
            value: "has",
            label: "Has",
          },
          {
            fieldname: "employment",
            value: "needs",
            label: "Needs",
          },
          {
            fieldname: "employment",
            value: "obtained",
            label: "Obtained",
          },
        ],
      },
      {
        label: "Education",
        value: "",
        type: "select",
        fieldName: "education",
        options: [
          {
            fieldname: "education",
            value: "unknown",
            label: "Unknown",
          },
          {
            fieldname: "education",
            value: "has",
            label: "Has",
          },
          {
            fieldname: "education",
            value: "needs",
            label: "Needs",
          },
          {
            fieldname: "education",
            value: "obtained",
            label: "Obtained",
          },
        ],
      },
      {
        label: "Transportation",
        value: "",
        type: "select",
        fieldName: "transportation",
        options: [
          {
            fieldname: "transportation",
            value: "unknown",
            label: "Unknown",
          },
          {
            fieldname: "transportation",
            value: "has",
            label: "Has",
          },
          {
            fieldname: "transportation",
            value: "needs",
            label: "Needs",
          },
          {
            fieldname: "transportation",
            value: "obtained",
            label: "Obtained",
          },
        ],
      },
    ],
    [
      {
        label: "Courts",
        value: "",
        type: "checkbox",
        fieldName: "courts",
        options: [
          { fieldname: "fargo", label: "Fargo", value: false },
          { fieldname: "west_fargo", label: "West Fargo", value: false },
          {
            fieldname: "cass_county_district",
            label: "Cass County District",
            value: false,
          },
        ],
      },
      {
        label: "Risk Level",
        value: "",
        type: "select",
        fieldName: "risk_level",
        options: [
          {
            fieldname: "risk_level",
            value: "none",
            label: "None",
          },
          {
            fieldname: "risk_level",
            value: "low",
            label: "Low",
          },
          {
            fieldname: "risk_level",
            value: "medium",
            label: "Medium",
          },
          {
            fieldname: "risk_level",
            value: "high",
            label: "High",
          },
        ],
      },
      {
        label: "Assessment Score",
        value: "",
        type: "select",
        fieldName: "risk_score",
        options: [
          { fieldname: "risk_score", value: "none", label: "None" },
          { fieldname: "risk_score", value: "0", label: "0" },
          { fieldname: "risk_score", value: "1", label: "1" },
          { fieldname: "risk_score", value: "2", label: "2" },
          { fieldname: "risk_score", value: "3", label: "3" },
          { fieldname: "risk_score", value: "4", label: "4" },
          { fieldname: "risk_score", value: "5", label: "5" },
          { fieldname: "risk_score", value: "6", label: "6" },
          { fieldname: "risk_score", value: "7", label: "7" },
          { fieldname: "risk_score", value: "8", label: "8" },
          { fieldname: "risk_score", value: "9", label: "9" },
          { fieldname: "risk_score", value: "10", label: "10" },
        ],
      },
    ],
    [
      {
        label: "Program Result",
        value: "select",
        type: "select",
        fieldName: "program_result",
        options: [
          {
            label: "In Progress",
            fieldname: "program_result",
            value: "in_progress",
          },
          {
            label: "Successful",
            fieldname: "program_result",
            value: "successful",
          },
          {
            label: "Unsuccessful",
            fieldname: "program_result",
            value: "unsuccessful",
          },
          {
            label: "Temporarily suspended",
            fieldname: "program_result",
            value: "temporarily_suspended",
          },
        ],
      },
      {
        label: "Absconded",
        value: "select",
        type: "select",
        fieldName: "absconded",
        options: [
          {
            label: "Unknown",
            fieldname: "absconded",
            value: "unknown",
          },
          {
            label: "Yes",
            fieldname: "absconded",
            value: "yes",
          },
          {
            label: "No",
            fieldname: "absconded",
            value: "no",
          },
        ],
      },
      {
        label: "Did Recidivate",
        value: "",
        type: "select",
        fieldName: "did_recidivate",
        options: [
          {
            label: "No",
            fieldname: "did_recidivate",
            value: "no",
          },
          {
            label: "Yes",
            fieldname: "did_recidivate",
            value: "yes",
          },
        ],
      },
      {
        label: "Recidivism Reason",
        value: "",
        type: "select",
        fieldName: "recidivism_reason",
        options: [
          {
            label: "Unknown",
            fieldname: "recidivism_reason",
            value: "unknown",
          },
          {
            label: "New Charge",
            fieldname: "recidivism_reason",
            value: "new_charge",
          },
          {
            label: "Civil",
            fieldname: "recidivism_reason",
            value: "civil",
          },
        ],
      },
    ],
  ],
];
