import * as React from "react";
import { Moment } from "moment-timezone";
import { Form, Columns, Heading, Level } from "react-bulma-components";
import { Button } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import { EVENT_TIME_INTERVAL } from "../../../shared/lib/modalFormHelpers";
import ModalSelect from "../../modals/ModalSelect";
import ModalInput from "../../modals/ModalInput";
import { ValidationItemType, Operation } from "../../modals/types";
import { SomeOfThePropsTypedFromModalWrapper } from "../../HOCs/ModalWrapperHOC";

import DatePickerWrapper from "../../DatePickerWrapper";
import { BroadcastMessageRecipientDisplay } from "../BroadcastMessagesGql";
import { BroadcastMessageFormData } from "./BroadcastMessageModalWrapper";

import "../../modals/ModalForm.scss";
import { isSoon, getNextButtonText } from "./BroadcastMessageUtil";
import { ScarePrompt } from "../../../shared/components/elements/ScarePrompt";
import { ModalInputTypes } from "../../../shared/lib/graphql/index";

type EventFormValidations = {
  recipient: ValidationItemType;
  message: ValidationItemType;
  scheduledFor: ValidationItemType;
};

export interface BroadcastMessageFormOptions {
  onDelete?: (evt: React.SyntheticEvent<HTMLButtonElement>) => void;
  recipients: Array<BroadcastMessageRecipientDisplay>;
  onCancel: (evt: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

interface BroadcastMessageFormProps
  extends BroadcastMessageFormOptions,
    Pick<
      SomeOfThePropsTypedFromModalWrapper,
      | "handleModalFormInputChange"
      | "handleDatePickerChange"
      | "handleDatePickerRawChange"
      | "handleFormSubmit"
    > {
  addEvent: Function;
  attemptedSubmission: boolean;
  modalFormData: BroadcastMessageFormData;
  validations: EventFormValidations;
  recipients: Array<BroadcastMessageRecipientDisplay>;
  operation: Operation;
  // updateObject: BroadcastMessageObjectType;
}

export class BroadcastMessageForm extends React.PureComponent<
  BroadcastMessageFormProps
> {
  render() {
    const {
      handleModalFormInputChange,
      modalFormData,
      validations,
      attemptedSubmission,
      handleDatePickerChange,
      handleDatePickerRawChange,
      recipients,
      onDelete,
      operation,
    } = this.props;

    const { message, recipient, scheduledFor } = modalFormData;

    const scheduledSoon = isSoon(scheduledFor);

    const nextButtonText = getNextButtonText(scheduledFor, false);

    return (
      <div className="modal-form">
        <Level>
          <Level.Side align="left">
            <Level.Item>
              <Heading className="is-4">
                {operation === Operation.Add ? "Write" : "Edit"} Message
                {scheduledSoon && (
                  <span className="sendingSoon">
                    This message is scheduled to send soon.
                  </span>
                )}
              </Heading>
            </Level.Item>
          </Level.Side>
          <Level.Side align="left">
            <Level.Item>
              {onDelete && (
                <ScarePrompt
                  warningText={`Are you sure you want to delete this message? This cannot be undone.`}
                  itemName="message"
                  button={<Button type="danger">Delete</Button>}
                  onYes={onDelete}
                />
              )}
              <Level.Item />
              <Button onClick={this.props.onCancel}>Cancel</Button>
              <Level.Item />
              <Button type="primary" onClick={this.props.handleFormSubmit}>
                {nextButtonText}
              </Button>
            </Level.Item>
          </Level.Side>
        </Level>

        <Columns className="modal-column">
          <Columns.Column className="first-half" size={5}>
            <Form.Field>
              <ModalInput
                label={message.label}
                name="message"
                value={message.value}
                inputType={ModalInputTypes.Textarea}
                attemptedSubmission={attemptedSubmission}
                handleModalFormInputChange={handleModalFormInputChange}
                textArea
                validations={validations}
              />
            </Form.Field>
          </Columns.Column>
          <Columns.Column size={2}>
            <Form.Field>
              <ModalSelect
                modalStateKey="recipient"
                label={recipient.label}
                validations={validations}
                value={recipient.value}
                name="recipient"
                inputType={ModalInputTypes.Select}
                attemptedSubmission={attemptedSubmission}
                handleModalFormInputChange={handleModalFormInputChange}
                options={recipients}
              />
            </Form.Field>
          </Columns.Column>
          <Columns.Column size={2}>
            <Form.Field>
              <DatePickerWrapper
                selectedDate={scheduledFor}
                name="scheduledFor"
                label={"Send Date"}
                placeholder={"--/--/----"}
                popperPlacement={"top"}
                validations={validations}
                onChange={(date: Moment) => {
                  handleDatePickerChange({
                    value: date,
                    name: "scheduledFor",
                    type: "scheduled-for", // ?
                  });
                }}
                attemptedSubmission={attemptedSubmission}
                onChangeRaw={handleDatePickerRawChange}
                type={ModalInputTypes.StartDate}
              />
            </Form.Field>
          </Columns.Column>
          <Columns.Column size={2}>
            <Form.Field>
              <DatePickerWrapper
                selectedDate={scheduledFor}
                name="scheduledFor"
                label={"Send Time"}
                placeholder={"--:-- --"}
                validations={validations}
                onChange={(date: Moment) => {
                  handleDatePickerChange({
                    value: date,
                    name: "scheduledFor",
                    type: "scheduled-for", // ?
                  });
                }}
                attemptedSubmission={attemptedSubmission}
                onChangeRaw={handleDatePickerRawChange}
                showTimeSelect={true}
                showTimeSelectOnly={true}
                timeIntervals={EVENT_TIME_INTERVAL}
                dateFormat="LT"
                timeCaption="Time"
                popperPlacement="top"
                type={ModalInputTypes.StartTime}
              />
            </Form.Field>
          </Columns.Column>
        </Columns>
      </div>
    );
  }
}
