import { RRule } from "rrule";
import { DO_NOT_REPEAT } from "./recurrenceOptions";

export const rruleDictionary: { [key: string]: any } = {
  [DO_NOT_REPEAT]: "",
  daily: {
    freq: RRule.DAILY,
    interval: 1,
  },
  every_weekday: {
    freq: RRule.WEEKLY,
    interval: 1,
    byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
  },
  every_monday: {
    freq: RRule.WEEKLY,
    interval: 1,
    byweekday: [RRule.MO],
  },
  every_tuesday: {
    freq: RRule.WEEKLY,
    interval: 1,
    byweekday: [RRule.TU],
  },
  every_wednesday: {
    freq: RRule.WEEKLY,
    interval: 1,
    byweekday: [RRule.WE],
  },
  every_thursday: {
    freq: RRule.WEEKLY,
    interval: 1,
    byweekday: [RRule.TH],
  },
  every_friday: {
    freq: RRule.WEEKLY,
    interval: 1,
    byweekday: [RRule.FR],
  },
  every_saturday: {
    freq: RRule.WEEKLY,
    interval: 1,
    byweekday: [RRule.SA],
  },
  every_sunday: {
    freq: RRule.WEEKLY,
    interval: 1,
    byweekday: [RRule.SU],
  },
  tuesday_thursday: {
    freq: RRule.WEEKLY,
    interval: 1,
    byweekday: [RRule.TU, RRule.TH],
  },
  monday_wednesday_friday: {
    freq: RRule.WEEKLY,
    interval: 1,
    byweekday: [RRule.MO, RRule.WE, RRule.FR],
  },
};
