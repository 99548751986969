import * as React from "react";

import { DashboardBucket, CaseManager } from "../../shared/lib/graphql";
import {
  ParticipantListTableContainer,
  NameComponentType,
} from "../../shared/components/participantListTable/index";
import { ParticipantListTableQueryTypes } from "../../shared/components/participantListTable/gql/index";
import { SentryErrorBoundary } from "../../shared/components/error-boundaries/SentryErrorBoundary";
import { AlwaysableTableColumn } from "../../shared/components/participantListTable/ParticipantListTableColumns";
import { ReviewCheck } from "../elements/ReviewCheck";
import { getFeatureFlags } from "../../featureFlags";
import { Group } from "@material-ui/icons";
import "../../css/sectionBucket.scss";
import { getBucketConfig } from "./WorkflowBucketConfigs";

export interface WorkflowBucketProps {
  caseManagerId: string;
  bucket: DashboardBucket;
  nameComponent: NameComponentType;
  onReview: (id: string, reviewed: boolean) => Promise<boolean>;
}

const {
  clientDisplayTerm,
  formatting: { startDateHumanizeCutoff },
  supportsProgramCompliance,
  whatIsComplianceCalled,
  whatIsRiskLevelCalled,
} = getFeatureFlags();

export class WorkflowBucket extends React.PureComponent<WorkflowBucketProps> {
  private ref: React.RefObject<HTMLHeadingElement>;

  private getBaseAdditionalColumns = (
    readOnly: boolean
  ): AlwaysableTableColumn<any>[] => {
    return [
      {
        key: "reviewed_by_case_manager",
        id: "reviewed",
        always: true,
        sortable: false,
        headerStyle: { width: 50 },
        header: "Done",
        // type of this last object an item in our row
        render: (
          reviewedByCaseManager: CaseManager | null,
          _: number,
          { review_id }: any
        ) => {
          return (
            <ReviewCheck
              id={review_id}
              disabled={readOnly}
              caseManager={reviewedByCaseManager}
              onChange={this.onReview}
            />
          );
        },
      },
    ];
  };

  constructor(props: WorkflowBucketProps) {
    super(props);
    this.ref = React.createRef<HTMLHeadingElement>();
  }

  private onReview = async (
    id: string,
    evt: React.SyntheticEvent<HTMLInputElement>
  ): Promise<boolean> => {
    const { checked: reviewed } = evt.currentTarget;
    const result = await this.props.onReview(id, reviewed);
    // on fail, force update triggers the ReviewCheck to re-render with the props that are unset
    if (!result) this.forceUpdate();
    return result;
  };

  componentDidMount() {
    // For clients navigating to this page via an item review email,
    // This hack is used to scroll them to the proper position within the page.
    // Unfortunately, the content for the page takes time to load and the position of section
    // Isn't known until all of the data for the components is properly fetched.
    // Thus, a timeout is used as a hack. Probably would need to use React suspense to handle properly.
    const { bucket } = this.props;
    if (this.ref && location.href.includes(`#${bucket.key}`)) {
      setTimeout(() => {
        if (this.ref.current === null) return;
        this.ref.current.scrollIntoView();
      }, 2000);
    }
  }

  render() {
    const { bucket, caseManagerId, nameComponent } = this.props;

    // for now, supervisor mode is turned on when you select "all" for CM
    const config = getBucketConfig(bucket.key, caseManagerId === "all");
    const {
      additionalColumns: additionalColumnsFn,
      reviewIsReadOnly = false,
    } = config;

    const baseColumns = this.getBaseAdditionalColumns(reviewIsReadOnly);

    const additionalColumns =
      typeof additionalColumnsFn === "function"
        ? baseColumns.concat(additionalColumnsFn())
        : baseColumns;

    return (
      <section className="bucket" id={bucket.key} ref={this.ref}>
        <div className="section-info">
          <Group />
          <div>
            <div className="section-title">{bucket.title}</div>
            <div className="section-blurb">{config.blurb}</div>
          </div>
        </div>
        <SentryErrorBoundary
          ErrorComponent={() => (
            <p>An error occured, please try again later.</p>
          )}
        >
          <div className="table-container">
            <ParticipantListTableContainer
              showSecondaryCaseManager={false}
              key={`bucket-${bucket.key}`}
              tableQuery={ParticipantListTableQueryTypes.ReviewItems}
              caseManagerId={caseManagerId}
              className={`workflow-bucket-table bucket-${bucket.key}`}
              bucket={bucket}
              onLoad={config.dataOnLoad}
              nameComponent={nameComponent}
              columnsToDisplay={config.columns}
              additionalColumns={additionalColumns}
              supportsProgramCompliance={supportsProgramCompliance}
              clientDisplayTerm={clientDisplayTerm}
              whatIsComplianceCalled={whatIsComplianceCalled}
              whatIsRiskLevelCalled={whatIsRiskLevelCalled}
              startDateHumanizeCutoff={startDateHumanizeCutoff}
              columnNameRemaps={config.columnNameRemaps}
            />
          </div>
        </SentryErrorBoundary>
      </section>
    );
  }
}
