import React from "react";
import { Booking, ProspectStatus } from "../../graphql-types";
import { QueryRenderer } from "../../shared/lib/graphql";
import { bookingsGraphqlQuery } from "./ProspectsGraphql";
import idx from "idx.macro";
import { ProspectTable } from "./ProspectTable";
import { ProspectSearch } from "./ProspectSearch";

export interface ProspectBookingGraphqlData {
  bookings: { booking: Booking[] };
}

interface ProspectTableProps {
  prospectStatus: string | undefined;
}

export function ProspectTableQueryContainer({
  prospectStatus = ProspectStatus.New,
}: ProspectTableProps) {
  return (
    <div className="constrained-section activeContentContainer">
      {prospectStatus === "search" ? (
        <ProspectSearch />
      ) : (
        <QueryRenderer
          query={bookingsGraphqlQuery}
          variables={{ prospect_status: prospectStatus }}
          SuccessComponent={(props: ProspectBookingGraphqlData) => {
            const bookings = idx(props, (_) => _.bookings.booking);
            return <ProspectTable bookings={bookings} />;
          }}
        />
      )}
    </div>
  );
}
