import React from "react";
import { safelyUnwrapArrayWithPossibleNullOrUndefined } from "../../utils/safeUnwrap";
import { RangeContainer } from "./RangeContainer";
import { ScoresContainer } from "./ScoresContainer";
import { AssessmentInfoContainer } from "./AssessmentInfoContainer";
import { ParticipantAssessmentResultPlusParticipantName } from "./types";

export function AssessmentForm(
  props: ParticipantAssessmentResultPlusParticipantName
) {
  const { ranges, scores, rating, total_score: totalScore, id } = props;

  const unwrappedRanges = safelyUnwrapArrayWithPossibleNullOrUndefined(ranges);
  const unwrappedScores = safelyUnwrapArrayWithPossibleNullOrUndefined(scores);
  const sortedScores = unwrappedScores.sort((a, b) => {
    const { question_number: firstQuestion } = a;
    const { question_number: secondQuestion } = b;

    return firstQuestion - secondQuestion;
  });
  return (
    <div id={id}>
      <AssessmentInfoContainer {...props} />
      <ScoresContainer
        scores={sortedScores}
        rating={rating}
        totalScore={totalScore}
        ranges={unwrappedRanges}
        assessmentResultId={id}
      />
      <p style={{ pageBreakAfter: "always" }} />
      <RangeContainer ranges={unwrappedRanges} />
    </div>
  );
}
