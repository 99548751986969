import React from "react";
import DatePicker from "react-datepicker";
import { Form } from "react-bulma-components";
import { Moment } from "moment";
import { ValidationItemStates } from "./modals/types";
import { FormLabelWithToolTip } from "../shared/components/elements/FormLabelWithToolTip";

interface FormDataFieldMoment {
  label: string;
  value: Moment;
}

interface FormDataFieldNull {
  label: string;
  value: null;
}

interface Props {
  selectedDate: FormDataFieldMoment | FormDataFieldNull;
  name: string;
  label: string;
  validations: any;
  onChange: (date: Moment, event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeRaw: Function;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  timeIntervals?: number;
  dateFormat?: string;
  timeCaption?: string;
  minTime?: Moment;
  maxTime?: Moment;
  isClearable?: boolean;
  popperPlacement?: string;
  minDate?: Moment;
  type: string;
  placeholder?: string;
  inline?: boolean;
  attemptedSubmission?: boolean;
  toolTipText?: string;
}

const DatePickerWrapper = ({
  selectedDate,
  name,
  label,
  validations,
  onChange,
  onChangeRaw,
  showTimeSelect,
  showTimeSelectOnly,
  timeIntervals,
  dateFormat,
  timeCaption,
  minTime,
  maxTime,
  isClearable,
  popperPlacement,
  minDate,
  type,
  placeholder,
  toolTipText,
  inline = false,
  attemptedSubmission = false,
}: Props) => {
  return (
    <Form.Field>
      <FormLabelWithToolTip labelText={label} tipText={toolTipText} />
      <Form.Control>
        <DatePicker
          inline={inline}
          className="react-datepicker-input"
          selected={selectedDate && selectedDate.value}
          onChange={onChange}
          onChangeRaw={(event: React.ChangeEvent<HTMLInputElement>) => {
            onChangeRaw({
              value: event.target.value,
              name,
              type,
              selectedDate: selectedDate && selectedDate.value,
            });
          }}
          placeholderText={placeholder || ""}
          showTimeSelect={showTimeSelect}
          showTimeSelectOnly={showTimeSelectOnly}
          timeIntervals={timeIntervals}
          dateFormat={dateFormat}
          timeCaption={timeCaption}
          minTime={minTime}
          maxTime={maxTime}
          isClearable={isClearable}
          popperPlacement={popperPlacement}
          minDate={minDate}
        />
        {validations && validations[name].errors.length > 0 ? (
          <Form.Help color="danger">{validations[name].errors[0]}</Form.Help>
        ) : null}
        {validations &&
        validations[name].state === ValidationItemStates.pristine &&
        attemptedSubmission ? (
          <Form.Help color="danger">{"Field is required"}</Form.Help>
        ) : null}
      </Form.Control>
    </Form.Field>
  );
};

export default DatePickerWrapper;
