import React from "react";
import idx from "idx.macro";

import {
  addParticipant,
  updateParticipant,
} from "../../../actions/participants";
import { CarePlanParticipantType } from "../../care-plan/CarePlanQuery";
import AddParticipantForm from "./AddParticipantForm";
import AddParticipantModalWrapper, {
  ParticipantModalFormData,
} from "./AddParticipantModalWrapper";
import { ModalPropsPassThrough } from "../ModalWrapper";
import {
  AddParticipantGqlArguments,
  UpdateParticipantGqlArguments,
} from "../../../actions/participants";
import { ParticipantDetailsType } from "../../screens/ParticipantDetailsQueryTypes";
import { ParticipantContextType } from "../../../utils/context";
import { getUpdateParticipantBaseVariables } from "../../participant-profile/ParticipantProfileContainer";

export interface AddParticipantModalPropsExternal {
  callback?: (participant: CarePlanParticipantType) => void;
  participant?: ParticipantDetailsType;
  metaDataObject?: ParticipantContextType;
}

export type AddParticipantModalProps = ModalPropsPassThrough<
  ParticipantModalFormData
> &
  AddParticipantModalPropsExternal;

type NewParticipant = {
  addParticipant: {
    participant: CarePlanParticipantType;
  };
};

type UpdatedParticipant = {
  updateParticipant: {
    participant: CarePlanParticipantType;
  };
};

const addParticipantArgumentDictionary: { [key: string]: string } = {
  assessmentInstrument: "assessment_tool",
  assessmentRiskLevel: "risk_level",
  assessmentScore: "assessment_score",
  caseManager: "case_manager_id",
  firstName: "first",
  lastName: "last",
  mobileNumber: "mobile",
  language: "language",
  preferredName: "preferred_name",
  probation_number: "probation_number",
  compliance: "compliance",
  startDate: "supervision_begin_date",
  endDate: "supervision_end_date",
  probationNumber: "probation_number",
};

export function getGraphQLVariablesFromFormData(
  formData: ParticipantModalFormData
) {
  return Object.keys(formData).reduce((acc, key) => {
    const newItem = {
      [addParticipantArgumentDictionary[key]]: formData[key].value,
    };
    acc = {
      ...acc,
      ...newItem,
    };

    return acc;
  }, {});
}

class AddParticipantModal extends React.Component<AddParticipantModalProps> {
  private addParticipantCallback = async (
    formData: ParticipantModalFormData
  ) => {
    const data = getGraphQLVariablesFromFormData(formData);

    //sorry TypeScript, simpler to just force cast it!
    const variables = {
      ...data,
    } as AddParticipantGqlArguments;

    //TODO: Implement more robust system for handling error/loading states within mutations called from React components
    const newParticipant = (await addParticipant(variables)) as NewParticipant;
    const participant = idx(
      newParticipant,
      (_) => _.addParticipant.participant
    );
    const participantId = (participant || {}).id;

    if (!participant || !participantId) {
      console.error(
        "Callback after adding a participant, but no participantId found. \n Sent:",
        { ...variables, mobile: "redacted" }
      );
    }
    const { callback } = this.props;
    if (callback) {
      callback(participant);
    }
  };

  private updateParticipantCallback = async (
    formData: ParticipantModalFormData
  ) => {
    const { participant } = this.props;

    if (!participant) {
      return;
    }

    const { id } = participant as ParticipantDetailsType;

    const data = getGraphQLVariablesFromFormData(formData);

    //sorry TypeScript, simpler to just force cast it!
    const variables = {
      ...getUpdateParticipantBaseVariables(participant),
      ...data,
      id,
    } as UpdateParticipantGqlArguments;

    //TODO: Implement more robust system for handling error/loading states within mutations called from React components
    const updatedParticipants = (await updateParticipant(
      variables,
      participant
    )) as UpdatedParticipant;
    const updatedParticipant = idx(
      updatedParticipants,
      (_) => _.updateParticipant.participant
    );
    const updatedParticipantId = (updatedParticipant || {}).id;

    if (!updatedParticipant || !updatedParticipantId) {
      console.error(
        "Callback after updating a participant, but no updatedParticipantId found. \n Sent:",
        { ...variables, mobile: "redacted" }
      );
    }
    const { callback } = this.props;
    if (callback) {
      callback(updatedParticipant);
    }
  };

  render() {
    const { operation, type, participant, metaDataObject } = this.props;
    return (
      <AddParticipantModalWrapper
        callBackForAPI={
          participant && participant.id
            ? this.updateParticipantCallback
            : this.addParticipantCallback
        }
        type={type}
        operation={operation}
        updateObject={participant}
        metaDataObject={metaDataObject}
      >
        {(data: any) => <AddParticipantForm {...data} />}
      </AddParticipantModalWrapper>
    );
  }
}

export default AddParticipantModal;
