import React from "react";
import { QueryRenderer } from "../../shared/lib/graphql";
import { ParticipantDetailsQuery } from "../screens/ParticipantDetailsQueryTypes";
import { CarePlanContainerProps } from "../care-plan/CarePlanContainer";
import { Participant } from "../../graphql-types";
import { ParticipantVictimsForm } from "./ParticipantVictimsForm";
import { ProtectiveOrderForm } from "./ProtectiveOrderForm";
import idx from "idx.macro";

export function ParticipantVictimsContainer(props: CarePlanContainerProps) {
  const { id } = props;

  return (
    <QueryRenderer
      query={ParticipantDetailsQuery}
      variables={{ id }}
      SuccessComponent={(props: {
        participants: { participant: Participant[] };
      }) => {
        const participant = idx(
          props,
          (_) => _.participants.participant[0]
        ) as Participant;

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ProtectiveOrderForm participant={participant} />
            <ParticipantVictimsForm participant={participant} />
          </div>
        );
      }}
    />
  );
}
