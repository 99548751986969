import React from "react";
import { Columns } from "react-bulma-components";
import moment from "moment";
import { ParticipantAssessmentResultPlusParticipantName } from "./types";

export function AssessmentInfoContainer(
  props: ParticipantAssessmentResultPlusParticipantName
) {
  const {
    scored_by: scoredBy,
    case_number: caseNumber,
    assessment_name: assessmentName,
    participantFullName,
    updated_at: updatedAt,
  } = props;

  return (
    <>
      <Columns className="assessment-info-container-first">
        <Columns.Column size={12}>
          <p className="assessment-title">{assessmentName}</p>
        </Columns.Column>
      </Columns>
      <Columns className="assessment-info-container">
        <Columns.Column size={1} />
        <Columns.Column size={5}>
          <p>
            <strong>Name:</strong>
            {` ${participantFullName}`}
          </p>
          <p>
            <strong>Case Number:</strong>
            {` ${caseNumber}`}
          </p>
        </Columns.Column>
        <Columns.Column size={5}>
          <p>
            <strong>Date of Assessment:</strong>
            {` ${
              updatedAt
                ? moment(updatedAt).format("MM/DD/YYYY")
                : moment().format("MM/DD/YYYY")
            }`}
          </p>
          <p>
            <strong>Name of Assessor: </strong>
            {`${scoredBy}`}
          </p>
        </Columns.Column>
        <Columns.Column size={1} />
      </Columns>
    </>
  );
}
