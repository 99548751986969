import { GraphQLTaggedNode } from "react-relay";

import { TableData } from "../../table/tableTypes";
import { ParticipantItem } from "../ParticipantListTypes";

// export * really just for the result types
export * from "./ParticipantListTableGql";
export * from "./ReviewItemsTableGql";

import {
  ParticipantListTableGraphqlWithEvents,
  ParticipantListTableGraphqlWithEventsSynchronous,
  ParticipantListTableGraphqlWithEventsIsValid,
  ParticipantListTableGraphqlWithEventsDataToItems,
} from "./ParticipantListTableGql";
import {
  ReviewItemsTableGqlValidArgs,
  ReviewItemsTableGql,
  ReviewItemsTableDataToItems,
} from "./ReviewItemsTableGql";

export enum ParticipantListTableQueryTypes {
  ParticipantList = "ParticipantList",
  ParticipantListSortedByEvent = "ParticipantListSortedByEvent",
  ReviewItems = "ReviewItems",
}

export interface ParticipantListTableQueryDef {
  key: ParticipantListTableQueryTypes;
  isValid: (args: any) => boolean;
  query: GraphQLTaggedNode;
  dataToItems: (data: any) => TableData<ParticipantItem>;
}

export const ParticipantListTableQueries: {
  [key: string]: ParticipantListTableQueryDef;
} = {
  [ParticipantListTableQueryTypes.ParticipantList]: {
    key: ParticipantListTableQueryTypes.ParticipantList,
    isValid: ParticipantListTableGraphqlWithEventsIsValid,
    query: ParticipantListTableGraphqlWithEvents,
    dataToItems: ParticipantListTableGraphqlWithEventsDataToItems,
  },
  [ParticipantListTableQueryTypes.ParticipantListSortedByEvent]: {
    key: ParticipantListTableQueryTypes.ParticipantList,
    isValid: ParticipantListTableGraphqlWithEventsIsValid,
    query: ParticipantListTableGraphqlWithEventsSynchronous,
    dataToItems: ParticipantListTableGraphqlWithEventsDataToItems,
  },
  [ParticipantListTableQueryTypes.ReviewItems]: {
    key: ParticipantListTableQueryTypes.ReviewItems,
    isValid: ReviewItemsTableGqlValidArgs,
    query: ReviewItemsTableGql,
    dataToItems: ReviewItemsTableDataToItems,
  },
};
