import * as React from "react";
import moment, { Moment } from "moment";
import { DatePicker } from "antd";

import { UpdateObligationGqlArguments } from "../../actions/obligations";
import { CarePlanObligationType } from "./CarePlanQuery";

const dateFormatList = ["MM/DD/YYYY"];

interface ObligationDatePickerProps {
  start: string;
  end: string;
  mutationCallback: (
    variables: UpdateObligationGqlArguments,
    obligation: CarePlanObligationType
  ) => void;
  obligation_id: string;
  isReadOnly?: boolean;
  obligation: CarePlanObligationType;
}

class ObligationDatePicker extends React.Component<ObligationDatePickerProps> {
  _handleEndMutation = (mutationPayload: UpdateObligationGqlArguments) => {
    const { mutationCallback, obligation } = this.props;
    mutationCallback(mutationPayload, obligation);
  };

  _handleStartMutation = (mutationPayload: UpdateObligationGqlArguments) => {
    const { mutationCallback, obligation } = this.props;
    mutationCallback(mutationPayload, obligation);
  };

  handleStartDateChange = (start: Moment | null) => {
    if (start && !start.isValid()) {
      return null;
    }

    const { end } = this.props;
    const { obligation_id } = this.props;
    const payload: UpdateObligationGqlArguments = {
      obligation_id,
    };
    payload.start = start ? start.toISOString() : "";

    // first case, either the start or the end case is null.
    // no additional start vs end date validation needed.
    // proceed to change state and run the mutation
    if (end === null || start === null) {
      this._handleStartMutation(payload);
      return;
    }

    // normal case, the selected start date is same or before end date
    // just set the start and proceed with the mutation
    if (start.isSameOrBefore(end)) {
      this._handleStartMutation(payload);
      return;
    }

    // edge case, we have a start and end, but the user set the start
    // date to after the end date, fix by forcing end to be start

    payload.end = start.toISOString();

    this._handleStartMutation(payload);
    return;
  };

  handleEndDateChange = (end: Moment | null) => {
    const { start } = this.props;
    const { obligation_id } = this.props;

    if (end && !end.isValid()) {
      return null;
    }

    const payload: UpdateObligationGqlArguments = {
      obligation_id,
    };

    payload.end = end ? end.toISOString() : "";

    // first case, either the start or the end date is null.
    // no additional start vs end date validation needed.
    // proceed to change state and run the mutation
    if (end === null || start === null) {
      this._handleEndMutation(payload);
      return;
    }

    // normal case, the selected end date is same or after end date
    // just set the start and proceed with the mutation
    if (end.isSameOrAfter(start)) {
      this._handleEndMutation(payload);
      return;
    }

    // edge case, we have a start and end, but the user set the end
    // date to before the start date, fix by forcing start to be end

    payload.start = end.toISOString();

    this._handleEndMutation(payload);
    return;
  };

  render() {
    const { start, end } = this.props;

    return (
      <div className="level" onClick={(e) => e.stopPropagation()}>
        <DatePicker
          format={dateFormatList}
          value={moment(start).isValid() ? moment(start) : undefined}
          onChange={this.handleStartDateChange}
          disabled={this.props.isReadOnly}
        />
        {"-"}
        <DatePicker
          format={dateFormatList}
          value={moment(end).isValid() ? moment(end) : undefined}
          onChange={this.handleEndDateChange}
          disabled={this.props.isReadOnly}
        />
      </div>
    );
  }
}

export default ObligationDatePicker;
