import React, { Component } from "react";
import { Columns } from "react-bulma-components";
import { GoalTaskList } from "./GoalTaskList";
import { QueryRenderer } from "../../shared/lib/graphql";
import { GoalTaskQuery, GoalsTasksQueryResult } from "./GoalTaskQuery";
import { addGoal, removeGoal, updateGoal } from "../../actions/goals";
import { addTask, removeTask, updateTask } from "../../actions/tasks";
import idx from "idx.macro";
import "../care-plan/CarePlan.scss";
import "../care-plan/CarePlanSection.scss";
import "./GoalsTasks.scss";
import { getFeatureFlags } from "../../featureFlags";

export interface GoalsTasksContainerProps {
  id: string;
}

export class ParticipantGoalsTasksContainer extends Component<
  GoalsTasksContainerProps
> {
  render() {
    const { id } = this.props;

    return (
      <QueryRenderer
        query={GoalTaskQuery}
        variables={{ id }}
        SuccessComponent={(props: GoalsTasksQueryResult) => {
          const goals = idx(props, (_) => _.participants.participant[0].goals);
          const tasks = idx(props, (_) => _.participants.participant[0].tasks);
          const taskConfig = idx(props, (_) => _.application.task_config) || [];
          const taskTypes = taskConfig.map(({ key, label: value }) => ({
            key,
            value,
          }));

          const {
            whatIsGoalCalledPlural = "Goals",
          } = getFeatureFlags().goalsTasks;

          const {
            whatIsGoalCalledSingular = "Goal",
          } = getFeatureFlags().goalsTasks;

          const {
            whatIsTaskCalledPlural = "Tasks",
          } = getFeatureFlags().goalsTasks;

          const {
            whatIsTaskCalledSingular = "Task",
          } = getFeatureFlags().goalsTasks;

          return (
            <Columns>
              <Columns.Column size={6} style={{ paddingRight: 40 }}>
                <GoalTaskList
                  titlePlural={whatIsGoalCalledPlural}
                  titleSingular={whatIsGoalCalledSingular}
                  listItems={goals}
                  addMutation={addGoal}
                  removeMutation={removeGoal}
                  participantId={id}
                  updateMutation={updateGoal}
                  type="goal"
                />
              </Columns.Column>
              <Columns.Column size={6}>
                <GoalTaskList
                  titlePlural={whatIsTaskCalledPlural}
                  titleSingular={whatIsTaskCalledSingular}
                  listItems={tasks}
                  addMutation={addTask}
                  removeMutation={removeTask}
                  taskTypes={taskTypes}
                  updateMutation={updateTask}
                  participantId={id}
                  type="task"
                />
              </Columns.Column>
            </Columns>
          );
        }}
      />
    );
  }
}
