/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type CadenceOptions = "monthly" | "weekly" | "%future added value";
export type DataInputField = "aa_meeting" | "address" | "employment" | "law_enforcement_contact" | "%future added value";
export type DataInputType = "confirmation" | "verification" | "%future added value";
export type MutationResult = "failure" | "skipped" | "success" | "%future added value";
export type VerificationOptions = "update_or_request" | "with_confirmation" | "%future added value";
export type CheckInData = {
    readonly cadence?: CadenceOptions | null;
    readonly dayOfWeek?: number | null;
    readonly dayOfMonth?: number | null;
    readonly verificationType?: VerificationOptions | null;
};
export type obligationsEditCheckInObligationCadenceSettingsMutationVariables = {
    readonly obligation_id: string;
    readonly check_in_data: CheckInData;
};
export type obligationsEditCheckInObligationCadenceSettingsMutationResponse = {
    readonly editCheckInObligationCadenceSettings: ({
        readonly result: MutationResult;
        readonly description: string | null;
        readonly obligation: ({
            readonly id: string | null;
            readonly title: ({
                readonly en: string | null;
            }) | null;
            readonly activity: ({
                readonly id: string;
                readonly type: string;
                readonly data_input_type: DataInputType | null;
                readonly data_input_field: DataInputField | null;
            }) | null;
            readonly service_provider: ({
                readonly id: string | null;
                readonly title: string | null;
            }) | null;
            readonly sub_address_for_event: string | null;
            readonly status: string | null;
            readonly compliance: string | null;
            readonly start: string | null;
            readonly end: string | null;
            readonly tag: string | null;
            readonly is_writable: boolean | null;
            readonly cadence_option: CadenceOptions | null;
            readonly day_of_week: number | null;
            readonly day_of_month: number | null;
            readonly verification_option: VerificationOptions | null;
            readonly event_templates: ReadonlyArray<({
                readonly id: string | null;
                readonly rrule: string | null;
                readonly exdate: string | null;
                readonly events: ReadonlyArray<({
                    readonly id: string | null;
                    readonly date: string | null;
                    readonly event_template: ({
                        readonly id: string | null;
                        readonly rrule: string | null;
                    }) | null;
                    readonly activity: {
                        readonly id: string;
                        readonly title: {
                            readonly en: string | null;
                        };
                        readonly type: string;
                    };
                    readonly required: boolean | null;
                    readonly service_provider: {
                        readonly id: string | null;
                        readonly address: string | null;
                        readonly phone: string | null;
                        readonly sub_address_for_event: string | null;
                        readonly title: string | null;
                    };
                    readonly attended: string | null;
                    readonly disposition: string | null;
                }) | null> | null;
            }) | null> | null;
        }) | null;
        readonly errors: ReadonlyArray<string | null> | null;
    }) | null;
};
export type obligationsEditCheckInObligationCadenceSettingsMutation = {
    readonly response: obligationsEditCheckInObligationCadenceSettingsMutationResponse;
    readonly variables: obligationsEditCheckInObligationCadenceSettingsMutationVariables;
};



/*
mutation obligationsEditCheckInObligationCadenceSettingsMutation(
  $obligation_id: String!
  $check_in_data: CheckInData!
) {
  editCheckInObligationCadenceSettings(obligation_id: $obligation_id, check_in_data: $check_in_data) {
    result
    description
    obligation {
      id
      title {
        en
      }
      activity {
        id
        type
        data_input_type
        data_input_field
      }
      service_provider {
        id
        title
      }
      sub_address_for_event
      status
      compliance
      start
      end
      tag
      is_writable
      cadence_option
      day_of_week
      day_of_month
      verification_option
      event_templates {
        id
        rrule
        exdate
        events {
          id
          date
          event_template {
            id
            rrule
          }
          activity {
            id
            title {
              en
            }
            type
          }
          required
          service_provider {
            id
            address
            phone
            sub_address_for_event
            title
          }
          attended
          disposition
        }
      }
    }
    errors
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "obligation_id",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "check_in_data",
            "type": "CheckInData!",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "title",
        "storageKey": null,
        "args": null,
        "concreteType": "i18nText",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "en",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "type",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "title",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "sub_address_for_event",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "rrule",
        "args": null,
        "storageKey": null
    } as any), v7 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "editCheckInObligationCadenceSettings",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "check_in_data",
                    "variableName": "check_in_data"
                },
                {
                    "kind": "Variable",
                    "name": "obligation_id",
                    "variableName": "obligation_id"
                }
            ],
            "concreteType": "NewObligation",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "result",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "obligation",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Obligation",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "activity",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Activity",
                            "plural": false,
                            "selections": [
                                (v1 /*: any*/),
                                (v3 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "data_input_type",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "data_input_field",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "service_provider",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ServiceProvider",
                            "plural": false,
                            "selections": [
                                (v1 /*: any*/),
                                (v4 /*: any*/)
                            ]
                        },
                        (v5 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "status",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "compliance",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "start",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "end",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "tag",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "is_writable",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "cadence_option",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "day_of_week",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "day_of_month",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "verification_option",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "event_templates",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "EventTemplate",
                            "plural": true,
                            "selections": [
                                (v1 /*: any*/),
                                (v6 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "exdate",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "events",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Event",
                                    "plural": true,
                                    "selections": [
                                        (v1 /*: any*/),
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "date",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "event_template",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "EventTemplate",
                                            "plural": false,
                                            "selections": [
                                                (v1 /*: any*/),
                                                (v6 /*: any*/)
                                            ]
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "activity",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "Activity",
                                            "plural": false,
                                            "selections": [
                                                (v1 /*: any*/),
                                                (v2 /*: any*/),
                                                (v3 /*: any*/)
                                            ]
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "required",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "service_provider",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "ServiceProvider",
                                            "plural": false,
                                            "selections": [
                                                (v1 /*: any*/),
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "address",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "phone",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                (v5 /*: any*/),
                                                (v4 /*: any*/)
                                            ]
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "attended",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "disposition",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "errors",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "obligationsEditCheckInObligationCadenceSettingsMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v7 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "obligationsEditCheckInObligationCadenceSettingsMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v7 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "obligationsEditCheckInObligationCadenceSettingsMutation",
            "id": null,
            "text": "mutation obligationsEditCheckInObligationCadenceSettingsMutation(\n  $obligation_id: String!\n  $check_in_data: CheckInData!\n) {\n  editCheckInObligationCadenceSettings(obligation_id: $obligation_id, check_in_data: $check_in_data) {\n    result\n    description\n    obligation {\n      id\n      title {\n        en\n      }\n      activity {\n        id\n        type\n        data_input_type\n        data_input_field\n      }\n      service_provider {\n        id\n        title\n      }\n      sub_address_for_event\n      status\n      compliance\n      start\n      end\n      tag\n      is_writable\n      cadence_option\n      day_of_week\n      day_of_month\n      verification_option\n      event_templates {\n        id\n        rrule\n        exdate\n        events {\n          id\n          date\n          event_template {\n            id\n            rrule\n          }\n          activity {\n            id\n            title {\n              en\n            }\n            type\n          }\n          required\n          service_provider {\n            id\n            address\n            phone\n            sub_address_for_event\n            title\n          }\n          attended\n          disposition\n        }\n      }\n    }\n    errors\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'dd7de7041858ecc1512c89eb52912686';
export default node;
