import React, { useState } from "react";
import {
  BookingGroup,
  Booking,
  BookingClassification,
  YesNo,
} from "../../graphql-types";
import { customPanelStyle } from "../care-plan/CarePlan";
import { Row, Col, Table, Collapse, Typography, Button } from "antd";
import {
  orTransmittalColumns,
  TransmittalCreation,
} from "./TransmittalCreation";
import { emailTransmittal } from "../../actions/email_transmittal";
import moment from "moment";
import { Tooltip } from "antd";
import { InfoOutlined } from "@material-ui/icons";
import { ROOT_PATH } from "../../AppInfo";
import { getRowClassName } from "./TransmittalCreation";
import "./Bookings.scss";

const { Panel } = Collapse;
const { Text, Title } = Typography;

export function BookingGroupContainer(props: {
  bookingGroup: BookingGroup;
  isToday: boolean;
}) {
  const { bookingGroup, isToday } = props;
  const [isEditShown, setIsEditShown] = useState(false);

  const inteligibleBookings = bookingGroup.ineligible_bookings
    ? bookingGroup.ineligible_bookings
    : [];

  const allBookings = [...bookingGroup.bookings, ...inteligibleBookings];

  const bookingsSorted = allBookings.sort((a, b) => {
    if (a.booking_info.booking_datetime && b.booking_info.booking_datetime) {
      const first = moment(a.booking_info.booking_datetime).toDate();
      const second = moment(b.booking_info.booking_datetime).toDate();

      return second.getTime() - first.getTime();
    }
    return 1;
  });

  return (
    <div>
      <Collapse
        key={bookingGroup.id}
        defaultActiveKey={isToday ? bookingGroup.id : "1"}
      >
        <Panel
          key={bookingGroup.id}
          header={
            <BookingGroupPanelHeader
              isToday={isToday}
              bookingGroup={bookingGroup}
              isEditShown={isEditShown}
              setIsEditShown={setIsEditShown}
            />
          }
          style={{ ...customPanelStyle }}
        >
          {isEditShown === false ? (
            <BookingGroupPanelExpanded bookingGroup={bookingGroup} />
          ) : (
            <TransmittalCreation
              bookings={bookingsSorted}
              bookingGroupId={bookingGroup.id}
            />
          )}
        </Panel>
      </Collapse>
    </div>
  );
}

function BookingGroupPanelHeader({
  bookingGroup,
  isEditShown,
  setIsEditShown,
  isToday,
}: {
  bookingGroup: BookingGroup;
  isEditShown: boolean;
  setIsEditShown: React.Dispatch<React.SetStateAction<boolean>>;
  isToday: boolean;
}) {
  const { email_sent: emailSent } = bookingGroup;
  const date = moment(bookingGroup.created_at).format("dddd, MMMM DD, YYYY");

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Text>{`OR Report: ${date}`}</Text>
      <Row onClick={(event) => event.stopPropagation()}>
        <Button>
          <a
            href={`/${ROOT_PATH}/print_transmittal/${bookingGroup.id}`}
            target="_blank"
          >
            Print
          </a>
        </Button>

        <Button
          style={{ marginLeft: "5px" }}
          onClick={() =>
            emailTransmittal({ booking_group_id: bookingGroup.id })
          }
        >
          {emailSent ? "Resend email" : "Email"}
        </Button>

        {isToday && (
          <Button
            type="primary"
            style={{ marginLeft: "5px" }}
            onClick={() => {
              setIsEditShown(!isEditShown);
            }}
          >
            {isEditShown ? "Cancel Editing" : "Edit"}
          </Button>
        )}
      </Row>
    </div>
  );
}

export function BookingGroupPanelExpanded({
  bookingGroup,
}: {
  bookingGroup: BookingGroup;
}) {
  const { bookings } = bookingGroup;

  const date = moment(bookingGroup.created_at).format("dddd, MMMM DD, YYYY");
  const title = `OR TRANSMITTAL SHEET FOR ${date}`;

  const bookingsMapFunction = (booking: Booking) => {
    return {
      booking_number:
        booking.has_wait === YesNo.Yes ? (
          <>
            <Col>
              <Row justify="center">
                <Text>{booking.id}</Text>
              </Row>
            </Col>
            <Col>
              <Text strong>WAIT</Text>
            </Col>
          </>
        ) : (
          <Col>
            <Row justify="center">
              <Text>{booking.id}</Text>
            </Row>
          </Col>
        ),
      name: `${booking.booking_defendent.name.last}, ${booking.booking_defendent.name.first} `,
      room_time: booking.booking_status ? (
        <>
          <Col>
            {moment(booking.booking_status.court_appearance_datetime).format(
              "MM/DD/YYYY"
            )}
          </Col>
          <Col>
            {moment(booking.booking_status.court_appearance_datetime).format(
              "h:mm a"
            )}
          </Col>

          <Col>{`DEPT ${booking.booking_status.court_room || "N/A"}`}</Col>
        </>
      ) : null,
      date_of_birth: moment(booking.booking_defendent.date_of_birth).format(
        "MM/DD/YYYY"
      ),
      pc:
        booking.booking_charges && booking.booking_charges.length > 0
          ? booking.booking_charges[0].charge_type
          : "",
      charges: booking.booking_charges
        ? booking.booking_charges.map((bookingCharge) => {
            return (
              <>
                {bookingCharge.charge_description ? (
                  <Tooltip title={bookingCharge.charge_description}>
                    <Text style={{ marginLeft: "5px" }}>
                      {bookingCharge.charge_code_section}
                    </Text>
                    <InfoOutlined
                      style={{
                        fontSize: 14,
                        top: 2,
                        position: "relative",
                        left: 3,
                      }}
                    />
                  </Tooltip>
                ) : (
                  <Text style={{ marginLeft: "5px" }}>
                    {bookingCharge.charge_code_section}
                  </Text>
                )}
              </>
            );
          })
        : null,
      comments: booking.comments,
    };
  };

  const classificationFilterFunction = (
    booking: Booking,
    classification: string
  ) => {
    return booking.classification === classification;
  };

  const newReferrals = bookings
    .filter((booking) =>
      classificationFilterFunction(booking, BookingClassification.Referral)
    )
    .map(bookingsMapFunction);

  const newArrests = bookings
    .filter((booking) =>
      classificationFilterFunction(booking, BookingClassification.NewArrest)
    )
    .map(bookingsMapFunction);

  const conditional = bookings
    .filter((booking) =>
      classificationFilterFunction(booking, BookingClassification.Conditional)
    )
    .map(bookingsMapFunction);

  const supervised = bookings
    .filter((booking) =>
      classificationFilterFunction(booking, BookingClassification.Supervised)
    )
    .map(bookingsMapFunction);

  const orBookings = [
    { name: <Text strong>NEW REFERRALS</Text> },
    ...newReferrals,
    { name: <Text strong>NEW ARRESTS</Text> },
    ...newArrests,
    { name: <Text strong>VOP CONDITIONAL</Text> },
    ...conditional,
    { name: <Text strong>VOP SUPERVISED</Text> },
    ...supervised,
  ];

  return (
    <div>
      <Table
        rowKey={(_record: any, index: number) => {
          return index.toString();
        }}
        pagination={false}
        rowClassName={getRowClassName}
        columns={orTransmittalColumns}
        dataSource={orBookings}
        title={() => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Title level={4}>{title}</Title>{" "}
          </div>
        )}
      />
    </div>
  );
}
