import React, { useState } from "react";
import { CSVDownload } from "react-csv";
import moment from "moment";
import { Button } from "antd";
import fetcher from "../../shared/lib/fetcher/fetcher";
import sniffProject from "../../shared/lib/sniffProject";
import { message } from "antd";

export function CheckInReport() {
  const [clientData, setClientData] = useState<any>(null);

  const handleReportButtonPressed = async () => {
    const hide = message.loading("Preparing your report..", 0);
    setClientData([]);
    const projectId = sniffProject();

    const data = await fetcher(
      `https://us-central1-${projectId}.cloudfunctions.net/firestoreRestApiServer/check_in_report`
    );

    if (!data) {
      setTimeout(hide, 1000);
      return;
    }

    setClientData(data);
    setTimeout(hide, 1000);
  };

  return (
    <Button type="primary" onClick={handleReportButtonPressed}>
      Download Check In Report
      {clientData && clientData.length > 0 && (
        <CSVDownload
          data={clientData}
          filename={`Client Check in Report - ${moment().format(
            "MM/DD/YYYY"
          )}.csv`}
          target="_blank"
        />
      )}
    </Button>
  );
}
