import * as React from "react";
import SortableTable from "../../shared/components/table/SortableTable";

import "./defaultAnalyticsTable.scss";
import {
  AnalyticsNotificationItem,
  AnalyticsEventItem,
} from "./analyticsTypes";
import { TableColumn } from "../../shared/components/table/tableTypes";

type BreakdownItem = AnalyticsEventItem | AnalyticsNotificationItem;

interface Props {
  columns: Array<TableColumn<BreakdownItem>>;
  data: Array<BreakdownItem>;
  note?: string;
}

export default class BreakdownTable extends React.Component<Props> {
  render() {
    return (
      <div className="defaultAnalyticsTable">
        <SortableTable
          columns={this.props.columns}
          className="breakdownTable"
          data={this.props.data}
        />
        {this.props.data.length === 0 ? <div>No data available</div> : null}
        {this.props.data.length > 0 && this.props.note ? (
          <p className="table-note-p">{this.props.note}</p>
        ) : null}
      </div>
    );
  }

  _renderEmpty() {
    return <div>No data available</div>;
  }
}
