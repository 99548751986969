import * as React from "react";
import { debounce } from "lodash";
import { Form } from "react-bulma-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { SearchResultsBox } from "./SearchResultsBox";
import { getFeatureFlags } from "../../featureFlags";
import "./ParticipantSearchInput.scss";

interface ParticipantSearchInputProps {}

interface ParticipantSearchInputState {
  searchInputValue: string;
  searchText: string;
  showAutoComplete: boolean;
  selectedValue: number | null;
  redirect: boolean;
}

export class ParticipantSearchInput extends React.PureComponent<
  ParticipantSearchInputProps,
  ParticipantSearchInputState
> {
  private wrapperRef = React.createRef<HTMLDivElement>();
  constructor(props: ParticipantSearchInputProps) {
    super(props);
    this.state = {
      searchInputValue: "",
      searchText: "",
      showAutoComplete: false,
      selectedValue: null,
      redirect: false,
    };

    // when searchText is updated, it will trigger
    // an API call (passed down to a QueryRenderer component)
    // debounce the searchTextChange so as not to bang on the API during typing
    this._handleSearchTextChange = debounce(this._handleSearchTextChange, 500);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this._handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this._handleClickOutside);
  }

  setWrapperRef = (node: any) => {
    this.wrapperRef = node;
  };

  _handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: searchInputValue } = event.target;
    this.setState({ searchInputValue });

    // this is in a separate method because it is debounced in the constructor.
    // basically want the searchInputValue to update on keystroke
    // but want debouncing on the variable that is passed down to
    // SearchResultsBox and triggers an API call (searchText)
    this._handleSearchTextChange(searchInputValue);
  };

  _handleSearchTextChange = (searchText: string) => {
    this.setState({ searchText });
  };

  _handleClickOutside = (event: any) => {
    const node = this.wrapperRef.current as HTMLDivElement;
    if (node && node.contains(event.target)) {
      this.setState({ showAutoComplete: true });
      return;
    }
    this.setState({ showAutoComplete: false });
  };

  _handleNameKeyDown = (event: any) => {
    // handles up arrow, down arrow, and enter key events
    // from user (allows user to use arrow keys + enter to select)

    const { searchText } = this.state;
    // enter key hit - set redirect
    // this will trigger a redirect in SearchResultsBox
    if (event.keyCode === 13) {
      this.setState({ redirect: true });
    }

    if (!searchText) {
      this.setState({
        selectedValue: null,
      });
    }

    // down arrow key hit, increment selected value
    if (event.keyCode === 40) {
      this.setState((state) => ({
        selectedValue: state.selectedValue ? state.selectedValue + 1 : 1,
      }));
      return;
    }

    // up arrow key hit, decrement selected value
    if (event.keyCode === 38) {
      this.setState((state) => ({
        selectedValue:
          state.selectedValue && state.selectedValue > 1
            ? state.selectedValue - 1
            : null,
      }));
    }
  };

  _resetSelectedValue = () => {
    this.setState({
      selectedValue: 0,
    });
  };

  _resetRedirect = () => {
    this.setState({
      redirect: false,
    });
  };

  render() {
    const {
      searchText,
      searchInputValue,
      showAutoComplete,
      selectedValue,
      redirect,
    } = this.state;

    return (
      <div className="participant-search-input" ref={this.wrapperRef}>
        <div className="control has-icons-left has-icons-right">
          <Form.Input
            className="search"
            placeholder={`Search ${getFeatureFlags().clientDisplayTerm}s`}
            value={searchInputValue}
            name="searchBoxInput"
            onChange={this._handleInputChange}
            onKeyDown={this._handleNameKeyDown}
            autoComplete="off"
          />
          <span className="icon is-small is-left">
            <FontAwesomeIcon color={"#9eadba"} icon={faSearch} />
          </span>
          {showAutoComplete && searchText.length > 0 && (
            <SearchResultsBox
              searchText={searchText}
              selectedValue={selectedValue || 0}
              resetSelectedValue={this._resetSelectedValue}
              resetRedirect={this._resetRedirect}
              redirect={redirect}
            />
          )}
        </div>
      </div>
    );
  }
}
