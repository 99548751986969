import { addUserProperty, initialize, setCustomer } from "./heap/index";

import { WebApps, APP_CONFIGS } from "./configs";
import { isStaging, skipIsDev } from "../util";
import { findAlias } from "./customer-aliases";

export const initHeapAnalytics = (app: WebApps) => {
  if (skipIsDev()) return;

  const config = APP_CONFIGS[app];

  if (!config) {
    console.error(`Unknown app "${app}"`);
    return;
  } else if (!config.heap) {
    console.warn(`No Heap Analytics configuration for app "${app}".`);
    return;
  }

  const appId =
    isStaging() || skipIsDev() ? config.heap.stagingAppId : config.heap.appId;

  initialize(appId);

  addUserProperty({ release: config.release });
  setCustomer(getCustomer(window.location.hostname));
};

const FIRST_SUBDOMAIN_RE = /([^\.]+)\./;

// export for testing
export const getCustomer = (windowLocationHostname: string): string => {
  // read the portion of the hostname to the first '.' and use that as the customer
  const customerFromDomain = FIRST_SUBDOMAIN_RE.exec(windowLocationHostname);

  const hostname =
    customerFromDomain && customerFromDomain.length >= 1
      ? customerFromDomain[1]
      : window.location.hostname;

  return findAlias(hostname);
};
