/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type BroadcastMessageRecipientType = "activity" | "all" | "caseload" | "program" | "risk_level" | "risk_level_by_sex" | "%future added value";
export type BroadcastMessagesGqlQueryVariables = {};
export type BroadcastMessagesGqlQueryResponse = {
    readonly application: ({
        readonly risk_levels: ReadonlyArray<({
            readonly key: string;
            readonly value: string | null;
        }) | null> | null;
    }) | null;
    readonly activities: ({
        readonly activity: ReadonlyArray<({
            readonly id: string;
            readonly type: string;
            readonly title: {
                readonly en: string | null;
            };
        }) | null> | null;
    }) | null;
    readonly broadcast_messages_desc: ({
        readonly broadcast_message: ReadonlyArray<({
            readonly id: string;
            readonly created_at: string;
            readonly deployed_at: string | null;
            readonly deployed: boolean;
            readonly text: string;
            readonly to: {
                readonly queryType: BroadcastMessageRecipientType;
                readonly arg: string | null;
            };
            readonly case_manager: ({
                readonly name: {
                    readonly first: string;
                    readonly last: string | null;
                };
            }) | null;
            readonly scheduled: boolean;
            readonly time_to_deploy: string | null;
        }) | null> | null;
    }) | null;
    readonly broadcast_messages: ({
        readonly broadcast_message: ReadonlyArray<({
            readonly id: string;
            readonly created_at: string;
            readonly deployed_at: string | null;
            readonly deployed: boolean;
            readonly text: string;
            readonly to: {
                readonly queryType: BroadcastMessageRecipientType;
                readonly arg: string | null;
            };
            readonly case_manager: ({
                readonly name: {
                    readonly first: string;
                    readonly last: string | null;
                };
            }) | null;
            readonly scheduled: boolean;
            readonly time_to_deploy: string | null;
        }) | null> | null;
    }) | null;
    readonly case_managers: ({
        readonly case_manager: ReadonlyArray<({
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
        }) | null> | null;
    }) | null;
};
export type BroadcastMessagesGqlQuery = {
    readonly response: BroadcastMessagesGqlQueryResponse;
    readonly variables: BroadcastMessagesGqlQueryVariables;
};



/*
query BroadcastMessagesGqlQuery {
  application {
    risk_levels {
      key
      value
    }
    id
  }
  activities {
    activity {
      id
      type
      title {
        en
      }
    }
  }
  broadcast_messages_desc: broadcast_messages(order_by: "deployed_at_desc") {
    broadcast_message {
      id
      created_at
      deployed_at
      deployed
      text
      to {
        queryType
        arg
      }
      case_manager {
        name {
          first
          last
        }
        id
      }
      scheduled
      time_to_deploy
    }
  }
  broadcast_messages {
    broadcast_message {
      id
      created_at
      deployed_at
      deployed
      text
      to {
        queryType
        arg
      }
      case_manager {
        name {
          first
          last
        }
        id
      }
      scheduled
      time_to_deploy
    }
  }
  case_managers {
    case_manager {
      id
      name {
        first
        last
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "risk_levels",
        "storageKey": null,
        "args": null,
        "concreteType": "KeyedString",
        "plural": true,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "key",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "value",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "activities",
        "storageKey": null,
        "args": null,
        "concreteType": "Pagination",
        "plural": false,
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "activity",
                "storageKey": null,
                "args": null,
                "concreteType": "Activity",
                "plural": true,
                "selections": [
                    (v1 /*: any*/),
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "type",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "title",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "i18nText",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "en",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    }
                ]
            }
        ]
    } as any), v3 = [
        ({
            "kind": "Literal",
            "name": "order_by",
            "value": "deployed_at_desc"
        } as any)
    ], v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "created_at",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "deployed_at",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "deployed",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "text",
        "args": null,
        "storageKey": null
    } as any), v8 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "to",
        "storageKey": null,
        "args": null,
        "concreteType": "BroadcastMessageRecipient",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "queryType",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "arg",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v9 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "name",
        "storageKey": null,
        "args": null,
        "concreteType": "PersonName",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "first",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "last",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v10 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "scheduled",
        "args": null,
        "storageKey": null
    } as any), v11 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "time_to_deploy",
        "args": null,
        "storageKey": null
    } as any), v12 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "broadcast_message",
            "storageKey": null,
            "args": null,
            "concreteType": "BroadcastMessage",
            "plural": true,
            "selections": [
                (v1 /*: any*/),
                (v4 /*: any*/),
                (v5 /*: any*/),
                (v6 /*: any*/),
                (v7 /*: any*/),
                (v8 /*: any*/),
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "case_manager",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CaseManager",
                    "plural": false,
                    "selections": [
                        (v9 /*: any*/)
                    ]
                },
                (v10 /*: any*/),
                (v11 /*: any*/)
            ]
        } as any)
    ], v13 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "case_managers",
        "storageKey": null,
        "args": null,
        "concreteType": "Pagination",
        "plural": false,
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "case_manager",
                "storageKey": null,
                "args": null,
                "concreteType": "CaseManager",
                "plural": true,
                "selections": [
                    (v1 /*: any*/),
                    (v9 /*: any*/)
                ]
            }
        ]
    } as any), v14 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "broadcast_message",
            "storageKey": null,
            "args": null,
            "concreteType": "BroadcastMessage",
            "plural": true,
            "selections": [
                (v1 /*: any*/),
                (v4 /*: any*/),
                (v5 /*: any*/),
                (v6 /*: any*/),
                (v7 /*: any*/),
                (v8 /*: any*/),
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "case_manager",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CaseManager",
                    "plural": false,
                    "selections": [
                        (v9 /*: any*/),
                        (v1 /*: any*/)
                    ]
                },
                (v10 /*: any*/),
                (v11 /*: any*/)
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "BroadcastMessagesGqlQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "application",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Application",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/)
                    ]
                },
                (v2 /*: any*/),
                {
                    "kind": "LinkedField",
                    "alias": "broadcast_messages_desc",
                    "name": "broadcast_messages",
                    "storageKey": "broadcast_messages(order_by:\"deployed_at_desc\")",
                    "args": (v3 /*: any*/),
                    "concreteType": "Pagination",
                    "plural": false,
                    "selections": (v12 /*: any*/)
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "broadcast_messages",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Pagination",
                    "plural": false,
                    "selections": (v12 /*: any*/)
                },
                (v13 /*: any*/)
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "BroadcastMessagesGqlQuery",
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "application",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Application",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/)
                    ]
                },
                (v2 /*: any*/),
                {
                    "kind": "LinkedField",
                    "alias": "broadcast_messages_desc",
                    "name": "broadcast_messages",
                    "storageKey": "broadcast_messages(order_by:\"deployed_at_desc\")",
                    "args": (v3 /*: any*/),
                    "concreteType": "Pagination",
                    "plural": false,
                    "selections": (v14 /*: any*/)
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "broadcast_messages",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Pagination",
                    "plural": false,
                    "selections": (v14 /*: any*/)
                },
                (v13 /*: any*/)
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "BroadcastMessagesGqlQuery",
            "id": null,
            "text": "query BroadcastMessagesGqlQuery {\n  application {\n    risk_levels {\n      key\n      value\n    }\n    id\n  }\n  activities {\n    activity {\n      id\n      type\n      title {\n        en\n      }\n    }\n  }\n  broadcast_messages_desc: broadcast_messages(order_by: \"deployed_at_desc\") {\n    broadcast_message {\n      id\n      created_at\n      deployed_at\n      deployed\n      text\n      to {\n        queryType\n        arg\n      }\n      case_manager {\n        name {\n          first\n          last\n        }\n        id\n      }\n      scheduled\n      time_to_deploy\n    }\n  }\n  broadcast_messages {\n    broadcast_message {\n      id\n      created_at\n      deployed_at\n      deployed\n      text\n      to {\n        queryType\n        arg\n      }\n      case_manager {\n        name {\n          first\n          last\n        }\n        id\n      }\n      scheduled\n      time_to_deploy\n    }\n  }\n  case_managers {\n    case_manager {\n      id\n      name {\n        first\n        last\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '4a5f63762214655e0988d521753fa0db';
export default node;
