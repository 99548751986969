import * as React from "react";
import { Modal } from "react-bulma-components";
import {
  withTracker,
  TRACKED_GRAPHQL_CALL_TYPES,
  WithTrackerProps,
} from "../../lib/graphql";

import styles from "./MutationErrorWarning.module.scss";

interface MutationErrorWarningState {
  forceClosed: boolean;
}

interface MutationErrorWarningProps extends WithTrackerProps {}

class MutationErrorWarningComponent extends React.PureComponent<
  MutationErrorWarningProps,
  MutationErrorWarningState
> {
  state = {
    forceClosed: false,
  };

  componentDidUpdate({ errors }: MutationErrorWarningProps) {
    if (errors !== this.props.errors) {
      // reset the close state - render will use the props value
      this.setState({ forceClosed: false });
    }
  }

  private close = () => {
    this.setState({ forceClosed: true });
  };

  private getErrorsToDisplay = (
    errors: string[] | null
  ): JSX.Element | null => {
    if (!errors) return null;

    const firstError = errors[0];

    return (
      <p className={styles.errorSpecific}>
        {errors.length === 1
          ? firstError
          : `${firstError} (+${errors.length - 1} more)`}
      </p>
    );
  };

  render() {
    const { forceClosed } = this.state;
    const { errors } = this.props;

    const displayError = this.getErrorsToDisplay(errors);
    // we should implement some semantics form the back-end
    //  Currently, we say "retry" if we won't have information on what the error is
    // Otherwise we show that specific information
    const shouldRetry = !displayError;

    return (
      <Modal
        show={!forceClosed && !!errors}
        showClose={false}
        onClose={() => {}}
      >
        <Modal.Card>
          <Modal.Card.Head showClose={true} onClose={this.close}>
            <Modal.Card.Title>An Error Occurred</Modal.Card.Title>
          </Modal.Card.Head>
          <Modal.Card.Body>
            <p>
              Sorry, we were unable to process that last action.
              {shouldRetry && " Please try again."}
            </p>
            {displayError || (
              <p>Promise has been alerted and is working to fix the problem.</p>
            )}
          </Modal.Card.Body>
          <Modal.Card.Foot />
        </Modal.Card>
      </Modal>
    );
  }
}

export const MutationErrorWarning = withTracker(
  TRACKED_GRAPHQL_CALL_TYPES.Mutation,
  MutationErrorWarningComponent
);
