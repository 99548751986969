import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import idx from "idx.macro";
import PrintCourtMemo from "../elements/PrintCourtMemo";
import { QueryRenderer } from "../../shared/lib/graphql";
import { bookingsGraphqlByIdQuery } from "../bookings/BookingsGraphql";
import { ProspectBookingGraphqlData } from "../prospects/ProspectTableQueryContainer";

interface Props {
  bookingId: string;
}

export default class PrintCourtMemoContainer extends React.Component<
  RouteComponentProps<Props>
> {
  render() {
    return (
      <QueryRenderer
        query={bookingsGraphqlByIdQuery}
        variables={{
          id: this.props.bookingId,
        }}
        SuccessComponent={(props: ProspectBookingGraphqlData) => {
          const [booking] = idx(props, (_) => _.bookings.booking);

          return <PrintCourtMemo booking={booking} />;
        }}
      />
    );
  }
}
