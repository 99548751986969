/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type CalendarGraphqlQueryVariables = {
    readonly id: string;
};
export type CalendarGraphqlQueryResponse = {
    readonly participants: ({
        readonly participant: ReadonlyArray<({
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
            readonly events: ReadonlyArray<({
                readonly id: string | null;
                readonly date: string | null;
                readonly end: string | null;
                readonly activity: {
                    readonly title: {
                        readonly en: string | null;
                    };
                };
            }) | null> | null;
        }) | null> | null;
    }) | null;
};
export type CalendarGraphqlQuery = {
    readonly response: CalendarGraphqlQueryResponse;
    readonly variables: CalendarGraphqlQueryVariables;
};



/*
query CalendarGraphqlQuery(
  $id: String!
) {
  participants(case_manager_id: $id) {
    participant {
      id
      name {
        first
        last
      }
      events {
        id
        date
        end
        activity {
          title {
            en
          }
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "String!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "case_manager_id",
            "variableName": "id"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "name",
        "storageKey": null,
        "args": null,
        "concreteType": "PersonName",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "first",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "last",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "date",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "end",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "title",
        "storageKey": null,
        "args": null,
        "concreteType": "i18nText",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "en",
                "args": null,
                "storageKey": null
            }
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "CalendarGraphqlQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participants",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Pagination",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "participant",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Participant",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "events",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Event",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "activity",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "Activity",
                                            "plural": false,
                                            "selections": [
                                                (v6 /*: any*/)
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "CalendarGraphqlQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participants",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Pagination",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "participant",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Participant",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "events",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Event",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "activity",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "Activity",
                                            "plural": false,
                                            "selections": [
                                                (v6 /*: any*/),
                                                (v2 /*: any*/)
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "CalendarGraphqlQuery",
            "id": null,
            "text": "query CalendarGraphqlQuery(\n  $id: String!\n) {\n  participants(case_manager_id: $id) {\n    participant {\n      id\n      name {\n        first\n        last\n      }\n      events {\n        id\n        date\n        end\n        activity {\n          title {\n            en\n          }\n          id\n        }\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'cd875ed58caea0076d0ab5c5f4d396b4';
export default node;
