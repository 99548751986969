import * as React from "react";
import { Subscription } from "rxjs";

import {
  FlightTrackers,
  TRACKED_GRAPHQL_CALL_TYPES,
  GraphqlFlightTrackerType,
  GRAPHQL_FLIGHT_STATUS,
} from "./graphqlFlightTracker";
import { wrapComponent } from "../types";

export interface WithTrackerProps {
  defaultMessage: string;
  trackerInFlightStatus: GRAPHQL_FLIGHT_STATUS;
  identifier: string | null;
  errors: string[] | null;
}

export interface WithTrackerState extends WithTrackerProps {
  tracker: GraphqlFlightTrackerType;
}

export function withTracker<TCall, TWrappedProps extends WithTrackerProps>(
  trackerKey: TRACKED_GRAPHQL_CALL_TYPES,
  WrappedComponent:
    | React.ComponentType<TWrappedProps>
    | React.SFC<TWrappedProps>
): React.ComponentClass<TCall> {
  const C = class withTracker extends React.PureComponent<
    TCall,
    WithTrackerState
  > {
    private defaultMessageSubscription: Subscription;
    private errorsSubscription: Subscription;
    private inFlightSubscription: Subscription;

    constructor(props: TCall) {
      super(props);

      const tracker = FlightTrackers.get(trackerKey);

      this.state = {
        defaultMessage: "",
        errors: null,
        identifier: null,
        trackerInFlightStatus: GRAPHQL_FLIGHT_STATUS.NULL,
        tracker,
      };

      this.defaultMessageSubscription = tracker.$defaultMessage.subscribe(
        (defaultMessage) => {
          this.setState({ defaultMessage });
        }
      );

      this.errorsSubscription = tracker.$errors.subscribe((errorItem) => {
        if (errorItem === null) return;
        const { identifier, errors } = errorItem;
        this.setState({ identifier, errors });
      });

      this.inFlightSubscription = tracker.$inFlightStatus.subscribe(
        (trackerInFlightStatus) => {
          this.setState({ trackerInFlightStatus });
        }
      );
    }

    componentWillUnmount() {
      if (this.inFlightSubscription) this.inFlightSubscription.unsubscribe();
      if (this.defaultMessageSubscription)
        this.defaultMessageSubscription.unsubscribe();
      if (this.errorsSubscription) this.errorsSubscription.unsubscribe();
    }

    render() {
      return (
        <WrappedComponent
          defaultMessage={this.state.defaultMessage}
          identifier={this.state.identifier}
          errors={this.state.errors}
          trackerInFlightStatus={this.state.trackerInFlightStatus}
          {...this.props}
        />
      );
    }
  };

  return wrapComponent(C, WrappedComponent, "withTracker");
}
