import { FetcherOptions } from "./sharedTypes";

export default function optionsToStr(options: FetcherOptions): string {
  const keys = options ? Object.keys(options) : [];

  if (keys.length === 0) {
    return "";
  }

  return keys
    .map((key: string) => {
      return `${key}=${encodeURIComponent((options as any)[key] as string)}`;
    })
    .join("&");
}
