/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type MutationResult = "failure" | "skipped" | "success" | "%future added value";
export type tasksUpdateTaskMutationVariables = {
    readonly participant_id: string;
    readonly task_id: string;
    readonly is_completed?: boolean | null;
    readonly type?: string | null;
};
export type tasksUpdateTaskMutationResponse = {
    readonly updateParticipantTask: ({
        readonly task: ({
            readonly id: string;
            readonly label: string | null;
            readonly completed_at: string | null;
            readonly type: string | null;
        }) | null;
        readonly result: MutationResult;
        readonly description: string | null;
        readonly errors: ReadonlyArray<string | null> | null;
    }) | null;
};
export type tasksUpdateTaskMutation = {
    readonly response: tasksUpdateTaskMutationResponse;
    readonly variables: tasksUpdateTaskMutationVariables;
};



/*
mutation tasksUpdateTaskMutation(
  $participant_id: String!
  $task_id: String!
  $is_completed: Boolean
  $type: String
) {
  updateParticipantTask(participant_id: $participant_id, task_id: $task_id, is_completed: $is_completed, type: $type) {
    task {
      id
      label
      completed_at
      type
    }
    result
    description
    errors
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "participant_id",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "task_id",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "is_completed",
            "type": "Boolean",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "type",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "updateParticipantTask",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "is_completed",
                    "variableName": "is_completed"
                },
                {
                    "kind": "Variable",
                    "name": "participant_id",
                    "variableName": "participant_id"
                },
                {
                    "kind": "Variable",
                    "name": "task_id",
                    "variableName": "task_id"
                },
                {
                    "kind": "Variable",
                    "name": "type",
                    "variableName": "type"
                }
            ],
            "concreteType": "NewParticipantTask",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "task",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ParticipantTask",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "label",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "completed_at",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "type",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "result",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "errors",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "tasksUpdateTaskMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "tasksUpdateTaskMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "tasksUpdateTaskMutation",
            "id": null,
            "text": "mutation tasksUpdateTaskMutation(\n  $participant_id: String!\n  $task_id: String!\n  $is_completed: Boolean\n  $type: String\n) {\n  updateParticipantTask(participant_id: $participant_id, task_id: $task_id, is_completed: $is_completed, type: $type) {\n    task {\n      id\n      label\n      completed_at\n      type\n    }\n    result\n    description\n    errors\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '157203489010c1f611ecc7b59ab69d48';
export default node;
