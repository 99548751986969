import moment, { Moment } from "moment";
import { CaseManager, KeyedString, AssessmentTool } from "./graphql/flowTypes";

export const EVENT_TIME_INTERVAL = 15;

interface RoundTimeIntervalProps {
  date: Moment;
  method?: string;
  interval: number;
}

export const roundTimeIntervals = ({
  date,
  interval,
}: RoundTimeIntervalProps): Moment => {
  const duration = moment.duration(interval, "minutes");
  return moment(Math.ceil(+date / +duration) * +duration);
};

export const combineMomentTimeAndDates = (
  timeToSync: Moment,
  dateToSync: Moment
): Moment => {
  if (!dateToSync || dateToSync instanceof moment === false || !timeToSync) {
    return timeToSync;
  }

  const dateString = dateToSync.format("MM/DD/YYYY");
  const hour = timeToSync.hour();
  const minute = timeToSync.minute();
  return moment(dateString).hour(hour).minute(minute);
};

export const serializeCaseManagers = (
  caseManagers: CaseManager[]
): KeyedString[] => {
  return caseManagers.map(
    (caseManager: CaseManager): KeyedString => {
      const { id } = caseManager;
      const { first, last } = caseManager.name;
      const displayName = `${first} ${last}`;
      return {
        key: id,
        value: displayName,
      };
    }
  );
};

export const serializeAssessmentTools = (
  assessmentTools: AssessmentTool[]
): KeyedString[] => {
  return assessmentTools.map(
    (assessmentTool: AssessmentTool): KeyedString => {
      const { id, name } = assessmentTool;
      return {
        key: id,
        value: name,
      };
    }
  );
};

/*
This function accepts three parameters, an array of objects to be converted to a dictionary, a key, and a value.
Using the specified key and value, the function will construct a dictionary and return it to the user.
Both key and value should be existing properties on each object in the array.
The specified key will be used as a key for the newly created dictionary, and the specified value attribute will be the key's value.

Example, given the following array
[{id: "333149", name: "Omar", phone: "303-437-3333"}, {id: "3331423", name: "Jeff", phone: "303-437-3322"}]
Along with the key of "id" and the value of "phone", the function will return the following:

{
  333149: "303-437-3333",
  3331423: "303-437-3322"
}

*/
export function arrayToDictionarySpecifyKeyValue<T>(
  arrayToConvert: Array<T>,
  key: keyof T,
  value: keyof T
): { [key: string]: any } {
  const normalizedObject = arrayToConvert.reduce(
    (acc: any, itemToNormalize: T): any => {
      acc[itemToNormalize[key]] = itemToNormalize[value];
      return acc;
    },
    {}
  );

  return normalizedObject as { [key: string]: any };
}
