import * as React from "react";
import moment from "moment-timezone";

import {
  ActivityMeta,
  TimelineEntryDetailsTypes,
  LogEntryType,
} from "../../lib/graphql/flowTypes";
import styles from "./Timeline.module.scss";
import {
  TimelineEventFromLogEntry,
  TimelineTypeModifiers,
} from "./TimelineEventIcon";
import { TimelineTimelineEntryType, TimelineEventType } from "./TimelineGql";
import { getDateForTimelineEvent } from "./TimelineUtil";
import { NotificationDeliveryIndicator } from "./NotificationDeliveryIndicator";
const titleCase = require("title-case");

const getEventActivityTitle = (
  event: TimelineEventType,
  past: boolean,
  eventAttendanceTitles: { [key: string]: string }
): string => {
  const {
    attended,
    activity: {
      title: { en: activityTitle },
    },
  } = event;

  return eventAttendanceTitles[attended]
    ? `${activityTitle}: ${eventAttendanceTitles[attended]}`
    : `${activityTitle}${past ? `: ${eventAttendanceTitles["default"]}` : ""}`;
};

const getTimelineTitle = (
  timelineEntry: TimelineTimelineEntryType,
  past: boolean,
  eventAttendanceTitles: { [key: string]: string }
): string => {
  const {
    id,
    event: {
      activity: {
        title: { en: activityTitle },
      },
    },
    details,
    notification,
    type: logType,
  } = timelineEntry;

  // Most activities
  // NOTE: If notifications are removed (seen in the wild, but possibly only invalid data)
  //  Then notification can be null though this is a NotificationQueue (or, potentially, other Notification* type)

  if (!notification && activityTitle) {
    return getEventActivityTitle(
      timelineEntry.event,
      past,
      eventAttendanceTitles
    );
  }

  // Various non-notifications & not activities changes
  if (!notification) {
    switch (logType) {
      case LogEntryType.ParticipantUpdated:
        return "Client Data Updated";
      case LogEntryType.NotificationQueue:
      case LogEntryType.NotificationDelete:
      case LogEntryType.NotificationDeliveryFailure:
      case LogEntryType.NotificationDeliverySuccess:
      case LogEntryType.NotificationDeploy:
      case LogEntryType.NotificationMessageParticipant:
        return "Notification";
      case LogEntryType.ParticipantActiveStatusChanged:
        const { after, reason } = details;

        const reasonUnwrapped = reason || "";

        const reasonFormatted = titleCase(reasonUnwrapped.replace("_", " "));

        const isActive = after ? after.is_active : null;

        if (isActive === true) {
          return `Client activated: ${reasonFormatted}`;
        } else if (isActive === false) {
          return `Client deactivated: ${reasonFormatted}`;
        } else {
          // we shouldn't ever get here but just in case
          console.error("after.is_active status not set");
          return `Client deactivated: ${reasonFormatted}`;
        }
      default:
        console.error(
          `Found non-notification Timeline entry, unknown logType "${logType}"`
        );
        return "Changed";
    }
  }

  // Most notifications
  if (activityTitle) return `Reminder for ${activityTitle}`;

  if (details === null) {
    console.warn(`Unexpected shape for Timeline Entry "${id}"`);
    return "";
  }

  // Other notifications
  switch (details.type) {
    case TimelineEntryDetailsTypes.NewParticipant:
      return "Client Welcome Message";
    case TimelineEntryDetailsTypes.NewMessageForParticipant:
      return "Notification of Case Manager Chat Message";
    default:
      return `Notification`;
  }
};

const getTimelineType = (timelineEntry: TimelineTimelineEntryType): string => {
  const {
    event: {
      activity: { type: activityType },
    },
    details,
    notification,
    type: logType,
  } = timelineEntry;

  if (!notification) return activityType || logType;

  return details && details.type ? details.type : logType;
};

const getTimelineTypeModifier = (
  timelineEntry: TimelineTimelineEntryType
): TimelineTypeModifiers | null => {
  const { type } = timelineEntry;

  switch (type) {
    case LogEntryType.EventDeleted:
      return TimelineTypeModifiers.DELETED;
    default:
      return null;
  }
};

const getEventData = (
  timelineEntry: TimelineTimelineEntryType,
  past: boolean,
  eventAttendanceTitles: { [key: string]: string }
) => {
  const {
    id,
    note,
    event: { attended, time_specified: timeSpecified },
    notification,
    ...rest
  } = timelineEntry;

  const displayDate = getDateForTimelineEvent(timelineEntry);

  const title = getTimelineTitle(timelineEntry, past, eventAttendanceTitles);

  const type = getTimelineType(timelineEntry);

  const typeModifier = getTimelineTypeModifier(timelineEntry);

  const detailsNote =
    timelineEntry.details && timelineEntry.details.note
      ? timelineEntry.details.note
      : "";

  return {
    ...rest,
    id,
    note,
    displayDate,
    attended,
    notification,
    title,
    type,
    timeSpecified,
    typeModifier,
    detailsNote,
  };
};

export const TimelineEvent = (
  past: boolean,
  eventAttendanceTitles: { [key: string]: string },
  eventTypes?: Array<ActivityMeta>
) => (timelineEntry: TimelineTimelineEntryType) => {
  const {
    id,
    attended,
    note,
    displayDate,
    notification,
    title,
    type,
    typeModifier,
    timeSpecified,
    detailsNote,
  } = getEventData(timelineEntry, past, eventAttendanceTitles);

  const isEvent = !notification && timelineEntry.event;

  const displayDateFormatted =
    timeSpecified || !isEvent
      ? moment(displayDate).format("MM/DD/YY hh:mm A").replace(/\s/g, "\u00A0")
      : moment(displayDate).format("MM/DD/YY").replace(/\s/g, "\u00A0");

  return (
    <div
      key={id}
      data-id={id}
      data-type={type}
      className={`media is-marginless is-paddingless ${
        styles.timelineEntry || ""
      }`}
    >
      <div className="media-left is-marginless">
        <div className="level">
          <span
            className={`level-item ${styles.column} ${styles.noGrow} ${styles.timelineDate}`}
          >
            {displayDateFormatted}
          </span>
          <span className={`level-item ${styles.column} ${styles.noGrow}`}>
            <div className={styles.iconContainer}>
              <TimelineEventFromLogEntry
                past={past}
                type={type}
                attended={attended}
                eventTypes={eventTypes}
                typeModifier={typeModifier}
              />
            </div>
          </span>
        </div>
      </div>
      <span className={`media-content ${styles.column}`}>
        <span className={styles.timelineItemTitle}>{title}</span>
        {notification && (
          <>
            <span className={styles.notification}>{notification.body}</span>
            {notification.time_deployed && (
              <NotificationDeliveryIndicator id={notification.id} />
            )}
          </>
        )}
        {note && <span className={styles.notification}>{note}</span>}
        {detailsNote && (
          <span className={styles.notification}>{`Note: ${detailsNote}`}</span>
        )}
      </span>
    </div>
  );
};
