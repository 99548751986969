import { Moment } from "moment";
import ModalWrapperHOC from "../../HOCs/ModalWrapperHOC";
import ModalWrapper from "../ModalWrapper";
import {
  roundTimeIntervals,
  EVENT_TIME_INTERVAL,
} from "../../../shared/lib/modalFormHelpers";
import { FormField, FormDataBase, ValidationsType } from "../types";
import {
  Obligation,
  Participant,
  ServiceProvider,
} from "../../../shared/lib/graphql";
import { AddEventFormOptions } from "./AddEventForm";
import { getFeatureFlags } from "../../../featureFlags";
import moment from "moment";

export interface AddEventFormData extends FormDataBase {
  participant: FormField;
  serviceProvider: FormField;
  startDate: FormField<Moment> | FormField<null>;
  rrule: FormField;
  [key: string]: any;
}

export interface AddEventFormDataStructured {
  obligation: Partial<Obligation>;
  start: String;
  rrule: FormField;
}

export interface ObligationAndParticipantDetailsType {
  first: string;
  last: string;
  where: string;
  rrule: string;
  end: string;
}

class AddEventModalWrapper extends ModalWrapper<AddEventFormData> {
  static formState = getAddEventModalStatic(null);
  componentDidMount() {
    // TODO: We need to cleanup this `static` vs. "change in this here cdm instance method"

    // on remount, refresh the static class prop formState
    // allows us to pass in some data (obligationAndParticipantDetails)
    // to hydrate the formState static prop
    const { updateObject: obligationAndParticipantDetails } = this.props;

    if (obligationAndParticipantDetails) {
      AddEventModalWrapper.formState = getAddEventModalStatic(
        obligationAndParticipantDetails
      );
    } else {
      AddEventModalWrapper.formState = getAddEventModalStatic(null);
    }
  }
}

function getAddEventModalStatic(
  obligationAndParticipantDetails:
    | ObligationAndParticipantDetailsType
    | null
    | undefined
) {
  return {
    modalFormData: {
      participant: {
        label: getFeatureFlags().clientDisplayTerm || "Client",
        value: obligationAndParticipantDetails
          ? `${obligationAndParticipantDetails.first} ${obligationAndParticipantDetails.last}`
          : "",
      },
      serviceProvider: {
        label: "Where",
        value: obligationAndParticipantDetails
          ? obligationAndParticipantDetails.where
          : "",
      },
      startDate: {
        label: "Start Date",
        value: roundTimeIntervals({
          date: moment(),
          interval: EVENT_TIME_INTERVAL,
        }),
        id: "",
      },
      rrule: {
        label: "Repeat",
        value: "",
      },
    } as AddEventFormData,
    constitute: (data: AddEventFormData): AddEventFormDataStructured => {
      const {
        participant: { value: participantId },
        serviceProvider: { value: serviceProviderId },
        startDate: { value: start },
        rrule,
      } = data;

      return {
        obligation: {
          participant: { id: participantId } as Participant,
          service_provider: {
            id: serviceProviderId,
          } as ServiceProvider,
        },
        start: start ? start.toISOString() : null,
        rrule,
      } as AddEventFormDataStructured;
    },
    validations: {
      participant: {
        includeIf: ({ selectParticipant }: AddEventFormOptions) =>
          !!selectParticipant,
        state: "pristine",
        errors: [] as string[],
      },
      serviceProvider: {
        includeIf: ({ selectServiceProvider }: AddEventFormOptions) =>
          !!selectServiceProvider,
        state: "pristine",
        errors: [] as string[],
      },
      startDate: { state: "valid", errors: [] as string[] },
      rrule: {
        state: "valid",
        errors: [] as string[],
      },
    } as ValidationsType,
    attemptedSubmission: false,
    secondPage: false,
    secondPageMessageWarning: false,
    showModal: false,
  };
}

export default ModalWrapperHOC(AddEventModalWrapper);
