/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type ParticipantListContainerQueryVariables = {
    readonly case_manager_id?: string | null;
};
export type ParticipantListContainerQueryResponse = {
    readonly application: ({
        readonly risk_levels: ReadonlyArray<({
            readonly key: string;
            readonly value: string | null;
        }) | null> | null;
    }) | null;
    readonly me: ({
        readonly case_manager: ({
            readonly id: string;
        }) | null;
    }) | null;
    readonly case_managers: ({
        readonly case_manager: ReadonlyArray<({
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
        }) | null> | null;
    }) | null;
    readonly all_case_managers: ({
        readonly case_manager: ReadonlyArray<({
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
            readonly id: string;
        }) | null> | null;
    }) | null;
};
export type ParticipantListContainerQuery = {
    readonly response: ParticipantListContainerQueryResponse;
    readonly variables: ParticipantListContainerQueryVariables;
};



/*
query ParticipantListContainerQuery(
  $case_manager_id: String
) {
  application {
    risk_levels {
      key
      value
    }
    id
  }
  me {
    case_manager {
      id
    }
  }
  case_managers(id: $case_manager_id) {
    case_manager {
      id
      name {
        first
        last
      }
    }
  }
  all_case_managers: case_managers {
    case_manager {
      name {
        first
        last
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "case_manager_id",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "risk_levels",
        "storageKey": null,
        "args": null,
        "concreteType": "KeyedString",
        "plural": true,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "key",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "value",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "case_manager",
                "storageKey": null,
                "args": null,
                "concreteType": "CaseManager",
                "plural": false,
                "selections": [
                    (v2 /*: any*/)
                ]
            }
        ]
    } as any), v4 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "name",
        "storageKey": null,
        "args": null,
        "concreteType": "PersonName",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "first",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "last",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v5 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "case_managers",
        "storageKey": null,
        "args": [
            {
                "kind": "Variable",
                "name": "id",
                "variableName": "case_manager_id"
            }
        ],
        "concreteType": "Pagination",
        "plural": false,
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "case_manager",
                "storageKey": null,
                "args": null,
                "concreteType": "CaseManager",
                "plural": true,
                "selections": [
                    (v2 /*: any*/),
                    (v4 /*: any*/)
                ]
            }
        ]
    } as any), v6 = ({
        "kind": "LinkedField",
        "alias": "all_case_managers",
        "name": "case_managers",
        "storageKey": null,
        "args": null,
        "concreteType": "Pagination",
        "plural": false,
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "case_manager",
                "storageKey": null,
                "args": null,
                "concreteType": "CaseManager",
                "plural": true,
                "selections": [
                    (v4 /*: any*/),
                    (v2 /*: any*/)
                ]
            }
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "ParticipantListContainerQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "application",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Application",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/)
                    ]
                },
                (v3 /*: any*/),
                (v5 /*: any*/),
                (v6 /*: any*/)
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "ParticipantListContainerQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "application",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Application",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/)
                    ]
                },
                (v3 /*: any*/),
                (v5 /*: any*/),
                (v6 /*: any*/)
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "ParticipantListContainerQuery",
            "id": null,
            "text": "query ParticipantListContainerQuery(\n  $case_manager_id: String\n) {\n  application {\n    risk_levels {\n      key\n      value\n    }\n    id\n  }\n  me {\n    case_manager {\n      id\n    }\n  }\n  case_managers(id: $case_manager_id) {\n    case_manager {\n      id\n      name {\n        first\n        last\n      }\n    }\n  }\n  all_case_managers: case_managers {\n    case_manager {\n      name {\n        first\n        last\n      }\n      id\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'c3cc164000e38e8071cd05407089faf0';
export default node;
