import React from "react";
import AddCaseForm from "./AddCaseForm";
import AddCaseModalWrapper, { AddCaseFormData } from "./AddCaseModalWrapper";
import { ModalProps } from "../ModalWrapper";

export type AddEventModalProps = ModalProps<AddCaseFormData>;

export default function ({
  callBackForAPI,
  operation,
  type,
  disableButton,
  buttonComponent,
  updateObject,
  ...formOptions
}: AddEventModalProps) {
  return (
    <AddCaseModalWrapper
      callBackForAPI={callBackForAPI}
      type={type}
      disableButton={disableButton}
      buttonComponent={buttonComponent}
      operation={operation}
      updateObject={updateObject}
    >
      {(data: any) => (
        <AddCaseForm {...formOptions} {...data} updateObject={updateObject} />
      )}
    </AddCaseModalWrapper>
  );
}
