export enum WebApps {
  CMWA = "CMWA",
}

export interface APP_CONFIG_TYPE {
  [key: string]: {
    release: string;
    sentry?: {
      dsn: string;
    };
    googleAnalytics?: {
      key: string;
    };
    heap?: {
      appId: string;
      stagingAppId: string;
    };
  };
}

export const APP_CONFIGS: APP_CONFIG_TYPE = {
  [WebApps.CMWA]: {
    release: "cmwa@1.13.0",
    sentry: {
      dsn: "https://824fae8fd23d49fd9632ee63d9e8f627@sentry.io/1342345",
    },
    googleAnalytics: {
      key: "UA-129280374-2",
    },
    heap: {
      appId: "3107897928",
      stagingAppId: "4217130413",
    },
  },
};
