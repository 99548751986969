import graphql from "babel-plugin-relay/macro";

export const CalendarGraphqlQuery = graphql`
  query CalendarGraphqlQuery($id: String!) {
    participants(case_manager_id: $id) {
      participant {
        id
        name {
          first
          last
        }
        events {
          id
          date
          end
          activity {
            title {
              en
            }
          }
        }
      }
    }
  }
`;
