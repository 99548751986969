import React from "react";
import { Moment } from "moment";
import { Message } from "react-bulma-components";
import { Button } from "antd";

import "./ModalConfirmationPage.scss";
import ConfirmationModalRow from "../ConfirmationModalRow";

import { FormDataBase } from "./types";
import { getFeatureFlags } from "../../featureFlags";
import { SomeOfThePropsTypedFromModalWrapper } from "../HOCs/ModalWrapperHOC";

export interface ModalConfirmationPageProps<T = any>
  extends Pick<
    SomeOfThePropsTypedFromModalWrapper,
    "handleFormConfirmSubmit" | "onDelete"
  > {
  name: string;
  modalFormData: FormDataBase<T>;
  togglePage: (event: any) => void;
  secondPageMessageWarning?: string;
}

const ModalConfirmationPage = function <TData>({
  name,
  modalFormData,
  togglePage,
  handleFormConfirmSubmit,
  secondPageMessageWarning,
}: ModalConfirmationPageProps<TData>) {
  return (
    <div className="modal-confirmaton-page">
      <div className="main">
        <div className="confirm-box">
          <div className="table-wrapper">
            <table>
              <tbody>
                {Object.keys(modalFormData).map((key) => {
                  const { label, dateFormat, text, value } = modalFormData[key];

                  const displayValue =
                    dateFormat && value && ((value as any) as Moment).format
                      ? ((value as any) as Moment).format(dateFormat)
                      : text || value;

                  return (
                    <ConfirmationModalRow
                      key={key}
                      name={label}
                      value={displayValue}
                    />
                  );
                })}
                <tr>
                  <td colSpan={2}>
                    <Button type={"link"} onClick={togglePage}>
                      {`Edit ${name}`}
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {secondPageMessageWarning && (
          <Message color="warning">
            <Message.Body>
              {/* <Notification> */}
              <div className="has-text-warning has-text-weight-bold">
                Things to consider:{" "}
              </div>
              The changes you're making will result in messages being sent to
              this {getFeatureFlags().clientDisplayTerm}.
            </Message.Body>
          </Message>
        )}
        <div className="confirm-button-wrapper">
          <Button
            type="primary"
            style={{ width: "100%" }}
            onClick={handleFormConfirmSubmit}
          >
            {secondPageMessageWarning ? "Confirm and Send Messages" : "Confirm"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmationPage;
