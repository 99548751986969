import * as React from "react";
import graphql from "babel-plugin-relay/macro";
import { Link, Redirect, RouteComponentProps, Location } from "@reach/router";
import idx from "idx.macro";
import {
  KeyedString,
  QueryRenderer,
  CaseManager,
} from "../../shared/lib/graphql";
import { ParticipantListSearchProvider } from "../../shared/components/participantListTable";
import { LinkedTab } from "../../shared/components/elements/LinkedTabs";
import { ROOT_PATH } from "../../AppInfo";
import { ParticipantListContents } from "./ParticipantListContents";
import { CaseManagerSelect } from "../elements/CaseManagerSelect";

import "./ParticipantList.scss";

export interface ParticipantListContainerProps {
  riskLevel?: string;
  caseManagerId?: string;
  status?: string;
  baseUri?: string;
}

interface ApplicationConfigsGQLResponse {
  application: {
    risk_levels: KeyedString[];
  };
  me: {
    case_manager: {
      id: string;
    };
  };
  case_managers: {
    case_manager: CaseManager[];
  };
  all_case_managers: {
    case_manager: CaseManager[];
  };
}

const BUCKET_PLACEHOLDER = "-";

const riskLevelStringExceptionsRiskLevelStrings = ["inactive", "active", ""];

export const getBaseUri = (path?: string): string => {
  if (!path) {
    console.error("No path parameter passed. Unexpected.");
    return "";
  }
  let uri =
    path.indexOf(":") !== -1 ? path.substring(0, path.lastIndexOf(":")) : path;
  // keep only the first of any additional slashes
  uri = (/^\/*(\/.*)/.exec(uri) || [])[1];
  if (!uri.endsWith("/")) {
    uri += "/";
  }
  return uri;
};

const getcaseLoadCmUrls = (
  path: string = "",
  riskLevel: string = "",
  caseManagerId: string = ""
): { activePath: string; inactivePath: string } => {
  const riskLevelPlaceholder = ":riskLevel";
  const caseManagerIdPlaceholder = ":caseManagerId";
  const statusPlaceholder = ":status";
  const riskLevelPath = path.replace(riskLevelPlaceholder, riskLevel);
  const basePath = riskLevelPath.replace(
    caseManagerIdPlaceholder,
    caseManagerId
  );

  // Very hacky we are using deactivated as a risk level on
  // This route, so special rules apply
  if (path === "/case-manager/clients/:riskLevel") {
    return {
      activePath: path.replace(riskLevelPlaceholder, ""),
      inactivePath: path.replace(riskLevelPlaceholder, "inactive"),
    };
  }

  // if no risk level is passed in
  if (path === "/case-manager/clients") {
    return {
      activePath: riskLevelPath,
      inactivePath: riskLevelPath + "/inactive",
    };
  }

  const activePath = basePath.replace(statusPlaceholder, "active");
  const inactivePath = basePath.replace(statusPlaceholder, "inactive");

  return {
    activePath,
    inactivePath,
  };
};

const riskLevelPath = ({ key }: KeyedString) => key.toLowerCase();

const renderCaseManagerSelect = (
  navigate: (url: string) => void,
  baseUrl: string,
  // riskLevel: string, // we're ignoring this for now otherwise it gets weird
  riskLevels: KeyedString[],
  status: string = "active",
  caseManagers: CaseManager[],
  caseManagerId?: string
) => (
  <CaseManagerSelect
    caseManagers={caseManagers}
    value={caseManagerId}
    onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
      const { value: id } = evt.target;
      if (id === "all")
        navigate(`${baseUrl}${riskLevels[0].key.toLowerCase()}`);
      else navigate(`${baseUrl}-/${id}/status/${status}`);
    }}
  />
);

const mapRiskLevelsAsLinkedTabs = (
  baseUri: string,
  selectedRiskLevel: string | undefined,
  riskLevels: Array<KeyedString>
) => {
  return riskLevels.map(
    (level) =>
      ({
        ...level,
        linkTo: baseUri + encodeURIComponent(level.key.toLowerCase()),
        selected: level.key.toLowerCase() === selectedRiskLevel,
      } as LinkedTab)
  );
};

interface State {
  query: string;
}

export class ParticipantListContainer extends React.PureComponent<
  RouteComponentProps<ParticipantListContainerProps>,
  State
> {
  state = {
    query: "",
  };

  _search = (query: string) => {
    this.setState({ query });
  };
  render() {
    const { query } = this.state;
    const { path, riskLevel, caseManagerId, status } = this.props;

    return (
      <ParticipantListSearchProvider value={{ query, onChange: this._search }}>
        <ParticipantListQueryRenderer
          path={path}
          riskLevel={riskLevel}
          status={status}
          caseManagerId={caseManagerId}
        />
      </ParticipantListSearchProvider>
    );
  }
}

class ParticipantListQueryRenderer extends React.PureComponent<
  RouteComponentProps<ParticipantListContainerProps>,
  State
> {
  render() {
    const {
      path,
      riskLevel: selectedRiskLevelString = "",
      caseManagerId,
      status,
    } = this.props;
    const baseUri = getBaseUri(path);

    return (
      <Location>
        {({ navigate }) => (
          <QueryRenderer
            query={graphql`
              query ParticipantListContainerQuery($case_manager_id: String) {
                application {
                  risk_levels {
                    key
                    value
                  }
                }
                me {
                  case_manager {
                    id
                  }
                }
                case_managers(id: $case_manager_id) {
                  case_manager {
                    id
                    name {
                      first
                      last
                    }
                  }
                }
                all_case_managers: case_managers {
                  case_manager {
                    name {
                      first
                      last
                    }
                    id
                  }
                }
              }
            `}
            variables={{ case_manager_id: caseManagerId || "not-set" }}
            SuccessComponent={(props: ApplicationConfigsGQLResponse) => {
              const riskLevels =
                idx(props, (_) => _.application.risk_levels) || [];
              const allCaseManagers =
                idx(props, (_) => _.all_case_managers.case_manager) || [];
              const meCaseManager = idx(props, (_) => _.me.case_manager);
              const isCaseManager = !!meCaseManager;
              const caseManagers =
                idx(props, (_) => _.case_managers.case_manager) || [];
              const caseManager = caseManagers.find(
                ({ id }) => id === caseManagerId
              );

              // Note: This will show as selected for the case when none is selected simply by being first, not ideal
              const caseManagersSelectable = [
                {
                  id: "all",
                  name: { first: "All", last: "" },
                } as CaseManager,
              ].concat(allCaseManagers);

              // for case-managers, if caseManager isn't defined,
              //  default redirect them to themselves
              if (
                isCaseManager &&
                !caseManager &&
                (!selectedRiskLevelString || selectedRiskLevelString === "-")
              ) {
                return (
                  <Redirect
                    to={`${baseUri}-/${meCaseManager.id}/status/active`}
                    noThrow
                  />
                );
              }

              if (riskLevels.length > 0 && !selectedRiskLevelString) {
                return (
                  <Redirect
                    to={`${baseUri}${riskLevelPath(riskLevels[0])}`}
                    noThrow
                  />
                );
              }

              const showActive =
                status === "inactive" || selectedRiskLevelString === "inactive"
                  ? false
                  : true;

              const riskLevelTabs =
                BUCKET_PLACEHOLDER === selectedRiskLevelString
                  ? []
                  : mapRiskLevelsAsLinkedTabs(
                      baseUri,
                      selectedRiskLevelString,
                      riskLevels
                    );

              const { activePath, inactivePath } = getcaseLoadCmUrls(
                path,
                selectedRiskLevelString,
                caseManagerId
              );

              const activeTab = {
                value: "Active",
                key: "active",
                linkTo: activePath,
                selected: showActive,
              };

              const inactiveTab = {
                value: "Inactive",
                key: `inactive`,
                linkTo: inactivePath,
                selected: !showActive,
              };

              const tabs =
                riskLevelTabs.length > 0
                  ? [...riskLevelTabs, inactiveTab]
                  : [activeTab, inactiveTab];

              const selectedRiskLevel = riskLevels.find(
                (level) => riskLevelPath(level) === selectedRiskLevelString
              );
              if (
                !selectedRiskLevel &&
                BUCKET_PLACEHOLDER !== selectedRiskLevelString &&
                riskLevelStringExceptionsRiskLevelStrings.includes(
                  selectedRiskLevelString
                ) &&
                selectedRiskLevelString !== inactiveTab.key
              ) {
                console.error(
                  `Unknown risk level requested: "${selectedRiskLevelString}"`
                );
              }

              const baseUrl = `/${ROOT_PATH}/clients/`;

              return (selectedRiskLevel && selectedRiskLevelString) ||
                caseManager ||
                riskLevelStringExceptionsRiskLevelStrings.includes(
                  selectedRiskLevelString
                ) ? (
                <ParticipantListContents
                  baseUrl={baseUrl}
                  caseManager={caseManager}
                  titleTag={renderCaseManagerSelect(
                    navigate,
                    baseUrl,
                    riskLevels,
                    // selectedRiskLevelString,
                    status!,
                    caseManagersSelectable,
                    caseManagerId
                  )}
                  Link={Link}
                  riskLevels={riskLevels}
                  selectedRiskLevel={selectedRiskLevel}
                  tabs={tabs}
                  showActive={showActive}
                />
              ) : null;
            }}
          />
        )}
      </Location>
    );
  }
}
