/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type ParticipantDocumentsGraphqlQueryVariables = {
    readonly id: string;
};
export type ParticipantDocumentsGraphqlQueryResponse = {
    readonly me: ({
        readonly case_manager: ({
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
        }) | null;
    }) | null;
    readonly participants: ({
        readonly participant: ReadonlyArray<({
            readonly id: string;
            readonly documents: ReadonlyArray<({
                readonly id: string;
                readonly name: string;
                readonly mime_type: string;
                readonly filepath: string;
                readonly case_manager_name: string | null;
                readonly created_at: string;
            }) | null> | null;
        }) | null> | null;
    }) | null;
};
export type ParticipantDocumentsGraphqlQuery = {
    readonly response: ParticipantDocumentsGraphqlQueryResponse;
    readonly variables: ParticipantDocumentsGraphqlQueryVariables;
};



/*
query ParticipantDocumentsGraphqlQuery(
  $id: String!
) {
  me {
    case_manager {
      id
      name {
        first
        last
      }
    }
  }
  participants(id: $id) {
    participant {
      id
      documents {
        id
        name
        mime_type
        filepath
        case_manager_name
        created_at
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "String!",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "me",
            "storageKey": null,
            "args": null,
            "concreteType": "Me",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "case_manager",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CaseManager",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "name",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PersonName",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "first",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "last",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                }
            ]
        } as any),
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "participants",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                }
            ],
            "concreteType": "Pagination",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participant",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Participant",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "documents",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ParticipantDocument",
                            "plural": true,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "name",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "mime_type",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "filepath",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "case_manager_name",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "created_at",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "ParticipantDocumentsGraphqlQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v2 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "ParticipantDocumentsGraphqlQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v2 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "ParticipantDocumentsGraphqlQuery",
            "id": null,
            "text": "query ParticipantDocumentsGraphqlQuery(\n  $id: String!\n) {\n  me {\n    case_manager {\n      id\n      name {\n        first\n        last\n      }\n    }\n  }\n  participants(id: $id) {\n    participant {\n      id\n      documents {\n        id\n        name\n        mime_type\n        filepath\n        case_manager_name\n        created_at\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'c383423687852128f547463b8ded4b9d';
export default node;
