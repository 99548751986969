/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type LogEntryType = "CSVImportAddEventsError" | "CSVImportAddReminderTemplatesError" | "CSVImportDownloadComplete" | "CSVImportDownloadError" | "CSVImportStart" | "CSVImportSuccess" | "CreateReviewItemError" | "EventCreated" | "EventDeleted" | "EventUpdated" | "IntegrationEnd" | "IntegrationError" | "IntegrationStart" | "MessageOnNotificationSent" | "NotificationDelete" | "NotificationDeliveryFailure" | "NotificationDeliverySuccess" | "NotificationDeploy" | "NotificationMessageParticipant" | "NotificationQueue" | "ObligationCreated" | "ObligationDeleted" | "ObligationUpdated" | "ParticipantActiveStatusChanged" | "ParticipantDeleted" | "ParticipantLogin" | "ParticipantPhoneUpdated" | "ParticipantUpdated" | "ReviewItemForcedExpiry" | "ServiceProviderUpdated" | "StatsError" | "%future added value";
export type TimelineGqlQueryVariables = {
    readonly id?: string | null;
};
export type TimelineGqlQueryResponse = {
    readonly application: ({
        readonly activity_information: ReadonlyArray<({
            readonly key: string | null;
            readonly timeline_event_type: string | null;
        }) | null> | null;
    }) | null;
    readonly timeline: ({
        readonly timeline_entry: ReadonlyArray<({
            readonly id: string;
            readonly effective_timestamp: string;
            readonly type: LogEntryType;
            readonly details: any | null;
            readonly participant: ({
                readonly name: {
                    readonly first: string;
                    readonly last: string | null;
                };
            }) | null;
            readonly event: ({
                readonly id: string | null;
                readonly date: string | null;
                readonly activity: {
                    readonly id: string;
                    readonly title: {
                        readonly en: string | null;
                    };
                    readonly type: string;
                };
                readonly attended: string | null;
                readonly time_specified: boolean | null;
            }) | null;
            readonly notification: ({
                readonly id: string | null;
                readonly body: string | null;
                readonly time_deployed: string | null;
            }) | null;
        }) | null> | null;
    }) | null;
};
export type TimelineGqlQuery = {
    readonly response: TimelineGqlQueryResponse;
    readonly variables: TimelineGqlQueryVariables;
};



/*
query TimelineGqlQuery(
  $id: String
) {
  application {
    activity_information {
      key
      timeline_event_type
    }
    id
  }
  timeline(participant: $id) {
    timeline_entry {
      id
      effective_timestamp
      type
      details
      participant {
        name {
          first
          last
        }
        id
      }
      event {
        id
        date
        activity {
          id
          title {
            en
          }
          type
        }
        attended
        time_specified
      }
      notification {
        id
        body
        time_deployed
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "activity_information",
        "storageKey": null,
        "args": null,
        "concreteType": "ActivityMeta",
        "plural": true,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "key",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "timeline_event_type",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v2 = [
        ({
            "kind": "Variable",
            "name": "participant",
            "variableName": "id"
        } as any)
    ], v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "effective_timestamp",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "type",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "details",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "name",
        "storageKey": null,
        "args": null,
        "concreteType": "PersonName",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "first",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "last",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v8 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": null,
        "concreteType": "Event",
        "plural": false,
        "selections": [
            (v3 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "date",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "activity",
                "storageKey": null,
                "args": null,
                "concreteType": "Activity",
                "plural": false,
                "selections": [
                    (v3 /*: any*/),
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "title",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "i18nText",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "en",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    },
                    (v5 /*: any*/)
                ]
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "attended",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "time_specified",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v9 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "notification",
        "storageKey": null,
        "args": null,
        "concreteType": "Notification",
        "plural": false,
        "selections": [
            (v3 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "body",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "time_deployed",
                "args": null,
                "storageKey": null
            }
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "TimelineGqlQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "application",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Application",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/)
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "timeline",
                    "storageKey": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "Pagination",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "timeline_entry",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "TimelineEntry",
                            "plural": true,
                            "selections": [
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "participant",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Participant",
                                    "plural": false,
                                    "selections": [
                                        (v7 /*: any*/)
                                    ]
                                },
                                (v8 /*: any*/),
                                (v9 /*: any*/)
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "TimelineGqlQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "application",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Application",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v3 /*: any*/)
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "timeline",
                    "storageKey": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "Pagination",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "timeline_entry",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "TimelineEntry",
                            "plural": true,
                            "selections": [
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "participant",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Participant",
                                    "plural": false,
                                    "selections": [
                                        (v7 /*: any*/),
                                        (v3 /*: any*/)
                                    ]
                                },
                                (v8 /*: any*/),
                                (v9 /*: any*/)
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "TimelineGqlQuery",
            "id": null,
            "text": "query TimelineGqlQuery(\n  $id: String\n) {\n  application {\n    activity_information {\n      key\n      timeline_event_type\n    }\n    id\n  }\n  timeline(participant: $id) {\n    timeline_entry {\n      id\n      effective_timestamp\n      type\n      details\n      participant {\n        name {\n          first\n          last\n        }\n        id\n      }\n      event {\n        id\n        date\n        activity {\n          id\n          title {\n            en\n          }\n          type\n        }\n        attended\n        time_specified\n      }\n      notification {\n        id\n        body\n        time_deployed\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '291955713ab7d0d9cd825725772f13a0';
export default node;
