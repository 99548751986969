import { RequestNode, Variables } from "relay-runtime";

import fetcher from "../fetcher/fetcher";
import {
  FlightTrackers,
  GRAPHQL_FLIGHT_STATUS,
  TRACKED_GRAPHQL_CALL_TYPES,
} from "./graphqlFlightTracker";

// there are some mutations that we do not wish to track in flightTracker
// those can be added to this object literal
// so that they will be treated as if they were queries
const BLACKLISTED_MUTATIONS: { [key: string]: TRACKED_GRAPHQL_CALL_TYPES } = {
  messagesMarkMessagesAsReadMutation: TRACKED_GRAPHQL_CALL_TYPES.Query,
  messagesSendMessageMutation: TRACKED_GRAPHQL_CALL_TYPES.Query,
};

export const defaultGraphqlFetcher = async (
  operation: RequestNode,
  variables: Variables
) => {
  const { operationKind, name } = operation;

  const tracker = FlightTrackers.get(
    BLACKLISTED_MUTATIONS[name] || operationKind
  );

  tracker.start();

  let errorsResult;
  let fetcherResult = null;
  const variableKeys = Object.keys(variables).join(", ");

  try {
    fetcherResult = await fetcher(
      "/graphql/v1",
      {
        query: operation.text,
        variables,
      },
      "POST"
    );

    // Graphql top-level errors end up in errors
    //  (uncaught exceptions, thrown errors)
    const { errors: graphQlErrors } = fetcherResult;

    // Our standard for result + error messages in mutation
    //  Returning a result, and that object
    const { data = { first: {} } } = fetcherResult;
    const { result, description, errors } =
      data[Object.keys(data)[0]] ||
      ({} as {
        result: GRAPHQL_FLIGHT_STATUS;
        description?: string;
        errors?: string[];
      });

    errorsResult = summarizeErrors(graphQlErrors, result, description, errors);

    if (result === GRAPHQL_FLIGHT_STATUS.Skipped) {
      console.warn(
        `Got a "skipped" GraphQl result. ${operationKind} "${name}", variables: ${variableKeys}`
      );
    }

    // literally, the second least we can do - console.error that an error occurred
    if (graphQlErrors) {
      console.error(
        `Error in ${operationKind} "${name}", variables: ${variableKeys} `,
        JSON.stringify(graphQlErrors)
      );
    }
  } catch (error) {
    console.error(
      `Error while calling ${operationKind} "${name}", variables ${variableKeys}`,
      JSON.stringify(error)
    );
    errorsResult = ["Unknown or network error."];
  }

  tracker.stop(name, errorsResult);

  return fetcherResult;
};

const summarizeErrors = (
  graphQlErrors?: string[],
  result?: string,
  description?: string,
  errors?: string[]
): string[] | undefined => {
  if (
    result === GRAPHQL_FLIGHT_STATUS.Success ||
    result === GRAPHQL_FLIGHT_STATUS.Skipped
  ) {
    return undefined;
  }

  if (graphQlErrors) return graphQlErrors;

  if (description) {
    return !errors ? [description] : errors;
  }

  if (result === GRAPHQL_FLIGHT_STATUS.Failure) {
    // can't quite decide about this approach
    return (null as any) as string[];
  }

  return undefined;
};
