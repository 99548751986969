import moment, { Moment } from "moment";
import ModalWrapperHOC from "../../HOCs/ModalWrapperHOC";
import ModalWrapper from "../ModalWrapper";
import {
  FormDataBase,
  FormField,
  ValidationsType,
  ValidationItemStates,
} from "../types";
import { CarePlanObligationType } from "../../care-plan/CarePlanQuery";

export interface ObligationModalFormData extends FormDataBase {
  participant: FormField;
  activity: FormField;
  title: FormField;
  description: FormField;
  serviceProvider: FormField;
  roomNumber: FormField;
  startDate: FormField<Moment>;
  endDate: FormField<Moment> | FormField<null>;
  [key: string]: FormField<string | Moment | boolean | null>;
}

class AddObligationModalWrapper extends ModalWrapper<
  ObligationModalFormData,
  CarePlanObligationType
> {
  static formState = getObligationModalStatic(null);

  componentDidMount() {
    // TODO: We need to cleanup this `static` vs. "change in this here cdm instance method"

    // on remount, refresh the static class prop formState
    // handles the two possible cases of receiving a specific obligation (update)
    // or not receiving a specific obligation (add)
    const { updateObject: obligation } = this.props;
    if (obligation) {
      AddObligationModalWrapper.formState = getObligationModalStatic(
        obligation
      );
    } else {
      AddObligationModalWrapper.formState = getObligationModalStatic(null);
    }
  }
}

function getObligationModalStatic(obligation: CarePlanObligationType | null) {
  return {
    modalFormData: {
      title: {
        label: "Title",
        value: obligation && obligation.title ? obligation.title.en : "",
      },
      description: {
        label: "Description",
        value:
          obligation && obligation.description ? obligation.description.en : "",
      },
      serviceProvider: {
        label: "Where",
        value:
          obligation && obligation.service_provider
            ? obligation.service_provider.id
            : "",
        text:
          obligation && obligation.service_provider
            ? obligation.service_provider.title
            : "",
      },
      roomNumber: {
        label: "Room/Dept/Suite",
        value: obligation ? obligation.sub_address_for_event : "",
      },
      startDate: {
        label: "Start Date",
        dateFormat: "dddd, MMMM Do YYYY",
        value: obligation && obligation.start ? moment(obligation.start) : null,
      },
      endDate: {
        label: "End Date",
        dateFormat: "dddd, MMMM Do YYYY",
        value: obligation && obligation.end ? moment(obligation.end) : null,
      },
    } as ObligationModalFormData,
    validations: {
      // n.b. title isn't validated, it's allowed to be empty
      title: {
        state: ValidationItemStates.valid,
        errors: [] as string[],
      },
      description: {
        state: ValidationItemStates.valid,
        errors: [] as string[],
      },
      serviceProvider: {
        state:
          obligation && obligation.service_provider
            ? ValidationItemStates.valid
            : ValidationItemStates.pristine,
        errors: [] as string[],
      },
      roomNumber: {
        state: ValidationItemStates.valid,
        errors: [] as string[],
      },
      startDate: {
        state: ValidationItemStates.valid,
        errors: [] as string[],
      },
      endDate: { state: ValidationItemStates.valid, errors: [] as string[] },
    } as ValidationsType,
    attemptedSubmission: false,
    secondPage: false,
    secondPageMessageWarning: false,
    showModal: true,
    showButton: false,
  };
}

// oof, not purty way to pass things through
export default (handleModalClose?: () => void) =>
  ModalWrapperHOC(AddObligationModalWrapper, {
    modalWrapperHOCControlsOpenState: false,
    handleModalClose,
  });
