import React, { useEffect } from "react";
import { Booking } from "../../graphql-types";
import { CourtMemoPrintContainer } from "../prospects/CourtMemoForm";

interface Props {
  booking: Booking;
}

export default function PrintCourtMemo(props: Props) {
  const { booking } = props;

  useEffect(() => {
    document.title = `COURT MEMO FORM ${booking.id}`;

    setTimeout(() => {
      window.print();
    }, 2000);
  });
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <CourtMemoPrintContainer booking={booking} />
    </div>
  );
}
