/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type BucketId = "DataInputChange" | "FailureToAppear" | "NoAttendanceData" | "NoAttendanceIntegrationData" | "NoFutureCourtDate" | "NoFutureEvents" | "OneYearAnniversary" | "RecentDataInput" | "StaleDataInput" | "SupervisionDateReview" | "UpcomingUnreviewedCourtDate" | "%future added value";
export type WorkflowScreenGqlQueryVariables = {};
export type WorkflowScreenGqlQueryResponse = {
    readonly application: ({
        readonly dashboard_buckets: ReadonlyArray<({
            readonly key: BucketId;
            readonly title: string | null;
        }) | null> | null;
    }) | null;
    readonly me: ({
        readonly case_manager: ({
            readonly id: string;
        }) | null;
    }) | null;
    readonly all_case_managers: ({
        readonly case_manager: ReadonlyArray<({
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
            readonly id: string;
        }) | null> | null;
    }) | null;
};
export type WorkflowScreenGqlQuery = {
    readonly response: WorkflowScreenGqlQueryResponse;
    readonly variables: WorkflowScreenGqlQueryVariables;
};



/*
query WorkflowScreenGqlQuery {
  application {
    dashboard_buckets {
      key
      title
    }
    id
  }
  me {
    case_manager {
      id
    }
  }
  all_case_managers: case_managers {
    case_manager {
      name {
        first
        last
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "dashboard_buckets",
        "storageKey": null,
        "args": null,
        "concreteType": "DashboardBucket",
        "plural": true,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "key",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "title",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "case_manager",
                "storageKey": null,
                "args": null,
                "concreteType": "CaseManager",
                "plural": false,
                "selections": [
                    (v1 /*: any*/)
                ]
            }
        ]
    } as any), v3 = ({
        "kind": "LinkedField",
        "alias": "all_case_managers",
        "name": "case_managers",
        "storageKey": null,
        "args": null,
        "concreteType": "Pagination",
        "plural": false,
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "case_manager",
                "storageKey": null,
                "args": null,
                "concreteType": "CaseManager",
                "plural": true,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "name",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PersonName",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "first",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "last",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    },
                    (v1 /*: any*/)
                ]
            }
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "WorkflowScreenGqlQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "application",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Application",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/)
                    ]
                },
                (v2 /*: any*/),
                (v3 /*: any*/)
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "WorkflowScreenGqlQuery",
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "application",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Application",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/)
                    ]
                },
                (v2 /*: any*/),
                (v3 /*: any*/)
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "WorkflowScreenGqlQuery",
            "id": null,
            "text": "query WorkflowScreenGqlQuery {\n  application {\n    dashboard_buckets {\n      key\n      title\n    }\n    id\n  }\n  me {\n    case_manager {\n      id\n    }\n  }\n  all_case_managers: case_managers {\n    case_manager {\n      name {\n        first\n        last\n      }\n      id\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'b37ecf2bed3b18ef2223bbefbba10e95';
export default node;
