/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type ClassGraphqlQueryVariables = {};
export type ClassGraphqlQueryResponse = {
    readonly group_classes: ReadonlyArray<({
        readonly id: string;
        readonly participants: ReadonlyArray<{
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
        }>;
        readonly start_date: string;
        readonly end_date: string;
        readonly exclude_dates: ReadonlyArray<string>;
        readonly service_provider: {
            readonly id: string | null;
            readonly title: string | null;
        };
        readonly activity: {
            readonly id: string;
            readonly title: {
                readonly en: string | null;
            };
        };
        readonly days_of_week: ReadonlyArray<string>;
    }) | null> | null;
    readonly service_providers: ({
        readonly service_provider: ReadonlyArray<({
            readonly id: string | null;
            readonly address: string | null;
            readonly phone: string | null;
            readonly sub_address_for_event: string | null;
            readonly title: string | null;
        }) | null> | null;
    }) | null;
    readonly activities: ({
        readonly activity: ReadonlyArray<({
            readonly id: string;
            readonly type: string;
            readonly title: {
                readonly en: string | null;
            };
        }) | null> | null;
    }) | null;
    readonly participants: ({
        readonly participant: ReadonlyArray<({
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
        }) | null> | null;
    }) | null;
};
export type ClassGraphqlQuery = {
    readonly response: ClassGraphqlQueryResponse;
    readonly variables: ClassGraphqlQueryVariables;
};



/*
query ClassGraphqlQuery {
  group_classes {
    id
    participants {
      id
      name {
        first
        last
      }
    }
    start_date
    end_date
    exclude_dates
    service_provider {
      id
      title
    }
    activity {
      id
      title {
        en
      }
    }
    days_of_week
  }
  service_providers {
    service_provider {
      id
      address
      phone
      sub_address_for_event
      title
    }
  }
  activities {
    activity {
      id
      type
      title {
        en
      }
    }
  }
  participants(active_clients_only: true) {
    participant {
      id
      name {
        first
        last
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v1 = [
        (v0 /*: any*/),
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "name",
            "storageKey": null,
            "args": null,
            "concreteType": "PersonName",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "first",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "last",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "title",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "title",
        "storageKey": null,
        "args": null,
        "concreteType": "i18nText",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "en",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v4 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "group_classes",
            "storageKey": null,
            "args": null,
            "concreteType": "GroupClass",
            "plural": true,
            "selections": [
                (v0 /*: any*/),
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participants",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Participant",
                    "plural": true,
                    "selections": (v1 /*: any*/)
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "start_date",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "end_date",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "exclude_dates",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "service_provider",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ServiceProvider",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v2 /*: any*/)
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activity",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Activity",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v3 /*: any*/)
                    ]
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "days_of_week",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any),
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "service_providers",
            "storageKey": null,
            "args": null,
            "concreteType": "Pagination",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "service_provider",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ServiceProvider",
                    "plural": true,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "address",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "phone",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "sub_address_for_event",
                            "args": null,
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ]
                }
            ]
        } as any),
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "activities",
            "storageKey": null,
            "args": null,
            "concreteType": "Pagination",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activity",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Activity",
                    "plural": true,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "type",
                            "args": null,
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ]
                }
            ]
        } as any),
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "participants",
            "storageKey": "participants(active_clients_only:true)",
            "args": [
                {
                    "kind": "Literal",
                    "name": "active_clients_only",
                    "value": true
                }
            ],
            "concreteType": "Pagination",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participant",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Participant",
                    "plural": true,
                    "selections": (v1 /*: any*/)
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "ClassGraphqlQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": (v4 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "ClassGraphqlQuery",
            "argumentDefinitions": [],
            "selections": (v4 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "ClassGraphqlQuery",
            "id": null,
            "text": "query ClassGraphqlQuery {\n  group_classes {\n    id\n    participants {\n      id\n      name {\n        first\n        last\n      }\n    }\n    start_date\n    end_date\n    exclude_dates\n    service_provider {\n      id\n      title\n    }\n    activity {\n      id\n      title {\n        en\n      }\n    }\n    days_of_week\n  }\n  service_providers {\n    service_provider {\n      id\n      address\n      phone\n      sub_address_for_event\n      title\n    }\n  }\n  activities {\n    activity {\n      id\n      type\n      title {\n        en\n      }\n    }\n  }\n  participants(active_clients_only: true) {\n    participant {\n      id\n      name {\n        first\n        last\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'de16a4233e224728fbb52d866eebca6e';
export default node;
