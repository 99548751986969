import * as React from "react";
import moment from "moment-timezone";

import { ActivityMeta } from "../../lib/graphql/flowTypes";
import styles from "./Timeline.module.scss";
import { TimelineEvent } from "./TimelineEvent";
import { TimelineTimelineEntryType } from "./TimelineGql";
import { getDateForTimelineEvent } from "./TimelineUtil";

export type TimelineProps = {
  timelineEvents: Array<TimelineTimelineEntryType>;
  eventTypes?: Array<ActivityMeta>;
  hideNotifications?: boolean;
  eventAttendanceTitles: { [key: string]: string };
};

const now = moment();

type splitResults<T> = { hit: Array<T>; miss: Array<T> };
type splitOnFunc = <T>(
  items: Array<T>,
  comparator: (item: T) => boolean
) => splitResults<T>;

const singlePassSplitOn: splitOnFunc = function <T>(
  items: Array<T>,
  comparator: (item: T) => boolean
): splitResults<T> {
  const hit: Array<T> = [] as Array<T>;
  const miss: Array<T> = [];

  items.forEach((item) => {
    if (comparator(item)) hit.push(item);
    else miss.push(item);
  });
  return { hit, miss };
};

// Avoids destructuring errors
const minimumTimelineEntry: { [key: string]: any } = {
  event: { activity: { title: "" } },
};

const cleanTimelineEvents = (
  items: Array<TimelineTimelineEntryType>
): TimelineTimelineEntryType[] =>
  items.map((item) => {
    const newItem = Object.assign(
      {},
      minimumTimelineEntry,
      item
    ) as TimelineTimelineEntryType & { [key: string]: any };
    // ensure that nulls don't overwrite values (top-level keys only)
    Object.keys(minimumTimelineEntry).forEach((key) => {
      if (newItem[key] == null) newItem[key] = minimumTimelineEntry[key];
    });
    return newItem as TimelineTimelineEntryType;
  });

const sortTimelineEvents = (
  events: TimelineTimelineEntryType[]
): TimelineTimelineEntryType[] => {
  const timelineEvents = events.slice();
  timelineEvents.sort((a, b) => {
    const dateA = getDateForTimelineEvent(a);
    const dateB = getDateForTimelineEvent(b);
    if (dateA === dateB) return 0;
    else return moment(dateA).isSameOrBefore(moment(dateB)) ? 1 : -1;
  });

  return timelineEvents;
};

/**
 * We're getting some duplicate notification types, this will deduplicate them
 *  (So that until they are migrated out, we still display nicely)
 *
 */
const dedupTimelineNotificationEvents = (
  events: TimelineTimelineEntryType[]
): TimelineTimelineEntryType[] => {
  const getId = (event: TimelineTimelineEntryType) => {
    const notificationId = event.notification ? event.notification.id : null;
    const name = event.participant ? event.participant.name : ({} as any);
    const eventId = event.event ? event.event.id : null;

    // only handling cases where we have a notification
    return notificationId
      ? `${eventId}-${notificationId}-${name.first}-${name.last}`
      : null;
  };

  const grouped = events.reduce((result: any, event) => {
    const id = getId(event);

    if (!id) return result;

    if (!result[id]) result[id] = { entries: [] };
    result[id].entries.push(event);
    return result;
  }, {});

  return events.filter((event) => {
    const id = getId(event);
    if (!id) return true;

    const { entries } = grouped[id];
    if (!entries || entries.length !== 2) return true;

    return /deploy/i.test(event.type);
  });
};

export class Timeline extends React.PureComponent<TimelineProps> {
  render() {
    const {
      timelineEvents: timelineEventsProp,
      eventTypes,
      eventAttendanceTitles,
    } = this.props;

    // It comes sorted from the backend but on the effective_timestamp
    //  we display the event date (or notification.time_deploy)
    const cleanEvents = cleanTimelineEvents(timelineEventsProp);
    const timelineEvents = sortTimelineEvents(cleanEvents).filter(
      (event) => !this.props.hideNotifications || !event.notification
    );
    const dedupedNotificationEvents = dedupTimelineNotificationEvents(
      timelineEvents
    );

    const {
      hit: before,
      miss: after,
    } = singlePassSplitOn(dedupedNotificationEvents, (item) =>
      moment(getDateForTimelineEvent(item)).isSameOrBefore(now)
    );

    const hasBeforeAndAfter = after.length > 0 && before.length > 0;

    return (
      <div className="timeline">
        {after.map(TimelineEvent(false, eventAttendanceTitles, eventTypes))}
        {hasBeforeAndAfter && (
          <div className={styles.nowContainer}>
            <span className={styles.nowTitle}>Now</span>
            <hr className={styles.nowLine} />
          </div>
        )}
        {before.map(TimelineEvent(true, eventAttendanceTitles, eventTypes))}
      </div>
    );
  }
}
