/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type MutationResult = "failure" | "skipped" | "success" | "%future added value";
export type reviewItemsReviewMutationVariables = {
    readonly id: string;
    readonly reviewed: boolean;
    readonly expire?: boolean | null;
};
export type reviewItemsReviewMutationResponse = {
    readonly reviewReviewItem: ({
        readonly result: MutationResult;
        readonly description: string | null;
        readonly review_item: ({
            readonly id: string;
            readonly details: any | null;
            readonly expired: boolean;
            readonly reviewed: boolean;
            readonly reviewed_by_id: string | null;
            readonly reviewed_by_case_manager: ({
                readonly id: string;
                readonly name: {
                    readonly first: string;
                    readonly last: string | null;
                };
            }) | null;
            readonly type: string;
        }) | null;
    }) | null;
};
export type reviewItemsReviewMutation = {
    readonly response: reviewItemsReviewMutationResponse;
    readonly variables: reviewItemsReviewMutationVariables;
};



/*
mutation reviewItemsReviewMutation(
  $id: String!
  $reviewed: Boolean!
  $expire: Boolean
) {
  reviewReviewItem(id: $id, reviewed: $reviewed, expire: $expire) {
    result
    description
    review_item {
      id
      details
      expired
      reviewed
      reviewed_by_id
      reviewed_by_case_manager {
        id
        name {
          first
          last
        }
      }
      type
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "reviewed",
            "type": "Boolean!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "expire",
            "type": "Boolean",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "reviewReviewItem",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "expire",
                    "variableName": "expire"
                },
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                },
                {
                    "kind": "Variable",
                    "name": "reviewed",
                    "variableName": "reviewed"
                }
            ],
            "concreteType": "ReviewReviewItem",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "result",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "review_item",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ReviewItem",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "details",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "expired",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "reviewed",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "reviewed_by_id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "reviewed_by_case_manager",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CaseManager",
                            "plural": false,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "name",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "PersonName",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "first",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "last",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "type",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "reviewItemsReviewMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v2 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "reviewItemsReviewMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v2 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "reviewItemsReviewMutation",
            "id": null,
            "text": "mutation reviewItemsReviewMutation(\n  $id: String!\n  $reviewed: Boolean!\n  $expire: Boolean\n) {\n  reviewReviewItem(id: $id, reviewed: $reviewed, expire: $expire) {\n    result\n    description\n    review_item {\n      id\n      details\n      expired\n      reviewed\n      reviewed_by_id\n      reviewed_by_case_manager {\n        id\n        name {\n          first\n          last\n        }\n      }\n      type\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '11824ec930097903fa40093d94228c8d';
export default node;
