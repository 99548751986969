import React, { useEffect } from "react";
import { BookingGroup } from "../../graphql-types";
import { BookingGroupPanelExpanded } from "../bookings/BookingGroupContainer";
import moment from "moment-timezone";

interface Props {
  bookingGroup: BookingGroup;
}

export default function PrintTransmittal(props: Props) {
  const { bookingGroup } = props;

  useEffect(() => {
    const date = moment(bookingGroup.created_at).format("dddd, MMMM DD, YYYY");
    document.title = `OR TRANSMITTAL SHEET FOR ${date}`;

    setTimeout(() => {
      window.print();
    }, 2000);
  });
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          maxWidth: "1100px",
        }}
      >
        <BookingGroupPanelExpanded bookingGroup={bookingGroup} />
      </div>
    </div>
  );
}
