import * as React from "react";
import classnames from "classnames";

import { getStatusBulmaColor } from "../../lib/util";
import { KeyedString } from "../../lib/graphql";
import { TableColumn, TableData } from "../table/tableTypes";
import SortableTable from "../table/SortableTable";
import {
  ColumnHeaderMapping,
  NameComponentType,
  ParticipantItem,
  TableColumnKeyType,
} from "./ParticipantListTypes";
import {
  getKnownColumns,
  KnownColumnPassthroughOptions,
  getKnownColumnsArgs,
  AlwaysableTableColumn,
} from "./ParticipantListTableColumns";

export interface ParticipantListTablePassThroughProps<
  TItem extends ParticipantItem
> extends KnownColumnPassthroughOptions<TItem> {
  columnsToDisplay?: string[];
  nameComponent: NameComponentType;
  columnNameRemaps?: ColumnHeaderMapping;
}

// all the shared keys of ParticipantListTablePassThroughProps
export type ParticipantListTablePassThroughPropsAllKeys = {
  [P in keyof Required<ParticipantListTablePassThroughProps<any>>]: any;
};

export interface ParticipantListTableProps<TItem extends ParticipantItem>
  extends ParticipantListTablePassThroughProps<TItem> {
  className?: string;
  items: TableData<TItem>;
  riskLevels: Array<KeyedString>;
}

export class ParticipantListTable<
  TItem extends ParticipantItem = ParticipantItem
> extends React.PureComponent<ParticipantListTableProps<TItem>> {
  render() {
    const {
      additionalColumns,
      columnsToDisplay,
      complianceOptions,
      items,
      riskLevels,
      columnNameRemaps,
    } = this.props;
    const columns = this._getColumns(
      items,
      columnsToDisplay,
      additionalColumns,
      columnNameRemaps
    );

    if (this._hasItems()) return <p>No records found.</p>;
    if (columns.length === 0) {
      console.error(
        "No columns found - could occur if returned columns are not known."
      );
      return null;
    }

    const className = classnames(
      "table is-striped participantTable",
      this.props.className
    );

    return (
      <SortableTable<TItem, string>
        className={className}
        columns={columns}
        data={this._mapData(items, riskLevels, complianceOptions)}
      />
    );
  }

  _hasItems() {
    const { items } = this.props;
    return !items || items.length === 0;
  }
  _getColumns = (
    data: TableData<TItem>,
    columnsToDisplay?: string[],
    additionalColumns?: AlwaysableTableColumn<TItem>[],
    columnNameRemaps?: ColumnHeaderMapping
  ): Array<TableColumn<TItem, TableColumnKeyType>> => {
    if (typeof data[0] === "string") {
      throw new Error("Expected object, got a string in DashboardTable data.");
    }
    const first = data[0];
    if (!first) {
      return [
        {
          sortable: false,
          key: "",
          id: "",
          defaultSorting: "",
          header: "",
          render: () => null,
        },
      ];
    }
    const dataKeys = Object.keys(first);

    const {
      nameComponent: Name,
      clientDisplayTerm,
      complianceOptions,
      supportsProgramCompliance,
      whatIsComplianceCalled,
      whatIsRiskLevelCalled,
      startDateHumanizeCutoff,
      urlBuilder,
    } = this.props;

    const getColumnArgs: getKnownColumnsArgs = {
      Name,
      additionalColumns,
      columnsToDisplay,
      clientDisplayTerm,
      colorMapper: getStatusBulmaColor,
      complianceOptions,
      participants: data,
      whatIsComplianceCalled,
      whatIsRiskLevelCalled,
      supportsProgramCompliance,
      startDateHumanizeCutoff,
      urlBuilder,
      columnNameRemaps: columnNameRemaps || {},
    };

    // Any additional columns without a position, go first
    const knownColumns = (additionalColumns || [])
      .filter(({ at }) => !at)
      .concat(getKnownColumns<TItem>(getColumnArgs));

    // Then we mix in any columns that have a position, at their requested position
    //  (applied in order as they were passed in)
    if (additionalColumns) {
      additionalColumns.forEach(({ at, ...columnBase }) => {
        // Handled the no 'at' first columns above
        if (!at || !columnBase) return;
        else {
          // we do some filters below, so it's weird if this is specified to get inserted at
          //  a specific location and then doesn't appear, so "insist" that it pass our filtering below
          const column = {
            always: true,
            ...columnBase,
          };
          // Not in columnsToDisplay? Make it so
          if (
            !!columnsToDisplay &&
            !columnsToDisplay.includes((columnBase as any).key)
          ) {
            columnsToDisplay.push((columnBase as any).key);
          }
          knownColumns.splice(at, 0, column);
        }
      });
    }

    return (
      knownColumns
        // `always` items are always present regardless of data
        .filter(
          ({ always, key }) => always || dataKeys.indexOf(key as string) !== -1
        )
        // if the caller passed in a subselection, respect that
        // we're filtering again, we expect list of columns to be short, so the inefficiency /shrug
        .filter(
          ({ id }) =>
            !columnsToDisplay || columnsToDisplay.includes(id as string)
        )
        // map down to column
        .map(
          ({ always, ...rest }) =>
            ({ ...rest } as TableColumn<TItem, TableColumnKeyType>)
        )
    );
  };

  _mapData = (
    data: TableData<TItem>,
    riskLevels: Array<KeyedString>,
    complianceOptions?: Array<KeyedString>
  ): TableData<TItem> => {
    return data.map(
      // `...rest` Fixed recently, until then, cast it to any: https://github.com/Microsoft/TypeScript/pull/28312
      ({ risk_level, compliance, ...rest }: any) =>
        ({
          risk_level: (
            riskLevels.find(({ key }) => key === risk_level) || {
              value: undefined,
            }
          ).value,
          compliance: !complianceOptions
            ? undefined
            : (
                complianceOptions.find(({ key }) => key === compliance) || {
                  value: undefined,
                }
              ).value,
          ...rest,
        } as TItem)
    );
  };
}
