import graphql from "babel-plugin-relay/macro";

import { DashboardBucket, CaseManager } from "../../shared/lib/graphql";

export interface WorkflowScreenGqlResponse {
  application: {
    dashboard_buckets: DashboardBucket[];
  };
  me: {
    case_manager: {
      id: string;
    };
  };
  all_case_managers: {
    case_manager: CaseManager[];
  };
}

export const WorkflowScreenGql = graphql`
  query WorkflowScreenGqlQuery {
    application {
      dashboard_buckets {
        key
        title
      }
    }
    me {
      case_manager {
        id
      }
    }
    all_case_managers: case_managers {
      case_manager {
        name {
          first
          last
        }
        id
      }
    }
  }
`;
