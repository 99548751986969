import * as React from "react";
import { wrapComponent } from "../lib/types";

// TODO: Add raf, and such
export interface WithTimerProps {
  clearTimeout: (handle: number) => void;
  debounce: (cb: () => void, wait: number) => void;
  setInterval: (cb: () => void, wait: number) => number;
  setTimeout: (cb: () => void, wait: number) => number;
}

export function withTimer<TCall, TWrappedProps extends WithTimerProps>(
  WrappedComponent: React.ComponentType<TWrappedProps>,
  windowToUse: Window = window
): React.ComponentClass<TCall> {
  const C = class withTimeout extends React.PureComponent<TCall> {
    protected _timeouts: number[] = [];
    protected _intervals: number[] = [];
    protected _debounces: { [key: string]: number } = {};

    componentWillUnmount() {
      if (this._timeouts) {
        this._timeouts.forEach((timeout) => {
          windowToUse.clearTimeout(timeout);
        });
        this._timeouts = null as any;
      }
      if (this._intervals) {
        this._intervals.forEach((interval) => {
          windowToUse.clearInterval(interval);
        });
        this._intervals = null as any;
      }
    }

    protected _clearTimeout = (handle: number) => {
      if (!this._timeouts) return;
      const toClearIndex = this._timeouts.findIndex((t) => t === handle);
      if (toClearIndex !== -1) {
        this._timeouts.splice(toClearIndex, 1);
      }
      windowToUse.clearTimeout(handle);
    };

    protected _debounce = (cb: () => void, wait: number, key = "DEFAULT") => {
      if (this._debounces[key]) this._clearTimeout(this._debounces[key]);
      this._debounces[key] = this._setTimeout(cb, wait);
    };

    protected _setTimeout = (cb: () => void, wait: number) => {
      const result = windowToUse.setTimeout(cb, wait);
      this._timeouts.push(result);
      return result;
    };

    protected _setInterval = (cb: () => void, wait: number) => {
      const result = windowToUse.setInterval(cb, wait);
      this._intervals.push(result);
      return result;
    };

    render() {
      return (
        <WrappedComponent
          clearTimeout={this._clearTimeout}
          debounce={this._debounce}
          setTimeout={this._setTimeout}
          setInterval={this._setInterval}
          {...this.props}
        />
      );
    }
  };

  return wrapComponent(C, WrappedComponent, "withTimer");
}
