import * as React from "react";
import { Input as RCEInput, Input } from "react-chat-elements";
import { Form } from "react-bulma-components";
import { Button } from "antd";
import { MutationResult } from "../../shared/lib/graphql";
import { SendMessageInnerResult } from "../../actions/messages";
import {
  MessagingStates,
  smsOptions,
} from "../../shared/util/determine_sms_status";
import "emoji-mart/css/emoji-mart.css";
import { Picker, Emoji } from "emoji-mart";

interface GetDisabledReasonParams {
  appEnabled: boolean;
  participantIsActive: boolean;
  smsState: MessagingStates;
  disabled?: boolean;
}

function getDisabledReasonIfCanChatWithClient({
  appEnabled,
  participantIsActive,
  smsState,
  disabled = false,
}: GetDisabledReasonParams): string {
  if (disabled) {
    return "because chat is disabled";
  }

  if (!participantIsActive) {
    return "because this account is not active";
  }

  if (appEnabled) {
    return "";
  }

  const readableSMSState: string | undefined = smsOptions.find(
    (o) => o.key === smsState
  )!.value;

  if (smsState !== MessagingStates.MESSAGING_ON) {
    return `because SMS is ${readableSMSState}`;
  }

  return "";
}

export interface ChatInputProps {
  participantName: string;
  participantLanguage: string;
  participantIsActive: boolean;
  smsState: MessagingStates;
  isGroupChat: boolean;
  sendMessage: (text: string) => Promise<SendMessageInnerResult>;
  disabled?: boolean;
  appEnabled: boolean;
}

interface ChatInputState {
  newMessage: string;
  showEmoji: boolean;
}

export class ChatInput extends React.PureComponent<
  ChatInputProps,
  ChatInputState
> {
  inputRef: React.RefObject<Input> = undefined as any;

  constructor(props: ChatInputProps) {
    super(props);

    this.inputRef = React.createRef();

    this.state = {
      newMessage: "",
      showEmoji: false,
    };
  }

  private addEmoji = (emoji: any) => {
    const node = this.inputRef.current as any;

    const { native } = emoji;

    this.setState((state) => ({
      newMessage: state.newMessage + native,
    }));

    // such hack, react-chat-elements forces us to use a ref
    // and this is a nasty workaround #dealwithitjeffrey
    if (!node) {
      return;
    }

    node.setState((state: any) => ({
      value: state.value + native,
    }));
  };

  private sendMessage = async () => {
    const { sendMessage } = this.props;
    if (this.state.newMessage.trim().length === 0) return;
    const { result } = await sendMessage(this.state.newMessage);
    if (result === MutationResult.Success) {
      (this.inputRef.current as any).clear();
      this.setState({ newMessage: "" });
    }
  };

  private toggleEmojiPicker = () => {
    this.setState((state) => ({
      showEmoji: !state.showEmoji,
    }));
  };

  private inputKeyPress = (
    evt: React.KeyboardEvent<HTMLInputElement>
  ): boolean | void => {
    if (evt.charCode === 13) {
      if (this.state.newMessage.trim().length === 0) return; // don't send empty messages Omar
      evt.preventDefault();
      this.sendMessage();
      return false;
    }
  };

  private inputKeyUp = (
    evt: React.KeyboardEvent<HTMLInputElement>
  ): boolean | void => {
    if (evt.charCode !== 13) {
      this.setState({ newMessage: (evt.target as HTMLInputElement).value });
    }
  };

  render() {
    const {
      participantName,
      participantIsActive,
      smsState,
      disabled,
      isGroupChat,
      appEnabled,
    } = this.props;
    const disabledReason =
      !isGroupChat &&
      getDisabledReasonIfCanChatWithClient({
        appEnabled,
        participantIsActive,
        smsState,
        disabled,
      });

    const { showEmoji } = this.state;

    const recipientName = isGroupChat ? "the group" : participantName;

    return (
      <div style={{ marginTop: 10 }}>
        {disabledReason ? (
          <Form.Input
            disabled={true}
            placeholder={`You cannot chat with ${recipientName} ${disabledReason}`}
          />
        ) : (
          <RCEInput
            ref={this.inputRef as any /* ts/typings :facepalm: */}
            defaultValue=""
            multiline={true}
            placeholder={`Send a message to ${recipientName}`}
            className="chat-message-input"
            onKeyPress={this.inputKeyPress}
            onKeyUp={this.inputKeyUp}
            leftButtons={
              <div
                onClick={this.toggleEmojiPicker}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "5px",
                  padding: "5px",
                }}
              >
                <Emoji emoji=":smiley:" size={28} />
              </div>
            }
            rightButtons={
              <>
                <Button
                  type="primary"
                  onClick={this.sendMessage}
                  className="chat-message-send"
                >
                  Send
                </Button>
              </>
            }
            spellCheck={true}
          />
        )}
        {showEmoji ? (
          <Picker
            onSelect={this.addEmoji}
            style={{ position: "relative", bottom: "480px" }}
          />
        ) : null}
        <div>{`${this.state.newMessage.length}`} characters</div>
        {isGroupChat ? null : (
          <div className="has-text-info">
            {" "}
            Messages longer than 160 characters may be broken up into multiple
            texts if your client is only on SMS and not using the app
          </div>
        )}
      </div>
    );
  }
}
