/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type IdCardType = "license" | "state_id" | "unknown" | "%future added value";
export type MutationResult = "failure" | "skipped" | "success" | "%future added value";
export type PersonEyeColor = "black" | "blue" | "brown" | "gray" | "green" | "hazel" | "maroon" | "unknown" | "%future added value";
export type PersonHair = "bald" | "black" | "blonde" | "blue" | "brown" | "gray" | "green" | "orange" | "pink" | "purple" | "red" | "sandy" | "unknown" | "white" | "%future added value";
export type PersonRace = "AmericanIndianOrAlaskaNative" | "Asian" | "BlackOrAfricanAmerican" | "NativeHawaiianOrOtherPacificIslander" | "Unknown" | "White" | "%future added value";
export type PersonSex = "female" | "male" | "unknown" | "%future added value";
export type SmsMessageActor = "promise" | "twilio" | "%future added value";
export type participantsUpdateParticipantConsentMutationVariables = {
    readonly id: string;
    readonly sms_enabled?: boolean | null;
    readonly sms_disabled_by?: SmsMessageActor | null;
    readonly sms_consent?: boolean | null;
};
export type participantsUpdateParticipantConsentMutationResponse = {
    readonly updateParticipant: ({
        readonly participant: ({
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
            readonly case_manager: ({
                readonly id: string;
                readonly name: {
                    readonly first: string;
                    readonly last: string | null;
                };
            }) | null;
            readonly risk_level: string | null;
            readonly assessment: ({
                readonly score: string | null;
                readonly tool: string | null;
            }) | null;
            readonly language: string;
            readonly phone: ({
                readonly mobile: string | null;
                readonly home: string | null;
            }) | null;
            readonly status: string | null;
            readonly compliance: string | null;
            readonly events: ReadonlyArray<({
                readonly id: string | null;
                readonly activity: {
                    readonly id: string;
                    readonly title: {
                        readonly en: string | null;
                    };
                };
                readonly date: string | null;
            }) | null> | null;
            readonly preferred_name: string | null;
            readonly sms_enabled: boolean | null;
            readonly sms_disabled_by: SmsMessageActor | null;
            readonly sms_consent: boolean | null;
            readonly sms_can_enable: boolean | null;
            readonly is_active: boolean | null;
            readonly supervision_begin_date: string | null;
            readonly supervision_end_date: string | null;
            readonly auth_token: string | null;
            readonly created_at: string;
            readonly case_manager_id: string | null;
            readonly hispanic_or_latino: boolean | null;
            readonly race: PersonRace | null;
            readonly alcohol_monitoring: boolean | null;
            readonly gps_monitoring: boolean | null;
            readonly date_of_birth: string | null;
            readonly sex: PersonSex | null;
            readonly employer: ({
                readonly name: string | null;
            }) | null;
            readonly hair: PersonHair | null;
            readonly eye_color: PersonEyeColor | null;
            readonly weight: string | null;
            readonly height: string | null;
            readonly person_address: string | null;
            readonly place_of_birth: string | null;
            readonly person_id: ({
                readonly number: string | null;
                readonly type: IdCardType | null;
                readonly state: string | null;
            }) | null;
            readonly victims: ReadonlyArray<{
                readonly name: ({
                    readonly first: string | null;
                    readonly last: string | null;
                }) | null;
                readonly phone: string | null;
                readonly date_of_birth: string | null;
                readonly address: string | null;
                readonly sex: PersonSex | null;
            }> | null;
            readonly stay_away: boolean | null;
            readonly peaceful_contact: boolean | null;
            readonly case_number: string | null;
            readonly extra_equipment: string | null;
            readonly incident_notes: string | null;
            readonly offense_info: string | null;
            readonly record_source: string | null;
            readonly court_recommendation: string | null;
            readonly court_decision: string | null;
            readonly case_disposition: string | null;
            readonly home_phone_human_readable: string | null;
            readonly cell_phone_human_readable: string | null;
            readonly equipment_returned: ReadonlyArray<string | null> | null;
            readonly protective_order_notes: string | null;
            readonly probation_number: string | null;
        }) | null;
        readonly result: MutationResult;
        readonly description: string | null;
        readonly errors: ReadonlyArray<string | null> | null;
    }) | null;
};
export type participantsUpdateParticipantConsentMutation = {
    readonly response: participantsUpdateParticipantConsentMutationResponse;
    readonly variables: participantsUpdateParticipantConsentMutationVariables;
};



/*
mutation participantsUpdateParticipantConsentMutation(
  $id: String!
  $sms_enabled: Boolean
  $sms_disabled_by: SmsMessageActor
  $sms_consent: Boolean
) {
  updateParticipant(id: $id, sms_enabled: $sms_enabled, sms_consent: $sms_consent, sms_disabled_by: $sms_disabled_by) {
    participant {
      id
      name {
        first
        last
      }
      case_manager {
        id
        name {
          first
          last
        }
      }
      risk_level
      assessment {
        score
        tool
      }
      language
      phone {
        mobile
        home
      }
      status
      compliance
      events {
        id
        activity {
          id
          title {
            en
          }
        }
        date
      }
      preferred_name
      sms_enabled
      sms_disabled_by
      sms_consent
      sms_can_enable
      is_active
      supervision_begin_date
      supervision_end_date
      auth_token
      created_at
      case_manager_id
      hispanic_or_latino
      race
      alcohol_monitoring
      gps_monitoring
      date_of_birth
      sex
      employer {
        name
      }
      hair
      eye_color
      weight
      height
      person_address
      place_of_birth
      person_id {
        number
        type
        state
      }
      victims {
        name {
          first
          last
        }
        phone
        date_of_birth
        address
        sex
      }
      stay_away
      peaceful_contact
      case_number
      extra_equipment
      incident_notes
      offense_info
      record_source
      court_recommendation
      court_decision
      case_disposition
      home_phone_human_readable
      cell_phone_human_readable
      equipment_returned
      protective_order_notes
      probation_number
    }
    result
    description
    errors
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "sms_enabled",
            "type": "Boolean",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "sms_disabled_by",
            "type": "SmsMessageActor",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "sms_consent",
            "type": "Boolean",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "first",
            "args": null,
            "storageKey": null
        } as any),
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "last",
            "args": null,
            "storageKey": null
        } as any)
    ], v3 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "name",
        "storageKey": null,
        "args": null,
        "concreteType": "PersonName",
        "plural": false,
        "selections": (v2 /*: any*/)
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "date_of_birth",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "sex",
        "args": null,
        "storageKey": null
    } as any), v6 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "updateParticipant",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                },
                {
                    "kind": "Variable",
                    "name": "sms_consent",
                    "variableName": "sms_consent"
                },
                {
                    "kind": "Variable",
                    "name": "sms_disabled_by",
                    "variableName": "sms_disabled_by"
                },
                {
                    "kind": "Variable",
                    "name": "sms_enabled",
                    "variableName": "sms_enabled"
                }
            ],
            "concreteType": "NewParticipant",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participant",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Participant",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "case_manager",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CaseManager",
                            "plural": false,
                            "selections": [
                                (v1 /*: any*/),
                                (v3 /*: any*/)
                            ]
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "risk_level",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "assessment",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ParticipantAssessment",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "score",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "tool",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "language",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "phone",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PersonPhone",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "mobile",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "home",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "status",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "compliance",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "events",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Event",
                            "plural": true,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "activity",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Activity",
                                    "plural": false,
                                    "selections": [
                                        (v1 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "title",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "i18nText",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "en",
                                                    "args": null,
                                                    "storageKey": null
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "date",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "preferred_name",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "sms_enabled",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "sms_disabled_by",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "sms_consent",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "sms_can_enable",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "is_active",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "supervision_begin_date",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "supervision_end_date",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "auth_token",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "created_at",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "case_manager_id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "hispanic_or_latino",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "race",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "alcohol_monitoring",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "gps_monitoring",
                            "args": null,
                            "storageKey": null
                        },
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "employer",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ParticipantEmployer",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "name",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "hair",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "eye_color",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "weight",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "height",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "person_address",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "place_of_birth",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "person_id",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PersonId",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "number",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "type",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "state",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "victims",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ParticipantContact",
                            "plural": true,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "name",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "VictimName",
                                    "plural": false,
                                    "selections": (v2 /*: any*/)
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "phone",
                                    "args": null,
                                    "storageKey": null
                                },
                                (v4 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "address",
                                    "args": null,
                                    "storageKey": null
                                },
                                (v5 /*: any*/)
                            ]
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "stay_away",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "peaceful_contact",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "case_number",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "extra_equipment",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "incident_notes",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "offense_info",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "record_source",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "court_recommendation",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "court_decision",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "case_disposition",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "home_phone_human_readable",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "cell_phone_human_readable",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "equipment_returned",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "protective_order_notes",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "probation_number",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "result",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "errors",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "participantsUpdateParticipantConsentMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v6 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "participantsUpdateParticipantConsentMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v6 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "participantsUpdateParticipantConsentMutation",
            "id": null,
            "text": "mutation participantsUpdateParticipantConsentMutation(\n  $id: String!\n  $sms_enabled: Boolean\n  $sms_disabled_by: SmsMessageActor\n  $sms_consent: Boolean\n) {\n  updateParticipant(id: $id, sms_enabled: $sms_enabled, sms_consent: $sms_consent, sms_disabled_by: $sms_disabled_by) {\n    participant {\n      id\n      name {\n        first\n        last\n      }\n      case_manager {\n        id\n        name {\n          first\n          last\n        }\n      }\n      risk_level\n      assessment {\n        score\n        tool\n      }\n      language\n      phone {\n        mobile\n        home\n      }\n      status\n      compliance\n      events {\n        id\n        activity {\n          id\n          title {\n            en\n          }\n        }\n        date\n      }\n      preferred_name\n      sms_enabled\n      sms_disabled_by\n      sms_consent\n      sms_can_enable\n      is_active\n      supervision_begin_date\n      supervision_end_date\n      auth_token\n      created_at\n      case_manager_id\n      hispanic_or_latino\n      race\n      alcohol_monitoring\n      gps_monitoring\n      date_of_birth\n      sex\n      employer {\n        name\n      }\n      hair\n      eye_color\n      weight\n      height\n      person_address\n      place_of_birth\n      person_id {\n        number\n        type\n        state\n      }\n      victims {\n        name {\n          first\n          last\n        }\n        phone\n        date_of_birth\n        address\n        sex\n      }\n      stay_away\n      peaceful_contact\n      case_number\n      extra_equipment\n      incident_notes\n      offense_info\n      record_source\n      court_recommendation\n      court_decision\n      case_disposition\n      home_phone_human_readable\n      cell_phone_human_readable\n      equipment_returned\n      protective_order_notes\n      probation_number\n    }\n    result\n    description\n    errors\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '43afaca363f396be11a382cfbbd8e9f1';
export default node;
