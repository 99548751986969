import React, { ChangeEvent } from "react";
import { Form } from "react-bulma-components";
import { KeyedString } from "../../shared/lib/graphql/index";
import { ValidationItemStates } from "./types";
import { FormLabelWithToolTip } from "../../shared/components/elements/FormLabelWithToolTip";

type Props = {
  /**
   * Warning: Must match the key in the form or it will behave oddly
   */
  modalStateKey: string;
  label: String;
  validations: any;
  value: String;
  name: string;
  inputType?: string;
  attemptedSubmission: Boolean;
  handleModalFormInputChange: Function;
  options: KeyedString[];
  disabled?: Boolean;
  toolTipText?: string;
};

class ModalSelect extends React.PureComponent<Props> {
  componentDidMount() {
    const { value } = this.props;
    //if we already have a value for the modal, then no need
    if (value) {
      return;
    }

    // On load, set the state to the first option - since that is what it looks like is happening visible
    // Allow the user to just hit "continue" on a modal with the first value "set"
    const { modalStateKey, handleModalFormInputChange, options } = this.props;
    if (!options || options.length === 0) return;

    const initialSelectObj = this.buildFakeModalChange();

    if (!initialSelectObj) {
      console.info(
        `Couldn\'t build the initialSelectObj for a select in a form. ${modalStateKey} ${options.length}`
      );
    } else {
      handleModalFormInputChange(initialSelectObj);
    }
  }

  private buildFakeModalChange = (): object | null => {
    const { modalStateKey, inputType, name, options } = this.props;
    if (!options || options.length === 0) return null;

    const selectedOptions = [
      (options.map(({ key: value, value: text }) => ({
        text,
        value,
      })) as Array<{ text: string | undefined; value: string }>)[0],
    ];

    return {
      isFakeEvent: true,
      modalStateKey,
      currentTarget: {
        getAttribute: (attr: string): string | undefined => {
          if (attr !== "data-input-type") {
            console.error(
              "This is fake event inside modal forms and the `getAttribute` only supports 'data-input-type'"
            );
            return;
          } else {
            return inputType;
          }
        },
      },
      target: {
        name,
        value: options[0].key,
        selectedOptions,
      },
    };
  };

  render() {
    const {
      label,
      validations,
      value,
      name,
      inputType,
      attemptedSubmission,
      handleModalFormInputChange,
      options,
      toolTipText,
      disabled = false,
    } = this.props;

    return (
      <Form.Field>
        <FormLabelWithToolTip
          labelText={label as string}
          tipText={toolTipText}
        />
        <Form.Control>
          <Form.Select
            className="modal-select"
            name={name}
            value={value}
            disabled={disabled}
            data-input-type={inputType}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              handleModalFormInputChange(event);
            }}
          >
            {options.map(({ key, value }: KeyedString) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </Form.Select>
        </Form.Control>
        {validations[name].errors.length > 0 ? (
          <Form.Help color="danger">{validations[name].errors[0]}</Form.Help>
        ) : null}
        {validations[name].state === ValidationItemStates.pristine &&
        attemptedSubmission ? (
          <Form.Help color="danger">{"Field is required"}</Form.Help>
        ) : null}
      </Form.Field>
    );
  }
}

export default ModalSelect;
