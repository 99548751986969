import {
  Activity,
  BroadcastMessageRecipientType,
  KeyedString,
} from "../../shared/lib/graphql/index";
import {
  BroadcaseMessageCaseManagerRecipientTarget,
  BroadcastMessageRecipientDisplay,
} from "./BroadcastMessagesGql";
import { getFeatureFlags } from "../../featureFlags";
import { PersonSex } from "../../graphql-types";
const titleCase = require("title-case");

const makeKey = (type: BroadcastMessageRecipientType, id: string) =>
  `${type.toLowerCase()}_${(id || "").toLowerCase()}`;

export const mergeRecipients = (
  start: BroadcastMessageRecipientDisplay[],
  riskLevels: KeyedString[],
  caseManagers: BroadcaseMessageCaseManagerRecipientTarget[],
  activities: Activity[]
) => {
  const featureFlags = getFeatureFlags();
  const broadCastMessageBuckets = featureFlags.broadcastMessagingBuckets;
  const broadcastMessageProgramConfig =
    featureFlags.broadcastMessagingBucketsProgramRegexes || [];
  let riskLevelRecipients: BroadcastMessageRecipientDisplay[] = [];
  let riskLevelByGenderRecipients: BroadcastMessageRecipientDisplay[] = [];
  let caseLoadRecipients: BroadcastMessageRecipientDisplay[] = [];
  let activitiesRecipient: BroadcastMessageRecipientDisplay[] = [];
  let programRecipients: BroadcastMessageRecipientDisplay[] = [];

  if (
    broadCastMessageBuckets.includes(BroadcastMessageRecipientType.RiskLevel)
  ) {
    riskLevelRecipients = riskLevels.map(
      ({ key, value }) =>
        ({
          key: makeKey(BroadcastMessageRecipientType.RiskLevel, key),
          value,
          query: {
            queryType: BroadcastMessageRecipientType.RiskLevel,
            arg: key,
          },
        } as BroadcastMessageRecipientDisplay)
    );
  }

  if (
    broadCastMessageBuckets.includes(
      BroadcastMessageRecipientType.RiskLevelBySex
    )
  ) {
    const sexOptions = [PersonSex.Female, PersonSex.Male];
    riskLevelByGenderRecipients = riskLevels.reduce(
      (acc, { key, value }) => {
        const newGenderRecipientDisplays = sexOptions.map((sex) => {
          return {
            key: makeKey(
              BroadcastMessageRecipientType.RiskLevelBySex,
              `${key}_${sex}`
            ),
            value: `${value} (${titleCase(sex)})`,
            query: {
              queryType: BroadcastMessageRecipientType.RiskLevelBySex,
              arg: `${key}_${sex}`,
            },
          } as BroadcastMessageRecipientDisplay;
        });

        acc.push(...newGenderRecipientDisplays);
        return acc;
      },
      [] as BroadcastMessageRecipientDisplay[]
    );
  }

  if (
    broadCastMessageBuckets.includes(BroadcastMessageRecipientType.Activity)
  ) {
    activitiesRecipient = activities.map(({ id, title: { en } }) => {
      return {
        key: makeKey(BroadcastMessageRecipientType.Activity, id),
        value: `Activity: ${en}`,
        query: {
          queryType: BroadcastMessageRecipientType.Activity,
          arg: id,
        },
      };
    });
  }

  if (
    broadCastMessageBuckets.includes(BroadcastMessageRecipientType.Caseload)
  ) {
    caseLoadRecipients = caseManagers.map(
      ({ id, name: { first, last } }) =>
        ({
          key: makeKey(BroadcastMessageRecipientType.Caseload, id),
          value: `Caseload: ${first} ${last}`,
          query: {
            queryType: BroadcastMessageRecipientType.Caseload,
            arg: id,
          },
        } as BroadcastMessageRecipientDisplay)
    );
  }

  if (broadCastMessageBuckets.includes(BroadcastMessageRecipientType.Program)) {
    programRecipients = broadcastMessageProgramConfig.map(
      (config) =>
        ({
          key: makeKey(BroadcastMessageRecipientType.Program, config.name),
          query: {
            arg: config.regex,
            queryType: BroadcastMessageRecipientType.Program,
          },
          value: config.name,
        } as BroadcastMessageRecipientDisplay)
    );
  }

  return [
    ...start,
    ...programRecipients,
    ...riskLevelByGenderRecipients,
    ...riskLevelRecipients,
    ...activitiesRecipient,
    ...caseLoadRecipients,
  ];
};
