import React, { Component } from "react";
import idx from "idx.macro";
import {
  KeyedString,
  QueryRenderer,
  CaseManager,
  AssessmentTool,
  NOT_SET,
} from "../../../shared/lib/graphql/index";
import {
  serializeCaseManagers,
  serializeAssessmentTools,
} from "../../../shared/lib/modalFormHelpers";
import graphql from "babel-plugin-relay/macro";
import AddParticipantModal, {
  AddParticipantModalPropsExternal,
} from "./AddParticipantModal";
import { CreateParticipantContext } from "../../../utils/context";
import { getFeatureFlags } from "../../../featureFlags";
import { arrayToDictionarySpecifyKeyValue } from "../../../shared/lib/modalFormHelpers";
import { Operation } from "../types";

interface AddParticipantGqlResponse {
  case_managers: {
    case_manager: CaseManager[];
  };
  application: {
    risk_levels: KeyedString[];
    assessment_tools: AssessmentTool[];
    languages: KeyedString[];
    compliance_options: KeyedString[];
  };
}

const {
  whatIsRiskLevelCalled,
  caseManagerDisplayTerm,
  whatIsComplianceCalled,
} = getFeatureFlags();

interface Props extends AddParticipantModalPropsExternal {
  operation: Operation;
  type: string;
}

class AddParticipantQueryWrapper extends Component<Props> {
  render() {
    const { operation, type, participant } = this.props;
    return (
      <QueryRenderer
        query={graphql`
          query AddParticipantQueryWrapperQuery {
            application {
              risk_levels {
                key
                value
              }
              compliance_options {
                key
                value
              }
              assessment_tools {
                id
                name
                scale {
                  key
                  value
                }
              }
              languages {
                key
                value
              }
            }
            case_managers {
              case_manager {
                id
                name {
                  first
                  last
                }
                phone {
                  mobile
                }
                email
              }
            }
          }
        `}
        SuccessComponent={(props: AddParticipantGqlResponse) => {
          const riskLevels = idx(props, (_) => _.application.risk_levels) || [];
          const caseManagers = idx(props, (_) => _.case_managers.case_manager);
          const languages = idx(props, (_) => _.application.languages);
          const complianceOptions = idx(
            props,
            (_) => _.application.compliance_options
          );
          if (!caseManagers || caseManagers.length === 0) {
            console.warn(
              "No case managers found in system. This is likely an error."
            );
          }

          const assessmentTools =
            idx(props, (_) => _.application.assessment_tools) || [];

          const assessmentToolsWithPlaceholder = assessmentTools.map((tool) => {
            const { id, name, scale } = tool;
            return {
              id,
              name,
              scale: [
                {
                  key: NOT_SET,
                  value: "Select score...",
                } as KeyedString,
              ].concat(scale),
            };
          });

          const assessmentToolDictionary = arrayToDictionarySpecifyKeyValue(
            assessmentToolsWithPlaceholder,
            "id",
            "scale"
          );

          const caseManagersSerialized: KeyedString[] = [
            {
              key: NOT_SET,
              value: `Select a ${caseManagerDisplayTerm}...`,
            } as KeyedString,
          ].concat(caseManagers ? serializeCaseManagers(caseManagers) : []);

          const riskLevelsSerialized: KeyedString[] = [
            {
              key: NOT_SET,
              value: `Select ${whatIsRiskLevelCalled || "Risk Level"}...`,
            } as KeyedString,
          ].concat(riskLevels);

          const assessmentToolsSerialized = [
            { key: NOT_SET, value: "Select instrument..." } as KeyedString,
          ].concat(serializeAssessmentTools(assessmentTools));

          if (!complianceOptions || complianceOptions.length === 0) {
            console.warn(
              "No compliance options in system. This is likely an error."
            );
          }

          const complianceOptionsSerialized = [
            // TODO: Put this compliance into a shared place, started but complexity, WET
            {
              key: NOT_SET,
              value: `Select ${whatIsComplianceCalled || "Compliance"}...`,
            } as KeyedString,
          ].concat(complianceOptions);

          const languagesSerialized = [
            { key: NOT_SET, value: "Select language..." },
            ...languages,
          ];

          return (
            <>
              {caseManagersSerialized && caseManagersSerialized.length > 0 && (
                <CreateParticipantContext.Provider
                  value={{
                    caseManagersSerialized,
                    riskLevelsSerialized,
                    assessmentToolsSerialized,
                    languagesSerialized,
                    assessmentToolDictionary,
                    complianceOptionsSerialized,
                  }}
                >
                  <AddParticipantModal
                    operation={operation}
                    type={type}
                    callback={this.props.callback}
                    participant={participant}
                    metaDataObject={{
                      caseManagersSerialized,
                      riskLevelsSerialized,
                      assessmentToolsSerialized,
                      languagesSerialized,
                      assessmentToolDictionary,
                      complianceOptionsSerialized,
                    }}
                  />
                </CreateParticipantContext.Provider>
              )}
            </>
          );
        }}
      />
    );
  }
}

export default AddParticipantQueryWrapper;
