import * as React from "react";
import { Form } from "react-bulma-components";

import { KeyedString } from "../../lib/graphql/flowTypes";

export type SelectGroupedOptions = { [key: string]: Array<KeyedString> };

interface Props {
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  options?: Array<KeyedString>;
  groupedOptions?: SelectGroupedOptions;
  name?: string;
  value?: string;
  color?: string | null;
  className?: string;
  // Use the instructionalDefault value to give a hint about what will be selected
  // e.g. "Select status...", it will show when the value is null
  instructionalDefault?: string;
  hideInstructionalDefault?: boolean;
  disabled?: boolean;
  fieldClassName?: string;
  onClick?: Function;
}

export default class Select extends React.PureComponent<Props> {
  _mapOptions = (
    options: Array<KeyedString> | undefined
  ): JSX.Element[] | undefined =>
    options &&
    options.map(({ key, value }) => (
      <option key={key} value={key}>
        {value}
      </option>
    ));

  _renderOptions(): JSX.Element[] | undefined {
    const { options, groupedOptions } = this.props;

    if (!groupedOptions) {
      return this._mapOptions(options);
    } else {
      return Object.keys(groupedOptions).map((key) => (
        <optgroup label={key} key={key}>
          {this._mapOptions(groupedOptions[key])}
        </optgroup>
      ));
    }
  }

  render() {
    const {
      color,
      name,
      fieldClassName,
      onChange,
      value,
      hideInstructionalDefault = false,
      instructionalDefault = "Select...",
      className,
      disabled,
      onClick,
    } = this.props;

    const optionsToRender = this._renderOptions();

    return (
      <Form.Field className={fieldClassName}>
        <Form.Select
          onClick={onClick}
          className={[className, disabled ? "disabled" : null].join(" ")}
          color={color}
          onChange={onChange}
          name={name}
          value={value ? value : disabled ? "Not set" : instructionalDefault}
          disabled={disabled}
        >
          {/*
            NOTE: Preserve the order & existence of the following so that the
              width of the select is maintained across disabled and enabled states
          */}
          {/*
            `disabled`
            If disabled, show this option
            If disabled, it is also is selected
              Therefore: if there is no other selection, it is the selected option
                If there is another selection, it gets selected in code after this
                  (see ModalSelect.tsx > componentDidMount l 37 initialSelectObj)
                  We don't have the selection value at this point, so we can't set it
                  this is a hack, the ModalSelect was also a hack
          */}
          {disabled && (
            <option disabled hidden>
              Not set
            </option>
          )}
          {/*
            `instructionalDefault`
            Show the instruction message - it's disabled - and will always be present
            in order to invisibly set a min-width for the select
            NOTE: Sometimes the optionsToRender include their own "instruction" hence why this is optional
          */}
          {!hideInstructionalDefault && !!instructionalDefault && (
            <option disabled>{instructionalDefault}</option>
          )}
          {optionsToRender}
        </Form.Select>
      </Form.Field>
    );
  }
}
