import * as React from "react";
import idx from "idx.macro";
import moment from "moment-timezone";

import { ActivityIconWeb } from "../../../lib/ActivityIconWeb";

export type DisplayEventType = {
  date: string;
  activity: {
    title: { en: string };
  };
};

const displayEventInfo = (event: DisplayEventType) => {
  if (!event)
    return (
      <span className="has-text-info eventColumn">No upcoming events</span>
    );

  const title = idx(event, (_) => _.activity.title.en);
  return (
    <span className="eventColumn">
      <span style={{ fontFamily: "monaco", fontSize: 12 }}>
        <ActivityIconWeb icon={title} className="tableIcon" />
      </span>
      <span>{title}</span>
    </span>
  );
};

const displayEventTime = (event: DisplayEventType) => {
  if (!event) return <span className="has-text-info eventDateColumn" />;

  const { date } = event;
  const eventTime = formatDateTimeForDisplay(date);

  return <span className="eventDateColumn">{eventTime}</span>;
};

export const displayEvent = (event: DisplayEventType) => ({
  eventTime: displayEventTime(event),
  eventInfo: displayEventInfo(event),
});

export const formatDateTimeForDisplay = (
  date: moment.Moment | string
): string => {
  return moment(date).format("MMM DD, YYYY h:mmA");
};
