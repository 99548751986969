import React from "react";
import { Form, Row, Button } from "antd";
import { FormComponentProps } from "antd/es/form";
import { InterviewFormSection, InterviewFormItem } from "./shared";
import { InputFormDataWithInputType } from "./types";
import { updateBookingCourtDecisionData } from "../../actions/bookings";
import { PretrialDecision, Maybe } from "../../graphql-types";
import "./prospects.scss";

interface CourtDecisionFormFieldProps extends FormComponentProps {
  courtDecisionFormFieldsWithInitialValue: InputFormDataWithInputType[];
  releasedWithConditionsFormFieldsWithInitialValue: InputFormDataWithInputType[];
  sentencedFormFieldsWithInitialValue: InputFormDataWithInputType[];
  petitionResolvedFormFieldsWithInitialValue: InputFormDataWithInputType[];
  bookingId: string;
}

class CourtDecisionForm extends React.Component<CourtDecisionFormFieldProps> {
  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err: any, values: any) => {
      if (!err) {
        const { bookingId } = this.props;
        await updateBookingCourtDecisionData({
          booking_id: bookingId,
          pretrial_decision_form_data: {
            decision: values.decision,
            has_gps_monitoring: values.has_gps_monitoring,
            has_alcohol_monitoring: values.has_alcohol_monitoring,
            has_basic_monitoring: values.has_basic_monitoring,
            sentencing_decision: values.sentencing_decision,
            jail_sanction: values.jail_sanction,
          },
        });
      }
    });
  };

  render() {
    const {
      courtDecisionFormFieldsWithInitialValue,
      form,
      releasedWithConditionsFormFieldsWithInitialValue,
      sentencedFormFieldsWithInitialValue,
      petitionResolvedFormFieldsWithInitialValue,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    const renderReleaseOptions = (): Maybe<JSX.Element> => {
      const wasReleasedWithConditions =
        form.getFieldValue("decision") ===
          PretrialDecision.PretrialReleaseWithConditions ||
        courtDecisionFormFieldsWithInitialValue[0].initialValue ===
          PretrialDecision.PretrialReleaseWithConditions;
      return wasReleasedWithConditions ? (
        <>
          {releasedWithConditionsFormFieldsWithInitialValue.map(
            (releasedWithConditionsFormField) => {
              return (
                <InterviewFormItem
                  key={releasedWithConditionsFormField.id}
                  formData={releasedWithConditionsFormField}
                  getFieldDecorator={getFieldDecorator}
                />
              );
            }
          )}
        </>
      ) : null;
    };

    const renderSentenceOptions = (): Maybe<JSX.Element> => {
      const wasSentenced =
        form.getFieldValue("decision") === PretrialDecision.Sentenced ||
        courtDecisionFormFieldsWithInitialValue[0].initialValue ===
          PretrialDecision.Sentenced;
      return wasSentenced ? (
        <>
          {sentencedFormFieldsWithInitialValue.map((sentencedFormField) => {
            return (
              <InterviewFormItem
                key={sentencedFormField.id}
                formData={sentencedFormField}
                getFieldDecorator={getFieldDecorator}
              />
            );
          })}
        </>
      ) : null;
    };

    const renderPetitionResolvedOptions = (): Maybe<JSX.Element> => {
      const wasPetitionResolved =
        form.getFieldValue("decision") === PretrialDecision.PetitionResolved ||
        courtDecisionFormFieldsWithInitialValue[0].initialValue ===
          PretrialDecision.PetitionResolved;
      return wasPetitionResolved ? (
        <>
          {petitionResolvedFormFieldsWithInitialValue.map(
            (petitionResolvedFormField) => {
              return (
                <InterviewFormItem
                  key={petitionResolvedFormField.id}
                  formData={petitionResolvedFormField}
                  getFieldDecorator={getFieldDecorator}
                />
              );
            }
          )}
        </>
      ) : null;
    };

    return (
      <Form onSubmit={this.handleSubmit}>
        <InterviewFormSection
          sectionTitle={"Court Decision Information"}
          renderContents={() => (
            <>
              {courtDecisionFormFieldsWithInitialValue.map(
                (courtDecisionFormField) => {
                  return (
                    <InterviewFormItem
                      key={courtDecisionFormField.id}
                      formData={courtDecisionFormField}
                      getFieldDecorator={getFieldDecorator}
                    />
                  );
                }
              )}
              {renderReleaseOptions()}
              {renderSentenceOptions()}
              {renderPetitionResolvedOptions()}
            </>
          )}
        />
        <Row type="flex" justify="center" gutter={24}>
          <Form.Item>
            <Button type="primary" size={"large"} htmlType="submit">
              Save Court Decision
            </Button>
          </Form.Item>
        </Row>
      </Form>
    );
  }
}

export const CourtDecisionFormWrapped = Form.create<
  CourtDecisionFormFieldProps
>({
  name: "Court decision form",
})(CourtDecisionForm);
