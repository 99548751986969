import React from "react";

import { ModalProps, renderAsFnArg } from "../../modals/ModalWrapper";

import {
  BroadcastMessageForm,
  BroadcastMessageFormOptions,
} from "./BroadcastMessageForm";
import {
  BroadcastMessageModalWrapper,
  BroadcastMessageFormData,
} from "./BroadcastMessageModalWrapper";

export type BroadcastMessageModalProps = ModalProps<BroadcastMessageFormData> &
  BroadcastMessageFormOptions;

export class BroadcastMessageModal extends React.PureComponent<
  BroadcastMessageModalProps
> {
  render() {
    const {
      callBackForAPI,
      operation,
      type,
      onDelete,
      updateObject,
      renderAs,
      ...formOptions
    } = this.props;
    if (renderAs) {
      console.error(
        "Passing renderAs to BroadcastMessageModal is not supported."
      );
      return;
    }
    return (
      <BroadcastMessageModalWrapper
        callBackForAPI={callBackForAPI}
        type={type}
        operation={operation}
        onDelete={onDelete}
        updateObject={updateObject}
        renderAs={({ children }: renderAsFnArg) => <div>{children}</div>}
      >
        {(data: any) => (
          <BroadcastMessageForm
            {...formOptions}
            {...data}
            onDelete={onDelete}
            updateObject={updateObject}
            operation={operation}
          />
        )}
      </BroadcastMessageModalWrapper>
    );
  }
}
