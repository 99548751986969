import * as React from "react";
import classnames from "classnames";
import { Level, Media, Content } from "react-bulma-components";
import "./PageHeader.scss";

export interface PageHeaderProps {
  children?: React.ReactNode | React.ReactNode[];
  classNames?: string | { [key: string]: boolean };
  headerRight?: React.ReactNode;
  pageName: string;
  pageBlurb?: string;
  turnOffBottomPadding?: boolean;
  titleTag?: React.ReactNode;
}

export class PageHeader extends React.PureComponent<PageHeaderProps> {
  render() {
    const {
      children,
      classNames,
      headerRight,
      pageName,
      pageBlurb,
      turnOffBottomPadding,
      titleTag,
    } = this.props;

    const containerClassObj =
      typeof classNames === "string" ? { [classNames]: true } : classNames;
    const containerClassName = classnames({
      "page-header-container": true,
      // building an object above then spread below means that any defaults can be overridden
      ...containerClassObj,
    });

    const topClassName = classnames("constrained-section", "top", {
      "has-tabs": turnOffBottomPadding,
    });

    return (
      <div className={containerClassName}>
        <div className="shaded-section">
          <div className={topClassName}>
            <Media>
              <Media.Item position="left">
                <Content>
                  <Level>
                    <Level.Side align="left">
                      <h1 className="title is-3 page-title">{pageName}</h1>
                    </Level.Side>
                    {!titleTag ? null : (
                      <Level.Item className="title-tag">{titleTag}</Level.Item>
                    )}
                  </Level>
                  <h6 className="page-blurb">{pageBlurb}</h6>
                </Content>
              </Media.Item>
              <Media.Item position="right">
                <Level>
                  {!headerRight ? null : (
                    <Level.Side align="right">{headerRight}</Level.Side>
                  )}
                </Level>
              </Media.Item>
            </Media>
            {children}
          </div>
        </div>
      </div>
    );
  }
}
