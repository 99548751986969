import * as React from "react";
import Validator, { ValueToValidate } from "../../shared/lib/validator";
import moment, { Moment } from "moment";
import { FormDataBase } from "../modals/types";

import {
  EVENT_TIME_INTERVAL,
  combineMomentTimeAndDates,
} from "../../shared/lib/modalFormHelpers";
import { ModalProps, ModalCallbackFnType } from "../modals/ModalWrapper";
import { ModalConfirmationPageProps } from "../modals/ModalConfirmationPage";
import { ModalInputTypes, NOT_SET } from "../../shared/lib/graphql/flowTypes";

export interface ValueTuple<TValue = string | Moment>
  extends ValueToValidate<TValue> {
  name: string;
}

export interface ModalWrapperOptions {
  modalWrapperHOCControlsOpenState: boolean;
  SecondPageComponent?: React.ComponentType<ModalConfirmationPageProps>;
  handleModalClose: () => void;
  handleModalOpen: () => void;
}

const defaultModalWrapperOptions: Partial<ModalWrapperOptions> = {
  modalWrapperHOCControlsOpenState: true,
};

type FormInputChangeElementBase = HTMLElement & ValueTuple;

// We're wrapping our own type instead of using a HTML{FOO}Element
//  to abstract over Inputs and sometimes Selects underlying our change handler
export interface FormInputChangeElement extends FormInputChangeElementBase {}

interface Props<TData> extends ModalProps<TData> {
  children: React.ReactNode;
}

type FormValidationsArgs<TValue = string | Moment> = {
  comparisonValue?: any;
} & ValueTuple<TValue>;

type DatePickerChangeArgs = ValueTuple<Moment>;

type handleDatePickerRawArgs = {
  selectedDate: Moment;
} & ValueTuple<string>;

interface validation {
  state: string;
  errors: string[];
}

export interface ModalFormInfo<TData extends FormDataBase, TStructured> {
  modalFormData: TData;
  constitute?: (data: TData) => TStructured;
  validations: { [key: string]: validation };
}

interface FormStateType<TData extends FormDataBase, TStructured>
  extends ModalFormInfo<TData, TStructured> {
  attemptedSubmission: boolean;
  secondPage: boolean;
  showModal: boolean;
}

export interface ModalWrappedComponentType<
  TData extends FormDataBase = FormDataBase,
  TStructured = any
> {
  formState: FormStateType<TData, TStructured>;
}

export interface SomeOfThePropsTypedFromModalWrapper {
  handleModalFormInputChange: (
    event: React.ChangeEvent<FormInputChangeElement & { [key: string]: any }>
  ) => void;
  onDelete?: (evt: React.SyntheticEvent<HTMLButtonElement>) => void; // I'm not convinced this should be here 🚢
  handleFormConfirmSubmit: (args: any) => void;
  handleDatePickerChange: (args: FormValidationsArgs<Moment>) => void;
  handleDatePickerRawChange: (args: handleDatePickerRawArgs) => void;
  handleModalOpen: () => void;
  handleModalClose: () => void;
  handlePhoneValidation: (name: string, value: string) => void;
  updateSpecifiedFields: (fields: { [key: string]: any }) => void;
  // TODO: Type handleFormSubmit
  /**
   * This args surely cannot be `any`
   */
  handleFormSubmit: (args: any) => void;
}

// TODO: Move these to a single point of use
// TODO: Type these beyond "Function"
export interface ModalWrapperComponentProps
  extends SomeOfThePropsTypedFromModalWrapper {
  togglePage: Function;
  SecondPageComponent?: React.ComponentType<ModalConfirmationPageProps>;
}

function ModalFormHOC<
  TWrapperProps extends ModalWrapperComponentProps,
  TData extends FormDataBase = FormDataBase,
  TStructured = any
>(
  WrappedComponent: React.ComponentType<TWrapperProps> &
    ModalWrappedComponentType<TData, TStructured>,
  {
    modalWrapperHOCControlsOpenState,
    SecondPageComponent,
    handleModalClose,
    handleModalOpen,
  } = defaultModalWrapperOptions
) {
  return class extends React.Component<
    Props<TData>,
    FormStateType<TData, TStructured>
  > {
    constructor(props: Props<TData>) {
      super(props);

      const {
        formState: { constitute, ...rest },
      } = WrappedComponent;
      this.state = {
        ...rest,
      } as FormStateType<TData, TStructured>;
    }

    componentDidMount() {
      // refreshes the form state data on remount.
      // useful in the case where the formState static prop
      // in WrappedComponent has updated, which can occur if
      // update objects need to passed to the WrappedComponent

      const {
        formState: { constitute, ...rest },
      } = WrappedComponent;

      this.setState(() => ({
        ...rest,
      }));
    }

    _resetFormData = () => {
      const {
        formState: { constitute, ...rest },
      } = WrappedComponent;

      const newState = Object.assign({}, rest);
      if (modalWrapperHOCControlsOpenState) {
        newState.showModal = false;
      }
      if (handleModalClose) handleModalClose();

      this.setState(newState);
    };

    _handleModalOpen = () => {
      if (handleModalOpen) handleModalOpen();
      if (modalWrapperHOCControlsOpenState)
        this.setState(() => ({ showModal: true }));
    };

    _handleModalClose = () => {
      if (handleModalClose) handleModalClose();
      if (modalWrapperHOCControlsOpenState)
        this.setState(() => ({ showModal: false }));
    };

    handleModalFormInputChange = (event: any) => {
      const index =
        event.nativeEvent &&
        event.nativeEvent.target &&
        event.nativeEvent.target.selectedIndex;
      const text = index ? event.nativeEvent.target[index].text : "";
      const { name, value } = event.target;
      const type =
        event.currentTarget.getAttribute("data-input-type") || undefined;

      if (event.hasOwnProperty("isFakeEvent") === false) {
        this._handleFormValidations({ name, value, type });
      }

      const { modalFormData } = this.state;
      const newData = Object.assign({}, modalFormData[name], { value });

      // need to also get the listed value
      // unsure about usage of the `type` vs. capability checking
      if (
        (type === ModalInputTypes.Select && text) ||
        type === ModalInputTypes.SelectOptional
      ) {
        newData.text = text;
      }

      this.setState({
        modalFormData: {
          ...(modalFormData as any),
          [name]: newData,
        },
      });
    };

    _handlePhoneValidation = (name: string, value: string) => {
      const validator = new Validator();
      const {
        validationErrors,
        validationState,
      } = validator.getValidationErrors({
        type: ModalInputTypes.Phone,
        value,
        comparisonValue: null,
      });

      this.setState(
        (state: FormStateType<TData, TStructured>) =>
          ({
            ...state,
            validations: {
              ...state.validations,
              [name]: {
                state: validationState,
                errors: validationErrors,
              },
            },
          } as FormStateType<TData, TStructured>)
      );
    };

    _handleFormValidations = ({
      name,
      value,
      type,
      comparisonValue,
    }: FormValidationsArgs) => {
      const validator = new Validator();

      const {
        validationErrors,
        validationState,
      } = validator.getValidationErrors({ type, value, comparisonValue });

      /*
      phone is a special case
      it can only fail validation on blur, so that the user
      isn't annoyed with error message during entry
      */

      if (type === ModalInputTypes.Phone && validationState === "error") {
        return;
      }

      this.setState(
        (state: FormStateType<TData, TStructured>) =>
          ({
            ...state,
            validations: {
              ...state.validations,
              [name]: {
                state: validationState,
                errors: validationErrors,
              },
            },
          } as FormStateType<TData, TStructured>)
      );
    };

    _handleStartDateChange = (
      { name, value, type }: DatePickerChangeArgs,
      isStartDate: boolean = true
    ) => {
      const { endDate = null } = this.state.modalFormData;

      if (!value) return;

      // if no end date, just set the start date
      if (!endDate) {
        this.setState((state) => ({
          ...state,
          modalFormData: {
            ...(state.modalFormData as any),
            [name]: {
              ...state.modalFormData[name],
              value,
            },
          },
        }));
        this._handleFormValidations({
          name,
          value: value,
          type: type,
          comparisonValue: null,
        });
        return;
      }

      /*
      Handle a couple of cases
      1. Normal case, the entered start date is before the previously-entered end-date (or there is no previously entered end date)
      2. Other case, the entered start date is after a previously-entered start date.
      */

      const modalFormData = {
        ...(this.state.modalFormData as any),
        [name]: {
          ...this.state.modalFormData[name],
          value: value,
        },
      } as TData;

      let comparisonValue = undefined;
      // If this is a startdate, we check and enforce things about the endDate
      if (isStartDate) {
        const endDateIsBeforeStartDate = value.isSameOrAfter(endDate.value)
          ? true
          : false;
        const adjustedEndDate = value
          .clone()
          .add(EVENT_TIME_INTERVAL, "minutes");

        comparisonValue = endDateIsBeforeStartDate
          ? adjustedEndDate
          : endDate.value;

        modalFormData.endDate = {
          ...this.state.modalFormData["endDate"],
          value: endDateIsBeforeStartDate
            ? adjustedEndDate
            : this.state.modalFormData["endDate"].value,
        };
      }

      this.setState({ modalFormData });

      //handle the form validations

      this._handleFormValidations({
        name,
        value: value,
        type: type,
        comparisonValue,
      });
    };

    _handleEndDateChange = ({ name, value, type }: DatePickerChangeArgs) => {
      const { startDate = null } = this.state.modalFormData;

      if (!value) return;

      // if no start date, just set the end date
      if (!startDate) {
        this.setState((state) => ({
          ...state,
          modalFormData: {
            ...(state.modalFormData as any),
            [name]: {
              ...state.modalFormData[name],
              value,
            },
          },
        }));
        this._handleFormValidations({
          name,
          value: value,
          type: type,
          comparisonValue: null,
        });
        return;
      }

      /*
      Handle a couple of cases
      1. Normal case, the entered start date is before the previously-entered end-date (or there is no previously entered end date)
      2. Other case, the entered start date is after a previously-entered start date.
      */

      const endDateIsBeforeStartDate =
        value.isSameOrBefore(startDate.value) && startDate.value ? true : false;

      let adjustedEndDate = moment();
      if (startDate.value) {
        adjustedEndDate = startDate.value
          .clone()
          .add(EVENT_TIME_INTERVAL, "minutes");
      }

      this.setState((state: FormStateType<TData, TStructured>) => ({
        ...state,
        modalFormData: {
          ...(state.modalFormData as any),
          [name]: {
            ...state.modalFormData[name],
            value: endDateIsBeforeStartDate ? adjustedEndDate : value,
          },
        } as TData,
      }));

      //handle the form validations

      this._handleFormValidations({
        name,
        value: endDateIsBeforeStartDate ? adjustedEndDate : value,
        type: type,
        comparisonValue: startDate.value,
      });
    };

    _preserveDateOnManualTimeEntry = (value: Moment, type: string): Moment => {
      const { startDate, endDate } = this.state.modalFormData;
      const comparisonDate =
        type === ModalInputTypes.StartTime ? startDate.value : endDate.value;

      return combineMomentTimeAndDates(value, comparisonDate);
    };

    handleDatePickerChange = ({
      name,
      value,
      type,
    }: FormValidationsArgs<Moment>) => {
      const { startDate, endDate } = this.state.modalFormData;
      let newValue = value;

      // this is a very hacky way to allow for "optional" date time fields.
      // because of the complexity of validating start / end dates, which are dependent on each other
      // and some idiosyncracies with the react-date-picker this ends up being the simplest way
      // to give us an escape hatch for non-required date-time fields.
      if (!value) {
        if (typeof type === "string" && type.includes("optional")) {
          this.setState((state: FormStateType<TData, TStructured>) => ({
            ...state,
            modalFormData: {
              ...(state.modalFormData as any),
              [name]: {
                ...state.modalFormData[name],
                value: null,
              },
            } as TData,
          }));
        }
        return;
      }

      /*
       handle case where time is changed directly by the user (the date field is lost in this case)
       this appears to be a flaw in the datepicker plugin, which probably isn't intended to separate out date and time fields in UI
       as we are doing.
       */

      if (
        type === ModalInputTypes.StartTime ||
        (type === ModalInputTypes.EndTime && value instanceof moment)
      ) {
        newValue = this._preserveDateOnManualTimeEntry(value, type);
      }

      if (!newValue) {
        this._handleFormValidations({
          name,
          value: undefined,
          type: type,
          comparisonValue:
            name === "startDate" ? endDate.value : startDate.value,
        });
        this.setState((state: FormStateType<TData, TStructured>) => ({
          ...state,
          modalFormData: {
            ...(state.modalFormData as any),
            [name]: {
              ...state.modalFormData[name],
              value: null,
            },
          } as TData,
        }));
        return;
      }

      if (name === "startDate") {
        return this._handleStartDateChange({ name, value: newValue, type });
      } else if (name === "endDate") {
        return this._handleEndDateChange({ name, value: newValue, type });
      } else {
        return this._handleStartDateChange(
          { name, value: newValue, type },
          false
        );
      }
    };

    handleDatePickerRawChange = ({
      value,
      name,
      type,
      selectedDate,
    }: handleDatePickerRawArgs) => {
      // allowing this to pass due to the fact that there are some optionals

      if (
        value === "" &&
        typeof type === "string" &&
        type.includes("optional")
      ) {
        return;
      }

      // This is needed as a work around to some limitations of react-date-picker.
      // See https://github.com/Hacker0x01/react-datepicker/issues/1335
      const { startDate, endDate } = this.state.modalFormData;
      try {
        const convertedDate = moment(value);
        const valid = convertedDate.isValid();

        const comparisonValue =
          name === "startDate" ? endDate.value : startDate.value;

        if (valid) {
          const combinedDate = combineMomentTimeAndDates(
            convertedDate,
            selectedDate
          );

          const startDateIsAfterEndDate =
            name === "startDate"
              ? combinedDate >= endDate.value
              : combinedDate <= startDate.value;

          if (startDateIsAfterEndDate) {
            this._handleFormValidations({
              name,
              value: combinedDate,
              type: type,
              comparisonValue: comparisonValue,
            });
            return;
          }

          this.handleDatePickerChange({ name, value: combinedDate, type });
          return;
        }

        this._handleFormValidations({
          name,
          value: convertedDate,
          type: type,
          comparisonValue: comparisonValue,
        });
      } catch (err) {
        // various invalid value can throw with moment()
        // TODO: Catch that more granularly, but for now, this ensures we don't crash on typing a date
      }
    };

    // horrible, horrible, horrible
    _updateSpecifiedFields = (
      fieldsToUpdate: Object,
      validationsToUpdate?: Object
    ) => {
      this.setState((state) => ({
        modalFormData: {
          ...(state.modalFormData as any),
          ...fieldsToUpdate,
        },
        validations: {
          ...state.validations,
          ...validationsToUpdate,
        },
      }));
    };

    handleFormSubmit = (args: any) => {
      const validator = new Validator();
      const invalidFields = validator.testAllValidations(
        this.state.validations,
        args
      );

      if (!invalidFields) {
        this._togglePage();
        return;
      }
      this.setState(() => ({
        attemptedSubmission: true,
      }));
      console.warn(
        "modal form submission, client-side: Invalid fields",
        invalidFields
      );
    };

    handleFormConfirmSubmit = (
      callBackForAPI: ModalCallbackFnType<TData | TStructured>
    ) => {
      const {
        formState: { constitute },
      } = WrappedComponent;
      const { modalFormData } = this.state;

      // filter out any optional select data that was left as "not set"
      const modalFormDataFiltered = Object.keys(modalFormData).reduce(
        (acc, key) => {
          acc[key] =
            modalFormData[key].value === NOT_SET
              ? { ...modalFormData[key], value: undefined }
              : modalFormData[key];
          return acc;
        },
        {} as any
      );

      callBackForAPI(
        constitute ? constitute(modalFormDataFiltered!) : modalFormDataFiltered,
        this._handleModalClose
      );
      this._resetFormData();
    };

    _togglePage = () => {
      this.setState((state: FormStateType<TData, TStructured>) => ({
        secondPage: !state.secondPage,
      }));
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          SecondPageComponent={SecondPageComponent}
          handleModalFormInputChange={this.handleModalFormInputChange}
          handleFormSubmit={this.handleFormSubmit}
          handleFormConfirmSubmit={this.handleFormConfirmSubmit}
          togglePage={this._togglePage}
          handleDatePickerChange={this.handleDatePickerChange}
          handleDatePickerRawChange={this.handleDatePickerRawChange}
          handleModalOpen={this._handleModalOpen}
          handleModalClose={this._handleModalClose}
          handlePhoneValidation={this._handlePhoneValidation}
          updateSpecifiedFields={this._updateSpecifiedFields}
        />
      );
    }
  };
}

export default ModalFormHOC;
