import { QueryResponseCache, RequestNode, Variables } from "relay-runtime";
import { defaultGraphqlFetcher } from "./defaultGraphqlFetcher";

type RelayFetchQueryType = (
  operation: RequestNode,
  variables: Variables
) => Promise<any>;

const relayCache = new QueryResponseCache({ size: 250, ttl: 120 * 1000 });

export const buildFetchQuery = (
  responseCache?: QueryResponseCache
): RelayFetchQueryType => {
  if (responseCache === null) {
    return defaultGraphqlFetcher;
  }

  return async (operation: RequestNode, variables: Variables) => {
    const { text, operationKind, force } = operation;

    const isMutation = operationKind === "mutation";
    const isQuery = operationKind === "query";

    // look for value in cache
    const fromCache = relayCache.get(text, variables);
    if (isQuery && !force && fromCache) return fromCache;

    // otherwise, make the request
    const response = await defaultGraphqlFetcher(operation, variables);

    if (isQuery && response) {
      relayCache.set(text, variables, response);
    }
    if (isMutation) {
      relayCache.clear();
    }

    return response;
  };
};
