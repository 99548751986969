import * as React from "react";
import { Button } from "antd";
import {
  DashboardBucketQueryIdValues,
  Omit,
  ReviewItem,
} from "../../shared/lib/graphql/flowTypes";
import { ColumnHeaderMapping } from "../../shared/components/participantListTable/index";
import { PassThroughTooltip } from "../../shared/components/elements/PassThroughTooltip";
import { getFeatureFlags } from "../../featureFlags";
import { AlwaysableTableColumn } from "../../shared/components/participantListTable/ParticipantListTableColumns";
import AddEventModal from "../modals/events/AddEventModal";
import { Operation } from "../modals/types";
import {
  CarePlanObligationType,
  CarePlanEventType,
} from "../care-plan/CarePlanQuery";
import {
  addObligationEvent,
  updateObligationEvent,
} from "../../actions/obligations";
import { AddEventFormData } from "../modals/events/AddEventModalWrapper";
import { reviewReviewItem } from "../../actions/reviewItems";
import moment from "moment";
import {
  EventAttendanceSelect,
  EventAttendanceTitles,
} from "../elements/EventAttendanceSelect";
import { SendChatButton } from "../chat/SendChatButton";
import idx from "idx.macro";
import { canChatWithParticipant } from "../../shared/util/determine_sms_status";
import { OfflineDataEntryForm } from "../data-input/OfflineDataEntryForm";

const { clientDisplayTerm } = getFeatureFlags();

const sortOnParticipantName = (items: any) => {
  items.sort((itemA: Partial<ReviewItem>, itemB: Partial<ReviewItem>) => {
    if (
      !itemA.participant ||
      !itemA.participant.name ||
      !itemB.participant ||
      !itemB.participant.name
    ) {
      return 0;
    }

    const {
      name: { first: firstA, last: lastA },
    } = itemA.participant;
    const {
      name: { first: firstB, last: lastB },
    } = itemB.participant;

    if (lastA === lastB) return firstA > firstB ? 1 : -1;
    return lastA! > lastB! ? 1 : -1;
  });
  return items;
};

export interface WorkflowBucketConfig {
  key: DashboardBucketQueryIdValues;
  blurb: string;
  columnNameRemaps?: ColumnHeaderMapping;
  columns: string[];
  // n.b.: This next thing is a function really only so we can have a pass through generic type and not spec it here
  //  The alternative was explored and is terri-bad
  additionalColumns?: <TItem>() => AlwaysableTableColumn<TItem>[];
  reviewIsReadOnly?: boolean;
  dataOnLoad?: <TTableData>(items: TTableData) => TTableData;
}

const onEventAdded = (id: string, obligation: CarePlanObligationType) => async (
  { start, end }: AddEventFormData,
  closeModal: () => void
) => {
  await addObligationEvent({
    obligation,
    date: (start as any) as string,
    end: (end as any) as string,
  });

  await reviewReviewItem({ id, reviewed: true, expire: true });

  closeModal();
};

const onEventAttendanceChange = (
  id: string,
  obligation: CarePlanObligationType,
  event: CarePlanEventType
) => async (evt: React.ChangeEvent<HTMLSelectElement>) => {
  const { name, value } = evt.target;

  await updateObligationEvent({
    obligation,
    event,
    eventData: {
      [name]: value,
    },
  });

  await reviewReviewItem({ id, reviewed: true, expire: true });
};

// would really be much simpler if this was just driven off of the configs
// of each review item (customizable per client)
// TODO: Add "columns" to review item configs in service.json and use that
// instead of messing with all this logic on the FE
function applyFeatureFlagsToColumns(baseColumns: string[]) {
  const {
    showStartDateInClientTable,
    showProgramTitleInClientTable,
    showRiskLevelInClientTable,
    showCaseNumberInClientTable,
  } = getFeatureFlags();

  const colsToRemove = new Set<string>();

  if (!showStartDateInClientTable) {
    colsToRemove.add("start_date");
  }
  if (!showProgramTitleInClientTable) {
    colsToRemove.add("program_title");
  }
  if (!showRiskLevelInClientTable) {
    colsToRemove.add("risk_level");
  }

  if (!showCaseNumberInClientTable) {
    colsToRemove.add("case_number");
  }

  return baseColumns.filter((col) => !colsToRemove.has(col));
}

const baseColumnWithEvents = [
  "reviewed",
  "name",
  "case_number",
  "start_date",
  "specific-event",
  "specific-event-date",
  "program_title",
  "risk_level",
];

const baseColumnNoEvents = [
  "reviewed",
  "name",
  "start_date",
  "risk_level",
  "program_title",
];
const baseColumnsNoFutureEventsColumns = [
  "reviewed",
  "name",
  "start_date",
  "obligation",
  "risk_level",
];
const staleDataInputColumns = [
  "name",
  "address",
  "obligation-title-only",
  "next_input_event",
];
const lawEnforcementContactColumns = [
  "reviewed",
  "name",
  "obligation-title-only",
  "obligation_input_value",
  "last_input_event",
];
const dataInputChangeColumns = [
  "reviewed",
  "name",
  "obligation-title-only",
  "input_value",
  "input_last_value",
  "specific-event-date",
  "image_upload",
];

const oneYearAnniversary = [
  "reviewed",
  "name",
  "end_date",
  "start_date_date",
  "weeks_in_program",
  "risk_level",
  "program_title",
];

const noFutureCourtDate = [
  "name",
  "case_number",
  "end_date",
  "start_date_date",
  "risk_level",
  "program_title",
];

const columnsToDisplayDictionary = {
  columnsWithEvents: applyFeatureFlagsToColumns(baseColumnWithEvents),
  columnsNoEvents: applyFeatureFlagsToColumns(baseColumnNoEvents),
  noFutureEventsColumns: applyFeatureFlagsToColumns(
    baseColumnsNoFutureEventsColumns
  ),
  staleDataInputColumns: applyFeatureFlagsToColumns(staleDataInputColumns),
  lawEnforcementContactColumns: applyFeatureFlagsToColumns(
    lawEnforcementContactColumns
  ),
  dataInputChangeColumns: applyFeatureFlagsToColumns(dataInputChangeColumns),
  oneYearAnniversary: applyFeatureFlagsToColumns(oneYearAnniversary),
  noFutureCourtDate: applyFeatureFlagsToColumns(noFutureCourtDate),
};

const KNOWN_BUCKETS: { [key: string]: Omit<WorkflowBucketConfig, "key"> } = {
  UpcomingUnreviewedCourtDate: {
    blurb: `${clientDisplayTerm}s with court dates in the next two weeks. Check "done" to remove them from this list at the end of the day.`,
    columnNameRemaps: {
      "specific-event": "Future Event",
      "specific-event-date": "Future Event Date",
    },
    columns: columnsToDisplayDictionary.columnsWithEvents,
  },
  FailureToAppear: {
    blurb: `${clientDisplayTerm}s that have missed a court date in the last 30 days. Check "done" to remove them from this list at the end of the day.`,
    columnNameRemaps: { "specific-event": "Past Event" },
    columns: columnsToDisplayDictionary.columnsWithEvents,
  },
  NoFutureEvents: {
    blurb: `Click "Add Event" to schedule a new case manager appointment. Report will be updated at the end of each day`,
    columns: columnsToDisplayDictionary.noFutureEventsColumns,
    reviewIsReadOnly: true,
    dataOnLoad: sortOnParticipantName,
    additionalColumns: () => [
      {
        at: 4,
        key: "add-event",
        id: "add-event",
        header: "Add Event",
        sortable: false,
        render: (_: null, __: number, item: any) => {
          const { obligation, participant, reviewed, review_id } = item;

          if (!obligation) {
            return null;
          }
          const isReadOnly =
            typeof obligation.is_writable === "boolean" &&
            !obligation.is_writable;

          // We could display a friendlier message here
          // (e.g. "go change it in your 'CE Tool' - customer-specific way to add an even to a non writable obligation)
          if (isReadOnly) return null;

          // once reviewed, don't show this button
          if (reviewed) return null;

          return (
            <AddEventModal
              updateObject={{
                first: participant.name.first,
                last: participant.name.last,
                where: obligation.service_provider.title,
              }}
              callBackForAPI={onEventAdded(review_id, obligation)}
              selectParticipant={false}
              selectServiceProvider={false}
              type="Event"
              operation={Operation.Add}
              buttonComponent={
                <PassThroughTooltip
                  title={`Schedule a new ${obligation.title.en} event`}
                >
                  <Button type={"primary"} size={"small"}>
                    Add Event
                  </Button>
                </PassThroughTooltip>
              }
            />
          );
        },
      },
    ],
  },
  NoAttendanceData: {
    blurb: `Please set the attendance from the dropdown menu. ${clientDisplayTerm}s with attendance set will be removed from the list by the end of each day.`,
    columnNameRemaps: {
      "specific-event": "Past Event",
      "specific-event-date": "Past Event Date",
    },
    reviewIsReadOnly: true,
    columns: columnsToDisplayDictionary.columnsWithEvents,
    dataOnLoad: sortOnParticipantName,
    additionalColumns: () => [
      {
        at: 2,
        key: "set-attendance",
        id: "set-attendance",
        header: "Set Attendance",
        sortable: false,
        render: (_: null, __: number, item: any) => {
          const { event, reviewed, review_id } = item;

          if (!event) {
            return null;
          }

          // don't show the attendance selector once this has been reviewed / attendance marked
          if (reviewed) return EventAttendanceTitles[event.attended] || "";

          const { obligation } = event;

          if (!obligation) {
            return null;
          }

          return (
            <EventAttendanceSelect
              onChange={onEventAttendanceChange(review_id, obligation, event)}
            />
          );
        },
      },
    ],
  },
  SupervisionDateReview: {
    blurb: `Review supervision schedule and update if appropriate. Mark "Done" when review is complete. Report will be updated at the end of each day`,
    columns: columnsToDisplayDictionary.columnsNoEvents,
    dataOnLoad: sortOnParticipantName,
    additionalColumns: () => [
      {
        at: 2,
        key: "done-attendance",
        id: "done-attendance",
        header: "Days in Program (FIX TITLE)",
        sortable: false,
        render: (_: null, ___: number, __: any) => {
          return (
            <Button
              type={"primary"}
              style={{ fontSize: 10 }}
              className="add-event add-event-workflow-no-future-events"
            >
              Mark as Done (NO EFFECT)
            </Button>
          );
        },
      },
      {
        at: 3,
        key: "set-attendance",
        id: "set-attendance",
        header: "Days in Program",
        sortable: false,
        render: (_: null, idx: number, __: any) => {
          return (
            <span>
              {80 + idx} {`<--`} 😱bad maths
            </span>
          );
        },
      },
      {
        at: 4,
        key: "start-date",
        id: "start-date",
        header: "Start Date",
        sortable: false,
        render: (_: null, idx: number, __: any) => {
          return (
            <span>
              {moment()
                .subtract(80 + idx, "days")
                .format("MMM D, YYYY")}
              {`<--`} more bad maths {Math.round(Math.random() * 344 + 1)}
            </span>
          );
        },
      },
      {
        at: 5,
        key: "ninety-date",
        id: "ninety-date",
        header: "90th Day",
        sortable: false,
        render: (_: null, idx: number, __: any) => {
          return <span>{moment().add(idx, "days").format("MMM D, YYYY")}</span>;
        },
      },
    ],
  },
  NoAttendanceIntegrationData: {
    blurb: `The following past events are missing attendance data.`,
    columnNameRemaps: {
      "specific-event": "Past Event",
      "specific-event-date": "Past Event Date",
    },
    reviewIsReadOnly: true,
    columns: columnsToDisplayDictionary.columnsWithEvents,
    dataOnLoad: sortOnParticipantName,
  },
  StaleDataInput: {
    blurb: `The following ${clientDisplayTerm.toLowerCase()}s are out of compliance on data input.`,
    reviewIsReadOnly: true,
    columns: columnsToDisplayDictionary.staleDataInputColumns,
    columnNameRemaps: { "obligation-title-only": "Data Type" },
    additionalColumns: () => [
      {
        at: 23,
        key: "send_reminder",
        id: "send_reminder",
        header: "Additional Reminder",
        sortable: false,
        render: (_: null, __: number, item: any) => {
          const { participant } = item;
          const canChat = canChatWithParticipant(
            !!idx(item, (_) => _.case_manager.id),
            participant.sms_enabled,
            participant.sms_consent,
            idx(participant, (_) => _.phone.mobile),
            idx(participant, (_) => _.current_device.device_id)
          );
          return (
            <SendChatButton
              defaultMessage={
                "Reminder! You are late submitting data to your probation officer. Please log-in to the promise app immediately: http://promise.app.link"
              }
              participantId={participant.id}
              buttonText={"Remind Now"}
              chatEnabled={canChat}
            />
          );
        },
      },
      {
        at: 24,
        key: "manual_entry",
        id: "manual_entry",
        header: "Offline reporting",
        sortable: false,
        render: (_: null, __: number, item: any) => {
          return <OfflineDataEntryForm workflowItem={item} />;
        },
      },
    ],
  },
  RecentDataInput: {
    blurb: `The following ${clientDisplayTerm.toLowerCase()}s have recently updated their law enforcement contact information. Check "done" to remove them from this list at the end of the day.`,
    reviewIsReadOnly: false,
    columns: columnsToDisplayDictionary.lawEnforcementContactColumns,
    columnNameRemaps: { "obligation-title-only": "Data Type" },
  },
  DataInputChange: {
    blurb: `The following ${clientDisplayTerm.toLowerCase()}s have recently confirmed or updated their address or employment information. Check "done" to remove them from this list at the end of the day.`,
    reviewIsReadOnly: false,
    columns: columnsToDisplayDictionary.dataInputChangeColumns,
    columnNameRemaps: {
      "specific-event-date": "Date",
      "obligation-title-only": "Data Type",
    },
  },
  OneYearAnniversary: {
    blurb: `${clientDisplayTerm}s who recently reached one-year anniversary of supervision end date. Check "done" to remove them from this list at the end of the day.`,
    columns: columnsToDisplayDictionary.oneYearAnniversary,
  },
  NoFutureCourtDate: {
    blurb: `The following ${clientDisplayTerm.toLowerCase()}s do not have an upcoming court date. When you enter the next court date, they will be removed from this list at the end of the day.
    `,
    columns: columnsToDisplayDictionary.noFutureCourtDate,
  },
};

export const getBucketConfig = (
  key: DashboardBucketQueryIdValues,
  supervisor: boolean = false
): WorkflowBucketConfig => {
  const config = KNOWN_BUCKETS[key];
  if (!config) {
    console.error(`Bucket ${key} unknown.`);
    return { key } as any;
  }
  // add in the key
  return {
    key,
    ...config,
    columns: supervisor
      ? config.columns.concat("case_manager")
      : config.columns,
  };
};
