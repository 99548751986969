import * as React from "react";

import Select from "../../shared/components/elements/Select";
import { CaseManager } from "../../shared/lib/graphql";

export interface CaseManagerSelectProps {
  caseManagers: CaseManager[];
  onChange: (evt: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
}

export const CaseManagerSelect = (props: CaseManagerSelectProps) => {
  return (
    <Select
      name="case-managers"
      className="case-manager-select"
      fieldClassName="field-inline"
      options={props.caseManagers.map(({ id: key, name }) => ({
        key,
        value: `${name.first} ${name.last}`,
      }))}
      onChange={props.onChange}
      value={props.value}
      hideInstructionalDefault={true}
    />
  );
};
