import * as React from "react";
import {
  createHistory,
  LocationProvider,
  Location,
  Redirect,
  Router,
} from "@reach/router";

import { skipIsDev } from "./shared/lib/util";
import { AppHeader } from "./shared/components/layout/";
import { PageviewTracker } from "./shared/lib/reach-router/PageviewTracker";
import { SetHeapUser } from "./shared/lib/analytics/heapSetUser";
import { MutationErrorWarning } from "./shared/components/elements/MutationErrorWarning";
import { ROOT_PATH, TOP_LEVEL_NAV_ITEMS } from "./AppInfo";
import { getFeatureFlags } from "./featureFlags";
import FourOhFour from "./components/screens/404";
import { AnalyticsReportContainer } from "./components/screens/AnalyticsReportContainer";
import { BroadcastMessagesScreen } from "./components/screens/BroadcastMessagesScreen";
import { GroupChatScreen } from "./components/screens/GroupChatScreen";
import { ParticipantListContainer } from "./components/screens/ParticipantListContainer";
import { participantDetailsTab } from "./components/screens/ParticipantDetailsTabs";
import { ParticipantDetailsContainer } from "./components/screens/ParticipantDetailsContainer";
import PrintEventContainer from "./components/screens/PrintEventContainer";
import { WorkflowScreen } from "./components/screens/WorkflowScreen";
import { ZenDeskWidget } from "./shared/components/ZenDeskWidget";
import { AppGqlResponse } from "./AppGql";
import { ParticipantSearchInput } from "./components/elements/ParticipantSearchInput";
import { CaseAnalyticsScreen } from "./components/screens/CaseAnalyticsScreen";
import { CalendarScreen } from "./components/screens/CalendarScreen";
import { ClassesScreen } from "./components/screens/ClassesScreen";
import { BookingsScreen } from "./components/screens/BookingsScreen";
import { ProspectsScreen } from "./components/screens/ProspectsScreen";
import { ProspectDetailScreen } from "./components/screens/ProspectDetailScreen";
import PrintTransmittal from "./components/screens/PrintTransmittalContainer";
import PrintRecommendation from "./components/screens/PrintRecommendationContainer";
import PrintCourtMemo from "./components/screens/PrintCourtMemoContainer";

const history = createHistory(window as any);

const {
  supportsBroadcastMessaging,
  supportsCaseAnalytics,
  supportsCalendar,
  supportsClasses,
  workflow: { display: displayWorkflow },
  supportsBookings,
  supportsProspects,
} = getFeatureFlags();

function supportsHeader(url: string) {
  const noHeaders = [
    `/${ROOT_PATH}/analytics-report`,
    `/${ROOT_PATH}/print_event`,
    `/${ROOT_PATH}/print_transmittal`,
    `/${ROOT_PATH}/print_recommendation`,
    `/${ROOT_PATH}/print_court_memo`,
  ];

  return !noHeaders.some((path) => {
    return url.indexOf(path) > -1;
  });
}

export interface AppContentsProps {
  appInfo: AppGqlResponse;
  zendeskToken?: string;
}

export class AppContents extends React.PureComponent<AppContentsProps> {
  render() {
    const { appInfo } = this.props;
    return (
      <>
        <SetHeapUser me={appInfo.me} />
        <LocationProvider history={history}>
          <Location>
            {({ location }) => {
              return (
                <div>
                  {supportsHeader(location.href) ? (
                    <AppHeader
                      navigationOptions={TOP_LEVEL_NAV_ITEMS}
                      renderSearchBar={() => <ParticipantSearchInput />}
                    />
                  ) : null}
                  <MutationErrorWarning />
                  <div className="app-contents">
                    <Router location={location}>
                      <Redirect from="/" to={`/${ROOT_PATH}/clients`} noThrow />
                      <Redirect
                        from={`/${ROOT_PATH}`}
                        to={`/${ROOT_PATH}/clients`}
                        noThrow
                      />
                      <Redirect
                        from={`/${ROOT_PATH}/clients/-/:caseManagerId/care-plan`}
                        to={`/${ROOT_PATH}/clients/-/:caseManagerId/status/active`}
                        noThrow
                      />
                      <PrintEventContainer
                        path={`/${ROOT_PATH}/print_event/:participantId/:eventId`}
                      />

                      <PrintTransmittal
                        path={`/${ROOT_PATH}/print_transmittal/:bookingGroupId`}
                      />

                      <PrintCourtMemo
                        path={`/${ROOT_PATH}/print_court_memo/:bookingId`}
                      />

                      <PrintRecommendation
                        path={`/${ROOT_PATH}/print_recommendation/:bookingId`}
                      />

                      {supportsBroadcastMessaging && (
                        <BroadcastMessagesScreen
                          path={`/${ROOT_PATH}/broadcast`}
                        />
                      )}
                      {supportsCaseAnalytics && (
                        <CaseAnalyticsScreen
                          path={`/${ROOT_PATH}/case-analytics`}
                        />
                      )}
                      {supportsCalendar && (
                        <CalendarScreen path={`/${ROOT_PATH}/calendar`} />
                      )}
                      {supportsBookings && (
                        <BookingsScreen path={`/${ROOT_PATH}/bookings`} />
                      )}
                      {supportsProspects && (
                        <ProspectsScreen path={`/${ROOT_PATH}/prospects`} />
                      )}

                      {supportsProspects && (
                        <ProspectDetailScreen
                          path={`/${ROOT_PATH}/prospects/:id/:section`}
                        />
                      )}

                      {supportsProspects && (
                        <ProspectsScreen
                          path={`/${ROOT_PATH}/prospects/:prospect_status`}
                        />
                      )}

                      {supportsClasses && (
                        <ClassesScreen path={`/${ROOT_PATH}/classes`} />
                      )}
                      <GroupChatScreen path={`/${ROOT_PATH}/group-messages`} />
                      <ParticipantListContainer
                        path={`/${ROOT_PATH}/clients`}
                      />
                      <ParticipantListContainer
                        path={`/${ROOT_PATH}/clients/:riskLevel`}
                      />
                      <ParticipantListContainer
                        path={`/${ROOT_PATH}/clients/:status/`}
                      />
                      <ParticipantListContainer
                        path={`/${ROOT_PATH}/clients/:riskLevel/:caseManagerId/status/:status`}
                      />
                      {/* This isn't really used anymore, but we're keeping it
                      around Because we have some old email links we've sent out
                      that link here */}
                      <Redirect
                        from={`/${ROOT_PATH}/clients/:name/:id`}
                        to={`${ROOT_PATH}/clients/:name/:id/${
                          participantDetailsTab[
                            getFeatureFlags().defaultTabInClientDetails ||
                              "carePlan"
                          ]
                        }`}
                        noThrow
                      />
                      <Redirect
                        from={`/${ROOT_PATH}/clients/id/:id`}
                        to={`${ROOT_PATH}/clients/id/:id/${
                          participantDetailsTab[
                            getFeatureFlags().defaultTabInClientDetails ||
                              "carePlan"
                          ]
                        }`}
                        noThrow
                      />
                      <ParticipantDetailsContainer
                        path={`/${ROOT_PATH}/clients/:name/:id/:selectedTab`}
                      />
                      {displayWorkflow && (
                        <WorkflowScreen
                          path={`/${ROOT_PATH}/workflow`}
                          appInfo={appInfo}
                          baseUri={`/${ROOT_PATH}/clients`}
                        />
                      )}
                      {displayWorkflow && (
                        <WorkflowScreen
                          path={`/${ROOT_PATH}/workflow/:caseManagerId`}
                          appInfo={appInfo}
                          baseUri={`/${ROOT_PATH}/clients`}
                        />
                      )}
                      <FourOhFour default />
                      <Redirect from="/" to={`/${ROOT_PATH}/clients`} noThrow />
                      <AnalyticsReportContainer
                        path={`/${ROOT_PATH}/analytics-report`}
                      />
                    </Router>
                  </div>
                  {!skipIsDev() && this.props.zendeskToken && (
                    <ZenDeskWidget token={this.props.zendeskToken} />
                  )}
                </div>
              );
            }}
          </Location>
          <Location>
            {({ location }) => <PageviewTracker pathname={location.pathname} />}
          </Location>
        </LocationProvider>
      </>
    );
  }
}
