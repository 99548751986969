/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type MutationResult = "failure" | "skipped" | "success" | "%future added value";
export type NeedStatus = "has" | "needs" | "obtained" | "unknown" | "%future added value";
export type RecidivismReason = "civil" | "new_charge" | "unknown" | "%future added value";
export type YesNoUnknown = "no" | "unknown" | "yes" | "%future added value";
export type caseAddMutationVariables = {
    readonly participant_id: string;
    readonly case_number: string;
};
export type caseAddMutationResponse = {
    readonly addCase: ({
        readonly case: ({
            readonly id: string;
            readonly case_number: string;
            readonly supervision_start_date: string | null;
            readonly supervision_end_date: string | null;
            readonly created_at: string;
            readonly updated_at: string | null;
            readonly housing: ({
                readonly status: NeedStatus | null;
            }) | null;
            readonly employment: ({
                readonly status: NeedStatus | null;
            }) | null;
            readonly education: ({
                readonly status: NeedStatus | null;
            }) | null;
            readonly treatment_types: ReadonlyArray<({
                readonly id: string;
            }) | null> | null;
            readonly group_meetings: ReadonlyArray<({
                readonly id: string;
            }) | null> | null;
            readonly courts: ReadonlyArray<({
                readonly id: string;
            }) | null> | null;
            readonly transportation: ({
                readonly status: NeedStatus | null;
            }) | null;
            readonly primary_treatment_facilities: ReadonlyArray<({
                readonly id: string;
            }) | null> | null;
            readonly did_recidivate: YesNoUnknown | null;
            readonly recidivism_reason: RecidivismReason | null;
            readonly case_type: string | null;
            readonly medicated: YesNoUnknown | null;
            readonly program_result: string | null;
            readonly risk_level: string | null;
            readonly assessment_score: string | null;
            readonly hand_off_to_probation: YesNoUnknown | null;
            readonly absconded: YesNoUnknown | null;
            readonly is_emh: YesNoUnknown | null;
            readonly risk_score: string | null;
        }) | null;
        readonly result: MutationResult;
    }) | null;
};
export type caseAddMutation = {
    readonly response: caseAddMutationResponse;
    readonly variables: caseAddMutationVariables;
};



/*
mutation caseAddMutation(
  $participant_id: String!
  $case_number: String!
) {
  addCase(participant_id: $participant_id, case_number: $case_number) {
    case {
      id
      case_number
      supervision_start_date
      supervision_end_date
      created_at
      updated_at
      housing {
        status
      }
      employment {
        status
      }
      education {
        status
      }
      treatment_types {
        id
      }
      group_meetings {
        id
      }
      courts {
        id
      }
      transportation {
        status
      }
      primary_treatment_facilities {
        id
      }
      did_recidivate
      recidivism_reason
      case_type
      medicated
      program_result
      risk_level
      assessment_score
      hand_off_to_probation
      absconded
      is_emh
      risk_score
    }
    result
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "participant_id",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "case_number",
            "type": "String!",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
        } as any)
    ], v3 = [
        (v1 /*: any*/)
    ], v4 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "addCase",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "case_number",
                    "variableName": "case_number"
                },
                {
                    "kind": "Variable",
                    "name": "participant_id",
                    "variableName": "participant_id"
                }
            ],
            "concreteType": "NewCaseResult",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "case",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Case",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "case_number",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "supervision_start_date",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "supervision_end_date",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "created_at",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "updated_at",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "housing",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CaseNeed",
                            "plural": false,
                            "selections": (v2 /*: any*/)
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "employment",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CaseNeed",
                            "plural": false,
                            "selections": (v2 /*: any*/)
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "education",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CaseNeed",
                            "plural": false,
                            "selections": (v2 /*: any*/)
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "treatment_types",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "IdField",
                            "plural": true,
                            "selections": (v3 /*: any*/)
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "group_meetings",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "IdField",
                            "plural": true,
                            "selections": (v3 /*: any*/)
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "courts",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "IdField",
                            "plural": true,
                            "selections": (v3 /*: any*/)
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "transportation",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CaseNeed",
                            "plural": false,
                            "selections": (v2 /*: any*/)
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "primary_treatment_facilities",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "IdField",
                            "plural": true,
                            "selections": (v3 /*: any*/)
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "did_recidivate",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "recidivism_reason",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "case_type",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "medicated",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "program_result",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "risk_level",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "assessment_score",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "hand_off_to_probation",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "absconded",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "is_emh",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "risk_score",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "result",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "caseAddMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v4 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "caseAddMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v4 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "caseAddMutation",
            "id": null,
            "text": "mutation caseAddMutation(\n  $participant_id: String!\n  $case_number: String!\n) {\n  addCase(participant_id: $participant_id, case_number: $case_number) {\n    case {\n      id\n      case_number\n      supervision_start_date\n      supervision_end_date\n      created_at\n      updated_at\n      housing {\n        status\n      }\n      employment {\n        status\n      }\n      education {\n        status\n      }\n      treatment_types {\n        id\n      }\n      group_meetings {\n        id\n      }\n      courts {\n        id\n      }\n      transportation {\n        status\n      }\n      primary_treatment_facilities {\n        id\n      }\n      did_recidivate\n      recidivism_reason\n      case_type\n      medicated\n      program_result\n      risk_level\n      assessment_score\n      hand_off_to_probation\n      absconded\n      is_emh\n      risk_score\n    }\n    result\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'd3d53f1e511c6402d2cca643c66ea993';
export default node;
