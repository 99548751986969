import React from "react";
import { QueryRenderer } from "../../shared/lib/graphql";
import { Participant } from "../../graphql-types";
import { CarePlanContainerProps } from "../care-plan/CarePlanContainer";
import { participantCasesGraphqlQuery } from "./ParticipantCasesGraphql";
import idx from "idx.macro";
import { safelyUnwrapArrayWithPossibleNullOrUndefined } from "../../utils/safeUnwrap";
import { CaseForm, CaseHeader } from "./CaseContainer";
import AddCase from "../modals/case/AddCase";
import { Tooltip } from "antd";
import { Button } from "antd";
import { AddCaseFormData } from "../modals/case/AddCaseModalWrapper";
import { Operation } from "../modals/types";
import { addCase } from "../../actions/case";
import { Collapse } from "antd";
import { customPanelStyle } from "../care-plan/CarePlan";

export interface ParticipantAssessmentQueryResult {
  participants: {
    participant: Array<Participant>;
  };
}

export function ParticipantCaseContainer(props: CarePlanContainerProps) {
  const { id } = props;
  const { Panel } = Collapse;
  return (
    <QueryRenderer
      query={participantCasesGraphqlQuery}
      variables={{ id }}
      SuccessComponent={(props: ParticipantAssessmentQueryResult) => {
        const participant = idx(props, (_) => _.participants.participant[0]);
        const { cases } = participant;
        const unwrappedCases = safelyUnwrapArrayWithPossibleNullOrUndefined(
          cases ? cases : []
        );

        return (
          <div>
            <div style={{ marginBottom: "1em" }}>
              <AddCase
                updateObject={{
                  first: participant.name.first,
                  last: participant.name.last,
                }}
                callBackForAPI={async (formData: AddCaseFormData) =>
                  await addCase({
                    participant_id: participant.id,
                    case_number: formData.caseNumber.value,
                  })
                }
                type="Case"
                operation={Operation.Add}
                buttonComponent={
                  <Tooltip title={`Add a new participant case`}>
                    <Button type={"primary"}>Add Case</Button>
                  </Tooltip>
                }
              />
            </div>
            {unwrappedCases.map((newCase, index) => (
              <Collapse>
                <Panel
                  key={index.toString()}
                  header={
                    <CaseHeader case={newCase} participant={participant} />
                  }
                  style={{ ...customPanelStyle }}
                >
                  <CaseForm
                    key={newCase.id}
                    case={newCase}
                    participant={participant}
                  />
                </Panel>
              </Collapse>
            ))}
          </div>
        );
      }}
    />
  );
}
