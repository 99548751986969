import * as React from "react";
import { ChatContainer } from "./ChatContainer";
import {
  UserConsumer,
  UserContextType,
} from "../../shared/lib/contexts/UserContext";

interface Props {
  groupId: string;
}
interface State {}

export default class GroupChat extends React.Component<Props, State> {
  render() {
    return (
      <div>
        <UserConsumer>
          {(userContext: UserContextType) => {
            return (
              <ChatContainer
                id=""
                groupId={this.props.groupId}
                userId={userContext.user ? userContext.user.uid : ""}
              />
            );
          }}
        </UserConsumer>
      </div>
    );
  }
}
