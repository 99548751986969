import React from "react";
import AddEventForm, { AddEventFormOptions } from "./AddEventForm";
import AddEventModalWrapper, { AddEventFormData } from "./AddEventModalWrapper";
import { ModalProps } from "../ModalWrapper";

export type AddEventModalProps = ModalProps<AddEventFormData> &
  AddEventFormOptions;

export default function ({
  callBackForAPI,
  operation,
  type,
  disableButton,
  buttonComponent,
  updateObject,
  ...formOptions
}: AddEventModalProps) {
  return (
    <AddEventModalWrapper
      callBackForAPI={callBackForAPI}
      type={type}
      disableButton={disableButton}
      buttonComponent={buttonComponent}
      operation={operation}
      updateObject={updateObject}
    >
      {(data: any) => (
        <AddEventForm {...formOptions} {...data} updateObject={updateObject} />
      )}
    </AddEventModalWrapper>
  );
}
