import React from "react";
import { Button, Input, Form, Icon, Row, Col, Typography } from "antd";
import { ParticipantContactFormEntry } from "./types";

interface Props {
  contacts: ParticipantContactFormEntry[];
  updateContact: (
    newContact: ParticipantContactFormEntry,
    indexToUpdate: number
  ) => void;
  removeContact: (index: number) => void;
  addContact: (contact: ParticipantContactFormEntry) => void;
  title: string;
}

export function ParticipantContactsForm(props: Props) {
  const { contacts, updateContact, removeContact, addContact, title } = props;

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    indexToChange: number
  ) => {
    const { name, value } = event.target;

    const victimToChange = contacts[indexToChange];
    updateContact({ ...victimToChange, [name]: value }, indexToChange);
  };

  const { Title } = Typography;

  return (
    <div>
      <Title level={4}>{title}</Title>
      {contacts.map((contact: ParticipantContactFormEntry, index) => (
        <div key={index}>
          <Row gutter={[16, 0]}>
            <Col span={3}>
              <Form.Item label="First name">
                <Input
                  value={contact.first_name}
                  name="first_name"
                  placeholder="e.g. John"
                  onChange={(event) => handleInputChange(event, index)}
                />
              </Form.Item>
            </Col>

            <Col span={3}>
              <Form.Item label="Last name">
                <Input
                  value={contact.last_name}
                  name="last_name"
                  placeholder="e.g. Doe"
                  onChange={(event) => handleInputChange(event, index)}
                />
              </Form.Item>
            </Col>

            <Col span={5}>
              <Form.Item label="Address">
                <Input
                  value={contact.address}
                  name="address"
                  placeholder="address"
                  onChange={(event) => handleInputChange(event, index)}
                />
              </Form.Item>
            </Col>

            <Col span={3}>
              <Form.Item label="Phone">
                <Input
                  value={contact.phone}
                  name="phone"
                  placeholder="(555)-555-5555"
                  onChange={(event) => handleInputChange(event, index)}
                />
              </Form.Item>
            </Col>

            <Col span={3}>
              <Form.Item label="Relationship">
                <Input
                  value={contact.relationship}
                  name="relationship"
                  placeholder="E.g. Wife"
                  onChange={(event) => handleInputChange(event, index)}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="How often seen">
                <Input
                  value={contact.how_often_seen}
                  name="how_often_seen"
                  placeholder="E.g. weekly"
                  onChange={(event) => handleInputChange(event, index)}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "103px",
                }}
              >
                <Button type="danger" onClick={() => removeContact(index)}>
                  Remove
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      ))}
      <Row>
        <Col span={3}>
          <Button
            type="dashed"
            onClick={() =>
              addContact({
                first_name: "",
                last_name: "",
                address: "",
                date_of_birth: undefined,
                phone: "",
                how_often_seen: "",
                relationship: "",
              })
            }
          >
            <Icon type="plus" /> Add
          </Button>
        </Col>
      </Row>
    </div>
  );
}
