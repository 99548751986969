import graphql from "babel-plugin-relay/macro";

import {
  Activity,
  ActivityMeta,
  Event,
  KeyedString,
  Obligation,
  Participant,
  EventTemplate,
} from "../../shared/lib/graphql";

export type CarePlanParticipantType = Pick<Participant, "id" | "name"> & {
  obligations: Array<CarePlanObligationType>;
};

export type CarePlanEventType = Pick<
  Event,
  | "id"
  | "date"
  | "attended"
  | "activity"
  | "required"
  | "service_provider"
  | "disposition"
  | "event_template"
>;

export type CarePlanActivityType = Pick<Activity, "id" | "type">;

export type CarePlanObligationType = Pick<
  Obligation,
  | "id"
  | "activity"
  | "event_templates"
  | "title"
  | "description"
  | "start"
  | "end"
  | "status"
  | "compliance"
  | "sub_address_for_event"
  | "is_writable"
  | "tag"
  | "cadence_option"
  | "day_of_week"
  | "day_of_month"
  | "verification_option"
> & { service_provider: CarePlanServiceProvider } & {
  event_templates: Array<CarePlanEventTemplateType>;
};

export type CarePlanServiceProvider = {
  id: string;
  title: string;
};

export type CarePlanEventTemplateType = Pick<
  EventTemplate,
  "id" | "exdate" | "rrule"
> & { events: Array<CarePlanEventType> };

export interface CarePlanQueryResult {
  activities: {
    activity: Array<CarePlanActivityType>;
  };
  application: {
    activity_information: Array<ActivityMeta>;
    compliance_options: KeyedString[];
    disposition_options: KeyedString[];
    obligation_status_options: KeyedString[];
  };
  service_providers: {
    service_provider: CarePlanServiceProvider[];
  };
  participants: {
    participant: Array<CarePlanParticipantType>;
  };
}

export const CarePlanQuery = graphql`
  query CarePlanQueryQuery($id: String!) {
    application {
      activity_information {
        key
        title
        care_plan {
          type
        }
      }
      compliance_options {
        key
        value
      }
      disposition_options {
        key
        value
      }
      obligation_status_options {
        key
        value
      }
    }
    service_providers {
      service_provider {
        id
        title
      }
    }
    participants(id: $id) {
      participant {
        id
        name {
          first
          last
        }
        assessment {
          score
          tool
        }
        preferred_name
        obligations {
          id
          title {
            en
          }
          description {
            en
          }
          activity {
            id
            type
            data_input_type
            data_input_field
          }
          service_provider {
            id
            title
          }
          sub_address_for_event
          status
          compliance
          start
          end
          tag
          is_writable
          cadence_option
          day_of_week
          day_of_month
          verification_option
          event_templates {
            id
            rrule
            exdate
            events {
              id
              date
              event_template {
                id
                rrule
              }
              activity {
                id
                title {
                  en
                }
                type
              }
              required
              service_provider {
                id
                address
                phone
                sub_address_for_event
                title
              }
              attended
              disposition
            }
          }
        }
      }
    }
  }
`;
