import graphql from "babel-plugin-relay/macro";

export const caseAnalyticsGraphqlQuery = graphql`
  query CaseAnalyticsGraphqlQuery(
    $risk_levels: [String]
    $primary_treatment_facilities: [String]
    $treatment_types: [String]
    $medicated: [String]
    $group_meetings: [String]
    $hand_off_to_probation: [String]
    $housing_statuses: [String]
    $education_statuses: [String]
    $employment_statuses: [String]
    $transportation_statuses: [String]
    $courts: [String]
    $case_type: [String]
    $weeks_in_program: [MinMaxInput]
    $absconded: [String]
    $is_emh: [String]
    $gender: [String]
  ) {
    case_stats(
      risk_levels: $risk_levels
      primary_treatment_facilities: $primary_treatment_facilities
      treatment_types: $treatment_types
      medicated: $medicated
      group_meetings: $group_meetings
      hand_off_to_probation: $hand_off_to_probation
      housing_statuses: $housing_statuses
      education_statuses: $education_statuses
      employment_statuses: $employment_statuses
      transportation_statuses: $transportation_statuses
      courts: $courts
      case_type: $case_type
      weeks_in_program: $weeks_in_program
      absconded: $absconded
      is_emh: $is_emh
      gender: $gender
    ) {
      total
      number_completed_total
      number_recidivated_total
      number_completed_successful
      number_completed_unsuccessful
      number_recidivism
      number_no_recidivism
      year
    }
  }
`;
