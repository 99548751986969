/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type MutationResult = "failure" | "skipped" | "success" | "%future added value";
export type goalsMarkParticipantGoalCompleteMutationVariables = {
    readonly participant_id: string;
    readonly goal_id: string;
    readonly is_completed?: boolean | null;
    readonly label?: string | null;
};
export type goalsMarkParticipantGoalCompleteMutationResponse = {
    readonly updateParticipantGoal: ({
        readonly goal: ({
            readonly id: string;
            readonly label: string;
            readonly created_at: string;
            readonly completed_at: string | null;
        }) | null;
        readonly result: MutationResult;
    }) | null;
};
export type goalsMarkParticipantGoalCompleteMutation = {
    readonly response: goalsMarkParticipantGoalCompleteMutationResponse;
    readonly variables: goalsMarkParticipantGoalCompleteMutationVariables;
};



/*
mutation goalsMarkParticipantGoalCompleteMutation(
  $participant_id: String!
  $goal_id: String!
  $is_completed: Boolean
  $label: String
) {
  updateParticipantGoal(participant_id: $participant_id, goal_id: $goal_id, is_completed: $is_completed, label: $label) {
    goal {
      id
      label
      created_at
      completed_at
    }
    result
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "participant_id",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "goal_id",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "is_completed",
            "type": "Boolean",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "label",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "updateParticipantGoal",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "goal_id",
                    "variableName": "goal_id"
                },
                {
                    "kind": "Variable",
                    "name": "is_completed",
                    "variableName": "is_completed"
                },
                {
                    "kind": "Variable",
                    "name": "label",
                    "variableName": "label"
                },
                {
                    "kind": "Variable",
                    "name": "participant_id",
                    "variableName": "participant_id"
                }
            ],
            "concreteType": "NewParticipantGoal",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "goal",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ParticipantGoal",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "label",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "created_at",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "completed_at",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "result",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "goalsMarkParticipantGoalCompleteMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "goalsMarkParticipantGoalCompleteMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "goalsMarkParticipantGoalCompleteMutation",
            "id": null,
            "text": "mutation goalsMarkParticipantGoalCompleteMutation(\n  $participant_id: String!\n  $goal_id: String!\n  $is_completed: Boolean\n  $label: String\n) {\n  updateParticipantGoal(participant_id: $participant_id, goal_id: $goal_id, is_completed: $is_completed, label: $label) {\n    goal {\n      id\n      label\n      created_at\n      completed_at\n    }\n    result\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'd19df851a9710af5ffcfa23038766024';
export default node;
