import { RiskFactor, ResearchFactor } from "../../graphql-types";
import { Moment } from "moment";

export enum InputType {
  Input = "Input",
  Date = "Date",
  Select = "Select",
  TextArea = "TextArea",
  Time = "Time",
}

export type InputFormDataWithInputType = InputFormData & {
  inputType: InputType;
} & {
  optionsArray?: SelectOption[];
};

export interface InputFormData {
  id: string;
  label: string;
  errorMessage: string;
  initialValue?: any;
  placeholder?: string;
  required: boolean;
  validationPattern?: RegExp;
}

export interface SelectFormData extends InputFormData {
  optionsArray: SelectOption[];
}

export interface ResearchFactorFormData extends SelectFormData {
  id: keyof ResearchFactor;
}

export interface RiskFactorFormData extends SelectFormData {
  id: keyof RiskFactor;
}

export interface SelectOption {
  id: string;
  title: string;
}

export interface ParticipantContactFormEntry {
  first_name: string;
  last_name: string;
  date_of_birth: Moment | undefined;
  address: string;
  phone: string;
  how_often_seen: string;
  relationship: string;
}
