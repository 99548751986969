import { MutationUpdateCaseArgs, MutationAddCaseArgs } from "../graphql-types";
import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "../shared/lib/graphql/commitMutation";
import { RecordSourceSelectorProxy } from "relay-runtime";
import { checkMutationReturn } from "./util";

export const addCase = async (variables: MutationAddCaseArgs) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation caseAddMutation(
          $participant_id: String!
          $case_number: String!
        ) {
          addCase(participant_id: $participant_id, case_number: $case_number) {
            case {
              id
              case_number
              supervision_start_date
              supervision_end_date
              created_at
              updated_at
              housing {
                status
              }
              employment {
                status
              }
              education {
                status
              }
              treatment_types {
                id
              }
              group_meetings {
                id
              }
              courts {
                id
              }
              transportation {
                status
              }
              primary_treatment_facilities {
                id
              }
              did_recidivate
              recidivism_reason
              case_type
              medicated
              program_result
              risk_level
              assessment_score
              hand_off_to_probation
              absconded
              is_emh
              risk_score
            }
            result
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreAddCase(store, "addCase", variables.participant_id);
      },
    },
    "Error while adding case."
  );
};

export const updateCase = async (variables: MutationUpdateCaseArgs) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation caseUpdateMutation(
          $case_id: String!
          $supervision_start_date: String
          $supervision_end_date: String
          $housing: NeedStatus
          $employment: NeedStatus
          $education: NeedStatus
          $transportation: NeedStatus
          $did_recidivate: YesNoUnknown
          $case_type: String
          $medicated: YesNoUnknown
          $program_result: String
          $risk_level: String
          $assessment_score: String
          $recidivism_reason: RecidivismReason
          $primary_treatment_facilities: [IdInput]
          $hand_off_to_probation: YesNoUnknown
          $treatment_types: [IdInput]
          $courts: [IdInput]
          $group_meetings: [IdInput]
          $absconded: YesNoUnknown
          $is_emh: YesNoUnknown
          $risk_score: String
        ) {
          updateCase(
            case_id: $case_id
            supervision_start_date: $supervision_start_date
            supervision_end_date: $supervision_end_date
            housing: $housing
            employment: $employment
            education: $education
            transportation: $transportation
            did_recidivate: $did_recidivate
            treatment_types: $treatment_types
            case_type: $case_type
            medicated: $medicated
            program_result: $program_result
            courts: $courts
            risk_level: $risk_level
            assessment_score: $assessment_score
            recidivism_reason: $recidivism_reason
            primary_treatment_facilities: $primary_treatment_facilities
            hand_off_to_probation: $hand_off_to_probation
            group_meetings: $group_meetings
            absconded: $absconded
            is_emh: $is_emh
            risk_score: $risk_score
          ) {
            case {
              id
              case_number
              supervision_start_date
              supervision_end_date
              created_at
              updated_at
              housing {
                status
              }
              employment {
                status
              }
              education {
                status
              }
              treatment_types {
                id
              }
              group_meetings {
                id
              }
              courts {
                id
              }
              transportation {
                status
              }
              primary_treatment_facilities {
                id
              }
              did_recidivate
              recidivism_reason
              case_type
              medicated
              program_result
              risk_level
              assessment_score
              hand_off_to_probation
              absconded
              is_emh
              risk_score
            }
            result
          }
        }
      `,
      variables,
      // updater: store => {
      //   updateRelayStoreUpdateCase(
      //     store,
      //     "updateCase",
      //     participantId,
      //     variables.case_id
      //   );
      // }
    },
    "Error while updating case."
  );
};

const updateRelayStoreAddCase = (
  store: RecordSourceSelectorProxy,
  rootFieldName: string,
  participant_id: string
) => {
  const payload = checkMutationReturn(store, rootFieldName);

  if (!payload) return;

  const newCase = payload.getLinkedRecord("case");

  const participant = store.get(participant_id);

  if (!participant) {
    console.error(
      `Error while updating the store after ${rootFieldName}.\n` +
        " Can't find the participant in the store."
    );
    return;
  }

  const caseResultList = participant.getLinkedRecords("cases");

  //add new cases to front of case list
  const combinedCaseResults = [newCase].concat(caseResultList);

  //set the new case
  participant.setLinkedRecords(combinedCaseResults, "cases");
};
