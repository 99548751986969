import ModalWrapperHOC from "../../HOCs/ModalWrapperHOC";
import { FormDataBase, FormField } from "../types";
import ModalWrapper from "../ModalWrapper";
import { ValidationsType, ValidationItemStates } from "../types";
import { getFeatureFlags } from "../../../featureFlags";
import { ParticipantDetailsType } from "../../screens/ParticipantDetailsQueryTypes";
import { ParticipantContextType } from "../../../utils/context";
import { arrayToDictionarySpecifyKeyValue } from "../../../shared/lib/modalFormHelpers";
import moment, { Moment } from "moment";

export interface ParticipantModalFormData extends FormDataBase {
  firstName: FormField;
  lastName: FormField;
  preferredName: FormField;
  probationNumber: FormField;
  mobileNumber: FormField;
  language: FormField;
  assessmentScore: FormField;
  assessmentInstrument: FormField;
  assessmentRiskLevel: FormField;
  compliance: FormField;
  startDate: FormField<Moment>;
  endDate: FormField<Moment> | FormField<null>;
  caseManager: FormField;
}

class AddParticipantModalWrapper extends ModalWrapper<
  ParticipantModalFormData,
  ParticipantDetailsType
> {
  static formState = getParticipantModalStatic(null, null);

  componentDidMount() {
    // on remount, refresh the static class prop formState
    // handles the two possible cases of receiving a specific participant (update)
    // or not receiving a specific participant (add)
    const { updateObject: participant, metaDataObject } = this.props;
    if (participant) {
      AddParticipantModalWrapper.formState = getParticipantModalStatic(
        participant,
        metaDataObject
      );
    } else {
      AddParticipantModalWrapper.formState = getParticipantModalStatic(
        null,
        null
      );
    }
  }
}

export default ModalWrapperHOC(AddParticipantModalWrapper);

function getParticipantModalStatic(
  participant: ParticipantDetailsType | null | undefined,
  metaDataObject: ParticipantContextType | null
) {
  // we have some default values from the database
  // but lack the corresponding UI labels, which come in from configs.
  // create a complete of quick dictionaries so that we can map values to labels

  const riskLevelsDictionary = arrayToDictionarySpecifyKeyValue(
    metaDataObject ? metaDataObject.riskLevelsSerialized : [],
    "key",
    "value"
  );

  const assessmentToolDictionary = arrayToDictionarySpecifyKeyValue(
    metaDataObject ? metaDataObject.assessmentToolsSerialized : [],
    "key",
    "value"
  );

  const complianceDictionary = arrayToDictionarySpecifyKeyValue(
    metaDataObject ? metaDataObject.complianceOptionsSerialized : [],
    "key",
    "value"
  );

  const basicFormData = {
    modalFormData: {
      firstName: {
        label: "First Name",
        value: participant ? participant.name.first : "",
      },
      lastName: {
        label: "Last Name",
        value: participant ? participant.name.last : "",
      },
      preferredName: {
        label: "Preferred Name",
        value:
          participant && participant.preferred_name
            ? participant.preferred_name
            : "",
      },
      probationNumber: {
        label: "Probation Number",
        value:
          participant && participant.probation_number
            ? participant.probation_number
            : "",
      },
      mobileNumber: {
        label: "Mobile Number",
        value: participant ? participant.phone.mobile : "",
      },
      language: {
        label: "Language",
        value: participant ? participant.language : "",
        text:
          participant && participant.language === "es" ? "Spanish" : "English",
      },
      assessmentRiskLevel: {
        label: getFeatureFlags().whatIsRiskLevelCalled || "Risk Level",
        value: participant ? participant.risk_level : "",
        text:
          participant && metaDataObject
            ? riskLevelsDictionary[participant.risk_level]
            : "",
      },
      compliance: {
        label: getFeatureFlags().whatIsComplianceCalled || "Compliance",
        value: participant ? participant.compliance : "",
        text:
          participant && metaDataObject
            ? complianceDictionary[participant.compliance]
            : "",
      },
      startDate: {
        label: "Supervision Begin Date",
        dateFormat: "dddd, MMMM Do YYYY",
        value:
          participant && participant.supervision_begin_date
            ? moment(participant.supervision_begin_date)
            : null,
      },
      endDate: {
        label: "Supervision End Date",
        dateFormat: "dddd, MMMM Do YYYY",
        value:
          participant && participant.supervision_end_date
            ? moment(participant.supervision_end_date)
            : null,
      },
      caseManager: {
        label: getFeatureFlags().caseManagerDisplayTerm,
        value: participant ? participant.case_manager.id : "",
        text: participant
          ? `${participant.case_manager.name.first} ${participant.case_manager.name.last}`
          : "",
      },
    } as ParticipantModalFormData,
    validations: {
      firstName: {
        state: participant
          ? ValidationItemStates.valid
          : ValidationItemStates.pristine,
        errors: [] as string[],
      },
      lastName: {
        state: participant
          ? ValidationItemStates.valid
          : ValidationItemStates.pristine,
        errors: [] as string[],
      },
      preferredName: {
        state: ValidationItemStates.valid,
        errors: [] as string[],
      },
      probationNumber: {
        state: ValidationItemStates.valid,
        errors: [] as string[],
      },
      mobileNumber: {
        state: ValidationItemStates.valid,
        errors: [] as string[],
      },
      language: {
        state: participant
          ? ValidationItemStates.valid
          : ValidationItemStates.pristine,
        errors: [] as string[],
      },
      assessmentRiskLevel: {
        state: participant
          ? ValidationItemStates.valid
          : ValidationItemStates.pristine,
        errors: [] as string[],
      },
      compliance: {
        state: ValidationItemStates.valid,
        errors: [] as string[],
      },
      caseManager: {
        state:
          participant && participant.case_manager.id
            ? ValidationItemStates.valid
            : ValidationItemStates.pristine,
        errors: [] as string[],
      },
      startDate: {
        state: ValidationItemStates.valid,
        errors: [] as string[],
      },
      endDate: { state: ValidationItemStates.valid, errors: [] as string[] },
    } as ValidationsType,
    attemptedSubmission: false,
    secondPage: false,
    showModal: false,
  };

  if (!getFeatureFlags().supportsAssessment) {
    return basicFormData;
  }

  const finalFormData = {
    ...basicFormData,
    modalFormData: {
      ...basicFormData.modalFormData,
      assessmentScore: {
        label: "Assessment Score",
        value: participant ? participant.assessment.score : "",
      },
      assessmentInstrument: {
        label: "Assessment Instrument",
        value: participant ? participant.assessment.tool : "",
        id: "",
        text:
          participant && metaDataObject
            ? assessmentToolDictionary[participant.assessment.tool]
            : "",
      },
    },
    validations: {
      ...basicFormData.validations,
      assessmentScore: {
        state:
          participant && participant.assessment.score
            ? ValidationItemStates.valid
            : ValidationItemStates.pristine,
        errors: [] as string[],
      },
      assessmentInstrument: {
        state:
          participant && participant.assessment.tool
            ? ValidationItemStates.valid
            : ValidationItemStates.pristine,
        errors: [] as string[],
      },
    },
  };

  return finalFormData;
}
