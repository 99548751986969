import moment from "moment-timezone";

export interface DatedItem {
  date: string;
}

export interface ParticipantListEventsFilterable {
  events: Array<DatedItem>;
}

export enum WhichEvents {
  all,
  past,
  upcoming,
}

/**
 * Return only the upcoming events inside the Participant objects
 *
 * 🚢 🐿
 *
 * @param items Items to filter based on events sub collection
 */
export const filterNextEvent = (
  items: Array<ParticipantListEventsFilterable>
) => {
  if (!items || items.length === 0) return items;

  return items.map((item) => ({
    ...item,
    events: [sortEvents(item.events, WhichEvents.upcoming)[0]],
  }));
};

/**
 * Sort some events
 * DO NOT USE THIS TO DISPLAY EVENTS IN A GIVEN SORT ORDER. Do that on the backend.
 *
 * @param events Events to sort
 * @param which Which events to include
 * @param oldestFirst self-describing
 */
export const sortEvents = <T extends DatedItem>(
  events: Array<T> = [],
  which: WhichEvents,
  oldestFirst: boolean = false
) => {
  const sortValues = oldestFirst ? [-1, 1] : [1, -1];
  const now = moment();

  let workingEvents: Array<T> = [];
  switch (which) {
    case WhichEvents.all:
      workingEvents = events.slice();
      break;
    case WhichEvents.upcoming:
      workingEvents = events.filter(({ date }) =>
        moment(date).isSameOrAfter(now)
      );
      break;
    case WhichEvents.past:
      workingEvents = events.filter(({ date }) =>
        moment(date).isSameOrBefore(now)
      );
      break;
  }

  if (workingEvents.length === 0) return workingEvents;

  workingEvents.sort(({ date: a }, { date: b }) => {
    if (a === b) return 0;
    else
      return moment(a).isSameOrBefore(moment(b))
        ? sortValues[0]
        : sortValues[1];
  });

  return workingEvents;
};
