import graphql from "babel-plugin-relay/macro";

export const participantDocumentsQuery = graphql`
  query ParticipantDocumentsGraphqlQuery($id: String!) {
    me {
      case_manager {
        id
        name {
          first
          last
        }
      }
    }
    participants(id: $id) {
      participant {
        id
        documents {
          id
          name
          mime_type
          filepath
          case_manager_name
          created_at
        }
      }
    }
  }
`;
