import * as React from "react";
import "./SummaryTable.scss";
import SectionTitle from "./SectionTitle";

interface Props {
  title?: string;
  data: Array<{ label: string; value: number | string }>;
}

export default class SummaryTable extends React.Component<Props> {
  render() {
    return (
      <div className="summaryTable">
        {this.props.title && <SectionTitle>{this.props.title}</SectionTitle>}
        {this.props.data.length > 0 ? this._renderTable() : this._renderEmpty()}
      </div>
    );
  }

  _renderEmpty() {
    return <div>No data available</div>;
  }

  _renderTable() {
    return (
      <div className="summaryColumns columns">
        {this.props.data.map(({ label, value }) => (
          <div key={label} className="column">
            <div className="label">{label}</div>
            <div className="value">{value}</div>
          </div>
        ))}
      </div>
    );
  }
}
