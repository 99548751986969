import * as React from "react";
import idx from "idx.macro";
import { Link } from "@reach/router";
import moment from "moment-timezone";
import { Tag } from "react-bulma-components";
import { Email } from "@material-ui/icons";

import { displayEvent, formatDateTimeForDisplay } from "./events/displayEvent";
import { PersonName, Omit, KeyedString } from "../../lib/graphql";
import {
  NameComponentType,
  ParticipantItem,
  TableColumnKeyType,
  ParticipantListEventType,
  UrlBuilderFn,
  ColumnHeaderMapping,
} from "./ParticipantListTypes";
import { TableColumn, TableData } from "../table/tableTypes";
import { sortEvents, WhichEvents } from "./ParticipantListEventFilters";
import "./css/ParticipantTable.scss";
import { ParticipantListEventsCell } from "./ParticipantListEventsCell";
import { ParticipantListTablePassThroughPropsAllKeys } from "./ParticipantListTable";
import {
  flattenSMSControls,
  smsOptions,
} from "../../util/determine_sms_status";
import { humanizeDateOverLimit } from "../../lib/util";
import { AddressComponent } from "../elements/AddressComponent";
import {
  COMPLIANCE_DAYS_THRESHOLD,
  HUMANIZE_DAYS_THREDHOLD,
} from "../../util/dashboard_constants";
import { Tooltip } from "@material-ui/core";
import EventDetailsQueryWrapper from "../../../components/modals/events/EventDetailsQueryWrapper";
import DataUpdateNeededQueryWrapper from "../../../components/elements/DataUpdateNeededQueryWrapper";
export type AlwaysableTableColumn<TItem> = {
  always?: boolean;
  at?: number;
} & TableColumn<TItem, TableColumnKeyType>;

export interface KnownColumnPassthroughOptions<TItem extends ParticipantItem> {
  additionalColumns?: AlwaysableTableColumn<TItem>[];
  supportsProgramCompliance?: boolean;
  whatIsComplianceCalled?: string;
  whatIsRiskLevelCalled?: string;
  clientDisplayTerm?: string;
  columnsToDisplay?: string[];
  complianceOptions?: Array<KeyedString>;
  startDateHumanizeCutoff?: number;
  urlBuilder?: UrlBuilderFn;
}

export interface KnownColumnOptions<TItem extends ParticipantItem>
  extends KnownColumnPassthroughOptions<TItem> {
  Name?: NameComponentType;
  participants: Array<ParticipantItem>;
  colorMapper?: (val?: string) => string | null;
  columnNameRemaps: ColumnHeaderMapping;
}

export type getKnownColumnsArgs = {
  Name: NameComponentType;
  colorMapper: any; // not enforcing this type here
  participants: any; // not enforcing this type here
  columnNameRemaps: ColumnHeaderMapping;
} & Omit<ParticipantListTablePassThroughPropsAllKeys, "nameComponent">;

const blackListedNextActivityEventsToDisplay = new Set([
  "activity_AA Meeting Confirmation",
  "activity_AA Meeting Verification",
  "activity_Address Confirmation",
  "activity_Address Verification",
  "activity_Employment Confirmation",
  "activity_Employment Verification",
  "activity_Law Enforcement Contact Confirmation",
]);

function getNextEventExcludingCheckInEvents(
  events: ParticipantListEventType[]
): ParticipantListEventType {
  return getNextEvent(events, true);
}

function getNextEventIncludingCheckInEvents(
  events: ParticipantListEventType[]
): ParticipantListEventType {
  return getNextEvent(events, false);
}

function getNextEvent(
  events: ParticipantListEventType[],
  excludeCheckInEvents: boolean
) {
  const filteredEvents = excludeCheckInEvents
    ? events.filter(({ activity }) => {
        const { id } = activity;
        return !blackListedNextActivityEventsToDisplay.has(id);
      })
    : events;
  const sortedEvents = sortEvents(filteredEvents, WhichEvents.upcoming, true);
  return idx(sortedEvents, (_) => _[0]);
}

const getHeader = (
  id: string,
  columnHeaders: ColumnHeaderMapping,
  defaultHeader?: string
): string => {
  return columnHeaders.hasOwnProperty(id)
    ? columnHeaders[id]
    : defaultHeader || "";
};

export const getKnownColumns = function <TItem extends ParticipantItem>(
  options?: KnownColumnOptions<TItem>
): Array<AlwaysableTableColumn<TItem>> {
  const eventLoaderComponent = (
    <span className="has-text-info eventColumn"> </span>
  );

  const {
    Name,
    colorMapper,
    // columnsToDisplay,
    participants,
    clientDisplayTerm = "Client",
    whatIsComplianceCalled = "Compliance",
    whatIsRiskLevelCalled = "Risk Level",
    urlBuilder,
    columnNameRemaps = {},
  } = options || ({} as KnownColumnOptions<TItem>);

  const participantIdToEvents: {
    [key: string]: Array<ParticipantListEventType>;
  } = {};

  const columnHeaders = { ...columnNameRemaps };

  if (clientDisplayTerm) {
    columnHeaders["name"] = clientDisplayTerm;
  }
  columnHeaders["compliance"] = whatIsComplianceCalled;
  columnHeaders["risk_level"] = whatIsRiskLevelCalled;

  const KNOWN_TABLE_COLUMNS: Array<
    { always?: boolean } & TableColumn<TItem, TableColumnKeyType>
  > = [
    {
      key: "name",
      id: "name",
      defaultSorting: "asc",
      header: getHeader("name", columnHeaders, "Client"),
      sortable: false,
      dataStyle: { textDecoration: "underline" },
      render: (name: PersonName, _: number, { id, preferred_name }: TItem) =>
        Name ? (
          <Name id={id} name={name} preferred_name={preferred_name} />
        ) : null,
      ascSortFunction: (data: TableData<TItem>) => {
        data.sort((a: TItem, b: TItem) => {
          let aname = a.name && a.name.last && a.name.last.toLowerCase();
          let bname = b.name && b.name.last && b.name.last.toLowerCase();

          if (aname && bname && aname > bname) {
            return 1;
          }
          if (aname && bname && aname < bname) {
            return -1;
          }
          return 0;
        });

        return data;
      },
    },
    {
      key: "name",
      id: "name-sorted-by-events",
      defaultSorting: "asc",
      header: getHeader("name", columnHeaders, "Client"),
      sortable: false,
      ascSortFunction: (data: TableData<TItem>) => {
        // if there are events, sort by date
        // TODO the casting here implies there's a mistake in our typing / query somewhere
        if (data.length > 0 && (data[0] as any).events) {
          data.sort((a: TItem, b: TItem) => {
            const sortedAEvents = sortEvents(
              a.events,
              WhichEvents.upcoming,
              true
            );
            const nextAEvent: ParticipantListEventType = idx(
              sortedAEvents,
              (_) => _[0]
            );
            if (!nextAEvent) {
              return 1;
            }
            const sortedBEvents = sortEvents(
              b.events,
              WhichEvents.upcoming,
              true
            );
            const nextBEvent: ParticipantListEventType = idx(
              sortedBEvents,
              (_) => _[0]
            );
            if (!nextBEvent) {
              return -1;
            }

            // if dates are the same (cmp === 0), sort participants again by name
            // otherwise, in order to get descending order, flip the cmp values
            const cmp = nextBEvent.date.localeCompare(nextAEvent.date);
            if (cmp === 0) {
              let aname = a.name && a.name.first;
              let bname = b.name && b.name.first;

              if (aname && bname && aname < bname) {
                return -1;
              }
              if (aname && bname && aname > bname) {
                return 1;
              }
            } else if (cmp === 1) {
              return -1;
            } else if (cmp === -1) {
              return 1;
            }
            return cmp;
          });

          // if there are no events, sort by participant name
        } else {
          data.sort((a: TItem, b: TItem) => {
            let aname = a.name && a.name.last && a.name.last.toLowerCase();
            let bname = b.name && b.name.last && b.name.last.toLowerCase();

            if (aname && bname && aname > bname) {
              return 1;
            }
            if (aname && bname && aname < bname) {
              return -1;
            }
            return 0;
          });
        }
        return data;
      },
      dataStyle: { textDecoration: "underline" },
      render: (name: PersonName, _: number, { id, preferred_name }: TItem) =>
        Name ? (
          <Name id={id} name={name} preferred_name={preferred_name} />
        ) : null,
    },
    {
      key: "sent_message_unread_count",
      id: "sent_message_unread_count",
      header: "",
      render: (val: string, _: number, item: TItem) => {
        if (!val || parseInt(val) === 0 || isNaN(parseInt(val))) return null;

        const contents = (
          <span className="message-unread-count">
            <Email className="tableIcon highlight" />
            {val}
          </span>
        );

        if (!urlBuilder) return contents;

        const url = urlBuilder(item)["messages-notes"];

        return url ? <Link to={url}>{contents}</Link> : contents;
      },
    },
    {
      always: true,
      key: "data_update_neeeded",
      id: "data_update_needed",
      header: getHeader("data_update_needed", columnHeaders, " Update Needed"), // whatIsRiskLevelCalled is required, so default should never be used
      render: (_: null, __: number, item: any) => {
        return <DataUpdateNeededQueryWrapper participantId={item.id} />;
      },
    },
    {
      always: true,
      key: "case_number",
      id: "case_number",
      header: getHeader("case_number", columnHeaders, "Case Number"),
      render: (val: string, _: number, __: TItem) => {
        return val || "Unknown";
      },
    },
    {
      always: false,
      key: "compliance",
      id: "compliance",
      header: getHeader("compliance", columnHeaders, "Compliance"), // default should never be used since whatIsComplianceCalled is required
      render: (val: string, _: number, __: TItem) => {
        return colorMapper ? (
          <Tag color={colorMapper(val)}>{val || "Not Set"}</Tag>
        ) : null;
      },
    },
    {
      always: false,
      key: "deactivated_reason",
      id: "deactivated_reason",
      header: getHeader(
        "deactivated_reason",
        columnHeaders,
        "Deactivated reason"
      ),
      render: (val: string, _: number, __: TItem) => {
        return colorMapper ? (
          <Tag color={colorMapper(val)}>{val || "Not Set"}</Tag>
        ) : null;
      },
    },
    {
      always: true,
      key: "tasks",
      id: "tasks",
      dataStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      header: getHeader("tasks", columnHeaders, "Tasks Completed"),
      render: (__: any, _: number, { tasks }: TItem) => {
        let completed =
          tasks && tasks.filter((task) => task.completed_at).length.toString();
        return tasks ? (
          <span>{`${completed} / ${tasks.length.toString()}`}</span>
        ) : null;
      },
    },
    {
      key: "supervision_begin_date",
      id: "start_date",
      header: "Time in Program",
      render: (startDate: string, _: number, participant: TItem) => {
        const {
          supervision_begin_date: supervisionBeginDate,
          supervision_end_date: supervisionEndDate,
        } = participant;

        if (supervisionBeginDate && supervisionEndDate) {
          /*
          There are a few cases when we have supervision begin + end dates.

          1. Today's date is before the supervision begin date, return nothing
          2. Today's date is between the supervision begin and end date, return time between supervision begin and today
          3. Today's date is after the supervision end / begin date, return time between supervision begin and end date
         */

          const supervisionBeginMoment = moment(supervisionBeginDate);
          const supervisionEndMoment = moment(supervisionEndDate);
          const currentMoment = moment();

          if (currentMoment.isBefore(supervisionBeginMoment)) {
            return "0 days";
          }

          const duration =
            currentMoment.isAfter(supervisionBeginMoment) &&
            currentMoment.isBefore(supervisionEndMoment)
              ? moment.duration(currentMoment.diff(supervisionBeginMoment))
              : moment.duration(
                  supervisionEndMoment.diff(supervisionBeginMoment)
                );

          return duration.asDays() > 7
            ? `${Math.round(duration.asWeeks())} weeks`
            : `${Math.ceil(duration.asDays())} days`;
        }

        const mdate = moment(startDate);
        const spanAttributes = !mdate.isValid
          ? ""
          : { title: mdate.format("MMMM DD, YYYY") };

        if (!supervisionBeginDate) {
          return "";
        }

        const startMoment = moment(supervisionBeginDate);
        const finishMoment = moment();

        const duration = moment.duration(finishMoment.diff(startMoment));
        const humanized =
          duration.asDays() > 7
            ? `${Math.round(duration.asWeeks())} weeks`
            : `${Math.ceil(duration.asDays())} days`;

        return <span {...spanAttributes}>{humanized}</span>;
      },
    },
    {
      key: "supervision_begin_date",
      id: "start_date_date",
      header: "Start Date",
      render: (endDate: string, _: number, __: TItem) => {
        const mdate = moment(endDate);
        const spanAttributes = !mdate.isValid
          ? ""
          : { title: mdate.format("MMMM DD, YYYY") };

        return <span {...spanAttributes}>{mdate.format("MMMM DD, YYYY")}</span>;
      },
    },
    {
      key: "supervision_end_date",
      id: "end_date",
      header: "End Date",
      render: (endDate: string, _: number, __: TItem) => {
        const mdate = moment(endDate);
        const spanAttributes = !mdate.isValid
          ? ""
          : { title: mdate.format("MMMM DD, YYYY") };

        return <span {...spanAttributes}>{mdate.format("MMMM DD, YYYY")}</span>;
      },
    },
    {
      always: true,
      key: "weeks_in_program",
      id: "weeks_in_program",
      header: getHeader("last_value", columnHeaders, "Weeks in Program"),
      render: (_: null, __: number, item: any) => {
        const beginDate = idx(
          item,
          (_) => _.participant.supervision_begin_date
        );
        const endDate = idx(item, (_) => _.participant.supervision_end_date);
        if (beginDate && endDate) {
          const endMoment = moment(endDate);
          const beginMoment = moment(beginDate);
          const duration = moment.duration(endMoment.diff(beginMoment));
          const hours = duration.weeks();
          return hours;
        }
        return "N/A";
      },
    },
    {
      key: "obligation",
      header: getHeader("obligation", columnHeaders, "Obligation"),
      render: (obligation: any, _: number, { id }: TItem) => {
        if (!obligation || !obligation.title || !obligation.title.en) {
          console.error(
            `Unexpected obligation structure for review item ${id}`
          );
          return null;
        }
        const syncedStr = obligation.is_writable ? "" : " (synced)";

        return (
          <span>
            {obligation.title.en}
            {syncedStr}
          </span>
        );
      },
    },
    {
      always: true,
      key: "obligation-title-only",
      id: "obligation-title-only",
      header: getHeader("obligation-title-only", columnHeaders, "Obligation"),
      render: (_: any, __: number, { id, obligation }: any) => {
        const obligationTitle = idx(obligation, (_) => _.title.en);
        if (!obligationTitle) {
          console.error(
            `Unexpected obligation structure for review item ${id}`
          );
          return null;
        }
        return <span>{obligationTitle}</span>;
      },
    },
    {
      always: true,
      key: "events",
      id: "next-events-sync",
      defaultSorting: "desc",
      header: getHeader("next-events-sync", columnHeaders, "Next Event"),
      render: (_: any, __: number, { events }: any) => {
        const sortedEvents = sortEvents(
          events,
          WhichEvents.upcoming,
          true
        ) as Array<ParticipantListEventType>;
        const nextEvent = idx(sortedEvents, (_) => _[0]);
        return displayEvent(nextEvent).eventInfo;
      },
    },
    {
      always: true,
      key: "event",
      id: "next-events-dates-sync",
      defaultSorting: "desc",
      sortable: false,
      header: getHeader(
        "next-events-dates-sync",
        columnHeaders,
        "Next Event Date"
      ),
      render: (_: any, __: number, { events }: any) => {
        const sortedEvents = sortEvents(
          events,
          WhichEvents.upcoming,
          true
        ) as Array<ParticipantListEventType>;
        const nextEvent = idx(sortedEvents, (_) => _[0]);
        return displayEvent(nextEvent).eventTime;
      },
    },
    {
      always: true,
      key: "events",
      id: "next-events",
      defaultSorting: "desc",
      header: getHeader("next-events", columnHeaders, "Next Event"),
      render: (_: any, __: number, { id }: any) => (
        <ParticipantListEventsCell
          participants={participants}
          participantId={id}
          loadingComponent={eventLoaderComponent}
        >
          {(events: Array<ParticipantListEventType>) => {
            participantIdToEvents[id] = events;

            const nextEvent = getNextEventExcludingCheckInEvents(events);

            return displayEvent(nextEvent).eventInfo;
          }}
        </ParticipantListEventsCell>
      ),
    },
    {
      always: true,
      key: "events",
      id: "next-events-include-check-in",
      defaultSorting: "desc",
      header: getHeader("next-events", columnHeaders, "Next Event"),
      render: (_: any, __: number, { id }: any) => (
        <ParticipantListEventsCell
          participants={participants}
          participantId={id}
          loadingComponent={eventLoaderComponent}
        >
          {(events: Array<ParticipantListEventType>) => {
            participantIdToEvents[id] = events;
            const nextEvent = getNextEventIncludingCheckInEvents(events);
            return displayEvent(nextEvent).eventInfo;
          }}
        </ParticipantListEventsCell>
      ),
    },
    {
      always: true,
      key: "event",
      id: "next-events-dates",
      defaultSorting: "desc",
      sortable: false,
      header: getHeader("next-events-dates", columnHeaders, "Next Event Date"),
      render: (_: any, __: number, { id }: any) => (
        <ParticipantListEventsCell
          participants={participants}
          participantId={id}
          loadingComponent={eventLoaderComponent}
        >
          {(events: Array<ParticipantListEventType>) => {
            participantIdToEvents[id] = events;

            const nextEvent = getNextEventExcludingCheckInEvents(events);

            return displayEvent(nextEvent).eventTime;
          }}
        </ParticipantListEventsCell>
      ),
    },
    {
      always: true,
      key: "event",
      id: "next-events-include-check-in-dates",
      defaultSorting: "desc",
      sortable: false,
      header: getHeader("next-events-dates", columnHeaders, "Next Event Date"),
      render: (_: any, __: number, { id }: any) => (
        <ParticipantListEventsCell
          participants={participants}
          participantId={id}
          loadingComponent={eventLoaderComponent}
        >
          {(events: Array<ParticipantListEventType>) => {
            participantIdToEvents[id] = events;

            const nextEvent = getNextEventIncludingCheckInEvents(events);

            return displayEvent(nextEvent).eventTime;
          }}
        </ParticipantListEventsCell>
      ),
    },

    {
      key: "id",
      id: "last-events",
      defaultSorting: "desc",
      header: getHeader("last-events", columnHeaders, "Last Event"),
      render: (participantId: any, _: number) => {
        return (
          <ParticipantListEventsCell
            participants={participants}
            participantId={participantId}
            loadingComponent={eventLoaderComponent}
          >
            {(events: Array<ParticipantListEventType>) => {
              participantIdToEvents[participantId] = events;
              const sortedEvents = sortEvents(events, WhichEvents.past);
              const lastEvent: ParticipantListEventType = idx(
                sortedEvents,
                (_) => _[0]
              );
              if (!lastEvent)
                return (
                  <span className="has-text-info eventColumn">
                    No past events
                  </span>
                );

              return displayEvent(lastEvent).eventInfo;
            }}
          </ParticipantListEventsCell>
        );
      },
    },
    {
      key: "id",
      id: "last-events-dates",
      defaultSorting: "desc",
      sortable: false,
      header: getHeader("last-events-dates", columnHeaders, "Last Event"),
      style: { borderLeft: "10px solid white" },
      render: (participantId: any, _: number) => {
        return (
          <ParticipantListEventsCell
            participants={participants}
            participantId={participantId}
            loadingComponent={eventLoaderComponent}
          >
            {(events: Array<ParticipantListEventType>) => {
              const sortedEvents = sortEvents(events, WhichEvents.past);
              const lastEvent: ParticipantListEventType = idx(
                sortedEvents,
                (_) => _[0]
              );
              if (!lastEvent)
                return <span className="has-text-info eventDateColumn" />;

              const { date } = lastEvent;
              const eventTime = moment(date).format("MMM DD, YYYY");
              return <span className="eventDateColumn">{eventTime}</span>;
            }}
          </ParticipantListEventsCell>
        );
      },
    },
    {
      always: true,
      key: "events",
      id: "specific-event",
      defaultSorting: "desc",
      header: getHeader("specific-event", columnHeaders, "Past Event"),
      render: (_: any, __: number, { event }: any) => {
        return displayEvent(event).eventInfo;
      },
    },
    {
      always: true,
      key: "events",
      id: "specific-event-date",
      defaultSorting: "desc",
      header: getHeader(
        "specific-event-date",
        columnHeaders,
        "Past Event Date"
      ),
      render: (_: any, __: number, { event }: any) => {
        return displayEvent(event).eventTime;
      },
    },
    {
      always: true,
      key: "program_title",
      id: "program_title",
      header: getHeader("program_title", columnHeaders, "Program"),
    },
    {
      always: true,
      key: "risk_level",
      id: "risk_level",
      header: getHeader("risk_level", columnHeaders, "Risk Level"), // whatIsRiskLevelCalled is required, so default should never be used
      render: (val: string, _: number, __: TItem) => {
        return val || "Unknown";
      },
    },
    {
      always: true,
      key: "current_device",
      id: "current_device",
      header: getHeader("current_device", columnHeaders, "Has App"), // whatIsRiskLevelCalled is required, so default should never be used
      render: (val: { platform: string }, _: number, __: TItem) => {
        if (!val) {
          return "No";
        }
        const { platform } = val;
        return platform ? "Yes" : "No";
      },
    },
    {
      key: "case_manager",
      id: "case_manager",
      header: getHeader("case_manager", columnHeaders, "Case Manager"),
      render: (caseManager: { name: PersonName }, _: number, __: TItem) => {
        if (!caseManager || !caseManager.name) return null;
        const {
          name: { first, last },
        } = caseManager;
        return `${first} ${last}`;
      },
    },
    {
      always: true,
      key: "sms_status",
      id: "sms_status",
      header: getHeader("sms_status", columnHeaders, "SMS Status"),
      render: (
        _: null,
        __: number,
        { sms_enabled, sms_consent, phone: { mobile } }: TItem
      ) => {
        const flatMessagingState = flattenSMSControls(
          sms_enabled,
          sms_consent,
          mobile
        );
        return smsOptions.find((o) => o.key === flatMessagingState)!.value;
      },
    },
    {
      always: true,
      key: "address",
      id: "address",
      header: getHeader("address", columnHeaders, "Address"),
      render: (_: null, __: number, { address }: TItem) => {
        const flatAddress = (address && address.value) || "";
        return <AddressComponent address={flatAddress} />;
      },
    },
    {
      always: true,
      key: "next_input_event",
      id: "next_input_event",
      header: getHeader("next_input_event", columnHeaders, "Due"),
      render: (_: null, __: number, item: any) => {
        const date = idx(item, (_) => _.obligation.next_input_event.date);
        if (!date) {
          return "Unknown";
        }

        const lastInputMoment = moment(date);
        const daysOutOfCompliance = moment
          .duration(moment(new Date()).diff(lastInputMoment))
          .asDays();
        let text: string;
        let color: string;
        if (daysOutOfCompliance < 0) {
          color = "success";
          text = "Compliant";
        } else {
          text = humanizeDateOverLimit(date, HUMANIZE_DAYS_THREDHOLD);
          color =
            daysOutOfCompliance <= COMPLIANCE_DAYS_THRESHOLD
              ? "warning"
              : "danger";
        }

        return (
          <Tooltip title={formatDateTimeForDisplay(lastInputMoment)}>
            <Tag color={color}>{text}</Tag>
          </Tooltip>
        );
      },
    },
    {
      always: true,
      key: "last_input_event",
      id: "last_input_event",
      header: getHeader("last_input_event", columnHeaders, "Data Submitted"),
      render: (_: null, __: number, item: any) => {
        const date = idx(item, (_) => _.obligation.last_input_event.date);
        return formatDateTimeForDisplay(date);
      },
    },
    {
      always: true,
      key: "input_last_value",
      id: "input_last_value",
      header: getHeader("last_value", columnHeaders, "Old Data"),
      render: (_: null, __: number, item: any) => {
        const field = idx(item, (_) => _.event.input_field_name);
        return idx(item, (_) => _.participant[field].last_value);
      },
    },
    {
      always: true,
      key: "input_value",
      id: "input_value",
      header: getHeader("value", columnHeaders, "New Data"),
      render: (_: null, __: number, item: any) => {
        const field = idx(item, (_) => _.event.input_field_name);
        return idx(item, (_) => _.participant[field].value);
      },
    },
    {
      always: true,
      key: "obligation_input_value",
      id: "obligation_input_value",
      header: getHeader("obligation_input_value", columnHeaders, "LE Contact"),
      render: (_: null, __: number, item: any) => {
        const status = idx(
          item,
          (_) => _.participant["law_enforcement_contact"].value
        );

        if (!status) {
          return;
        }

        return (
          <Tag color={status.toLowerCase() === "no" ? "success" : "danger"}>
            {status}
          </Tag>
        );
      },
    },
    {
      always: true,
      key: "image_upload",
      id: "image_upload",
      header: getHeader(
        "obligation_input_value",
        columnHeaders,
        "Image Upload"
      ),

      render: (_: null, __: number, item: any) => {
        return (
          <EventDetailsQueryWrapper
            participantId={item.participant.id}
            eventId={item.event.id}
            mediaOnly={true}
          />
        );
      },
    },
  ].map((columnDef) => {
    const mapped = { sortable: false };

    // id isn't required, but is required to display - ugg, just default it if possible
    if (!columnDef.id) columnDef.id = columnDef.key as string;

    return {
      ...mapped,
      ...columnDef,
    };
  });

  return KNOWN_TABLE_COLUMNS;
};

export const KNOWN_PARTICIPANT_COLUMNS = getKnownColumns().map(
  ({ key, header }) => ({
    key,
    header,
  })
);
