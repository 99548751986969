import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import graphql from "babel-plugin-relay/macro";
import idx from "idx.macro";

import PrintEvent from "../elements/PrintEvent";
import { StringLike } from "@firebase/util";
import { Participant, QueryRenderer } from "../../shared/lib/graphql";
import sniffProject from "../../shared/lib/sniffProject";

interface Props {
  eventId: string;
  participantId: StringLike;
}

interface PrintEventResponse {
  participants: {
    participant: Participant[];
  };
}

export default class PrintEventContainer extends React.Component<
  RouteComponentProps<Props>
> {
  componentDidMount() {
    document.title = " ";
  }
  render() {
    return (
      <QueryRenderer
        query={graphql`
          query PrintEventContainerQuery(
            $participant_id: String
            $event_id: String
          ) {
            participants(id: $participant_id) {
              participant {
                id
                name {
                  first
                  last
                }
                case_manager {
                  id
                  phone {
                    mobile
                  }
                  name {
                    first
                    last
                  }
                }
                language
                events(id: $event_id) {
                  id
                  date
                  activity {
                    title {
                      en
                      es
                    }
                  }
                  obligation {
                    title {
                      en
                      es
                    }
                  }
                  service_provider {
                    address
                  }
                }
              }
            }
          }
        `}
        variables={{
          participant_id: this.props.participantId,
          event_id: this.props.eventId,
        }}
        SuccessComponent={(props: PrintEventResponse) => {
          const participant = idx(props, (_) => _.participants.participant[0]);
          const event = idx(
            props,
            (_) => _.participants.participant[0].events[0]
          );

          return (
            <PrintEvent
              projectId={sniffProject()}
              participant={participant}
              event={event}
              onReady={() => {
                setTimeout(() => {
                  window.print();
                }, 5000);
              }}
            />
          );
        }}
      />
    );
  }
}
