import * as React from "react";

interface Props {
  address: string;
}

export class AddressComponent extends React.PureComponent<Props> {
  render() {
    const { address } = this.props;
    if (!address) {
      return <div>None Provided</div>;
    }
    const url = `https://maps.google.com/?q=${encodeURIComponent(address)}`;
    return (
      <div>
        <a href={url} target="_blank">
          {address}
        </a>
      </div>
    );
  }
}
