import "react-app-polyfill/ie9"; // polyfill support for ie9 and above

import React from "react";
import ReactDOM from "react-dom";

import {
  initGoogleAnalytics,
  initHeapAnalytics,
  initSentry,
  WebApps,
} from "./shared/lib/analytics";
import { AppErrorBoundary } from "./shared/components/error-boundaries/AppErrorBoundary";

import "./css/index.scss";
import App from "./App";

initSentry(WebApps.CMWA);
initGoogleAnalytics(WebApps.CMWA);
initHeapAnalytics(WebApps.CMWA);

ReactDOM.render(
  <AppErrorBoundary>
    <App />
  </AppErrorBoundary>,
  document.getElementById("root")
);
