import idx from "idx.macro";
import graphql from "babel-plugin-relay/macro";

import { RelayResponse, ReviewItem } from "../../../lib/graphql";
import { TableData } from "../../table/tableTypes";
import { ParticipantItem } from "../ParticipantListTypes";

export type ReviewItemsTableResultData = {
  review_items: {
    review_item: ReviewItem[];
  };
};

export interface ReviewItemsTableResultType
  extends RelayResponse<ReviewItemsTableResultData> {}

export const ReviewItemsTableGqlValidArgs = (args: any) =>
  args.case_manager_id !== undefined && args.review_type;

export const ReviewItemsTableDataToItems = (
  data: ReviewItemsTableResultData
): TableData<ParticipantItem> => {
  const reviewItems =
    idx<TableData<ParticipantItem>>(data, (_) => _.review_items.review_item) ||
    [];

  const filteredReviewItems = reviewItems
    .filter((reviewItem) => reviewItem)
    .filter((reviewItem) => {
      const { type, obligation, participant } = reviewItem as any;
      const isBadObligationReviewItem =
        type.includes("Obligation_") && obligation === null ? true : false;
      if (isBadObligationReviewItem) {
        return false;
      }

      const isBadParticipantReviewItem =
        type.includes("Participant_") && participant === null ? true : false;

      if (isBadParticipantReviewItem) {
        return false;
      }
      return true;
    });
  // quickly make this more like the original participant shape
  const mapped: TableData<ParticipantItem> = filteredReviewItems.map(
    ({ id: review_id, participant, ...rest }: any) =>
      ({
        id: (participant || {}).id,
        review_id,
        ...participant,
        ...rest,
        participant,
      } as any)
  );

  return mapped;
};

export const ReviewItemsTableGql = graphql`
  query ReviewItemsTableGqlQuery(
    $review_type: BucketId!
    $case_manager_id: String
  ) {
    application {
      risk_levels {
        key
        value
      }
      compliance_options {
        key
        value
      }
    }
    review_items(case_manager_id: $case_manager_id, review_type: $review_type) {
      review_item {
        id
        expires_at
        expired
        created_at
        reviewed
        reviewed_at
        reviewed_by_case_manager {
          id
          name {
            first
            last
          }
        }
        document_id
        event {
          id
          date
          created_at
          attended
          participant_id
          activity {
            title {
              en
              es
            }
          }
          obligation {
            id
            title {
              en
              es
            }
          }
          input_field_name
          input_response_text
        }
        type
        obligation {
          id
          title {
            en
            es
          }
          service_provider {
            id
            title
          }
          activity {
            data_input_field
            data_input_type
          }
          start
          is_writable
          next_input_event {
            date
          }
          last_input_event {
            date
          }
        }
        participant {
          id
          name {
            first
            last
          }
          preferred_name
          risk_level
          status
          compliance
          case_manager {
            id
            name {
              first
              last
            }
          }
          supervision_begin_date
          supervision_end_date
          tasks {
            id
            label
            completed_at
          }
          program_title
          address {
            value
            last_value
          }
          employment {
            value
            last_value
          }
          law_enforcement_contact {
            value
            last_value
          }
          aa_meeting {
            value
            last_value
          }
          sms_enabled
          sms_consent
          phone {
            mobile
          }
          current_device {
            device_id
          }
          case_number
          sent_message_unread_count(unread_for: "all")
        }
      }
    }
  }
`;
