import * as React from "react";
import { Button } from "react-bulma-components";

export interface ChatLoadMorePropsExternal {
  fetchMessages: () => void;
  hasMore: boolean;
}

interface ChatLoadMoreProps extends ChatLoadMorePropsExternal {
  enabled: boolean;
}

export class ChatLoadMore extends React.PureComponent<ChatLoadMoreProps> {
  render() {
    const { enabled, fetchMessages, hasMore } = this.props;
    if (!hasMore) return null;

    return (
      <Button
        disabled={!enabled}
        className="is-fullwidth is-light"
        style={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
        onClick={fetchMessages}
      >
        Load More
        {"\u2026"}
      </Button>
    );
  }
}
