import * as React from "react";

import {
  TRACKED_GRAPHQL_CALL_TYPES,
  WithTrackerProps,
  withTracker,
  GRAPHQL_FLIGHT_STATUS,
} from "../../lib/graphql";
import { Loader } from "../elements/Loader";

import "./AppHeader.scss";
import { withTimer, WithTimerProps } from "../withTimer";

type MutationStatusIndicatorPropType = WithTimerProps & WithTrackerProps;

interface StatusState {
  status: JSX.Element;
}

const SAVING_MESSAGE = (
  <div className="status-message saving">
    <span className="statusSpace">
      {"Saving\u2026"}
      <Loader />
    </span>
  </div>
);
const SAVED_MESSAGE = (
  <div className="status-message saved">
    <span className="statusSpace">Saved</span>
  </div>
);
const SAVED_DISPLAY_DELAY_MS = 1200;

class MutationStatusIndicatorComponent extends React.PureComponent<
  MutationStatusIndicatorPropType,
  StatusState
> {
  constructor(props: MutationStatusIndicatorPropType) {
    super(props);

    this.state = {
      status: this.statusMessage(props),
    };
  }

  componentWillReceiveProps(nextProps: MutationStatusIndicatorPropType) {
    const wasInFlight =
      this.props.trackerInFlightStatus === GRAPHQL_FLIGHT_STATUS.Inflight &&
      nextProps.trackerInFlightStatus !== GRAPHQL_FLIGHT_STATUS.Inflight;
    const isInFlight =
      this.props.trackerInFlightStatus !== GRAPHQL_FLIGHT_STATUS.Inflight &&
      nextProps.trackerInFlightStatus === GRAPHQL_FLIGHT_STATUS.Inflight;

    if (isInFlight) {
      this.setState({ status: SAVING_MESSAGE });
    } else if (wasInFlight) {
      let displayMessageFor = SAVED_DISPLAY_DELAY_MS;
      if (nextProps.trackerInFlightStatus === GRAPHQL_FLIGHT_STATUS.Success) {
        this.setState({ status: SAVED_MESSAGE });
      } else {
        displayMessageFor *= 3;
        this.setState({ status: this.statusErrors(nextProps) });
      }
      this.props.setTimeout(() => {
        this.setState({ status: this.statusMessage(nextProps) });
      }, displayMessageFor);
    } else {
      this.setState({ status: this.statusMessage(nextProps) });
    }
  }

  private statusMessage = ({
    defaultMessage,
  }: MutationStatusIndicatorPropType) => (
    <div className="status-message">{defaultMessage}</div>
  );

  private statusErrors = ({ errors }: MutationStatusIndicatorPropType) =>
    errors ? <span>Error: {errors[0]}</span> : <span>An Error Occurred.</span>;

  render() {
    const { status } = this.state;
    const { trackerInFlightStatus } = this.props;

    return trackerInFlightStatus || status ? status : null;
  }
}

const MutationStatusIndicatorTracked = withTracker<
  MutationStatusIndicatorPropType,
  MutationStatusIndicatorPropType
>(TRACKED_GRAPHQL_CALL_TYPES.Mutation, MutationStatusIndicatorComponent);

const MutationStatusIndicator = withTimer(MutationStatusIndicatorTracked);

export class AppHeaderStatus extends React.PureComponent {
  render() {
    return (
      <>
        <MutationStatusIndicator />
      </>
    );
  }
}
