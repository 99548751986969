import * as React from "react";
import idx from "idx.macro";

import { KeyedString, QueryRenderer, CaseManager } from "../../lib/graphql";
import { TableData } from "../table/tableTypes";
import { ParticipantItem, ParticipantListTable } from "./";
import { ParticipantListSearchConsumer } from "./ParticipantListSearchContext";
import {
  ParticipantListTablePassThroughProps,
  ParticipantListTablePassThroughPropsAllKeys,
} from "./ParticipantListTable";
import {
  ParticipantListTableGraphqlWithEventsResultType,
  ParticipantListTableQueries,
  ParticipantListTableQueryTypes as QueryTypes,
} from "./gql";

export interface ParticipantListTableContainerProps<
  TItem extends ParticipantItem
> extends ParticipantListTablePassThroughProps<TItem> {
  caseManager?: CaseManager;
  caseManagerId?: string;
  className?: string;
  riskLevel?: KeyedString;
  bucket?: KeyedString;
  review_type?: String;
  tableQuery: QueryTypes;
  showActive?: boolean;
  showSecondaryCaseManager: boolean;
  onLoad?: (items: TableData<TItem>) => TableData<TItem>;
}

export const ParticipantListTableQueryTypes = QueryTypes;

const getCaseManagerId = function <TItem extends ParticipantItem>({
  caseManager,
  caseManagerId,
}: ParticipantListTableContainerProps<TItem>) {
  if (caseManagerId === "all") return "";
  if (caseManagerId) return caseManagerId;
  else return caseManager ? caseManager.id : null;
};

/**
 * Fetch a standard list of participants
 * Renders a SortableTable with the values
 * Query and configurable columns (from a known set)
 *
 */
export const ParticipantListTableContainer = function <
  T extends ParticipantListTableContainerProps<TItem>,
  TItem extends ParticipantItem
>(props: T) {
  const {
    bucket,
    className,
    riskLevel,
    tableQuery,
    onLoad,
    showActive = true,
    showSecondaryCaseManager = false,
  } = props;

  const baseVariables = {
    active_clients_only: showActive,
    review_type: bucket ? bucket.key : undefined,
    risk: riskLevel && showActive ? riskLevel.key : undefined,
    case_manager_id: getCaseManagerId(props),
  };

  const selectedQuery = ParticipantListTableQueries[tableQuery];
  if (!selectedQuery) {
    console.error(`Unknown ParticipantListTableQuery requested: ${tableQuery}`);
    return <span {...{ "data-has-error": true }} />;
  }

  const showAll = getCaseManagerId(props) === null ? true : false;

  const { query: queryGql, isValid, dataToItems } = selectedQuery;

  return (
    <ParticipantListSearchConsumer>
      {({ query }) => {
        const variables = {
          ...baseVariables,
          name_contains: query,
        };
        const argsValid = isValid(variables);
        if (!argsValid) {
          console.warn(
            `Invalid args for query, will still try. "${tableQuery}", ` +
              `args: ${JSON.stringify(variables)}`
          );
        }

        return (
          <QueryRenderer
            query={queryGql}
            variables={{
              ...variables,
              case_manager_id:
                showSecondaryCaseManager && showAll === false
                  ? null
                  : "12345678",
              secondary_case_manager_id: getCaseManagerId(props),
            }}
            SuccessComponent={(
              firstQueryResult: ParticipantListTableGraphqlWithEventsResultType
            ) => {
              return (
                <QueryRenderer
                  query={queryGql}
                  variables={variables}
                  SuccessComponent={(
                    queryResult: ParticipantListTableGraphqlWithEventsResultType
                  ) => {
                    const secondaryCaseManagerItems: TableData<ParticipantItem> =
                      dataToItems(firstQueryResult) || [];

                    const primaryCaseManagerItems: TableData<ParticipantItem> =
                      dataToItems(queryResult) || [];

                    const items = [
                      ...primaryCaseManagerItems,
                      ...secondaryCaseManagerItems,
                    ];

                    const mappedItems = !onLoad
                      ? items
                      : onLoad(items as TableData<TItem>);

                    const riskLevels: Array<KeyedString> =
                      idx<Array<KeyedString>>(
                        queryResult,
                        (_) => _.application.risk_levels
                      ) || [];

                    const complianceOptions: Array<KeyedString> =
                      idx<Array<KeyedString>>(
                        queryResult,
                        (_) => _.application.compliance_options
                      ) || [];

                    // Enforce that we're picking off props and passing through all expected keys
                    //  note: If you add to `ParticipantListTablePassThroughPropsAllKeys` then this will have a TS error until you add it here
                    //    thus ensuring it gets passed through this component
                    const passThroughArgs: ParticipantListTablePassThroughPropsAllKeys = {
                      additionalColumns: props.additionalColumns,
                      clientDisplayTerm: props.clientDisplayTerm,
                      columnsToDisplay: props.columnsToDisplay,
                      complianceOptions: complianceOptions,
                      nameComponent: props.nameComponent,
                      supportsProgramCompliance:
                        props.supportsProgramCompliance,
                      whatIsComplianceCalled: props.whatIsComplianceCalled,
                      whatIsRiskLevelCalled: props.whatIsRiskLevelCalled,
                      urlBuilder: props.urlBuilder,
                      startDateHumanizeCutoff: props.startDateHumanizeCutoff,
                      columnNameRemaps: props.columnNameRemaps,
                    };

                    return (
                      <ParticipantListTable
                        className={className}
                        {...passThroughArgs} // sniff, TS doesn't know this type anymore
                        items={mappedItems}
                        riskLevels={riskLevels}
                      />
                    );
                  }}
                />
              );
            }}
          />
        );
      }}
    </ParticipantListSearchConsumer>
  );
};
