import graphql from "babel-plugin-relay/macro";

import { CaseManager } from "./shared/lib/graphql/index";

export interface AppGqlResponse {
  me: {
    email: string;
    case_manager?: CaseManager;
    zendesk: {
      expires_at?: string;
      token?: string;
    };
  };
  application: {
    flags: {
      all: object;
    };
  };
}

export const AppGql = graphql`
  query AppGqlQuery {
    me {
      email
      case_manager {
        id
        name {
          first
          last
        }
      }
      zendesk {
        expires_at
        token
      }
    }
    application {
      flags {
        all
      }
    }
  }
`;
