import isMagicPhoneNumber from "./is_magic_phone_number";

export enum MessagingStates {
  MESSAGING_ON = "MESSAGING_ON",
  MESSAGING_OFF = "MESSAGING_OFF",
  MESSAGING_OFF_CLIENT_NOT_OPTED_IN = "MESSAGING_OFF_CLIENT_NOT_OPTED_IN",
  MESSAGING_OFF_CLIENT_OPTED_OUT = "MESSAGING_OFF_CLIENT_OPTED_OUT",
  PHONE_NUMBER_NOT_PROVIDED = "PHONE_NUMBER_NOT_PROVIDED",
}

export type HideableOption = {
  key: string;
  value?: string;
  hidden?: boolean;
};

// for SMSSelect
export const smsOptions: Array<HideableOption> = [
  {
    key: MessagingStates.MESSAGING_ON,
    value: "Enabled",
    hidden: false,
  },
  {
    key: MessagingStates.MESSAGING_OFF,
    value: "Disabled",
    hidden: false,
  },
  {
    key: MessagingStates.MESSAGING_OFF_CLIENT_NOT_OPTED_IN,
    value: "Off: Missing client opt-in",
    hidden: true,
  },
  {
    key: MessagingStates.MESSAGING_OFF_CLIENT_OPTED_OUT,
    value: "Off: Client has opted out",
    hidden: true,
  },
  {
    key: MessagingStates.PHONE_NUMBER_NOT_PROVIDED,
    value: "Off: Phone number not provided",
    hidden: true,
  },
];

export function flattenSMSControls(
  smsEnabled: boolean,
  smsConsent: boolean,
  phone: string
): MessagingStates {
  if (!phone || isMagicPhoneNumber(phone)) {
    return MessagingStates.PHONE_NUMBER_NOT_PROVIDED;
  }
  if (smsConsent === false) {
    return MessagingStates.MESSAGING_OFF_CLIENT_OPTED_OUT;
  } else {
    if (smsConsent === undefined || smsConsent === null) {
      return MessagingStates.MESSAGING_OFF_CLIENT_NOT_OPTED_IN;
    } else {
      if (smsEnabled) {
        return MessagingStates.MESSAGING_ON;
      } else {
        return MessagingStates.MESSAGING_OFF;
      }
    }
  }
}

export function canChatWithParticipant(
  isCaseManager: boolean,
  smsEnabled: boolean,
  smsConsent: boolean,
  phone: string,
  appEnabled: boolean
): boolean {
  // are you a CM? if no, no chat for you.
  if (!isCaseManager) {
    return false;
  }

  // is SMS enabled? if so, you can chat!
  const smsStatus = flattenSMSControls(smsEnabled, smsConsent, phone);
  if (smsStatus === MessagingStates.MESSAGING_ON) {
    return true;
  }

  // do you have the app? if so, you can chat!
  if (appEnabled) {
    return true;
  }

  // no chat for you.
  return false;
}
