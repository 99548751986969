import * as React from "react";
import { Modal, Typography, Button } from "antd";

interface Props {
  warningText: string;
  onYes: Function;
  onNo: Function;
  modalIsOpen: boolean;
  yesButtonText: string;
  noButtonText: string;
}

export class SelectScarePrompt extends React.Component<Props> {
  _onYes = () => {
    this.props.onYes();
  };

  render() {
    const {
      modalIsOpen,
      onYes,
      onNo,
      warningText,
      yesButtonText,
      noButtonText,
    } = this.props;
    const { Text } = Typography;
    return (
      <>
        <Modal
          visible={modalIsOpen}
          onCancel={(event: any) => onNo(event)}
          onOk={(event: any) => onYes(event)}
          footer={[
            <Button type="danger" onClick={(event: any) => onYes(event)}>
              {yesButtonText}
            </Button>,
            <Button type="primary" onClick={(event: any) => onNo(event)}>
              {noButtonText}
            </Button>,
          ]}
        >
          <Text>{warningText}</Text>
        </Modal>
      </>
    );
  }
}
