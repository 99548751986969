import { TimelineTimelineEntryType } from "./TimelineGql";

export const getDateForTimelineEvent = (item: TimelineTimelineEntryType) => {
  const {
    event: { date },
    notification,
    effective_timestamp,
  } = item;

  if (!date && !notification) return effective_timestamp;

  if (!notification) return date;
  // notifications use different time and, sometimes, their time_deployed goes missing
  else
    return notification.time_deployed
      ? notification.time_deployed
      : effective_timestamp;
};
