import * as React from "react";
import { Link, WindowLocation } from "@reach/router";
import { Menu } from "react-bulma-components";

import "./HeaderNavMenu.scss";

import { withTimer, WithTimerProps } from "../withTimer";
import {
  NavigationOption,
  SubNavOption,
  isActiveLocationFn,
} from "./AppHeader";
import { AppHeaderGqlResponse } from "./AppHeaderGql";

export interface HeaderPropsExternal {
  navOption: NavigationOption;
  data?: AppHeaderGqlResponse;
  isActiveLocation?: isActiveLocationFn;
}

export interface HeaderProps extends HeaderPropsExternal, WithTimerProps {}

interface State {
  showSubMenu: boolean;
  top: string;
  left: string;
  fetchedSubNavOptions: SubNavOption[] | undefined;
}

class HeaderNavMenu extends React.Component<HeaderProps, State> {
  private menuRef = React.createRef<HTMLDivElement>();

  state = {
    showSubMenu: false,
    top: "",
    left: "",
    fetchedSubNavOptions: undefined,
  };

  componentWillMount() {
    document.addEventListener("mousedown", this._toggleSubMenu, false);
  }

  componentWillUnMount() {
    document.removeEventListener("mousedown", this._toggleSubMenu, false);
  }

  private handleNonSubMenu = (event: any) => {
    const id = event.target.getAttribute("data-id");

    if (id === "sub-option-link") return;
    else this.setState({ showSubMenu: false });
  };

  _toggleSubMenu = (event: any) => {
    //little hack to get the menu to collapse when a click is registered outside of the component
    const node = this.menuRef.current as HTMLDivElement;
    // const navOption = this.props.navOption;

    if (!node || !node.contains(event.target)) {
      this.handleNonSubMenu(event);
      return;
    }

    // handle the complex sub nav fetching
    // TODO: This has been amputated, but not removed, the fetchSubNavOptions no longer exists
    // if (navOption.fetchSubNavOptions && !this.state.fetchedSubNavOptions) {
    //   navOption.fetchSubNavOptions().then(subNavOptions => {
    //     this.props.setTimeout(() => {
    //       let options = subNavOptions;
    //       if (options.length === 0) {
    //         options = [{ label: "No Case Managers Found", link: "/clients" }];
    //       }
    //       this.setState({ fetchedSubNavOptions: options });
    //     }, 1);
    //   });
    // }

    const rect = event.target.getBoundingClientRect();

    const top = rect.top + 36;
    const left = 0;
    const topPixels = `${top}px`;
    const leftPixels = `${left}px`;
    this.setState((state) => ({
      showSubMenu: !state.showSubMenu,
      top: topPixels,
      left: leftPixels,
    }));
  };

  private getLinkIsActive = (
    location: WindowLocation,
    isPartiallyCurrent: boolean
  ) => {
    const { isActiveLocation } = this.props;
    if (!isActiveLocation) return isPartiallyCurrent;
    const result = isActiveLocation(location, isPartiallyCurrent, false);
    return result.isMatch;
  };

  render() {
    const { showSubMenu, top, left } = this.state;
    const { navOption } = this.props;
    let subNavOptions = navOption.subNavOptions;
    // if (!subNavOptions) {
    //   if (navOption.fetchSubNavOptions) {
    //     if (this.state.fetchedSubNavOptions) {
    //       subNavOptions = this.state.fetchedSubNavOptions;
    //     } else {
    //       subNavOptions = [{ label: "... Loading", link: "" }];
    //     }
    //   }
    // }

    return (
      <div className="header-nav-menu">
        <div ref={this.menuRef}>
          {/* <a className="link-text">{navOption.label}</a> */}
          <Link
            data-id="header-option-link"
            getProps={({
              location,
              isPartiallyCurrent,
            }: {
              location: WindowLocation;
              isPartiallyCurrent: boolean;
            }) => {
              const showAsCurrent = this.getLinkIsActive(
                location,
                isPartiallyCurrent
              );
              return {
                className: `link-text ${
                  showAsCurrent ? "is-active" : ""
                }`.trim(),
              };
            }}
            to={navOption.link || ""}
            onClick={(evt: React.SyntheticEvent<any>) => {
              evt.preventDefault();
            }}
          >
            {navOption.label}
            <span className="triangle" />
          </Link>
        </div>
        {showSubMenu && (
          <Menu.List className="header-sub-menu" style={{ top, left }}>
            {subNavOptions &&
              subNavOptions!.map((subOption: SubNavOption) => (
                <Menu.List.Item key={subOption.link}>
                  <Link
                    data-id="sub-option-link"
                    to={subOption.link}
                    onClick={() => this.setState({ showSubMenu: false })}
                  >
                    {subOption.label}
                  </Link>
                </Menu.List.Item>
              ))}
          </Menu.List>
        )}
      </div>
    );
  }
}

export default withTimer<HeaderPropsExternal, HeaderProps>(HeaderNavMenu);
