import React from "react";

import { addObligation, updateObligation } from "../../../actions/obligations";
import {
  CarePlanParticipantType,
  CarePlanObligationType,
} from "../../care-plan/CarePlanQuery";
import AddObligationModalWrapperOuter, {
  ObligationModalFormData,
} from "./AddObligationModalWrapper";
import { Operation } from "../types";
import { AddObligationForm, AddObligationFormProps } from "./AddObligationForm";

interface AddObligationModalProps {
  operation: Operation;
  type: string;
  participant: CarePlanParticipantType;
  updateObject?: CarePlanObligationType;
  activityId: string;
  showButton?: boolean;
  disableButton?: boolean;
  handleModalClose?: () => void;
}

function formatObligationMutationPayload(
  participant: CarePlanParticipantType,
  activityId: string,
  addObligationModalFormData: ObligationModalFormData
) {
  return {
    participant_id: participant.id,
    activity_id: activityId,
    title_en: addObligationModalFormData.title.value,
    description_en: addObligationModalFormData.description.value,
    service_provider_id: addObligationModalFormData.serviceProvider.value,
    sub_address_for_event: addObligationModalFormData.roomNumber.value,
    start: addObligationModalFormData.startDate.value
      ? addObligationModalFormData.startDate.value.toISOString()
      : null,
    end: addObligationModalFormData.endDate.value
      ? addObligationModalFormData.endDate.value.toISOString()
      : null,
  };
}

export class AddObligationModal extends React.Component<
  AddObligationModalProps
> {
  private _addObligationCallback = async (
    addObligationModalFormData: ObligationModalFormData,
    _: Function,
    participant: CarePlanParticipantType,
    activityId: string
  ) => {
    const variables = formatObligationMutationPayload(
      participant,
      activityId,
      addObligationModalFormData
    );

    await addObligation(variables);
  };

  private _updateObligationCallback = async (
    addObligationModalFormData: ObligationModalFormData,
    _: Function,
    participant: CarePlanParticipantType,
    activityId: string
  ) => {
    const { updateObject } = this.props;
    if (!updateObject) {
      return;
    }

    const variables = formatObligationMutationPayload(
      participant,
      activityId,
      addObligationModalFormData
    );

    await updateObligation(
      {
        ...variables,
        obligation_id: updateObject.id,
      },
      participant.id,
      updateObject
    );
  };

  render() {
    const {
      activityId,
      handleModalClose,
      participant,
      operation,
      type,
      updateObject,
    } = this.props;

    const AddObligationModalWrapper = AddObligationModalWrapperOuter(
      handleModalClose
    );

    const apiCallback = updateObject
      ? this._updateObligationCallback
      : this._addObligationCallback;
    return (
      <AddObligationModalWrapper
        callBackForAPI={(
          addObligationModalFormData: ObligationModalFormData,
          handleModalClose: Function
        ) =>
          apiCallback(
            addObligationModalFormData,
            handleModalClose,
            participant,
            activityId
          )
        }
        operation={operation}
        type={type}
        updateObject={updateObject}
      >
        {(data: AddObligationFormProps) => (
          <AddObligationForm activityId={activityId} {...data} />
        )}
      </AddObligationModalWrapper>
    );
  }
}
