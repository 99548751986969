export const loadHeap = (): boolean => {
  if (!window || !document || !document.location) {
    console.warn("Doesn't appear to be a browser, not setting adding Heap");
    return false;
  }

  /* tslist:disable */
  ((window as any).heap = (window as any).heap || []),
    ((window as any).heap.load = function (e: any, t: any) {
      ((window as any).heap.appid = e),
        ((window as any).heap.config = t = t || {});
      var r = t.forceSSL || "https:" === document.location!.protocol,
        a = document.createElement("script");
      (a.type = "text/javascript"),
        (a.async = !0),
        (a.src =
          (r ? "https:" : "http:") +
          "//cdn.heapanalytics.com/js/heap-" +
          e +
          ".js");
      var n = document.getElementsByTagName("script")[0];
      n.parentNode!.insertBefore(a, n);
      for (
        var o = function (e: any) {
            return function () {
              (window as any).heap.push(
                [e].concat(Array.prototype.slice.call(arguments, 0))
              );
            };
          },
          p = [
            "addEventProperties",
            "addUserProperties",
            "clearEventProperties",
            "identify",
            "resetIdentity",
            "removeEventProperty",
            "setEventProperties",
            "track",
            "unsetEventProperty",
          ],
          c = 0;
        c < p.length;
        c++
      )
        (window as any).heap[p[c]] = o(p[c]);
    });
  return true;
};
