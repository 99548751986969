import React from "react";
import { Booking, YesNo } from "../../graphql-types";
import { ROOT_PATH } from "../../AppInfo";
import { Link } from "@reach/router";
import { Table, Tag } from "antd";
import moment from "moment";
const titleCase = require("title-case");

interface ProspectSearchTableDataSource {
  key: string;
  client_name: JSX.Element;
}

export function SearchProspectBookingTable({
  bookings,
}: {
  bookings: Booking[];
}) {
  const prospectSearchTableDataSource: ProspectSearchTableDataSource[] = bookings.map(
    (
      {
        booking_defendent: bookingDefendent,
        id,
        booking_info: bookingInfo,
        prospect_status: prospectStatus,
        is_interview_declined: isInterviewDeclined,
      },
      index
    ) => {
      return {
        key: `${index}`,
        client_name: (
          <Link to={`/${ROOT_PATH}/prospects/${id}/summary`}>
            {`${bookingDefendent.name.last}, ${bookingDefendent.name.first}`}
          </Link>
        ),
        recommendation_type:
          isInterviewDeclined === YesNo.Yes ? "Memo" : "Recommendation",
        booking_date: bookingInfo.booking_datetime
          ? moment(bookingInfo.booking_datetime).format("MM/DD/YYYY hh:mm a")
          : "N/A",
        prospect_status: (
          <Tag>
            {prospectStatus ? titleCase(prospectStatus) : "Not Interviewed"}
          </Tag>
        ),
      };
    }
  );

  const prospectSearchTableColumns = [
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
    },
    {
      title: "Booking Date",
      dataIndex: "booking_date",
      key: "booking_date",
    },
    {
      title: "Prospect Status",
      dataIndex: "prospect_status",
      key: "prospect_status",
    },
  ];

  return (
    <Table
      dataSource={prospectSearchTableDataSource}
      columns={prospectSearchTableColumns}
      pagination={false}
    />
  );
}
