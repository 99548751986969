import React from "react";
import { Spin } from "antd";

export const Loader = () => (
  <div
    style={{
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Spin />
  </div>
);
