import * as React from "react";
import idx from "idx.macro";
import graphql from "babel-plugin-relay/macro";

import { QueryRenderer, RelayResponse } from "../../lib/graphql";
import { TableData } from "../table/tableTypes";
import { ParticipantItem, ParticipantListEventType } from "./";
import getBucketedRangeBasedOnPosition from "../../lib/getBucketedRangeBasedOnPosition";

export interface ParticipantListEventsCellProps {
  loadingComponent: React.ReactElement<any>;
  participantId: string;
  participants: Array<ParticipantItem>;
  children: Function;
}

export type ParticipantListEventsCellType = {
  participants: {
    participant: ParticipantItem[];
  };
};

export interface ParticipantListEventsCellResultType
  extends RelayResponse<ParticipantListEventsCellType> {}

const QUERY_BATCH_SIZE = 20;

const ParticipantListEventsCellQuery = graphql`
  query ParticipantListEventsCellQuery(
    $participant_ids: String!
    $sub_query_only: Boolean!
  ) {
    participants(ids: $participant_ids, sub_query_only: $sub_query_only) {
      participant {
        id
        events {
          id
          activity {
            id
            title {
              en
            }
          }
          date
          attended
        }
      }
    }
  }
`;

/**
 * A cell that fetches the events for a given participant, and calls its
 * children as a render-prop function.  In reality it fetches events for all
 * participants in the table as a performance hack, which causes Relay to batch
 * the requests for many cells into a smaller number of queries.  We use a
 * max of 20 participant events at a time to ensure they come back quickly.
 */
export const ParticipantListEventsCell = function (
  props: ParticipantListEventsCellProps
) {
  // Fetch QUERY_BATCH_SIZE rows at a time
  const participantIds = getBucketedRangeBasedOnPosition(
    props.participants.map((participant) => participant.id),
    props.participantId,
    QUERY_BATCH_SIZE
  );

  return (
    <QueryRenderer
      query={ParticipantListEventsCellQuery}
      variables={{
        sub_query_only: true,
        participant_ids: participantIds.join(","),
      }}
      LoadingComponent={() => {
        return props.loadingComponent;
      }}
      SuccessComponent={(queryResult: ParticipantListEventsCellResultType) => {
        const participants: TableData<ParticipantItem> =
          idx<TableData<ParticipantItem>>(
            queryResult,
            (_) => _.participants.participant
          ) || [];
        let events: Array<ParticipantListEventType> = [];
        const participant = participants.filter(
          (participant) => participant.id === props.participantId
        )[0];
        if (participant) {
          events = participant.events || [];
        }
        return Array.isArray(props.children)
          ? props.children[0](events)
          : props.children(events);
      }}
    />
  );
};
