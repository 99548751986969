import React, { useState } from "react";
import {
  Row,
  Col,
  Checkbox,
  Form,
  Select,
  Input,
  DatePicker,
  TimePicker,
  Empty,
  Button,
} from "antd";
import "./CarePlanSection.scss";
import { CalendarToday } from "@material-ui/icons";
import { Maybe } from "../../graphql-types";
import moment, { Moment } from "moment-timezone";
import {
  CarePlanParticipantType,
  CarePlanObligationType,
} from "./CarePlanQuery";
import { addObligationsFromCarePlanItems } from "../../actions/obligations";
import {
  CarePlanItem,
  VerificationOptions,
  CadenceOptions,
} from "../../graphql-types";
import { dataArrayToObjectWithSpecifiedFieldToUseAsKey } from "../classes/CreateClass";

const { Option } = Select;

const dateFormatList = ["MM/DD/YYYY"];

interface ObligationCarePlanItem {
  id: string;
  title: string;
  isCheckIn: boolean;
  hasVerification: boolean | null;
  checkInData: Maybe<CheckInData>;
  obligationData: Maybe<ObligationData>;
  serviceProviderId: string;
  activityId: string;
  verificationActivityId: Maybe<string>;
}

interface CheckInData {
  cadence: CadenceOptions;
  dayOfWeek: number;
  dayOfMonth: number;
  verificationType: VerificationOptions;
}

interface ObligationData {
  eventDate: Moment;
  eventTime: Moment;
  roomNumber: string;
}

// this is hard-coded for a single client (Marin Pretrial)
// for now, eventually we will adjust to look up from DB
const obligationCarePlanDictionary: {
  [key: string]: ObligationCarePlanItem;
} = {
  courtDate: {
    id: "courtDate",
    title: "Court Date",
    isCheckIn: false,
    hasVerification: null,
    checkInData: null,
    obligationData: {
      eventDate: moment(),
      eventTime: moment(),
      roomNumber: "265",
    },
    serviceProviderId: "marin",
    activityId: "activity_Court Date",
    verificationActivityId: null,
  },
  officeVisit: {
    id: "officeVisit",
    title: "Office visit",
    isCheckIn: false,
    hasVerification: null,
    checkInData: null,
    obligationData: {
      eventDate: moment(),
      eventTime: moment(),
      roomNumber: "265",
    },
    serviceProviderId: "marin",
    activityId: "activity_Office Visit",
    verificationActivityId: null,
  },
  lawEnforcementContactCheckIn: {
    id: "lawEnforcementContactCheckIn",
    title: "LE Contact",
    isCheckIn: true,
    hasVerification: false,
    checkInData: {
      cadence: CadenceOptions.Weekly,
      dayOfWeek: 1,
      dayOfMonth: 1,
      verificationType: VerificationOptions.UpdateOrRequest,
    },
    obligationData: null,
    serviceProviderId: "marin",
    activityId: "activity_Law Enforcement Contact Confirmation",
    verificationActivityId: null,
  },
  addressCheckIn: {
    id: "addressCheckIn",
    title: "Address",
    isCheckIn: true,
    hasVerification: true,
    checkInData: {
      cadence: CadenceOptions.Weekly,
      dayOfWeek: 1,
      dayOfMonth: 1,
      verificationType: VerificationOptions.UpdateOrRequest,
    },
    obligationData: null,
    serviceProviderId: "marin",
    activityId: "activity_Address Confirmation",
    verificationActivityId: "activity_Address Verification",
  },
  employmentCheckIn: {
    id: "employmentCheckIn",
    title: "Employment",
    isCheckIn: true,
    hasVerification: true,
    checkInData: {
      cadence: CadenceOptions.Weekly,
      dayOfWeek: 1,
      dayOfMonth: 1,
      verificationType: VerificationOptions.UpdateOrRequest,
    },
    obligationData: null,
    serviceProviderId: "marin",
    activityId: "activity_Employment Confirmation",
    verificationActivityId: "activity_Employment Verification",
  },
  AAMeetingCheckIn: {
    id: "AAMeetingCheckIn",
    title: "AA Meeting",
    isCheckIn: true,
    hasVerification: true,
    checkInData: {
      cadence: CadenceOptions.Weekly,
      dayOfWeek: 1,
      dayOfMonth: 1,
      verificationType: VerificationOptions.UpdateOrRequest,
    },
    obligationData: null,
    serviceProviderId: "marin",
    activityId: "activity_AA Meeting Confirmation",
    verificationActivityId: "activity_AA Meeting Verification",
  },
};

export function CarePlanSetup(props: {
  participant: CarePlanParticipantType;
  toggleIsCarePlanOpen: any;
  editableObligations: CarePlanObligationType[];
}) {
  const {
    participant: {
      name: { first, last },
    },
    editableObligations,
  } = props;
  const [obligationCarePlanItems, setObligationCarePlanItems] = useState(
    [] as ObligationCarePlanItem[]
  );

  const participantName = `${first} ${last}`;

  const onCheckboxChange = (e: any) => {
    const { checked, name } = e.target;
    if (checked === true) {
      addObligationOrCheckIn(name);
    } else {
      removeObligationOrCheckIn(name);
    }
  };

  const addObligationOrCheckIn = (id: string) => {
    setObligationCarePlanItems([
      ...obligationCarePlanItems,
      obligationCarePlanDictionary[id],
    ]);
  };

  const removeObligationOrCheckIn = (id: string) => {
    const obligationCarePlanItemsFiltered = obligationCarePlanItems.filter(
      (obligationCarePlanItem) => {
        return obligationCarePlanItem.id !== id;
      }
    );
    setObligationCarePlanItems([...obligationCarePlanItemsFiltered]);
  };

  const updateObligationCarePlanData = (
    checkInId: string,
    value: any,
    field: string,
    type: "checkInData" | "obligationData"
  ) => {
    const obligationToUpdate = obligationCarePlanItems.find(
      (obligationCarePlan) => {
        return checkInId === obligationCarePlan.id;
      }
    ) as ObligationCarePlanItem;

    const existingCheckInData = obligationToUpdate[type]
      ? obligationToUpdate[type]
      : {};

    const updatedObligation = {
      ...obligationToUpdate,
      [type]: {
        ...existingCheckInData,
        [field]: value,
      },
    };

    const updatedObligations = obligationCarePlanItems.map(
      (obligationCarePlanItem) => {
        const { id } = obligationCarePlanItem;
        return id === checkInId ? updatedObligation : obligationCarePlanItem;
      }
    );

    setObligationCarePlanItems(
      (updatedObligations as any) as ObligationCarePlanItem[]
    );
  };

  const handleConfirmButtonClicked = async () => {
    const {
      participant: { id: participantId },
      toggleIsCarePlanOpen,
    } = props;

    await addObligationsFromCarePlanItems(
      participantId,
      obligationCarePlanItems as CarePlanItem[]
    );

    toggleIsCarePlanOpen(false);
  };

  const activities = editableObligations.map((editableObligation) => {
    return editableObligation.activity;
  });

  const uniqueActivitiesDictionary = dataArrayToObjectWithSpecifiedFieldToUseAsKey(
    activities,
    "id"
  );

  return (
    <div style={{ padding: "30px" }}>
      <Row>
        <Col span={4}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p style={{ fontSize: "24px" }}>
              {`Select obligations to create care plan for ${participantName}`}
            </p>
            <Checkbox
              disabled={
                !!uniqueActivitiesDictionary[
                  obligationCarePlanDictionary.courtDate.activityId
                ]
              }
              name={"courtDate"}
              style={{ marginLeft: "8px" }}
              className="checkbox-vertical"
              onChange={onCheckboxChange}
            >
              Court Date
            </Checkbox>
            <Checkbox
              disabled={
                !!uniqueActivitiesDictionary[
                  obligationCarePlanDictionary.officeVisit.activityId
                ]
              }
              name={"officeVisit"}
              style={{ marginLeft: "8px" }}
              className="checkbox-vertical"
              onChange={onCheckboxChange}
            >
              Office Visit
            </Checkbox>
            <Checkbox
              disabled={
                !!uniqueActivitiesDictionary[
                  obligationCarePlanDictionary.addressCheckIn.activityId
                ]
              }
              name={"addressCheckIn"}
              className="checkbox-vertical"
              onChange={onCheckboxChange}
            >
              Address check-in
            </Checkbox>
            <Checkbox
              disabled={
                !!uniqueActivitiesDictionary[
                  obligationCarePlanDictionary.employmentCheckIn.activityId
                ]
              }
              name={"employmentCheckIn"}
              className="checkbox-vertical"
              onChange={onCheckboxChange}
            >
              Employment check-in
            </Checkbox>
            <Checkbox
              disabled={
                !!uniqueActivitiesDictionary[
                  obligationCarePlanDictionary.AAMeetingCheckIn.activityId
                ]
              }
              name={"AAMeetingCheckIn"}
              className="checkbox-vertical"
              onChange={onCheckboxChange}
            >
              AA Meeting check-in
            </Checkbox>
            <Checkbox
              disabled={
                !!uniqueActivitiesDictionary[
                  obligationCarePlanDictionary.lawEnforcementContactCheckIn
                    .activityId
                ]
              }
              name={"lawEnforcementContactCheckIn"}
              className="checkbox-vertical"
              onChange={onCheckboxChange}
            >
              LE Contact check-in
            </Checkbox>
            <Button
              type="primary"
              style={{ marginTop: "20px", width: "150px" }}
              disabled={obligationCarePlanItems.length === 0}
              onClick={handleConfirmButtonClicked}
            >
              Confirm
            </Button>
          </div>
        </Col>
        <Col span={20}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {obligationCarePlanItems.map((obligationCarePlanItem) => (
              <CarePlanObligationBox
                updateObligationCarePlanData={updateObligationCarePlanData}
                obligationCarePlanItem={obligationCarePlanItem}
                key={`care_plan_item_${obligationCarePlanItem.id}`}
              />
            ))}

            {obligationCarePlanItems.length % 2 !== 0 && <EmptyBox />}

            {obligationCarePlanItems.length === 0 && (
              <div
                style={{
                  height: "300px",
                  width: "900px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#F2F2F2",
                }}
              >
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

function CarePlanObligationBox(props: {
  obligationCarePlanItem: ObligationCarePlanItem;
  updateObligationCarePlanData: Function;
}) {
  const { isCheckIn, title, hasVerification } = props.obligationCarePlanItem;
  const { updateObligationCarePlanData, obligationCarePlanItem } = props;

  return (
    <div
      style={{
        margin: "10px",
        border: "1px solid #E0E0E0",
        width: "475px",
        height: "150px",
        backgroundColor: "#F5FAFD",
        borderRadius: "4px",
        padding: "15px",
      }}
    >
      <div style={{ display: "flex" }}>
        <CalendarToday />
        <p style={{ fontWeight: "bold", fontSize: "14px", marginLeft: "5px" }}>
          {title}
        </p>
      </div>

      <div style={{ display: "flex" }}>
        {isCheckIn ? (
          <CarePlanCheckIn
            hasVerification={hasVerification}
            updateObligationCarePlanData={updateObligationCarePlanData}
            obligationCarePlanItem={obligationCarePlanItem}
          />
        ) : (
          <CarePlanObligation
            updateObligationCarePlanData={updateObligationCarePlanData}
            obligationCarePlanItem={obligationCarePlanItem}
          />
        )}
      </div>
    </div>
  );
}

export function CarePlanCheckIn(props: {
  hasVerification: boolean | null;
  updateObligationCarePlanData: Function;
  obligationCarePlanItem: ObligationCarePlanItem;
}) {
  const {
    hasVerification,
    updateObligationCarePlanData,
    obligationCarePlanItem,
  } = props;

  const { id, checkInData } = obligationCarePlanItem;

  return (
    <>
      <Form.Item label="Check-in cadence">
        <Select
          value={checkInData ? checkInData.cadence : null}
          style={{ width: "125px" }}
          onChange={(value: any) =>
            updateObligationCarePlanData(id, value, "cadence", "checkInData")
          }
        >
          <Option value="weekly">Every week</Option>
          <Option value="monthly">Every month</Option>
        </Select>
      </Form.Item>
      {checkInData && checkInData.cadence === CadenceOptions.Weekly ? (
        <Form.Item label="Week day" style={{ marginLeft: "5px" }}>
          <Select
            value={checkInData ? checkInData.dayOfWeek : null}
            style={{ width: "80px" }}
            onChange={(value: any) =>
              updateObligationCarePlanData(
                id,
                value,
                "dayOfWeek",
                "checkInData"
              )
            }
          >
            <Option value={1}>M</Option>
            <Option value={2}>T</Option>
            <Option value={3}>W</Option>
            <Option value={4}>TH</Option>
            <Option value={5}>F</Option>
          </Select>
        </Form.Item>
      ) : (
        <Form.Item label="Day of Month" style={{ marginLeft: "5px" }}>
          <Select
            value={checkInData ? checkInData.dayOfMonth : null}
            style={{ width: "80px" }}
            onChange={(value: any) =>
              updateObligationCarePlanData(
                id,
                value,
                "dayOfMonth",
                "checkInData"
              )
            }
          >
            {Array.from(Array(28).keys()).map((item) => (
              <Option value={item + 1} key={`day_of_month_option${item}`}>
                {item + 1}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {hasVerification && (
        <Form.Item label="Resubmit Documents" style={{ marginLeft: "5px" }}>
          <Select
            value={checkInData ? checkInData.verificationType : null}
            style={{ width: "175px" }}
            onChange={(value: any) =>
              updateObligationCarePlanData(
                id,
                value,
                "verificationType",
                "checkInData"
              )
            }
          >
            <Option value={VerificationOptions.UpdateOrRequest}>
              On Update
            </Option>
            <Option value={VerificationOptions.WithConfirmation}>
              On Confirmation
            </Option>
          </Select>
        </Form.Item>
      )}
    </>
  );
}

function CarePlanObligation({
  updateObligationCarePlanData,
  obligationCarePlanItem,
}: {
  updateObligationCarePlanData: Function;
  obligationCarePlanItem: ObligationCarePlanItem;
}) {
  const { id, obligationData } = obligationCarePlanItem;
  return (
    <>
      <Form.Item label="Date">
        <DatePicker
          value={obligationData ? obligationData.eventDate : undefined}
          format={dateFormatList}
          onChange={(value) =>
            updateObligationCarePlanData(
              id,
              value,
              "eventDate",
              "obligationData"
            )
          }
        />
      </Form.Item>

      <Form.Item label="Time" style={{ marginLeft: "5px" }}>
        <TimePicker
          value={obligationData ? obligationData.eventTime : undefined}
          use12Hours={true}
          minuteStep={30}
          format={"h:mm a"}
          onChange={(value) =>
            updateObligationCarePlanData(
              id,
              value,
              "eventTime",
              "obligationData"
            )
          }
        />
      </Form.Item>

      <Form.Item label="Room Number" style={{ marginLeft: "5px" }}>
        <Input
          value={obligationData ? obligationData.roomNumber : undefined}
          placeholder={"e.g. Room 265"}
          onChange={(event) => {
            const { value } = event.target;
            updateObligationCarePlanData(
              id,
              value,
              "roomNumber",
              "obligationData"
            );
          }}
        />
      </Form.Item>
    </>
  );
}

function EmptyBox() {
  return (
    <div
      style={{
        margin: "10px",
        width: "475px",
        height: "150px",
        backgroundColor: "white",
      }}
    ></div>
  );
}
