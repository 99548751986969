/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type MutationResult = "failure" | "skipped" | "success" | "%future added value";
export type obligationsAddObligationEventMutationVariables = {
    readonly obligation_id: string;
    readonly date: string;
    readonly end?: string | null;
    readonly rrule?: string | null;
    readonly timezone?: string | null;
};
export type obligationsAddObligationEventMutationResponse = {
    readonly addObligationEvent: ({
        readonly result: MutationResult;
        readonly event_template: ({
            readonly id: string | null;
            readonly rrule: string | null;
            readonly exdate: string | null;
            readonly events: ReadonlyArray<({
                readonly id: string | null;
                readonly date: string | null;
                readonly activity: {
                    readonly id: string;
                    readonly title: {
                        readonly en: string | null;
                    };
                    readonly type: string;
                };
                readonly event_template: ({
                    readonly id: string | null;
                }) | null;
                readonly required: boolean | null;
                readonly service_provider: {
                    readonly address: string | null;
                    readonly phone: string | null;
                    readonly sub_address_for_event: string | null;
                    readonly title: string | null;
                };
                readonly attended: string | null;
                readonly disposition: string | null;
            }) | null> | null;
        }) | null;
    }) | null;
};
export type obligationsAddObligationEventMutation = {
    readonly response: obligationsAddObligationEventMutationResponse;
    readonly variables: obligationsAddObligationEventMutationVariables;
};



/*
mutation obligationsAddObligationEventMutation(
  $obligation_id: String!
  $date: String!
  $end: String
  $rrule: String
  $timezone: String
) {
  addObligationEvent(obligation_id: $obligation_id, date: $date, end: $end, rrule: $rrule, timezone: $timezone) {
    result
    event_template {
      id
      rrule
      exdate
      events {
        id
        date
        activity {
          id
          title {
            en
          }
          type
        }
        event_template {
          id
        }
        required
        service_provider {
          address
          phone
          sub_address_for_event
          title
          id
        }
        attended
        disposition
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "obligation_id",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "date",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "end",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "rrule",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "timezone",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "date",
            "variableName": "date"
        } as any),
        ({
            "kind": "Variable",
            "name": "end",
            "variableName": "end"
        } as any),
        ({
            "kind": "Variable",
            "name": "obligation_id",
            "variableName": "obligation_id"
        } as any),
        ({
            "kind": "Variable",
            "name": "rrule",
            "variableName": "rrule"
        } as any),
        ({
            "kind": "Variable",
            "name": "timezone",
            "variableName": "timezone"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "result",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "rrule",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "exdate",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "date",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "activity",
        "storageKey": null,
        "args": null,
        "concreteType": "Activity",
        "plural": false,
        "selections": [
            (v3 /*: any*/),
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "title",
                "storageKey": null,
                "args": null,
                "concreteType": "i18nText",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "en",
                        "args": null,
                        "storageKey": null
                    }
                ]
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "type",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v8 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "event_template",
        "storageKey": null,
        "args": null,
        "concreteType": "EventTemplate",
        "plural": false,
        "selections": [
            (v3 /*: any*/)
        ]
    } as any), v9 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "required",
        "args": null,
        "storageKey": null
    } as any), v10 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "address",
        "args": null,
        "storageKey": null
    } as any), v11 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "phone",
        "args": null,
        "storageKey": null
    } as any), v12 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "sub_address_for_event",
        "args": null,
        "storageKey": null
    } as any), v13 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "title",
        "args": null,
        "storageKey": null
    } as any), v14 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "attended",
        "args": null,
        "storageKey": null
    } as any), v15 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "disposition",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "obligationsAddObligationEventMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "addObligationEvent",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "NewEvent",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "event_template",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "EventTemplate",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "events",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Event",
                                    "plural": true,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        (v8 /*: any*/),
                                        (v9 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "service_provider",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "ServiceProvider",
                                            "plural": false,
                                            "selections": [
                                                (v10 /*: any*/),
                                                (v11 /*: any*/),
                                                (v12 /*: any*/),
                                                (v13 /*: any*/)
                                            ]
                                        },
                                        (v14 /*: any*/),
                                        (v15 /*: any*/)
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "obligationsAddObligationEventMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "addObligationEvent",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "NewEvent",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "event_template",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "EventTemplate",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "events",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Event",
                                    "plural": true,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        (v8 /*: any*/),
                                        (v9 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "service_provider",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "ServiceProvider",
                                            "plural": false,
                                            "selections": [
                                                (v10 /*: any*/),
                                                (v11 /*: any*/),
                                                (v12 /*: any*/),
                                                (v13 /*: any*/),
                                                (v3 /*: any*/)
                                            ]
                                        },
                                        (v14 /*: any*/),
                                        (v15 /*: any*/)
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "mutation",
            "name": "obligationsAddObligationEventMutation",
            "id": null,
            "text": "mutation obligationsAddObligationEventMutation(\n  $obligation_id: String!\n  $date: String!\n  $end: String\n  $rrule: String\n  $timezone: String\n) {\n  addObligationEvent(obligation_id: $obligation_id, date: $date, end: $end, rrule: $rrule, timezone: $timezone) {\n    result\n    event_template {\n      id\n      rrule\n      exdate\n      events {\n        id\n        date\n        activity {\n          id\n          title {\n            en\n          }\n          type\n        }\n        event_template {\n          id\n        }\n        required\n        service_provider {\n          address\n          phone\n          sub_address_for_event\n          title\n          id\n        }\n        attended\n        disposition\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '146dd8e65bb8c9958db0b2ce676b81e4';
export default node;
