import moment, { Moment } from "moment";
import { ModalInputTypes, NOT_SET } from "./graphql";

export interface ValueToValidate<T = string | Moment> {
  value?: T;
  type?: ModalInputTypes | string;
}

interface Props extends ValueToValidate {
  comparisonValue: any;
}

interface validator_result {
  validationErrors?: string[];
  validationState: string;
}

export default class Validator {
  getValidationErrors = ({
    type,
    value,
    comparisonValue,
  }: Props): validator_result => {
    const result: validator_result = {
      validationErrors: [],
      validationState: "",
    };
    switch (type) {
      case ModalInputTypes.Required:
        result.validationErrors = this._validateRequired(value as string);
        break;
      case ModalInputTypes.Name:
        result.validationErrors = this._validateName(value as string);
        break;
      case ModalInputTypes.NameOptional:
        result.validationErrors = this._validateNameOptional(value as string);
        break;
      case ModalInputTypes.MiddleInitial:
        result.validationErrors = this._validateName(value as string);
        break;
      case ModalInputTypes.DateFuture:
        result.validationErrors = this._validateDateFuture(value as string);
        break;
      case ModalInputTypes.Phone:
        result.validationErrors = this._validatePhone(value as string);
        break;
      case ModalInputTypes.Select:
        result.validationErrors = this._validateSelect(value as string);
        break;
      case ModalInputTypes.SelectOptional:
        result.validationErrors = [];
        break;
      case ModalInputTypes.StartTime:
        result.validationErrors = this._validateStartDate(
          value as Moment,
          comparisonValue
        );
        break;
      case ModalInputTypes.StartDate:
        result.validationErrors = this._validateStartDate(
          value as Moment,
          comparisonValue
        );
        break;
      case ModalInputTypes.EndTime:
        result.validationErrors = this._validateEndDate(
          value as Moment,
          comparisonValue
        );
        break;
      case ModalInputTypes.EndDate:
        result.validationErrors = this._validateEndDate(
          value as Moment,
          comparisonValue
        );
        break;
      case ModalInputTypes.Textarea:
        result.validationErrors = this._validateTextArea(value as string);
        break;
      default:
        result.validationErrors = [];
        break;
    }

    result.validationState =
      result.validationErrors.length > 0 ? "error" : "valid";
    return result;
  };

  // TODO: return more information about what is invalid
  testAllValidations = (validations: any, args?: any): string[] | null => {
    const invalidFields = Object.keys(validations)
      .map((key) => ({ key, ...validations[key] }))
      .filter(({ includeIf }: any) => !includeIf || includeIf(args))
      .map((validation) => {
        if (validation.errors.length > 0 || validation.state === "pristine") {
          return validation.key;
        }
      })
      .filter((x) => !!x); // nulls mean they are valid

    return invalidFields.length > 0 ? invalidFields : null;
  };

  _validateRequired = (value?: string): string[] => {
    const validations = [this._testRequired];
    return this._runValidations(validations, value);
  };

  _validateName = (value?: string): string[] => {
    const validations = [
      this._testRequired,
      this._testLength,
      this._testSpecialChars,
    ];
    return this._runValidations(validations, value);
  };

  _validateNameOptional = (value?: string): string[] => {
    const validations = [this._testLengthMax, this._testSpecialChars];
    return this._runValidations(validations, value);
  };

  _validateMid = (value?: string): string[] => {
    const validations = [this._testMidLength, this._testSpecialChars];
    return this._runValidations(validations, value);
  };

  _validateDateFuture = (value?: string): string[] => {
    const validations = [this._testIsDate];
    return this._runValidations(validations, value);
  };

  _validatePhone = (value?: string): string[] => {
    const validations = [this._testPhone];
    return this._runValidations(validations, value);
  };

  _validateSelect = (value?: string): string[] => {
    const validations = [this._testSelection];
    return this._runValidations(validations, value);
  };

  _validateStartDate = (startDate: Moment, endDate: Moment): string[] => {
    const validations = [this._testIsDate, this._testIsBeforeEndDate];
    return this._runValidations(validations, startDate, endDate);
  };

  _validateEndDate = (endDate: Moment, startDate: Moment): string[] => {
    const validations = [this._testIsDate, this._testIsAfterStartDate];
    return this._runValidations(validations, endDate, startDate);
  };

  _validateTextArea = (value?: string): string[] => {
    const validations = [this._testRequired];
    return this._runValidations(validations, value);
  };

  _runValidations = (
    validations: any,
    value: any,
    secondaryValue?: any
  ): string[] => {
    return validations.reduce(
      (
        acc: string[],
        valFunction: (value: any, secondaryValue?: any) => string
      ) => {
        const validationError = valFunction(value, secondaryValue);
        if (validationError.length > 0) {
          acc.push(validationError);
        }
        return acc;
      },
      []
    );
  };

  _testNumber = (value?: string): string => {
    if (!value) return "";
    return /^\d+$/.test(value) ? "" : "Must be a number";
  };

  _testRequired = (value?: string): string => {
    if (!value) return "Field is required";
    return value.length > 0 ? "" : "Field is required";
  };

  _testSpecialChars = (value?: string): string => {
    if (!value) return "";
    return /^[a-zA-Z\s,.\-'\u00C0-\u017F]+$/.test(value) === true
      ? ""
      : `Cannot have numbers or special characters (!%&)`;
  };

  _testIsDate = (date: Moment): string => {
    const errorMessage = "Enter a valid date.";
    if (date instanceof moment) {
      return date.isValid() ? "" : errorMessage;
    }

    return errorMessage;
  };

  _testDatePast = (date: Moment): string => {
    const currentDate = moment();
    return date >= currentDate ? "Date must be in the past" : "";
  };

  _testDateFuture = (date: Moment): string => {
    const currentDate = moment();
    return date < currentDate ? "Date must be in the future" : "";
  };

  _testMidLength = (value?: string): string => {
    if (!value) return "";
    return value.length === 1 ? "" : "Can only contain a single letter";
  };

  _testLength = (value?: string): string => {
    if (!value) return "";
    return value.length > 0 && value.length < 24
      ? ""
      : `Must be between 1 and 24 characters.`;
  };

  _testLengthMax = (value?: string): string => {
    if (!value) return "";
    return value.length < 24 ? "" : `Must be between 1 and 24 characters.`;
  };

  _testPhone = (value?: string): string => {
    if (!value) return "";
    return /^((\+?\d{1,2}[\s\-]?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}|\d{10})$/.test(
      value
    ) === true
      ? ""
      : `Not a valid phone number.`;
  };

  _testIsBeforeEndDate = (startDate: Moment, endDate: Moment): string => {
    if (startDate instanceof moment && endDate instanceof moment) {
      return startDate.isSameOrAfter(endDate)
        ? "Start date must come before end date."
        : "";
    }
    return "";
  };

  _testIsAfterStartDate = (endDate: Moment, startDate: Moment): string => {
    if (startDate instanceof moment && endDate instanceof moment) {
      return endDate.isSameOrBefore(startDate)
        ? "End date must come after start date"
        : "";
    }
    return "";
  };

  _testSelection = (value?: string): string => {
    if (!value) return "";
    return value === NOT_SET ? "Please select an option" : "";
  };
}
