export default function getBucketedRangeBasedOnPosition(
  ids: Array<string>,
  idToFind: string,
  bucketSize: number = 20
): Array<string> {
  const idx = ids.indexOf(idToFind);
  if (idx < 0) {
    return [];
  }
  const rangeStart = Math.max(0, idx - (idx % bucketSize));
  const rangeEnd = Math.min(ids.length, rangeStart + bucketSize);
  return ids.slice(rangeStart, rangeEnd);
}
