/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type CadenceOptions = "monthly" | "weekly" | "%future added value";
export type DataInputField = "aa_meeting" | "address" | "employment" | "law_enforcement_contact" | "%future added value";
export type DataInputType = "confirmation" | "verification" | "%future added value";
export type VerificationOptions = "update_or_request" | "with_confirmation" | "%future added value";
export type CarePlanQueryQueryVariables = {
    readonly id: string;
};
export type CarePlanQueryQueryResponse = {
    readonly application: ({
        readonly activity_information: ReadonlyArray<({
            readonly key: string | null;
            readonly title: string | null;
            readonly care_plan: ({
                readonly type: string | null;
            }) | null;
        }) | null> | null;
        readonly compliance_options: ReadonlyArray<({
            readonly key: string;
            readonly value: string | null;
        }) | null> | null;
        readonly disposition_options: ReadonlyArray<({
            readonly key: string;
            readonly value: string | null;
        }) | null> | null;
        readonly obligation_status_options: ReadonlyArray<({
            readonly key: string;
            readonly value: string | null;
        }) | null> | null;
    }) | null;
    readonly service_providers: ({
        readonly service_provider: ReadonlyArray<({
            readonly id: string | null;
            readonly title: string | null;
        }) | null> | null;
    }) | null;
    readonly participants: ({
        readonly participant: ReadonlyArray<({
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
            readonly assessment: ({
                readonly score: string | null;
                readonly tool: string | null;
            }) | null;
            readonly preferred_name: string | null;
            readonly obligations: ReadonlyArray<({
                readonly id: string | null;
                readonly title: ({
                    readonly en: string | null;
                }) | null;
                readonly description: ({
                    readonly en: string | null;
                }) | null;
                readonly activity: ({
                    readonly id: string;
                    readonly type: string;
                    readonly data_input_type: DataInputType | null;
                    readonly data_input_field: DataInputField | null;
                }) | null;
                readonly service_provider: ({
                    readonly id: string | null;
                    readonly title: string | null;
                }) | null;
                readonly sub_address_for_event: string | null;
                readonly status: string | null;
                readonly compliance: string | null;
                readonly start: string | null;
                readonly end: string | null;
                readonly tag: string | null;
                readonly is_writable: boolean | null;
                readonly cadence_option: CadenceOptions | null;
                readonly day_of_week: number | null;
                readonly day_of_month: number | null;
                readonly verification_option: VerificationOptions | null;
                readonly event_templates: ReadonlyArray<({
                    readonly id: string | null;
                    readonly rrule: string | null;
                    readonly exdate: string | null;
                    readonly events: ReadonlyArray<({
                        readonly id: string | null;
                        readonly date: string | null;
                        readonly event_template: ({
                            readonly id: string | null;
                            readonly rrule: string | null;
                        }) | null;
                        readonly activity: {
                            readonly id: string;
                            readonly title: {
                                readonly en: string | null;
                            };
                            readonly type: string;
                        };
                        readonly required: boolean | null;
                        readonly service_provider: {
                            readonly id: string | null;
                            readonly address: string | null;
                            readonly phone: string | null;
                            readonly sub_address_for_event: string | null;
                            readonly title: string | null;
                        };
                        readonly attended: string | null;
                        readonly disposition: string | null;
                    }) | null> | null;
                }) | null> | null;
            }) | null> | null;
        }) | null> | null;
    }) | null;
};
export type CarePlanQueryQuery = {
    readonly response: CarePlanQueryQueryResponse;
    readonly variables: CarePlanQueryQueryVariables;
};



/*
query CarePlanQueryQuery(
  $id: String!
) {
  application {
    activity_information {
      key
      title
      care_plan {
        type
      }
    }
    compliance_options {
      key
      value
    }
    disposition_options {
      key
      value
    }
    obligation_status_options {
      key
      value
    }
    id
  }
  service_providers {
    service_provider {
      id
      title
    }
  }
  participants(id: $id) {
    participant {
      id
      name {
        first
        last
      }
      assessment {
        score
        tool
      }
      preferred_name
      obligations {
        id
        title {
          en
        }
        description {
          en
        }
        activity {
          id
          type
          data_input_type
          data_input_field
        }
        service_provider {
          id
          title
        }
        sub_address_for_event
        status
        compliance
        start
        end
        tag
        is_writable
        cadence_option
        day_of_week
        day_of_month
        verification_option
        event_templates {
          id
          rrule
          exdate
          events {
            id
            date
            event_template {
              id
              rrule
            }
            activity {
              id
              title {
                en
              }
              type
            }
            required
            service_provider {
              id
              address
              phone
              sub_address_for_event
              title
            }
            attended
            disposition
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "String!",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "key",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "title",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "type",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "activity_information",
        "storageKey": null,
        "args": null,
        "concreteType": "ActivityMeta",
        "plural": true,
        "selections": [
            (v1 /*: any*/),
            (v2 /*: any*/),
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "care_plan",
                "storageKey": null,
                "args": null,
                "concreteType": "ActivityMetaCarePlan",
                "plural": false,
                "selections": [
                    (v3 /*: any*/)
                ]
            }
        ]
    } as any), v5 = [
        (v1 /*: any*/),
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "value",
            "args": null,
            "storageKey": null
        } as any)
    ], v6 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "compliance_options",
        "storageKey": null,
        "args": null,
        "concreteType": "KeyedString",
        "plural": true,
        "selections": (v5 /*: any*/)
    } as any), v7 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "disposition_options",
        "storageKey": null,
        "args": null,
        "concreteType": "KeyedString",
        "plural": true,
        "selections": (v5 /*: any*/)
    } as any), v8 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "obligation_status_options",
        "storageKey": null,
        "args": null,
        "concreteType": "KeyedString",
        "plural": true,
        "selections": (v5 /*: any*/)
    } as any), v9 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v10 = [
        (v9 /*: any*/),
        (v2 /*: any*/)
    ], v11 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "service_providers",
        "storageKey": null,
        "args": null,
        "concreteType": "Pagination",
        "plural": false,
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "service_provider",
                "storageKey": null,
                "args": null,
                "concreteType": "ServiceProvider",
                "plural": true,
                "selections": (v10 /*: any*/)
            }
        ]
    } as any), v12 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "en",
            "args": null,
            "storageKey": null
        } as any)
    ], v13 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "title",
        "storageKey": null,
        "args": null,
        "concreteType": "i18nText",
        "plural": false,
        "selections": (v12 /*: any*/)
    } as any), v14 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "sub_address_for_event",
        "args": null,
        "storageKey": null
    } as any), v15 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "rrule",
        "args": null,
        "storageKey": null
    } as any), v16 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "participants",
        "storageKey": null,
        "args": [
            {
                "kind": "Variable",
                "name": "id",
                "variableName": "id"
            }
        ],
        "concreteType": "Pagination",
        "plural": false,
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "participant",
                "storageKey": null,
                "args": null,
                "concreteType": "Participant",
                "plural": true,
                "selections": [
                    (v9 /*: any*/),
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "name",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PersonName",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "first",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "last",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "assessment",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ParticipantAssessment",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "score",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "tool",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "preferred_name",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "obligations",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Obligation",
                        "plural": true,
                        "selections": [
                            (v9 /*: any*/),
                            (v13 /*: any*/),
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "description",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "i18nText",
                                "plural": false,
                                "selections": (v12 /*: any*/)
                            },
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "activity",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "Activity",
                                "plural": false,
                                "selections": [
                                    (v9 /*: any*/),
                                    (v3 /*: any*/),
                                    {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "data_input_type",
                                        "args": null,
                                        "storageKey": null
                                    },
                                    {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "data_input_field",
                                        "args": null,
                                        "storageKey": null
                                    }
                                ]
                            },
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "service_provider",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ServiceProvider",
                                "plural": false,
                                "selections": (v10 /*: any*/)
                            },
                            (v14 /*: any*/),
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "status",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "compliance",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "start",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "end",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "tag",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "is_writable",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "cadence_option",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "day_of_week",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "day_of_month",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "verification_option",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "event_templates",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "EventTemplate",
                                "plural": true,
                                "selections": [
                                    (v9 /*: any*/),
                                    (v15 /*: any*/),
                                    {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "exdate",
                                        "args": null,
                                        "storageKey": null
                                    },
                                    {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "events",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "Event",
                                        "plural": true,
                                        "selections": [
                                            (v9 /*: any*/),
                                            {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "date",
                                                "args": null,
                                                "storageKey": null
                                            },
                                            {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "event_template",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "EventTemplate",
                                                "plural": false,
                                                "selections": [
                                                    (v9 /*: any*/),
                                                    (v15 /*: any*/)
                                                ]
                                            },
                                            {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "activity",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "Activity",
                                                "plural": false,
                                                "selections": [
                                                    (v9 /*: any*/),
                                                    (v13 /*: any*/),
                                                    (v3 /*: any*/)
                                                ]
                                            },
                                            {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "required",
                                                "args": null,
                                                "storageKey": null
                                            },
                                            {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "service_provider",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "ServiceProvider",
                                                "plural": false,
                                                "selections": [
                                                    (v9 /*: any*/),
                                                    {
                                                        "kind": "ScalarField",
                                                        "alias": null,
                                                        "name": "address",
                                                        "args": null,
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "kind": "ScalarField",
                                                        "alias": null,
                                                        "name": "phone",
                                                        "args": null,
                                                        "storageKey": null
                                                    },
                                                    (v14 /*: any*/),
                                                    (v2 /*: any*/)
                                                ]
                                            },
                                            {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "attended",
                                                "args": null,
                                                "storageKey": null
                                            },
                                            {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "disposition",
                                                "args": null,
                                                "storageKey": null
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "CarePlanQueryQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "application",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Application",
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/)
                    ]
                },
                (v11 /*: any*/),
                (v16 /*: any*/)
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "CarePlanQueryQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "application",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Application",
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/)
                    ]
                },
                (v11 /*: any*/),
                (v16 /*: any*/)
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "CarePlanQueryQuery",
            "id": null,
            "text": "query CarePlanQueryQuery(\n  $id: String!\n) {\n  application {\n    activity_information {\n      key\n      title\n      care_plan {\n        type\n      }\n    }\n    compliance_options {\n      key\n      value\n    }\n    disposition_options {\n      key\n      value\n    }\n    obligation_status_options {\n      key\n      value\n    }\n    id\n  }\n  service_providers {\n    service_provider {\n      id\n      title\n    }\n  }\n  participants(id: $id) {\n    participant {\n      id\n      name {\n        first\n        last\n      }\n      assessment {\n        score\n        tool\n      }\n      preferred_name\n      obligations {\n        id\n        title {\n          en\n        }\n        description {\n          en\n        }\n        activity {\n          id\n          type\n          data_input_type\n          data_input_field\n        }\n        service_provider {\n          id\n          title\n        }\n        sub_address_for_event\n        status\n        compliance\n        start\n        end\n        tag\n        is_writable\n        cadence_option\n        day_of_week\n        day_of_month\n        verification_option\n        event_templates {\n          id\n          rrule\n          exdate\n          events {\n            id\n            date\n            event_template {\n              id\n              rrule\n            }\n            activity {\n              id\n              title {\n                en\n              }\n              type\n            }\n            required\n            service_provider {\n              id\n              address\n              phone\n              sub_address_for_event\n              title\n            }\n            attended\n            disposition\n          }\n        }\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '3e5a304884a86d38cb7c640191ba6653';
export default node;
