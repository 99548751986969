/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type ParticipantListEventsCellQueryVariables = {
    readonly participant_ids: string;
    readonly sub_query_only: boolean;
};
export type ParticipantListEventsCellQueryResponse = {
    readonly participants: ({
        readonly participant: ReadonlyArray<({
            readonly id: string;
            readonly events: ReadonlyArray<({
                readonly id: string | null;
                readonly activity: {
                    readonly id: string;
                    readonly title: {
                        readonly en: string | null;
                    };
                };
                readonly date: string | null;
                readonly attended: string | null;
            }) | null> | null;
        }) | null> | null;
    }) | null;
};
export type ParticipantListEventsCellQuery = {
    readonly response: ParticipantListEventsCellQueryResponse;
    readonly variables: ParticipantListEventsCellQueryVariables;
};



/*
query ParticipantListEventsCellQuery(
  $participant_ids: String!
  $sub_query_only: Boolean!
) {
  participants(ids: $participant_ids, sub_query_only: $sub_query_only) {
    participant {
      id
      events {
        id
        activity {
          id
          title {
            en
          }
        }
        date
        attended
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "participant_ids",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "sub_query_only",
            "type": "Boolean!",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "participants",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "ids",
                    "variableName": "participant_ids"
                },
                {
                    "kind": "Variable",
                    "name": "sub_query_only",
                    "variableName": "sub_query_only"
                }
            ],
            "concreteType": "Pagination",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participant",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Participant",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "events",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Event",
                            "plural": true,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "activity",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Activity",
                                    "plural": false,
                                    "selections": [
                                        (v1 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "title",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "i18nText",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "en",
                                                    "args": null,
                                                    "storageKey": null
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "date",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "attended",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "ParticipantListEventsCellQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v2 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "ParticipantListEventsCellQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v2 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "ParticipantListEventsCellQuery",
            "id": null,
            "text": "query ParticipantListEventsCellQuery(\n  $participant_ids: String!\n  $sub_query_only: Boolean!\n) {\n  participants(ids: $participant_ids, sub_query_only: $sub_query_only) {\n    participant {\n      id\n      events {\n        id\n        activity {\n          id\n          title {\n            en\n          }\n        }\n        date\n        attended\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '8ba5a0bf08d5e4c945071e46ca2a3929';
export default node;
