import React from "react";
import moment from "moment";
import "./RecommendationForm";

export function SignatureLine() {
  return (
    <div className="avoid-page-break">
      <div className="signature-container">
        <div className="mr">
          <p>APPROVED:</p>
        </div>
        <div className={"signature-line-container mr"}>
          <div className="signature-line">&nbsp;</div>
          <p>Probation Supervisor</p>
        </div>
        <div className="mr">
          <p>{`DATE: ${moment().format("MM/DD/YYYY")}`} </p>
        </div>
        <div className={"signature-line-container"}>
          <div className="signature-line">&nbsp;</div>
          <p>Pretrial Program Team Member</p>
        </div>
      </div>
      <div>
        <div style={{ textAlign: "right" }}>cc: District Attorney</div>
        <div style={{ textAlign: "right" }}>Public Defender</div>
      </div>
    </div>
  );
}
