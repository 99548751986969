import graphql from "babel-plugin-relay/macro";

import { CaseManager } from "../../lib/graphql";

export interface AppHeaderGqlResponse {
  me: {
    email: string;
    case_manager: CaseManager;
  };
}

export const AppHeaderGql = graphql`
  query AppHeaderGqlQuery {
    me {
      email
      case_manager {
        id
        name {
          first
          last
        }
      }
    }
    application {
      flags {
        all(flag_ids: "group_chat_enabled")
      }
    }
  }
`;
