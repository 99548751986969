import graphql from "babel-plugin-relay/macro";

import { Participant } from "../../shared/lib/graphql";

export type ParticipantDetailsType = Pick<
  Participant,
  | "id"
  | "created_at"
  | "is_active"
  | "case_manager"
  | "language"
  | "name"
  | "assessment"
  | "phone"
  | "risk_level"
  | "status"
  | "compliance"
  | "auth_token"
  | "sms_enabled"
  | "sms_disabled_by"
  | "sms_consent"
  | "sms_can_enable"
  | "preferred_name"
  | "supervision_begin_date"
  | "supervision_end_date"
  | "address"
  | "hispanic_or_latino"
  | "race"
  | "alcohol_monitoring"
  | "gps_monitoring"
  | "date_of_birth"
  | "sex"
  | "employer"
  | "hair"
  | "eye_color"
  | "height"
  | "weight"
  | "person_address"
  | "place_of_birth"
  | "person_id"
  | "victims"
  | "stay_away"
  | "peaceful_contact"
  | "case_number"
  | "extra_equipment"
  | "incident_notes"
  | "offense_info"
  | "current_device"
  | "profile_image"
  | "fines_and_fees"
  | "record_source"
  | "court_recommendation"
  | "court_decision"
  | "case_disposition"
  | "home_phone_human_readable"
  | "cell_phone_human_readable"
  | "equipment_returned"
  | "protective_order_notes"
  | "probation_number"
>;

export interface ParticipantDetailsQueryResult {
  participants: {
    participant: Array<ParticipantDetailsType>;
  };
}

export const ParticipantDetailsQuery = graphql`
  query ParticipantDetailsQueryTypesQuery($id: String!) {
    application {
      compliance_options {
        key
        value
      }
      risk_levels {
        key
        value
      }
      twilio {
        source_number
      }
    }
    participants(id: $id) {
      participant {
        id
        created_at
        auth_token
        case_manager {
          id
          name {
            first
            last
          }
        }
        language
        name {
          first
          last
        }
        preferred_name
        assessment {
          score
          tool
        }
        phone {
          mobile
          home
        }
        risk_level
        status
        compliance
        sms_enabled
        sms_disabled_by
        sms_consent
        sms_can_enable
        is_active
        supervision_begin_date
        supervision_end_date
        address {
          value
        }
        hispanic_or_latino
        race
        alcohol_monitoring
        gps_monitoring
        date_of_birth
        sex
        employer {
          name
        }
        hair
        eye_color
        height
        weight
        person_address
        place_of_birth
        person_id {
          number
          state
          type
        }
        victims {
          name {
            first
            last
          }
          phone
          date_of_birth
          address
          sex
        }
        stay_away
        peaceful_contact
        case_number
        extra_equipment
        incident_notes
        offense_info
        current_device {
          platform
        }
        profile_image {
          base64_media
          mime_type
        }
        fines_and_fees {
          fines_and_fees_amount_owed
          fines_and_fees_last_payment_amount
          fines_and_fees_last_payment_date
          fines_and_fees_expected_payment_amount
          restitution_amount_owed
          restitution_last_payment_amount
          restitution_last_payment_date
          restitution_expected_payment_amount
        }
        record_source
        court_recommendation
        court_decision
        case_disposition
        home_phone_human_readable
        cell_phone_human_readable
        equipment_returned
        protective_order_notes
        probation_number
      }
    }
  }
`;
