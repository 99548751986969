import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import graphql from "babel-plugin-relay/macro";
import idx from "idx.macro";
import PrintTransmittal from "../elements/PrintTransmittal";
import { QueryRenderer } from "../../shared/lib/graphql";
import { BookingGroup } from "../../graphql-types";

interface Props {
  bookingGroupId: string;
}

interface BookingGroupResponse {
  booking_groups: {
    booking_group: BookingGroup[];
  };
}

export default class PrintTransmittalContainer extends React.Component<
  RouteComponentProps<Props>
> {
  render() {
    return (
      <QueryRenderer
        query={graphql`
          query PrintTransmittalContainerQuery($id: String) {
            booking_groups(id: $id) {
              booking_group {
                id
                created_at
                bookings {
                  id
                  booking_defendent {
                    cii_number
                    local_id
                    cdl_id
                    name {
                      first
                      last
                    }
                    date_of_birth
                    sex
                    race
                  }
                  booking_info {
                    booking_sid
                    local_id
                    booking_number
                    booking_datetime
                    booking_address
                  }
                  booking_charges {
                    id
                    booking_charge_sid
                    booking_sid
                    booking_number
                    booking_datetime
                    charge_auth_type
                    charge_code_section
                    charge_type
                    charge_datetime
                    charge_description
                    arrest_agency
                    arrest_datetime
                    crime_case_number
                    court_warrant_number
                  }
                  booking_releases {
                    charge_code_section
                    charge_description
                    charge_type
                    charge_release_datetime
                    charge_release_type
                    warrant_number
                    court_case_number
                    jc_control
                    created_datetime
                  }
                  booking_status {
                    booking_number
                    court_appearance_datetime
                    court_reason
                    court_room
                  }
                  classification
                  imported_date
                  comments
                  is_eligible
                  spanish_speaker
                  has_wait
                }
                ineligible_bookings {
                  id
                  booking_defendent {
                    cii_number
                    local_id
                    cdl_id
                    name {
                      first
                      last
                    }
                    date_of_birth
                    sex
                    race
                  }
                  booking_info {
                    booking_sid
                    local_id
                    booking_number
                    booking_datetime
                    booking_address
                  }
                  booking_charges {
                    id
                    booking_charge_sid
                    booking_sid
                    booking_number
                    booking_datetime
                    charge_auth_type
                    charge_code_section
                    charge_type
                    charge_datetime
                    charge_description
                    arrest_agency
                    arrest_datetime
                    crime_case_number
                    court_warrant_number
                  }
                  booking_releases {
                    charge_code_section
                    charge_description
                    charge_type
                    charge_release_datetime
                    charge_release_type
                    warrant_number
                    court_case_number
                    jc_control
                    created_datetime
                  }
                  booking_status {
                    booking_number
                    court_appearance_datetime
                    court_reason
                  }
                  classification
                  imported_date
                  comments
                  is_eligible
                  spanish_speaker
                  has_wait
                }
                email_sent
              }
            }
          }
        `}
        variables={{
          id: this.props.bookingGroupId,
        }}
        SuccessComponent={(props: BookingGroupResponse) => {
          const bookingGroup = idx(
            props,
            (_) => _.booking_groups.booking_group[0]
          );

          return <PrintTransmittal bookingGroup={bookingGroup} />;
        }}
      />
    );
  }
}
